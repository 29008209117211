export default {
    boatOnBook: {
        FR:
            'Le BoatOn Book est le carnet de santé de votre bateau ! Retrouvez-y tous les événements importants tels que: tâches passés et à venir, sorties que vous avez renseignées, dépenses et revenus.',
        EN:
            "The BoatOn Book is your boat's health book! Here you can find all the important events such as: past and future tasks, cruises, expenses and income.",
    },
    noBoat: {
        FR: `Vous n'avez pas enregistré de bateau`,
        EN: `You have not registered any boat`,
    },
    pageTitle: {
        FR: 'BoatOn Book',
        EN: 'BoatOn Book',
    },
}
