import React from 'react'
import dictionary from '../Dictionary/ActivityConfiguration'
import BoatOnComponent from '../BoatOnComponent'

export class ExpendableRow extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary

        this.state = {
            showExpand: false,
            expanded: false,
        }

        this.divRef = React.createRef()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState === this.state) {
            if (this.divRef?.current) {
                const { scrollHeight, offsetHeight } = this.divRef?.current
                if (scrollHeight > offsetHeight) {
                    this.setState({
                        showExpand: true,
                    })
                }
            }
        }
    }

    render() {
        const { showExpand, expanded } = this.state

        return (
            <>
                <div
                    ref={this.divRef}
                    className={this.props.className}
                    style={{
                        marginBottom: showExpand ? 20 : 0,
                        maxHeight: expanded ? 'unset' : undefined,
                    }}
                >
                    {showExpand && !expanded && (
                        <div
                            style={{
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                height: '100%',
                                boxShadow:
                                    'rgba(255,255,255,0.8) 0px -15px 8px -5px inset',
                                pointerEvents: 'none',
                            }}
                        ></div>
                    )}
                    {this.props.children}
                </div>
                {showExpand && (
                    <p
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            right: 0,
                            margin: 'auto',
                            width: '100%',
                            textAlign: 'center',
                            background: 'white',
                            color: '#303F9F',
                            cursor: 'pointer',
                        }}
                        onClick={() => {
                            this.setState({
                                expanded: !expanded,
                            })
                        }}
                    >
                        {expanded
                            ? this.displayText('showLess')
                            : this.displayText('showAll')}
                    </p>
                )}
            </>
        )
    }
}
