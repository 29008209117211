export default {
    redirectionWaiting: {
        FR: 'Redirection en attente',
        EN: 'Redirection waiting',
    },
    redirectionInProgress: {
        FR: 'Redirection en cours...',
        EN: 'Redirection in progress...',
    },
    somethingWentWrong: {
        EN:
            'Something went wrong, make sure the link you clicked is correct and try again later.',
        FR:
            'Un problème est survenu, assurez-vous que le lien que vous avez cliqué est correct et réessayez plus tard.',
    },
    redirectToHomepage: {
        FR: "Vous allez être redirigé vers l'accueil",
        EN: 'You will be redirected to the homepage',
    },
}
