import { inqueriesConstants } from '../../constants'
import { requestApi } from '../../services/api.service'

const getPersonalParkingInqueries = () => {
    return dispatch => {
        dispatch({
            type: inqueriesConstants.REQUEST_PERSONAL_PARKING_INQUERIES,
        })

        requestApi(`/inqueries/parking/user`, 'GET').then(
            result => {
                dispatch({
                    type:
                        inqueriesConstants.REQUEST_PERSONAL_PARKING_INQUERIES_SUCCESS,
                    personalParkingInqueries: result,
                })
            },
            error => {
                dispatch({
                    type:
                        inqueriesConstants.REQUEST_PERSONAL_PARKING_INQUERIES_FAILURE,
                    error: error,
                })
            },
        )
    }
}

const getPersonalInsuranceInqueries = () => {
    return dispatch => {
        dispatch({
            type: inqueriesConstants.REQUEST_PERSONAL_INSURANCE_INQUERIES,
        })

        requestApi(`/inqueries/insurance/user`, 'GET').then(
            result => {
                dispatch({
                    type:
                        inqueriesConstants.REQUEST_PERSONAL_INSURANCE_INQUERIES_SUCCESS,
                    personalInsuranceInqueries: result,
                })
            },
            error => {
                dispatch({
                    type:
                        inqueriesConstants.REQUEST_PERSONAL_INSURANCE_INQUERIES_FAILURE,
                    error: error,
                })
            },
        )
    }
}

const getReceivedInqueries = () => {
    return dispatch => {
        dispatch({
            type: inqueriesConstants.REQUEST_RECEIVED_PARKING_INQUERIES,
        })

        requestApi(`/inqueries/parking/userReceiver`, 'GET').then(
            result => {
                dispatch({
                    type:
                        inqueriesConstants.REQUEST_RECEIVED_PARKING_INQUERIES_SUCCESS,
                    receivedParkingInqueries: result,
                })
            },
            error => {
                dispatch({
                    type:
                        inqueriesConstants.REQUEST_RECEIVED_PARKING_INQUERIES_FAILURE,
                    error: error,
                })
            },
        )
    }
}

export const inqueriesActions = {
    getPersonalParkingInqueries,
    getPersonalInsuranceInqueries,
    getReceivedInqueries,
}
