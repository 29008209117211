import { requestApi } from '../services/api.service'
import { blockConstants } from '../constants'

export const getGroupById = (groupId = null) => {
    if (!groupId) return null

    return dispatch => {
        dispatch({ type: blockConstants.REQUEST_GROUP })
        return requestApi(`/user-groups/${groupId}/user`, 'GET').then(
            result => {
                dispatch({
                    type: blockConstants.REQUEST_GROUP_SUCCESS,
                    selectedGroup: result,
                })
                return result
            },
            error => {
                console.error(error)
                dispatch({
                    type: blockConstants.REQUEST_GROUP_FAILURE,
                    error,
                })
            },
        )
    }
}
