import React, { Component } from 'react'
import Translater from './Translater'
import CommonDictionary from './CommonDictionary'
import anchorLoader from '../../images/anchorLoader.gif'
import { LangContext } from '../../languages/LocalizerContext'
import AppRoute from '../../constants/AppRoute'
import { Typography, Box } from '@material-ui/core'

class BoatOnComponent extends Component {
    static contextType = LangContext

    constructor(props) {
        super(props)

        this.translater = new Translater()
        this.dictionary = CommonDictionary
        this.commonDictionary = CommonDictionary
        this.AppRoute = AppRoute

        this.displayText = this.displayText.bind(this)
        this.getLanguage = this.getLanguage.bind(this)
        this.getFrenchTranslate = this.getFrenchTranslate.bind(this)
        this.getEnglishTranslate = this.getEnglishTranslate.bind(this)
    }

    displayText(key) {
        // if (key === 'loadingText')
        //     console.log(this.dictionary[key], this.commonDictionary)
        return this.dictionary[key]
            ? this.translater.displayText(this.dictionary[key], this.context)
            : this.translater.displayText(
                  this.commonDictionary[key],
                  this.context,
              )
    }

    displayTextApi(translation) {
        if (!translation?.id || !translation[this.getLanguage(this.context)]) {
            console.error(`This translation didn't exist`, translation)
            return ``
        }
        return translation[this.getLanguage(this.context)]
    }

    getContext() {
        return this.context
    }

    getPathForRoute(routeKey) {
        return this.translater.getRoute(this.context, routeKey)
    }

    getContextLink(link) {
        return '/' + this.getContext() + link
    }

    getLanguage() {
        return this.translater.getLanguageForApi(this.context)
    }

    getFrenchTranslate(text) {
        return (
            this.translater.getFrenchTranslate(this.dictionary, text) ||
            this.translater.getFrenchTranslate(this.commonDictionary, text)
        )
    }

    getEnglishTranslate(text) {
        return (
            this.translater.getEnglishTranslate(this.dictionary, text) ||
            this.translater.getEnglishTranslate(this.commonDictionary, text)
        )
    }

    renderLoading(pixels, height, showText) {
        return (
            <div
                style={{
                    textAlign: `center`,
                    width: '100%',
                    height: `calc(${height} + 25px)` || `calc(100% + 25px)`,
                }}
            >
                <img
                    src={anchorLoader}
                    alt="anchor"
                    margin="auto"
                    width={pixels}
                    height={height || pixels}
                    style={{
                        top: '50%',
                        left: '50%',
                    }}
                />
                {showText && (
                    <Typography>
                        <Box fontWeight="fontWeightMedium">
                            {this.displayText('loadingText')}
                        </Box>
                    </Typography>
                )}
            </div>
        )
    }

    historyPush(
        history,
        routeKey,
        additionalURL = '',
        historyData = null,
        routeParameters = {},
    ) {
        let pathname =
            this.translater.getRoute(this.context, routeKey) + additionalURL
        pathname = this.replaceParameters(pathname, routeParameters)
        if (historyData) {
            historyData.pathname = pathname
            history.push(pathname, historyData)
        } else {
            history.push(pathname)
        }
    }

    replaceParameters(pathname, routeParameters) {
        for (const parameterKey in routeParameters) {
            pathname = pathname.replace(
                `:${parameterKey}`,
                routeParameters[parameterKey],
            )
        }
        return pathname
    }

    getMessages() {
        return this.translater.getMessages()[this.context]
    }

    getBobEventIcon(eventId) {
        return true
    }

    getAddress(addressObject) {
        if (!addressObject) return null
        else if (
            addressObject &&
            addressObject.latitude === '-1' &&
            addressObject.longitude === '-1'
        )
            return addressObject.fullText
        else if (
            addressObject &&
            addressObject.latitude !== '-1' &&
            addressObject.longitude !== '-1' &&
            addressObject.number &&
            addressObject.street &&
            addressObject.zipcode &&
            addressObject.city
        )
            return `${addressObject.number} ${addressObject.street} \n${addressObject.zipcode} ${addressObject.city}`
        return addressObject.fullText
    }
}

export default BoatOnComponent
