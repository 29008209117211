export default theme => ({
    header: {
        position: `relative`,
        height: 340,
        width: `calc(100% + 100vw - 100%)`,
        paddingTop: 50,
    },
    bottomTextContainer: {
        maxWidth: 1120,
        width: `96%`,
        margin: `70px auto`,
    },
    bottomTextTitle: {
        margin: `15px 0`,
        fontSize: `19px`,
    },
    bottomLinkIcon: {
        height: 20,
        transition: `0.4s`,
    },
    explTexts: {
        width: 545,
        [theme.breakpoints.down('sm')]: {
            width: `100%`,
            textAlign: `center`,
        },
    },
    explTitle: {
        fontWeight: 700,
        fontSize: `38px`,
        lineHeight: `53px`,
        marginBottom: 8,
    },
    explSubt: {
        lineHeight: `26px`,
        fontSize: `19px`,
        color: `rgba(0,0,0,0.5)`,
        marginBottom: 22,
    },
    bottomLink: {
        '&:hover': {
            cursor: `pointer`,
        },
        display: `flex`,
        alignItems: `center`,
        color: `rgba(0,0,0,0.65)`,
    },
    bottomTextShow: {
        marginRight: 5,
        fontSize: `14px`,
    },
    explLinkText: {
        color: `#303F9F`,
        fontWeight: 500,
        fontSize: `19px`,
        marginRight: 15,
    },
    explLinkIcon: {
        height: 20,
        color: `#303F9F`,
    },
    explLink: {
        display: `flex`,
        alignItems: `center`,
        '&:hover': {
            cursor: `pointer`,
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: `center`,
            width: `100%`,
        },
    },
    headerContainer: {
        display: `grid`,
        width: `100%`,
        boxSizing: `border-box`,
        columnGap: `20px`,
        gridTemplateColumns: `500px auto`,
        margin: `auto`,
        padding: `0px 50px`,
        maxWidth: `1356px`,
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: `1fr`,
            gridTemplateRows: `300px`,
            padding: `0px 15px`,
        },
    },
    youtubeContainer: {
        position: `relative`,
        overflow: `hidden`,
        width: `100%`,
        marginLeft: `auto`,
        marginRight: 0,
        paddingBottom: `56.25%`,
        height: 0,
        maxWidth: `525px`,
        [theme.breakpoints.down('sm')]: {
            marginRight: `auto`,
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: `auto`,
            marginTop: `25px`,
        },
    },
    titleContainer: {
        position: `relative`,
        display: `flex`,
        alignItems: `flex-start`,
        width: `100%`,
        flexDirection: `column`,
        [theme.breakpoints.down('sm')]: {
            alignItems: `center`,
            marginTop: -45,
        },
    },
    footerOffer: {
        position: `absolute`,
        right: -20,
        top: -135,
        transform: `translateX(100%)`,
        width: 175,
        height: 55,
        [theme.breakpoints.down('sm')]: {
            top: 80,
            right: 110,
        },
    },
    footerArrow: {
        marginBottom: 5,
        position: `absolute`,
        transform: `rotateZ(-20deg)`,
        left: 10,
        top: -20,
        [theme.breakpoints.down('sm')]: {
            right: -2,
            left: `auto`,
            top: -50,
            transform: `rotateZ(10deg)`,
        },
    },
    footerContainer: {
        overflow: `hidden`,
        width: `95%`,
        boxSizing: `border-box`,
        margin: `20px auto`,
        maxWidth: 1120,
        minHeight: 411,
        textAlign: `center`,
        background: `#F4F6F6`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        flexDirection: `column`,
        marginTop: 25,
        [theme.breakpoints.down('sm')]: {
            padding: `50px 10px`,
        },
    },
    footerLittletext: {
        position: `absolute`,
        bottom: -10,
        right: 0,
        fontFamily: `'Sedgwick Ave', cursive`,
        color: `#FFC107`,
        fontSize: `26px`,
        transform: `rotateZ(-10deg)`,
    },
    footerTitle: {
        fontWeight: 700,
        fontSize: `38px`,
        lineHeight: `53px`,
        position: `relative`,
    },
    footerSubtitle: {
        fontSize: `19px`,
        lineHeight: `30px`,
        color: `rgba(0,0,0,0.4)`,
        maxWidth: 490,
    },
    youtube: {
        zIndex: 1,
        position: `absolute`,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: `100%`,
        height: `100%`,
        border: `none`,
        maxHeight: `295.31px`,
        maxWidth: `525px`,
        boxSizing: `border-box`,
        borderRadius: 8,
    },
    satisfaction: {
        marginTop: 45,
        textAlign: `center`,
        fontWeight: 500,
        fontSize: `32px`,
        marginBottom: 30,
        lineHeight: 1.2,
        [theme.breakpoints.down('sm')]: {
            marginTop: 280,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: `55%`,
            marginBottom: 15,
        },
    },
    part: {
        marginBottom: 45,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        flexWrap: `wrap`,
        '& img': {
            margin: `5px 15px`,
        },
    },
    reviews: {
        display: `grid`,
        margin: `auto`,
        gridTemplateColumns: `repeat(4, 1fr)`,
        maxWidth: `1112px`,
        columnGap: `24px`,
        padding: `0px 45px`,
        paddingBottom: 10,
        overflowX: `auto`,
        [theme.breakpoints.down('sm')]: {
            width: `100%`,
            padding: `20px 0`,
            overflowX: `auto`,
        },
    },
    expl: {
        display: `flex`,
        alignItems: `center`,
        width: `100%`,
        justifyContent: `center`,
        flexWrap: `wrap`,
        '& > div': {
            margin: `20px 40px`,
        },
        padding: `15px 0`,
        [theme.breakpoints.down('sm')]: {
            '& > div': {
                margin: `15px 0px`,
            },
            padding: `15px 16px`,
            boxSizing: `border-box`,
            flexDirection: `column !important`,
        },
    },
    explImgs: {
        filter: `drop-shadow(0px 8.3871px 12.5806px rgba(48, 63, 159, 0.08))`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        flexDirection: `column`,
        borderRadius: 8,
        overflow: `hidden`,
    },
    explBackground: {
        width: `100%`,
        maxWidth: 520,
        objectFit: `contain`,
    },
    explDemo: {
        width: `100%`,
        maxWidth: 520,
        objectFit: `contain`,
    },
    preFooterButtons: {
        display: `flex`,
        marginTop: 40,
        marginLeft: `auto`,
        marginRight: `auto`,
        '& button': {
            marginRight: `24px`,
        },
        position: `relative`,
        alignItems: `center`,
        justifyContent: `center`,
        width: `fit-content`,
        zIndex: 2,
        [theme.breakpoints.down('sm')]: {
            flexDirection: `column !important`,
            marginLeft: `auto`,
            '& button': {
                marginRight: `0`,
                marginBottom: `10px`,
            },
        },
    },
    headerButtons: {
        display: `flex`,
        marginTop: 20,
        marginRight: `auto`,
        '& button': {
            marginRight: `24px`,
        },
        position: `relative`,
        alignItems: `center`,
        justifyContent: `center`,
        width: `fit-content`,
        zIndex: 2,
        [theme.breakpoints.down('sm')]: {
            flexDirection: `column !important`,
            marginLeft: `auto`,
            '& button': {
                marginRight: `0`,
                marginBottom: `10px`,
            },
        },
    },
    pointSpeTitle: {
        fontWeight: 700,
        color: `#303F9F`,
    },
    threePoints: {
        minHeight: 300,
        boxSizing: `border-box`,
        padding: `35px 10px`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        background: `#F4F6F6`,
        flexWrap: `wrap`,
        '& > div': {
            margin: `0 41px`,
        },
        [theme.breakpoints.down('sm')]: {
            '& > div': {
                margin: `10px 41px`,
            },
        },
    },
    pointTitle: {
        marginTop: 10,
        fontSize: `22px`,
        whiteSpace: 'pre-line',
        lineHeight: 1.25,
        textAlign: `center`,
    },
    pointText: {
        width: 320,
        color: `rgba(0,0,0,0.5)`,
        fontSize: `19px`,
        textAlign: `center`,
    },
    point: {
        display: `flex`,
        alignItems: `center`,
        flexDirection: `column`,
        justifyContent: `flex-start`,
        height: `285px`,
    },
    pointIconContainer: {
        height: 88,
        width: 88,
        minHeight: 88,
        minWidth: 88,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        borderRadius: `100%`,
        background: `white`,
        boxShadow: `-8px 0px 12px rgba(69, 88, 214, 0.08), 0px 8px 12px rgba(69, 88, 214, 0.04)`,
    },
    pointIcon: {
        width: 50,
        objectFit: `contain`,
        background: `white`,
    },
    normalButton: {
        padding: `10px 30px`,
    },
    whiteButton: {
        background: `white`,
        color: `#303F9F`,
        border: `2px solid #303F9F`,
        fontWeight: `bold`,
        padding: `10px 30px`,
        '&:hover': {
            background: `white`,
        },
    },
    title: {
        lineHeight: `40px`,
        textAlign: `center`,
        fontSize: `40px`,
        color: `#303F9F`,
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            marginTop: 50,
        },
    },
    subtitle: {
        width: 350,
        lineHeight: 1.5,
        marginTop: 20,
        fontSize: `22px`,
        '& sup': {
            fontSize: `14px`,
            marginRight: 4,
        },
    },
    vector: {
        width: `100%`,
        position: `absolute`,
        left: 0,
        bottom: 0,
        maxHeight: 58.72,
        minHeight: 122.27,
    },
    vector2: {
        minHeight: 122.27,
        [theme.breakpoints.down('xs')]: {
            display: `none`,
        },
    },
    vector3: {
        minHeight: 88.68,
        [theme.breakpoints.down('xs')]: {
            display: `none`,
        },
    },
    vector4: {
        minHeight: 58.72,
        [theme.breakpoints.down('xs')]: {
            display: `none`,
        },
    },
    vector5: {
        display: `none`,
        [theme.breakpoints.down('xs')]: {
            display: `inline-block`,
            bottom: -50,
        },
    },
    vector6: {
        display: `none`,
        [theme.breakpoints.down('xs')]: {
            display: `inline-block`,
            bottom: -125,
        },
    },
    vector7: {
        display: `none`,
        [theme.breakpoints.down('xs')]: {
            display: `inline-block`,
            bottom: -85,
        },
    },
    logoImage: {
        height: `100%`,
        maxHeight: `45px`,
    },
})
