export default {
    save: {
        FR: 'Enregistrer',
        EN: 'Save',
    },
    titleLevels: {
        FR: 'Vérifiez les capacités et les niveaux de vos équipements.',
        EN: 'Check filling levels of your equipments.',
    },
    capacity: {
        FR: 'Cap.',
        EN: 'Cap.',
    },
    actualLevel: {
        FR: 'Niveau actuel',
        EN: 'Actual level',
    },
    missingCapacity: {
        FR: `Il semble que la capacité de cet équipement n’a pas été complétée. Rendez-vous dans la page “Inventaire” pour la renseigner`,
        EN:
            'It seems that the capacity of this equipment has not been completed. Go to the "Inventory" page to fill it in.',
    },
}
