import React from 'react'
import BoatOnComponent from '../common/BoatOnComponent'
import Navigation from '../common/Navigation'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/StaticPagesCss'
import dictionary from './Dictionary/Error404PageDico'
import { Typography, Button } from '@material-ui/core'
import { history } from '../../helpers'

class Error404Page extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {}
    }

    render() {
        const { classes, location } = this.props
        var personnalizedError,
            routeRedirect,
            buttonText = null
        if (location && location.state) {
            var {
                personnalizedError,
                routeRedirect,
                buttonText,
            } = this.props.location.state
        }

        return (
            <div className={classes.root}>
                <Navigation>
                    <div className={classes.base}>
                        <Typography
                            variant="h1"
                            style={{
                                fontSize: '28px',
                                color: 'black',
                                paddingBottom: '30px',
                                margin: 'auto',
                                fontWeight: 400,
                            }}
                        >
                            Erreur 404
                        </Typography>
                        <Typography
                            style={{
                                fontSize: '22px',
                                fontWeight: 300,
                                whiteSpace: 'pre-wrap',
                                lineHeight: 'normal',
                                display: 'inline-block',
                            }}
                        >
                            {personnalizedError
                                ? personnalizedError
                                : this.displayText('basicError')}
                        </Typography>
                        {routeRedirect && buttonText ? (
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() =>
                                    this.historyPush(history, routeRedirect)
                                }
                                style={{ marginTop: '20px' }}
                            >
                                {buttonText}
                            </Button>
                        ) : (
                            <></>
                        )}
                        <div className={classes.body}></div>
                    </div>
                </Navigation>
            </div>
        )
    }
}

export default withStyles(styles)(Error404Page)
