export default theme => ({
    row: {
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'row',
        alignItems: 'center',
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        overflow: 'hidden',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
    },
    fcEvent: {
        borderRadius: 3,
        padding: 2,
        paddingTop: 5,
        width: '100%',
        cursor: 'pointer',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'space-between',
        paddingInline: 5,
        color: '#212121',
        fontSize: 12,
    },
    fcCustomEventInfo: {
        overflow: 'hidden',
        paddingLeft: 3,
        '&.fc-event-red': {
            borderLeft: 'solid 4px red',
            backgroundColor: 'rgb(255, 223, 164)',
        },
    },
    fcEventTitle: {
        fontSize: '1.1em',
        fontWeight: 500,
        '@media (max-width: 500px)': {
            fontSize: '0.9em',
        },
        '-webkit-line-clamp': 1,
        overflow: 'hidden',
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
    },

    fcAssigneeBadge: {
        width: 25,
        height: 25,
        borderRadius: 15,
        fontSize: 12,
        aspectRatio: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#303F9F',
        backgroundColor: '#E1E5F9',
        fontWeight: 'bold',
        marginRight: 6,
        alignSelf: 'center',
    },
    dotContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 3,
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        height: 15,
        alignSelf: 'center',
    },
    dot: {
        width: 5,
        height: 5,
        aspectRatio: 1,
        borderRadius: 5,
        backgroundColor: '#212121',
    },
    fcEventList: {
        color: 'white',
        borderRadius: 3,
        padding: 2,
        width: '100%',
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 5,
        paddingRight: 5,
        fontSize: 12,
    },
    eventListContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
    },
    boaticon: {
        width: 20,
        height: 20,
    },
    amountContainer: {},
    assigneeContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        height: 40,
        width: 40,
    },
    absenceTimeContainer: {
        display: 'flex',
        gap: 5,
    },
    clockIcon: {
        fontSize: '12px !important',
    },
})
