export default theme => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    textField: {
        flex: 1,
        '& .MuiInputBase-root': {
            gap: 5,
        },
    },
    tooltip: {
        backgroundColor: '#3040a0',
        color: 'white',
        maxWidth: 300,
        border: '1px solid #dadde9',
        fontSize: '15px',
    },
    helpIcon: {
        marginTop: `-10px`,
        width: `20px`,
        height: `20px`,
        marginRight: `3px`,
        marginLeft: `3px`,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    textButton: {
        fontSize: 14,
        alignSelf: 'flex-start',
    },
    addDoc: {
        border: '1px dashed #c4c4c4',
        margin: '10px 0px',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '5px',
        },
    },
    doc: {
        margin: 'auto',
        display: 'block',
        padding: '10px',
        width: '50px',
        height: '50px',
        color: '#C4C4C4',
    },

    // Calendar CUSTOM
    startEdge: {
        backgroundColor: '#FFFFFF',
    },
    endEdge: {
        backgroundColor: '#FFFFFF',
    },
    dayNumber: {
        fontWeight: '400',
        color: '#333333',
        '& span': { zIndex: 1 },
    },
    calendarWrapper: {
        fontSize: '14px',
        fontWeight: '400',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    calendar: {
        '& .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge': {
            borderTopLeftRadius: '20px',
            borderBottomLeftRadius: '20px',
            left: '6px',
            [theme.breakpoints.down('sm')]: {
                left: '6px',
            },
        },
        '& .rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge': {
            borderTopRightRadius: '20px',
            borderBottomRightRadius: '20px',
            right: '6px',
            [theme.breakpoints.down('sm')]: {
                right: '6px',
            },
        },
    },
    weekDays: {
        fontFamily: 'Roboto',
        borderBottom: '1px solid #E5E5E5',
        padding: '0px 0px 10px 0px',
        textTransform: 'capitalize',
    },
    weekDay: { paddingBottom: '10px', color: '#9C9C9C', fontWeight: 200 },
    month: {
        padding: '0px 0px 15px 0px',
    },
    monthAndYearWrapper: { paddingTop: '0px' },
    monthAndYearPickers: {
        '& select': {
            fontFamily: 'Roboto',
            textTransform: 'capitalize',
            color: '#333333',
            fontSize: '16px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '16px',
            },
            fontWeight: 400,
        },
    },
    dayPassive: { visibility: 'hidden' },
    dayStartPreview: {
        border: 'none',
        '& .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview': {
            position: 'absolute',
            border: '0px solid transparent',
        },
    },
    dayInPreview: { border: 'none' },
    dayEndPreview: { border: 'none' },
    days: {
        fontSize: '14px',
        paddingTop: '10px',
        paddingBottom: '8px',
        '& button': { marginBottom: '-10px', height: '50px' },
    },
    dayToday: {
        '&.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after': {
            backgroundColor: '#3040a0', //#D5EFFF
        },
        '& .rdrDayNumber span:after': {
            content: '',
            backgroundColor: '#3040a0',
            width: '13px',
            bottom: '8px',
        },
        '& .rdrDayNumber span': { fontWeight: 400, color: '#333333' },
    },
    startDateCustom: {
        color: '#FFFFFF',
        zIndex: 0,
        right: '0px',
        clipPath: 'ellipse(20px 20px)',
        backgroundColor: '#3040a0',
        fontWeight: 400,
        width: '50px',
        lineHeight: '44px',
    },
    startDateFilledCustom: {
        zIndex: -1,
        position: 'absolute',
        background: '#D5EFFF',
        width: '25px',
        height: '40px',
        top: '0px',
        right: '0px',
    },
    endDateFilledCustom: {
        zIndex: -1,
        position: 'absolute',
        background: '#D5EFFF',
        width: '25px',
        height: '40px',
        top: '0px',
        left: '0px',
    },
    endDateCustom: {
        color: '#FFFFFF',
        zIndex: 0,
        right: '0px',
        clipPath: 'ellipse(20px 20px)',
        backgroundColor: '#3040a0',
        fontWeight: 400,
        width: '50px',
        lineHeight: '44px',
    },
    inRangeCustom: { fontWeight: '400', color: '#333333' },
    todayCustom: { fontWeight: '400' },
    todayWithinIntervalCustom: { fontWeight: '400', color: '#333333' },
    weekEndCustom: { fontWeight: '400', color: '#333333' },
})
