import React from 'react'
import BoatOnComponent from '../BoatOnComponent'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/InputLabelCss'
import dictionary from './Dictionary/InputLabel'
import Chip from '@material-ui/core/Chip'
import { connect } from 'react-redux'
import { typesActions } from '../../../actions/types.actions'
import {
    Tooltip,
    Typography,
    Checkbox,
    TextField,
    Divider,
} from '@material-ui/core'
import BoatOnAutoComplete from '../../common/BoatOnAutoComplete'
import { userActions, getElementWithDetails } from '../../../actions'
import AppRoute from '../../../constants/AppRoute'
import { history } from '../../../helpers'
import { Button as ButtonBON } from '../BoatOnButton'
import BoatOnModal from '../BoatOnModal'
import { Autocomplete } from '@material-ui/lab'
import SearchIcon from '@material-ui/icons/Search'
import { fncDeepFilterOptions } from '../Utils'

class InputLabel extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            checkup: null,
            inventory: {
                equipmentFamily: null,
                equipment: null,
                parts: [],
            },
            modalOpen: null,
            nameTextfieldSelected: null,
        }

        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleTagsChange = this.handleTagsChange.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.handleAddEquipment = this.handleAddEquipment.bind(this)
        this.conditionAlreadySet = this.conditionAlreadySet.bind(this)
        this.getName = this.getName.bind(this)
        this.openModalDetail = this.openModalDetail.bind(this)
        this._getCallToAction = this._getCallToAction.bind(this)
        this._redirectPricing = this._redirectPricing.bind(this)
        this.openModalCTA = this.openModalCTA.bind(this)
    }

    componentDidMount() {
        const {
            event,
            loadingType,
            boat,
            userCheckups,
            userEquipmentFamilies,
            userEquipments,
            userParts,
            boats,
        } = this.props
        if (!boats) {
            if (event) var { checkup } = event

            this.setInfos({ checkup })
            if (loadingType === 0 && boat) {
                if (!userCheckups) {
                    this.props.dispatch(
                        typesActions.requestUserCheckups(boat.id || null),
                    )
                } else {
                    this._checkDuplicateCheckups(
                        JSON.parse(JSON.stringify(userCheckups)),
                    )
                }
                if (!userEquipmentFamilies) {
                    this.props.dispatch(
                        typesActions.requestUserEquipmentFamilies(
                            boat.id || null,
                        ),
                    )
                }
                if (!userEquipments) {
                    this.props.dispatch(
                        typesActions.requestUserEquipments(boat.id || null),
                    )
                }
                if (!userParts) {
                    this.props.dispatch(
                        typesActions.requestUserParts(boat.id || null),
                    )
                }
            } else {
                this._checkDuplicateCheckups(
                    JSON.parse(JSON.stringify(userCheckups)),
                )
            }
        }
    }

    _checkDuplicateCheckups(userCheckups) {
        if (userCheckups) {
            const duplicates = {}
            for (const userCheckup of userCheckups) {
                if (userCheckup.userCheckup) {
                    const duplicatesTmp = userCheckups.filter(
                        u => u?.userCheckup?.id === userCheckup.userCheckup.id,
                    )
                    if (duplicatesTmp && duplicatesTmp.length > 1) {
                        duplicates[duplicatesTmp[0].userCheckup.id] = []
                        for (const duplicate of duplicatesTmp) {
                            const index = userCheckups.findIndex(
                                u => duplicate.id === u.id,
                            )
                            duplicates[duplicate.userCheckup.id].push({
                                equipments: duplicate.equipments,
                                parts: duplicate.parts,
                            })
                            if (userCheckup.id !== duplicate.id)
                                userCheckups.splice(index, 1)
                        }
                    }
                }
            }
            this.setState({
                duplicates: duplicates,
                userCheckups: userCheckups,
            })
        }
    }

    componentDidUpdate(prevProps) {
        const { event, newEvent, loadingType, userCheckups, boats } = this.props
        if (!boats) {
            if (event) var { checkup, detail } = event
            if (detail) var { part, equipment } = detail
            if (!newEvent && prevProps.newEvent) {
                this.setInfos({ checkup, part, equipment })
            }

            if (prevProps.loadingType > 0 && loadingType === 0) {
                this._checkDuplicateCheckups(
                    JSON.parse(JSON.stringify(userCheckups)),
                )
            }
        }
    }

    setInfos({ checkup = null, part = null, equipment = null }) {
        this._mountInventory({ checkup, part, equipment })
        this.setState({
            checkup: checkup,
        })
    }

    handleCloseModal() {
        this.setState({
            modalOpen: null,
            nameTextfieldSelected: null,
        })
    }

    _getEquipmentFamily(equipment) {
        if (equipment.equipmentFamily) {
            if (equipment.equipmentFamily.type)
                return `(${this.displayTextApi(
                    equipment.equipmentFamily.type.translation,
                )})`
            else if (equipment.equipmentFamily.userEquipmentFamily)
                return `(${equipment.equipmentFamily.userEquipmentFamily.name})`
        }
        return ``
    }

    getName(element) {
        const { arrayFor } = this.props

        if (element) {
            if (element.checkupType)
                return this.displayTextApi(element.checkupType.translation)
            if (element.userCheckup) return element.userCheckup.name
            if (element.equipmentType)
                return `${this.displayTextApi(
                    element.equipmentType.translation,
                )} 
                    ${
                        arrayFor === 'INVENTORY_PART'
                            ? this._getEquipmentFamily(element)
                            : ''
                    }`
            if (element.userEquipment)
                return `${element.userEquipment.name} 
            ${
                arrayFor === 'INVENTORY_PART'
                    ? this._getEquipmentFamily(element)
                    : ''
            }`
            if (element.type)
                return this.displayTextApi(element.type.translation)
            if (element.userEquipmentFamily)
                return element.userEquipmentFamily.name

            if (element.partType)
                return this.displayTextApi(element.partType.translation)
            if (element.userPart) return element.userPart.name
            if (element.name) return element.name
        }
        return ``
    }

    _setUserElement(value, id) {
        if (id === 'EQUIPMENTFAMILY') {
            value = {
                userEquipmentFamily: {
                    name: value,
                },
            }
        } else if (id === 'EQUIPMENT') {
            value = {
                userEquipment: {
                    name: value,
                },
            }
        } else if (id === 'PART') {
            value = {
                userPart: {
                    name: value,
                },
            }
        } else {
            value = {
                userCheckup: {
                    name: value,
                },
            }
        }

        return value
    }

    handleTagsChange(_, values, { id }) {
        let { inventory, checkup, duplicates } = this.state
        if (!this.props.boats) {
            values = values.map(value => {
                if (typeof value === `string`)
                    return this._setUserElement(value, id)
                return value
            })

            if (id === 'EQUIPMENTFAMILY') {
                inventory = {
                    equipmentFamily: values[1] ? values[1] : values[0] || null,
                    equipment: null,
                    parts: [],
                }
            } else if (id === 'EQUIPMENT') {
                inventory = {
                    ...inventory,
                    equipment: values[1] ? values[1] : values[0] || null,
                    parts: [],
                }
            } else if (id === 'PART') {
                inventory = {
                    ...inventory,
                    parts: values,
                }
            } else {
                checkup = values[1] ? values[1] : values[0] || null
                if (
                    !(
                        checkup?.userCheckup &&
                        duplicates[checkup?.userCheckup.id]
                    )
                )
                    this._mountInventory({
                        checkup: values[1] ? values[1] : values[0] || null,
                    })
                else {
                    this.setState({
                        inventory: {
                            equipmentFamily: null,
                            equipment: null,
                            parts: [],
                        },
                    })
                }
                this.setState({ checkup: checkup }, () => {
                    this.props.onTagsChange(this._unmountInventory())
                    this.handleCloseModal()
                })
            }

            if (
                id === 'EQUIPMENTFAMILY' ||
                id === 'EQUIPMENT' ||
                id === 'PART'
            ) {
                this.setState({ inventory: inventory }, () => {
                    this.props.onTagsChange(this._unmountInventory())
                    this.handleCloseModal()
                })
            }
        } else {
            this.props.onTagsChange(values)
            this.handleCloseModal()
        }
    }

    _getEngineEquipments(userEquipment) {
        return userEquipment?.id >= 1 && userEquipment?.id <= 3
    }

    _excluseAllEngineExceptBasicEngine(userEquipment) {
        return (
            !userEquipment?.equipmentType?.optionnalDetailActivated?.power ||
            (userEquipment?.equipmentType?.optionnalDetailActivated?.power &&
                userEquipment?.equipmentType?.id === 5)
        )
    }

    _filterEquipments(areEngineSeparate = false) {
        let userEquipments = JSON.parse(
            JSON.stringify(this.props.userEquipments),
        )
        const { arrayFor, subscriptions } = this.props
        const { inventory } = this.state
        let engines = null

        if (
            inventory &&
            inventory.equipmentFamily &&
            JSON.stringify(inventory.equipmentFamily) !== `{}`
        ) {
            if (areEngineSeparate) {
                engines = userEquipments.filter(
                    userEquipment =>
                        inventory.equipmentFamily.id !== undefined &&
                        userEquipment?.equipmentFamily?.id ===
                            inventory.equipmentFamily.id &&
                        this._getEngineEquipments(userEquipment),
                )
                userEquipments = userEquipments.filter(
                    userEquipment =>
                        inventory.equipmentFamily.id !== undefined &&
                        userEquipment?.equipmentFamily?.id ===
                            inventory.equipmentFamily.id &&
                        this._excluseAllEngineExceptBasicEngine(userEquipment),
                )
            } else {
                userEquipments = userEquipments.filter(
                    userEquipment =>
                        inventory.equipmentFamily.id !== undefined &&
                        userEquipment?.equipmentFamily?.id ===
                            inventory.equipmentFamily.id,
                )
            }
        }

        if (arrayFor === 'INVENTORY_PART')
            userEquipments = this._getAlreadySetEquipment()

        if (
            (!userActions.checkSubscriptionPage(subscriptions, 6) ||
                !userActions.checkSubscriptionPage(subscriptions, 5) ||
                !userActions.checkSubscriptionPage(subscriptions, 4) ||
                !userActions.checkSubscriptionPage(subscriptions, 3)) &&
            arrayFor === 'INVENTORY_EQUIPMENT'
        ) {
            userEquipments = userEquipments.filter(
                e =>
                    e.equipmentType &&
                    e.equipmentType.optionnalDetailActivated &&
                    e.equipmentType.optionnalDetailActivated.power,
            )
            userEquipments.push({
                button: true,
                element: this._getCallToAction(`callToActionEquip`),
            })
        }

        return { equipments: userEquipments, engines: engines }
    }

    openModalCTA() {
        this.setState({ modalOpen: `callToAction` })
    }

    _getCallToAction(text) {
        return (
            <div onClick={this.openModalCTA} style={{ fontWeight: `bold` }}>
                {' '}
                {this.displayText(text)}{' '}
            </div>
        )
    }

    _redirectPricing() {
        this.historyPush(history, AppRoute.LogBook.Pricing)
    }

    _filterParts() {
        let userParts = JSON.parse(JSON.stringify(this.props.userParts))
        const { inventory } = this.state
        if (
            inventory &&
            inventory.equipment &&
            JSON.stringify(inventory.equipment) !== `{}`
        ) {
            userParts = userParts.filter(userPart => {
                return (
                    inventory.equipment.id !== undefined &&
                    userPart?.equipment?.id === inventory.equipment.id
                )
            })
        }
        return userParts
    }

    openModalDetail(e) {
        const saveEvent = this.props.event
        let event = e.currentTarget.dataset.event
            ? JSON.parse(e.currentTarget.dataset.event)
            : null
        const element = e.currentTarget.dataset.element
            ? JSON.parse(e.currentTarget.dataset.element)
            : null

        const { type } = e.currentTarget.dataset
        if (!event && element && (type === `PART` || type === `EQUIPMENT`)) {
            event = {
                detail: {
                    part: element.partType || element.userPart ? element : null,
                    equipment:
                        element.equipmentType || element.userEquipment
                            ? element
                            : null,
                },
            }
            if (
                event?.detail?.part &&
                saveEvent?.checkup?.parts?.[0]?.equipment
            ) {
                event.detail.part = {
                    ...event.detail.part,
                    equipment: saveEvent?.checkup?.parts?.[0]?.equipment,
                }
            }

            if (
                event?.detail?.equipment &&
                saveEvent?.checkup?.equipmentFamily &&
                !event?.detail?.equipment?.equipmentFamily
            ) {
                event.detail.equipment = {
                    ...event.detail.equipment,
                    equipmentFamily: saveEvent.checkup.equipmentFamily,
                }
            }
        }

        if (event && this.props.arrayFor === `CHECKUP`) {
            this.props.handleOpenModal(`detail`, { event })
            this.setState({ openModal: `detail` })
        }
    }

    _renderChip(eventElement, option, getTagProps, index) {
        const { subscriptions, isEngineInSelection } = this.props

        return (
            <Chip
                size="medium"
                variant="outlined"
                clickable
                data-event={JSON.stringify(eventElement)}
                data-type={
                    option.partType || option.userPart
                        ? `PART`
                        : option.equipmentType || option.userEquipment
                        ? `EQUIPMENT`
                        : null
                }
                data-element={JSON.stringify(option)}
                onClick={
                    userActions.checkSubscriptionPage(subscriptions, 6) ||
                    (option?.equipmentType?.translation?.fr &&
                        option.equipmentType.translation.fr
                            .toLowerCase()
                            .includes(`moteur`))
                        ? this.openModalDetail
                        : null
                }
                style={{
                    backgroundColor:
                        !eventElement &&
                        this.props.arrayFor === `CHECKUP` &&
                        (option.partType ||
                            option.userPart ||
                            option.equipmentType ||
                            option.userEquipment)
                            ? '#c4c4c4'
                            : '#fcd48e',
                }}
                label={
                    isEngineInSelection &&
                    (option.equipmentType || option.userEquipment)
                        ? this.displayText('engine')
                        : this.getName(option)
                }
                {...getTagProps({
                    index,
                })}
            />
        )
    }

    renderTags(value, getTagProps) {
        const { classes, arrayFor, subscriptions } = this.props

        return value.map((option, index) => {
            if (option) {
                const eventElement = getElementWithDetails(
                    this.props.events,
                    option,
                )
                return (
                    <Tooltip
                        className={classes.tooltip}
                        disableHoverListener={
                            arrayFor !== `CHECKUP` ||
                            !(
                                option.partType ||
                                option.userPart ||
                                option.equipmentType ||
                                option.userEquipment
                            ) ||
                            !subscriptions ||
                            (!userActions.checkSubscriptionPage(
                                subscriptions,
                                6,
                            ) &&
                                !(
                                    option?.equipmentType?.translation?.fr &&
                                    option.equipmentType.translation.fr
                                        .toLowerCase()
                                        .includes(`moteur`)
                                ))
                                ? true
                                : false
                        }
                        title={
                            <React.Fragment>
                                <Typography color="inherit">
                                    {!eventElement &&
                                    arrayFor === `CHECKUP` &&
                                    (option.partType || option.userPart)
                                        ? this.displayText('addInInventoryPart')
                                        : eventElement &&
                                          arrayFor === `CHECKUP` &&
                                          (option.partType || option.userPart)
                                        ? this.displayText('editInventoryPart')
                                        : !eventElement &&
                                          arrayFor === `CHECKUP` &&
                                          (option.equipmentType ||
                                              option.userEquipment)
                                        ? this.displayText(
                                              'addInInventoryEquipment',
                                          )
                                        : eventElement &&
                                          arrayFor === `CHECKUP` &&
                                          (option.equipmentType ||
                                              option.userEquipment)
                                        ? this.displayText(
                                              'editInventoryEquipment',
                                          )
                                        : ''}
                                </Typography>
                            </React.Fragment>
                        }
                    >
                        {this._renderChip(
                            eventElement,
                            option,
                            getTagProps,
                            index,
                        )}
                    </Tooltip>
                )
            }
            return <></>
        })
    }

    _mountFromCheckup(checkup, inventory) {
        if (checkup && checkup.parts && checkup.parts.length > 0) {
            const equipmentWithId = checkup.parts.find(
                part => part?.equipment?.id !== undefined,
            )
            const equipmentFamilyWithId = checkup.parts.find(
                part => part?.equipment?.equipmentFamily?.id !== undefined,
            )
            if (equipmentWithId?.equipment) {
                checkup.parts.map(
                    part => (part.equipment = equipmentWithId.equipment),
                )
            }
            inventory.parts = checkup.parts
            inventory.equipment =
                equipmentWithId?.equipment || checkup.parts[0].equipment //TODO: a changer si on veut y afficher plusieurs equipment
            if (
                equipmentFamilyWithId?.equipment?.equipmentFamily.type ||
                equipmentFamilyWithId?.equipment?.equipmentFamily
                    .userEquipmentFamily ||
                checkup.parts[0].equipment.equipmentFamily.type ||
                checkup.parts[0].equipment.equipmentFamily.userEquipmentFamily
            ) {
                inventory.equipmentFamily =
                    equipmentFamilyWithId?.equipment?.equipmentFamily ||
                    checkup.parts[0].equipment.equipmentFamily //TODO: a changer si on veut y afficher plusieurs family
            } else if (
                checkup.equipmentFamily.type ||
                checkup.equipmentFamily.userEquipmentFamily
            ) {
                inventory.equipmentFamily = checkup.equipmentFamily
            }
        } else if (
            checkup &&
            checkup.equipments &&
            checkup.equipments.length > 0
        ) {
            const equipmentWithId = checkup.equipments.find(
                equipment => equipment?.id !== undefined,
            )
            const equipmentFamilyWithId = checkup.parts.find(
                equipment => equipment?.equipmentFamily?.id !== undefined,
            )
            inventory.equipment = equipmentWithId || checkup.equipments[0] //TODO: a changer si on veut y afficher plusieurs equipment
            if (
                equipmentFamilyWithId?.equipmentFamily?.type ||
                equipmentFamilyWithId?.equipmentFamily?.userEquipmentFamily ||
                checkup.equipments[0].equipmentFamily?.type ||
                checkup.equipments[0].equipmentFamily?.userEquipmentFamily
            ) {
                inventory.equipmentFamily =
                    equipmentFamilyWithId?.equipmentFamily ||
                    checkup.equipments[0].equipmentFamily //TODO: a changer si on veut y afficher plusieurs family
            } else if (
                checkup.equipmentFamily?.type ||
                checkup.equipmentFamily?.userEquipmentFamily
            ) {
                inventory.equipmentFamily = checkup.equipmentFamily
            }
        }
        return inventory
    }

    _mountInventory({ checkup = null, part = null, equipment = null }) {
        const { arrayFor } = this.props
        let inventory = {
            equipmentFamily: null,
            equipment: null,
            parts: [],
        }

        if (checkup) inventory = this._mountFromCheckup(checkup, inventory)
        if (arrayFor !== `CHECKUP` && part?.equipment) {
            inventory.equipmentFamily = part.equipment.equipmentFamily
            inventory.equipment =
                part?.equipment &&
                (part.equipment.equipmentType || part.equipment.userEquipment)
                    ? part.equipment
                    : null
            inventory.parts = part?.partType || part?.userPart ? [part] : []
        }

        if (arrayFor !== `CHECKUP` && equipment) {
            inventory.equipmentFamily = equipment.equipmentFamily
            inventory.equipment =
                equipment &&
                (equipment.equipmentType || equipment.userEquipment)
                    ? equipment
                    : null
        }
        this.setState({ inventory: inventory })
    }

    _getCleanParts() {
        const inventory = JSON.parse(JSON.stringify(this.state.inventory))
        const { arrayFor } = this.props
        if (inventory && inventory.parts && inventory.parts.length > 0)
            return inventory.parts.map(part => {
                inventory.equipment = {
                    ...inventory.equipment,
                    equipmentFamily: inventory.equipmentFamily,
                }
                part = { ...part, equipment: inventory.equipment }
                return part
            })
        else if (
            inventory &&
            inventory.equipment &&
            (arrayFor === 'INVENTORY_PART' || arrayFor === 'ORDER')
        ) {
            return [
                {
                    equipment: inventory.equipment,
                },
            ]
        }
    }

    _getCleanEquipments() {
        const { isEngineInSelection } = this.props
        const inventory = JSON.parse(JSON.stringify(this.state.inventory))
        if (
            inventory?.equipment?.id === 1 &&
            isEngineInSelection === false &&
            this.props?.handleUpdateEngineSelection
        ) {
            this.props.handleUpdateEngineSelection(true) // open the engine selection input
        } else if (this.props?.handleUpdateEngineSelection) {
            this.props.handleUpdateEngineSelection(false)
        }
        return (inventory.equipment = {
            ...inventory.equipment,
            equipmentFamily: inventory.equipmentFamily,
        })
    }

    _generateCheckupForApi() {
        const inventory = JSON.parse(JSON.stringify(this.state.inventory))
        let checkup = JSON.parse(JSON.stringify(this.state.checkup))
        if (
            inventory.equipment &&
            inventory.parts &&
            inventory.parts.length === 0
        ) {
            const equipment = this._getCleanEquipments()
            checkup = {
                ...checkup,
                equipmentFamily: inventory.equipmentFamily,
                equipments: equipment ? [this._getCleanEquipments()] : [],
                parts: [],
            }
        } else if (inventory.parts) {
            const parts = this._getCleanParts()
            checkup = {
                ...checkup,
                equipmentFamily: inventory.equipmentFamily,
                parts: parts || [],
                equipments: [],
            }
        }

        return checkup
    }

    _unmountInventory() {
        const { arrayFor } = this.props
        const checkup = this.state.checkup
            ? this._generateCheckupForApi()
            : {
                  checkupType: null,
                  equipments: [],
                  goFromBoaton: null,
                  id: null,
                  parts: [],
                  userCheckup: null,
              }
        if (arrayFor === 'CHECKUP')
            return {
                checkup: checkup,
            }
        else if (arrayFor === 'INVENTORY_EQUIPMENT') {
            return {
                equipment: this._getCleanEquipments(),
            }
        } else if (arrayFor === 'INVENTORY_PART') {
            const parts = this._getCleanParts()
            return {
                part: parts && parts.length > 0 ? parts[0] : [],
            }
        } else if (arrayFor === 'ORDER') {
            const parts = this._getCleanParts()
            return {
                equipment: this._getCleanEquipments(),
                part: parts && parts.length > 0 ? parts[0] : [],
            }
        }
    }

    handleAddEquipment() {
        this.props.handleOpenModal(`equipmentAdd`)
        this.setState({ modalOpen: `equipmentAdd` })
    }

    conditionAlreadySet = alreadySet => option => {
        return alreadySet.some(element => option.id && element.id === option.id)
    }

    _getAlreadyEngineSet() {
        let userEquipments = JSON.parse(
            JSON.stringify(this.props.userEquipments),
        )
        const { inventories } = this.props

        userEquipments = userEquipments.filter(userEquipment => {
            if (!inventories || !inventories.equipmentFamilies) return false
            let toKeep = false
            Object.entries(inventories.equipmentFamilies).forEach(family => {
                if (family[1] && family[1].equipments) {
                    Object.entries(family[1].equipments).forEach(equipment => {
                        if (
                            !toKeep &&
                            userEquipment.id === parseInt(equipment[0]) &&
                            equipment[1].event
                        )
                            toKeep = true
                    })
                }
            })
            return toKeep
        })
        return userEquipments
    }

    _allEngineAlreadySet(equipments) {
        const engineSet = { 1: false, 2: false, 3: false }
        equipments.forEach(equipment => {
            if (parseInt(equipment[0]) === 1) engineSet[1] = true
            if (parseInt(equipment[0]) === 2) engineSet[2] = true
            if (parseInt(equipment[0]) === 3) engineSet[3] = true
        })
        return engineSet[1] && engineSet[2] && engineSet[3]
    }

    _getAlreadySetEquipment() {
        let userEquipments = JSON.parse(
            JSON.stringify(this.props.userEquipments),
        )
        const { inventories } = this.props

        userEquipments = userEquipments.filter(userEquipment => {
            if (!inventories || !inventories.equipmentFamilies) return false
            let toKeep = false
            Object.entries(inventories.equipmentFamilies).forEach(family => {
                if (family[1] && family[1].equipments) {
                    Object.entries(family[1].equipments).forEach(equipment => {
                        if (
                            !toKeep &&
                            userEquipment.id === parseInt(equipment[0]) &&
                            equipment[1].event
                        )
                            toKeep = true
                    })
                }
            })
            return toKeep
        })
        return userEquipments
    }

    _getAlreadySetEquipmentMultiEngine() {
        let userEquipments = JSON.parse(
            JSON.stringify(this.props.userEquipments),
        )
        const { inventories } = this.props

        userEquipments = userEquipments.filter(userEquipment => {
            if (!inventories || !inventories.equipmentFamilies) return false
            let toKeep = false
            Object.entries(inventories.equipmentFamilies).forEach(family => {
                if (family[1] && family[1].equipments) {
                    Object.entries(family[1].equipments).forEach(equipment => {
                        if (
                            !toKeep &&
                            userEquipment.id === parseInt(equipment[0]) &&
                            equipment[1].event &&
                            this._allEngineAlreadySet(
                                Object.entries(family[1].equipments),
                            )
                        )
                            toKeep = true
                    })
                }
            })
            return toKeep
        })
        return userEquipments
    }

    _getAlreadySetPart() {
        let userParts = JSON.parse(JSON.stringify(this.props.userParts))
        const { inventories } = this.props

        userParts = userParts.filter(userPart => {
            if (!inventories || !inventories.equipmentFamilies) return false
            let toKeep = false
            Object.entries(inventories.equipmentFamilies).forEach(family => {
                if (family[1] && family[1].equipments) {
                    Object.entries(family[1].equipments).forEach(equipment => {
                        if (equipment[1] && equipment[1].parts) {
                            Object.entries(equipment[1].parts).forEach(part => {
                                if (
                                    !toKeep &&
                                    userPart.id === parseInt(part[0])
                                )
                                    toKeep = true
                            })
                        }
                    })
                }
            })
            return toKeep
        })
        return userParts
    }

    _renderEngineSelection(nameTextfield) {
        const { classes, error, isEngineInSelection } = this.props
        return (
            <BoatOnAutoComplete
                fullWidth
                required
                multiple
                autoSelect
                margin="normal"
                options={nameTextfield.engines || []}
                filterOptions={(option, input) =>
                    fncDeepFilterOptions(option, input, this.getContext())
                }
                getElementToShow={option => {
                    const name = this.getName(option)
                        .replace('Moteur ', '')
                        .replace('Engine ', '')
                        .trim()
                    if (name === '') return '-'
                    return name
                }}
                conditionAlready={nameTextfield.conditionEngineAlreadySet}
                renderTags={this.renderTags}
                onChange={this.handleTagsChange}
                addProps={{
                    'data-type': nameTextfield.type,
                }}
                label={this.displayText('engineSpecification')}
                id={nameTextfield.type}
                inputClass={classes.engineTextfield}
                error={isEngineInSelection && error}
                helperText={
                    isEngineInSelection &&
                    error &&
                    this.displayText('errorEngineSelection')
                }
            />
        )
    }

    _renderAutoComplete(nameTextfield) {
        const {
            classes,
            error,
            eventFix,
            margin,
            subscriptions,
            arrayFor,
            isEngineInSelection,
        } = this.props

        return (
            <div style={{ gap: 15, width: '100%', display: 'flex' }}>
                <BoatOnAutoComplete
                    disabled={eventFix || nameTextfield.disable}
                    margin={margin}
                    multiple
                    required={nameTextfield.required}
                    fullWidth
                    autoSelect
                    groupBy={nameTextfield.groupBy}
                    addButton={nameTextfield.button ? true : false}
                    value={[...nameTextfield.selected]}
                    conditionAlready={nameTextfield.conditionAlreadySet}
                    options={nameTextfield.autoComplete || []}
                    label={nameTextfield.text}
                    inputClass={classes.textField}
                    id={nameTextfield.type}
                    onChange={this.handleTagsChange}
                    labelAdd={nameTextfield.button}
                    addProps={{
                        'data-type': nameTextfield.type,
                    }}
                    filterOptions={(option, input) =>
                        fncDeepFilterOptions(option, input, this.getContext())
                    }
                    onAddButtonClicked={this.handleAddEquipment}
                    getElementToShow={this.getName}
                    error={
                        !(
                            nameTextfield.selected &&
                            Array.isArray(nameTextfield.selected) &&
                            nameTextfield.selected.length > 0
                        ) &&
                        error &&
                        nameTextfield.error
                    }
                    helperText={
                        !(
                            nameTextfield.selected &&
                            Array.isArray(nameTextfield.selected) &&
                            nameTextfield.selected.length > 0
                        ) && error
                            ? nameTextfield.error
                            : ''
                    }
                    limitTags={nameTextfield.limitTags}
                    renderTags={this.renderTags}
                    freeSolo={
                        userActions.checkSubscriptionPage(subscriptions, 6) ||
                        arrayFor === 'CHECKUP'
                    }
                    subscriptions={subscriptions}
                    noOptionsText={
                        arrayFor !== 'CHECKUP'
                            ? this.displayText('noOption')
                            : null
                    }
                />
                {isEngineInSelection &&
                    arrayFor === 'INVENTORY_EQUIPMENT' &&
                    nameTextfield.type === 'EQUIPMENT' &&
                    this._renderEngineSelection(nameTextfield)}
            </div>
        )
    }

    renderSelectAll(displayedElements) {
        const { classes } = this.props
        const selected = JSON.parse(JSON.stringify(this.props.selected))

        if (displayedElements.length > 0)
            return (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Divider />
                    <div
                        onClick={() => {
                            if (
                                selected.filter(select =>
                                    displayedElements.some(
                                        element => element.id === select.id,
                                    ),
                                ).length === displayedElements.length
                            )
                                this.props.onTagsChange(
                                    selected.filter(
                                        select =>
                                            !displayedElements.some(
                                                element =>
                                                    element.id === select.id,
                                            ),
                                    ),
                                )
                            else this.props.onTagsChange(displayedElements)
                        }}
                        className={classes.rootNotAttributed}
                    >
                        <Checkbox
                            style={{ marginRight: 8 }}
                            color="primary"
                            checked={
                                selected.filter(select =>
                                    displayedElements.some(
                                        element => element.id === select.id,
                                    ),
                                ).length === displayedElements.length
                            }
                        ></Checkbox>
                        <Typography>{this.displayText('selectAll')}</Typography>
                    </div>
                </div>
            )
        return <></>
    }

    updateSelectedBoats(values) {
        if (!values.find(value => value.selectAll)) {
            this.props.onTagsChange(values)
        }
    }

    renderAutoCompleteBoat(nameTextfield) {
        const { classes, required = true } = this.props
        const boats = JSON.parse(JSON.stringify(this.props.boats))
        const selected = JSON.parse(JSON.stringify(this.props.selected))

        return (
            <div style={{ marginBottom: 10, width: '100%' }}>
                <Autocomplete
                    multiple
                    readOnly
                    classes={{
                        popupIndicatorOpen: classes.popupIndicatorOpen,
                        option: classes.option,
                        paper: classes.paper,
                        focused: classes.focused,
                        groupLabel: classes.groupLabel,
                        listbox: classes.listbox,
                    }}
                    id="boatselect"
                    options={[
                        ...boats,
                        {
                            selectAll: <></>,
                            name: this.displayText('selectAll'),
                        },
                    ]}
                    disableCloseOnSelect
                    onChange={(_, newValue) => {
                        this.updateSelectedBoats(newValue)
                    }}
                    getOptionSelected={(option, value) =>
                        option.id === value.id
                    }
                    renderOption={option =>
                        option.selectAll ? (
                            option.selectAll
                        ) : (
                            <div className={classes.optionDiv}>
                                <Checkbox
                                    style={{ marginRight: 8, marginLeft: 20 }}
                                    color="primary"
                                    checked={selected.some(
                                        select => select.id === option.id,
                                    )}
                                />
                                {option.name}
                            </div>
                        )
                    }
                    style={{ width: '100%' }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="outlined"
                            margin="normal"
                            label={nameTextfield.text}
                            style={{ margin: 0 }}
                            required={required}
                            error={this.props.error === true}
                            helperText={
                                this.props.error === true &&
                                this.props.helperText !== ''
                                    ? this.props.helperText
                                    : ''
                            }
                        />
                    )}
                    getOptionLabel={option => option.name ?? ''}
                    filterOptions={(options, input) => {
                        const newOptions = [
                            ...options.filter(
                                option =>
                                    option.name
                                        .toLowerCase()
                                        .includes(
                                            input.inputValue.toLowerCase(),
                                        ) || option.selectAll,
                            ),
                        ]

                        newOptions[
                            newOptions.length - 1
                        ].selectAll = this.renderSelectAll(
                            newOptions.filter(option => !option.selectAll),
                        )

                        return newOptions
                    }}
                    popupIcon={<SearchIcon style={{ color: '#5F5F5F' }} />}
                    value={selected}
                    renderTags={(_, getTagProps) =>
                        selected.map((option, index) => (
                            <Chip
                                size="medium"
                                variant="outlined"
                                style={{
                                    backgroundColor: '#fcd48e',
                                }}
                                {...getTagProps({ index })}
                                label={option.name}
                            />
                        ))
                    }
                    limitTags={nameTextfield.limitTags}
                />
            </div>
        )
    }

    _groupBy(option, type) {
        const { checkup, inventory } = this.state
        const duplicates = JSON.parse(JSON.stringify(this.state.duplicates))
        if (type === `EQUIPMENTFAMILY` && checkup?.userCheckup?.id) {
            const found = duplicates[checkup.userCheckup.id]?.filter(
                duplicate =>
                    duplicate?.equipments.some(
                        equipment =>
                            equipment?.equipmentFamily?.id === option.id,
                    ) ||
                    duplicate?.parts.some(
                        part =>
                            part?.equipment?.equipmentFamily?.id === option.id,
                    ),
            )
            if (found?.length > 0) return this.displayText(`alreadyLinked`)
        } else if (
            type === `EQUIPMENT` &&
            inventory.equipmentFamily &&
            checkup?.userCheckup?.id
        ) {
            const found = duplicates[checkup.userCheckup.id]?.filter(
                duplicate =>
                    duplicate?.equipments.some(
                        equipment => equipment?.id === option.id,
                    ) ||
                    duplicate?.parts.some(
                        part => part?.equipment?.id === option.id,
                    ),
            )
            if (found?.length > 0) return this.displayText(`alreadyLinked`)
        } else if (
            type === `PART` &&
            inventory.equipment &&
            checkup?.userCheckup?.id
        ) {
            const found = duplicates[
                checkup.userCheckup.id
            ]?.filter(duplicate =>
                duplicate?.parts.some(part => part?.id === option.id),
            )
            if (found?.length > 0) return this.displayText(`alreadyLinked`)
        }
        return this.displayText(`others`)
    }

    _arrayCheckup() {
        const { userEquipmentFamilies } = this.props
        const { checkup, userCheckups, duplicates } = this.state
        const inventory = JSON.parse(JSON.stringify(this.state.inventory))

        if (!inventory) return <></>

        const { equipmentFamily } = inventory
        const { equipment } = inventory
        const { parts } = inventory
        return [
            {
                type: 'CHECKUP',
                limitTags: 1,
                text: this.displayText('operation'),
                autoComplete: userCheckups,
                error: this.displayText('errorCheckup'),
                selected:
                    checkup && (checkup.userCheckup || checkup.checkupType)
                        ? [checkup]
                        : [],
                disable: null,
                required: true,
            },
            {
                type: 'EQUIPMENTFAMILY',
                limitTags: 1,
                text: this.displayText('chooseFamilyEquipment'),
                autoComplete: userEquipmentFamilies.sort(family => {
                    if (!checkup?.userCheckup?.id || !duplicates) return -1
                    const found = duplicates[checkup.userCheckup.id]?.filter(
                        duplicate =>
                            duplicate?.equipments.some(
                                equipment =>
                                    equipment?.equipmentFamily?.id ===
                                    family.id,
                            ) ||
                            duplicate?.parts.some(
                                part =>
                                    part?.equipment?.equipmentFamily?.id ===
                                    family.id,
                            ),
                    )
                    if (found?.length > 0) return 0
                    else return 1
                }),
                error: this.displayText('errorEquipmentFamily'),
                selected: equipmentFamily ? [equipmentFamily] : [],
                disable: null,
                required: true,
                groupBy:
                    checkup?.userCheckup?.id &&
                    duplicates &&
                    duplicates[checkup.userCheckup.id]
                        ? option => this._groupBy(option, 'EQUIPMENTFAMILY')
                        : null,
            },
            {
                type: 'EQUIPMENT',
                limitTags: 1,
                text: this.displayText('chooseEquipment'),
                autoComplete: this._filterEquipments().equipments.sort(
                    equipmentCheck => {
                        if (!checkup?.userCheckup?.id || !duplicates) return -1
                        const found = duplicates[
                            checkup.userCheckup.id
                        ]?.filter(
                            duplicate =>
                                duplicate?.equipments.some(
                                    equipment =>
                                        equipment?.id === equipmentCheck.id,
                                ) ||
                                duplicate?.parts.some(
                                    part =>
                                        part?.equipment?.id ===
                                        equipmentCheck.id,
                                ),
                        )
                        if (found?.length > 0) return 0
                        else return 1
                    },
                ),
                error: this.displayText('errorEquipment'),
                selected: equipment ? [equipment] : [],
                disable: equipmentFamily === null,
                required: true,
                groupBy:
                    checkup?.userCheckup?.id &&
                    duplicates &&
                    duplicates[checkup.userCheckup.id]
                        ? option => this._groupBy(option, 'EQUIPMENT')
                        : null,
            },
            {
                type: 'PART',
                limitTags: -1,
                text: this.displayText('choosePart'),
                autoComplete: this._filterParts().sort(partCheck => {
                    if (!checkup?.userCheckup?.id || !duplicates) return -1
                    const found = duplicates[
                        checkup.userCheckup.id
                    ]?.filter(duplicate =>
                        duplicate?.parts.some(
                            part => part?.id === partCheck.id,
                        ),
                    )
                    if (found?.length > 0) return 0
                    else return 1
                }),
                error: false,
                selected: parts ? parts : [],
                disable: equipment === null,
                conditionAlreadySet: this.conditionAlreadySet(parts),
                groupBy:
                    checkup?.userCheckup?.id &&
                    duplicates &&
                    duplicates[checkup.userCheckup.id]
                        ? option => this._groupBy(option, 'PART')
                        : null,
            },
        ]
    }

    _arrayInventoryEquipment() {
        let { userEquipmentFamilies, subscriptions } = this.props
        const { inventory } = this.state

        if (!inventory) return <></>

        const { equipmentFamily } = inventory
        const { equipment } = inventory

        if (!userActions.checkSubscriptionPage(subscriptions, 6)) {
            userEquipmentFamilies = userEquipmentFamilies.filter(e =>
                e.type?.translation?.fr.toLowerCase().includes(`moteur`),
            )
            userEquipmentFamilies.push({
                button: true,
                element: this._getCallToAction(`callToActionFamily`),
            })
        }

        const filterEquipment = this._filterEquipments(true)

        return [
            {
                type: 'EQUIPMENTFAMILY',
                limitTags: 1,
                autoComplete: this.isSailingBoat(userEquipmentFamilies),
                title: this.displayText('equipmentFamily'),
                text: this.displayText('chooseFamilyEquipment'),
                error: this.displayText('errorEquipmentFamilyInv'),
                selected: equipmentFamily ? [equipmentFamily] : [],
                disable: null,
                conditionAlreadySet: null,
                required: true,
            },
            {
                type: 'EQUIPMENT',
                limitTags: 1,
                autoComplete: filterEquipment.equipments,
                text: this.displayText('chooseEquipment'),
                title: this.displayText('equipment'),
                error: this.displayText('errorEquipmentInv'),
                selected: equipment ? [equipment] : [],
                disable: equipmentFamily === null,
                conditionAlreadySet: this.conditionAlreadySet(
                    this._getAlreadySetEquipment(),
                ),
                required: true,
                engines: filterEquipment.engines,
                conditionEngineAlreadySet: this.conditionAlreadySet(
                    this._getAlreadyEngineSet(),
                ),
            },
        ]
    }

    _arrayBoatsInUserGroup() {
        let { boats, selected, overrideLabel } = this.props

        return [
            {
                type: 'BOATS',
                autoComplete: boats,
                title: this.displayText('boat'),
                text: overrideLabel
                    ? overrideLabel
                    : this.displayText('chooseBoat'),
                selected: selected ? selected : [],
                disable: null,
                conditionAlreadySet: null,
                required: false,
            },
        ]
    }

    _arrayInventoryPart() {
        const { inventory } = this.state

        if (!inventory) return <></>

        const { equipment, parts } = inventory
        return [
            {
                type: 'EQUIPMENT',
                limitTags: 1,
                autoComplete: this._filterEquipments(true).equipments,
                text: this.displayText('chooseEquipment'),
                title: this.displayText('equipment'),
                error: this.displayText('errorEquipmentInv'),
                selected: equipment ? [equipment] : [],
                button: `${this.displayText('addEquipment')}`,
                conditionAlreadySet: null,
                required: true,
            },
            {
                type: 'PART',
                limitTags: 1,
                title: this.displayText('parts'),
                autoComplete: this._filterParts(),
                text: this.displayText('choosePart'),
                error: this.displayText('errorPartsInv'),
                selected: parts ? parts : [],
                disable: equipment === null,
                conditionAlreadySet: this.conditionAlreadySet(
                    this._getAlreadySetPart(),
                ),
                required: true,
            },
        ]
    }

    _arrayOrder() {
        const { inventory } = this.state
        let { userEquipmentFamilies, subscriptions } = this.props
        if (!inventory) return <></>
        let equipmentFamily = null,
            equipment = null,
            parts = []

        equipmentFamily = inventory.equipmentFamily
        equipment = inventory.equipment
        parts = inventory.parts

        if (
            this.props.getElementByInput &&
            (JSON.stringify(this.props.elementByInput?.equipmentFamily) !==
                JSON.stringify(equipmentFamily) ||
                JSON.stringify(this.props.elementByInput?.equipment) !==
                    JSON.stringify(equipment) ||
                JSON.stringify(this.props.elementByInput?.parts) !==
                    JSON.stringify(parts))
        ) {
            this.props.getElementByInput({
                equipmentFamily: equipmentFamily,
                equipment: equipment,
                parts: parts,
            })
        }

        if (!userActions.checkSubscriptionPage(subscriptions, 6)) {
            userEquipmentFamilies = userEquipmentFamilies.filter(e =>
                e.type?.translation?.fr.toLowerCase().includes(`moteur`),
            )
            userEquipmentFamilies.push({
                button: true,
                element: this._getCallToAction(`callToActionFamily`),
            })
        }

        return [
            {
                type: 'EQUIPMENTFAMILY',
                limitTags: 1,
                autoComplete: this.isSailingBoat(userEquipmentFamilies),
                title: this.displayText('equipmentFamily'),
                text: this.displayText('chooseFamilyEquipment'),
                error: this.displayText('errorEquipmentFamilyInv'),
                selected: equipmentFamily ? [equipmentFamily] : [],
                disable: null,
                conditionAlreadySet: null,
                required: true,
            },
            {
                type: 'EQUIPMENT',
                limitTags: 1,
                autoComplete: this._filterEquipments(true).equipments,
                text: this.displayText('chooseEquipment'),
                title: this.displayText('equipment'),
                error: this.displayText('errorEquipmentInv'),
                selected: equipment ? [equipment] : [],
                conditionAlreadySet: null,
                required: true,
                disable: equipmentFamily === null,
            },
            {
                type: 'PART',
                limitTags: 1,
                title: this.displayText('parts'),
                autoComplete: this._filterParts(),
                text: this.displayText('choosePart'),
                error: this.displayText('errorPartsInv'),
                selected: parts ? parts : [],
                disable: equipment === null,
                conditionAlreadySet: this.conditionAlreadySet(
                    this._getAlreadySetPart(),
                ),
                required: true,
            },
        ]
    }

    // for show fittings and rigging if your boat are sailer
    isSailingBoat(userEquipmentFamilies) {
        if (userEquipmentFamilies) {
            const { boat } = this.props
            if (boat.boatType.id !== 2) {
                return userEquipmentFamilies.filter(
                    equipmentFamily => equipmentFamily?.type?.id !== 18,
                )
            }
            return userEquipmentFamilies
        }
        return []
    }

    renderInventoryCheckup() {
        const {
            classes,
            userCheckups,
            userEquipmentFamilies,
            userEquipments,
            userParts,
            arrayFor,
            events,
        } = this.props
        let nameTextfields = []

        if (
            !userCheckups ||
            !userEquipmentFamilies ||
            !userEquipments ||
            !userParts ||
            !arrayFor ||
            !events
        )
            return this.renderLoading(`100px`, `100px`)

        if (arrayFor === 'CHECKUP') nameTextfields = this._arrayCheckup()
        else if (arrayFor === 'INVENTORY_EQUIPMENT')
            nameTextfields = this._arrayInventoryEquipment()
        else if (arrayFor === 'INVENTORY_PART')
            nameTextfields = this._arrayInventoryPart()
        else if (arrayFor === 'ORDER') nameTextfields = this._arrayOrder()

        return (
            <div>
                {nameTextfields.map((nameTextfield, index) => (
                    <div className={classes.root} key={index}>
                        {this._renderAutoComplete(nameTextfield)}
                    </div>
                ))}
                {this.state.modalOpen === `callToAction` && (
                    <BoatOnModal
                        openCondition={this.state.modalOpen}
                        handleClose={this.handleCloseModal}
                        modalCores={{
                            callToAction: (
                                <div
                                    style={{
                                        height: 150,
                                        padding: 15,
                                        textAlign: `center`,
                                        display: `flex`,
                                        alignItems: `center`,
                                        justifyContents: `center`,
                                        flexDirection: `column`,
                                    }}
                                >
                                    <p> {this.displayText(`bobSub`)} </p>
                                    <div
                                        style={{
                                            display: `flex`,
                                            alignItems: `center`,
                                            gap: 15,
                                        }}
                                    >
                                        <ButtonBON
                                            label={this.displayText(`cancel`)}
                                            type={`cancel`}
                                            onClick={this.handleCloseModal}
                                        />
                                        <ButtonBON
                                            label={this.displayText(`more`)}
                                            onClick={this._redirectPricing}
                                        />
                                    </div>
                                </div>
                            ),
                        }}
                        titles={{
                            callToAction: this.displayText(`editRepair`),
                        }}
                        maxWidth={{
                            callToAction: `sm`,
                        }}
                    />
                )}
            </div>
        )
    }

    renderUserGroup() {
        const { classes, boats } = this.props
        let nameTextfields = []
        if (!boats) return this.renderLoading(`100px`, `100px`)

        nameTextfields = this._arrayBoatsInUserGroup()

        return (
            <div>
                {nameTextfields.length > 0 ? (
                    nameTextfields.map((nameTextfield, index) => (
                        <div className={classes.rootBoat} key={index}>
                            {this.renderAutoCompleteBoat(nameTextfield)}
                        </div>
                    ))
                ) : (
                    <div className={classes.root} key={-1}>
                        {this.displayText(`noBoatSelected`)}
                    </div>
                )}
            </div>
        )
    }

    render() {
        const { boats } = this.props
        if (boats) {
            return this.renderUserGroup()
        } else {
            return this.renderInventoryCheckup()
        }
    }
}

function mapStateToProps(state) {
    return {
        eventTypes: typesActions.getEventTypeCheckup(state.types.eventTypes),
        events:
            state.bob.events && state.bob.loading === 0
                ? state.bob.events.filter(event => event.detail)
                : null,
        userCheckups: state.types.userCheckups,
        userEquipmentFamilies: state.types.userEquipmentFamilies,
        userEquipments: state.types.userEquipments,
        userParts: state.types.userParts,
        boat: state.bob.boat,
        loadingType: state.types.loading,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        newEvent: state.bob.newEvent,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(InputLabel))
