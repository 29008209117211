export default theme => ({
    footer: {
        padding: `16px 24px`,
        alignItems: `center`,
        justifyContent: 'flex-end',
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: 0,
    },
    warningBubble: {
        padding: `16px 10px`,
        backgroundColor: '#9CB8E31A',
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        border: `1px solid #9CB8E3`,
    },
    warningBubble__p: {
        marginLeft: 16,
        color: '#303F9F',
    },
    warningBubble__icon: {
        color: '#303F9F',
    },
})
