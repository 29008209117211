import { redirectConstants } from '../constants'
import { requestApi } from '../services/api.service'

const saveRedirectionParams = params => {
    return dispatch => {
        dispatch({
            type: redirectConstants.SAVE_REDIRECTION_PARAMS,
            params,
        })
    }
}

const resetRedirectionParams = () => {
    return dispatch => {
        dispatch({
            type: redirectConstants.CLEAR_REDIRECTION_PARAMS,
        })
    }
}

const getBobEventByIdForRedirect = eventId => {
    return dispatch => {
        dispatch({ type: redirectConstants.GET_EVENT_BY_ID })
        return requestApi(`/bob-events/${eventId}`, `GET`).then(
            result => {
                dispatch({
                    type: redirectConstants.GET_EVENT_BY_ID_SUCCESS,
                    event: result,
                })
            },
            error => {
                console.error(error)
                dispatch({
                    type: redirectConstants.GET_EVENT_BY_ID_FAILURE,
                })
            },
        )
    }
}

const getAbsenceByIdForRedirect = absenceId => {
    return dispatch => {
        dispatch({ type: redirectConstants.GET_EVENT_BY_ID })
        return requestApi(`/user-absences/${absenceId}`, `GET`).then(
            result => {
                dispatch({
                    type: redirectConstants.GET_EVENT_BY_ID_SUCCESS,
                    event: result,
                })
            },
            error => {
                console.error(error)
                dispatch({
                    type: redirectConstants.GET_EVENT_BY_ID_FAILURE,
                })
            },
        )
    }
}

const getWorkTimeByIdForRedirect = workTimeId => {
    return dispatch => {
        dispatch({ type: redirectConstants.GET_EVENT_BY_ID })
        return requestApi(`/work-times/${workTimeId}`, `GET`).then(
            result => {
                dispatch({
                    type: redirectConstants.GET_EVENT_BY_ID_SUCCESS,
                    event: result,
                })
            },
            error => {
                console.error(error)
                dispatch({
                    type: redirectConstants.GET_EVENT_BY_ID_FAILURE,
                })
            },
        )
    }
}

export const redirectActions = {
    saveRedirectionParams,
    resetRedirectionParams,
    getBobEventByIdForRedirect,
    getAbsenceByIdForRedirect,
    getWorkTimeByIdForRedirect,
}
