export const trainingConstants = {
    REQUEST_TRAINING: 'REQUEST_TRAINING',
    POST_TRAINING_SUCCESS: 'POST_TRAINING_SUCCESS',
    POST_TRAINING_FAILURE: 'POST_TRAINING_FAILURE',

    GET_ALL_TRAINING_SUCCESS: 'GET_ALL_TRAINING_SUCCESS',
    GET_ALL_TRAINING_FAILURE: 'GET_ALL_TRAINING_FAILURE',

    DELETE_TRAINING_SUCCESS: 'DELETE_TRAINING_SUCCESS',
    DELETE_TRAINING_FAILURE: 'DELETE_TRAINING_FAILURE',

    PUT_TRAINING_SUCCESS: 'PUT_TRAINING_SUCCESS',
    PUT_TRAINING_FAILURE: 'PUT_TRAINING_FAILURE',
}
