import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import { TextField, MenuItem, InputAdornment } from '@material-ui/core'
import BoatOnModalCore from '../BoatOnModalCore'
import styles from './Styles/RenderBoatCreateCss'
import dictionary from './Dictionary/RenderBoatCreateDico'
import AutoComplete from '../AutoComplete'
import AddressSearch from './AddressSearch'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import { boatActions } from '../../../actions/boat.actions'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import DirectionsBoatIcon from '@material-ui/icons/DirectionsBoat'
import InfoIcon from '@material-ui/icons/Info'
import EventIcon from '@material-ui/icons/Event'
import { typesActions } from '../../../actions'
import BoatOnModal from '../BoatOnModal'
import BoatOnAlert from './BoatOnAlert'
import BoatOnLoading from './BoatOnLoading'

class RenderBoatCreate extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            boatNameCreate: '',
            makerCreate: '',
            modelCreate: '',
            yearCreate: '1999',
            boatTypeCreate: -1,
            address: '',
            searchResult: [],
            searchResultMaker: [],
            focused: false,
            error: false,
            open: null,
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleChangeYear = this.handleChangeYear.bind(this)
        this.handleListSelection = this.handleListSelection.bind(this)
        this.setAddress = this.setAddress.bind(this)
        this.valid = this.valid.bind(this)
        this.checkError = this.checkError.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleOpenModalGeneration = this.handleOpenModalGeneration.bind(
            this,
        )
    }

    componentDidMount() {
        const { boatTypes, loadingTypes } = this.props
        if (!boatTypes && !loadingTypes) {
            this.props.dispatch(typesActions.requestBoatTypes())
        }
    }

    handleOpenModalGeneration(e) {
        e.preventDefault()
        this.setState({ open: 'infoGeneration' })
    }

    handleClose() {
        if (this.state.open === 'infoGeneration') {
            this.setState({ open: null })
            this.valid()
        }
    }

    handleSearchList(value, key) {
        if (!value) {
            // no value no results
            return this.setState({ searchResult: [], modelCreate: value })
        }

        const { listBoats } = this.props
        let matches = []
        if (key === 'modelCreate') {
            for (let i in listBoats.boatModels) {
                if (
                    listBoats.boatModels[i].libelleModeleBateau
                        .toLowerCase()
                        .includes(value.toLowerCase())
                ) {
                    matches.push(listBoats.boatModels[i])
                }
            }
            this.setState({
                searchResult: matches.length < 50 ? matches : [],
                modelCreate: value,
            })
        } else {
            for (let i in listBoats.boatModels) {
                if (
                    listBoats.boatModels[i].libelleConstructeur
                        .toLowerCase()
                        .includes(value.toLowerCase())
                ) {
                    matches.push(listBoats.boatModels[i].libelleConstructeur)
                }
            }
            matches = [...new Set(matches)]
            this.setState({
                searchResultMaker: matches.length < 50 ? matches : [],
                makerCreate: value,
            })
        }
    }

    handleChange(value, key) {
        this.setState({ focused: key })
        /*if (key === 'modelCreate' || key === 'makerCreate')
            this.handleSearchList(value, key)*/
        this.setState({ [key]: value })
    }

    handleChangeYear(value, key) {
        this.setState({ focused: key })
        this.setState({ [key]: value.getFullYear().toString() })
    }

    setAddress(address) {
        this.setState({
            address: address,
        })
    }

    handleListSelection(value, type) {
        if (value.libelleGenre === 'Bateau à moteur / jetski')
            value.libelleGenre = this.state.boatTypeCreate

        if (type === 'maker') {
            this.setState({
                makerCreate: value,
                searchResultMaker: [],
            })
        } else {
            this.setState({
                modelCreate: value.libelleModeleBateau,
                boatTypeCreate: value.libelleGenre,
                makerCreate: value.libelleConstructeur,
                searchResult: [],
            })
        }
    }

    checkError() {
        const { boatTypeCreate } = this.state
        if (boatTypeCreate === -1) {
            this.setState({ error: true })
        }
    }

    async valid() {
        const { valid, groupId, user, addToOwnGroupId } = this.props
        const {
            boatNameCreate,
            makerCreate,
            modelCreate,
            yearCreate,
            boatTypeCreate,
            address,
        } = this.state
        if (boatTypeCreate !== -1) {
            this.setState({ focused: false })
            const serialNumber = null

            this.setState({
                open: 'loadingBoat',
            })

            const resultBoat = await this.props.dispatch(
                boatActions.postNewBoatToGroup(user, {
                    boatNameCreate,
                    makerCreate,
                    modelCreate,
                    yearCreate,
                    boatTypeCreate,
                    address,
                    serialNumber,
                    groupId: addToOwnGroupId || groupId,
                }),
            )
            if (
                resultBoat &&
                Array.isArray(resultBoat) &&
                resultBoat.length > 0
            ) {
                resultBoat.sort((a, b) => {
                    if (a.id < b.id) {
                        return -1
                    } else if (b.id < a.id) {
                        return 1
                    }
                    return 0
                })
                localStorage.setItem(
                    'boatIdSelect',
                    resultBoat[resultBoat.length - 1].id,
                )
                valid(false, resultBoat[resultBoat.length - 1].id)
            } else valid(false)
        } else {
            this.setState({ error: true })
        }
    }

    _body() {
        const { classes, boatTypes } = this.props

        return (
            <form
                id="form-boat"
                key="boat"
                onSubmit={this.handleOpenModalGeneration}
            >
                <div className={classes.modal}>
                    <TextField
                        id="boatName"
                        label={this.displayText('boatName')}
                        margin="normal"
                        placeholder="Albatros"
                        required
                        variant="outlined"
                        value={this.state.boatNameCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'boatNameCreate')
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AssignmentIndIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        id="type"
                        select
                        required
                        variant="outlined"
                        label={this.displayText('boatType')}
                        value={this.state.boatTypeCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'boatTypeCreate')
                        }}
                        margin="normal"
                        fullWidth
                        style={{
                            textAlign: 'left',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <DirectionsBoatIcon />
                                </InputAdornment>
                            ),
                        }}
                        error={
                            this.state.boatTypeCreate === -1 && this.state.error
                        }
                        helperText={
                            this.state.boatTypeCreate === -1 && this.state.error
                                ? this.displayText('errorType')
                                : ''
                        }
                    >
                        <MenuItem value={-1}>
                            <em>{this.displayText('chooseType')}</em>
                        </MenuItem>
                        {boatTypes.map(type => (
                            <MenuItem key={type.id} value={type.id}>
                                {this.displayTextApi(
                                    type.translation,
                                ).toLowerCase()}
                            </MenuItem>
                        ))}
                    </TextField>
                    <AutoComplete
                        type="maker"
                        searchResult={this.state.searchResultMaker}
                        handleListSelection={this.handleListSelection}
                    />
                    <TextField
                        id="maker"
                        label={this.displayText('boatMaker')}
                        margin="normal"
                        required
                        placeholder="Beneteau"
                        variant="outlined"
                        value={this.state.makerCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'makerCreate')
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <InfoIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <AutoComplete
                        searchResult={this.state.searchResult}
                        handleListSelection={this.handleListSelection}
                    />
                    <TextField
                        id="model"
                        label={this.displayText('boatModel')}
                        margin="normal"
                        required
                        placeholder="Sun Odyssey 40"
                        variant="outlined"
                        value={this.state.modelCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'modelCreate')
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <InfoIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                            autoOk
                            okLabel=""
                            cancelLabel=""
                            views={['year']}
                            label={this.displayText('boatYear')}
                            value={new Date(this.state.yearCreate)}
                            onChange={e => {
                                this.handleChangeYear(e, 'yearCreate')
                            }}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <EventIcon />
                                    </InputAdornment>
                                ),
                            }}
                            required
                            inputVariant="outlined"
                            placeholder="1999"
                            margin="normal"
                        />
                    </MuiPickersUtilsProvider>
                    <AddressSearch
                        id="port"
                        style={{
                            width: `100%`,
                            marginTop: '16px',
                            marginBottom: '8px',
                        }}
                        setAddress={this.setAddress}
                        parkingAddress={null}
                        fullAddressInfo={this.state.address?.fullText}
                        label={this.displayText('homePortOrStorage')}
                        placeholder="Port des Minimes, La Rochelle"
                        InputProps={{ margin: 'normal', fullWidth: true }}
                    />
                </div>
            </form>
        )
    }

    render() {
        const { title, loadingTypes, boatTypes } = this.props

        if (loadingTypes !== 0 || !boatTypes) {
            return <></>
        }

        return (
            <>
                {this._renderTitle(title)}
                {this._renderBody({
                    body: this._body(),
                })}
                {this._renderActions(
                    this.props.addNow
                        ? [
                              {
                                  label: this.displayText('createBoat'),
                                  action: this.checkError,
                                  form: `form-boat`,
                              },
                          ]
                        : [
                              {
                                  label: this.displayText('quit'),
                                  action: this.props.valid,
                                  type: `cancel`,
                              },
                              {
                                  label: this.displayText('createBoat'),
                                  form: `form-boat`,
                              },
                          ],
                )}
                <BoatOnModal
                    openCondition={this.state.open}
                    handleClose={this.handleClose}
                    modalCores={{
                        infoGeneration: (
                            <BoatOnModalCore
                                body={
                                    <BoatOnAlert
                                        severity="info"
                                        description={this.displayText(
                                            'generationInfo',
                                        )}
                                    />
                                }
                                actions={[
                                    {
                                        label: this.displayText('understood'),
                                        action: this.handleClose,
                                    },
                                ]}
                            />
                        ),
                        loadingBoat: (
                            <BoatOnModalCore
                                body={
                                    <BoatOnLoading
                                        // severity="info"
                                        description={this.displayText(
                                            'loadingInfo',
                                        )}
                                    />
                                }
                                noCross={true}
                            />
                        ),
                    }}
                    titles={{
                        infoGeneration: this.displayText('information'),
                        loadingBoat: this.displayText('loading'),
                    }}
                    maxWidth={{
                        infoGeneration: `sm`,
                        loadingBoat: 'sm',
                    }}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        state: state.open,
        listBoats: state.fetch.listboatmodels,
        boatTypes: state.types.boatTypes,
        loadingTypes: state.types.loading,
        groupId: state.group.currentGroupId,
        user: state.authentication.user,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(RenderBoatCreate))
