import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ReactPixel from 'react-facebook-pixel'
import dictionary from './Dictionary/ModalSubscribeDico'
import styles from './Styles/ModalSubscribeCss'
import { userActions, typesActions } from '../../../../actions'
import CreditCard from '../../../common/UsefullComponents/CreditCard'
import TextField from '@material-ui/core/TextField'
import chargement from '../../../../images/anchorLoader.gif'
import { Divider } from '@material-ui/core'
import BoatOnModalCore from '../../BoatOnModalCore'
import { injectStripe } from 'react-stripe-elements'
import pricesConfig from '../../../PricesPage/pricesConfig'
import { boatActions } from '../../../../actions/boat.actions'

class ModalSubscribe extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            send: false,
            reductionCode: '',
        }

        const options = {
            autoConfig: true,
            debug: false,
        }
        ReactPixel.init('181677145678471', options)
        this.handleChange = this.handleChange.bind(this)
        this.validate = this.validate.bind(this)
    }

    async componentDidMount() {
        if (
            this.props.groupId &&
            !this.props.numberBoats &&
            this.props.boatsLoading === 0
        ) {
            this.props.dispatch(
                boatActions.getFleetNumberBoat(this.props.groupId),
            )
        }
    }

    handleChange(value, key) {
        this.setState({ [key]: value })
    }

    async validate() {
        const { subscribeType, user } = this.props
        const result =
            this.state.reductionCode !== ''
                ? await typesActions.promotionOfferValide(
                      this.state.reductionCode,
                  )
                : null
        if (result !== 422) {
            this.setState({ send: true })
            let { token } = await this.props.stripe.createToken()
            if (!token) {
                alert(this.displayText('incorrectCard'))
                this.setState({ send: false })
            } else if (token.id) {
                ReactPixel.track('Log book subscription')
                this.props.dispatch(
                    userActions.setupSubscriptions(
                        token.id,
                        subscribeType,
                        user,
                        this.state.reductionCode,
                    ),
                )
            }
        } else {
            alert("Votre code promotionnel n'est pas valide")
        }
    }

    _getPriceHT(price) {
        const { subscribeType } = this.props
        const numberBoats = this.props.numberBoats || 1

        switch (subscribeType) {
            case 1:
                return price.find(p => p.skipper).skipper.month.ht.toFixed(2)
            case 2:
                return price.find(p => p.skipper).skipper.year.ht.toFixed(2)
            case 3:
                return (
                    price.find(p => p.bosco).bosco.month.ht * numberBoats
                ).toFixed(2)
            case 4:
                return (
                    price.find(p => p.bosco).bosco.year.ht * numberBoats
                ).toFixed(2)
            case 5:
                return (
                    price.find(p => p.commandant).commandant.month.ht *
                    numberBoats
                ).toFixed(2)
            case 6:
                return (
                    price.find(p => p.commandant).commandant.year.ht *
                    numberBoats
                ).toFixed(2)
            case 7:
                return (
                    price.find(p => p.amiral).amiral.month.ht * numberBoats
                ).toFixed(2)
            case 8:
                return (
                    price.find(p => p.amiral).amiral.year.ht * numberBoats
                ).toFixed(2)
            case 9:
                return (
                    price.find(p => p.captain).captain.month.ht * numberBoats
                ).toFixed(2)
            case 10:
                return (
                    price.find(p => p.captain).captain.year.ht * numberBoats
                ).toFixed(2)
            default:
                return 0.0
        }
    }

    _getPriceTTC(price) {
        const { subscribeType } = this.props
        const numberBoats = this.props.numberBoats || 1

        switch (subscribeType) {
            case 1:
                return price.find(p => p.skipper).skipper.month.ttc.toFixed(2)
            case 2:
                return price.find(p => p.skipper).skipper.year.ttc.toFixed(2)
            case 3:
                return (
                    price.find(p => p.bosco).bosco.month.ttc * numberBoats
                ).toFixed(2)
            case 4:
                return (
                    price.find(p => p.bosco).bosco.year.ttc * numberBoats
                ).toFixed(2)
            case 5:
                return (
                    price.find(p => p.commandant).commandant.month.ttc *
                    numberBoats
                ).toFixed(2)
            case 6:
                return (
                    price.find(p => p.commandant).commandant.year.ttc *
                    numberBoats
                ).toFixed(2)
            case 7:
                return (
                    price.find(p => p.amiral).amiral.month.ttc * numberBoats
                ).toFixed(2)
            case 8:
                return (
                    price.find(p => p.amiral).amiral.year.ttc * numberBoats
                ).toFixed(2)
            case 9:
                return (
                    price.find(p => p.captain).captain.month.ttc * numberBoats
                ).toFixed(2)
            case 10:
                return (
                    price.find(p => p.captain).captain.year.ttc * numberBoats
                ).toFixed(2)
            default:
                return 0.0
        }
    }

    _body() {
        const { classes, subscribeType } = this.props
        return (
            <>
                <Typography variant="subtitle1" id="simple-modal-description">
                    <div>
                        <Typography
                            variant="subtitle2"
                            component="p"
                            gutterBottom
                        >
                            {this.displayText('youWillSubscribeTitle')}
                        </Typography>
                        <Typography variant={`body1`}>
                            {this.displayText('youWillSubscribe')}
                            {subscribeType !== 0 ? (
                                <span className={classes.st}>
                                    {this.displayText('st')}
                                </span>
                            ) : (
                                ''
                            )}{' '}
                            {subscribeType !== 0
                                ? this.displayText('youWillSubscribe2')
                                : ''}
                        </Typography>
                        <CreditCard />
                        <div
                            style={{ textAlign: 'center' }}
                            className={classes.footerValidate}
                        >
                            <TextField
                                id="reductionCode"
                                margin="normal"
                                placeholder={this.displayText('reductionCode')}
                                value={this.state.reductionCode}
                                onChange={e => {
                                    this.handleChange(
                                        e.target.value,
                                        'reductionCode',
                                    )
                                }}
                                className={classes.reduction}
                                style={{ textAlign: 'center' }}
                            />
                        </div>

                        <div className={classes.subscribeTypeBox}>
                            <Divider
                                classes={{ root: classes.subscribeDivider }}
                            />
                            <div className={classes.subscribeType}>
                                <div className={classes.subTypeDetails}>
                                    <Typography
                                        className={classes.afterFreeTryText}
                                    >
                                        {this.displayText('afterFreeTryText')}
                                    </Typography>
                                    <Typography variant={`subtitle2`}>
                                        {subscribeType % 2 === 1
                                            ? this.displayText(
                                                  'subTextDetailsMonthly',
                                              )
                                            : this.displayText(
                                                  'subTextDetailsAnnual',
                                              )}
                                    </Typography>
                                </div>
                                <div className={classes.subTypePrice}>
                                    <Typography variant={`subtitle2`}>
                                        {this._getPriceHT(pricesConfig)}
                                        {this.displayText('euro')}{' '}
                                        {this.displayText('ET')}
                                    </Typography>
                                    <Typography variant={`subtitle2`}>
                                        {this._getPriceTTC(pricesConfig)}
                                        {this.displayText('euro')}{' '}
                                        {this.displayText('IT')}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        {this.state.send ? (
                            <div style={{ textAlign: 'center' }}>
                                <img
                                    src={chargement}
                                    alt="loading"
                                    className={classes.loading}
                                />
                            </div>
                        ) : (
                            <div></div>
                        )}
                    </div>
                </Typography>
            </>
        )
    }

    render() {
        const { title } = this.props
        return (
            <>
                {this._renderTitle(title)}
                {this._renderBody({
                    body: this._body(),
                })}
                {this._renderActions([
                    {
                        label: this.displayText('subscription'),
                        action: this.validate,
                        disabled: this.state.send,
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        subscribed: state.fetch.subscribed,
        groupId: state.group.currentGroupId,
        numberBoats: state.boat.numberBoats,
        boatsLoading: state.boat.loading,
    }
}

export default injectStripe(
    connect(mapStateToProps)(withStyles(styles)(ModalSubscribe)),
)
