import React from 'react'
import { NavLink } from 'react-router-dom'
import { useIntl } from 'react-intl'

/**
 *
 * @param AppRoute to la route associé au lien
 * @param String link affiché si la route link n'est pas renseigné
 */
export const LocalizedLink = React.forwardRef(
    ({ to, children, link, ForwardedRef, ...props }, ref) => {
        const { formatMessage, locale } = useIntl()

        return (
            <NavLink
                to={to !== undefined ? localizeRoutePath(to) : link}
                className={props.className ? props.className : null}
                style={props.style ? props.style : null}
                color={props.color ? props.color : null}
                ref={props.ref ? props.color : null}
                title={to !== undefined ? localizeRoutePath(to) : link}
            >
                {children}
            </NavLink>
        )

        function localizeRoutePath(path) {
            switch (typeof path) {
                case 'undefined':
                    return undefined
                case 'object':
                    return path.map(
                        key => '/' + locale + formatMessage({ id: key }),
                    )
                default:
                    const isFallbackRoute = path === '*'
                    let format = formatMessage({ id: path })
                    return isFallbackRoute ? path : '/' + locale + format
            }
        }
    },
)
