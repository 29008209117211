export default theme => ({
    root: {
        width: '90%',
        paddingLeft: 80,
        paddingRight: 80,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            width: '100%',
        },
    },
})
