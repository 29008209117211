import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../../common/BoatOnModalCore'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/ConfirmationModalCss'
import dictionary from './Dictionary/ConfirmationModalDico'

class ConfirmationModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    _renderBody() {
        const { classes } = this.props

        return (
            <div className={classes.root}>{this.displayText('question')}</div>
        )
    }

    render() {
        const { title, showFollowButton, onClose, onValid } = this.props

        return (
            <>
                {this._renderTitle(
                    title,
                    false,
                    null,
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    body: this._renderBody(),
                })}
                {this._renderActions([
                    {
                        action: onClose,
                        type: 'text',
                        label: this.displayText('cancel'),
                    },
                    {
                        action: onValid,
                        label: this.displayText('valid'),
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    const isMobile = window.innerWidth < 600

    return {
        isMobile,
        currentUser: state.authentication?.user || null,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ConfirmationModal))
