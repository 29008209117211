import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import classNames from 'classnames'
import ChevronLeft from '@material-ui/icons/ChevronLeft'
import styles from './Styles/SideNavigationCss'
import BoatOnComponent from './BoatOnComponent'
import { ChevronRight } from '@material-ui/icons'
import { Box } from '@material-ui/core'
import BoatSelecter from '../LogbookPage/LogbookNavigationPage/BoatSelecter'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers'
import 'dayjs/locale/fr'
import { frFR } from '@mui/x-date-pickers/locales'

class SideNavigation extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.frenchLocale =
            frFR.components.MuiLocalizationProvider.defaultProps.localeText
    }

    render() {
        const {
            classes,
            list,
            main,
            open,
            handleClick,
            renderBoatSelecter,
        } = this.props
        const isMobile = window.innerWidth < 600

        return (
            <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={this.context}
                localeText={
                    this.context === `fr` ? this.frenchLocale : undefined
                }
            >
                <div className={classes.root}>
                    {isMobile && (
                        <div
                            className={classes.drawerButton}
                            onClick={handleClick}
                        >
                            <ChevronRight
                                style={{
                                    color: 'white',
                                    marginRight: '0',
                                }}
                                fontSize="inherit"
                            />
                        </div>
                    )}
                    <Drawer
                        variant={isMobile ? 'temporary' : 'permanent'}
                        classes={{
                            paper: classNames(
                                classes.drawerPaper,
                                !isMobile && !open && classes.drawerPaperClose,
                            ),
                        }}
                        open={open}
                        onClose={handleClick}
                    >
                        <div
                            className={
                                isMobile || open
                                    ? classes.toolbarRight
                                    : classes.toolbar
                            }
                            onClick={handleClick}
                        >
                            <IconButton className={classes.button}>
                                {isMobile || open ? (
                                    <ChevronLeft
                                        style={{
                                            marginRight: '0',
                                            marginLeft: 'auto',
                                        }}
                                    />
                                ) : (
                                    <MenuIcon />
                                )}
                            </IconButton>
                        </div>
                        <Divider />
                        <List
                            className={
                                isMobile ? classes.listMobile : undefined
                            }
                        >
                            {list}
                        </List>
                        {/* </div> */}
                    </Drawer>
                    <Box className={classes.scrollContent}>
                        <div className={classes.mainContainer}>
                            <div className={classes.boatSelecterContainer}>
                                {renderBoatSelecter && <BoatSelecter />}
                                <main className={classes.mainContent}>
                                    {main}
                                </main>
                            </div>
                        </div>
                    </Box>
                </div>
            </LocalizationProvider>
        )
    }
}

export default withStyles(styles)(SideNavigation)
