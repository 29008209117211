export default theme => ({
    login: {
        backgroundColor: '#fec107',
        borderRadius: 25,
    },
    card: {
        width: '50%',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            padding: '0px 0px 35px 0px',
        },
    },
    toCenter: {
        textAlign: 'center',
        fontSize: 26,
        fontWeight: 'normal',
    },
    sendButton: {
        marginTop: 15,
    },
})
