export default theme => ({
    root: {
        minWidth: `960px`,
        maxWidth: `1200px`,
        margin: `auto`,
        [theme.breakpoints.down('sm')]: {
            width: `100%`,
            minWidth: 0,
        },
    },
    body: {
        position: `relative`,
    },
    orderForm: {
        border: `1px solid #c4c4c4`,
        boxShadow: `0px 2px 10px 0.3px #c4c4c4`,
        padding: `28px 46px`,
        marginTop: `10px`,
        [theme.breakpoints.down('sm')]: {
            padding: `24px`,
        },
    },
    address: {
        whiteSpace: `break-spaces`,
    },
    senderInfos: {
        lineHeight: `22px`,
        marginTop: `8px`,
    },
    receiverInfos: {
        marginTop: `72px`,
        display: `flex`,
        flexDirection: `column`,
        [theme.breakpoints.down('sm')]: {
            marginTop: `32px`,
        },
        [theme.breakpoints.up('md')]: {
            width: `250px`,
        },
    },
    infos: {
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-between`,
        fontSize: `14px`,
        [theme.breakpoints.down('sm')]: {
            flexDirection: `column`,
        },
    },
    date: {
        marginTop: `76px`,
        [theme.breakpoints.down('sm')]: {
            marginTop: `16px`,
        },
    },
    tableHeader: {
        display: `flex`,
        flexDirection: `row`,
        marginTop: `50px`,
    },
    tableLine: {
        display: `flex`,
        flexDirection: `row`,
        position: 'relative',
    },
    deleteIcon: {
        position: 'absolute',
        left: -36,
        top: 10,
        color: `#C4C4C4`,
    },
    divider: {
        marginTop: `8px`,
        marginBottom: `11px`,
    },
    bigText: {
        width: `324px`,
        fontSize: `12px`,
    },
    normalText: {
        minWidth: `124px`,
        maxWidth: `194px`,
        fontSize: `12px`,
        width: `100%`,
    },
    smallText: {
        width: `50px`,
        marginRight: `24px`,
        fontSize: `12px`,
        marginLeft: `auto`,
    },
    bigInput: {
        width: `300px`,
        marginRight: `24px`,
    },
    normalInput: {
        minWidth: `100px`,
        maxWidth: `170px`,
        marginRight: `24px`,
        width: `100%`,
    },
    smallInput: {
        width: `50px`,
        marginRight: `24px`,
        marginLeft: `auto`,
    },
    buttonAddLine: {
        background: `#F2F4FD`,
        border: `1px solid #8994DB`,
        color: `#3040a0`,
        borderRadius: `8px`,
        marginTop: `16px`,
    },
    muiButtonRoot: {
        textTransform: `none`,
        fontSize: `16px`,
        fontWeight: `500`,
        fontStyle: `normal`,
    },
    tableDesktop: {
        [theme.breakpoints.down('md')]: {
            display: `none`,
        },
    },
    tableMobile: {
        [theme.breakpoints.up('lg')]: {
            display: `none`,
        },
    },
    tableBody: {
        marginBottom: `16px`,
    },
    dividerBox: {
        margin: `0px 12px`,
        width: `calc(100% - 24px)`,
        marginBottom: `12px`,
    },
    accordionDetails: {
        width: `calc(100% - 32px)`,
        display: `flex`,
        flexDirection: `column`,
    },
    mobileLine: {
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-between`,
    },
    flexColumn: {
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `flex-start`,
    },
    mobileBoxes: {
        marginBottom: `30px`,
        marginTop: `40px`,
    },
    mobileBox: {
        margin: `10px 0px`,
    },
    columnTitleMobile: {
        opacity: `0.6`,
        marginBottom: `4px`,
    },
    mobileIconButton: {
        color: `#C4C4C4`,
        height: `24px`,
        width: `24px`,
    },
    mobileIcons: {
        display: `flex`,
        flexDirection: `row`,
        marginTop: `35px`,
    },
    mobileButton: {
        marginRight: `0px`,
    },
    accordionSummaryRoot: {
        minHeight: 48,
        '&$accordionSummaryExpanded': {
            minHeight: 48,
        },
    },
    accordionSummaryContent: {
        '&$accordionSummaryExpanded': {
            margin: '12px 0',
        },
    },
    accordionSummaryExpanded: {},
    equipmentLine: {
        width: `100%`,
        padding: `8px 0px`,
        backgroundColor: `#F4F5F7`,
        marginTop: `16px`,
        [theme.breakpoints.down('md')]: {
            width: `calc(100% + 92px)`,
            marginLeft: `-46px`,
        },
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% + 48px)`,
            marginLeft: `-24px`,
        },
    },
    equipmentTitle: {
        color: `#848EA4`,
        textTransform: `uppercase`,
        marginLeft: `14px`,
        [theme.breakpoints.down('md')]: {
            marginLeft: `46px`,
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: `24px`,
        },
    },
    textFieldDialog: {
        marginBottom: `8px`,
    },
    demiTextFieldDialog: {
        marginBottom: `8px`,
        width: `calc(50% - 18px)`,
    },
    titleDialog: {
        [theme.breakpoints.down('sm')]: {
            width: `calc(100% - 48px)`,
            textAlign: `center`,
        },
    },
    dialogContainer: {
        alignItems: 'flex-end',
    },
    dialogPaper: {
        borderRadius: `12px`,
        [theme.breakpoints.up('md')]: {
            transform: `translate(0, -50%)`,
        },
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            width: `100%`,
            maxWidth: `100%`,
            borderRadius: `24px 24px 0px 0px`,
        },
    },
    closeIcon: {
        color: `#C4C4C4`,
        width: `20px`,
        marginRight: `14px`,
        [theme.breakpoints.down('sm')]: {
            display: `none`,
        },
    },
    headerDialog: {
        display: `flex`,
        flexDirection: `row`,
        justifyContent: `space-between`,
    },
    helperText: {
        marginLeft: 0,
    },
    optionnal: {
        fontSize: 12,
        color: `rgba(0, 0, 0, 0.54)`,
    },
    saveButton: {
        [theme.breakpoints.down('sm')]: {
            width: `100%`,
        },
    },
    downloadButton: {
        position: `relative`,
        float: `right`,
    },
})
