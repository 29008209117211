export default {
    control: {
        FR: 'Créer un contrôle',
        EN: 'Create a control',
    },
    download: {
        FR: 'Télécharger',
        EN: 'Download',
    },
    email: {
        FR: 'Email',
        EN: 'Email',
    },
    orderForm: {
        FR: 'Bon de commande',
        EN: 'Order form',
    },
    delete: {
        FR: 'Supprimer',
        EN: 'Delete',
    },
}
