import { redirectConstants } from '../constants'

const initialState = {
    params: null,
    event: undefined,
    loading: 0,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case redirectConstants.SAVE_REDIRECTION_PARAMS:
            return {
                ...prevState,
                params: {
                    ...prevState.params,
                    ...action.params,
                },
            }
        case redirectConstants.CLEAR_REDIRECTION_PARAMS:
            return {
                ...initialState,
            }
        case redirectConstants.GET_EVENT_BY_ID:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case redirectConstants.GET_EVENT_BY_ID_SUCCESS:
            return {
                ...prevState,
                event: action.event,
                loading: prevState.loading - 1,
            }
        case redirectConstants.GET_EVENT_BY_ID_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                event: null,
            }

        default:
            return prevState
    }
}
