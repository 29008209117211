export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'flex-end',
        overflowY: 'hidden',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            height: 'unset',
        },
    },
    title: {
        fontSize: 14,
        fontWeight: 600,
    },
    tooltip: {
        backgroundColor: '#3040a0',
        color: 'white',
        maxWidth: 300,
        border: '1px solid #dadde9',
        fontSize: 15,
    },
    helpIcon: {
        marginTop: -10,
        width: 20,
        height: 20,
        marginRight: 3,
        marginLeft: 3,
    },
})
