import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../../common/BoatOnComponent'
import styles from './Styles/InsuranceModalCss'
import dictionary from './Dictionary/InsuranceModalDico'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import { API_URL } from '../../../services/config.service'

class InsuranceModal extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {}
    }

    handleSubscribe(quotation) {
        this.props.refresh(true)
    }

    handleCallback(quotation) {
        alert(this.displayText('alert'))
    }

    handleDecline(quotation) {
        this.props.refresh(false)
    }

    renderHeader(quotation) {
        const { classes } = this.props

        return (
            <div className={classes.header}>
                <img
                    alt="logo"
                    title="logo"
                    height="100%"
                    src={API_URL + '/logoinsurances/' + quotation.InsuranceLogo}
                    className={classes.titleLogo}
                ></img>
                <Typography className={classes.titleFont}>
                    {quotation.InsuranceName}
                </Typography>
            </div>
        )
    }

    renderPrice(quotation) {
        const { classes } = this.props

        return (
            <div className={classes.price}>
                <Typography className={classes.fontPrice}>
                    {this.displayText('annualPrice')}
                </Typography>
                <Typography className={classes.fontPrice}>
                    {quotation.Price} €
                </Typography>
            </div>
        )
    }

    renderAdvice(quotation) {
        const { classes } = this.props

        return (
            <div className={classes.advice}>
                <Typography className={classes.fontSubtitle}>
                    {this.displayText('expertAdvice')}
                </Typography>
                <Typography className={classes.fontText}>
                    {quotation.Comment}
                </Typography>
            </div>
        )
    }

    renderOffer(quotation) {
        const { classes } = this.props
        const pdfs = quotation.pdf

        return (
            <div className={classes.offer}>
                <Typography className={classes.fontSubtitle}>
                    {this.displayText('detailsOffer')}
                </Typography>
                <Typography className={classes.fontText}>
                    {this.displayText('cliqueOnLink')}
                </Typography>
                {pdfs.length > 0 ? (
                    pdfs.map((pdf, i) => (
                        <Link
                            href={
                                API_URL +
                                '/insurance/insurancequotationpdf/' +
                                pdf.PDFLink
                            }
                            className={classes.linkText}
                            rel="noopener noreferrer"
                            target="_blank"
                            key={i}
                        >
                            {'- '} {pdf.PDFName}
                        </Link>
                    ))
                ) : (
                    <div />
                )}
            </div>
        )
    }

    renderButtons(quotation) {
        const { classes } = this.props

        return (
            <div className={classes.buttons}>
                <Button
                    variant="contained"
                    className={classes.accepted}
                    onClick={() => this.handleSubscribe(quotation)}
                >
                    {this.displayText('subscribe')}
                </Button>
                <Button
                    variant="contained"
                    className={classes.callback}
                    onClick={() => this.handleCallback(quotation)}
                >
                    {this.displayText('callback')}
                </Button>
                <Button
                    variant="contained"
                    className={classes.declined}
                    onClick={() => this.handleDecline(quotation)}
                >
                    {this.displayText('decline')}
                </Button>
            </div>
        )
    }

    renderStatus(status) {
        const { classes, quotation } = this.props
        const dateAnswer = quotation.DateAnswer
            ? new Date(quotation.DateAnswer.split(' ')[0]).toLocaleDateString()
            : false

        if (status === 1) {
            return (
                <div>
                    <Typography className={classes.fontSubtitle}>
                        {this.displayText('status')}
                    </Typography>
                    <Typography className={classes.acceptedFont}>
                        {this.displayText('accepted')}{' '}
                        {dateAnswer ? 'le : ' + dateAnswer : ''}
                    </Typography>
                </div>
            )
        } else {
            return (
                <div>
                    <Typography className={classes.fontSubtitle}>
                        {this.displayText('status')}
                    </Typography>
                    <Typography className={classes.declinedFont}>
                        {this.displayText('declined')}{' '}
                        {dateAnswer ? 'le : ' + dateAnswer : ''}
                    </Typography>
                </div>
            )
        }
    }

    render() {
        const { quotation, classes, insurance } = this.props

        return (
            <div className={classes.root}>
                {this.renderHeader(quotation)}
                {this.renderPrice(quotation)}
                {this.renderAdvice(quotation)}
                {this.renderOffer(quotation)}
                {insurance.InqueryStatus === 4 && quotation.Status === 0
                    ? this.renderButtons(quotation)
                    : this.renderStatus(quotation.Status)}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        loading: state.fetch.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(InsuranceModal))
