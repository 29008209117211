import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import styles from './Styles/ModalRenderConnectionCss'
import dictionary from './Dictionary/ModalRenderConnectionDico'
import BoatOnModalCore from '../../BoatOnModalCore'
import { userActions } from '../../../../actions'
import InputAdornment from '@material-ui/core/InputAdornment'
import EmailIcon from '@material-ui/icons/Email'
import LockIcon from '@material-ui/icons/Lock'

class ModalRenderConnection extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            email: '',
            password: '',
            focused: false,
            openModal: 'connection',
        }

        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleClose() {
        this.setState({ openModal: false }, () => this.props.handleClose(false))
    }

    handleChange(e) {
        this.setState({
            [e.currentTarget.dataset.key]: e.target.value,
            focused: e.currentTarget.dataset.key,
        })
    }

    handleSubmit(e) {
        const { saveUpdateSubscription } = this.props
        e.preventDefault()
        if (
            /^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(
                this.state.email,
            ) &&
            this.state.password.length >= 6
        ) {
            this.setState({ submitted: true })
            const { email, password } = this.state
            const { dispatch } = this.props
            if (email && password) {
                dispatch(userActions.login(email, password.trim()))
                    .then(() => {
                        if (saveUpdateSubscription) saveUpdateSubscription()
                        this.props.handleClose(true)
                    })
                    .catch(() => {
                        alert(this.displayText('error'))
                    })
            }
        }
    }

    renderForm() {
        const { userLoading } = this.props

        return (
            <form id="form-connection" onSubmit={this.handleSubmit}>
                <TextField
                    id="email"
                    label={this.displayText('email')}
                    margin="normal"
                    placeholder="paulgrenier@email.com"
                    error={
                        !/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(
                            this.state.email,
                        ) &&
                        this.state.email !== '' &&
                        this.state.focused !== 'email'
                    }
                    helperText={
                        !/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(
                            this.state.email,
                        ) &&
                        this.state.email !== '' &&
                        this.state.focused !== 'email'
                            ? this.displayText('invalidEmail')
                            : ''
                    }
                    required
                    variant="outlined"
                    value={this.state.email}
                    onChange={this.handleChange}
                    inputProps={{
                        'data-key': `email`,
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <EmailIcon />
                            </InputAdornment>
                        ),
                    }}
                    disabled={userLoading > 0}
                />
                <TextField
                    id="password"
                    label={this.displayText('password')}
                    margin="normal"
                    type="password"
                    required
                    variant="outlined"
                    placeholder="**********"
                    error={
                        this.state.password.length < 6 &&
                        this.state.password !== '' &&
                        this.state.focused !== 'password'
                    }
                    helperText={
                        this.state.password.length < 6 &&
                        this.state.password !== '' &&
                        this.state.focused !== 'password'
                            ? this.displayText('nbCharacterPassword')
                            : ''
                    }
                    value={this.state.password}
                    onChange={this.handleChange}
                    inputProps={{
                        'data-key': `password`,
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockIcon />
                            </InputAdornment>
                        ),
                    }}
                    disabled={userLoading > 0}
                />
            </form>
        )
    }

    render() {
        const { userLoading } = this.props

        return (
            <>
                {this._renderTitle(this.displayText('connection'))}
                {this._renderBody({
                    body: this.renderForm(),
                })}
                {this._renderActions([
                    {
                        label: this.displayText('connection'),
                        form: `form-connection`,
                        minWidth: `120px`,
                        disabled: userLoading > 0,
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        state: state.open,
        user: state.authentication.user,
        userLoading: state.authentication.loading,
    }
}

export default connect(mapStateToProps)(
    withStyles(styles)(ModalRenderConnection),
)
