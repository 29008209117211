import dayjs from 'dayjs'
import { absenceConstants } from '../../constants'
import { requestApi } from '../../services/api.service'
import worktimesUtils from '../../utils/worktimesUtils'

/**
 *
 * @param {*} userId
 * @param {*} storeAsSeparateProps Permet de stocker le résultat de la requête sans écraser les données des absences de cet utilisateur
 *                                 Utilisé pour les managers qui ont besoin de connaitre les absences d'un utilisateur
 * @returns
 */
const requestUserAbsences = userId => {
    return dispatch => {
        dispatch({
            type: absenceConstants.USER_ABSENCES_REQUEST,
        })
        return requestApi(`/user-absences/user-link/${userId}`, 'GET').then(
            result => {
                dispatch({
                    type: absenceConstants.USER_ABSENCES_SUCCESS,
                    absences: result,
                })
            },
            error => {
                dispatch({
                    type: absenceConstants.USER_ABSENCES_ERROR,
                    absences: [],
                })
            },
        )
    }
}

const getTotalAbsenceForUserLink = userLinkId => {
    return dispatch => {
        dispatch({
            type: absenceConstants.TOTAL_USER_ABSENCES_REQUEST,
        })
        return requestApi(
            `/user-absences/total-paid-vacation-used/user-link/${userLinkId}`,
            'GET',
        ).then(
            result => {
                dispatch({
                    type: absenceConstants.TOTAL_USER_ABSENCES_SUCCESS,
                    total: result,
                })
            },
            error => {
                console.error(error)
                dispatch({
                    type: absenceConstants.TOTAL_USER_ABSENCES_ERROR,
                    total: [],
                })
            },
        )
    }
}

const requestUserAbsencesByManager = userLinkId => {
    return dispatch => {
        dispatch({
            type: absenceConstants.USER_ABSENCES_MANAGER_REQUEST,
        })
        return requestApi(`/user-absences/by-manager-link/${userLinkId}`, 'GET')
            .then(
                result => {
                    dispatch({
                        type: absenceConstants.USER_ABSENCES_MANAGER_SUCCESS,
                        absencesManager: result,
                    })
                },
                error => {
                    dispatch({
                        type: absenceConstants.USER_ABSENCES_MANAGER_ERROR,
                        absencesManager: [],
                    })
                },
            )
            .catch(e => {
                dispatch({
                    type: absenceConstants.USER_ABSENCES_MANAGER_ERROR,
                    absencesManager: [],
                })
            })
    }
}

const getAllAbsencesForGroupMembers = ({ group }) => {
    return dispatch => {
        dispatch({
            type: absenceConstants.GROUP_ABSENCES_REQUEST,
        })
        Promise.allSettled(
            group.linkRGU.map(userLink => {
                return new Promise((resolve, reject) => {
                    return requestApi(
                        `/user-absences/user-link/${userLink.id}`,
                        'GET',
                    )
                        .then(absences => {
                            resolve({
                                userLinkId: userLink.id,
                                absences,
                            })
                        })
                        .catch(error => {
                            console.error(error)
                            reject({
                                userLinkId: userLink.id,
                                absences: [],
                            })
                        })
                })
            }),
        )
            .then(results => {
                dispatch({
                    type: absenceConstants.GROUP_ABSENCES_SUCCESS,
                    result: results.map(r => r.value).filter(r => r),
                })
            })
            .catch(e => {
                console.error(e)
                dispatch({
                    type: absenceConstants.GROUP_ABSENCES_ERROR,
                })
            })
    }
}

const getUserLinkPaidVacationsAllowed = (userLinks, paidVacationSettings) => {
    const currentPeriodYear = worktimesUtils.getCurrentPeriodYear(
        paidVacationSettings,
    )

    return dispatch => {
        dispatch({
            type: absenceConstants.GET_USER_LINKS_PAID_VACATIONS_ALLOWED,
        })
        Promise.allSettled(
            userLinks.map(
                userLink =>
                    new Promise((resolve, reject) =>
                        requestApi(
                            `/link-role-group-user/userLinkAllowedVacation/${userLink.id}`,
                            `GET`,
                        ).then(
                            result => {
                                let {
                                    dateStart,
                                } = worktimesUtils.getPeriodDates(
                                    paidVacationSettings,
                                )

                                const userCreationDate = userLink.user
                                    ? dayjs(userLink.user.createAccount)
                                    : userLink?.userSubscribe?.createAccount
                                    ? dayjs(
                                          userLink.userSubscribe.createAccount,
                                      )
                                    : dayjs()
                                let userCreationYear = userCreationDate.year()

                                if (
                                    worktimesUtils
                                        .onlyDayAndMonth(userCreationDate)
                                        .isBefore(dateStart)
                                ) {
                                    userCreationYear--
                                }

                                // Calcul du nombre d'années entre l'année actuelle et l'année de création de l'utilisateur
                                const nbYears =
                                    currentPeriodYear - userCreationYear

                                // Pour chacune de ces années, si un record de congés payés autorisés n'existe pas
                                // Alors on l'ajoute
                                for (let i = 0; i <= nbYears; i++) {
                                    const year = userCreationYear + i
                                    if (!result.find(r => r.year === year)) {
                                        result.push({
                                            year,
                                            nbAllowedVacations:
                                                paidVacationSettings.nbPaidVacationAllowed,
                                        })
                                    }
                                }

                                if (result.length === 0) {
                                    result.push({
                                        year: currentPeriodYear,
                                        nbAllowedVacations:
                                            paidVacationSettings.nbPaidVacationAllowed,
                                    })
                                }

                                resolve({
                                    userLinkId: userLink.id,
                                    value: result.sort((a, b) =>
                                        b.year > a.year ? 1 : -1,
                                    ),
                                })
                            },
                            error => {
                                reject(error)
                            },
                        ),
                    ),
            ),
        )
            .then(result => {
                dispatch({
                    type:
                        absenceConstants.GET_USER_LINKS_PAID_VACATIONS_ALLOWED_SUCCESS,
                    paidVacationsAllowed: result.map(r => r.value),
                })
            })
            .catch(error => {
                dispatch({
                    type:
                        absenceConstants.GET_USER_LINKS_PAID_VACATIONS_ALLOWED_FAILURE,
                    error,
                })
            })
    }
}

const clearGroupAbsences = () => {
    return dispatch => {
        dispatch({
            type: absenceConstants.CLEAR_GROUP_ABSENCES,
        })
    }
}

export const absencesActions = {
    requestUserAbsences,
    requestUserAbsencesByManager,
    getTotalAbsenceForUserLink,
    getAllAbsencesForGroupMembers,
    getUserLinkPaidVacationsAllowed,
    clearGroupAbsences,
}
