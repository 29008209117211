const SliderStyle = theme => ({
    thumb: {
        height: 18,
        width: 18,
        backgroundColor: '#fff',
        border: '2px solid #3040a0',
        marginLeft: '-9px',
        marginTop: '-8px',
    },
    track: {
        backgroundColor: '#3040a0',
        height: 5,
        opacity: 1,
        marginTop: '-2px',
    },
    root: {
        width: 'calc(100% - 18px)',
    },
})

export { SliderStyle }
