import { boatsConstants } from '../constants'
import { requestApi } from '../services/api.service'
import { userConstants } from '../constants'
import { initBobWithBoat } from './bob.actions'
import { groupActions } from './group.actions'

const requestBoats = (id, user) => {
    return requestBoatsGroup(id, user)
}

const requestBoatsGroup = (groupId, user) => {
    return dispatch => {
        dispatch({
            type: userConstants.START_BOATS_UPDATE,
        })
        requestApi(`/boats/group/${groupId}`, 'GET').then(
            result => {
                if (localStorage.getItem('boatIdSelect')) {
                    var boat = result.find(
                        boat =>
                            boat.id === localStorage.getItem('boatIdSelect'),
                    )
                    if (boat === null && result.length > 0) {
                        localStorage.setItem('boatIdSelect', result[0].id)
                        dispatch(
                            initBobWithBoat({ user, boatId: result[0].id }),
                        )
                    }
                }
                dispatch({
                    type: userConstants.BOATS_UPDATED,
                    user: _updateBoatsUser(result, user, groupId),
                })
            },
            error => {
                dispatch({ type: userConstants.BOATS_UPDATE_FAILED })
            },
        )
    }
}

const requestLinkedBoats = () => {
    return dispatch => {
        dispatch({ type: boatsConstants.REQUEST_LINKED_BOATS })
        requestApi('/boats', 'GET').then(
            result => {
                const listGroupId = [
                    ...new Set(result.map(boat => boat.userGroupId)),
                ]

                listGroupId.forEach(id =>
                    dispatch(groupActions.requestUserGroupLinked(id)),
                )

                dispatch({
                    type: boatsConstants.LINKED_BOATS_SUCCESS,
                    linkedBoats: result,
                })
            },
            error => {
                dispatch({ type: boatsConstants.LINKED_BOATS_FAILURE })
            },
        )
    }
}

const _updateBoatsUser = (result, user, groupId) => {
    const actualGroupId =
        groupId || parseInt(localStorage.getItem('groupIdSelect'))

    if (actualGroupId > 0) {
        if (user?.ownGroup?.id === actualGroupId) {
            user.ownGroup.boats = result
        } else if (user?.userGroup && Array.isArray(user.userGroup)) {
            user.userGroup = user.userGroup.map(group => {
                if (group?.linkRGU[0]?.boats) {
                    group.linkRGU[0].boats = result
                }
                return group
            })
        }
    }
    return user
}

const postNewBoatToGroup = (
    user,
    {
        boatNameCreate,
        makerCreate,
        modelCreate,
        yearCreate,
        boatTypeCreate,
        address,
        groupId,
        serialNumber,
        insurance,
    },
) => {
    let body = {
        name: boatNameCreate,
        maker: makerCreate || null,
        model: modelCreate || null,
        serialNumber: serialNumber || null,
        year: yearCreate ? parseInt(yearCreate) : null,
        boatType: {
            id:
                boatTypeCreate && boatTypeCreate >= 0
                    ? parseInt(boatTypeCreate)
                    : null,
        },
        userGroup: {
            id: groupId || parseInt(localStorage.getItem('groupIdSelect')),
        },
        willGenerateInventory: true,
    }

    if (insurance) body.insurance = true

    const newAddress =
        address && address?.fullText
            ? {
                  fullAddress: address.fullText,
                  latitude: address.latitude?.toString(),
                  longitude: address.longitude?.toString(),
              }
            : null

    if (newAddress) body.address = newAddress

    return dispatch => {
        dispatch({ type: boatsConstants.POST_BOATS_TO_GROUP })
        dispatch({
            type: userConstants.START_BOATS_UPDATE,
        })
        return requestApi('/boats/boatToGroup', 'POST', body).then(
            result => {
                dispatch({
                    type: boatsConstants.BOATS_TO_GROUP_SUCCESS,
                })
                dispatch({
                    type: userConstants.BOATS_UPDATED,
                    user: _updateBoatsUser(result, user, groupId),
                })
                return result
            },
            error => {
                dispatch({ type: boatsConstants.BOATS_TO_GROUP_FAILURE, error })
                dispatch({ type: userConstants.BOATS_UPDATE_FAILED })
            },
        )
    }
}

const getFleetNumberBoat = groupId => {
    return dispatch => {
        dispatch({ type: boatsConstants.REQUEST_NB_BOATS })
        if (!groupId)
            dispatch({
                type: boatsConstants.REQUEST_NB_BOATS_SUCCESS,
                numberBoats: 1,
            })
        else {
            return requestApi('/boats/nbBoatInGroup/' + groupId, 'GET').then(
                result => {
                    dispatch({
                        type: boatsConstants.REQUEST_NB_BOATS_SUCCESS,
                        numberBoats: result || 1,
                    })
                    return result
                },
                error => {
                    dispatch({
                        type: boatsConstants.REQUEST_NB_BOATS_FAILURE,
                    })
                    return error
                },
            )
        }
    }
}

export const boatActions = {
    requestBoats,
    postNewBoatToGroup,
    getFleetNumberBoat,
    requestLinkedBoats,
}
