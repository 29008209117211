export default theme => ({
    root: {
        minHeight: 'calc(55vh)',
        position: 'relative',
        marginLeft: '25px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0',
        },
    },
    title: {
        fontSize: '0.8125rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    appBar: {
        background: 'white',
        color: 'black',
    },
})
