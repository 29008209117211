export default theme => ({
    root: {
        paddingTop: 20,
        paddingLeft: 80,
        paddingRight: 80,
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    map: {
        '& .leaflet-pane.leaflet-map-pane': {
            zIndex: 'unset',
        },
        '& .leaflet-map-pane canvas': {
            zIndex: 'unset',
            position: 'absolute',
            left: 'unset',
            top: 'unset',
        },
        '& .leaflet-pane .leaflet-top-tiles-pane': {
            zIndex: 'unset',
            position: 'relative',
            top: 'unset',
            left: 'unset',
        },
        '& .leaflet-container': {
            '-webkit-tap-highlight-color': 'unset',
        },
        '& .leaflet-touch .leaflet-bar': {
            border: 'none',
        },
        '& .leaflet-popup.move-popup.leaflet-zoom-animated': {
            marginBottom: 'unset',
        },
        '& .leaflet-popup-tip-container': {
            left: 'unset',
        },
        '& .windy-map-2__play-controls': {
            display: 'none',
        },
        '& .windy-map-2__legend-container-wrap': {
            display: 'none',
        },
        '& .leaflet-control-attribution.leaflet-control': {
            display: 'none',
        },
        '& .windy-map-2__controls': {
            backgroundColor: 'transparent',
            height: 0,
            paddingTop: 0,
        },
        '& .map-list.model-list': {
            bottom: 5,
        },
        '& .map-list.type-list': {
            bottom: 45,
        },
        '& .windy-map .unit-temperature-list': {
            bottom: 85,
        },
        '& .windy-map .unit-speed-list': {
            bottom: 85,
        },

        '& .windy-map .windy-map__iso': {
            display: 'none',
        },
        '& .windy-map .windy-map__ptcs': {
            display: 'none',
        },
        '& .windy-map .windy-map__hear': {
            display: 'none',
        },
        '& .windy-map .windy-map__back': {
            display: 'none',
        },
        '& .windy-map .windy-map__fs': {
            display: 'none',
        },
        '& .windy-map .windy-map__progress': {
            display: 'none',
        },

        [theme.breakpoints.down('sm')]: {
            '& .leaflet-container': {
                minHeight: 350,
            },
            '& .windy-map': {
                minHeight: 350,
            },
        },
    },
    forecast: {
        '& #headerLinks': {
            display: 'none',
        },
    },
    wind: {
        '& #linksWrap': {
            display: 'none',
        },
    },
})
