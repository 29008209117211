import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../../common/BoatOnComponent'
import styles from './Styles/InsuranceInqueryDetailsCss'
import dictionary from './Dictionary/InsuranceInqueryDetailsDico'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import { API_URL } from '../../../services/config.service'
import InsuranceBoxRecap from './InsuranceBoxRecap'
import { LocalizedLink } from '../../../languages'
import CaptainPaul from '../../../images/CaptainPaul.png'
import CheckIcon from '@material-ui/icons/Check'
import Modal from '@material-ui/core/Modal'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'
import InsuranceModal from './InsuranceModal'
import StatusTitle from '../../common/StatusTitle'

class InsuranceInqueryDetails extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {}

        this.handleOpenDevisDetails = this.handleOpenDevisDetails.bind(this)
        this.handleCloseDevisDetails = this.handleCloseDevisDetails.bind(this)
    }

    getDiffPrice() {
        const { quotations, insurance } = this.props
        let miniPrice = -1

        for (let i = 0; i < quotations.length; i++) {
            if (quotations[i].Price < miniPrice || miniPrice === -1)
                miniPrice = quotations[i].Price
        }
        if (insurance.CurrentPrice - miniPrice < 0) return 0
        return insurance.CurrentPrice - miniPrice
    }

    handleOpenDevisDetails(quotation) {
        this.setState({ openDevis: true })
        this.setState({ selectedQuotation: quotation })
    }

    handleCloseDevisDetails() {
        this.setState({ openDevis: false })
        this.setState({ selectedQuotation: {} })
    }

    renderCaptainMessage() {
        const { classes, insurance } = this.props

        if (insurance.InqueryStatus === '3') {
            return <div />
        }

        return (
            <div className={classes.messageAndPaul}>
                <img
                    className={classes.imgCaptainPaul}
                    src={CaptainPaul}
                    width="100"
                    alt="CaptainPaul"
                    title="CaptainPaul"
                    style={{ position: 'static', marginRight: '20px' }}
                />
                <div className={classes.message}>
                    {this.getDiffPrice() > 0 ? (
                        insurance.InqueryStatus === '5' ? (
                            <div className={classes.bubble}>
                                <Typography>
                                    {this.displayText('congratsEnd')}
                                    <span style={{ fontWeight: '500' }}>
                                        {this.getDiffPrice() + ' €/'}
                                        {this.displayText('year')}
                                    </span>
                                    &nbsp;!
                                </Typography>
                                <Typography>
                                    {this.displayText('wp')}
                                </Typography>
                            </div>
                        ) : (
                            <div className={classes.bubble}>
                                <Typography>
                                    {this.displayText('congrats')}
                                    <span style={{ fontWeight: '500' }}>
                                        {this.getDiffPrice() + ' €/'}
                                        {this.displayText('year')}
                                    </span>
                                    &nbsp;!
                                </Typography>
                                <Typography>
                                    {this.displayText('letsGo')}
                                </Typography>
                            </div>
                        )
                    ) : (
                        <div className={classes.bubble}>
                            <Typography>
                                {this.displayText('expertOffer')}
                            </Typography>
                        </div>
                    )}
                    <div className={classes.virgule} />
                </div>
            </div>
        )
    }

    renderStatus(quotation) {
        const { classes } = this.props

        if (quotation.Status === '0') {
            return (
                <div className={classes.line}>
                    <FiberManualRecord
                        style={{
                            margin: 'auto',
                            marginRight: '10px',
                            color: '#c4c4c4',
                        }}
                    />
                    <Typography className={classes.statusWaiting}>
                        ({this.displayText('receive')})
                    </Typography>
                </div>
            )
        } else if (quotation.Status === '1') {
            return (
                <div className={classes.line}>
                    <FiberManualRecord
                        style={{
                            margin: 'auto',
                            marginRight: '10px',
                            color: '#3040a0',
                        }}
                    />
                    <Typography className={classes.statusAccepted}>
                        ({this.displayText('accepted')})
                    </Typography>
                </div>
            )
        } else if (quotation.Status === '2') {
            return (
                <div className={classes.line}>
                    <FiberManualRecord
                        style={{
                            margin: 'auto',
                            marginRight: '10px',
                            color: '#ef5350',
                        }}
                    />
                    <Typography className={classes.statusDeclined}>
                        ({this.displayText('declined')})
                    </Typography>
                </div>
            )
        }
        return <div />
    }

    renderOffers() {
        const { classes, quotations } = this.props

        return quotations.map((quotation, i) => (
            <div key={i} className={classes.offer}>
                <img
                    alt="logo"
                    title="logo"
                    src={API_URL + '/logoinsurances/' + quotation.InsuranceLogo}
                    className={classes.titleLogo}
                />
                <div className={classes.infos}>
                    <div className={classes.titleOffer}>
                        <Typography
                            className={classes.titleOffer}
                            style={{ marginRight: '10px' }}
                        >
                            {quotation.InsuranceName}
                        </Typography>{' '}
                        <StatusTitle status={quotation.Status} />
                    </div>
                    <Typography className={classes.text}>
                        <CheckIcon className={classes.check} />
                        {quotation.Comment}
                    </Typography>
                </div>
                <div className={classes.priceAndButton}>
                    <div className={classes.price}>
                        <Typography className={classes.number}>
                            {quotation.Price}{' '}
                        </Typography>
                        <Typography className={classes.littleBlue} gutterBottom>
                            &nbsp;€/{this.displayText('year')}
                        </Typography>
                    </div>
                    <Button
                        className={classes.button}
                        variant="contained"
                        color="secondary"
                        onClick={e => {
                            this.handleOpenDevisDetails(quotation)
                        }}
                    >
                        {this.displayText('moreInfos')}
                    </Button>
                </div>
            </div>
        ))
    }

    render() {
        const { classes, insurance, quotations, boats, loading } = this.props

        if (loading || !quotations) {
            return <div />
        }

        return (
            <div className={classes.root}>
                <LocalizedLink
                    to={this.AppRoute.InsuranceInqueries}
                    className={classes.linkBack}
                >
                    {this.displayText('comeBack')}
                </LocalizedLink>
                <Typography className={classes.title} gutterBottom>
                    {this.displayText('yourRequest')}
                </Typography>
                <InsuranceBoxRecap
                    insurance={insurance}
                    quotations={quotations}
                    boats={boats}
                />
                <Typography className={classes.title} gutterBottom>
                    {this.displayText('yourOffers')}
                </Typography>
                {this.renderCaptainMessage()}
                {this.renderOffers()}
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.openDevis || false}
                    onClose={this.handleCloseDevisDetails}
                >
                    <div id="modal" className={classes.paper}>
                        <Typography
                            variant="subtitle1"
                            id="simple-modal-description"
                        >
                            {this.state.openDevis && (
                                <InsuranceModal
                                    quotation={this.state.selectedQuotation}
                                    inqueryIdOpen={this.state.inqueryIdOpen}
                                    insurance={insurance}
                                    refresh={this.props.refresh}
                                />
                            )}
                        </Typography>
                    </div>
                </Modal>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        loading: state.fetch.loading,
    }
}

export default connect(mapStateToProps)(
    withStyles(styles)(InsuranceInqueryDetails),
)
