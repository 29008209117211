export default {
    register: {
        FR: 'Inscription',
        EN: 'Register',
    },
    diffPassword: {
        FR: 'Les deux mots de passe ne sont pas identiques',
        EN: 'The two passwords are not identical',
    },
    repeatPassword: {
        FR: 'Répéter le mot de passe',
        EN: 'Repeat the password',
    },
    nbCharacterPassword: {
        FR: 'Votre mot de passe doit faire au moins 6 caractères',
        EN: 'Your password must be at least 6 characters',
    },
    password: {
        FR: 'Mot de passe',
        EN: 'Password',
    },
    invalidEmail: {
        FR: "L'email entré n'est pas valide !",
        EN: 'The email entered is not valid',
    },
    email: {
        FR: 'E-Mail',
        EN: 'E-Mail',
    },
    firstName: {
        FR: 'Prénom',
        EN: 'Firstname',
    },
    lastName: {
        FR: 'Nom',
        EN: 'Lastname',
    },
    approuve: {
        FR: "J'ai lu et j'accepte les ",
        EN: 'I have read and accepted the',
    },
    termsOfUse: {
        FR: "Conditions Générales d'Utilisation ",
        EN: 'General Conditions of Use',
    },
    receiveEmail: {
        FR:
            'Je souhaite recevoir des promotions et des actualités sur la startup BoatOn',
        EN: 'I would like to receive promotions and news about BoatOn',
    },
}
