export default {
    title: {
        FR: `Prenez un temps d'avance`,
        EN: `Get ahead of the game`,
        DE: `Gehen Sie einen Schritt voraus`,
        BG: `Вземете преднина`,
        HR: `Idite korak ispred`,
        DA: `Kom foran spillet`,
        ES: `Adelántate al juego`,
        ET: `Minge mängu ette`,
        FI: `Pääse pelin edelle`,
        EL: `Προχωρήστε πριν το παιχνίδι`,
        HU: `Lépjen előre a játékban`,
        GA: `Téigh chun tosaigh sa chluiche`,
        IT: `Prendi il sopravvento`,
        LV: `Ieņemiet priekšrocību spēlē`,
        LT: `Įveikti žaidimą`,
        MT: `Mur lura mill-logħob`,
        NL: `Kom voor op het spel`,
        PL: `Wyprzedź grę`,
        PT: `Fique à frente do jogo`,
        RO: `Ia-o înaintea jocului`,
        SK: `Dostaňte sa vpred v hre`,
        SL: `Pridite pred igro`,
        SV: `Kom före spelet`,
        CS: `Předejděte hře`,
        RU: `Выйдите вперед в игре`,
        IS: `Komdu á undan leiknum`,
        JA: `ゲームに先行する`,
        KO: `게임을 앞서가세요`,
        UK: `Вийдіть вперед у грі`,
        ZH: `赢在起跑线上`,
    },
    subtitle1: {
        FR: `Le 1`,
        EN: `The 1`,
    },
    subtitle2: {
        FR: `er`,
        EN: `st`,
    },
    subtitle3: {
        FR: `logiciel de maintenance intelligent & éco-responsable`,
        EN: `smart & eco-friendly maintenance software`,
        DE: `intelligente und umweltfreundliche Wartungssoftware`,
        BG: `интелигентен и еко-приятен софтуер за поддръжка`,
        HR: `pametni i ekološki prihvatljiv softver za održavanje`,
        DA: `smart & miljøvenlig vedligeholdelsessoftware`,
        ES: `software de mantenimiento inteligente y respetuoso con el medio ambiente`,
        ET: `nutikas ja keskkonnasõbralik hooldustarkvara`,
        FI: `älykäs ja ympäristöystävällinen huoltosovellus`,
        EL: `έξυπνο & περιβαλλοντικά φιλικό λογισμικό συντήρησης`,
        HU: `intelligens és környezetbarát karbantartó szoftver`,
        GA: `córas úsáidteach & éiceolúnach glanadh bogearraí`,
        IT: `software di manutenzione intelligente e eco-sostenibile`,
        LV: `gudrs un videi draudzīgs uzturēšanas programmatūra`,
        LT: `protingas ir aplinkai draugiškas priežiūros programinė įranga`,
        MT: `softwer għal manutenzjoni ferm u eċo-friendly`,
        NL: `slimme & milieuvriendelijke onderhoudssoftware`,
        PL: `inteligentne i ekologiczne oprogramowanie do konserwacji`,
        PT: `software de manutenção inteligente e ecológico`,
        RO: `software de întreținere inteligent și prietenos cu mediul`,
        SK: `inteligentný a ekologický softvér na údržbu`,
        SL: `pametna in okolju prijazna programska oprema za vzdrževanje`,
        SV: `smart och miljövänlig underhållsprogramvara`,
        CS: `chytrý a ekologicky šetrný softwarový nástroj pro údržbu`,
        RU: `умное и экологически чистое программное обеспечение для обслуживания`,
        IS: `snjallt og umhverfisvænt viðhaldsforrit`,
        JA: `スマートでエコフレンドリーなメンテナンスソフトウェア`,
        KO: `스마트하고 친환경적인 유지보수 소프트웨어`,
        UK: `розумне та екологічно чисте програмне забезпечення для обслуговування`,
        ZH: `智能环保的维护软件`,
    },
    individual: {
        FR: `Particulier`,
        EN: `Individual`,
        DE: `Einzel`,
        BG: `Индивидуален`,
        HR: `Pojedinac`,
        DA: `Individuel`,
        ES: `Individual`,
        ET: `Individuaalne`,
        FI: `Yksittäinen`,
        EL: `Μεμονωμένος`,
        HU: `Egyéni`,
        GA: `Indibhidiúl`,
        IT: `Individuale`,
        LV: `Individuāls`,
        LT: `Individualus`,
        MT: `Individwali`,
        NL: `Individueel`,
        PL: `Indywidualny`,
        PT: `Individual`,
        RO: `Individual`,
        SK: `Individuálny`,
        SL: `Posamezni`,
        SV: `Individuell`,
        CS: `Jednotlivý`,
        RU: `Индивидуальный`,
        IS: `Einbýlishús`,
        JA: `個人`,
        KO: `개인`,
        UK: `Індивідуальний`,
        ZH: `个人`,
    },
    custom: {
        FR: `Vous avez plus de 10 bateaux ou souhaitez des fonctionnalités sur-mesure ?`,
        EN: `Do you have more than 10 boats or do you want customised features?`,
        DE: `Haben Sie mehr als 10 Boote oder möchten Sie maßgeschneiderte Funktionen?`,
        BG: `Имате ли повече от 10 лодки или искате ли персонализирани функции?`,
        HR: `Imate li više od 10 brodova ili želite prilagođene značajke?`,
        DA: `Har du mere end 10 både eller vil du have tilpassede funktioner?`,
        ES: `¿Tiene más de 10 barcos o desea funciones personalizadas?`,
        ET: `Kas teil on üle 10 paati või soovite kohandatud funktsioone?`,
        FI: `Onko sinulla yli 10 venettä tai haluatko mukautettuja ominaisuuksia?`,
        EL: `Έχετε περισσότερα από 10 σκάφη ή θέλετε εξατομικευμένες λειτουργίες;`,
        HU: `Van több mint 10 hajója, vagy egyedi funkciókat szeretne?`,
        GA: `An bhfuil níos mó ná 10 bád agat nó an mian leat gnéithe a shaincheapadh?`,
        IT: `Hai più di 10 barche o desideri funzionalità personalizzate?`,
        LV: `Vai jums ir vairāk nekā 10 laivas vai vēlaties pielāgotas funkcijas?`,
        LT: `Ar turite daugiau nei 10 laivų ar norite individualizuotų funkcijų?`,
        MT: `Għandek aktar minn 10 dgħajjes jew tixtieq għażliet personalizzati?`,
        NL: `Heeft u meer dan 10 boten of wilt u aangepaste functies?`,
        PL: `Czy masz więcej niż 10 łodzi lub chcesz niestandardowe funkcje?`,
        PT: `Tem mais de 10 barcos ou quer funcionalidades personalizadas?`,
        RO: `Aveți mai mult de 10 bărci sau doriți funcționalități personalizate?`,
        SK: `Máte viac ako 10 lodí alebo chcete prispôsobené funkcie?`,
        SL: `Imate več kot 10 čolnov ali želite prilagojene funkcije?`,
        SV: `Har du mer än 10 båtar eller vill du ha anpassade funktioner?`,
        CS: `Máte více než 10 lodí nebo chcete vlastní funkce?`,
        RU: `У вас более 10 лодок или вам нужны индивидуальные функции?`,
        IS: `Ertu með meira en 10 báta eða viltu sérsniðnar eiginleika?`,
        JA: `10艇以上のボートを持っていますか、またはカスタマイズされた機能をご希望ですか？`,
        KO: `10척 이상의 보트가 있거나 사용자 정의 기능이 필요하십니까?`,
        UK: `У вас є більше 10 човнів або ви хочете індивідуальні функції?`,
        ZH: `您是否拥有超过10艘船，或者您需要定制功能？`,
    },
    custom1: {
        FR: `Vous pouvez bénéficier de réductions supplémentaires.`,
        EN: `You can benefit from additional discounts`,
        DE: `Sie können von zusätzlichen Rabatten profitieren`,
        BG: `Можете да се възползвате от допълнителни отстъпки`,
        HR: `Možete imati dodatne popuste`,
        DA: `Du kan få ekstra rabatter`,
        ES: `Puede beneficiarse de descuentos adicionales`,
        ET: `Te saate lisasoodustusi`,
        FI: `Voit hyötyä lisäalennuksista`,
        EL: `Μπορείτε να επωφεληθείτε από επιπλέον έκπτωση`,
        HU: `További kedvezményekre jogosult`,
        GA: `Is féidir leat buntáistí breise a bhaint amach`,
        IT: `Puoi beneficiare di ulteriori sconti`,
        LV: `Jūs varat gūt papildu atlaidi`,
        LT: `Galite pasinaudoti papildomomis nuolaidomis`,
        MT: `Tista' tniedi mill-inqas b'rabtiet addizzjonali`,
        NL: `U kunt profiteren van extra kortingen`,
        PL: `Możesz skorzystać z dodatkowych zniżek`,
        PT: `Você pode se beneficiar de descontos adicionais`,
        RO: `Puteți beneficia de reduceri suplimentare`,
        SK: `Môžete využiť ďalšie zľavy`,
        SL: `Lahko koristite dodatne popuste`,
        SV: `Du kan dra nytta av ytterligare rabatter`,
        CS: `Můžete využít další slevy`,
        RU: `Вы можете воспользоваться дополнительными скидками`,
        IS: `Þú getur nýtt þér aukahluta`,
        JA: `追加の割引を受けることができます`,
        KO: `추가 할인 혜택을 받을 수 있습니다`,
        UK: `Ви можете скористатися додатковими знижками`,
        ZH: `您可以享受额外的折扣`,
    },

    contact: {
        FR: `Contactez-nous`,
        EN: `Contact us`,
        DE: `Kontaktieren Sie uns`,
        BG: `Свържете се с нас`,
        HR: `Kontaktirajte nas`,
        DA: `Kontakt os`,
        ES: `Contáctenos`,
        ET: `Võtke meiega ühendust`,
        FI: `Ota yhteyttä meihin`,
        EL: `Επικοινωνήστε μαζί μας`,
        HU: `Vegye fel velünk a kapcsolatot`,
        GA: `Déan teagmháil linn`,
        IT: `Contattaci`,
        LV: `Sazinieties ar mums`,
        LT: `Susisiekite su mumis`,
        MT: `Ikkuntattjana`,
        NL: `Neem contact met ons op`,
        PL: `Skontaktuj się z nami`,
        PT: `Entre em contato conosco`,
        RO: `Contactează-ne`,
        SK: `Kontaktujte nás`,
        SL: `Stopite v stik z nami`,
        SV: `Kontakta oss`,
        CS: `Kontaktujte nás`,
        RU: `Свяжитесь с нами`,
        IS: `Hafa samband við okkur`,
        JA: `お問い合わせ`,
        KO: `문의하기`,
        UK: `Зв’яжіться з нами`,
        ZH: `联系我们`,
    },
    confiance: {
        FR: `Ils nous font confiance`,
        EN: `They trust us`,
        DE: `Sie vertrauen uns`,
        BG: `Те ни вярват`,
        HR: `Oni nam vjeruju`,
        DA: `De stoler på os`,
        ES: `Confían en nosotros`,
        ET: `Nad usaldavad meid`,
        FI: `He luottavat meihin`,
        EL: `Μας εμπιστεύονται`,
        HU: `Ránk bíznak`,
        GA: `Creideann siad ionainn`,
        IT: `Ci fidano`,
        LV: `Viņi mums uzticas`,
        LT: `Jie mums pasitiki`,
        MT: `Jiddipendu minna`,
        NL: `Ze vertrouwen ons`,
        PL: `Ufają nam`,
        PT: `Eles confiam em nós`,
        RO: `Ei ne încred`,
        SK: `Dôverujú nám`,
        SL: `Zaupajo nam`,
        SV: `De litar på oss`,
        CS: `Důvěřují nám`,
        RU: `Они доверяют нам`,
        IS: `Þeir treysta okkur`,
        JA: `彼らは私たちを信頼しています`,
        KO: `그들은 우리를 신뢰합니다`,
        UK: `Вони нам довіряють`,
        ZH: `他们信任我们`,
    },
    more1: {
        FR: `Besoin de plus de fonctionnalités ? Accédez aux offres “Boat Manager”`,
        EN: `Need more features? Access the "Boat Manager" offers`,
        DE: `Mehr Funktionen benötigt? Greifen Sie auf die Angebote "Boat Manager" zu`,
        BG: `Нуждаете се от повече функции? Влезте в "Оферти за управление на лодки"`,
        HR: `Trebate više značajki? Pristupite ponudama "Upravitelja brodovima"`,
        DA: `Har du brug for flere funktioner? Få adgang til "Bådchef" -tilbudene`,
        ES: `¿Necesitas más funciones? Accede a las ofertas de "Gestor de Barcos"`,
        ET: `Vajate rohkem funktsioone? Pääsege juurde "Paadihalduri" pakkumistele`,
        FI: `Tarvitsetko lisäominaisuuksia? Siirry "Veneenjohtajan" tarjouksiin`,
        EL: `Χρειάζεστε περισσότερες λειτουργίες; Αποκτήστε πρόσβαση στις προσφορές "Διαχειριστής Σκαφών"`,
        HU: `További funkciókra van szüksége? Hozzáférhet a "Boat Manager" ajánlatokhoz`,
        GA: `An bhfuil níos mó gnéithe ag teastáil uait? Seol chuig na tairiscintí "Bainisteoir Báid"`,
        IT: `Hai bisogno di più funzionalità? Accedi alle offerte "Gestore della Barca"`,
        LV: `Vai nepieciešamas papildu funkcijas? Pieejiet "Kugniecības vadītāja" piedāvājumiem`,
        LT: `Reikia daugiau funkcijų? Pasiekite "Laivo vadovo" pasiūlymus`,
        MT: `Bżonn aktar karatteristiċi? Ikkuntattja l-“Offerti tal-Maniġer tal-Baħar”`,
        NL: `Meer functies nodig? Toegang krijgen tot de "Boat Manager" aanbiedingen`,
        PL: `Potrzebujesz więcej funkcji? Uzyskaj dostęp do ofert "Kierownika łodzi"`,
        PT: `Precisa de mais funcionalidades? Acesse as ofertas de "Gerente de Barcos"`,
        RO: `Aveți nevoie de mai multe funcționalități? Accesați ofertele "Manager de Bărci"`,
        SK: `Potrebujete viac funkcií? Získajte prístup k ponukám "Správcu lode"`,
        SL: `Potrebujete več funkcij? Dostopajte do ponudb "Upravitelja čolnov"`,
        SV: `Behöver du fler funktioner? Få tillgång till "Båtchef" -erbjudanden`,
        CS: `Potřebujete více funkcí? Získejte přístup k nabídkám "Manažer lodi"`,
        RU: `Нужно больше функций? Получите доступ к предложениям "Менеджера лодок"`,
        IS: `Þarftu fleiri eiginleika? Fáðu aðgang að boðum "Bátastjóra"`,
        JA: `さらなる機能が必要ですか？「ボートマネージャー」のオファーにアクセスしてください`,
        KO: `더 많은 기능이 필요하신가요? "보트 매니저" 제공을 이용하세요`,
        UK: `Потрібні додаткові функції? Отримайте доступ до пропозицій "Менеджера човнів"`,
        ZH: `需要更多功能吗？访问“船舶管理器”提供的服务`,
    },
    more2: {
        FR: `Besoin de moins de fonctionnalités ? Accédez aux offres “Particulier”`,
        EN: `Need fewer features? Go to the "Individual" offers`,
        DE: `Benötigen Sie weniger Funktionen? Gehen Sie zu den "Individuellen" Angeboten`,
        BG: `Нуждаете се от по-малко функции? Отидете на "Индивидуални" оферти`,
        HR: `Trebate manje značajki? Idite na "Pojedinačne" ponude`,
        DA: `Har du brug for færre funktioner? Gå til "Individuelle" tilbud`,
        ES: `¿Necesitas menos funciones? Ve a las ofertas "Individuales"`,
        ET: `Vajate vähem funktsioone? Minge "Individuaalsete" pakkumiste juurde`,
        FI: `Tarvitsetko vähemmän ominaisuuksia? Siirry "Yksittäisiin" tarjouksiin`,
        EL: `Χρειάζεστε λιγότερες λειτουργίες; Μεταβείτε στις προσφορές "Ενιαίου"`,
        HU: `Kevesebb funkcióra van szüksége? Menjen az "Egyéni" ajánlatokhoz`,
        GA: `An bhfuil níos lú gnéithe uait? Téigh chuig na tairiscintí "Aonair"`,
        IT: `Hai bisogno di meno funzionalità? Vai alle offerte "Individuali"`,
        LV: `Vajadzīgas mazāk funkcijas? Dodieties uz "Individuālajām" piedāvājumiem`,
        LT: `Reikia mažiau funkcijų? Eikite į "Individuali" pasiūlymus`,
        MT: `Bżonn anqas karatteristiċi? Mur l-“Individwali” offerti`,
        NL: `Behoefte aan minder functies? Ga naar de "Individuele" aanbiedingen`,
        PL: `Potrzebujesz mniej funkcji? Przejdź do ofert "Indywidualnych"`,
        PT: `Precisa de menos funcionalidades? Vá para as ofertas "Individuais"`,
        RO: `Aveți nevoie de mai puține funcționalități? Mergeți la ofertele "Individuale"`,
        SK: `Potrebujete menej funkcií? Prejdite na "Individuálne" ponuky`,
        SL: `Potrebujete manj funkcij? Pojdi na "Posamezne" ponudbe`,
        SV: `Behöver du färre funktioner? Gå till "Individuella" erbjudanden`,
        CS: `Potřebujete méně funkcí? Jděte na "Individuální" nabídky`,
        RU: `Нужны меньше функций? Перейдите к "Индивидуальным" предложениям`,
        IS: `Þarftu færri eiginleika? Faraðu á "Einstaklings" tilboðin`,
        JA: `機能が少なくてもいいですか？「個人」オファーに移動します`,
        KO: `더 적은 기능이 필요하십니까? "개인" 제공으로 이동`,
        UK: `Потрібно менше функцій? Перейдіть до пропозицій "Індивідуальні"`,
        ZH: `需要更少的功能吗？前往“个人”优惠`,
    },
    offer1: {
        FR: `Fini les permis mouillés ou tombés à l’eau, stockez gratuitement tous vos documents en ligne`,
        EN: `No more wet or fallen licenses, store all your documents online for free`,
        DE: `Keine nassen oder heruntergefallenen Lizenzen mehr, speichern Sie alle Ihre Dokumente kostenlos online`,
        BG: `Няма повече мокри или паднали лицензи, запазвайте всичките си документи онлайн безплатно`,
        HR: `Nema više mokrih ili pali licenci, besplatno pohranjujte sve svoje dokumente online`,
        DA: `Ingen flere våde eller faldne licenser, opbevar alle dine dokumenter online gratis`,
        ES: `No más licencias mojadas o caídas, almacene todos sus documentos en línea de forma gratuita`,
        ET: `Enam märjaid ega kukkunud litsentse, hoidke kõik oma dokumendid tasuta veebis`,
        FI: `Ei enää märkiä tai pudonneita lisenssejä, säilytä kaikki asiakirjasi ilmaiseksi verkossa`,
        EL: `Δεν υπάρχουν πια βρεγμένες ή πτωμένες άδειες, αποθηκεύστε όλα τα έγγραφά σας δωρεάν στο διαδίκτυο`,
        HU: `Nincsenek többé nedves vagy elesett engedélyek, tárolja az összes dokumentumát ingyen online`,
        GA: `Níl aon ceadúnais fliuch nó titim ann níos mó, stóráil do chuid cáipéisí ar líne saor in aisce`,
        IT: `Niente più licenze bagnate o cadute, conserva tutti i tuoi documenti online gratuitamente`,
        LV: `Vairs nav mitru vai nokritušu atļauju, bez maksas saglabājiet visus savus dokumentus tiešsaistē`,
        LT: `Nebėra drėgnų ar nukritusių licencijų, saugokite visus savo dokumentus internete nemokamai`,
        MT: `L-ebda licenzi mħabbta jew inqas, stabbilixxi d-dokumenti kollha tiegħek bl-oħrajn b'xejn fuq l-internet`,
        NL: `Geen natte of gevallen vergunningen meer, sla al uw documenten gratis online op`,
        PL: `Nie ma już mokrych lub upadłych licencji, przechowuj wszystkie swoje dokumenty online za darmo`,
        PT: `Não mais licenças molhadas ou caídas, armazene todos os seus documentos online gratuitamente`,
        RO: `Nu mai sunt permise ude sau căzute, stocați toate documentele dvs. online gratuit`,
        SK: `Už nie sú mokré alebo padnuté licencie, ukladajte všetky svoje dokumenty online zadarmo`,
        SL: `Ni več mokrih ali padlih dovoljenj, brezplačno shranite vse svoje dokumente na spletu`,
        SV: `Inga fler blöta eller fallna licenser, lagra alla dina dokument online gratis`,
        CS: `Žádné mokré nebo padlé licence, uložte všechny své dokumenty online zdarma`,
        RU: `Больше нет мокрых или упавших лицензий, храните все свои документы онлайн бесплатно`,
        IS: `Engar meiri vatnskenndar eða fallnar leyfiskirnar, geymið alla skjöl ykkar á netinu ókeypis`,
        JA: `もう湿ったり落ちたライセンスはありません。すべてのドキュメントを無料でオンラインで保存します`,
        KO: `더 이상 젖거나 떨어진 라이선스가 없습니다. 모든 문서를 온라인으로 무료로 저장하십시오`,
        UK: `Більше немає мокрих або впавших ліцензій, зберігайте всі свої документи в Інтернеті безкоштовно`,
        ZH: `不再有湿润或摔落的许可证，请免费在线存储所有文件`,
    },
    offer2: {
        FR: `L’essentiel pour commencer à simplifier la gestion de son bateau`,
        EN: `The essentials to start simplifying your boat management`,
        DE: `Das Wesentliche, um mit der Vereinfachung der Bootverwaltung zu beginnen`,
        BG: `Основното, за да започнете да опростявате управлението на кораба си`,
        HR: `Osnove za početak pojednostavljenja upravljanja brodom`,
        DA: `Det væsentlige for at begynde at forenkle din bådadministration`,
        ES: `Los elementos esenciales para empezar a simplificar la gestión de tu barco`,
        ET: `Põhiline alustamiseks oma paadi halduse lihtsustamiseks`,
        FI: `Olennaiset asiat veneen hallinnan yksinkertaistamiseen`,
        EL: `Τα απαραίτητα για να αρχίσετε να απλοποιείτε τη διαχείριση του σκάφους σας`,
        HU: `Az alapok, hogy elkezdje egyszerűsíteni a hajókezelést`,
        GA: `Na bunúsacha chun tosú ag simpliú d'eagrán do bhád`,
        IT: `Gli elementi essenziali per iniziare a semplificare la gestione della tua barca`,
        LV: `Būtiskās lietas, lai sāktu savas laivas pārvaldības vienkāršošanu`,
        LT: `Pagrindiniai dalykai, kad pradėtumėte supaprastinti savo valdomą laivą`,
        MT: `Il-ħtiġijiet biex tibda tisplika l-gestjoni tal-bajd tiegħek`,
        NL: `De essenties om te beginnen met het vereenvoudigen van uw bootbeheer`,
        PL: `Podstawy do rozpoczęcia upraszczania zarządzania łodzią`,
        PT: `Os fundamentos para começar a simplificar a gestão do seu barco`,
        RO: `Elementele esențiale pentru a începe simplificarea gestionării bărcii dumneavoastră`,
        SK: `Podstatné veci na začatie zjednodušovania riadenia svojho člna`,
        SL: `Osnove za začetek poenostavljanja upravljanja z vašo barko`,
        SV: `De väsentliga sakerna för att börja förenkla din båtadministration`,
        CS: `Základy pro zahájení zjednodušení správy vaší lodě`,
        RU: `Основы для начала упрощения управления вашей лодкой`,
        IS: `Grundvallarnir til að byrja á einföldun bátastjórnunar þinnar`,
        JA: `船の管理を簡素化するための基本`,
        KO: `보트 관리를 간소화하기 위한 필수 요소`,
        UK: `Основи для початку спрощення управління вашою лодкою`,
        ZH: `简化船只管理的基本要素`,
    },
    offer3: {
        FR: `La solution pour gérer une flotte de quelques bateaux sans prise de tête`,
        EN: `The solution to manage a fleet of a few boats without headaches`,
        DE: `Die Lösung zur Verwaltung einer Flotte von ein paar Booten ohne Kopfschmerzen`,
        BG: `Решението за управление на флот от няколко лодки без главоболие`,
        HR: `Rješenje za upravljanje flotom nekoliko brodova bez glavobolje`,
        DA: `Løsningen til at administrere en flåde af et par både uden hovedpine`,
        ES: `La solución para gestionar una flota de unos pocos barcos sin dolores de cabeza`,
        ET: `Lahendus mõne paadi haldamiseks ilma peavaluta`,
        FI: `Ratkaisu muutaman veneen hallitsemiseen ilman päänsärkyä`,
        EL: `Η λύση για τη διαχείριση μιας στόλου με μερικά καράβια χωρίς πονοκέφαλο`,
        HU: `A megoldás arra, hogy fejfájás nélkül kezeljen egy néhány hajóból álló flottát`,
        GA: `An réiteach chun fliúit a bhainistiú ar bhádáin beaga gan leadrán`,
        IT: `La soluzione per gestire una flotta di poche barche senza mal di testa`,
        LV: `Risinājums, kā pārvaldīt dažu laivu flotē bez galvassāpēm`,
        LT: `Sprendimas valdyti kelis laivų laivyną be galvos skausmo`,
        MT: `Is-soluzzjoni biex titmexxa flott ta’ ftit dgħajjes mingħajr uġigħ`,
        NL: `De oplossing om een vloot van een paar boten te beheren zonder hoofdpijn`,
        PL: `Rozwiązanie do zarządzania flotą kilku łodzi bez bólu głowy`,
        PT: `A solução para gerir uma frota de alguns barcos sem dores de cabeça`,
        RO: `Soluția pentru gestionarea unei flote de câteva bărci fără dureri de cap`,
        SK: `Riešenie na riadenie flotily niekoľkých lodí bez bolesti hlavy`,
        SL: `Rešitev za upravljanje flote nekaj ladij brez glavobola`,
        SV: `Lösningen för att hantera en flotta av några båtar utan huvudvärk`,
        CS: `Řešení pro správu flotily několika lodí bez bolesti hlavy`,
        RU: `Решение для управления флотом нескольких лодок без головной боли`,
        IS: `Lausn til að stjórna flota nokkurra báta án höfuðverkja`,
        JA: `頭痛を起こさずに数隻の船の艦隊を管理するための解決策`,
        KO: `두통없이 몇 척의 보트를 관리하는 해결책`,
        UK: `Рішення для управління флотом кількох човнів без головного болю`,
        ZH: `管理几艘船舶的解决方案，无需头痛`,
    },
    offer4: {
        FR: `La possibilité de travailler en équipe et organiser les tâches pour chaque bateau, même sans connexion internet`,
        EN: `The possibility to work in a team and organise the tasks for each boat, even without an internet connection`,
        DE: `Die Möglichkeit, im Team zu arbeiten und die Aufgaben für jedes Boot zu organisieren, auch ohne Internetverbindung`,
        BG: `Възможността да работите в екип и да организирате задачите за всяка лодка, дори без интернет връзка`,
        HR: `Mogućnost rada u timu i organiziranja zadataka za svaki brod, čak i bez internetske veze`,
        DA: `Muligheden for at arbejde i et team og organisere opgaverne for hvert skib, selv uden internetforbindelse`,
        ES: `La posibilidad de trabajar en equipo y organizar las tareas para cada barco, incluso sin conexión a Internet`,
        ET: `Võimalus meeskonnatööks ja iga paadi ülesannete korraldamiseks isegi ilma Interneti-ühenduseta`,
        FI: `Mahdollisuus työskennellä tiimissä ja järjestää tehtävät jokaiselle veneelle, jopa ilman Internet-yhteyttä`,
        EL: `Η δυνατότητα να εργάζεστε σε ομάδα και να οργανώνετε τις εργασίες για κάθε σκάφος, ακόμη και χωρίς σύνδεση στο Internet`,
        HU: `A lehetőség, hogy csapatban dolgozzon és szervezze meg a feladatokat minden hajóra, még internetkapcsolat nélkül is`,
        GA: `An deis a bheith ag obair i bhfoireann agus na tascanna a eagrú do gach bád, fiú gan nasc Idirlín`,
        IT: `La possibilità di lavorare in team e organizzare i compiti per ogni barca, anche senza connessione a Internet`,
        LV: `Iespēja strādāt komandā un organizēt uzdevumus katram laivai, pat bez interneta pieslēguma`,
        LT: `Galimybė dirbti komandoje ir organizuoti užduotis kiekvienai valčiai, net ir be interneto ryšio`,
        MT: `Il-possibbiltà li taħdem f’team u torganizza t-taqsima għal kull dgħajsa, anki mingħajr konnessjoni tal-internet`,
        NL: `De mogelijkheid om in een team te werken en de taken voor elk schip te organiseren, zelfs zonder internetverbinding`,
        PL: `Możliwość pracy w zespole i organizacji zadań dla każdej łodzi, nawet bez połączenia internetowego`,
        PT: `A possibilidade de trabalhar em equipe e organizar as tarefas para cada barco, mesmo sem conexão com a Internet`,
        RO: `Posibilitatea de a lucra în echipă și de a organiza sarcinile pentru fiecare barcă, chiar și fără conexiune la internet`,
        SK: `Možnosť pracovať v tíme a organizovať úlohy pre každú loď, aj bez pripojenia na internet`,
        SL: `Možnost dela v ekipi in organizacije nalog za vsak čoln, tudi brez internetne povezave`,
        SV: `Möjligheten att arbeta i team och organisera uppgifterna för varje båt, även utan internetanslutning`,
        CS: `Možnost práce v týmu a organizace úkolů pro každou loď, i bez internetového připojení`,
        RU: `Возможность работы в команде и организации задач для каждой лодки, даже без интернет-соединения`,
        IS: `Möguleiki til að vinna í liði og skipuleggja verkefni fyrir hvern bát, jafnvel án nettengingar`,
        JA: `チームで作業し、各船のタスクをインターネット接続なしで組織する機会`,
        KO: `팀으로 작업하고 각 보트의 작업을 인터넷 연결없이 조직할 수 있는 기회`,
        UK: `Можливість роботи в команді та організації завдань для кожного човна, навіть без Інтернет-з'єднання`,
        ZH: `即使没有互联网连接，也可以在团队中工作并为每艘船组织任务的机会`,
    },
    offer5: {
        FR: `Travail en équipe, rapports automatiques et statistiques sur l'état de la flotte : la solution idéale pour les superviseurs en manque de temps`,
        EN: `Teamwork, automatic reporting and fleet status statistics: the ideal solution for time-strapped supervisors`,
        DE: `Teamarbeit, automatische Berichterstattung und Flottenstatusstatistiken: die ideale Lösung für zeitlich eingeschränkte Aufsichtspersonen`,
        BG: `Работа в екип, автоматично представяне на доклади и статистика за състоянието на флота: идеалното решение за супервайзорите, които имат ограничено време`,
        HR: `Timski rad, automatsko izvještavanje i statistika o statusu flote: idealno rješenje za nadzornike koji imaju malo vremena`,
        DA: `Samarbejde, automatisk rapportering og flådestatusstatistikker: den ideelle løsning for tidsbegrænsede ledere`,
        ES: `Trabajo en equipo, informes automáticos y estadísticas sobre el estado de la flota: la solución ideal para supervisores con poco tiempo`,
        ET: `Meeskonnatöö, automaatne aruandlus ja laevastiku seisundi statistika: ideaalne lahendus ajapiiranguga juhatajatele`,
        FI: `Tiimityö, automaattinen raportointi ja laivaston tilastot: ihanteellinen ratkaisu ajanpuutteessa oleville esimiehille`,
        EL: `Ομαδική εργασία, αυτόματη αναφορά και στατιστικά για την κατάσταση του στόλου: η ιδανική λύση για επόπτες περιορισμένου χρόνου`,
        HU: `Csapatmunka, automatikus jelentések és flottastátusz statisztika: az ideális megoldás az időhiányos felügyelők számára`,
        GA: `Obair foirne, tuarascálacha huathoibríoch agus staitisticí maidir le stádas na flóta: an réiteach idéalach do stiúrthóirí am iomaíochta`,
        IT: `Lavoro di squadra, segnalazione automatica e statistiche sullo stato della flotta: la soluzione ideale per i supervisori con poco tempo`,
        LV: `Darbs komandā, automātiskā ziņošana un flotes statusa statistika: ideālais risinājums laika ierobežojumus piedzīvojošiem uzraugiem`,
        LT: `Komandinis darbas, automatinis ataskaitų pateikimas ir laivų būklės statistika: idealus sprendimas laiko stokojantiems priežiūros asmenims`,
        MT: `Ħidma f’kumpless, rapporti awtomatiċi u statistika dwar l-istatus tal-flotta: is-soluzzjoni ideali għall-isuperintendenti li għandhom ftit ħin`,
        NL: `Teamwerk, automatische rapportage en statistieken over de status van de vloot: de ideale oplossing voor tijdsgebrek bij supervisors`,
        PL: `Praca zespołowa, automatyczne raportowanie i statystyki dotyczące stanu floty: idealne rozwiązanie dla nadzorców z ograniczonym czasem`,
        PT: `Trabalho em equipe, relatórios automáticos e estatísticas sobre o estado da frota: a solução ideal para supervisores com pouco tempo`,
        RO: `Muncă în echipă, raportare automată și statistici privind starea flotei: soluția ideală pentru supraveghetorii cu timp limitat`,
        SK: `Tímová práca, automatické správy a štatistiky o stave flotily: ideálne riešenie pre dozorcov so zúfalým časom`,
        SL: `Ekipno delo, samodejno poročanje in statistika o stanju flote: idealna rešitev za nadzornike s časovnimi`,
        SV: `Teamarbete, automatisk rapportering och statistik om flottstatus: den idealiska lösningen för tidsbegränsade övervakare`,
        CS: `Týmová práce, automatické hlášení a statistiky o stavu flotily: ideální řešení pro dohledávače s časovým omezením`,
        RU: `Командная работа, автоматическая отчетность и статистика состояния флота: идеальное решение для ограниченных по времени наблюдателей`,
        IS: `Hóparbeiði, sjálfvirkar skýrslur og stöðuflotastatistík: frábær lausn fyrir tímaskornum eftirlitsmönnum`,
        JA: `チームワーク、自動報告、艦隊状態統計：時間の制約された監督者に最適なソリューション`,
        KO: `팀워크, 자동 보고 및 함대 상태 통계: 시간이 촉박한 감독자를 위한 이상적인 솔루션`,
        UK: `Командна робота, автоматична звітність та статистика стану флоту: ідеальне рішення для обмежених у часі спостерігачів`,
        ZH: `团队合作，自动报告和舰队状态统计：对时间紧张的监督者来说是理想的解决方案`,
    },
    offer6: {
        FR: `La gestion de votre flotte en mode pilote auto : bénéficiez de toute l'intelligence du BoatOn Book et boostez votre croissance`,
        EN: `Managing your fleet in auto pilot mode: benefit from all the intelligence of the BoatOn Book and boost your growth`,
        DE: `Verwalten Sie Ihre Flotte im Autopilotmodus: Nutzen Sie die Intelligenz des BoatOn Buchs und steigern Sie Ihr Wachstum`,
        BG: `Управление на вашата флота в режим на автопилот: възползвайте се от цялата интелигентност на BoatOn Book и увеличете растежа си`,
        HR: `Upravljanje vašim flotom u automatskom pilot modu: koristite sve inteligencije BoatOn Book-a i povećajte svoj rast`,
        DA: `Håndtering af din flåde i autopilottilstand: få gavn af al intelligensen fra BoatOn Book og øg din vækst`,
        ES: `Gestión de su flota en modo piloto automático: benefíciese de toda la inteligencia del BoatOn Book y aumente su crecimiento`,
        ET: `Haldke oma laevastikku autopiloodirežiimis: kasutage BoatOn Booki kogu intelligentsi ja suurendage oma kasvu`,
        FI: `Laivaston hallinta autopilottitilassa: hyödynnä kaikki BoatOn Bookin älykkyys ja kasvata kasvuasi`,
        EL: `Διαχείριση του στόλου σας σε λειτουργία αυτόματου πιλότου: επωφεληθείτε από όλη την ευφυία του BoatOn Book και ενισχύστε την ανάπτυξή σας`,
        HU: `Flottája kezelése automatikus pilóta módúban: élvezze a BoatOn Book összes intelligenciáját és növelje növekedését`,
        GA: `Ag bainistiú do bhádach i mód piolótó aonuair: bain úsáid as an eolais ar fad atá sa Leabhar BoatOn agus cuir le do fás`,
        IT: `Gestione della tua flotta in modalità pilota automatico: beneficia di tutta l'intelligenza del BoatOn Book e potenzia la tua crescita`,
        LV: `Jūsu flotes pārvaldīšana automātiskā pilotu režīmā: izmantojiet BoatOn Book visu inteliģenci un palieliniet savu izaugsmi`,
        LT: `Valdykite savo laivyną automatinio pilotavimo režimu: pasinaudokite visa BoatOn Book intelektu ir padidinkite savo augimą`,
        MT: `Il-gestjoni tal-flotta tiegħek fi modalità pilota awtomatika: ngħinu minn l-intelligenza kollha tal-BoatOn Book u izżid it-tkabbir tiegħek`,
        NL: `Uw vloot beheren in de automatische pilootmodus: profiteer van alle intelligentie van het BoatOn Book en stimuleer uw groei`,
        PL: `Zarządzanie flotą w trybie autopilota: korzystaj ze wszystkiej inteligencji BoatOn Book i zwiększaj swój wzrost`,
        PT: `Gerir a sua frota em modo piloto automático: beneficie de toda a inteligência do BoatOn Book e impulsione o seu crescimento`,
        RO: `Gestionarea flotei dvs. în modul pilot automat: beneficiați de toată inteligența BoatOn Book și stimulați-vă creșterea`,
        SK: `Správa vášho flotily v režime autopilota: využite všetku inteligenciu BoatOn Book a zvýšte svoj rast`,
        SL: `Upravljanje vaše flote v avtopilotskem načinu: izkoristite vso inteligenco BoatOn Book in povečajte svojo rast`,
        SV: `Hantera din flotta i autopilotläge: dra nytta av all intelligens från BoatOn Book och öka din tillväxt`,
        CS: `Správa vaší flotily v autopilotním režimu: využijte veškerou inteligenci BoatOn Book a zvyšte svůj růst`,
        RU: `Управление вашим флотом в режиме автопилота: воспользуйтесь всей интеллектуальной мощью BoatOn Book и увеличьте свой рост`,
        IS: `Stjórnun á flotanum þínum í sjálfvirkum stýrimóði: nýttu þér allan vitund BoatOn Book og öflugðu vöxtinn þinn`,
        JA: `自動操縦モードでのフリート管理：BoatOn Bookのすべての知識を活用して成長を促進します`,
        KO: `오토 파일럿 모드에서 함대 관리: BoatOn Book의 모든 지능을 활용하여 성장을 촉진하세요`,
        UK: `Управління вашим флотом в режимі автопілота: скористайтеся всією інтелектуальною потужністю BoatOn Book і збільште свій ріст`,
        ZH: `以自动驾驶模式管理您的舰队：利用BoatOn Book的所有智能，促进您的增长`,
    },
    offerT1: {
        FR: `Mousse`,
        EN: `Mousse`,
    },
    offerT2: {
        FR: `Skipper`,
        EN: `Skipper`,
    },
    offerT3: {
        FR: `Bosco`,
        EN: `Bosco`,
    },
    offerT4: {
        FR: `Capitaine`,
        EN: `Captain`,
    },
    offerT6: {
        FR: `Amiral`,
        EN: `Admiral`,
    },
    offerT5: {
        FR: `Commandant`,
        EN: `Commander`,
    },
    desc0: {
        FR: `Très bonne application que j'utilise pour mon semi-rigide, des personnes très sérieuses qui vous suivent et répondent à toute question. Je recommande fortement.`,
        EN: `Very good application that I use for my semi-rigid, very serious people who follow you and answer any question. I highly recommend.`,
        DE: `Sehr gute Anwendung, die ich für mein Halbstarres benutze, sehr ernsthafte Leute, die Ihnen folgen und jede Frage beantworten. Ich empfehle es sehr.`,
        BG: `Много добро приложение, което използвам за моя полукръстник, много сериозни хора, които ви следят и отговарят на всякакви въпроси. Горещо препоръчвам.`,
        HR: `Vrlo dobra aplikacija koju koristim za svoj polučvrsti čamac, vrlo ozbiljni ljudi koji vas prate i odgovaraju na sva pitanja. Toplo preporučujem.`,
        DA: `Meget god applikation, som jeg bruger til min halvstiv, meget seriøse mennesker, der følger dig og besvarer eventuelle spørgsmål. Jeg anbefaler stærkt.`,
        ES: `Muy buena aplicación que uso para mi semirrígida, personas muy serias que te siguen y responden cualquier pregunta. ¡Lo recomiendo mucho!`,
        ET: `Väga hea rakendus, mida kasutan oma pooltahuka jaoks, väga tõsised inimesed, kes jälgivad teid ja vastavad igale küsimusele. Soovitan väga.`,
        FI: `Erittäin hyvä sovellus, jota käytän puolijäykälleni, erittäin vakavasti otettavia ihmisiä, jotka seuraavat sinua ja vastaavat mihin tahansa kysymykseen. Suosittelen lämpimästi.`,
        EL: `Πολύ καλή εφαρμογή που χρησιμοποιώ για το ημισκληρό μου, πολύ σοβαρά άτομα που σας ακολουθούν και απαντούν σε οποιαδήποτε ερώτηση. Το συνιστώ ανεπιφύλακτα.`,
        HU: `Nagyon jó alkalmazás, amit félig merevemhez használok, nagyon komoly emberek, akik követnek és válaszolnak minden kérdésre. Nagyon ajánlom.`,
        GA: `Feidhmchlár iontach a bhfuilim ag baint úsáide as dá bharr m'iascach leathchreise, daoine an-chinnte a leanann tú agus a fhreagraíonn ar aon cheist. Molaim go hard.`,
        IT: `Applicazione molto buona che uso per il mio semirigido, persone molto serie che ti seguono e rispondono a qualsiasi domanda. Consiglio vivamente.`,
        LV: `Ļoti labs lietojumprogramma, ko izmantoju savam puscietsilim, ļoti nopietni cilvēki, kas jūs seko un atbild uz jebkuru jautājumu. Ļoti iesaku.`,
        LT: `Labai gera programa, kurią naudoju savo pusiau standžiam laivui, labai rimti žmonės, kurie jus seka ir atsako į bet kokį klausimą. Labai rekomenduoju.`,
        MT: `Aplikazzjoni tajba ħafna li jużaw għall-buttina semi-taqsima tiegħi, ħaddiema serji ħafna li jsegwuok u jirrispondu għal kwalunkwe mistoqsija. Nirrikommenda b’mod ħoti.`,
        NL: `Zeer goede applicatie die ik gebruik voor mijn semi-stijve, zeer serieuze mensen die je volgen en elk vraag beantwoorden. Ik raad het ten zeerste aan.`,
        PL: `Bardzo dobra aplikacja, którą używam do mojego półsztywnego, bardzo poważni ludzie, którzy cię śledzą i odpowiadają na każde pytanie. Gorąco polecam.`,
        PT: `Aplicativo muito bom que uso para o meu semi-rígido, pessoas muito sérias que te seguem e respondem a qualquer pergunta. Eu recomendo muito.`,
        RO: `Aplicație foarte bună pe care o folosesc pentru semi-rigidul meu, oameni foarte serioși care te urmăresc și răspund la orice întrebare. Recomand cu căldură.`,
        SK: `Veľmi dobrá aplikácia, ktorú používam na svoj polučlnný, veľmi vážni ľudia, ktorí vás sledujú a odpovedajú na akúkoľvek otázku. Veľmi odporúčam.`,
        SL: `Zelo dobra aplikacija, ki jo uporabljam za svoj poltrdi čoln, zelo resni ljudje, ki vas spremljajo in odgovorijo na vsako vprašanje. Zelo priporočam.`,
        SV: `Mycket bra applikation som jag använder för min halvstyva, mycket seriösa människor som följer dig och svarar på alla frågor. Jag rekommenderar starkt.`,
        CS: `Velmi dobrá aplikace, kterou používám pro svou polosloupovou, velmi vážní lidé, kteří vás sledují a odpovídají na jakoukoli otázku. Velmi doporučuji.`,
        RU: `Очень хорошее приложение, которое я использую для своего полуторного, очень серьезные люди, которые следят за вами и отвечают на любые вопросы. Я настоятельно рекомендую.`,
        IS: `Mjög góð forrit sem ég nota fyrir hálfstíf minn, mjög alvarlegir fólk sem fylgir þér og svara öllum spurningum. Ég mæli eindregið með því.`,
        JA: `私が私のセミリジッドボートに使用している非常に良いアプリケーション、あなたを追いかけて任意の質問に答える非常に真剣な人々。 お勧めします。`,
        KO: `내 반딧불이 용으로 사용하는 매우 좋은 애플리케이션, 당신을 따라 다니고 모든 질문에 답하는 매우 진지한 사람들. 강력 추천합니다.`,
        UK: `Дуже хороший додаток, який я використовую для свого напівжорсткого човна, дуже серйозні люди, які слідкують за вами та відповідають на будь-яке запитання. Я настійно рекомендую.`,
        ZH: `非常好的应用程序，我用于我的半刚性，非常认真的人跟着你回答任何问题。 我强烈推荐。`,
    },
    desc1: {
        FR: `Tout simplement la meilleure réponse à la gestion de mon bateau. Facile et efficace.`,
        EN: `Simply the best answer to managing my boat. Easy and efficient.`,
        DE: `Einfach die beste Antwort auf das Management meines Bootes. Einfach und effizient.`,
        BG: `Просто най-добрият отговор за управлението на моя кораб. Лесен и ефективен.`,
        HR: `Jednostavno najbolji odgovor na upravljanje mojim brodom. Jednostavno i učinkovito.`,
        DA: `Simpelthen det bedste svar på at administrere min båd. Nemt og effektivt.`,
        ES: `Simplemente la mejor respuesta para gestionar mi barco. Fácil y eficiente.`,
        ET: `Lihtsalt parim vastus minu paadi haldamiseks. Lihtne ja efektiivne.`,
        FI: `Yksinkertaisesti paras vastaus veneeni hallintaan. Helppoa ja tehokasta.`,
        EL: `Απλώς η καλύτερη απάντηση για τη διαχείριση του σκάφους μου. Εύκολη και αποτελεσματική.`,
        HU: `Egyszerűen a legjobb válasz a hajóm kezelésére. Egyszerű és hatékony.`,
        GA: `Is é sin go héasca an freagra is fearr ar bhainistiú mo bháid. Simplí agus éifeachtach.`,
        IT: `Semplicemente la migliore risposta per gestire la mia barca. Facile ed efficiente.`,
        LV: `Vienkārši labākais atbilde uz manu laivu pārvaldību. Viegli un efektīvi.`,
        LT: `Tiesiog geriausias atsakymas į mano valdomą laivą. Paprasta ir efektyvu.`,
        MT: `Sempliċiment ir-riżposta aħjar għall-gestjoni tal-battell tiegħi. Facilment u effiċjenti.`,
        NL: `Gewoon het beste antwoord op het beheren van mijn boot. Makkelijk en efficiënt.`,
        PL: `Po prostu najlepsza odpowiedź na zarządzanie moją łodzią. Łatwe i skuteczne.`,
        PT: `Simplesmente a melhor resposta para gerir o meu barco. Fácil e eficiente.`,
        RO: `Pur și simplu cea mai bună răspuns pentru gestionarea bărcii mele. Ușor și eficient.`,
        SK: `Jednoducho najlepšia odpoveď na riadenie svojej lode. Jednoduché a účinné.`,
        SL: `Preprosto najboljši odgovor na upravljanje moje ladje. Enostavno in učinkovito.`,
        SV: `Helt enkelt det bästa svaret på att hantera min båt. Enkelt och effektivt.`,
        CS: `Jednoduše nejlepší odpověď na správu mé lodě. Snadné a efektivní.`,
        RU: `Просто лучший ответ на управление моим судном. Просто и эффективно.`,
        IS: `Einfaldlega besta svarið við stjórnun báts míns. Auðvelt og árangursríkt.`,
        JA: `私のボートを管理するための最善の答え。 簡単で効率的です。`,
        KO: `내 보트를 관리하는 가장 좋은 대답입니다. 쉽고 효율적입니다.`,
        UK: `Просто найкраща відповідь на управління моїм човном. Просто і ефективно.`,
        ZH: `简单地说，这是管理我的船的最佳答案。 简单而高效。`,
    },
    desc2: {
        EN: `A dynamic team that listens to its customers! A big thank you to them for their responsiveness! I strongly recommend them.`,
        FR: `Une équipe dynamique et à l’écoute de ses clients ! Un grand merci à eux pour leur réactivité ! Je les recommande fortement.`,
        DE: `Ein dynamisches Team, das seinen Kunden zuhört! Ein großes Dankeschön an sie für ihre Reaktionsfähigkeit! Ich empfehle sie nachdrücklich.`,
        BG: `Динамичен екип, който слуша своите клиенти! Голямо благодаря на тях за отзивчивостта им! Горещо ги препоръчвам.`,
        HR: `Dinamičan tim koji sluša svoje kupce! Veliko im hvala na njihovoj odzivnosti! Toplo ih preporučujem.`,
        DA: `Et dynamisk team, der lytter til sine kunder! En stor tak til dem for deres hurtige reaktionsevne! Jeg anbefaler dem stærkt.`,
        ES: `¡Un equipo dinámico que escucha a sus clientes! ¡Un gran agradecimiento a ellos por su capacidad de respuesta! Los recomiendo encarecidamente.`,
        ET: `Dünaamiline meeskond, kes kuulab oma kliente! Suur tänu neile nende reageerimisvõime eest! Soovitan neid tugevalt.`,
        FI: `Dynaaminen tiimi, joka kuuntelee asiakkaitaan! Suuri kiitokset heille nopeasta reagoinnista! Suosittelen heitä lämpimästi.`,
        EL: `Μια δυναμική ομάδα που ακούει τους πελάτες της! Ένα μεγάλο ευχαριστώ σε αυτούς για την ανταπόκρισή τους! Τους συνιστώ ανεπιφύλακτα.`,
        HU: `Egy dinamikus csapat, amely hallgat ügyfeleire! Nagy köszönet nekik gyors reagálásukért! Nagyon ajánlom őket.`,
        GA: `Foirgneamh dinimiciúil a éisteann leis na custaiméirí! Buíochas mór dóibh as a bhfreagarthacht! Molaim go hard iad.`,
        IT: `Un team dinamico che ascolta i suoi clienti! Un grande ringraziamento a loro per la loro reattività! Li raccomando vivamente.`,
        LV: `Dinamiska komanda, kas klausās savus klientus! Liels paldies viņiem par viņu reakcijas spēju! Es tos stingri iesaku.`,
        LT: `Dinaminė komanda, kuri klausosi savo klientų! Didelis jiems padėkoti už jų reagavimo gebėjimą! Aš tvirtai juos rekomenduoju.`,
        MT: `Grupp dinamiku li jisma' lis-sidien tiegħu! Merħba kbira għalihom għall-rispons tagħhom! Nirrikommandahom b'kuxjenza.`,
        NL: `Een dynamisch team dat naar zijn klanten luistert! Een grote dank aan hen voor hun responsiviteit! Ik beveel ze sterk aan.`,
        PL: `Dynamiczny zespół, który słucha swoich klientów! Bardzo im dziękuję za ich reaktywność! Gorąco ich polecam.`,
        PT: `Uma equipe dinâmica que ouve seus clientes! Um grande obrigado a eles pela responsividade! Eu os recomendo fortemente.`,
        RO: `O echipă dinamică care ascultă clienții săi! Un mare mulțumesc lor pentru reactivitatea lor! Îi recomand cu tărie.`,
        SK: `Dynamický tím, ktorý počúva svojich zákazníkov! Veľká vďaka im za ich reakčnosť! Vrelo odporúčam.`,
        SL: `Dinamična ekipa, ki posluša svoje stranke! Velika zahvala njim za njihovo odzivnost! Močno jih priporočam.`,
        SV: `Ett dynamiskt team som lyssnar på sina kunder! En stor tack till dem för deras lyhördhet! Jag rekommenderar dem starkt.`,
        CS: `Dynamický tým, který poslouchá své zákazníky! Velký dík jim za jejich reaktivitu! Velmi je doporučuji.`,
        RU: `Динамичная команда, которая слушает своих клиентов! Большое спасибо им за их отзывчивость! Я настоятельно рекомендую их.`,
        IS: `Dynamískt lið sem hlustar á viðskiptavini sína! Stór þakka þeim fyrir viðbrögðin þeirra! Ég mæli eindregið með þeim.`,
        JA: `顧客の声に耳を傾けるダイナミックなチーム！彼らの迅速な対応に感謝します！強くお勧めします。`,
        KO: `고객의 의견을 경청하는 다이나믹한 팀! 그들의 빠른 대응에 감사드립니다! 강력히 추천합니다.`,
        UK: `Динамічна команда, яка слухає своїх клієнтів! Велика подяка їм за їх реактивність! Я наполягаю на рекомендації.`,
        ZH: `一个积极听取客户意见的团队！对于他们的快速响应表示感谢！我强烈推荐他们。`,
    },
    desc3: {
        FR: `Equipe commerciale très réactive et à l'écoute de nos besoins.`,
        EN: `Very responsive sales team that listens to our needs.`,
        DE: `Sehr reaktives Vertriebsteam, das auf unsere Bedürfnisse eingeht.`,
        BG: `Много отзивчив екип за продажби, който слуша нашите нужди.`,
        HR: `Vrlo odzivni prodajni tim koji sluša naše potrebe.`,
        DA: `Meget lydhør salgsteam, der lytter til vores behov.`,
        ES: `Equipo de ventas muy receptivo que escucha nuestras necesidades.`,
        ET: `Väga reageeriv müügimeeskond, kes kuulab meie vajadusi.`,
        FI: `Hyvin reagoiva myyntitiimi, joka kuuntelee tarpeitamme.`,
        EL: `Πολύ ανταποκριτική ομάδα πωλήσεων που ακούει τις ανάγκες μας.`,
        HU: `Nagyon reaktív értékesítési csapat, amely figyelembe veszi igényeinket.`,
        GA: `Foirgneamh díolacháin an-ghnách a éisteann leis na riachtanais atá againn.`,
        IT: `Team commerciale molto reattivo che ascolta le nostre esigenze.`,
        LV: `Ļoti reaktīva pārdošanas komanda, kas klausās mūsu vajadzības.`,
        LT: `Labai reaguojantis pardavimų komanda, kuri klausosi mūsų poreikių.`,
        MT: `Tim ta 'bejgħ b 'rispons tajjeb li jisma 'l-bżonnijiet tagħna.`,
        NL: `Zeer responsief verkoopteam dat naar onze behoeften luistert.`,
        PL: `Bardzo reaktywny zespół sprzedaży, który słucha naszych potrzeb.`,
        PT: `Equipe de vendas muito responsiva que ouve nossas necessidades.`,
        RO: `Echipa de vânzări foarte receptivă care ascultă nevoile noastre.`,
        SK: `Veľmi reaktívny obchodný tím, ktorý počúva naše potreby.`,
        SL: `Zelo odziven prodajni tim, ki posluša naše potrebe.`,
        SV: `Mycket responsiv försäljningsavdelning som lyssnar på våra behov.`,
        CS: `Velmi reaktivní prodejní tým, který poslouchá naše potřeby.`,
        RU: `Очень отзывчивая коммерческая команда, которая слушает наши потребности.`,
        IS: `Mjög viðkunnanleg söluteymi sem hlustar á þarfir okkar.`,
        JA: `私たちのニーズに耳を傾ける非常に反応が良い営業チーム。`,
        KO: `우리의 요구를 듣는 매우 반응이 뛰어난 영업 팀입니다.`,
        UK: `Дуже реактивна комерційна команда, яка слухає наші потреби.`,
        ZH: `非常响应迅速的销售团队，听取我们的需求。`,
    },
    desc4: {
        FR: `Super carnet d’entretien.
        J’ai eu le créateur au téléphone et il a pris le temps de bien tout expliquer. Bon courage pour la suite !`,
        EN: `Great service booklet.
        I had the founder on the phone and he took the time to explain everything well. Good luck with the rest!`,
        DE: `Tolles Serviceheft.
        Ich hatte den Gründer am Telefon und er nahm sich die Zeit, alles gut zu erklären. Viel Glück für den Rest!`,
        BG: `Страхотен сервизен бележник.
        Имаше създателя на телефона и той си взе времето да обясни всичко добре. Успех в бъдеще!`,
        HR: `Odlična usluga.
        Razgovarao sam s osnivačem telefonom i posvetio je vrijeme da sve dobro objasni. Sretno dalje!`,
        DA: `Fantastisk servicehåndbog.
        Jeg havde grundlæggeren i telefonen, og han tog sig tid til at forklare alt godt. Held og lykke med resten!`,
        ES: `Excelente libro de servicio.
        Tuve al fundador al teléfono y se tomó el tiempo para explicarlo todo bien. ¡Buena suerte con el resto!`,
        ET: `Suurepärane teenusleht.
        Rääkisin asutajaga telefonitsi ja ta võttis aega kõige selgitamiseks. Edu ülejäänuga!`,
        FI: `Loistava huoltokirja.
        Minulla oli perustaja puhelimessa ja hän otti aikaa selittää kaiken hyvin. Onnea jatkoon!`,
        EL: `Εξαιρετικό βιβλίο υπηρεσίας.
        Είχα τον ιδρυτή στο τηλέφωνο και πήρε τον χρόνο να εξηγήσει τα πάντα καλά. Καλή τύχη με το υπόλοιπο!`,
        HU: `Nagyszerű szervizkönyv.
        Beszéltem a alapítóval telefonon és időt szánt rá, hogy minden jól megmagyarázza. Sok sikert a többihez!`,
        GA: `Leabhar seirbhíse iontach.
        Bhí an bunaitheoir ar an bhfón agus thóg sé an t-am chun gach rud a mhíniú go maith. Go n-éirí leat leis an gcuid eile!`,
        IT: `Ottimo libretto di servizio.
        Ho parlato con il fondatore al telefono e si è preso il tempo di spiegare tutto bene. Buona fortuna per il resto!`,
        LV: `Lieliska apkopes grāmata.
        Man bija dibinātājs pie telefona, un viņš ņēma laiku, lai visu labi izskaidrotu. Veiksmi turpmāk!`,
        LT: `Puikus aptarnavimo vadovas.
        Buvo įkūrėjas telefonu ir jis paėmė laiko viskam gerai paaiškinti. Sėkmės su likusiu!`,
        MT: `Biljett tal-ħidma kif twila.
        Kelliem il-kostruttur fuq it-telefon u ħa ħin biex jespjega kollox sew. Inħeġġeġlek bil-ġdid!`,
        NL: `Geweldig serviceboekje.
        Ik had de oprichter aan de telefoon en hij nam de tijd om alles goed uit te leggen. Succes met de rest!`,
        PL: `Świetny książeczka serwisowa.
        Miałem założyciela na telefonie i poświęcił czas, aby wszystko dobrze wyjaśnić. Powodzenia z resztą!`,
        PT: `Ótimo livro de serviço.
        Tive o fundador ao telefone e ele tirou tempo para explicar tudo bem. Boa sorte com o resto!`,
        RO: `Carnet de service excelent.
        Am avut fondatorul la telefon și și-a luat timp să explice totul bine. Succes cu restul!`,
        SK: `Skvelá servisná kniha.
        Mal som zakladateľa na telefóne a venoval sa tomu, aby všetko dobre vysvetlil. Veľa šťastia ďalej!`,
        SL: `Odlična storitvena knjiga.
        Imel sem ustanovitelja na telefonu in si je vzel čas, da je vse dobro pojasnil. Srečno s preostankom!`,
        SV: `Utmärkt servicebok.
        Jag hade grundaren i telefon och han tog sig tid att förklara allt väl. Lycka till med resten!`,
        CS: `Skvělý servisní zápisník.
        Měl jsem zakladatele na telefonu a vzal si čas, aby všechno dobře vysvětlil. Hodně štěstí s tím zbytkem!`,
        RU: `Отличный сервисный буклет.
        Я разговаривал с основателем по телефону, и он уделил время, чтобы все хорошо объяснить. Удачи в дальнейшем!`,
        IS: `Frábær þjónustubók.
        Ég hafði stofnleikara í símanum og hann tók sér tíma til að útskýra allt vel. Gangi þér vel með restina!`,
        JA: `素晴らしいサービスブックレット。
        私は創業者と電話で話し、彼はすべてをよく説明する時間を取りました。残りの成功を祈っています！`,
        KO: `최고의 서비스 소책자.
        나는 창업자와 전화로 이야기했고, 그는 모든 것을 잘 설명하기 위해 시간을 할애했습니다. 나머지에 행운을 빕니다!`,
        UK: `Чудовий сервісний буклет.
        Я розмовляв з засновником по телефону, і він відвідав час, щоб все добре пояснити. Удачі в майбутньому!`,
        ZH: `优秀的服务手册。
        我和创始人通了电话，他花时间向我解释了一切。祝你在接下来的旅程中好运！`,
    },
    subscription: {
        FR: 'Abonnement',
        EN: 'Subscription',
        DE: 'Abonnement',
        BG: 'Абонамент',
        HR: 'Pretplata',
        DA: 'Abonnement',
        ES: 'Suscripción',
        ET: 'Tellimus',
        FI: 'Tilaus',
        EL: 'Συνδρομή',
        HU: 'Előfizetés',
        GA: 'Sábháilteacht',
        IT: 'Abbonamento',
        LV: 'Abonēšana',
        LT: 'Prenumerata',
        MT: 'Iscrizzjoni',
        NL: 'Abonnement',
        PL: 'Subskrypcja',
        PT: 'Assinatura',
        RO: 'Abonament',
        SK: 'Predplatné',
        SL: 'Naročnina',
        SV: 'Prenumeration',
        CS: 'Předplatné',
        RU: 'Подписка',
        IS: 'Áskrift',
        JA: '購読',
        KO: '구독',
        UK: 'Підписка',
        ZH: '订阅',
    },
    boatManager: {
        FR: 'Boat Manager',
        EN: 'Boat Manager',
        DE: 'Boat Manager',
        BG: 'Управление на лодки',
        HR: 'Upravitelj brodova',
        DA: 'Bådmanager',
        ES: 'Gestor de barcos',
        ET: 'Paadihaldur',
        FI: 'Veneen hallinta',
        EL: 'Διαχείριση σκαφών',
        HU: 'Hajókezelő',
        GA: 'Bainisteoir Bád',
        IT: 'Gestione barca',
        LV: 'Kugļa pārvaldība',
        LT: 'Valdymas valties',
        MT: 'Mudell tal-bajja',
        NL: 'Bootmanager',
        PL: 'Menadżer łodzi',
        PT: 'Gerente de Barco',
        RO: 'Manager de bărci',
        SK: 'Manažér lodí',
        SL: 'Upravitelj čolnov',
        SV: 'Båtchef',
        CS: 'Manažer lodi',
        RU: 'Управление лодками',
        IS: 'Bátastjóri',
        JA: 'ボートマネージャー',
        KO: '보트 매니저',
        UK: 'Керівник човнів',
        ZH: '船舶管理',
    },
}
