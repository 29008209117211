export default {
    operator: {
        FR: 'Fournisseur',
        EN: 'Operator',
    },
    edit: {
        FR: 'Modifier',
        EN: 'Edit',
    },
    delete: {
        FR: 'Supprimer',
        EN: 'Delete',
    },
    validateDelete: {
        FR: 'Voulez-vous vraiment supprimer cette commande ?',
        EN: 'Do you really want to delete this order?',
    },
    noOrders: {
        FR: `Vous n'avez effectué aucune commande pour le moment.`,
        EN: 'You have not placed any orders yet.',
    },
}
