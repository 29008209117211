import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from './BoatOnComponent'
import styles from './Styles/BoatOnSpeedometerCss'
import dictionary from './Dictionary/BoatOnModalDico'
import jauge from '../../images/jauge.png'
import jauge_plate from '../../images/jauge_plate.png'
import pointeur from '../../images/pointeur.png'
import pointeur_plate from '../../images/pointeur_plate.png'
import { withWidth, Typography } from '@material-ui/core'

class BoatOnSpeedometer extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    reductedValue(x) {
        if (x === null) return 0
        if (isNaN(x)) return x

        if (x < 1000) {
            return x
        }

        if (x < 1000000) {
            return (x / 1000).toFixed(2) + 'K'
        }
        if (x < 10000000) {
            return (x / 1000000).toFixed(2) + 'M'
        }

        if (x < 1000000000) {
            return (x / 1000000).toFixed(2) + 'M'
        }

        if (x < 1000000000000) {
            return (x / 1000000000).toFixed(2) + 'B'
        }
        return x || 0
    }

    calcDeg(deg, value, maxValue, minValue) {
        if (value > maxValue) value = maxValue
        if (value < minValue) value = minValue
        value = value - minValue
        if (value > 0) {
            deg = deg + (((value * 100) / (maxValue - minValue)) * 300) / 100
        }
        return { value, deg }
    }

    renderFlat() {
        const { minValue, maxValue, classes } = this.props
        let { value, deg } = this.calcDeg(
            0,
            this.props.value,
            maxValue,
            minValue,
        )

        return (
            <div className={classes.rootFlate}>
                <Typography className={classes.minValueFlate}>
                    {this.reductedValue(minValue)} h
                </Typography>
                <Typography className={classes.maxValueFlate}>
                    {this.reductedValue(maxValue)} h
                </Typography>
                <Typography className={classes.value}>
                    <b>{this.reductedValue(value)} h</b>
                </Typography>
                <img
                    src={jauge_plate}
                    alt="jauge_plate"
                    width="100%"
                    height="18px"
                    className={classes.jauge_flate}
                ></img>
                <img
                    className={classes.pointeur_plate}
                    src={pointeur_plate}
                    alt="pointeur_plate"
                    height="20px"
                    style={{ left: `${deg}%` }}
                ></img>
            </div>
        )
    }

    renderNormal() {
        const {
            minValue,
            maxValue,
            value,
            classes,
            removeGraduate,
            inverted,
        } = this.props
        const { _, deg } = this.calcDeg(28, value, maxValue, minValue)

        return (
            <div
                className={classes.root}
                style={
                    !removeGraduate
                        ? {}
                        : {
                              width: '100%',
                          }
                }
            >
                {!removeGraduate && (
                    <>
                        <Typography className={classes.minValue}>
                            {this.reductedValue(minValue) + 'h'}
                        </Typography>
                        <Typography className={classes.maxValue}>
                            {this.reductedValue(maxValue) + 'h'}
                        </Typography>
                        <Typography className={classes.middleValue}>
                            {this.reductedValue(
                                minValue + (maxValue - minValue) / 2,
                            ) + 'h'}
                        </Typography>
                        <Typography className={classes.value75}>
                            {this.reductedValue(
                                minValue +
                                    (maxValue - minValue) / 2 +
                                    (maxValue - minValue) / 4,
                            ) + 'h'}
                        </Typography>
                    </>
                )}
                <img
                    src={jauge}
                    alt="jauge"
                    height="100%"
                    className={
                        inverted
                            ? `${classes.jauge} ${classes.invertedJauge}`
                            : classes.jauge
                    }
                />
                <img
                    className={classes.pointeur}
                    src={pointeur}
                    alt="pointeur"
                    height="100%"
                    style={{ transform: `translate(50%) rotate(${deg}deg)` }}
                />
            </div>
        )
    }

    render() {
        const { forceNormalRender = false, flat, width } = this.props
        const isSmallScreen = /xs|sm/.test(width)

        if (!forceNormalRender && (flat || isSmallScreen))
            return this.renderFlat()
        return this.renderNormal()
    }
}
export default withWidth()(withStyles(styles)(BoatOnSpeedometer))
