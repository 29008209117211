export default {
    title: {
        FR: `Veuillez mettre à jour le nombre d’heures des équipements ci-dessous : `,
        EN: `Please update the number of hours of the equipments below : `,
    },
    nbSavedHour: {
        FR: `Nombre d’heures enregistrées : `,
        EN: `Number of saved hours : `,
    },
    nbActualHour: {
        FR: `Nombre d’heures actuel : `,
        EN: `Number of actual hours : `,
    },
    validate: {
        FR: `Valider`,
        EN: `Validate`,
    },
    errorHour: {
        FR: `Le nombre d'heures actuel doit être supérieur au nombre d'heures enregistrées`,
        EN: `The number of actual hours must be greater than the number of saved hours`,
    },
}
