import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger'
import appReducer from '../reducers'
import { userConstants } from '../constants'

const loggerMiddleware = createLogger({
    predicate: (getState, action) => action.type.includes('@@reduc-form'),
})

const rootReducer = (state, action) => {
    if (action.type === userConstants.LOGOUT) {
        state = undefined
        localStorage.clear()
    }
    return appReducer(state, action)
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // sert à débuger redux et les stores à enlever en cas de modification de se fichier

export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunkMiddleware, loggerMiddleware)),
)
