export default theme => ({
    root: {
        width: '100%',
        boxShadow: 'none',
        boxSizing: 'border-box',
    },
    firstPart: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '5px',
    },
    text: {
        fontSize: '18px',
        display: 'inline-table',
    },
    label: {
        fontWeight: '500',
        marginBottom: '5px',
        marginTop: '10px',
    },
    paddingChange: {
        padding: 10,
    },
})
