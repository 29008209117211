export default {
    noInquery: {
        FR: "Vous n'avez fait aucune demande d'assurance",
        EN: 'You have not applied for insurance',
    },
    makeNew: {
        FR: "Faire une nouvelle demande d'assurance",
        EN: 'Applying for new insurance',
    },
    makeFirst: {
        FR: "Faire une demande d'assurance",
        EN: 'Applying for insurance',
    },
}
