import heic2any from 'heic2any'

export const convertHeicToJpeg = async file => {
    return new Promise((resolve, reject) => {
        heic2any({
            blob: file,
            toType: 'image/png',
            quality: 0.5,
        })
            .then(blob =>
                resolve(
                    new File(
                        [blob],
                        file.name.toLowerCase().replace('.heic', '.png'),
                        {
                            type: 'image/png',
                        },
                    ),
                ),
            )
            .catch(error => {
                console.error(error)
                reject(error)
            })
    })
}
