export default {
    typeEv: {
        FR: `Choisissez un type d'évènement`,
        EN: `Choose an event type`,
    },
    youAddExpense: {
        FR: "Vous êtes en train d'ajouter une dépense, à vous de jouer",
        EN: "You are adding an expense, now it's up to you",
    },
    youEditExpense: {
        FR: 'Vous êtes en train de modifier une dépense',
        EN: 'You are editing an expense',
    },
    youAddIncome: {
        FR: "Vous êtes en train d'ajouter un revenu, bravo",
        EN: "You are adding an income, now it's up to you",
    },
    youEditIncome: {
        FR: 'Vous êtes en train de modifier un revenu',
        EN: 'You are editing an income',
    },
    amount: {
        FR: 'Montant',
        EN: 'Amount',
    },
    date: {
        FR: 'Date',
        EN: 'Date',
    },
    choiceTypeExpense: {
        FR: 'Choisir un type de dépense',
        EN: `Choose a type of expense`,
    },
    choiceTypeIncome: {
        FR: 'Choisir un type de revenu',
        EN: 'Choose a type of income',
    },
    titleExpense: {
        FR: "Libellé de la dépense (c'est le titre de votre dépense)",
        EN: 'Name of the expense (this is the title of your expense)',
    },
    titleIncome: {
        FR: "Libellé du revenu (c'est le titre de votre revenu)",
        EN: 'Name of the income (this is the title of your income)',
    },
    detailsExpense: {
        FR: 'Détails de la dépense',
        EN: 'Details of the expense',
    },
    detailsIncome: {
        FR: 'Détails du revenu',
        EN: 'Details of the income',
    },
    validate: {
        FR: 'Je valide',
        EN: 'Validate',
    },
    addDocumentExpense: {
        FR: 'Ajouter un document pour cette dépense',
        EN: 'Add a document for this expense',
    },
    addDocumentIncome: {
        FR: 'Ajouter un document pour ce revenu',
        EN: 'Add a document for this income',
    },
    errorChoiceType: {
        FR: `Vous devez choisir un type de transaction.`,
        EN: `You must choose a transaction type.`,
    },
    errorDate: {
        FR: `Vous devez choisir une date.`,
        EN: `You must choose a date.`,
    },
    errorAmount: {
        FR: `Vous devez indiquer le montant de votre transaction.`,
        EN: `You must indicate the amount of your transaction.`,
    },
    errorTitle: {
        FR: `Vous devez définir un titre.`,
        EN: `You must define a title.`,
    },
    noBoat: {
        FR: `Vous n'avez pas enregistré de bateau`,
        EN: `You have not registered any boat`,
    },
    dateEmpty: {
        FR: 'jj/mm/aaaa',
        EN: 'mm/dd/yyyy',
    },
    income: {
        FR: `Revenu`,
        EN: `Income`,
    },
    expense: {
        FR: `Dépense`,
        EN: `Expense`,
    },
    addMovement: {
        FR: 'Ajouter un mouvement',
        EN: 'Add a movement',
    },
    budget: {
        FR: 'Mon budget',
        EN: 'My budget',
    },
    addExpense: {
        FR: 'Ajouter une dépense',
        EN: 'Add expense',
    },
    addIncome: {
        FR: 'Ajouter un revenu',
        EN: 'Add income',
    },
    selectAll: {
        FR: 'Tout sélectionner',
        EN: 'Select all',
    },
    expandAll: {
        FR: 'Tout déplier',
        EN: 'Expand all',
    },
    validateDeleteMultiple: {
        FR: 'Voulez-vous vraiment supprimer ces transactions ?',
        EN: 'Do you really want to delete these transactions ?',
    },
    editDate: {
        FR: 'Modifier la date',
        EN: 'Edit date',
    },
    essentialInfos: {
        FR: 'Informations essentielles',
        EN: 'Essential information',
    },
    activity: {
        FR: "Fil d'activité",
        EN: 'Activity feed',
    },
    amountIt: {
        FR: 'Montant TTC',
        EN: 'Amount VAT incl.',
    },
    amountEt: {
        FR: 'Montant HT',
        EN: 'Amount VAT excl.',
    },
    tax: {
        FR: 'TVA',
        EN: 'VAT',
    },
    startDownloadTitle: {
        FR: `Votre téléchargement va se lancer !`,
        EN: 'Your download will start soon  !',
    },
    startDownloadMessage: {
        FR: `Félicitations, l'export de votre budget en cours de création. Ça peut prendre un petit moment mais pas d’inquiétude, vous les retrouverez dans les téléchargements de votre navigateur. Par sécurité, nous vous les envoyons aussi par e-mail 😉`,
        EN: `Congratulations, your budget export are being created. It may take a little while, but don't worry, you'll find them in your browser's downloads. To be on the safe side, we're also sending them to you by e-mail.😉`,
    },
}
