export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        marginTop: '10px',
        position: 'relative',
        border: '1px solid #c4c4c4',
        width: '100%',
        padding: 0,
    },
    menuItem: {
        backgroundColor: '#fff',
        height: '50px',
        width: '100%',
        padding: 0,
        '&:hover': {
            color: '#fff',
        },
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            color: '#000',
        },
    },
    menuButton: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    menuTitle: {
        textAlign: 'center',
        width: '100%',
    },
    menuItemSelected: {
        backgroundColor: '#3040a0',
        color: '#fff',
        height: '50px',
        width: '100%',
        padding: 0,
        '&:hover': {
            color: '#fff',
            backgroundColor: '#3040a0',
        },
        textAlign: 'center',
    },
    text: {
        color: '#fff',
    },
    desktop: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    mobile: {
        backgroundColor: '#3040a0',
        color: 'white',
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
})
