export default {
    movement: {
        FR: 'Mes mouvements',
        EN: 'My movements',
    },
    repair: {
        FR: 'Réparation',
        EN: 'Repare',
    },
    storage: {
        FR: 'Stockage',
        EN: 'Storage',
    },
    edit: {
        FR: `Modifier la transaction`,
        EN: `Modify the transaction`,
    },
    files: {
        FR: `Voir les fichiers joints`,
        EN: `See attached files`,
    },
    supprimerTransaction: {
        FR: `Supprimer la transaction`,
        EN: `Delete the transaction`,
    },
    insurance: {
        FR: 'Assurance',
        EN: 'Insurance',
    },
    delete: {
        FR: 'Supprimer',
        EN: 'Delete',
    },
    validateDelete: {
        FR: 'Voulez-vous vraiment supprimer cette transaction ?',
        EN: 'Do you really want to delete this transaction ?',
    },
    noDateSet: {
        FR: 'Pas de date',
        EN: 'No date',
    },
}
