import React from 'react'

import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../../../../common/BoatOnComponent'
import styles from './Styles/OrderFormCss'
import dictionary from './Dictionary/OrderFormDico'
import BackLinkedPage from '../../../../common/BackLinkedPage'
import BoatOnAvatar from '../../../../common/BoatOnAvatar'
import AppRoute from '../../../../../constants/AppRoute'
import {
    Typography,
    TextField,
    Divider,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Dialog,
    DialogTitle,
    DialogContent,
    Slide,
} from '@material-ui/core'
import { DeleteOutline, ExpandMore, Edit, Close } from '@material-ui/icons'
import AddressSearch from '../../../../common/UsefullComponents/AddressSearch'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import dateUtils from '@date-io/date-fns'
import LocaleFr from 'date-fns/locale/fr'
import LocaleEn from 'date-fns/locale/en-US'
import { Button } from '../../../../common'
import { history } from '../../../../../helpers'
import BoatOnAutoComplete from '../../../../common/BoatOnAutoComplete'
import { downloadOrderFormPdf } from '../../../../../actions/bob.actions'
import { fncDeepFilterOptions } from '../../../../common/Utils'
import BoatOnModal from '../../../../common/BoatOnModal'
import OrderModal from '../../OrderPage/OrderModal'
import InputLabel from '../../../../common/UsefullComponents/InputLabel'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

class OrderForm extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            companyName: '',
            orderNumber: this._generateOrderNumber(),
            address: {},
            doneDate: new Date(),
            modalOpen: null,
            setInfos: [],
            isDialogOpen: false,
            dialogType: null,
            addElement: { index: null, element: this._setEmptyLine() },
            createElement: { category: '', partEquipment: '' },
            options: null,
            savedIndex: null,
            error: false,
            openModal: false,
            event: props.event || null,
            isEngineInSelection: false,
            elementByInput: {},
        }

        this.renderBody = this.renderBody.bind(this)
        this.removeLine = this.removeLine.bind(this)
        this.updateInfoValues = this.updateInfoValues.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.updateStateValue = this.updateStateValue.bind(this)
        this.updateDoneDate = this.updateDoneDate.bind(this)
        this.addLine = this.addLine.bind(this)
        this.handleOpenDialog = this.handleOpenDialog.bind(this)
        this.handleCloseDialog = this.handleCloseDialog.bind(this)
        this.editLine = this.editLine.bind(this)
        this.conditionAlreadySet = this.conditionAlreadySet.bind(this)
        this.saveCreation = this.saveCreation.bind(this)
        this.generateOrder = this.generateOrder.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleResetCreateElement = this.handleResetCreateElement.bind(this)
        this.getElementByInput = this.getElementByInput.bind(this)
        this.handleUpdateEngineSelection = this.handleUpdateEngineSelection.bind(
            this,
        )
        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.addNewEventDetail = this.addNewEventDetail.bind(this)
    }

    getElementByInput(inventory) {
        this.setState({ elementByInput: inventory })
    }

    handleResetCreateElement() {
        this.setState({ createElement: { equipment: {}, part: {} } })
    }

    handleOpenModal(modalOpen) {
        this.setState({ modalOpen: modalOpen })
    }

    _generateOrderNumber() {
        let now = Date.now().toString()
        now += now + Math.floor(Math.random() * 10)
        return [now.slice(0, 4), now.slice(4, 10), now.slice(10, 14)].join('-')
    }

    generateOrder() {
        const {
            setInfos,
            doneDate,
            address,
            companyName,
            orderNumber,
        } = this.state
        const { context } = this.props

        if (!doneDate || !address?.fullText || !companyName)
            this.setState({ error: true })
        else {
            this.setState({ error: false, openModal: 'generateOrder' })
            this.props.dispatch(
                downloadOrderFormPdf(
                    setInfos,
                    doneDate,
                    address,
                    companyName,
                    context,
                    orderNumber,
                ),
            )
        }
    }

    handleOpenDialog(e) {
        const { type, index } = e.currentTarget.dataset

        this.setState({
            isDialogOpen: true,
            dialogType: type,
            savedIndex: type === 'newPartOrEquipment' ? index : null,
        })
    }

    saveCreation() {
        const {
            addElement,
            createElement,
            options,
            savedIndex,
            setInfos,
            elementByInput,
        } = this.state

        //TODO : manage language
        if (
            !createElement.category ||
            createElement.category === '' ||
            elementByInput?.equipment?.equipmentType?.translation ||
            elementByInput?.equipment?.userEquipment?.name
        ) {
            createElement.category =
                elementByInput.equipment?.equipmentType?.translation?.fr ||
                elementByInput.equipment?.userEquipment?.name
            addElement.element.equipmentFamily = elementByInput.equipmentFamily
            addElement.element.equipment = elementByInput.equipment
            if (savedIndex !== null && savedIndex !== undefined) {
                setInfos[savedIndex].equipment = elementByInput.equipment
                setInfos[savedIndex].equipmentFamily =
                    elementByInput.equipmentFamily
            }
            if (
                (!createElement.partEquipment ||
                    createElement.partEquipment === '') &&
                elementByInput.parts.length > 0 &&
                (elementByInput.parts[0].partType?.translation ||
                    elementByInput.parts[0].userPart?.name)
            ) {
                createElement.partEquipment =
                    elementByInput.parts[0].partType?.translation?.fr ||
                    elementByInput.parts[0].userPart?.name
                addElement.element.part = elementByInput.parts[0]
                if (savedIndex !== null && savedIndex !== undefined) {
                    setInfos[savedIndex].part = elementByInput.parts[0]
                }
            }
        }
        if (savedIndex === null || savedIndex === undefined) {
            addElement.element.category = createElement.category
            addElement.element.infos.partEquipment =
                createElement.partEquipment ||
                createElement.category + ' (complet)'
        } else {
            setInfos[savedIndex].infos.partEquipment =
                createElement.partEquipment ||
                createElement.category + ' (complet)'
            setInfos[savedIndex].category = createElement.category
        }

        options.push({
            category: createElement.category,
            eventId: null,
            infos: {
                partEquipment:
                    createElement.partEquipment ||
                    createElement.category + ' (complet)',
                maker: '',
                model: '',
                reference: '',
                quantity: '1',
                full: createElement.partEquipment ? true : false,
            },
        })
        this._sortArray(options)
        this._sortArray(setInfos)
        this.setState({
            addElement: addElement,
            dialogType: 'addLine',
            options: options,
            savedIndex: null,
            setInfos: setInfos,
            createElement: { equipment: {}, part: {} },
            isDialogOpen:
                savedIndex === null || savedIndex === undefined ? true : false,
        })
    }

    componentDidMount() {
        const location = history.location

        if (location?.state?.selectedEvents) {
            this._initInfos(location.state.selectedEvents)
        } else {
            //TODO: rediriger vers inventory
        }

        if (this.props.events) {
            this._initOptions()
        }
    }

    componentDidUpdate() {
        if (this.props.events && !this.state.options) {
            this._initOptions()
        }
        const location = history.location

        if (
            location?.state?.selectedEvents &&
            this.state.setInfos.length === 0 &&
            this.props.events
        ) {
            this._initInfos(location.state.selectedEvents)
        }
    }

    _getNameElement(detail) {
        if (detail.part) {
            const part = detail.part
            if (part.partType)
                return this.displayTextApi(detail.part.partType.translation)
            else if (part.userPart) return part.userPart.name
        } else if (detail.equipment) {
            const equipment = detail.equipment
            if (equipment.equipmentType)
                return this.displayTextApi(equipment.equipmentType.translation)
            else if (equipment.userEquipment)
                return equipment.userEquipment.name
        }
        return ''
    }

    _getNameCategory(detail) {
        let category = ''

        if (detail.part) {
            if (detail.part.equipment && detail.part.equipment.equipmentType)
                category = this.displayTextApi(
                    detail.part.equipment.equipmentType.translation,
                )
            else if (
                detail.part.equipment &&
                detail.part.equipment.userEquipment
            )
                category = detail.part.equipment.userEquipment.name
        } else if (detail.equipment) {
            category = this._getNameElement(detail)
        }

        return category
    }

    _initInfos(selectedEvents) {
        const { events } = this.props
        const arrayInfo = []
        if (events && Array.isArray(events)) {
            selectedEvents.forEach(selected => {
                const { detail } = events.find(event => event.id === selected)
                if (detail) {
                    arrayInfo.push({
                        category: this._getNameCategory(detail),
                        eventId: selected,
                        infos: {
                            partEquipment:
                                this._getNameElement(detail) +
                                (detail.equipment ? ' (complet)' : ''),
                            maker: detail.brand || '',
                            model: detail.model || '',
                            reference: detail.internalReference || '',
                            quantity: '1',
                            full: detail.equipment ? true : false,
                        },
                    })
                }
            })
            if (arrayInfo.length > 0) {
                this._sortArray(arrayInfo)
                this.setState({ setInfos: arrayInfo })
            }
        }
    }

    _initOptions() {
        const { events } = this.props
        const arrayInfo = []

        if (events && Array.isArray(events)) {
            events.forEach(event => {
                const { detail } = event
                if (
                    detail &&
                    !detail.archived &&
                    event.delimitedDate &&
                    event.delimitedDate.startDate
                ) {
                    arrayInfo.push({
                        category: this._getNameCategory(detail),
                        eventId: event.id,
                        infos: {
                            partEquipment:
                                this._getNameElement(detail) +
                                (detail.equipment ? ' (complet)' : ''),
                            maker: detail.brand || '',
                            model: detail.model || '',
                            reference: detail.internalReference || '',
                            quantity: '1',
                            full: detail.equipment ? true : false,
                        },
                    })
                }
            })
            if (arrayInfo.length > 0) {
                this._sortArray(arrayInfo)
                this.setState({ options: arrayInfo })
            }
        }
    }

    handleCloseDialog() {
        this.setState({
            isDialogOpen: false,
            dialogType: null,
            addElement: { index: null, element: this._setEmptyLine() },
            savedIndex: null,
        })
    }

    addLine(e) {
        const { setInfos, addElement } = this.state
        const { mobile } = e.currentTarget.dataset
        if (!mobile) {
            setInfos.push(this._setEmptyLine())
            this.setState({
                setInfos: setInfos,
            })
        } else if (addElement && addElement.index !== null) {
            setInfos[addElement.index] = addElement.element
            this._sortArray(setInfos)
            this.handleCloseDialog()
        } else {
            setInfos.push(addElement.element)
            this._sortArray(setInfos)
            this.handleCloseDialog()
        }
        this.setState({
            setInfos: setInfos,
        })
    }

    editLine(e) {
        const { setInfos } = this.state
        const { index, type } = e.currentTarget.dataset

        this.setState({
            addElement: {
                index: index,
                element: JSON.parse(JSON.stringify(setInfos[index])),
            },
            dialogType: type,
            isDialogOpen: true,
        })
    }

    _setEmptyLine() {
        return {
            category: '',
            infos: {
                partEquipment: '',
                maker: '',
                model: '',
                reference: '',
                quantity: '1',
            },
            full: null,
            eventId: null,
        }
    }

    removeLine(e) {
        const { setInfos } = this.state
        const { index } = e.currentTarget.dataset
        setInfos.splice(index, 1)
        this.setState({
            setInfos: setInfos,
        })
    }

    updateStateValue(e) {
        const { key } = e.currentTarget.dataset
        this.setState({ [key]: e.target.value })
    }

    updateDoneDate(date) {
        this.setState({ doneDate: date })
    }

    _sortArray(array) {
        array.sort((a, b) => {
            if (!b.category) return -1
            else if (!a.category) return 1
            if (
                b.category === a.category &&
                (a.full || (a.infos && a.infos.full))
            )
                return -1
            else if (
                b.category === a.category &&
                (b.full || (b.infos && b.infos.full))
            )
                return 1
            return -b.category.localeCompare(a.category)
        })
    }

    handleChange(e, value) {
        this.updateInfoValues(e, value, { indexAutoComplete: null })
    }

    updateInfoValues(e, value, { indexAutoComplete }) {
        const { setInfos, isDialogOpen } = this.state
        let { addElement } = this.state
        const { key, index } = e.currentTarget.dataset

        if (!isDialogOpen) {
            if (!index && !key && indexAutoComplete !== null) {
                setInfos[indexAutoComplete] =
                    JSON.parse(JSON.stringify(value)) || this._setEmptyLine()
            } else {
                if (key && key === `quantity`) {
                    e.target.value =
                        e.target.value !== '' && parseInt(e.target.value)
                            ? parseInt(e.target.value)
                            : null
                }
                setInfos[index].infos[key] = e.target.value
            }
            this._sortArray(setInfos)
            this.setState({
                setInfos: setInfos,
            })
        } else {
            if (!index && !key) {
                addElement.element =
                    JSON.parse(JSON.stringify(value)) || this._setEmptyLine()
            } else {
                if (key && key === `quantity`) {
                    e.target.value =
                        e.target.value !== '' && parseInt(e.target.value)
                            ? parseInt(e.target.value)
                            : null
                }
                addElement.element.infos[key] = e.target.value
            }
            this.setState({
                addElement: addElement,
            })
        }
    }

    _renderSmallInputs(index, info) {
        const { classes } = this.props

        return (
            <>
                <TextField
                    inputProps={{
                        'data-index': index,
                        'data-key': `quantity`,
                    }}
                    className={classes.smallInput}
                    id={`quantity${index}`}
                    placeholder={`1`}
                    margin="dense"
                    variant="outlined"
                    value={info.quantity || ''}
                    onChange={this.handleChange}
                />
            </>
        )
    }

    _renderNormalInputs(index, info) {
        const { classes } = this.props

        return (
            <>
                <TextField
                    inputProps={{
                        'data-index': index,
                        'data-key': `maker`,
                    }}
                    className={classes.normalInput}
                    id={`maker${index}`}
                    placeholder={this.displayText('maker')}
                    margin="dense"
                    variant="outlined"
                    value={info.maker}
                    onChange={this.handleChange}
                />
                <TextField
                    inputProps={{
                        'data-index': index,
                        'data-key': `model`,
                    }}
                    className={classes.normalInput}
                    id={`model${index}`}
                    placeholder={this.displayText('model')}
                    margin="dense"
                    variant="outlined"
                    value={info.model}
                    onChange={this.handleChange}
                />
                <TextField
                    inputProps={{
                        'data-index': index,
                        'data-key': `reference`,
                    }}
                    className={classes.normalInput}
                    id={`reference${index}`}
                    placeholder={this.displayText('reference')}
                    margin="dense"
                    variant="outlined"
                    value={info.reference}
                    onChange={this.handleChange}
                />
            </>
        )
    }

    _renderLines() {
        const { setInfos } = this.state
        const { classes } = this.props
        let lastCategory = [null]

        return (
            <>
                {setInfos.map(({ infos, category, eventId }, index) => {
                    return (
                        <>
                            {this._renderEquipmentLine(lastCategory, category)}
                            <div className={classes.tableLine}>
                                <IconButton
                                    data-index={index}
                                    size={`small`}
                                    id={`deleteButton${index}`}
                                    className={classes.deleteIcon}
                                    onClick={this.removeLine}
                                    children={<DeleteOutline />}
                                />
                                {this._renderAutoCompletion(
                                    infos,
                                    category,
                                    eventId,
                                    this.displayText('partEquipment'),
                                    classes.bigInput,
                                    index,
                                )}
                                {this._renderNormalInputs(index, infos)}
                                {this._renderSmallInputs(index, infos)}
                            </div>
                        </>
                    )
                })}
            </>
        )
    }

    _renderEquipmentLine(lastCategory, newCategory) {
        const { classes } = this.props
        const { setInfos } = this.state

        if (newCategory && lastCategory[0] !== newCategory) {
            lastCategory[0] = newCategory
            return (
                <div className={classes.equipmentLine}>
                    <Typography
                        variant={'body2'}
                        className={classes.equipmentTitle}
                    >
                        {lastCategory[0]}
                    </Typography>
                </div>
            )
        } else if (
            !newCategory &&
            lastCategory[0] !== newCategory &&
            setInfos.find(info => info.infos.partEquipment !== '')
        ) {
            lastCategory[0] = newCategory
            return <Divider className={classes.divider} />
        }
        return <></>
    }

    _renderTableDesktop() {
        const { classes } = this.props

        return (
            <div className={classes.tableDesktop}>
                <div className={classes.tableHeader}>
                    <Typography className={classes.bigText} variant={`h4`}>
                        {this.displayText(`partEquipment`)}
                    </Typography>
                    <Typography className={classes.normalText} variant={`h4`}>
                        {this.displayText(`maker`)}
                    </Typography>
                    <Typography className={classes.normalText} variant={`h4`}>
                        {this.displayText(`model`)}
                    </Typography>
                    <Typography className={classes.normalText} variant={`h4`}>
                        {this.displayText(`reference`)}
                    </Typography>
                    <Typography className={classes.smallText} variant={`h4`}>
                        {this.displayText(`quantity`)}
                    </Typography>
                </div>
                <Divider className={classes.divider} />
                <div className={classes.tableBody}>{this._renderLines()}</div>
                <Button
                    label={this.displayText(`AddLine`)}
                    onClick={this.addLine}
                    type={`secondary`}
                />
            </div>
        )
    }

    _renderMobileDetails(info) {
        const { classes } = this.props

        return (
            <div className={classes.mobileLine}>
                <div className={classes.flexColumn}>
                    <Typography
                        variant={`body1`}
                        className={classes.columnTitleMobile}
                    >
                        {this.displayText('maker')}
                    </Typography>
                    <Typography>{info.maker}</Typography>
                </div>
                <div className={classes.flexColumn}>
                    <Typography
                        variant={`body1`}
                        className={classes.columnTitleMobile}
                    >
                        {this.displayText('model')}
                    </Typography>
                    <Typography>{info.model}</Typography>
                </div>
                <div className={classes.flexColumn}>
                    <Typography
                        variant={`body1`}
                        className={classes.columnTitleMobile}
                    >
                        {this.displayText('reference')}
                    </Typography>
                    <Typography>{info.reference}</Typography>
                </div>
            </div>
        )
    }

    _renderMobileActions(index) {
        const { classes } = this.props
        return (
            <div className={classes.mobileIcons}>
                <IconButton
                    data-index={index}
                    size={`small`}
                    id={`deleteButton${index}`}
                    onClick={this.removeLine}
                    className={classes.mobileButton}
                    children={
                        <DeleteOutline className={classes.mobileIconButton} />
                    }
                />
                <IconButton
                    data-index={index}
                    data-type={`editLine`}
                    size={`small`}
                    id={`editButton${index}`}
                    onClick={this.editLine}
                    className={classes.mobileButton}
                    children={
                        <Edit
                            className={classes.mobileIconButton}
                            style={{ height: `22px`, width: `22px` }}
                        />
                    }
                />
            </div>
        )
    }

    _renderTableMobile() {
        const { classes } = this.props
        const { setInfos } = this.state
        let lastCategory = [null]

        return (
            <div className={classes.tableMobile}>
                <div className={classes.mobileBoxes}>
                    {setInfos.map(({ infos, category }, index) => (
                        <>
                            {this._renderEquipmentLine(lastCategory, category)}
                            <Accordion
                                id={`accordion${index}`}
                                className={classes.mobileBox}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    id={`panel${index}`}
                                    classes={{
                                        root: classes.accordionSummaryRoot,
                                        content:
                                            classes.accordionSummaryContent,
                                        expanded:
                                            classes.accordionSummaryExpanded,
                                    }}
                                >
                                    <Typography>
                                        {infos.quantity}x {infos.partEquipment}
                                    </Typography>
                                </AccordionSummary>
                                <Divider className={classes.dividerBox} />
                                <AccordionDetails
                                    id={`accordionDetails${index}`}
                                    className={classes.accordionDetails}
                                >
                                    {this._renderMobileDetails(infos)}
                                    {this._renderMobileActions(index)}
                                </AccordionDetails>
                            </Accordion>
                        </>
                    ))}
                </div>
                <Button
                    label={this.displayText(`AddLine`)}
                    addProps={{ 'data-type': `addLine` }}
                    onClick={this.handleOpenDialog}
                    type={`secondary`}
                    fullWidth
                />
            </div>
        )
    }

    _renderDate() {
        const { classes } = this.props

        return (
            <div className={classes.date}>
                <Typography variant={`body2`}>
                    {this.displayText(`date`)}
                </Typography>
                <MuiPickersUtilsProvider
                    utils={dateUtils}
                    locale={this.getLanguage() === 'FR' ? LocaleFr : LocaleEn}
                >
                    <DatePicker
                        autoOk
                        margin="dense"
                        variant="inline"
                        onChange={this.updateDoneDate}
                        value={this.state.doneDate}
                        format="dd/MM/yyyy"
                        inputVariant="outlined"
                    />
                </MuiPickersUtilsProvider>
            </div>
        )
    }

    _renderReceiverInfos() {
        const { classes } = this.props
        return (
            <div className={classes.receiverInfos}>
                <Typography variant={`body2`}>
                    {this.displayText(`for`)}
                </Typography>
                <TextField
                    id="companyName"
                    label={this.displayText('companyName')}
                    margin="dense"
                    variant="outlined"
                    value={this.state.companyName}
                    onChange={this.updateStateValue}
                    inputProps={{
                        'data-key': `companyName`,
                    }}
                    error={!this.state.companyName && this.state.error}
                    helperText={
                        !this.state.companyName && this.state.error
                            ? this.displayText('errorCompany')
                            : ''
                    }
                    fullWidth
                ></TextField>
                <AddressSearch
                    multiline
                    label={this.displayText('address')}
                    className={classes.searchAddress}
                    setAddress={address => this.setState({ address: address })}
                    fullAddressInfo={this.state.address?.fullText}
                    style={{ width: '100%', marginTop: '4px' }}
                    error={
                        !(this.state.address && this.state.address.fullText) &&
                        this.state.error
                    }
                    helperText={
                        !(this.state.address && this.state.address.fullText) &&
                        this.state.error
                            ? this.displayText('errorAddress')
                            : ''
                    }
                ></AddressSearch>
            </div>
        )
    }

    _renderSenderInfos() {
        const { user, classes } = this.props

        //TODO: Remplacer nom du particulier par nom de société quand il y aura les sociétés
        return (
            <div>
                <BoatOnAvatar user={user} />
                <div className={classes.senderInfos}>
                    <Typography variant={`body1`}>
                        {user.firstName} {user.lastName}
                    </Typography>
                    <Typography variant={`body1`} className={classes.address}>
                        {this.getAddress(user.address)}
                    </Typography>
                    {this._renderDate()}
                </div>
            </div>
        )
    }

    renderBody() {
        const { classes } = this.props
        const { orderNumber } = this.state

        return (
            <div className={classes.body}>
                <Typography>
                    {this.displayText(`orderForm`)} {`N°${orderNumber}`}
                </Typography>
                <div className={classes.orderForm}>
                    <div className={classes.infos}>
                        {this._renderSenderInfos()}
                        {this._renderReceiverInfos()}
                    </div>
                    {this._renderTableDesktop()}
                    {this._renderTableMobile()}
                </div>
                <Button
                    addProps={{ 'data-mobile': true }}
                    label={this.displayText(`continue`)}
                    onClick={this.generateOrder}
                    type={`primary`}
                    style={{
                        marginTop: `32px`,
                        marginBottom: `15px`,
                        marginLeft: 'auto',
                    }}
                    classNameOverride={classes.downloadButton}
                />
            </div>
        )
    }

    _renderMultiTextfiellOnLine() {
        const { infos } = this.state.addElement.element
        const { classes } = this.props

        //TODO: réduire cette fonction
        return (
            <>
                <div>
                    <TextField
                        inputProps={{
                            'data-key': `maker`,
                        }}
                        id={`makerDialog`}
                        placeholder={this.displayText('maker')}
                        margin="dense"
                        variant="outlined"
                        value={infos.maker}
                        onChange={this.handleChange}
                        style={{ marginRight: `18px` }}
                        className={classes.demiTextFieldDialog}
                    />
                    <TextField
                        inputProps={{
                            'data-key': `model`,
                        }}
                        id={`modelDialog`}
                        placeholder={this.displayText('model')}
                        margin="dense"
                        variant="outlined"
                        value={infos.model}
                        onChange={this.handleChange}
                        style={{ marginLeft: `18px` }}
                        className={classes.demiTextFieldDialog}
                    />
                </div>
                <div>
                    <TextField
                        inputProps={{
                            'data-key': `reference`,
                        }}
                        id={`referenceDialog`}
                        placeholder={this.displayText('reference')}
                        margin="dense"
                        variant="outlined"
                        value={infos.reference}
                        onChange={this.handleChange}
                        style={{ marginRight: `18px` }}
                        className={classes.demiTextFieldDialog}
                    />
                    <TextField
                        inputProps={{
                            'data-key': `quantity`,
                        }}
                        id={`quantityDialog`}
                        placeholder={this.displayText('quantity')}
                        margin="dense"
                        variant="outlined"
                        value={infos.quantity || ''}
                        onChange={this.handleChange}
                        style={{ marginLeft: `18px` }}
                        className={classes.demiTextFieldDialog}
                    />
                </div>
            </>
        )
    }

    conditionAlreadySet(option) {
        const { setInfos } = this.state

        return setInfos.some(
            info =>
                (option.eventId && info.eventId === option.eventId) ||
                (!option.eventId &&
                    option.category === info.category &&
                    option.infos.partEquipment === info.infos.partEquipment),
        )
    }

    _renderAutoCompletion(
        infos,
        category,
        eventId,
        placeholder,
        inputClass,
        index,
    ) {
        const { options } = this.state
        const value = {
            category: category,
            infos: infos,
            eventId: eventId,
        }

        if (!options) return <></>

        return (
            <BoatOnAutoComplete
                addButton
                value={value}
                conditionAlready={this.conditionAlreadySet}
                options={options.sort(
                    (a, b) => -b.category?.localeCompare(a.category),
                )}
                placeholder={placeholder}
                inputClass={inputClass}
                indexAutoComplete={index}
                onChange={this.updateInfoValues}
                labelAdd={this.displayText('addLabelButton')}
                addProps={{
                    'data-type': `newPartOrEquipment`,
                    'data-index': index,
                }}
                onAddButtonClicked={this.handleOpenDialog}
                getElementToShow={option =>
                    option && option.infos ? option.infos.partEquipment : ''
                }
                filterOptions={(option, input) =>
                    fncDeepFilterOptions(option, input, this.getContext())
                }
                groupBy={option => option.category}
            />
        )
    }

    handleUpdateEngineSelection(value) {
        this.setState({ isEngineInSelection: value })
    }

    addNewEventDetail(callback) {
        this.setState({ modalOpen: null }, () => {
            callback()
        })
    }

    _renderDialogContentAddPartOrEquipment() {
        const { classes } = this.props
        const { createElement } = this.state

        return (
            <div className={classes.flexColumn}>
                <InputLabel
                    event={this.state.event}
                    onTagsChange={() => {}}
                    error={this.state.error}
                    modalOpen={this.state.modalOpen}
                    handleOpenModal={this.handleOpenModal}
                    arrayFor={'ORDER'}
                    inventories={this.props.inventories}
                    isEngineInSelection={this.state.isEngineInSelection}
                    handleUpdateEngineSelection={
                        this.handleUpdateEngineSelection
                    }
                    dataFromOrder={createElement}
                    handleResetCreateElement={this.handleResetCreateElement}
                    getElementByInput={this.getElementByInput}
                    elementByInput={this.state.elementByInput}
                />
                <Button
                    addProps={{ 'data-mobile': true }}
                    label={this.displayText(`save`)}
                    onClick={this.saveCreation}
                    type={`primary`}
                    style={{
                        marginTop: `32px`,
                        marginBottom: `15px`,
                        marginLeft: 'auto',
                    }}
                    classNameOverride={classes.saveButton}
                />
            </div>
        )
    }

    _renderDialogContentAddLine() {
        const { addElement } = this.state
        const { classes } = this.props

        return (
            <div className={classes.flexColumn}>
                {this._renderAutoCompletion(
                    addElement.element.infos,
                    addElement.element.category,
                    addElement.element.eventId,
                    this.displayText('equipmentDesignation'),
                    classes.textFieldDialog,
                )}
                {this._renderMultiTextfiellOnLine()}
                <Button
                    addProps={{ 'data-mobile': true }}
                    label={
                        !addElement.index
                            ? this.displayText(`add`)
                            : this.displayText(`edit`)
                    }
                    onClick={this.addLine}
                    type={`primary`}
                    style={{ marginTop: `32px`, marginBottom: `15px` }}
                />
            </div>
        )
    }

    _renderDialogAddOrEditLine() {
        const { addElement } = this.state
        const { classes } = this.props

        return (
            <>
                <div className={classes.headerDialog}>
                    <DialogTitle
                        id="alert-dialog-slide-title"
                        className={classes.titleDialog}
                    >
                        {!addElement.index
                            ? this.displayText(`addingLine`)
                            : this.displayText(`editingLine`)}
                    </DialogTitle>
                    <IconButton
                        size={`small`}
                        id={`closeDialog`}
                        className={classes.closeIcon}
                        onClick={this.handleCloseDialog}
                        children={<Close />}
                    />
                </div>
                <DialogContent>
                    {this._renderDialogContentAddLine()}
                </DialogContent>
            </>
        )
    }

    _renderDialogPartOrEquipment() {
        const { classes } = this.props

        return (
            <>
                <div className={classes.headerDialog}>
                    <DialogTitle
                        id="alert-dialog-slide-title"
                        className={classes.titleDialog}
                    >
                        {this.displayText(`addingPartOrEquipment`)}
                    </DialogTitle>
                    <IconButton
                        size={`small`}
                        id={`closeDialog`}
                        className={classes.closeIcon}
                        onClick={this.handleCloseDialog}
                        children={<Close />}
                    />
                </div>

                <DialogContent>
                    {this._renderDialogContentAddPartOrEquipment()}
                </DialogContent>
            </>
        )
    }

    _renderDialog() {
        const { isDialogOpen, dialogType } = this.state
        const { classes } = this.props

        return (
            <Dialog
                fullWidth
                maxWidth={`xs`}
                open={isDialogOpen}
                onClose={this.handleCloseDialog}
                keepMounted
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                classes={{
                    container: classes.dialogContainer,
                    paper: classes.dialogPaper,
                }}
                TransitionComponent={Transition}
            >
                {(dialogType === `addLine` || dialogType === `editLine`) &&
                    this._renderDialogAddOrEditLine()}
                {dialogType === `newPartOrEquipment` &&
                    this._renderDialogPartOrEquipment()}
            </Dialog>
        )
    }

    handleCloseModal() {
        this.setState({ openModal: false })
    }

    render() {
        const { classes, events } = this.props
        const {
            openModal,
            companyName,
            orderNumber,
            doneDate,
            setInfos,
        } = this.state

        const inventoryDetails = setInfos.map(item => {
            const event = events.find(event => event.id === item.eventId)

            const itemDetails = {
                brand: item?.infos?.maker || null,
                model: item?.infos?.model || null,
                internalReference: item?.infos?.reference || null,
                quantity: parseInt(item.infos.quantity),
            }

            if (event?.detail?.part?.id || item?.part?.id) {
                itemDetails.part = {
                    id: event?.detail?.part?.id || item.part.id,
                }
            } else if (item?.part?.userPart.name) {
                itemDetails.part = {
                    userPart: {
                        name: item?.part?.userPart.name,
                    },
                    equipment: item?.equipment?.userEquipment?.name
                        ? {
                              userEquipment: {
                                  name: item?.equipment?.userEquipment.name,
                              },
                              equipmentFamily:
                                  item?.equipmentFamily?.userEquipmentFamily
                                      ?.name || item?.equipmentFamily?.id
                                      ? item?.equipmentFamily
                                      : null,
                          }
                        : item.equipment.id
                        ? item.equipment
                        : null,
                }
            } else if (event?.detail?.equipment?.id || item?.equipment?.id) {
                itemDetails.equipment = {
                    id: event?.detail?.equipment?.id || item.equipment.id,
                }
            } else if (item?.equipment?.userEquipment.name) {
                itemDetails.equipment = {
                    userEquipment: {
                        name: item?.equipment?.userEquipment.name,
                    },
                    equipmentFamily:
                        item?.equipmentFamily?.userEquipmentFamily.name ||
                        item?.equipmentFamily?.id
                            ? item?.equipmentFamily
                            : null,
                }
            } else if (item.category) {
                itemDetails.equipment = {
                    userEquipment: {
                        name: item.category,
                    },
                }
                if (
                    item.infos?.partEquipment &&
                    item.infos?.partEquipment !== item.category
                ) {
                    itemDetails.part = {
                        userPart: {
                            name: item.infos.partEquipment,
                        },
                    }
                }
            }

            return itemDetails
        })

        return (
            <div className={classes.root}>
                <BackLinkedPage
                    backLink={AppRoute.LogBook.Inventory}
                    body={this.renderBody}
                />
                {this._renderDialog()}
                <BoatOnModal
                    openCondition={openModal}
                    handleClose={this.handleCloseModal}
                    modalCores={{
                        generateOrder: (
                            <OrderModal
                                operator={companyName}
                                orderNumber={orderNumber}
                                orderDate={doneDate}
                                inventoryDetails={inventoryDetails}
                            />
                        ),
                    }}
                    maxWidth={{
                        generateOrder: `sm`,
                    }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        events: state.bob.events,
        boat: state.bob.boat,
        inventories: state.bob.inventories,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(OrderForm))
