import React from 'react'
import { connect } from 'react-redux'
import dictionary from './Dictionary/MyFleetListDico'
import styles from './Styles/MyFleetGraphicCss'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { withStyles } from '@material-ui/core/styles'
import {
    getBobEvent,
    getElementStockByDate,
} from '../../../../actions/bob.actions'
import { Line } from 'react-chartjs-2'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
} from 'chart.js'
import { filterActions, typesActions } from '../../../../actions'
import MenuItem from '@material-ui/core/MenuItem/MenuItem'
import TextField from '@material-ui/core/TextField/TextField'
import { getBoatsOfAFleet } from '../../../../actions/bob.actions'
import { getContextFromUrl } from '../../../../languages/LocalizerUtils'
import BoatOnDateSelector from '../../../common/UsefullComponents/BoatOnDateSelector'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import dateUtils from '@date-io/date-fns'
import LocaleFr from 'date-fns/locale/fr'
import LocaleEn from 'date-fns/locale/en-US'

class MyFleet extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            boatsBobEvents: null,
            repairEvents: null,
            startDate: null,
            endDate: null,
            filterSelect: 1,
            boats: getBoatsOfAFleet(this.props.user, this.props.groupId),
        }

        ChartJS.register(CategoryScale, PointElement, LinearScale, LineElement)

        this.onDatesChange = this.onDatesChange.bind(this)
    }

    componentDidMount() {
        this.getAllBobEvents()
        this.getAllRepairs()
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.events &&
            ((prevProps.loadingEvents > 0 && this.props.loadingEvents === 0) ||
                JSON.stringify(prevProps.events) !==
                    JSON.stringify(this.props.events))
        ) {
            this.getAllRepairs()
        }
    }

    async getAllBobEvents() {
        const { boats } = this.state
        const boatsBobEvents = []
        if (!boats || (!Array.isArray(boats) && boats.length > 0)) return

        for (const boat of boats) {
            boatsBobEvents.push(await getBobEvent(boat.id))
        }

        this.setState({ boatsBobEvents: boatsBobEvents })
    }

    async getAllRepairs() {
        const { events, eventTypes } = this.props
        if (events && eventTypes) {
            const repairs = []
            events.forEach(event => {
                const eventType = typesActions.getEventTypeById(
                    eventTypes,
                    event.eventType.id,
                )
                if (event.checkup && eventType && eventType.repair) {
                    repairs.push(event)
                }
            })
            this.setState({ repairEvents: repairs })
        }
    }

    spentOrEarnedForGraphic(spending) {
        const { startDate, endDate } = this.state
        var label = []
        var data = []
        var date = this.getBeginDate(startDate, endDate),
            y = date.getFullYear(),
            m = date.getMonth()
        var firstDay = new Date(y, m, 1)
        var lastDay = new Date(y, m + 1, 1)
        const numberMonth = this.getNumberMonth(startDate, endDate)
        if (this.state.boatsBobEvents) {
            var spent = 0
            for (var i = 0; i < numberMonth; i++) {
                this.state.boatsBobEvents.forEach(bobEvents => {
                    bobEvents.forEach(bobEvent => {
                        if (
                            spending === true &&
                            bobEvent.eventType &&
                            bobEvent.eventType.spending &&
                            bobEvent.transaction &&
                            bobEvent.transaction.amountIt &&
                            bobEvent.delimitedDate &&
                            bobEvent.delimitedDate.startDate &&
                            firstDay <=
                                new Date(bobEvent.delimitedDate.startDate) &&
                            new Date(bobEvent.delimitedDate.startDate) < lastDay
                        ) {
                            spent += bobEvent.transaction.amountIt
                        } else if (
                            spending === false &&
                            bobEvent.eventType &&
                            bobEvent.eventType.earning &&
                            bobEvent.transaction &&
                            bobEvent.transaction.amountIt &&
                            bobEvent.delimitedDate &&
                            bobEvent.delimitedDate.startDate &&
                            firstDay <=
                                new Date(bobEvent.delimitedDate.startDate) &&
                            new Date(bobEvent.delimitedDate.startDate) < lastDay
                        ) {
                            spent += bobEvent.transaction.amountIt
                        }
                    })
                })
                label.push(
                    '1 ' +
                        new Date(firstDay).toLocaleString('default', {
                            month: 'short',
                        }),
                )
                data.push(spent)
                firstDay = lastDay
                y = firstDay.getFullYear()
                m = firstDay.getMonth()
                lastDay = new Date(y, m + 1, 1)
                spent = 0
            }
        }
        if (spending) {
            return {
                labels: label,
                datasets: [
                    {
                        label: 'Dépense par mois',
                        data: data,
                        fill: false,
                        backgroundColor: '#303f9f',
                        borderColor: '#303f9f',
                        cubicInterpolationMode: 'monotone',
                        tension: 0.4,
                    },
                ],
            }
        } else {
            return {
                labels: label,
                datasets: [
                    {
                        label: 'Revenu par mois',
                        data: data,
                        fill: false,
                        backgroundColor: '#303f9f',
                        borderColor: '#303f9f',
                        cubicInterpolationMode: 'monotone',
                        tension: 0.4,
                    },
                ],
            }
        }
    }

    soonExpire(checkupDate, validedDate, dateOfMonth) {
        if (!checkupDate || validedDate) return false

        const firstDate = new Date(checkupDate).setMonth(
            new Date(checkupDate).getMonth() - 3,
        )

        if (
            new Date(firstDate).getTime() < new Date(dateOfMonth).getTime() &&
            new Date(dateOfMonth).getTime() < new Date(checkupDate).getTime() &&
            !validedDate
        )
            return true
        return false
    }

    overdue(checkupDate, validedDate, dateOfMonth) {
        if (
            new Date(dateOfMonth).getTime() > new Date(checkupDate).getTime() &&
            !validedDate
        )
            return true
        return false
    }

    valided(validedDate) {
        if (validedDate) return true
        return false
    }

    getNumberMonth(start, end) {
        var startDate = new Date(start),
            yStart = startDate.getFullYear(),
            mStart = startDate.getMonth() + 1
        var endDate = new Date(end),
            yEnd = endDate.getFullYear(),
            mEnd = endDate.getMonth() + 1
        var length = 12
        if (start && end) {
            if (yStart === yEnd) {
                if (mStart <= mEnd) {
                    length = mEnd - mStart + 1
                }
            } else if (yStart < yEnd) {
                let years = yEnd - yStart
                length = mEnd - mStart + 1 + years * 12
            }
        }
        return length
    }

    getBeginDate(start, end) {
        var dateStart = null
        if (start) {
            dateStart = start
        } else {
            dateStart = new Date()
        }
        if (end && !start) {
            var endDate = new Date(end),
                mEnd = endDate.getMonth() - 12
            endDate.setMonth(mEnd)
            return endDate
        }
        return dateStart
    }

    repairForGraphique(type) {
        const { repairEvents, startDate, endDate } = this.state
        const numberMonth = this.getNumberMonth(startDate, endDate)
        var count = 0
        var label = []
        var data = []
        var date = this.getBeginDate(startDate, endDate),
            y = date.getFullYear(),
            m = date.getMonth()
        var firstDay = new Date(y, m, 1)
        var lastDay = new Date(y, m + 1, 1)
        if (repairEvents && repairEvents.length !== 0) {
            for (var i = 0; i < numberMonth; i++) {
                repairEvents.map((repair, index) => {
                    var checkupDate =
                        repair.delimitedDate && repair?.delimitedDate?.startDate
                            ? new Date(repair.delimitedDate.startDate)
                            : new Date()
                    if (type === 1) {
                        if (this.valided(repair?.delimitedDate?.endDate)) {
                            if (
                                repair.delimitedDate &&
                                repair?.delimitedDate?.endDate &&
                                new Date(repair?.delimitedDate?.endDate) <
                                    firstDay
                            ) {
                                count += 1
                            }
                        }
                    } else if (type === 2) {
                        if (
                            this.soonExpire(
                                checkupDate,
                                repair?.delimitedDate?.endDate,
                                firstDay,
                            )
                        ) {
                            count += 1
                        }
                    } else {
                        if (
                            this.overdue(
                                checkupDate,
                                repair?.delimitedDate?.endDate,
                                firstDay,
                            )
                        ) {
                            count += 1
                        }
                    }
                })
                label.push(
                    '1 ' +
                        new Date(firstDay).toLocaleString('default', {
                            month: 'short',
                        }),
                )
                data.push(count)
                firstDay = lastDay
                y = firstDay.getFullYear()
                m = firstDay.getMonth()
                lastDay = new Date(y, m + 1, 1)
                count = 0
            }
        }
        if (type === 1) {
            return {
                labels: label,
                datasets: [
                    {
                        label: this.displayText(`type1`),
                        data: data,
                        fill: false,
                        backgroundColor: '#303f9f',
                        borderColor: '#303f9f',
                        cubicInterpolationMode: 'monotone',
                        tension: 0.4,
                    },
                ],
            }
        } else if (type === 2) {
            return {
                labels: label,
                datasets: [
                    {
                        label: this.displayText(`type2`),
                        data: data,
                        fill: false,
                        backgroundColor: '#303f9f',
                        borderColor: '#303f9f',
                        cubicInterpolationMode: 'monotone',
                        tension: 0.4,
                    },
                ],
            }
        } else {
            return {
                labels: label,
                datasets: [
                    {
                        label: this.displayText(`type3`),
                        data: data,
                        fill: false,
                        backgroundColor: '#303f9f',
                        borderColor: '#303f9f',
                        cubicInterpolationMode: 'monotone',
                        tension: 0.4,
                    },
                ],
            }
        }
    }

    stockForGraphic(type) {
        const { events } = this.props
        const { boats } = this.state
        const { startDate, endDate, boatsBobEvents } = this.state
        let partInStock = 0
        let partNoStock = 0
        let label = []
        let data = []
        const numberMonth = this.getNumberMonth(startDate, endDate)
        let date = this.getBeginDate(startDate, endDate),
            y = date.getFullYear(),
            m = date.getMonth()
        let firstDay = new Date(y, m, 1)
        let lastDay = new Date(y, m + 1, 1)
        if (boats && boatsBobEvents) {
            for (let j = 0; j < numberMonth; j++) {
                for (let i = 0; i < boatsBobEvents.length; i++) {
                    boatsBobEvents[i].forEach(event => {
                        const { detail } = event
                        if (detail) {
                            if (detail.archived === null) {
                                if (!event.delimitedDate?.startDate) {
                                    const stock = getElementStockByDate(
                                        event,
                                        firstDay,
                                    )
                                    if (stock > 0) {
                                        partInStock += stock
                                    } else {
                                        partNoStock += 1
                                    }
                                }
                            }
                        }
                    })
                }
                label.push(
                    '1 ' +
                        new Date(firstDay).toLocaleString('default', {
                            month: 'short',
                        }),
                )
                if (type === 1) {
                    data.push(partInStock)
                } else {
                    data.push(partNoStock)
                }
                firstDay = lastDay
                y = firstDay.getFullYear()
                m = firstDay.getMonth()
                lastDay = new Date(y, m + 1, 1)
                partNoStock = 0
                partInStock = 0
            }
        }
        if (type === 1) {
            return {
                labels: label,
                datasets: [
                    {
                        label: this.displayText(`piece1`),
                        data: data,
                        fill: false,
                        backgroundColor: '#303f9f',
                        borderColor: '#303f9f',
                        cubicInterpolationMode: 'monotone',
                        tension: 0.4,
                    },
                ],
            }
        } else {
            return {
                labels: label,
                datasets: [
                    {
                        label: this.displayText(`piece2`),
                        data: data,
                        fill: false,
                        backgroundColor: '#303f9f',
                        borderColor: '#303f9f',
                        cubicInterpolationMode: 'monotone',
                        tension: 0.4,
                    },
                ],
            }
        }
    }

    onDatesChange(start, end) {
        this.setState({
            startDate: start,
            endDate: end,
        })
    }

    render() {
        const { classes } = this.props
        this.stockForGraphic()
        const options = {
            scales: {
                y: {
                    ticks: {
                        beginAtZero: true,
                    },
                },
                x: {
                    grid: {
                        color: 'transparent',
                        display: true,
                        drawBorder: false,
                        zeroLineColor: '#ccc',
                        zeroLineWidth: 1,
                    },
                },
            },
            plugins: {
                legend: {
                    display: false,
                },
            },
        }
        const filters = [
            {
                id: 1,
                title: this.displayText(`filter1`),
                function: this.repairForGraphique(2),
            },
            {
                id: 2,
                title: this.displayText(`filter2`),
                function: this.repairForGraphique(1),
            },
            {
                id: 3,
                title: this.displayText(`filter3`),
                function: this.repairForGraphique(3),
            },
            {
                id: 4,
                title: this.displayText(`filter4`),
                function: this.spentOrEarnedForGraphic(0),
            },
            {
                id: 5,
                title: this.displayText(`filter5`),
                function: this.spentOrEarnedForGraphic(1),
            },
            {
                id: 6,
                title: this.displayText(`filter6`),
                function: this.stockForGraphic(1),
            },
            {
                id: 7,
                title: this.displayText(`filter7`),
                function: this.stockForGraphic(2),
            },
        ]

        return (
            <div className={classes.root}>
                <div className={classes.filter}>
                    <TextField
                        id="type"
                        variant="outlined"
                        margin="dense"
                        required
                        select
                        value={this.state.filterSelect}
                        onChange={e => {
                            this.setState({
                                filterSelect: e.target.value,
                            })
                        }}
                        className={classes.textField}
                        InputProps={{
                            classes: {
                                input: classes.input,
                                marginDense: classes.marginInput,
                            },
                        }}
                        InputLabelProps={{
                            classes: {
                                root: classes.labelInput,
                            },
                        }}
                    >
                        {filters.map(filter => {
                            return (
                                <MenuItem key={filter.id} value={filter.id}>
                                    {filter.title}
                                </MenuItem>
                            )
                        })}
                    </TextField>

                    <div className={classes.rootDateRange}>
                        <MuiPickersUtilsProvider
                            utils={dateUtils}
                            locale={
                                this.getLanguage() === 'FR'
                                    ? LocaleFr
                                    : LocaleEn
                            }
                        >
                            <BoatOnDateSelector
                                isDateRange
                                isCompact
                                onDateChange={this.onDatesChange}
                                start={this.state.startDate}
                                end={this.state.endDate}
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                </div>
                <Line
                    className={classes.line}
                    data={filters[this.state.filterSelect - 1].function}
                    options={options}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)

    return {
        user: state.authentication.user,
        bob: state.bob,
        events: filterActions.filterBobState(
            state.bob.events,
            state.filter.bobFilter,
            context,
            {
                searchString: true,
                rangeDate: true,
                checkupTypesChecked: true,
                amountRange: true,
                rangeHour: true,
                equipmentFamily: true,
                equipment: true,
            },
        ),
        eventTypes: typesActions.getEventTypeCheckup(state.types.eventTypes),
        groupId: state.group.currentGroupId,
        loadingEvents: state.bob.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MyFleet))
