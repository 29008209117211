import React from 'react'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import dictionary from '../Dictionary/RepairListDico'
import BoatOnAlert from '../../../../common/UsefullComponents/BoatOnAlert'
import { connect } from 'react-redux'

class UnvalidModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    _body() {
        return (
            <>
                <BoatOnAlert
                    severity={`info`}
                    description={this.displayText('unValidateText')}
                />
            </>
        )
    }

    render() {
        const { title, unvalidatedEvent } = this.props

        if (!unvalidatedEvent) return <></>

        return (
            <>
                {this._renderTitle(title)}
                {this._renderBody({
                    body: this._body(),
                })}
                {this._renderActions([
                    {
                        disabled: this.props.loading > 0,
                        label: this.displayText(`cancel`),
                        action: this.props.handleClose,
                        type: `text`,
                    },
                    {
                        disabled: this.props.loading > 0,
                        label: this.displayText(`unvalid`),
                        action: unvalidatedEvent,
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        loading: state.bob.loading,
    }
}

export default connect(mapStateToProps)(UnvalidModal)
