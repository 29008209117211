export default theme => ({
    tooltip: {
        backgroundColor: '#3040a0',
        color: 'white',
        maxWidth: 300,
        border: '1px solid #dadde9',
        fontSize: 15,
    },
    helpIcon: {
        marginTop: -10,
        width: 20,
        height: 20,
        marginRight: 3,
        marginLeft: 3,
    },
    tabTitle: {
        fontSize: 14,
        fontWeight: 400,
        marginBottom: 20,
    },

    //Tab : Crew
    crewPage: {
        display: 'flex',
        flexDirection: 'column',
        gap: 16,
    },
    sectionContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    autocompleteInput: {
        minHeight: 50,
        '& > div': {
            minHeight: 50,
        },
        '& > label.MuiInputLabel-outlined.MuiInputLabel-marginDense': {
            transform: 'translate(14px, 17px) scale(1)',
        },
        '& > label.MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
        },
    },

    //Tab : Niveau de reserve
    stockWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10,
        marginBottom: 10,
        gap: 10,
    },
    stockIconContainer: {
        height: 40,
        width: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.primary.main,
        borderRadius: '100%',
    },
    stockIcon: {
        height: 25,
        width: 25,
        filter: 'invert(1)',
    },
    stockVerticalWrapper: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    stockTitle: {
        fontSize: 16,
        fontWeight: 400,
    },
    stockDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    stockInput: {
        minWidth: 100,
        flex: 1,
    },
    stockInputWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        width: '100%',
    },
    stockInfo: {
        fontSize: 14,
        fontWeight: 200,
        color: 'grey',
        minWidth: 70,
    },
    stockInfoPercent: {
        fontSize: 14,
        fontWeight: 200,
        color: 'grey',
        minWidth: 70,
        textAlign: 'end',
    },

    //Tab : Heures de marche
    equipmentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 10,
        maringBottom: 10,
    },
    equipmentIcon: {
        height: 25,
        width: 25,
        marginRight: 20,
    },
    equipmentDetails: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },

    noCapacity: {
        '&:hover': {
            cursor: 'help',
        },
    },
})
