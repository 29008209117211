export default theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: `-10px`,
    },
    rootLine: {
        padding: '10px',
        minHeight: '40px',
        height: '40px',
        backgroundColor: '#F7F9FB',
        minWidth: `670px`,
        width: 'calc(100vw - calc(100vw - 892px))',
        display: `grid`,
        alignItems: 'center',
        gridTemplateColumns: `50px 2fr 2fr 2fr 2fr 105px`,
        gridTemplateRows: `100%`,
        gridColumnGap: `5px`,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)',
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: `30px 140px 140px 120px 120px auto`,
        },
    },
    backgroundColorWhite: {
        backgroundColor: 'white',
    },
    bellIcon: {
        width: '20px',
        height: '20px',
        marginLeft: '5px',
        marginTop: '5px',
        [theme.breakpoints.down('md')]: {
            width: '15px',
            height: '15px',
            marginBottom: '5px',
        },
    },
    tooltip: {
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
    },
    dateAlert: {
        width: '30px',
        height: '30px',
        borderRadius: '5px',
        backgroundColor: '#C4C4C4',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        [theme.breakpoints.down('md')]: {
            width: '25px',
            height: '25px',
        },
    },
    dateAlertGreen: {
        backgroundColor: '#6BC070',
    },
    dateAlertOrange: {
        backgroundColor: '#F4BA5C',
    },
    dateAlertRed: {
        backgroundColor: '#E36B61',
    },
    date: {
        width: '105px',
        marginRight: '10px',
        marginTop: '4px',
    },
    icons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '105px',
        justifyContent: 'flex-end',
    },
    iconButton: {
        color: 'black',
        padding: '5px',
    },
    basic: {
        color: '#3040a0',
    },
    grey: {
        color: '#C4C4C4',
    },
    titleLine: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '15px',
        marginTop: '10px',
    },
    title: Object.assign({}, theme.typography.minititle, { fontSize: 14 }),
    dates: {
        display: 'flex',
        flexDirection: 'row',
        width: '300px',
    },
    infos: {
        width: 'calc(100% - 486px)',
        marginLeft: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        flexWrap: 'wrap',
        marginRight: 'auto',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            width: 'calc(100% - 115px)',
            overflow: 'hidden',
        },
    },
    div50: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    validateText: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '0.75rem',
        width: '100%',
        textAlign: 'center',
    },
    validateDate: {
        width: '105px',
        marginRight: '10px',
    },
    appBar: {
        boxShadow: `none`,
    },
    rootTab: {
        color: `#0000006b`,
        fontSize: 14,
        textTransform: `none`,
        minWidth: 0,
        fontWeight: 400,
        padding: 0,
        marginRight: 22,
    },
    divider: {
        width: `calc(100% + 48px)`,
        marginLeft: `-24px`,
    },
    body: {
        minHeight: '350px',
        maxHeight: '350px',
    },
    headerTable: {
        display: `grid`,
        gridTemplateColumns: `50px 2fr 2fr 2fr 2fr 105px`,
        width: 'calc(100vw - calc(100vw - 892px))',
        margin: '10px',
        gridColumnGap: `5px`,
        marginTop: 0,
        minWidth: `670px`,
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)',
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: `30px 140px 140px 120px 120px auto`,
            width: '100%',
        },
    },
    scrollableBody: {
        maxHeight: `280px`,
        overflowY: `auto`,
        overflowX: `hidden`,
        [theme.breakpoints.down('sm')]: {
            minWidth: '670px',
            width: '100%',
        },
    },
    tableTitle: {
        fontWeight: 700,
        fontSize: `12px`,
    },
    table: {
        overflowX: `auto`,
        whiteSpace: 'nowrap',
        minWidth: `100%`,
        height: `auto`,
    },
})
