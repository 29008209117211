export default theme => ({
    allPhotos: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    images: {
        width: '100px',
        height: '100px',
        marginRight: '15px',
        position: 'relative',
        cursor: 'pointer',
    },
    pictures: {
        margin: 'auto',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        border: '0.5px solid #C4C4C4',
        position: 'relative',
    },
    importantImage: {
        width: '30rem',
        margin: 'auto',
        height: '100%',
        position: 'relative',
        display: 'inline-block',
    },
    cross: {
        position: 'absolute',
        right: 0,
        top: 0,
        '&:hover': {
            cursor: 'pointer',
        },
        zIndex: 1,
    },
})
