export default theme => ({
    buttonSecondary: {
        background: `#E1E4F5`,
        border: `1px solid #3040a0`,
        color: `#212121`,
        borderRadius: `8px`,
        boxShadow: `0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)`,
    },
    buttonText: {
        color: `#c4c4c4`,
        border: `none`,
        background: `white`,
        boxShadow: `none`,
        '&:hover': {
            color: '#3040a0',
        },
        '&:active': {
            backgroundColor: `white`,
            boxShadow: `none`,
        },
    },
    buttonPrimary: {
        background: `#3040a0`,
        color: `white`,
        boxShadow: `0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)`,
        border: `1px solid #3040a0`,
        borderRadius: `8px`,
        '&:hover': {
            backgroundColor: '#4758C7',
        },
        '&:active': {
            backgroundColor: '#4758C7',
            boxShadow: `none`,
        },
        '&:focus': {
            // a voir
        },
    },
    buttonLight: {
        background: `white`,
        color: `#212121`,
        boxShadow: `0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)`,
        border: `1px solid #3040a0`,
        borderRadius: `8px`,
        '&:hover': {
            backgroundColor: '#c4c4c4',
        },
        '&:active': {
            backgroundColor: '#c4c4c4',
            boxShadow: `none`,
        },
        '&:focus': {
            // a voir
        },
    },
    buttonCancel: {
        background: `#e0e0e0`,
        color: `black`,
        '&:hover': {
            backgroundColor: '#c4c4c4',
        },
        '&:active': {
            backgroundColor: '#c4c4c4',
            boxShadow: `none`,
        },
    },
    buttonError: {
        background: '#E36B61',
        color: `white`,
        '&:hover': {
            backgroundColor: 'red',
        },
        '&:active': {
            backgroundColor: 'red',
            boxShadow: `none`,
        },
        borderRadius: `8px`,
        border: '1px solid #E36B61',
    },
    popoverDropdown: {
        marginTop: 10,
    },
    muiButtonRoot: {
        textTransform: `none`,
        fontSize: `14px`,
        fontWeight: `500`,
        fontStyle: `normal`,
        marginLeft: '0px !important',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
    },
    icon: {
        marginLeft: '0px !important',
    },
    disabled: {
        border: `none`,
    },
    label: {
        fontSize: '14px',
        letterSpacing: '0.40px',
        fontWeight: 500,
        lineHeight: '20px',
    },
    selectedCount: {
        marginLeft: '5px',
        borderRadius: '50%',
        color: '#3040a0',
        backgroundColor: 'white',
        width: '20px',
        height: '20px',
        fontWeight: 400,
    },
})
