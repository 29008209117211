/**
 * Get title with translate of an event
 *
 * @function getTranslatedTitleFromEvent
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {Object} parent - this from the calling class.
 * @param {Event} event - event from bob event.
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 18/06/2024 - 16:35
 * @author Samuel.C
 */

export function getTranslatedTitleFromEvent(parent, event) {
    if (!event || !parent) {
        console.error(`
            Error: les valeurs d'entrés ne sont pas valides:
                Parent: ${parent}
                Event: ${event}
        `)
        return ''
    }

    const { checkup, detail } = event

    if (checkup)
        return checkup?.checkupType
            ? parent.displayTextApi(checkup.checkupType?.translation)
            : checkup?.userCheckup
            ? checkup.userCheckup.name
            : event.title
    else if (detail) {
        const part = detail.part
        const equipment = detail.equipment

        if (part) {
            if (part.userPart) return part.userPart.name
            else if (part.partType)
                return parent.displayTextApi(part.partType.translation)
        } else if (equipment) {
            if (equipment.userEquipment) return equipment.userEquipment.name
            else if (equipment.equipmentType)
                return parent.displayTextApi(
                    equipment.equipmentType.translation,
                )
        }
    }
    return event.title
}

/**
 * Get title with translate of an event
 *
 * @function isSafariIOS
 *
 * @region ```
 * return values
 * ```
 *
 * @return {Boolean} - if true, the device is on IOS and with safari navigator.
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 25/07/2024 - 15:32
 * @author Samuel.C
 */
export function isSafariIOS() {
    const ua = navigator.userAgent
    // Check if the device is running iOS
    const isIOS = /iP(hone|od|ad)/.test(ua)
    // Check if the browser is Safari
    const isSafari =
        /Safari/.test(ua) && !/Chrome/.test(ua) && !/CriOS/.test(ua)

    return isIOS && isSafari
}
