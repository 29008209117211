export default theme => ({
    text: {
        color: 'black',
    },
    line: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr 2fr',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            gridTemplateRows: 'repeat(3, auto)',
            gridTemplateColumns: 'unset',
        },
    },
    group: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        flexFlow: `row wrap`,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '400px',
        },
    },
    groupNoWrap: {
        display: 'flex',
        alignItems: 'center',
        columnGap: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            maxWidth: '400px',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            gap: 12,
        },
    },
    hiddeMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    hiddeDesktop: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    input: {
        backgroundColor: 'white',
    },
})
