export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 40px)',
        padding: '20px',
        paddingTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0,
            margin: 0,
        },
    },
    title: {
        fontWeight: 500,
        marginBottom: '10px',
    },
    subtitle: {
        color: '#b4b4b4',
        padding: '10px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
    },
    priceTotal: {
        fontSize: '22px',
        fontWeight: 500,
        padding: '10px',
        paddingRight: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
        },
    },
    text: {
        fontSize: '12px',
    },
    price: {
        fontSize: '16px',
        fontWeight: 500,
    },
    infos: {
        border: '1px solid #c4c4c4',
        boxShadow: '0px 2px 10px 0.3px #c4c4c4',
        padding: '10px',
        width: 'calc(100% - 20px)',
    },
    divider: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    income: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginRight: '25px',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    noMargin: {
        margin: '0 !important',
    },
    accordionSummaryContent: {
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 500,
        transition: 'all linear 0.2s',
        '&.Mui-expanded': {
            fontSize: 20,
            transition: 'all linear 0.2s',
        },
    },
    accordionContent: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    expense: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: '25px',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    expenseHead: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    expenseBody: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '20px',
        paddingInline: 20,
        gap: 40,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    row: {
        display: 'flex',
        justifyContent: 'space-evenly',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            alignItems: 'center',
            gap: 30,
        },
    },
    graph: {
        marginBlock: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
        },
    },
    bar: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    doughnut: {
        width: '20%',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
    },
    compte: {
        display: 'flex',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 5,
        },
    },
    line: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        // justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    inactive: {
        opacity: 0.2,
    },
    texts: {
        width: '125px',
        minWidth: '125px',
        marginTop: 'auto',
        display: 'block',
        marginBottom: 'auto',
        paddingTop: '10px',
        paddingBottom: '10px',
    },
    firstLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    icon: {
        margin: 'auto',
        // marginLeft: '50%',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
            marginTop: 'auto',
            marginBottom: 'auto',
        },
    },
    field: {
        width: '98%',
        margin: 'auto',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    recherche: {
        width: '40%',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: '15px',
        },
    },
    accordionDisabled: {
        opacity: 1,
    },
})
