import { withStyles } from '@material-ui/styles'
import React from 'react'
import styles from './Styles/MyUsersPageCss'
import BoatOnComponent from '../../../common/BoatOnComponent'
import dictionary from './Dictionary/MyUsersPageDictionary'
import Typography from '@material-ui/core/Typography'
import BoatOnAppBar from '../../../common/BoatOnAppBar'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create'
import BoatOnModal from '../../../common/BoatOnModal'
import { Button } from '../../../common'
import EditUserModal from './EditUserModal'
import NewUserModal from './NewUserModal'
import { Paper, TextField } from '@material-ui/core'
import { groupActions } from '../../../../actions/group.actions'
import { userActions } from '../../../../actions/user.actions'
import { connect } from 'react-redux'
import ChangeTitleModal from './ChangeTitleModal'
import PersoNewUserModal from './PersoNewUserModal'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'
import { fixIosHeaderBug } from '../../../../utils/usefullFunctions'
import {
    Actions,
    PermissionsEntities,
    PermissionsPageTypeIds,
} from '../../../../constants'

class MyUsersPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            roles: [],
            users: [],
            index: 0,
            openModal: ``,
            userToDelete: undefined,
            userToEdit: null,
            search: ``,
            nom: ``,
            newUserEmail: null,
        }

        this._changeIndex = this._changeIndex.bind(this)
        this._renderArray = this._renderArray.bind(this)
        this._delete = this._delete.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this._getModalDelete = this._getModalDelete.bind(this)
        this.handleNewUser = this.handleNewUser.bind(this)
        this._openModalAddUser = this._openModalAddUser.bind(this)
        this._changeSearch = this._changeSearch.bind(this)
        this._manageGroupDisplay = this._manageGroupDisplay.bind(this)
        this._changeName = this._changeName.bind(this)
        this._submitName = this._submitName.bind(this)
        this._openModalChangeTitle = this._openModalChangeTitle.bind(this)
        this.submitModifName = this.submitModifName.bind(this)
        this.openModalEdit = this.openModalEdit.bind(this)
    }

    async submitModifName(name) {
        //changer le nom
        await this.props.dispatch(
            groupActions.changeGroupName(name, this.props.groups.id),
        )
        await this.props.dispatch(userActions.init())
    }

    _openModalChangeTitle() {
        this.setState({ openModal: `changeTitle` })
    }

    async _delete(user) {
        if (this.state.openModal === `delete`) {
            await this.props.dispatch(
                groupActions.deleteUserFromGroup(
                    this.state.userToDelete.userRGUId,
                ),
            )
            await this.props.dispatch(
                groupActions.requestUserGroups(this.props.groupId),
            )
            this.setState({ openModal: false, userToDelete: undefined })
        } else {
            this.setState({ openModal: `delete`, userToDelete: user })
        }
    }

    componentDidMount() {
        const { groups } = this.props
        const { users } = this.state

        if (groups && users.length === 0) {
            this._manageGroupDisplay()
        }
    }

    componentDidUpdate(prevProps) {
        const { user, groups, groupId, roles } = this.props
        const { users } = this.state
        const urlParams = new URLSearchParams(window.location.search)

        if (
            urlParams.has(`email`) &&
            (this.state.newUserEmail === null ||
                this.state.newUserEmail !== urlParams.get(`email`))
        ) {
            this.setState({
                openModal: `persoUser`,
                newUserEmail: urlParams.get(`email`),
            })
        }

        if (
            JSON.stringify(prevProps.groups) !==
                JSON.stringify(this.props.groups) &&
            this.props.groups !== null
        ) {
            this._manageGroupDisplay()
        }

        if (user && groups === null) {
            if (groupId !== -1)
                this.props.dispatch(groupActions.requestUserGroups(groupId))
            else if (user?.userGroup && user.userGroup.length > 0)
                this.props.dispatch(
                    groupActions.requestUserGroups(user.userGroup[0].id),
                )
        }

        if (roles === null) {
            this.props.dispatch(groupActions.requestRoles())
        }

        if (user?.ownGroup?.id && groupId === -1) {
            this.props.dispatch(
                groupActions.changeCurrentGroup(user?.ownGroup?.id),
            )
        }

        if (
            urlParams.has('id') &&
            users.length > 0 &&
            roles !== null &&
            this.state.openModal === ''
        ) {
            const id = +urlParams.get('id')
            const url = new URL(window.location)

            url.searchParams.delete('id')
            window.history.pushState('', document.title, url)

            this.setState({
                openModal: 'edit',
                userToEdit: users.find(u => u.userRGUId === id),
            })
        }
    }

    async _submitName() {
        await this.props.dispatch(groupActions.createGroup(this.state.nom))
        await this.props.dispatch(userActions.init())
        //changer le nom du groupe    // "API_URL": "http://localhost:3001",
    }

    _changeName({ target: { value } }) {
        this.setState({ nom: value })
    }

    _openModalAddUser() {
        this.setState({ openModal: `newUser` })
    }

    _changeSearch({ target: { value } }) {
        this.setState({ search: value })
    }

    handleCloseModal() {
        if (this.state.openModal === `persoUser`) {
            const urlParams = new URLSearchParams(window.location.search)
            if (urlParams.has(`email`)) {
                const url = new URL(window.location)
                url.searchParams.delete(`email`)
                window.history.pushState('', document.title, url)
            }
        }
        this.setState({
            userToDelete: undefined,
            openModal: false,
        })
    }

    async handleNewUser(email, role, boatGroup) {
        const { groupId } = this.props
        window.history.pushState('', document.title, `?email=${email}`)
        await this.props.dispatch(
            groupActions.addUserToGroup(
                groupId,
                role,
                boatGroup,
                email,
                this.context,
            ),
        )
        this.setState({ openModal: `persoUser` })
    }

    _getModalDelete() {
        const { classes } = this.props
        const { userToDelete } = this.state

        return (
            <div className={classes.containerDelete}>
                <Typography className={classes.titleDelete} variant={`body1`}>
                    {this.displayText(`areYouSure1`)}
                    {userToDelete?.firstName && userToDelete?.lastName ? (
                        <strong>
                            {` ${userToDelete?.firstName} ${userToDelete?.lastName} (${userToDelete?.email}) `}
                        </strong>
                    ) : (
                        this.displayText(`thisUser`)
                    )}
                    {' ' + this.displayText(`areYouSure2`)}
                </Typography>
                <div className={classes.buttonsDelete}>
                    <Button
                        onClick={this.handleCloseModal}
                        label={this.displayText(`cancel`)}
                        type={`cancel`}
                    />
                    <Button
                        onClick={this._delete}
                        label={this.displayText(`delete`)}
                    />
                </div>
            </div>
        )
    }

    openModalEdit() {
        const { users } = this.state
        const url = new URL(window.location)
        url.searchParams.delete(`email`)
        window.history.pushState('', document.title, url)
        this._edit(
            users.find(
                u =>
                    u.email === this.state.newUserEmail ||
                    u.userSubscribe?.mail === this.state.newUserEmail,
            ),
        )
    }

    _edit(user, index) {
        if (this.state.openModal === `edit`) {
            //appliquer les modifications l'utilisateur
        } else {
            this.setState({ openModal: `edit`, userToEdit: user })
        }
    }

    _manageGroupDisplay() {
        const { groups } = this.props
        let users = [...groups.linkRGU]
        users = users.map(e => {
            return {
                ...e.user,
                boatsGroup: e.boats,
                roles: e.userRole,
                userRGUId: e.id,
                userGroupId: groups.id,
                userSubscribe: e.userSubscribe,
                rights: e.rights,
                hasCompany: e.hasCompany,
            }
        })
        users.sort((a, b) =>
            this.displayTextApi(a.roles.translation).localeCompare(
                b.roles.translation,
            ),
        )

        let roles = {}
        users.forEach(p => {
            if (roles[p.roles.id]) roles[p.roles.id].nb++
            else
                roles[p.roles.id] = {
                    nb: 1,
                    name: p.roles.userRole
                        ? p.roles.userRole
                        : p.roles.translation
                        ? this.displayTextApi(p.roles.translation)
                        : '',
                }
        })
        const user = users.filter(
            user => this.state.userToEdit?.id === user?.id,
        )
        if (user.length > 1 && this.state.userToEdit?.userSubscribe?.id) {
            const userSubscribe = users.filter(
                user =>
                    this.state.userToEdit?.userSubscribe?.id ===
                    user?.userSubscribe?.id,
            )
            this.setState({
                roles,
                users: [...users],
                userToEdit:
                    userSubscribe?.length > 0
                        ? userSubscribe[0]
                        : this.state.userToEdit,
            })
        } else {
            this.setState({
                roles,
                users: [...users],
                userToEdit: user?.length > 0 ? user[0] : this.state.userToEdit,
            })
        }
        if (
            roles &&
            Object.entries(roles) &&
            Object.entries(roles).length - 1 < this.state.index
        ) {
            this.setState({ index: 0 })
        }
    }

    _renderArray(role) {
        const { users, search } = this.state
        const { classes } = this.props
        let usersAfterFilter = [...users]
        usersAfterFilter.filter(u => u.userSubscribe)
        const searchStatus = (search, status) =>
            search.toLowerCase() === this.displayText(status).toLowerCase()

        if (role) {
            usersAfterFilter = usersAfterFilter.filter(
                u => +u.roles.id === +role,
            )
        }

        if (search !== '') {
            usersAfterFilter = usersAfterFilter.filter(
                u =>
                    `${u.firstName?.toLowerCase()} ${u.lastName?.toLowerCase()}`.includes(
                        search.toLowerCase(),
                    ) ||
                    u.email?.toLowerCase().includes(search.toLowerCase()) ||
                    (u.roles?.translation &&
                        u.roles?.translation[this.context]
                            .toLowerCase()
                            .includes(search.toLowerCase())) ||
                    (searchStatus(search, 'attente') ? u.userSubscribe : 0) ||
                    (searchStatus(search, 'actif') ? u.activeAccount : 0) ||
                    (searchStatus(search, 'suspendu')
                        ? !u.activeAccount && u.userSubscribe === null
                        : 0),
            )
        }

        return (
            <div className={classes.tableContainer}>
                <table className={classes.table}>
                    <tbody>
                        <tr className={classes.columnsTitles}>
                            <th className={classes.firstColumn}>
                                {' '}
                                {this.displayText(`compte`)}{' '}
                            </th>
                            <th> {this.displayText(`email`)} </th>
                            <th> {this.displayText(`role`)} </th>
                            <th> {this.displayText(`status`)} </th>
                            <th></th>
                        </tr>

                        {usersAfterFilter.map((e, i) => (
                            <tr key={i} className={classes.rows}>
                                <td
                                    className={
                                        classes.firstColumn + ' ' + classes.row
                                    }
                                >
                                    {e.firstName} {e.lastName}
                                </td>
                                <td className={classes.row}>
                                    {e.email || e.userSubscribe.mail}
                                </td>
                                <td className={classes.row}>
                                    {e.roles.userRole
                                        ? e.roles.userRole
                                        : this.displayTextApi(
                                              e.roles.translation,
                                          )}
                                </td>
                                <td className={`${classes.row}`}>
                                    <Typography
                                        className={`${classes.status} ${
                                            e.userSubscribe !== null
                                                ? classes.waiting
                                                : !e.activeAccount
                                                ? classes.inactivity
                                                : classes.activity
                                        }`}
                                    >
                                        {e.userSubscribe !== null
                                            ? this.displayText(`attente`)
                                            : !e.activeAccount
                                            ? this.displayText(`suspendu`)
                                            : this.displayText(`actif`)}
                                    </Typography>
                                </td>
                                <td className={classes.icons}>
                                    <BoatOnBlock
                                        permissions={{
                                            action: Actions.Update,
                                            entityName:
                                                PermissionsEntities.LinkRoleGroupUser,
                                        }}
                                        children={
                                            <CreateIcon
                                                onClick={() => this._edit(e)}
                                                className={classes.icon}
                                            />
                                        }
                                    />
                                    <BoatOnBlock
                                        permissions={{
                                            action: Actions.Delete,
                                            entityName:
                                                PermissionsEntities.LinkRoleGroupUser,
                                        }}
                                        children={
                                            <DeleteIcon
                                                onClick={() => this._delete(e)}
                                                className={classes.icon}
                                            />
                                        }
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }

    _changeIndex(index) {
        this.setState({ index })
    }

    render() {
        const { classes } = this.props
        const { users, roles, index, openModal } = this.state
        return (
            <Paper className={classes.container}>
                <div className={classes.header}>
                    <div className={classes.headerText}>
                        <Typography
                            className={classes.title}
                            component="h1"
                            variant="h1"
                        >
                            {this.displayText(`title`)}
                        </Typography>
                        {this.props.groupId !== -1 && (
                            <div
                                className={classes.subHeader}
                                onClick={this._openModalChangeTitle}
                            >
                                <Typography
                                    className={classes.subTitle}
                                    component="h2"
                                >
                                    {this.props.groups?.name}
                                </Typography>
                                <CreateIcon className={classes.iconSubTitle} />
                            </div>
                        )}
                    </div>
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Create,
                            entityName: PermissionsEntities.LinkRoleGroupUser,
                        }}
                        children={
                            <Button
                                disabled={this.props.groupId === -1}
                                classNameOverride={classes.addUser}
                                label={this.displayText(`addUser`)}
                                onClick={this._openModalAddUser}
                            />
                        }
                    />
                </div>
                <>
                    <TextField
                        label={this.displayText(`search`)}
                        onChange={this._changeSearch}
                        className={classes.field}
                        value={this.state.search}
                        variant="outlined"
                        size="small"
                        type="text"
                        onBlur={() => fixIosHeaderBug()}
                    />
                    <div className={classes.appBarContainer}>
                        <BoatOnAppBar
                            labels={[
                                `Tous (${users.length})`,
                                ...Object.entries(roles).map(
                                    p => `${p[1].name} (${p[1].nb})`,
                                ),
                            ]}
                            tabIndex={index}
                            displayBodyOut={this._changeIndex}
                        />
                    </div>
                    {this._renderArray(
                        index === 0
                            ? false
                            : Object.entries(roles)[index - 1][0],
                    )}
                </>
                <BoatOnModal
                    openCondition={openModal}
                    handleClose={this.handleCloseModal}
                    side={openModal === `edit` ? 'right' : null}
                    modalCores={{
                        delete: this._getModalDelete(),
                        edit: (
                            <EditUserModal
                                handleClose={this.handleCloseModal}
                                user={this.state.userToEdit}
                                boats={this.props.boatsGroup}
                                closeModal={this.handleCloseModal}
                            />
                        ),
                        newUser: (
                            <NewUserModal
                                handleSend={this.handleNewUser}
                                groupId={this.props.groups?.id}
                                boats={this.props.boatsGroup}
                            />
                        ),
                        persoUser: (
                            <PersoNewUserModal
                                role={this.displayTextApi(
                                    users.find(
                                        u =>
                                            u.email ===
                                                this.state.newUserEmail ||
                                            u.userSubscribe?.mail ===
                                                this.state.newUserEmail,
                                    )?.roles?.translation,
                                )}
                                closePerso={this.handleCloseModal}
                                openPerso={this.openModalEdit}
                            />
                        ),
                        changeTitle: (
                            <ChangeTitleModal
                                groupName={this.props.groups?.name}
                                submitModifName={this.submitModifName}
                            />
                        ),
                    }}
                    titles={{
                        delete: `Supprimer cet utilisateur`,
                        edit: `Modifier les informations de cet utilisateur`,
                        newUser: `Invitation d un nouvel utilisateur`,
                        changeTitle: `Changer le titre`,
                        persoUser: `Personnaliser cet utilisateur`,
                    }}
                    maxWidth={{
                        persoUser: `sm`,
                        delete: `xs`,
                        edit: `sm`,
                        newUser: `xs`,
                        changeTitle: `xs`,
                    }}
                />
            </Paper>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        groups: state.group.groupsMembers,
        boatsGroup: state.group.groupsMembers
            ? state.group.groupsMembers.boats
            : null,
        groupId: state.group.currentGroupId,
        roles: state.group.roles,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MyUsersPage))
