export default theme => ({
    root: {
        position: 'relative',
    },
    buttonAccept: {
        minWidth: '200px',
        marginTop: '20px',
        marginRight: '20px',
    },
    buttonCancel: {
        minWidth: '200px',
        marginTop: '20px',
        marginRLeft: '20px',
        background: theme.palette.waiting.main,
    },
    infos: Object.assign({}, theme.flex.columnAlignCenter, {
        alignItems: 'flex-start',
    }),
})
