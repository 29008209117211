import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Modal from '@material-ui/core/Modal'
import dictionary from './Dictionary/ModalBoatDico'
import styles from './Styles/ModalBoatsCss'
import BoatOnComponent from '../../BoatOnComponent'
import ModalCreateBoat from './ModalCreateBoat'

class ModalNothingBoatonBook extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            openCreateBoat: false,
        }

        this.handleCreateBoat = this.handleCreateBoat.bind(this)
        this.handleSignIn = this.handleSignIn.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    handleCreateBoat() {
        this.setState({ openCreateBoat: true })
        this.handleClose()
    }

    handleSignIn() {
        this.setState({ openSignIn: true })
    }

    handleClose() {
        this.setState({ openCreateBoat: false })
        this.props.handleClose()
    }

    renderPreChoice() {
        const { classes } = this.props

        return (
            <div className={classes.preChoice}>
                <Typography className={classes.helpText}>
                    {this.displayText('nothing')}
                </Typography>
            </div>
        )
    }

    render() {
        const { classes } = this.props

        return (
            <Modal
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                open={this.props.open || false}
                onClose={this.handleClose}
            >
                <div id="modal" className={classes.paper}>
                    <Typography
                        variant="subtitle1"
                        id="simple-modal-description"
                    >
                        <div>
                            {(!this.state.openCreateBoat &&
                                this.renderPreChoice()) ||
                                (this.state.openCreateBoat && (
                                    <ModalCreateBoat
                                        handleClose={this.props.handleClose}
                                    />
                                ))}
                        </div>
                    </Typography>
                </div>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        state: state.open,
    }
}

export default connect(mapStateToProps)(
    withStyles(styles)(ModalNothingBoatonBook),
)
