import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from './BoatOnComponent'
import styles from './Styles/BoatOnAppBarCss'
import dictionary from './Dictionary/BoatOnModalDico'
import { AppBar, Tabs, Tab, Divider } from '@material-ui/core'

class BoatOnAppBar extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            tabIndex: props.tabIndex || 0,
        }

        this.handleChangeTab = this.handleChangeTab.bind(this)
    }

    handleChangeTab(_, newValue) {
        const { displayBodyOut } = this.props
        this.setState({ tabIndex: newValue })
        if (displayBodyOut) displayBodyOut(newValue)
    }

    componentDidUpdate() {
        if (
            this.props.tabIndex !== null &&
            this.props.tabIndex !== this.state.tabIndex
        ) {
            this.setState({ tabIndex: this.props.tabIndex })
        }
    }

    _renderAppBarWithoutBody() {
        const { tabIndex } = this.state
        const { classes, labels, disableDivider } = this.props

        return (
            <>
                <AppBar
                    position="static"
                    color={'transparent'}
                    classes={{ colorTransparent: classes.appBar }}
                >
                    <Tabs
                        value={tabIndex}
                        onChange={this.handleChangeTab}
                        aria-label="boaton-tabs"
                        indicatorColor={`primary`}
                        textColor={`primary`}
                    >
                        {labels.map(label => (
                            <Tab
                                key={label}
                                label={label}
                                classes={{
                                    root: classes.rootTab,
                                }}
                            />
                        ))}
                    </Tabs>
                </AppBar>
                {!disableDivider && <Divider className={classes.divider} />}
            </>
        )
    }

    _renderAppBarWithBody() {
        const { tabIndex } = this.state
        const { cores, bodyMinHeigh = 0 } = this.props

        return (
            <>
                {this._renderAppBarWithoutBody()}
                {cores.map((core, index) => (
                    <div
                        key={index}
                        index={index}
                        hidden={tabIndex !== index}
                        style={{ minHeight: bodyMinHeigh }}
                    >
                        {core}
                    </div>
                ))}
            </>
        )
    }

    render() {
        const { labels, cores, displayBodyOut } = this.props

        if (
            cores &&
            labels &&
            cores.length === labels.length &&
            !displayBodyOut
        ) {
            return <>{this._renderAppBarWithBody()}</>
        } else if (labels && !cores && displayBodyOut)
            return <>{this._renderAppBarWithoutBody()}</>

        console.error(`error for display BoatOnAppBar`)
        return <></>
    }
}

export default withStyles(styles)(BoatOnAppBar)
