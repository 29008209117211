import React from 'react'
import { connect } from 'react-redux'
import BoatOnComponent from '../BoatOnComponent'
import dictionary from './Dictionary/BoatOnBlockDico'
import { Actions } from '../../../constants'

const DisabledComponentNames = [
    'TextField',
    'Input',
    'Select',
    'Autocomplete',
    'BoatOnNumberField',
    'Checkbox',
    'AutoComplete',
    'AddressSearch',
    'MuiAutocomplete',
    'Switch',
    'Button',
    'MobileTimePicker',
]

const AccessTypes = {
    AVAILABLE: 'AVAILABLE',
    RESTRICTED: 'RESTRICTED',
    UNAVAILABLE: 'UNAVAILABLE',
}

class BoatOnBlock extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    _getAccessRights(permissions) {
        const { selectedGroup, onlyOnePermission, addedCondition } = this.props

        if (Array.isArray(permissions)) {
            let access = !onlyOnePermission
            for (const permission of permissions) {
                const userAccessToBlock = selectedGroup?.userRole?.userRoleAccess.find(
                    access =>
                        ((permission?.entityName &&
                            access?.bobPermission?.entity?.entityName ===
                                permission.entityName) ||
                            (permission?.pageTypeId &&
                                access?.bobPermission?.pageType?.id ===
                                    permission.pageTypeId)) &&
                        access?.bobPermission?.[permission.action] === true,
                )
                if (
                    !onlyOnePermission &&
                    !userAccessToBlock?.access &&
                    (!addedCondition ||
                        (addedCondition && addedCondition(permission)))
                ) {
                    access = AccessTypes.UNAVAILABLE
                    break
                } else if (
                    onlyOnePermission &&
                    !userAccessToBlock?.access &&
                    (!addedCondition ||
                        (addedCondition && addedCondition(permission)))
                ) {
                    access = AccessTypes.UNAVAILABLE
                } else if (
                    onlyOnePermission &&
                    userAccessToBlock?.access === true &&
                    (!addedCondition ||
                        (addedCondition && addedCondition(permission)))
                ) {
                    access = AccessTypes.AVAILABLE
                    break
                }
            }
            return { access }
        } else if (
            (permissions?.entityName || permissions?.pageTypeId) &&
            permissions?.action
        ) {
            const userAccessToBlock = selectedGroup?.userRole?.userRoleAccess.find(
                access =>
                    ((permissions?.entityName &&
                        access?.bobPermission?.entity?.entityName ===
                            permissions.entityName) ||
                        (permissions?.pageTypeId &&
                            access?.bobPermission?.pageType?.id ===
                                permissions.pageTypeId)) &&
                    access?.bobPermission?.[permissions.action] === true,
            )
            if (userAccessToBlock?.access !== undefined)
                return {
                    access: userAccessToBlock.access
                        ? AccessTypes.AVAILABLE
                        : AccessTypes.RESTRICTED,
                }
            else if (permissions.action === Actions.Update) {
                const userAccessToBlock = selectedGroup?.userRole?.userRoleAccess.find(
                    access =>
                        ((permissions?.entityName &&
                            access?.bobPermission?.entity?.entityName ===
                                permissions.entityName) ||
                            (permissions?.pageTypeId &&
                                access?.bobPermission?.pageType?.id ===
                                    permissions.pageTypeId)) &&
                        access?.bobPermission?.[Actions.Read] === true,
                )
                if (userAccessToBlock?.access !== undefined)
                    return {
                        access: AccessTypes.RESTRICTED,
                    }
            }
        }
        return { error: true }
    }

    _canChildrenBeDisabled(children) {
        const isDatePicker =
            children.props && (children.props.openTo || children.props.views)
        return (
            children.props?.renderInput ||
            children.props?.slotProps?.textField ||
            isDatePicker ||
            (children.type &&
                DisabledComponentNames.some(
                    componentName =>
                        children.type?.displayName?.includes(componentName) ||
                        children?.type?.name?.includes(componentName),
                ))
        )
    }

    render() {
        const {
            children,
            blockProps,
            selectedGroup,
            childrenNoAccess,
            permissions,
        } = this.props

        if (!selectedGroup) {
            return <></>
        }

        if (permissions === null) {
            console.error(
                `You didnt set a definition of permissions for this block`,
            )
            return <></>
        }

        if (!children) {
            console.error(`The children is not defined`)
            return <></>
        }

        const { access, error } = this._getAccessRights(permissions)

        if (error) {
            console.error(`This permission doesn't exist`)
            if (childrenNoAccess) {
                return childrenNoAccess
            } else {
                return <></>
            }
        }

        switch (access) {
            case AccessTypes.AVAILABLE:
                if (blockProps) return <div {...blockProps}>{children}</div>
                return children
            case AccessTypes.RESTRICTED:
                const restrictedChildren = this._canChildrenBeDisabled(
                    children,
                ) ? (
                    React.cloneElement(children, {
                        disabled: true,
                    })
                ) : (
                    <></>
                )
                if (blockProps)
                    return <div {...blockProps}>{restrictedChildren}</div>
                return restrictedChildren
            case AccessTypes.UNAVAILABLE:
                return <></>
            default:
                return <></>
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        selectedGroup: state.block.selectedGroup,
        currentGroupId: state.group.currentGroupId,
        groupLoading: state.group.loading,
        groups: state.group.groupsMembers,
    }
}

export default connect(mapStateToProps)(BoatOnBlock)
