import { chronoConstants } from '../constants'

const initialState = {
    chrono: [],
    loading: 0,
    workTime: null,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case chronoConstants.START_SEND:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case chronoConstants.START_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case chronoConstants.START_FAILURE:
            return {
                ...prevState,
                loading: -1,
            }
        case chronoConstants.CURRENT_WORK_TIME_GET:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case chronoConstants.CURRENT_WORK_TIME_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                workTime: action.workTime,
            }
        case chronoConstants.CURRENT_WORK_TIME_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                workTime: action.workTime,
            }
        case chronoConstants.BREAK_SEND:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case chronoConstants.BREAK_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case chronoConstants.BREAK_FAILURE:
            return {
                ...prevState,
                loading: 0,
            }
        case chronoConstants.RESTART_SEND:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case chronoConstants.RESTART_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case chronoConstants.RESTART_FAILURE:
            return {
                ...prevState,
                loading: 0,
            }
        case chronoConstants.STOP_SEND:
            return {
                ...initialState,
                loading: prevState.loading + 1,
            }
        case chronoConstants.STOP_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case chronoConstants.STOP_FAILURE:
            return {
                ...prevState,
                loading: 0,
            }
        default:
            return prevState
    }
}
