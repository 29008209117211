export const getUserTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const getSimpleFormatedDate = (date, context) => {
    new Date(`${date}Z`)
    return new Intl.DateTimeFormat(context, {
        timeZone: getUserTimezone(),
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
    }).format(date)
}

export const getSimpleFormatedDateTimeUTC = (date, context) => {
    new Date(`${date}Z`)
    const formattedDate = new Intl.DateTimeFormat(context, {
        timeZone: getUserTimezone(),
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }).format(date)

    return `${formattedDate} ${getFormatedOffsetUTC()}`
}

export const getSimpleFormatedDateTime = (date, context) => {
    new Date(`${date}Z`)
    return new Intl.DateTimeFormat(context, {
        timeZone: getUserTimezone(),
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }).format(date)
}

export const getSimpleFormatedTimeUTC = (date, context) => {
    new Date(`${date}Z`)
    const formattedDate = new Intl.DateTimeFormat(context, {
        timeZone: getUserTimezone(),
        hour: '2-digit',
        minute: '2-digit',
    }).format(date)

    return `${formattedDate} ${getFormatedOffsetUTC()}`
}

export const getSimpleFormatedTime = (date, context) => {
    new Date(`${date}Z`)
    return new Intl.DateTimeFormat(context, {
        timeZone: getUserTimezone(),
        hour: '2-digit',
        minute: '2-digit',
    }).format(date)
}

export const getFormatedOffsetUTC = () => {
    const currentDate = new Date()

    // Get the UTC offset in minutes and convert it to hours
    const offsetMinutes = currentDate.getTimezoneOffset()
    const offsetHours = Math.floor(Math.abs(offsetMinutes) / 60)
    const offsetMinutesRemaining = Math.abs(offsetMinutes) % 60

    // Determine if the offset is positive or negative
    const offsetSign = offsetMinutes <= 0 ? '+' : '-'

    // Format the offset (e.g., +05:30 or -02:00)
    return `(UTC${offsetSign}${String(offsetHours).padStart(2, '0')}:${String(
        offsetMinutesRemaining,
    ).padStart(2, '0')})`
}

export const getLongFormatedDateTimeUTC = (date, context) => {
    new Date(`${date}Z`)
    const formattedDate = new Intl.DateTimeFormat(context, {
        timeZone: getUserTimezone(),
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }).format(date)

    return `${formattedDate} ${getFormatedOffsetUTC()}`
}

export const getLongFormatedDateTime = (date, context) => {
    return new Intl.DateTimeFormat(context, {
        timeZone: getUserTimezone(),
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }).format(date)
}
