export default theme => ({
    modal: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    button: Object.assign(
        {
            marginLeft: '5px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },
        theme.button.primary,
    ),
    buttonCancel: {
        marginLeft: '5px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    buttons: Object.assign(
        {
            width: '100%',
            paddingTop: '20px',
        },
        theme.flex.rowSpacearound,
    ),
    radio: Object.assign(
        {
            width: '100%',
            alignItems: 'center',
        },
        theme.flex.rowStart,
    ),
})
