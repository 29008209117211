export default theme => ({
    fields: {
        display: 'flex',
        width: '100%',
        marginTop: '5px',
    },
    textField: {
        width: '100%',
    },
    twoTextField: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        gridGap: '15px',
        width: `100%`,
    },
    twoTextFieldWithButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: 'calc(100% - 95px)',
    },
    btnView: {
        margin: '10px 0px',
        height: '38px',
        boxShadow: 'none',
        width: 'calc(50% - 20px)',
    },
    btnChange: {
        minWidth: '94px',
        height: '52px',
        marginTop: '16px',
    },
    textfieldAndButton: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-end',
        width: 'calc(100% + 20px)',
    },
    error: {
        color: '#f44336',
        marginRight: '14px',
        marginLeft: '14px',
        fontSize: '0.75rem',
    },
    button: { width: `calc(100% - 20px)` },
    input: {
        borderBottomRightRadius: 0,
        borderTopRightRadius: 0,
    },
})
