export default {
    save: {
        FR: 'Enregistrer',
        EN: 'Save',
    },
    delete: {
        FR: 'Effacer',
        EN: 'Delete',
    },
    cancel: {
        FR: 'Annuler',
        EN: 'Cancel',
    },
    filter: {
        FR: 'Filtrer',
        EN: 'Filter',
    },
    validate: {
        FR: 'Valider',
        EN: 'Validate',
    },
}
