export default theme => ({
    root: {
        marginTop: '20px',
        marginBottom: '40px',
    },
    linkBack: {
        display: 'block',
        textDecoration: 'none',
        color: theme.palette.primary.main,
        margin: '6px 0',
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'underline',
        },
        marginBottom: '20px',
    },
    title: theme.typography.title,
    titleOffer: Object.assign(
        {},
        theme.typography.title,
        theme.flex.rowAlignCenter,
    ),
    messageAndPaul: theme.flex.rowAlignCenter,
    message: {
        position: 'relative',
        height: '100%',
    },
    bubble: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        padding: '5px 10px',
        borderRadius: '5px',
        position: 'relative',
        width: '100%',
        zIndex: '2',
    },
    virgule: {
        '&::before': {
            zIndex: '1',
            position: 'absolute',
            bottom: '0%',
            left: '-12px',
            borderWidth: '20px 40px 0 0',
            borderRadius: '40px',
            borderStyle: 'solid',
            transform: 'skewX(40deg)',
            color: theme.palette.primary.main,
            content: 'close-quote',
        },
        '&::after': {
            zIndex: '1',
            position: 'absolute',
            transform: 'rotate(45deg)',
            bottom: '13px',
            left: '-19px',
            borderWidth: '17px 22px 0 0',
            borderRadius: '45px / 30px',
            borderStyle: 'solid',
            color: theme.palette.primary.contrastText,
            content: 'close-quote',
        },
    },
    offer: Object.assign(
        {
            marginTop: '25px',
        },
        theme.boxBoatOn,
        theme.flex.row,
    ),
    button: {
        fontSize: '14px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    priceAndButton: Object.assign(
        {
            width: '40%',
            justifyContent: 'center',
            [theme.breakpoints.down('sm')]: {
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
        },
        theme.flex.columnAlignEnd,
    ),
    infos: Object.assign(
        {
            width: '60%',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        theme.flex.column,
    ),
    titleLogo: {
        margin: '10px',
        marginRight: '25px',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '100px',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '20%',
            marginRight: '15px',
        },
    },
    price: Object.assign(
        {
            color: theme.palette.primary.main,
        },
        theme.flex.rowAlignEnd,
    ),
    text: theme.flex.rowAlignCenter,
    check: {
        marginRight: '10px',
        color: theme.palette.accepted.main,
    },
    littleBlue: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0',
        },
    },
    number: Object.assign(
        {
            fontWeight: '500',
        },
        theme.typography.title,
    ),
    paper: {
        display: 'inline-block',
        position: 'absolute',
        top: '100px',
        left: '50%',
        transform: 'translate(-50%)',
        width: '65%',
        maxHeight: '90%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
        [theme.breakpoints.down('sm')]: {
            top: '50px',
            padding: '10px',
            width: 'calc(100% - 20px)',
        },
    },
    statusWaiting: {
        margin: 'auto',
        marginLeft: '0px',
        fontStyle: 'italic',
        color: theme.palette.waiting.main,
    },
    statusSend: {
        margin: 'auto',
        marginLeft: '0px',
        fontStyle: 'italic',
        color: theme.palette.secondary.main,
    },
    statusTreated: {
        margin: 'auto',
        marginLeft: '0px',
        fontStyle: 'italic',
        color: theme.palette.accepted.main,
    },
    line: Object.assign({ marginLeft: '10px' }, theme.flex.row),
})
