export const en = {
    'routes.root': '/',
    'routes.home': '/home',
    'routes.inqueries': '/my-requests',
    'routes.inqueries.insurance': '/my-requests/insurance',
    'routes.inqueries.insurance.details': '/my-requests/insurance/details',
    'routes.inqueries.repairs': '/my-requests/repairs',
    'routes.inqueries.parking': '/my-requests/parking',
    'routes.inqueries.parking.chatbox': '/my-requests/parking/chat',
    'routes.inquery': '/my-request',
    'routes.login': '/login',
    'routes.loginInGroup': '/login/:id',
    'routes.register': '/register',
    'routes.profile': '/my-profile',
    'routes.404': '/erreur-404',
    'routes.rent-my-parking': '/rent-my-space',
    'routes.messages': '/messages',
    'routes.forget': '/forget',
    'routes.parkingid': '/parking/:id',
    'routes.booking': '/booking',
    'routes.personnal-infos': '/personnalInfos',
    'routes.payment-infos': '/paymentInfos',
    'routes.parkings-infos': '/parkingsInfos',
    'routes.boat-infos': '/boat-infos',
    'routes.le-blog': '/le-blog',
    'routes.search-boat-parking': '/search-boat-parking',
    'routes.get-boat-insurance': '/get-boat-insurance',
    'routes.profile.boat-infos': '/boat-infos',
    'routes.sepa': '/sepa-payment',
    'routes.prices': '/prices',
    'routes.prices.part': '/prices/part',
    'routes.prices.yacht': '/prices/yacht',

    'routes.logbook.home': '/boaton-book',
    'routes.logbook.alerts': '/boaton-book/my-alerts',
    'routes.logbook.inventory': '/boaton-book/my-inventory',
    'routes.logbook.fleet': '/boaton-book/my-fleet',
    'routes.logbook.repairs': '/boaton-book/my-repairs',
    'routes.logbook.informations': '/boaton-book/my-boat',
    'routes.logbook.exps-a-incm': '/boaton-book/my-expenses-and-income',
    'routes.logbook.documents': '/boaton-book/my-documents',
    'routes.logbook.book': '/boaton-book/my-boaton-book',
    'routes.logbook.calendar': '/boaton-book/my-boaton-calendar',
    'routes.logbook.worktime': '/boaton-book/my-worktime',
    'routes.logbook.pricing': '/boaton-book/pricing',
    'routes.logbook.order-form': '/boaton-book/order-form',
    'routes.logbook.orders': '/boaton-book/orders',
    'routes.logbook.my-users': '/boaton-book/my-users',
    'routes.logbook.settings.worktimes': '/boaton-book/settings/worktimes',
    'routes.mainsite': '/main-site',
    'routes.logbook.settings.paid-vacations':
        '/boaton-book/settings/paidvacations',
    'routes.logbook.logbook': '/boaton-book/logbook',
    'routes.logbook.logbookid': '/boaton-book/logbook/:id',

    'routes.redirect': '/redirect',
}
