export const commentsConstants = {
    SEND_COMMENT: 'SEND_COMMENT',
    COMMENT_SUCCESS: 'COMMENT_SUCCESS',
    COMMENT_FAILURE: 'COMMENT_FAILURE',
    REQUEST_COMMENTS: 'REQUEST_COMMENTS',
    UPDATE_COMMENT: 'UPDATE_COMMENT',
    DELETE_COMMENT: 'DELETE_COMMENT',
    DELETE_COMMENT_SUCCESS: 'DELETE_COMMENT_SUCCESS',
    DELETE_COMMENT_FAILURE: 'DELETE_COMMENT_FAILURE',
}
