export default theme => ({
    card: {
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        flexDirection: `column`,
        position: `relative`,
        padding: 25,
        width: 410,
        background: `white`,
        borderRadius: 8,
        boxSizing: `border-box`,
        minHeight: 800,
        boxShadow: `0px 8px 24px 0px #ACAEBB29`,
        [theme.breakpoints.down('sm')]: {
            borderRadius: 0,
            padding: `0 17px`,
        },
    },
    moisGratuit: {
        background: `#3040A0`,
        color: `white`,
        position: `absolute`,
        fontWeight: 700,
        fontSize: 14,
        top: 8,
        right: 17,
        padding: `0 3px`,
    },
    header: {
        display: `flex`,
        width: `100%`,
        justifyContent: `space-between`,
        alignItems: 'baseline',
        marginBottom: 5,
        height: 25,
    },
    title: {
        fontWeight: 700,
        fontSize: 30,
    },
    price: {
        textAlign: `right`,
        fontWeight: 700,
        fontSize: 20,
        color: `rgba(0,0,0,0.6)`,
    },
    pricePrecision: {
        fontSize: 12,
        marginLeft: 5,
        whiteSpace: 'nowrap',
    },
    desc: {
        textAlign: `left`,
        width: `100%`,
        color: `rgba(0,0,0,0.5)`,
        height: 66,
        lineHeight: '16px',
    },
    descWithoutPrix: {
        marginBottom: 80,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20,
        },
    },
    droitSansPrix: {
        marginBottom: 70,
    },
    switchContainer: {
        height: 66,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tabs: {
        display: `flex`,
        border: `2px solid #3040A0`,
        borderRadius: `100px`,
        padding: 2,
        color: `black`,
    },
    tab: {
        '&:hover': {
            cursor: `pointer`,
        },
        flexDirection: `column`,
        transition: `0.2s`,
        background: `#ffffff`,
        textAlign: `center`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        borderRadius: `100px`,
        height: 48,
        width: 130,
    },
    titleOccurence: {
        fontWeight: 500,
        fontSize: `16px`,
    },
    descOccurence: {
        fontWeight: 400,
        fontSize: `12px`,
    },
    active: {
        color: `white`,
        background: `#303F9F`,
    },
    button: {
        marginTop: 10,
        height: 60,
        borderRadius: 100,
        width: `100%`,
    },
    droit: {
        display: `flex`,
        alignItems: `center`,
        width: `100%`,
        padding: '10px 0',
    },
    iconDroitCheck: {
        color: `#22BA5F`,
        width: 20,
        height: 20,
        marginRight: 16,
        marginLeft: 11,
    },
    iconDroitNo: {
        width: 20,
        height: 20,
        color: `#C1C1C1`,
        marginRight: 16,
        marginLeft: 11,
    },
    descDroit: {
        fontSize: `16px`,
        color: `rgba(0,0,0,0.6)`,
    },
})
