import React from 'react'
import dictionary from './Dictionary/OfferCardDico.js'
import styles from './Styles/OfferCardCss'
import { Typography, withStyles } from '@material-ui/core'
import BoatOnComponent from '../common/BoatOnComponent.js'
import { Button } from '../common/BoatOnButton.jsx'
import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'
import { connect } from 'react-redux'

class OfferCard extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary

        this._changeTab = this._changeTab.bind(this)
        this._chooseSub = this._chooseSub.bind(this)
        this.formatPrice = this.formatPrice.bind(this)
    }

    _changeTab() {
        const { onTabChange } = this.props
        onTabChange()
    }

    _chooseSub() {
        const { byYear } = this.props
        this.props.subscribeTo(this.props.id + (byYear ? 1 : 0))
    }

    /**
     * Formatte un prix d'un nombre à un string
     * ex: 4.9 => 4,90€
     * @param {*} price number
     */
    formatPrice(price) {
        return `${price.toFixed(2).replace('.', ',')}€`
    }

    render() {
        const {
            classes,
            buttonType,
            color,
            name,
            desc,
            price,
            priceYear,
            droits,
            user,
            free,
            byYear,
        } = this.props

        return (
            <div
                style={{ borderTop: `6px solid ${color}` }}
                className={classes.card}
            >
                <div className={classes.header}>
                    <Typography className={classes.title}> {name} </Typography>
                    <Typography className={classes.price}>
                        {price
                            ? byYear
                                ? this.formatPrice(priceYear)
                                : this.formatPrice(price)
                            : this.displayText(`free`)}
                        {price && (
                            <span className={classes.pricePrecision}>
                                {this.displayText(`infoHT`)}
                            </span>
                        )}
                    </Typography>
                </div>
                {price ? (
                    <>
                        <Typography
                            className={`${classes.desc} ${!price &&
                                classes.descWithoutPrix}`}
                        >
                            {` ${desc} `}
                        </Typography>
                        <div className={classes.switchContainer}>
                            <div className={classes.tabs}>
                                <div
                                    onClick={this._changeTab}
                                    className={`${classes.tab} ${!byYear &&
                                        classes.active}`}
                                >
                                    <Typography
                                        className={classes.titleOccurence}
                                    >
                                        {` ${this.displayText(`mensuel`)} `}
                                    </Typography>
                                    <Typography
                                        className={classes.descOccurence}
                                    >
                                        {` ${this.displayText(
                                            `sansEngagement`,
                                        )} `}
                                    </Typography>
                                </div>
                                <div
                                    onClick={this._changeTab}
                                    className={`${classes.tab} ${byYear &&
                                        classes.active}`}
                                >
                                    <Typography
                                        className={classes.titleOccurence}
                                    >
                                        {` ${this.displayText(`annuel`)} `}
                                    </Typography>
                                    <Typography
                                        style={{
                                            color: byYear ? `white` : `#5FA7F6`,
                                        }}
                                        className={classes.descOccurence}
                                    >
                                        {` ${this.displayText(`eco`)} `}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <Typography className={classes.desc}>
                            {` ${desc} `}
                        </Typography>
                        <Typography
                            className={classes.switchContainer}
                            style={{ color: '#3040A0' }}
                        >
                            {this.displayText(`startForFree`)}
                        </Typography>
                    </>
                )}

                {droits.map((droit, index) => (
                    <div
                        className={`${classes.droit} ${!price &&
                            index >= droits.length - 1 &&
                            user &&
                            classes.droitSansPrix}`}
                        key={index}
                        style={{
                            borderBottom:
                                index < droits.length - 1
                                    ? `1px solid #F1F1F1`
                                    : `none`,
                        }}
                    >
                        {droit ? (
                            <CheckIcon className={classes.iconDroitCheck} />
                        ) : (
                            <ClearIcon className={classes.iconDroitNo} />
                        )}
                        <Typography className={classes.descDroit}>
                            {` ${this.displayText(`droit${index}`)} `}
                        </Typography>
                    </div>
                ))}

                {(price || !user) && (
                    <Button
                        onClick={this._chooseSub}
                        type={buttonType}
                        label={
                            free === true
                                ? this.displayText(`try2`)
                                : this.displayText(`try1`)
                        }
                        classNameOverride={classes.button}
                        fullWidth
                    />
                )}
                {price && (
                    <Typography className={classes.moisGratuit}>
                        {` ${this.displayText(`monthFree`)} `}
                    </Typography>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(OfferCard))
