export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
    },
    search: {
        position: 'relative',
        width: '100%',
        height: '100%',
    },
    bar: {
        margin: 0,
        width: '100%',
        float: 'left',
        fontSize: '18px',
        borderRadius: '3px',
        '&:focus': {
            outline: 'none',
        },
        boxSizing: 'border-box',
    },
    autoCompletion: {
        position: 'absolute',
        top: '100%',
        backgroundColor: '#fff',
        border: '1px solid #c4c4c4',
        width: '100%',
        padding: 0,
        margin: 0,
        boxSizing: 'border-box',
        zIndex: 100001,
        borderTop: `none`,
    },
    field: {
        fontSize: '18px',
        padding: '10px',
        paddingTop: '5px',
        paddingBottom: '5px',
        color: '#000',
        '&:hover': {
            backgroundColor: '#3040a0',
            color: '#fff',
        },
    },
    clearButton: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
})
