export const workTimeConstants = {
    POST: 'POST',
    POST_SUCCESS: 'POST_SUCCESS',
    POST_FAILURE: 'POST_FAILURE',
    GET_WORK_TIMES: 'GET_WORK_TIMES',
    GET_WORK_TIMES_SUCCESS: 'GET_WORK_TIMES_SUCCESS',
    GET_WORK_TIMES_FAILURE: 'GET_WORK_TIMES_FAILURE',
    GET_WORK_TIMES_MANAGER: 'GET_WORK_TIMES_MANAGER',
    GET_WORK_TIMES_MANAGER_SUCCESS: 'GET_WORK_TIMES_MANAGER_SUCCESS',
    GET_WORK_TIMES_MANAGER_FAILURE: 'GET_WORK_TIMES_MANAGER_FAILURE',
    UPDATE_WORK_TIME: 'UPDATE_WORK_TIME',
    UPDATE_WORK_TIME_SUCCESS: 'UPDATE_WORK_TIME_SUCCESS',
    UPDATE_WORK_TIME_FAILURE: 'UPDATE_WORK_TIME_FAILURE',
    DELETE_WORK_TIME: 'DELETE_WORK_TIME',
    DELETE_WORK_TIME_SUCCESS: 'DELETE_WORK_TIME_SUCCESS',
    DELETE_WORK_TIME_FAILURE: 'DELETE_WORK_TIME_FAILURE',
}
