export default {
    boatName: {
        FR: 'Nom du bateau',
        EN: 'Boat name',
    },
    boatType: {
        FR: 'Type du bateau',
        EN: 'Boat type',
    },
    boatYear: {
        FR: 'Année du bateau',
        EN: 'Boat year',
    },
    boatMaker: {
        FR: 'Marque du bateau',
        EN: 'Boat Maker',
    },
    boatModel: {
        FR: 'Modèle du bateau',
        EN: 'Boat model',
    },
    boatRegistration: {
        FR: 'Immatriculation',
        EN: 'Boat registration',
    },
    homePortOrStorage: {
        FR: 'Port ou lieu de stockage',
        EN: 'Homeport or storage area',
    },
    createBoat: {
        FR: 'Créer mon bateau',
        EN: 'Create my boat',
    },
    select: {
        FR: 'Selectionnez',
        EN: 'Select',
    },
    selectType: {
        FR: 'Veuillez selectionner un type de bateau',
        EN: 'Please select a type of boat',
    },
    quit: {
        FR: 'Ajouter plus tard',
        EN: 'Add later',
    },
    chooseType: {
        FR: 'Choisissez un type de bateau',
        EN: 'Choose a boat type',
    },
    errorType: {
        FR: 'Veuillez renseigner le type de votre bateau',
        EN: 'Please fill in the type of your boat',
    },
    infoAutoGenerateInventory: {
        FR: `Générer l'inventaire de mon bateau à la création :`,
        EN: `Generate my boat's inventory when creating :`,
    },
    generationInfo: {
        FR: `Félicitations pour l’ajout de ce bateau dans votre BoatOn Book ! Pour vous simplifier la vie, nous avons créé quelques équipements et tâches de maintenance correspondant à ce modèle. Vous pouvez les modifier ou supprimer à tout moment. Enjoy 😉`,
        EN: `Congratulations on adding this boat to your BoatOn Book! To make your life easier, we've created a few equipment and maintenance tasks for this model. You can modify or delete them at any time. Enjoy 😉`,
    },
    information: {
        FR: `Information`,
        EN: `Information`,
    },
    understood: {
        FR: `J'ai compris`,
        EN: `I understood`,
    },
    loading: {
        FR: 'Chargement',
        EN: 'Loading',
    },
    loadingInfo: {
        FR:
            'Nous sommes en train de créer votre bateau, ça ne prend que quelques instants 😊',
        EN:
            'We are currently creating your boat, it will only take a few moments 😊',
    },
}
