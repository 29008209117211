export default theme => ({
    root: {
        width: '100%',
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#3040a0',
        color: 'white',
        height: '50px',
        alignItems: 'center',
        padding: '25px',
    },
    titleFont: {
        fontSize: '35px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '30px',
        },
    },
    titleLogo: {
        marginRight: '25px',
    },
    price: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingRight: '35px',
        paddingLeft: '35px',
        backgroundColor: '#fec107',
        padding: '5px',
        [theme.breakpoints.down('xs')]: {
            paddingRight: '15px',
            paddingLeft: '15px',
        },
    },
    fontPrice: {
        fontWeight: '500',
        textTransform: 'uppercase',
        fontSize: '20px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '18px',
        },
    },
    advice: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '20px',
    },
    fontSubtitle: {
        fontWeight: '500',
        textTransform: 'uppercase',
        fontSize: '16px',
        backgroundColor: '#e0e0e0',
        padding: '5px',
        paddingRight: '35px',
        paddingLeft: '35px',
        [theme.breakpoints.down('xs')]: {
            paddingRight: '15px',
            paddingLeft: '15px',
        },
    },
    fontText: {
        paddingTop: '5px',
        paddingRight: '35px',
        paddingLeft: '35px',
        paddingBottom: '5px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
            paddingRight: '15px',
            paddingLeft: '15px',
        },
    },
    linkText: {
        paddingRight: '35px',
        paddingLeft: '35px',
        textDecoration: 'none',
        color: 'black',
        fontStyle: 'italic',
        [theme.breakpoints.down('xs')]: {
            fontSize: '14px',
            paddingRight: '15px',
            paddingLeft: '15px',
        },
    },
    accepted: {
        backgroundColor: theme.palette.accepted.main,
    },
    declined: {
        backgroundColor: theme.palette.declined.main,
    },
    callback: {
        backgroundColor: theme.palette.waiting.main,
    },
    offer: {
        marginBottom: '20px',
        overflow: 'hidden !important',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    buttons: theme.flex.rowSpacearound,
    acceptedFont: {
        color: theme.palette.primary.main,
        fontSize: '20px',
        paddingRight: '35px',
        paddingLeft: '35px',
        width: '100%',
        paddingTop: '10px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px',
            paddingRight: '15px',
            paddingLeft: '15px',
            paddingBottom: '10px',
        },
    },
    declinedFont: {
        color: theme.palette.declined.main,
        fontSize: '20px',
        paddingRight: '35px',
        paddingLeft: '35px',
        width: '100%',
        paddingTop: '10px',
        [theme.breakpoints.down('xs')]: {
            fontSize: '16px',
            paddingRight: '15px',
            paddingLeft: '15px',
            paddingBottom: '10px',
        },
    },
})
