export default theme => ({
    root: {
        with: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        marginTop: '10px',
        marginBottom: '10px',
    },
    cardNumber: {
        height: '24px',
        width: '50%',
        minWidth: '300px',
        border: '1px solid #c4c4c4',
        margin: 0,
        padding: '14px',
        paddingTop: '8px',
        paddingBottom: '8px',
        boxSizing: 'content-box',
        '&:hover': {
            border: '1px solid #000',
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: '220px',
            width: '100%',
            marginBottom: '10px',
        },
        StripeElement: {
            height: '40px',
        },
    },
    firstLine: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    cardExpiry: {
        height: '24px',
        width: '15%',
        minWidth: '80px',
        border: '1px solid #c4c4c4',
        margin: 0,
        padding: '14px',
        paddingTop: '8px',
        paddingBottom: '8px',
        '&:hover': {
            border: '1px solid #000',
        },
    },
    cardCvc: {
        height: '24px',
        width: '10%',
        minWidth: '40px',
        border: '1px solid #c4c4c4',
        margin: 0,
        padding: '14px',
        paddingTop: '8px',
        paddingBottom: '8px',
        '&:hover': {
            border: '1px solid #000',
        },
    },
    title: {
        fontSize: '20px',
    },
})
