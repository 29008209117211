import { appStrings } from '../../languages'

class Translater {
    displayText(dictionaryKey, context) {
        if (!dictionaryKey) return null
        return dictionaryKey[this.getLanguage(context)]
    }

    getLanguageForApi(context) {
        return context === 'fr' ? 'fr' : 'en'
    }

    getLanguage(context) {
        return context === 'fr' ? 'FR' : 'EN'
    }

    getFrenchTranslate(dictionary, text) {
        for (const key in dictionary) {
            if (dictionary[key].EN === text) {
                return dictionary[key].FR
            }
        }
    }

    getEnglishTranslate(dictionary, text) {
        for (const key in dictionary) {
            if (dictionary[key].FR === text) {
                return dictionary[key].EN
            }
        }
    }

    getRoute(context, routeKey) {
        return '/' + context + appStrings[context][routeKey]
    }

    getMessages() {
        const messages = { ...appStrings }
        return messages
    }
}

export default Translater
