export default theme => ({
    root: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
    },

    buttonIcon: {
        marginRight: 10,
    },

    linkContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
    },

    alert: {
        borderColor: '#9CB8E3 !important',
        backgroundColor: 'rgba(156, 184, 227, 0.1) !important',
        display: 'flex !important',
        flexDirection: 'row',
        alignItems: 'center',
        color: '#303F9F !important',
        '& .MuiAlert-icon': {
            color: '#303F9F !important',
        },
    },
})
