import React, { createRef } from 'react'
import styles from './EventCss.js'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../../../../../common/BoatOnComponent.js'
import CalendarPageDico from '../../Dictionary/CalendarPageDico.js'
import EventList from './EventList/EventList.jsx'
import { getNameFromRepair } from '../../../../../../actions/bob.actions.js'
import { getContextFromUrl } from '../../../../../../languages/LocalizerUtils.js'
import UserBadge from '../../../../../common/UserBadge.jsx'
import AccessTimeIcon from '@mui/icons-material/AccessTime'

class Event extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = CalendarPageDico

        const url = window.location.pathname
        this.context = getContextFromUrl(url)

        const { eventInfo } = this.props

        this.state = {
            isActionMenuOpen: false,
        }

        this.actionsButtonRef = createRef()
        this.openActionsMenu = this.openActionsMenu.bind(this)
        this.closeActionsMenu = this.closeActionsMenu.bind(this)
    }

    openActionsMenu() {
        this.props.openActionsMenu(this.props.eventInfo, this.actionsButtonRef)
    }

    closeActionsMenu() {
        this.setState({
            isActionMenuOpen: false,
        })
    }

    render() {
        const { classes, eventInfo, openDocModal } = this.props

        //Evenement en format Liste
        // if (eventInfo.view.type === 'listYear') {
        //     return (
        //         <EventList
        //             classes={classes}
        //             eventInfo={eventInfo}
        //             actionsButtonRef={this.actionsButtonRef}
        //             openActionsMenu={this.openActionsMenu}
        //             openDocModal={openDocModal}
        //         />
        //     )
        // }

        if (eventInfo.event._def.extendedProps.type === 'bobevent') {
            return this._renderBobEvent(eventInfo)
        } else if (eventInfo.event._def.extendedProps.type === 'absence') {
            return this._renderAbsenceEvent(eventInfo)
        } else if (eventInfo.event._def.extendedProps.type === 'worktime') {
            return this._renderWorkTimeEvent(eventInfo)
        }
        return <></>
    }

    _renderHours(stringHour) {
        const array = stringHour.split(':')

        return `${array[0]}h${+array[1] > 0 ? array[1] : ''}`
    }

    _renderBobEvent(eventInfo) {
        const { classes } = this.props
        const isMobile = window.innerWidth < 600

        const equipmentName = getNameFromRepair(
            eventInfo.event._def.extendedProps,
            this.context,
        )

        const nomEntretien = eventInfo.event._def.extendedProps?.checkup
            ?.checkupType
            ? this.displayTextApi(
                  eventInfo.event._def.extendedProps.checkup.checkupType
                      .translation,
              )
            : eventInfo.event._def.extendedProps?.checkup?.userCheckup
            ? eventInfo.event._def.extendedProps.checkup.userCheckup.name
            : eventInfo.event._def.title

        const event = {
            allDay: eventInfo.event._def.allDay,
            equipmentName: equipmentName,
            title: nomEntretien,
            description: eventInfo.event._def.extendedProps.description,
            backgroundColor: eventInfo.backgroundColor,
            type: eventInfo.event._def.extendedProps.eventType,
            equipments: eventInfo.event._def.extendedProps.checkup?.equipments,
            boat: eventInfo.event._def.extendedProps.boatDetails,
            assignee: eventInfo.event._def.extendedProps.attachedTo?.find(
                a => a.user !== null,
            )?.user,
            files: eventInfo.event._def.extendedProps.files,
            transaction: eventInfo.event._def.extendedProps.transaction,
            timeText: eventInfo.timeText,
        }

        const title = (
            <span className={`${classes.row} ${classes.fcEventTitle}`}>
                {/* Equipment */}
                {event.equipmentName !== 'Error' && (
                    <span>
                        {event.equipmentName}
                        {'\u00A0-\u00A0'}
                    </span>
                )}

                {/* Titre */}
                {event.title}
            </span>
        )
        const eventType = this.displayTextApi(event.type.translation)
        const eventPrice = event.transaction
            ? ` | ${
                  event.type.spending && event.transaction.amountIt !== 0 ? (
                      <span className={classes.amountContainer}>
                          - {event.transaction.amountIt} €
                      </span>
                  ) : (
                      <span className={classes.amountContainer}>
                          + {event.transaction.amountIt} €
                      </span>
                  )
              }`
            : ''
        const eventBoat = event.boat ? ` | ${event.boat.name}` : ''

        return (
            <div
                className={classes.fcEvent}
                style={{
                    backgroundColor: `${event.backgroundColor}30`,
                    borderLeft: `solid 4px ${event.backgroundColor}`,
                }}
                title={`${
                    event.equipmentName !== 'Error'
                        ? `${event.equipmentName} - ${event.title}`
                        : event.title
                } | ${eventType}${eventPrice}${eventBoat} | ${this._renderHours(
                    event.timeText,
                )}`}
            >
                {/* Affiche le badge d'assignation dans toutes les vues Desktop et uniquement année en mobile */}
                {event.assignee &&
                    (!isMobile ||
                        (isMobile &&
                            eventInfo.view.type === 'multiMonthYear')) && (
                        <div
                            className={classes.fcAssigneeBadge}
                            style={{ border: 'solid 2px #303F9F' }}
                        >
                            <UserBadge userLink={event.assignee} />
                        </div>
                    )}
                <div className={classes.col}>
                    {title}
                    {/* {!isMobile && (
                        <div className={classes.row}>
                            <div className={classes.fcEventInfo}>
                                // Type de l'événement
                                {eventType}

                                // Montant
                                {eventPrice}

                                // Nom du bateau
                                {eventBoat}
                            </div>
                        </div>
                    )} */}
                    {this._renderHours(event.timeText)}
                </div>

                {/* Affiche les boutons de menu sur toutes les vues desktop */}
                {displayActionMenu(isMobile, eventInfo.view.type) && (
                    <div
                        className={classes.dotContainer}
                        ref={this.actionsButtonRef}
                        onClick={this.openActionsMenu}
                    >
                        <span className={classes.dot}></span>
                        <span className={classes.dot}></span>
                        <span className={classes.dot}></span>
                    </div>
                )}
            </div>
        )
    }

    _renderAbsenceEvent(eventInfo) {
        const { classes, usersLinked } = this.props
        const isMobile = window.innerWidth < 600

        const currentUser = usersLinked.find(
            user =>
                user.id === eventInfo.event._def.extendedProps.event.userLinkId,
        )

        const event = {
            allDay: eventInfo.event._def.allDay,
            title: eventInfo.event._def.title,
            description: eventInfo.event._def.extendedProps.description,
            backgroundColor: eventInfo.backgroundColor,
            type: eventInfo.event._def.extendedProps.eventType,
            boat: eventInfo.event._def.extendedProps.boatDetails,
            assignee: currentUser
                ? currentUser
                : eventInfo.event._def.extendedProps.attachedTo,
            files: eventInfo.event._def.extendedProps.files,
            transaction: eventInfo.event._def.extendedProps.transaction,
            status: eventInfo.event._def.extendedProps.event.absenceStatus.id,
            startTime: eventInfo.event._def.extendedProps.event.startTime,
            endTime: eventInfo.event._def.extendedProps.event.endTime,
        }

        if (event.status === 3) return null

        if (event.allDay) {
            return (
                <div
                    className={classes.fcEvent}
                    style={{
                        backgroundColor: `${event.backgroundColor}`,
                        color: 'white',
                        opacity: event.status === 1 ? 0.5 : 1,
                    }}
                    title={`${event.title} | ${this.displayText('allDay')}${
                        event.boat ? ` | ${event.boat.name}` : ''
                    } | ${
                        event.status === 1
                            ? this.displayText('waiting')
                            : this.displayText('accepted')
                    }`}
                >
                    {/* Affiche le badge d'assignation dans toutes les vues Desktop et uniquement année en mobile */}
                    {event.assignee &&
                        (!isMobile ||
                            (isMobile &&
                                eventInfo.view.type === 'multiMonthYear')) && (
                            <UserBadge small userLink={event.assignee} />
                        )}
                    <div className={classes.col}>
                        <span
                            className={`${classes.row} ${classes.fcEventTitle}`}
                        >
                            {/* Titre */}
                            {event.title}
                        </span>
                        {!isMobile && (
                            <div className={classes.row}>
                                <div className={classes.fcEventInfo}>
                                    {/* Nom du bateau */}
                                    {event.boat && (
                                        <span>
                                            {' | '}
                                            {event.boat.name}
                                        </span>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    {/* Affiche les boutons de menu sur toutes les vues desktop */}
                    {displayActionMenu(isMobile, eventInfo.view.type) && (
                        <div
                            className={classes.dotContainer}
                            ref={this.actionsButtonRef}
                            onClick={this.openActionsMenu}
                        >
                            <span className={classes.dot}></span>
                            <span className={classes.dot}></span>
                            <span className={classes.dot}></span>
                        </div>
                    )}
                </div>
            )
        }

        return (
            <div
                className={classes.fcEvent}
                style={{
                    backgroundColor: `${event.backgroundColor}`,
                    color: 'white',
                    opacity: event.status === 1 ? 0.5 : 1,
                }}
                title={`${event.title} | ${this._renderHours(
                    event.startTime,
                )} - ${this._renderHours(event.endTime)}${
                    event.boat ? ` | ${event.boat.name}` : ''
                } | ${
                    event.status === 1
                        ? this.displayText('waiting')
                        : this.displayText('accepted')
                }`}
            >
                {/* Affiche le badge d'assignation dans toutes les vues Desktop et uniquement année en mobile */}
                {event.assignee &&
                    (!isMobile ||
                        (isMobile &&
                            eventInfo.view.type === 'multiMonthYear')) && (
                        <UserBadge userLink={event.assignee} />
                    )}
                <div className={classes.col}>
                    <span className={`${classes.row} ${classes.fcEventTitle}`}>
                        {/* Titre */}
                        {event.title}
                    </span>
                    <span className={classes.absenceTimeContainer}>
                        {eventInfo.timeText}
                    </span>
                    {!isMobile && (
                        <div className={classes.row}>
                            <div className={classes.fcEventInfo}>
                                {/* Nom du bateau */}
                                {event.boat && (
                                    <span>
                                        {' | '}
                                        {event.boat.name}
                                    </span>
                                )}
                            </div>
                        </div>
                    )}
                    <div>
                        {`${this._renderHours(
                            event.startTime,
                        )} - ${this._renderHours(event.endTime)}`}
                    </div>
                </div>

                {/* Affiche les boutons de menu sur toutes les vues desktop */}
                {displayActionMenu(isMobile, eventInfo.view.type) && (
                    <div
                        className={classes.dotContainer}
                        ref={this.actionsButtonRef}
                        onClick={this.openActionsMenu}
                    >
                        <span className={classes.dot}></span>
                        <span className={classes.dot}></span>
                        <span className={classes.dot}></span>
                    </div>
                )}
            </div>
        )
    }

    _renderWorkTimeEvent(eventInfo) {
        const { classes } = this.props
        const isMobile = window.innerWidth < 600

        const event = {
            allDay: eventInfo.event._def.allDay,
            title: eventInfo.event._def.title,
            description: eventInfo.event._def.extendedProps.description,
            backgroundColor: eventInfo.backgroundColor,
            type: eventInfo.event._def.extendedProps.eventType,
            boat: eventInfo.event._def.extendedProps.boatDetails,
            assignee: eventInfo.event._def.extendedProps.attachedTo,
            files: eventInfo.event._def.extendedProps.files,
            transaction: eventInfo.event._def.extendedProps.transaction,
            startTime: eventInfo.event._def.extendedProps.event.startTime,
            endTime: eventInfo.event._def.extendedProps.event.endTime,
        }

        return (
            <div
                className={classes.fcEvent}
                style={{
                    backgroundColor: `${event.backgroundColor}`,
                    color: 'white',
                }}
                title={`${event.title} | ${this._renderHours(
                    event.startTime,
                )} - ${this._renderHours(event.endTime)}`}
            >
                {/* Affiche le badge d'assignation dans toutes les vues Desktop et uniquement année en mobile */}
                {event.assignee &&
                    (!isMobile ||
                        (isMobile &&
                            eventInfo.view.type === 'multiMonthYear')) && (
                        <UserBadge userLink={event.assignee} />
                    )}
                <div className={classes.col}>
                    <span
                        className={[classes.row, classes.fcEventTitle].join(
                            ' ',
                        )}
                    >
                        {/* Titre */}
                        {event.title}
                    </span>
                    <div>
                        {`${this._renderHours(
                            event.startTime,
                        )} - ${this._renderHours(event.endTime)}`}
                    </div>
                </div>

                {/* Affiche les boutons de menu sur toutes les vues desktop */}
                {displayActionMenu(isMobile, eventInfo.view.type) && (
                    <div
                        className={classes.dotContainer}
                        ref={this.actionsButtonRef}
                        onClick={this.openActionsMenu}
                    >
                        <span className={classes.dot}></span>
                        <span className={classes.dot}></span>
                        <span className={classes.dot}></span>
                    </div>
                )}
            </div>
        )
    }
}

function displayActionMenu(isMobile, viewType) {
    if (!isMobile) {
        if (viewType === 'dayGridMonth') {
            return true
        }
    } else {
        if (
            viewType === 'timeGridDay' ||
            (isMobile && viewType === 'multiMonthYear')
        ) {
            return true
        }
    }

    return false
}

export default withStyles(styles)(Event)
