import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { TextField } from '@material-ui/core'
import { updateBoatWithoutLoading } from '../../../../../actions'

import dictionary from './Dictionary/LogBookAISModalDico'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/LogBookAISModalCss'

import { fixIosHeaderBug } from '../../../../../utils/usefullFunctions'

class LogBookAISModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            AIS: 'false',
            showInfo: false,
            mmsi: '',
            error: false,
        }

        this.handleBackBtn = this.handleBackBtn.bind(this)
        this.next = this.next.bind(this)
        this.save = this.save.bind(this)
        this.handleChangeAIS = this.handleChangeAIS.bind(this)
        this.handleChangeMMSI = this.handleChangeMMSI.bind(this)
        this.isValid = this.isValid.bind(this)
    }

    componentDidMount() {
        const { currentBoat } = this.props

        if (currentBoat.mmsi) {
            this.setState({
                AIS: 'true',
            })
        }
    }

    isValid() {
        const { currentBoat } = this.props
        const { AIS, mmsi } = this.state

        let isValid = true

        if (!currentBoat.mmsi && AIS === 'true' && !mmsi) {
            this.setState({
                error: true,
            })
            isValid = false
        }

        return isValid
    }

    handleBackBtn() {
        const { showInfo } = this.state

        if (showInfo) {
            this.setState({ showInfo: false })
            return
        }

        this.props.onClose()
    }

    save() {
        this.props.handleValid(this.state.AIS === 'true')
    }

    async next() {
        const { showInfo, mmsi } = this.state
        const { currentBoat, user, groupId } = this.props
        const AIS = this.state.AIS === 'true'

        if (!this.isValid()) return

        if (mmsi && !currentBoat.mmsi) {
            currentBoat.mmsi = mmsi
            await this.props.dispatch(
                updateBoatWithoutLoading(currentBoat, user, groupId),
            )
        }

        if (AIS && !showInfo) {
            this.setState({ showInfo: true })
            return
        }

        this.save()
    }

    handleChangeAIS(e) {
        this.setState({ AIS: e.target.value })
    }

    handleChangeMMSI(value) {
        this.setState({
            mmsi: value.substring(0, 10),
            error: false,
        })
    }

    _body() {
        const { AIS, showInfo, mmsi, error } = this.state
        const { classes, currentBoat } = this.props

        if (showInfo)
            return (
                <div className={classes.text}>
                    {this.displayText('AISInfos')}
                </div>
            )

        return (
            <div className={classes.text}>
                {this.displayText('AISEquipedQuestion')}
                <RadioGroup
                    className={classes.radioGroup}
                    value={AIS}
                    onChange={this.handleChangeAIS}
                >
                    <FormControlLabel
                        value="true"
                        control={<Radio className={classes.radioBtn} />}
                        label={this.displayText('yes')}
                    />
                    <FormControlLabel
                        value="false"
                        control={<Radio className={classes.radioBtn} />}
                        label={this.displayText('no')}
                    />
                </RadioGroup>

                {!currentBoat.mmsi && AIS === 'true' && (
                    <div className={classes.mmsiContainer}>
                        {this.displayText('mmsiLabel')}
                        <TextField
                            fullWidth
                            label={this.displayText('mmsiNumber')}
                            margin="dense"
                            variant="outlined"
                            placeholder="00MIDXXXX"
                            value={mmsi}
                            onChange={e =>
                                this.handleChangeMMSI(e.target.value)
                            }
                            error={error}
                            inputProps={{
                                pattern: '[0-9]*',
                                inputMode: 'numeric',
                            }}
                            onBlur={() => fixIosHeaderBug()}
                            onKeyDown={e => {
                                if (
                                    /[^0-9]/.test(e.key) &&
                                    e.key !== 'Backspace'
                                )
                                    e.preventDefault()
                            }}
                        />
                    </div>
                )}
            </div>
        )
    }

    render() {
        const { showInfo } = this.state

        const { title, noCross = true, showFollowButton } = this.props

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    null,
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    body: this._body(),
                })}
                {this._renderActions(
                    showInfo
                        ? [
                              {
                                  action: this.handleBackBtn,
                                  label: this.displayText('back'),
                                  type: 'text',
                              },
                              {
                                  action: this.next,
                                  label: this.displayText('end'),
                              },
                          ]
                        : [
                              {
                                  action: this.next,
                                  label: this.displayText('next'),
                              },
                          ],
                )}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentBoat: state.bob?.boat,
        user: state.authentication.user,
        groupId: state.group.currentGroupId,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LogBookAISModal))
