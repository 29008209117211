import React from 'react'
import BoatOnComponent from '../../../../common/BoatOnComponent'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/CycleSelectorCss'
import dictionary from './Dictionary/CycleSelectorDico'
import {
    getCycleHours,
    getCycle,
    getCycleMonth,
    getCycleWeek,
} from '../../../../../actions/bob.actions'
import { TextField } from '@material-ui/core'
import { Button } from '../../../../common'

class CycleSelector extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        const setCycle = getCycle({ event: props.event })
        this.state = {
            cycle:
                setCycle?.cycleType?.id === 1
                    ? 'MONTH'
                    : setCycle?.cycleType?.id === 2
                    ? 'HOURS'
                    : 'WEEK',
            lastDate: this.props.event?.delimitedDate?.startDate || new Date(),
            lastHours: this.props.event?.actualDuration || 0,
        }

        this.setCycleMonth = this.setCycleMonth.bind(this)
        this.setCycleWeek = this.setCycleWeek.bind(this)
        this.setCycleHours = this.setCycleHours.bind(this)
    }

    componentDidUpdate(prevProps) {
        const setCycle = getCycle({ event: this.props.event })

        if (
            JSON.stringify(prevProps.event) !== JSON.stringify(this.props.event)
        ) {
            this.setState({
                cycle:
                    setCycle?.cycleType?.id === 1
                        ? 'MONTH'
                        : setCycle?.cycleType?.id === 2
                        ? 'HOURS'
                        : 'WEEK',
            })
        }
    }

    setCycleMonth() {
        const { event } = this.props
        const { lastDate } = this.state

        const cycle = getCycle({ event: event })
        this.setState({ cycle: 'MONTH' })
        this.props.handleChange({
            checkup: {
                ...event.checkup,
                userCheckup: {
                    ...event.checkup.userCheckup,
                    cycles: [
                        {
                            ...cycle,
                            cycleLength: 0,
                            cycleType: {
                                id: 1,
                            },
                        },
                    ],
                },
            },
        })
        this.props.handleChange({
            actualDuration: null,
        })
        this.props.handleChange({
            delimitedDate: { startDate: lastDate },
        })
    }

    setCycleHours() {
        const { event } = this.props
        const { lastHours } = this.state

        const cycle = getCycle({ event: event })
        this.setState({ cycle: 'HOURS' })
        this.props.handleChange({
            checkup: {
                ...event.checkup,
                userCheckup: {
                    ...event.checkup.userCheckup,
                    cycles: [
                        {
                            ...cycle,
                            cycleLength: 0,
                            cycleType: {
                                id: 2,
                            },
                        },
                    ],
                },
            },
        })
        this.props.handleChange({
            actualDuration: lastHours,
        })
        this.props.handleChange({
            delimitedDate: { startDate: null },
        })
    }

    setCycleWeek() {
        const { event } = this.props
        const { lastDate } = this.state

        const cycle = getCycle({ event: event })
        this.setState({ cycle: 'WEEK' })
        this.props.handleChange({
            checkup: {
                ...event.checkup,
                userCheckup: {
                    ...event.checkup.userCheckup,
                    cycles: [
                        {
                            ...cycle,
                            cycleLength: 0,
                            cycleType: {
                                id: 3,
                            },
                        },
                    ],
                },
            },
        })
        this.props.handleChange({
            actualDuration: null,
        })
        this.props.handleChange({
            delimitedDate: { startDate: lastDate },
        })
    }

    _renderCycle() {
        const { classes, event, error } = this.props
        const { cycle } = this.state

        const cycleSet =
            cycle === 'HOURS'
                ? getCycleHours(event)
                : cycle === 'MONTH'
                ? getCycleMonth(event)
                : getCycleWeek(event)

        return (
            <div className={classes.fields}>
                <div className={classes.twoTextFieldWithButton}>
                    <TextField
                        id="cycleLength"
                        variant="outlined"
                        label={
                            cycle === 'HOURS'
                                ? this.displayText('cycleLengthHours')
                                : cycle === 'MONTH'
                                ? this.displayText('cycleLengthMonth')
                                : this.displayText('cycleLengthWeek')
                        }
                        required
                        error={cycleSet === null && error}
                        helperText={
                            cycleSet === null && error
                                ? this.displayText('errorCycleLength')
                                : ''
                        }
                        margin="normal"
                        className={classes.textField}
                        value={cycleSet === null ? '' : cycleSet}
                        onChange={e => {
                            this.props.handleChangeCycleLength(
                                e.target.value !== ''
                                    ? parseInt(e.target.value)
                                    : null,
                            )
                        }}
                        disabled={
                            event?.checkup?.goFromBoaton ||
                            event === null ||
                            (!event?.checkup?.checkupType &&
                                !event?.checkup?.userCheckup) ||
                            event?.checkup?.checkupType
                        }
                        InputProps={{
                            classes: {
                                root: classes.input,
                            },
                        }}
                    />
                </div>
                <Button
                    classNameOverride={classes.buttonAdd}
                    disabled={
                        event === null ||
                        (!event?.checkup?.checkupType &&
                            !event?.checkup?.userCheckup) ||
                        event?.checkup?.checkupType
                    }
                    dropDown={[
                        {
                            label: this.displayText('cycleHours'),
                            action: this.setCycleHours,
                            disabled: cycle === 'HOURS',
                        },
                        {
                            label: this.displayText('cycleWeek'),
                            action: this.setCycleWeek,
                            disabled: cycle === 'WEEK',
                        },
                        {
                            label: this.displayText('cycleMonth'),
                            action: this.setCycleMonth,
                            disabled: cycle === 'MONTH',
                        },
                    ]}
                    variant="contained"
                    type="secondary"
                    label={
                        cycle === 'HOURS'
                            ? this.displayText('cycleHours')
                            : cycle === 'MONTH'
                            ? this.displayText('cycleMonth')
                            : this.displayText('cycleWeek')
                    }
                    size={`large`}
                    style={{
                        height: `53.5px`,
                        width: `100px`,
                        marginTop: `16px`,
                        marginBottom: `8px`,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                    }}
                />
            </div>
        )
    }

    render() {
        return this._renderCycle()
    }
}

export default withStyles(styles)(CycleSelector)
