import React from 'react'
import { useLocation } from 'react-router-dom'
import { Switch, Redirect } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { LangContext } from '../../languages/LocalizerContext'
import { Helmet } from 'react-helmet'

import { appStrings } from '../../languages'
import AppMeta from '../../languages/AppMeta'
import AppFAQ from '../../languages/AppFAQ'
import { getCurrentRootRouteKey } from '../../languages/LocalizerUtils'

export const MultiLangualSwitch = ({ children, errorRedirection }) => {
    const { pathname } = useLocation()
    const { messages, formatMessage, locale } = useIntl()

    const routeKey = getCurrentRootRouteKey(pathname, messages)
    const meta = AppMeta[locale][routeKey] || AppMeta[locale]['routes.root']
    return (
        <LangContext.Provider value={locale}>
            <Helmet>
                <title>{`${meta.title}`}</title>
                <meta property="og:title" content={`${meta.title}`} />
                <meta
                    property="og:description"
                    content={`${meta.description}`}
                />
                <meta
                    property="og:image"
                    content={`https://www.boaton.fr/${meta.image}`}
                />
                <meta name="description" content={`${meta.description}`} />
                <link
                    rel="alternate"
                    hreflang="en"
                    href={'https://www.boaton.fr' + getMatchingRoute('en')}
                />
                <link
                    rel="alternate"
                    hreflang="fr"
                    href={'https://www.boaton.fr' + getMatchingRoute('fr')}
                />
                <link
                    rel="alternate"
                    hreflang="x-default"
                    href={'https://www.boaton.fr' + getMatchingRoute('fr')}
                />
                <link
                    rel="canonical"
                    href={'https://www.boaton.fr' + getMatchingRoute('fr')}
                />
                <meta
                    property="og:url "
                    content={`${'https://www.boaton.fr' +
                        getMatchingRoute('fr')}`}
                />
                <meta
                    property="og:locale"
                    content={`${locale === 'en' ? 'en_US' : 'fr_FR'}`}
                />
            </Helmet>
            <Helmet
                script={[
                    {
                        type: 'application/ld+json',
                        innerHTML: `
                        ${JSON.stringify(getFAQ())}`,
                    },
                ]}
            ></Helmet>
            <Helmet
                script={[
                    {
                        type: 'application/ld+json',
                        innerHTML: `
                        ${JSON.stringify(getWebPage())}`,
                    },
                ]}
            ></Helmet>
            <Switch>
                {React.Children.map(children, child =>
                    React.isValidElement(child) &&
                    (child.props.route !== undefined ||
                        child.props.path !== undefined)
                        ? React.cloneElement(child, {
                              ...child.props,
                              path:
                                  child.props.route !== undefined
                                      ? localizeRoute(child.props.route)
                                      : localizePath(child.props.path),
                          })
                        : child,
                )}

                <Redirect to={localizeRoute(errorRedirection)} />
            </Switch>
        </LangContext.Provider>
    )

    function getFAQ() {
        return {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: AppFAQ[locale].map(value => {
                return {
                    '@type': 'Question',
                    name: value.question,
                    acceptedAnswer: {
                        '@type': 'Answer',
                        text: value.answer,
                    },
                }
            }),
        }
    }

    function getWebPage() {
        return {
            '@context': 'http://schema.org/',
            '@type': 'WebPage',
            description: `${meta.description}`,
            image: `${meta.description}`,
            url: `https://www.boaton.fr${pathname}`,
        }
    }

    function localizeRoute(route) {
        const isFallbackRoute = route === '*'
        let format = formatMessage({ id: route })
        return isFallbackRoute ? route : '/' + locale + format
    }

    function localizePath(path) {
        return '/' + locale + path
    }

    function getMatchingRoute(language) {
        const [, route] = pathname.split('/' + locale)
        const routeKey = Object.keys(messages).find(
            key => messages[key] === route,
        )

        const matchingRoute = appStrings[language][routeKey]

        return `/${language}` + matchingRoute
    }
}
