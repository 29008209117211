export default {
    back: {
        FR: 'Retour',
        EN: 'Back',
    },
    next: {
        FR: 'Suivant',
        EN: 'Next',
    },
    save: {
        FR: 'Enregistrer',
        EN: 'Save',
    },
    end: {
        FR: 'Terminer',
        EN: 'Save',
    },
    AISEquipedQuestion: {
        FR: `Si votre bateau est équipé de l'AIS, vous pouvez enregistrer
        automatiquement votre trajet. Souhaitez-vous utiliser l'AIS pour
        enregistrer les données ?`,
        EN: `If your boat is equiped with AIS, you can automatically record
        your travel. Do you want to use AIS to record data ?`,
    },
    AISInfos: {
        FR: `Dès que vous êtes prêts, cliquez sur "Démarrer la navigation" et
            votre trajet s'enregistrera tout seul d'après les données AIS.`,
        EN:
            'When you are ready, click on "Start navigation" and your travel will be record from your AIS data.',
    },
    yes: {
        FR: 'Oui',
        EN: 'Yes',
    },
    no: {
        FR: 'Non',
        EN: 'No',
    },
    mmsiNumber: {
        FR: 'Numéro MMSI',
        EN: 'MMSI Number',
    },
    mmsiLabel: {
        FR: `Votre bateau n'a pas de numéro MMSI renseigné! Veuillez le remplir :`,
        EN: `Your boat didn't have a filled MMSI.`,
    },
}
