import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../../common/BoatOnComponent'
import styles from './Styles/InsuranceBoxRecapCss'
import dictionary from './Dictionary/InsuranceBoxRecapDico'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { history } from '../../../helpers'
import AppRoute from '../../../constants/AppRoute'
import StatusTitle from '../../common/StatusTitle'

class InsuranceBoxRecap extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {}

        this.handleOpenDetails = this.handleOpenDetails.bind(this)
    }

    handleOpenDetails(insurance) {
        this.historyPush(history, AppRoute.InsuranceDetails)
        this.props.updateSelectedInsurance(insurance)
    }

    // render la premiere ligne avec titre et status
    renderTitle(insurance) {
        const { classes } = this.props

        return (
            <div className={classes.title}>
                <div className={classes.textTitle}>
                    <Typography className={classes.titleFont}>
                        {insurance?.insuranceInq?.boat?.name || 'Boat Name'} :
                        &nbsp;
                    </Typography>
                    <Typography className={classes.titleFont}>
                        {this.displayText('insurance')} (
                        {new Date(
                            insurance.submissionDate,
                        ).toLocaleDateString()}
                        )
                    </Typography>
                </div>
                <StatusTitle
                    status={insurance.inqueryStatus.id}
                    type="insurance"
                />
            </div>
        )
    }

    render() {
        const { classes, insurance, buttonOn } = this.props

        return (
            <div key={insurance.id} className={classes.inquery}>
                {this.renderTitle(insurance)}
                <div className={classes.allElement}>
                    <div className={classes.info}>
                        <div className={classes.firstPart}>
                            <div className={classes.line}>
                                <Typography style={{ fontWeight: 'bold' }}>
                                    {this.displayText('dateEndContract')} :
                                </Typography>
                                &nbsp;
                                <Typography>
                                    {new Date(
                                        insurance.insuranceInq.expirationDate,
                                    ).toLocaleDateString()}
                                </Typography>
                            </div>
                            <div className={classes.line}>
                                <Typography style={{ fontWeight: 'bold' }}>
                                    {this.displayText('currentInsurer')} :
                                </Typography>
                                &nbsp;
                                <Typography>
                                    {insurance.insuranceInq.actualInsurance}
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.secondPart}>
                            <div className={classes.line}>
                                <Typography style={{ fontWeight: 'bold' }}>
                                    {this.displayText('annualPremium')} :
                                </Typography>
                                &nbsp;
                                <Typography>
                                    {insurance.insuranceInq.currentPrice} €
                                </Typography>
                            </div>
                            <div className={classes.line}>
                                <Typography style={{ fontWeight: 'bold' }}>
                                    {this.displayText('amountGood')} :
                                </Typography>
                                &nbsp;
                                <Typography>
                                    {insurance.insuranceInq.insuredValue} €
                                </Typography>
                            </div>
                        </div>
                        <div className={classes.comms}>
                            <Typography>
                                <span style={{ fontWeight: 'bold' }}>
                                    {this.displayText('info')} :
                                </span>{' '}
                                {insurance.insuranceInq.navigationProgram
                                    ? insurance.insuranceInq.navigationProgram
                                    : this.displayText('noMoreInfo')}
                            </Typography>
                        </div>
                    </div>
                    {(insurance.inqueryStatus.id === '4' ||
                        insurance.inqueryStatus.id === '5' ||
                        insurance.inqueryStatus.id === '3') &&
                    buttonOn ? (
                        <div className={classes.buttonBox}>
                            <Button
                                className={classes.button}
                                variant="contained"
                                color="secondary"
                                onClick={e => {
                                    this.handleOpenDetails(insurance)
                                }}
                            >
                                {this.displayText('seeOffers')}
                            </Button>
                            <ChevronRightIcon
                                onClick={e => {
                                    this.handleOpenDetails(insurance)
                                }}
                                className={classes.chevron}
                            />
                        </div>
                    ) : (
                        <div />
                    )}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(InsuranceBoxRecap))
