export default {
    navigationReady: {
        FR: 'Navigation prête',
        EN: 'Navigation ready',
    },
    navigationInProgress: {
        FR: 'Navigation en cours',
        EN: 'Navigation in progress',
    },
    travelTab: {
        FR: 'Trajet',
        EN: 'Travel',
    },
    navDetailsTab: {
        FR: 'Détails de navigation',
        EN: 'Travel details',
    },
    activityTab: {
        FR: `Fil d'activité`,
        EN: `Activity thread`,
    },
    the: {
        FR: 'le',
        EN: 'the',
    },
    declareIncident: {
        FR: 'Déclarer un incident',
        EN: 'Declare an incident',
    },
    editNavigation: {
        FR: 'Modifier la navigation',
        EN: 'Edit navigation',
    },
    editTravel: {
        FR: 'Modifier trajet',
        EN: 'Edit travel',
    },
    share: {
        FR: 'Partager',
        EN: 'Share',
    },
    incidentTitle: {
        FR: `Création d'une future tâche`,
        EN: 'Creating a futur task',
    },
    confirmationModal: {
        FR: 'Confirmation de suppression',
        EN: 'Delete confirmation',
    },
}
