export default {
    cancel: {
        FR: 'Annuler',
        EN: 'Cancel',
    },
    next: {
        FR: 'Suivant',
        EN: 'Save',
    },
    back: {
        FR: 'Retour',
        EN: 'Back',
    },
    save: {
        FR: 'Enregistrer',
        EN: 'Save',
    },

    stockLevelsTab: {
        FR: 'Niveau réserves',
        EN: 'Filling levels',
    },
    actualLevel: {
        FR: 'Niveau actuel',
        EN: 'Actual level',
    },
    titleLevels: {
        FR: 'Vérifiez les capacités et les niveaux de vos équipements.',
        EN: 'Check filling levels of your equipments.',
    },
    capacity: {
        FR: 'Cap.',
        EN: 'Cap.',
    },
    emptyStock: {
        FR: 'Oups, aucun équipement avec un niveau à contrôler trouvé ...',
        EN: 'Oups, no equipment with level to fill found ...',
    },

    worktimeTab: {
        FR: 'Heures de marche',
        EN: 'Worktime',
    },

    detailTab: {
        FR: 'Détails',
        EN: 'Details',
    },

    worktimeTitle: {
        FR: 'Mettez à jour le nombre d’heures des équipements ci-dessous.',
        EN: 'Update number of worktime of yours équipments.',
    },
    emptyWorktime: {
        FR: `Oups, aucun équipement avec en cours d'utilisation trouvé ...`,
        EN: 'Oups, no equipment in use found ...',
    },

    addressTitle: {
        FR: 'Arrivée',
        EN: 'Arrival',
    },
    arrivalPlace: {
        FR: `Lieu d'arrivée`,
        EN: `Arrival place`,
    },
    arrivalDate: {
        FR: `Date d'arrivée`,
        EN: 'Arrival date',
    },
    arrivalHour: {
        FR: `Heure d'arrivée`,
        EN: 'Arrival hour',
    },

    commentTitle: {
        FR:
            'Écrivez ici toute remarque sur l’état de la mer, la météo, les personnes à bord ou tout événement notable qui a eu lieu durant la navigation.',
        EN:
            'Write here all your observation about sea condition, the weather, passengers or each event noteworthy during the travel.',
    },
    captainJugment: {
        FR: 'Appréciation du capitaine',
        EN: 'Captain Jugment',
    },
    photoAndDocs: {
        FR: 'Photos et documents',
        EN: 'Photos and documents',
    },

    comment: {
        FR: 'Commentaire',
        EN: 'Comment',
    },

    missingCapacity: {
        FR: `Il semble que la capacité de cet équipement n’a pas été complétée. Rendez-vous dans la page “Inventaire” pour la renseigner`,
        EN:
            'It seems that the capacity of this equipment has not been completed. Go to the "Inventory" page to fill it in.',
    },

    aisWithoutAddress: {
        FR: `Remplir une adresse`,
        EN: 'Fill an address',
    },
}
