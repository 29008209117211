export default {
    boatOnBook: {
        FR:
            'Le BoatOn Book est le carnet de santé de votre bateau ! Retrouvez-y tous les événements importants tels que: tâches passés et à venir, sorties que vous avez renseignées, dépenses et revenus.',
        EN:
            "The BoatOn Book is your boat's health book! Here you can find all the important events such as: past and future tasks, cruises, expenses and income.",
    },
    noBoat: {
        FR: `Vous n'avez pas enregistré de bateau`,
        EN: `You have not registered any boat`,
    },
    editRepair: {
        FR: `Edition d'une tâche`,
        EN: `Editing a task`,
    },
    createNextRepair: {
        FR: `Création d'une future tâche`,
        EN: `Creating a future task`,
    },
    createPrevRepair: {
        FR: `Création d'une tâche effectuée`,
        EN: `Creating a completed task`,
    },
    userAddPrevMaintenance: {
        FR: 'Ajouter une tâche effectuée',
        EN: 'Add a completed task',
    },
    userAddNextMaintenance: {
        FR: 'Ajouter une tâche future',
        EN: 'Add a future task',
    },
    userAddDocument: {
        FR: 'Ajouter un document',
        EN: 'Add a document',
    },
    userAddExpense: {
        FR: 'Ajouter une dépense',
        EN: 'Add an expense',
    },
    userAddIncome: {
        FR: 'Ajouter un revenu',
        EN: 'Add an income',
    },
    addCustomEvent: {
        FR: 'Ajouter un évènement personnalisé',
        EN: 'Add a custom event',
    },
    addWorktime: {
        FR: 'Ajouter un temps de travail',
        EN: 'Add work time',
    },
    addAbsence: {
        FR: 'Ajouter une absence',
        EN: 'Add an absence',
    },
    search: {
        FR: 'Je recherche',
        EN: 'Search',
    },
    moreFilters: {
        FR: 'Filtres',
        EN: 'Filters',
    },
    edit: {
        FR: 'Modifier',
        EN: 'Edit',
    },
    duplicate: {
        FR: 'Dupliquer',
        EN: 'Duplicate',
    },
    delete: {
        FR: 'Supprimer',
        EN: 'Delete',
    },
    more: {
        FR: 'autre',
        EN: 'more',
    },
    morePlural: {
        FR: 'autres',
        EN: 'more',
    },
    moreLinkHint: {
        FR: 'Afficher #1 événements',
        EN: 'Show #1 events',
    },
    export: {
        FR: 'Exporter les tâches de toute la flotte',
        EN: 'Export fleet-wide tasks',
    },
    exportMobile: {
        FR: 'Exporter',
        EN: 'Export',
    },
    validateDelete: {
        FR: 'Voulez-vous vraiment supprimer cet événement ?',
        EN: 'Do you really want to delete this event ?',
    },
    documentsAndPhotos: {
        FR: 'Documents et photos',
        EN: 'Documents and photos',
    },
    today: {
        FR: "Aujourd'hui",
        EN: 'Today',
    },
    add: {
        FR: 'Ajouter',
        EN: 'Add',
    },
    day: {
        FR: 'Jour',
        EN: 'Day',
    },
    week: {
        FR: 'Semaine',
        EN: 'Week',
    },
    month: {
        FR: 'Mois',
        EN: 'Month',
    },
    year: {
        FR: 'Année',
        EN: 'Year',
    },
    list: {
        FR: 'Liste',
        EN: 'List',
    },
    january: {
        FR: 'Janvier',
        EN: 'January',
    },
    february: {
        FR: 'Février',
        EN: 'february',
    },
    march: {
        FR: 'Mars',
        EN: 'Mars',
    },
    april: {
        FR: 'Avril',
        EN: 'April',
    },
    may: {
        FR: 'Mai',
        EN: 'May',
    },
    june: {
        FR: 'Juin',
        EN: 'June',
    },
    july: {
        FR: 'Juillet',
        EN: 'July',
    },
    august: {
        FR: 'Août',
        EN: 'August',
    },
    september: {
        FR: 'Septembre',
        EN: 'September',
    },
    october: {
        FR: 'Octobre',
        EN: 'October',
    },
    november: {
        FR: 'Novembre',
        EN: 'Novembre',
    },
    december: {
        FR: 'Décembre',
        EN: 'December',
    },
    monday: {
        FR: 'Lundi',
        EN: 'Monday',
    },
    tuesday: {
        FR: 'Mardi',
        EN: 'Tuesday',
    },
    wednesday: {
        FR: 'Mercredi',
        EN: 'Wednesday',
    },
    thursday: {
        FR: 'Jeudi',
        EN: 'Thursday',
    },
    friday: {
        FR: 'Vendredi',
        EN: 'Friday',
    },
    saturday: {
        FR: 'Samedi',
        EN: 'Saturday',
    },
    sunday: {
        FR: 'Dimanche',
        EN: 'Sunday',
    },
    myDocuments: {
        FR: 'Mes documents',
        EN: 'My documents',
    },
    validEventTitle: {
        FR: `Validation de l'opération`,
        EN: `Checkup confirmation`,
    },
    unvalidEventTitle: {
        FR: `Annulation de la validation`,
        EN: `Cancellation of the validation`,
    },
    titleModalCustomEvent: {
        FR: 'Ajouter un évènement personnalisé',
        EN: 'Add a custom event',
    },
    planNext: {
        FR: `Planifier le prochain contrôle`,
        EN: `Plan the next inspection`,
    },
    pageTitle: {
        FR: 'Mon calendrier',
        EN: 'My calendar',
    },
    download: {
        FR: 'Télécharger',
        EN: 'Download',
    },
    loading: {
        FR: `Chargement...`,
        EN: `Loading...`,
    },
    workTimeModalTitle: {
        FR: 'Temps de travail',
        EN: 'Work time',
    },
    leaveModalTitle: {
        FR: 'Absence',
        EN: 'Leave',
    },
    allDay: {
        FR: '🕗 Toute la journée',
        EN: '🕗 All day',
    },
    waiting: {
        FR: 'En attente',
        EN: 'Waiting',
    },
    accepted: {
        FR: 'Accepté',
        EN: 'Accepted',
    },
}
