export default {
    // same as other dictionnary there because they are both keys
    personnalInfos: {
        FR: 'Mes informations personnelles',
        EN: 'Contact details',
    },
    boatInfos: {
        FR: 'Mon bateau',
        EN: 'My boat',
    },
    parkingsInfos: {
        FR: 'Mes emplacements',
        EN: 'My places to rent',
    },
    messages: {
        FR: 'Mes messages',
        EN: 'My messages',
    },
    inqueries: {
        FR: 'Mes demandes',
        EN: 'My inqueries',
    },
    paymentInfos: {
        FR: 'Mes informations de virement',
        EN: 'My payment information',
    },
}
