export default theme => ({
    tooltip: {
        backgroundColor: '#3040a0',
        color: 'white',
        maxWidth: 300,
        border: '1px solid #dadde9',
        fontSize: '15px',
    },
    helpIcon: {
        marginTop: `-10px`,
        width: `20px`,
        height: `20px`,
        marginRight: `3px`,
        marginLeft: `3px`,
    },
    input: {
        margin: 0,
    },
})
