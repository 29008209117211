export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'calc(100vh - 88px - 96px - 64px)', //size of screen - header - footer - padding top in mobile
        },
    },
    iosRoot: {
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'calc(100vh - 88px - 96px - 64px - 82px)', //size of screen - header - footer - padding top - footer safari in mobile
        },
    },
    title: {
        fontSize: 14,
        marginLeft: 20,
    },

    worktimeWrapper: {
        maxHeight: '70vh',
        overflowY: 'auto',
        padding: '0 20px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
    },
    equipmentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 15,
    },
    equipmentIcon: {
        width: 25,
        height: 25,
    },
    equipmentDetails: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            flex: 'unset',
        },
    },
    equipmentLabel: {
        fontSize: 16,
    },
})
