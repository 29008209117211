export default {
    info: {
        FR:
            "Vous pouvez adapter ici les paramètres des congés payés pour toute votre équipe. Par défaut, les données affichées ci-dessous suivent la Convention du Travail Maritime de l'Organisation Internationale du Travail (OIT).",
        EN:
            'Here you can adjust the vacation pay parameters for your entire team. By default, the data displayed below follows the Maritime Labor Convention of the International Labor Organization (ILO).',
    },
    nbPaidVacationAllowedLabel: {
        FR: 'Nombre de congés payés autorisés dans l’année',
        EN: 'Number of authorized paid vacations per year',
    },
    days: {
        FR: 'Jours',
        EN: 'Days',
    },
    paidVacationsPeriodStartLabel: {
        FR: 'Début de la période de référence pour le calcul des congés payés',
        EN: 'Start of reference period for calculating paid leave',
    },
    authorizeCumulativeLeave: {
        FR: 'Autoriser le cumul des congés payés d’une année à l’autre',
        EN:
            'Allowing paid vacations to be accumulated from one year to the next',
    },
    datePickerPlaceholder: {
        FR: 'ex: 14/06',
        EN: 'ex: 06/14',
    },
}
