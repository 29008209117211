import { requestApi } from '../../services/api.service'
import { downloadConstants } from '../../constants/download.constants'

export const getRepairExcel = (arrayId = [], context = 'FR', boatId = null) => {
    return dispatch => {
        dispatch({ type: downloadConstants.START_DOWNLOAD })
        return requestApi(
            `/bob-events/checkups/generate-excel/${context.toUpperCase()}/${boatId}`,
            `POST`,
            arrayId,
            false,
            false,
        )
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    return result.blob()
                },
                error => {
                    console.error(error)
                    return null
                },
            )
            .then(blob => {
                if (blob) {
                    dispatch({ type: downloadConstants.DOWNLOAD_SUCCESS })
                    const href = window.URL.createObjectURL(blob)
                    const link = document.createElement(`a`)
                    link.href = href
                    link.setAttribute(`download`, `repairs.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                } else {
                    dispatch({ type: downloadConstants.DOWNLOAD_FAILURE })
                }
            })
            .catch(error => console.error(error))
    }
}

export const getRepairsExcel = (context = 'FR') => {
    return dispatch => {
        dispatch({ type: downloadConstants.START_DOWNLOAD })
        return requestApi(
            `/bob-events/checkups/generate-excel/all-boat/${context.toUpperCase()}`,
            `POST`,
            null,
            false,
            false,
        )
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    return result.blob()
                },
                error => {
                    console.error(error)
                    return null
                },
            )
            .then(blob => {
                if (blob) {
                    dispatch({ type: downloadConstants.DOWNLOAD_SUCCESS })
                    const href = window.URL.createObjectURL(blob)
                    const link = document.createElement(`a`)
                    link.href = href
                    link.setAttribute(`download`, `repairs.xlsx`)
                    document.body.appendChild(link)
                    link.click()
                } else {
                    dispatch({ type: downloadConstants.DOWNLOAD_FAILURE })
                }
            })
            .catch(error => console.error(error))
    }
}
