export default theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            width: `100% !important`,
            maxWidth: `100% !important`,
            borderRadius: `24px 24px 24px 24px`,
            minWidth: 0,
            margin: `0 !important`,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `space-between`,
            position: `absolute`,
            bottom: 0,
        },
    },
    root2: {
        borderRadius: `0px !important`,
        margin: `0 !important`,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `space-between`,
        position: `absolute`,
    },
    down: {
        bottom: 0,
        width: `100% !important`,
        maxWidth: `100% !important`,
        minWidth: 0,
    },
    up: {
        top: 0,
        width: `100% !important`,
        maxWidth: `100% !important`,
        minWidth: 0,
    },
    left: {
        left: 0,
        height: `100% !important`,
        maxHeight: `100% !important`,
    },
    right: {
        right: 0,
        height: `100% !important`,
        maxHeight: `100% !important`,
    },
    paperFullWidth: {
        borderRadius: 12,
        [theme.breakpoints.down('sm')]: {
            borderRadius: `12px 12px 0px 0px`,
        },
    },
})
