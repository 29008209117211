export default {
    navigationTime: {
        FR: 'Durée de navigation',
        EN: 'Navigation time',
    },
    travelDistance: {
        FR: 'Distance parcourue',
        EN: 'Travel distance',
    },
    speedMax: {
        FR: 'Vitesse max',
        EN: 'Speed max',
    },
    speedAverage: {
        FR: 'Vitesse moyenne',
        EN: 'Speed average',
    },
    consumption: {
        FR: 'Consommation',
        EN: 'Consumption',
    },
    speed: {
        FR: 'Allure',
        EN: 'Speed',
    },
    weatherOverview: {
        FR: 'Aperçu météo',
        EN: 'Weather overview',
    },
    weather: {
        FR: 'Météo',
        EN: 'Weather',
    },
    wind: {
        FR: 'Vent',
        EN: 'Wind',
    },
    seaLevel: {
        FR: 'Marée',
        EN: 'Sea level',
    },
    windDirection: {
        FR: 'Vent dir.',
        EN: 'Wind dir.',
    },
    windSpeed: {
        FR: 'Vent vit.',
        EN: 'Wind speed',
    },
    gust: {
        FR: 'Rafale',
        EN: 'Gust',
    },
    waveHeight: {
        FR: 'Vagues',
        EN: 'Wave',
    },
    wavePeriod: {
        FR: 'Vagues périodes',
        EN: 'Wave period',
    },
    waveDirection: {
        FR: 'Vagues dir.',
        EN: 'Wave dir.',
    },
    swellHeight: {
        FR: 'Houle',
        EN: 'Swell',
    },
    airTemperature: {
        FR: 'Temp. air',
        EN: 'Air temp.',
    },
    waterTemperature: {
        FR: 'Temp. eau',
        EN: 'Water temp.',
    },
    cloudCover: {
        FR: 'Nuages',
        EN: 'Cloud cover',
    },
    precipitation: {
        FR: 'Précip.',
        EN: 'Precip.',
    },
    location: {
        FR: 'Lieu',
        EN: 'Location',
    },
    visibility: {
        FR: 'Visibilité',
        EN: 'Visibility',
    },
    nauticMille: {
        FR: 'M',
        EN: 'Nm',
    },
}
