export default {
    invalidEmail: {
        FR: 'E-Mail non valide',
        EN: 'Invalid E-Mail',
    },
    nbCharacterPassword: {
        FR: 'Votre mot de passe doit faire au moins 6 caractères',
        EN: 'Your password must be at least 6 characters',
    },
    addBoat: {
        FR: 'Ajouter un bateau',
        EN: 'Add a boat',
    },
    signin: {
        FR: 'Valider mon inscription',
        EN: 'Signin',
    },
    missingInfo: {
        FR:
            'Les informations suivantes sont nécessaires à fin de créer votre bateau: Nom, Marque, Modèle et Adresse.',
        EN:
            'The following information is required to create your boat: Name, Make, Model and Address.',
    },
    accountCreate: {
        FR: "Création d'un compte",
        EN: 'Account creation',
    },
    boatAdd: {
        FR: "Ajout d'un bateau",
        EN: 'Add a boat',
    },
    enginesAdd: {
        FR: 'Ajout des moteurs',
        EN: 'Add engines',
    },
}
