import React from 'react'
import { connect } from 'react-redux'

import BoatOnComponent from '../BoatOnComponent'
import BoatOnAutoComplete from '../BoatOnAutoComplete'

class BoatOnCrewSelector extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.state = {
            users: this.props.value || [],
        }

        this.onChangeUser = this.onChangeUser.bind(this)
        this.getUserName = this.getUserName.bind(this)
    }

    componentDidUpdate() {
        if (this.props.value !== this.state.users) {
            this.setState({ users: this.props.value })
        }
    }

    onChangeUser(_, value) {
        this.setState(
            {
                users: value?.id || null,
            },
            () => (this.props.onChange ? this.props.onChange(value) : null),
        )
    }

    isAlreadySelected(opt, selectedOpt) {
        return selectedOpt?.some(
            elem =>
                (opt?.user?.id && opt?.user?.id === elem?.user?.id) ||
                (opt?.userSubscribe?.id &&
                    elem?.userSubscribe?.id === opt?.userSubscribe?.id),
        )
    }

    getUserName(elem) {
        const { linkedUsers } = this.props

        if (elem?.user?.id) {
            let name = ``
            linkedUsers.forEach(link => {
                if (link?.user?.id === elem?.user?.id)
                    name = `${link?.user?.firstName} ${link?.user?.lastName}`
            })
            return name
        } else if (elem?.userSubscribe?.id && elem?.userSubscribe?.mail)
            return elem?.userSubscribe?.mail
        return null
    }

    isOptionEqualToValue(opt, value) {
        if (
            (value?.userSubscribe?.id &&
                value?.userSubscribe?.id === opt?.userSubscribe?.id) ||
            (value?.user?.id && value?.user?.id === opt?.user?.id)
        ) {
            return true
        }
        return false
    }

    render() {
        const { linkedUsers, value } = this.props
        const autoCompleteProps = { ...this.props }

        //remove unused item
        delete autoCompleteProps.dispatch
        delete autoCompleteProps.value
        delete autoCompleteProps.linkedUsers

        let currentValues = linkedUsers.filter(user => value.includes(user.id))

        return (
            <BoatOnAutoComplete
                autoComplete
                multiple
                renderTagCondition={option =>
                    !option.user && option.userSubscribe
                }
                getOptionSelected={this.isOptionEqualToValue}
                margin="dense"
                value={currentValues}
                conditionAlready={opt => this.isAlreadySelected(opt, value)}
                options={linkedUsers}
                onChange={this.onChangeUser}
                getElementToShow={this.getUserName}
                getName={this.getUserName}
                filterOptions={this.filterUsers}
                {...autoCompleteProps}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
    }
}

/**
 * Selector on all linked boats to current user
 * This component is extract from Autocomplete component from MUI
 *
 * @class BoatOnBoatSelector
 * @extends {BoatOnBoatSelector}
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {OnChange} onChange - called each time value change.
 * @param {String} value - id of the selected boat.
 * @param {Boolean} error - if true, change style to indicate error.
 *
 *
 * @region ```
 * Callbacks
 * ```
 *
 * @callback OnChange
 * @param {Object} value - value selected.
 *
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 30/04/2024 - 15:19
 * @author Samuel.C
 */
export default connect(mapStateToProps)(BoatOnCrewSelector)
