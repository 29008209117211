import React from 'react'
import { connect } from 'react-redux'
import BoatOnModal from '../../../../common/BoatOnModal'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import BoatOnAutoComplete from '../../../../common/BoatOnAutoComplete'
import BoatOnNumberField from '../../../../common/UsefullComponents/BoatOnNumberField'
import BoatOnBoatSelector from '../../../../common/UsefullComponents/BoatOnBoatSelector'
import NewUserModal from '../../MyUsersPage/NewUserModal'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Tooltip } from '@material-ui/core'
import { Help as HelpIcon } from '@material-ui/icons'
import { boatActions } from '../../../../../actions/boat.actions'
import { groupActions } from '../../../../../actions/group.actions'
import { initBobWithBoat } from '../../../../../actions/bob.actions'
import { getTranslatedTitleFromEvent } from '../../Utils'
import { userActions } from '../../../../../actions'

import dictionary from './Dictionary/LogBookModalDico'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/LogBookModalCss'
import { getFillingLevels, getWorkingHours } from '../Utils/eventsFilter'

class LogBookModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            tabIndex: 0,
            error: 0,
            selectedCaptain: [],
            selectedCrew: [],
            boat: this.props.currentBoat || null,
            fillingLevelsAtStart: [],
            workingEquipmentHours: [],
            equipmentCapacities: [],
            equipmentInUse: [],
            openModal: false,
            isNewCaptain: false,
        }

        this.handleBackBtn = this.handleBackBtn.bind(this)
        this.save = this.save.bind(this)
        this.isComplete = this.isComplete.bind(this)
        this.handleCaptainChange = this.handleCaptainChange.bind(this)
        this.handleCrewChange = this.handleCrewChange.bind(this)
        this.getUserName = this.getUserName.bind(this)
        this.handleChangeEquipmentHours = this.handleChangeEquipmentHours.bind(
            this,
        )
        this.handleChangeStock = this.handleChangeStock.bind(this)
        this.onChangeBoat = this.onChangeBoat.bind(this)
        this.handleOpenNewUser = this.handleOpenNewUser.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleNewUser = this.handleNewUser.bind(this)
    }

    componentDidMount() {
        const { events, linkedBoats, dispatch } = this.props

        if (events) this._initState()

        if (!linkedBoats) {
            dispatch(boatActions.requestLinkedBoats())
        }
    }

    componentDidUpdate(prevProps) {
        const { events } = this.props

        if (events && prevProps.events !== events) this._initState()
    }

    _initState() {
        const { events, userEquipments } = this.props

        const workingHoursEvents = getWorkingHours(events)
        const fillingLevelEvents = getFillingLevels(events, userEquipments)

        const workingEquipmentHours = workingHoursEvents.equipmentInUse.map(
            event => ({
                hours: event.detail.hourActual,
                equipment: {
                    id: event?.detail?.equipment?.id,
                },
            }),
        )

        const fillingLevels = fillingLevelEvents.bobEventUsed.map(event => ({
            level: event.detail?.optionnalDetail?.fillingLevel || 0,
            equipment: {
                id: event.detail?.equipment?.id,
            },
        }))

        this.setState({
            equipmentInUse: workingHoursEvents.equipmentInUse,
            equipmentCapacities: fillingLevelEvents.bobEventUsed,
            fillingLevelsAtStart: fillingLevels,
            workingEquipmentHours,
        })
    }

    setTabIndex(newIndex) {
        const { isLoading } = this.props

        if (isLoading) return

        this.setState({ tabIndex: newIndex })
    }

    handleBackBtn() {
        const { tabIndex } = this.state
        const { handleClose } = this.props

        if (tabIndex > 0) this.setState({ tabIndex: tabIndex - 1 })
        else handleClose()
    }

    handleCloseModal() {
        this.setState({ openModal: false })
    }

    isComplete() {
        const {
            selectedCaptain,
            fillingLevelsAtStart,
            workingEquipmentHours,
            boat,
        } = this.state

        let stockAreValid = true
        let equipmentsAreValid = true

        fillingLevelsAtStart.forEach(level => {
            if (level.level === '') stockAreValid = false
        })

        workingEquipmentHours.forEach(equipment => {
            if (equipment.hours === '') equipmentsAreValid = false
        })

        if (selectedCaptain.length === 0 || !boat) {
            this.setState({ tabIndex: 0, error: true })
            return false
        } else if (!stockAreValid) {
            this.setState({ tabIndex: 1, error: true })
            return false
        } else if (!equipmentsAreValid) {
            this.setState({ tabIndex: 2, error: true })
            return false
        }

        return true
    }

    save() {
        const {
            tabIndex,
            selectedCaptain,
            selectedCrew,
            fillingLevelsAtStart,
            workingEquipmentHours,
            boat,
            isNewCaptain,
        } = this.state
        const { onClose, linkedUsers } = this.props

        if (tabIndex < 2) {
            this.setState({ tabIndex: tabIndex + 1 })
            return
        }

        if (!this.isComplete()) return

        let captain = null

        if (isNewCaptain && typeof selectedCaptain[0] === 'string') {
            const currentCaptain = linkedUsers.find(
                user =>
                    user.userSubscribe &&
                    user.userSubscribe.mail === selectedCaptain[0],
            )

            captain = currentCaptain.id
        } else captain = selectedCaptain[0].id

        const crew = selectedCrew.map(person => {
            if (person.id)
                return {
                    userLinkId: person.id,
                }
            else {
                const user = linkedUsers.find(
                    user =>
                        user.userSubscribe &&
                        user.userSubscribe.mail === person,
                )

                return {
                    userLinkId: user.id,
                }
            }
        })
        const createdTravel = {
            departureAddress: null,
            arrivalAddress: null,
            boat: {
                id: boat,
            },
            fillingLevels: fillingLevelsAtStart,
            fillingLevelsAtStart: fillingLevelsAtStart.map(fl => ({
                ...fl,
                isAtStart: true,
            })),
            workingEquipmentHours,
            navigationMembers: crew,
            comment: '',
            stopOvers: [],
            delimitedDate: null,
            captainLinkId: captain,
            nbDepartingPassengers: null,
            isAisUsed: false,
        }

        onClose(createdTravel)
    }

    handleCaptainChange(_, values) {
        let { selectedCrew } = this.state

        let selectedCaptain = []

        values.forEach(value => {
            if (!value.button) selectedCaptain.push(value)
        })

        if (selectedCaptain.length > 1) selectedCaptain = [selectedCaptain[1]]

        if (selectedCaptain.length === 1) {
            const index = selectedCrew.findIndex(
                item => item.id === selectedCaptain[0].id,
            )

            if (index !== -1) selectedCrew.splice(index, 1)
        }

        this.setState({
            selectedCaptain,
            selectedCrew,
            isNewCaptain: false,
        })
    }

    handleCrewChange(_, selectedCrew) {
        const newSelectedCrew = []

        selectedCrew.forEach(value => {
            if (!value.button) newSelectedCrew.push(value)
        })

        this.setState({ selectedCrew: newSelectedCrew })
    }

    handleChangeEquipmentHours(value, index) {
        const { equipmentInUse, workingEquipmentHours } = this.state

        const indexEquipment = workingEquipmentHours.findIndex(
            item =>
                equipmentInUse[index]?.detail?.equipment &&
                equipmentInUse[index].detail.equipment.id === item.equipment.id,
        )

        workingEquipmentHours[indexEquipment].hours =
            value !== '' ? parseInt(value) : ''

        this.setState({ workingEquipmentHours })
    }

    handleChangeStock(value, equipmentId) {
        const { fillingLevelsAtStart } = this.state
        const index = fillingLevelsAtStart.findIndex(
            item => item.equipment.id === equipmentId,
        )

        fillingLevelsAtStart[index].level = value !== '' ? parseInt(value) : ''

        this.setState({ fillingLevelsAtStart })
    }

    isAlreadySelected(opt, selectedOpt) {
        return selectedOpt?.some(
            elem =>
                (opt?.user?.id && opt?.user?.id === elem?.user?.id) ||
                (opt?.userSubscribe?.id &&
                    elem?.userSubscribe?.id === opt?.userSubscribe?.id),
        )
    }

    filterUsers(opt, input) {
        const firstLast = opt?.user?.id
            ? `${opt?.user?.firstName} ${opt?.user?.lastName}`
            : null
        const lastFirst = opt?.user?.id
            ? `${opt?.user?.lastName} ${opt?.user?.firstName}`
            : null

        if (
            !input?.inputValue ||
            (opt?.user?.id &&
                ((firstLast &&
                    firstLast.toLowerCase().includes(input.inputValue)) ||
                    (lastFirst &&
                        lastFirst.toLowerCase().includes(input.inputValue)))) ||
            (opt?.userSubscribe?.id &&
                opt?.userSubscribe?.mail
                    .toLowerCase()
                    .includes(input.inputValue.toLowerCase()))
        ) {
            return true
        }
        return false
    }

    getUserName(elem) {
        const { linkedUsers } = this.props

        if (elem?.user?.id) {
            let name = ``
            linkedUsers.forEach(link => {
                if (link?.user?.id === elem?.user?.id)
                    name = `${link?.user?.firstName} ${link?.user?.lastName}`
            })
            return name
        } else if (elem?.userSubscribe?.id && elem?.userSubscribe?.mail)
            return elem?.userSubscribe?.mail
        else if (typeof elem === 'string') return elem
        return null
    }

    isOptionEqualToValue(opt, value) {
        if (
            (value?.userSubscribe?.id &&
                value?.userSubscribe?.id === opt?.userSubscribe?.id) ||
            (value?.user?.id && value?.user?.id === opt?.user?.id)
        ) {
            return true
        }
        return false
    }

    onChangeBoat(value) {
        this.setState({ boat: value?.id || null })
        localStorage.setItem('boatIdSelect', value?.id || -1)
        this.props.dispatch(
            initBobWithBoat({
                user: this.props.user,
                boatId: value?.id,
            }),
        )
    }

    handleOpenNewUser(isNewCaptain) {
        this.setState({ openModal: `newUser`, isNewCaptain })
    }

    async handleNewUser(email, role, boatGroup) {
        const { groupId, dispatch } = this.props

        await dispatch(
            groupActions.addUserToGroup(
                groupId,
                role,
                boatGroup,
                email,
                this.context,
            ),
        )

        if (this.state.isNewCaptain) this.setState({ selectedCaptain: [email] })
        else
            this.setState({ selectedCrew: [...this.state.selectedCrew, email] })

        await dispatch(groupActions.requestUserGroups(groupId))

        this.handleCloseModal()
    }

    _renderCrew() {
        const { classes, linkedUsers = [], groupId, subscriptions } = this.props
        const { error, selectedCaptain, selectedCrew, boat } = this.state

        return (
            <div className={classes.crewPage}>
                <div className={classes.sectionContainer}>
                    {this.displayText('titleBoat')}
                    <BoatOnBoatSelector
                        classes={{
                            inputRootOverride: classes.autocompleteInput,
                        }}
                        groupId={groupId}
                        error={error && !boat ? true : undefined}
                        onChange={this.onChangeBoat}
                        value={boat}
                    />
                </div>
                <div className={classes.sectionContainer}>
                    {this.displayText('titleCrew')}
                    <BoatOnAutoComplete
                        required
                        classes={{
                            inputRootOverride: classes.autocompleteInput,
                        }}
                        error={
                            error && selectedCaptain.length === 0
                                ? true
                                : undefined
                        }
                        autoComplete
                        multiple
                        renderTagCondition={option =>
                            !option.user && option.userSubscribe
                        }
                        getOptionSelected={this.isOptionEqualToValue}
                        margin="dense"
                        value={selectedCaptain}
                        conditionAlready={opt =>
                            this.isAlreadySelected(opt, selectedCaptain)
                        }
                        options={linkedUsers}
                        label={
                            <>
                                {this.displayText('captain')}
                                <Tooltip
                                    title={this.displayText('helpCaptain')}
                                    classes={{ tooltip: classes.tooltip }}
                                >
                                    <HelpIcon className={classes.helpIcon} />
                                </Tooltip>
                            </>
                        }
                        onChange={this.handleCaptainChange}
                        getElementToShow={this.getUserName}
                        getName={this.getUserName}
                        filterOptions={this.filterUsers}
                        addButton={userActions.checkSubscriptionPage(
                            subscriptions,
                            7,
                        )}
                        labelAdd={this.displayText('addLabelButton')}
                        onAddButtonClicked={() => this.handleOpenNewUser(true)}
                    />

                    {userActions.checkSubscriptionPage(subscriptions, 7) && (
                        <BoatOnAutoComplete
                            classes={{
                                inputRootOverride: classes.autocompleteInput,
                            }}
                            autoComplete
                            renderTagCondition={option =>
                                !option.user && option.userSubscribe
                            }
                            getOptionSelected={this.isOptionEqualToValue}
                            margin="dense"
                            multiple
                            value={selectedCrew}
                            conditionAlready={opt =>
                                this.isAlreadySelected(opt, [
                                    ...selectedCaptain,
                                    ...selectedCrew,
                                ])
                            }
                            options={linkedUsers}
                            label={
                                <>
                                    {this.displayText('crewInput')}
                                    <Tooltip
                                        title={this.displayText('helpCrew')}
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <HelpIcon
                                            className={classes.helpIcon}
                                        />
                                    </Tooltip>
                                </>
                            }
                            onChange={this.handleCrewChange}
                            getElementToShow={this.getUserName}
                            getName={this.getUserName}
                            filterOptions={this.filterUsers}
                            addButton
                            labelAdd={this.displayText('addLabelButton')}
                            onAddButtonClicked={() =>
                                this.handleOpenNewUser(false)
                            }
                        />
                    )}
                </div>
            </div>
        )
    }

    _renderStockLevel() {
        const { classes, context } = this.props
        const { equipmentCapacities, fillingLevelsAtStart, error } = this.state

        const displayStocks = equipmentCapacities
            .sort((a, b) => {
                const aName = getTranslatedTitleFromEvent(this, a)
                const bName = getTranslatedTitleFromEvent(this, b)

                if (!aName) return 1
                if (!bName) return -1

                return aName.localeCompare(bName)
            })
            .map((event, index) => {
                const equipment = event.detail?.equipment || null

                if (!equipment) return null

                const imgSrc = equipment.equipmentFamily?.type?.image
                    ? require(`images/${equipment.equipmentFamily?.type?.image}`)
                    : null
                const familyType =
                    equipment.equipmentFamily?.type?.translation[context] || ''
                const equipmentId = equipment.id
                const value = fillingLevelsAtStart.find(
                    item => item.equipment.id === equipmentId,
                )?.level

                const unit = event.capacity
                    ? 'L'
                    : equipment?.equipmentType?.optionnalDetailActivated?.unit
                const capacity = event.detail.optionnalDetail?.capacity
                const capacityRemaining = (value * capacity) / 100

                return (
                    <div
                        className={classes.stockWrapper}
                        key={`modalStockLevelItem-${index}`}
                    >
                        <div className={classes.stockIconContainer}>
                            <img
                                src={imgSrc}
                                alt={familyType}
                                className={classes.stockIcon}
                            />
                        </div>
                        <div className={classes.stockVerticalWrapper}>
                            <div className={classes.stockTitle}>
                                {getTranslatedTitleFromEvent(this, event)}
                            </div>
                            <div className={classes.stockDetails}>
                                {event.detail?.optionnalDetail?.stockName || ''}
                                <div className={classes.stockInputWrapper}>
                                    <BoatOnNumberField
                                        label={this.displayText('actualLevel')}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    %
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                        margin="dense"
                                        onChange={e =>
                                            this.handleChangeStock(
                                                e.target.value,
                                                equipmentId,
                                            )
                                        }
                                        className={classes.stockInput}
                                        value={value}
                                        error={error && value === ''}
                                        max={100}
                                        min={0}
                                    />

                                    <div className={classes.stockInfo}>
                                        {capacity ? (
                                            `${this.displayText(
                                                'capacity',
                                            )} ${capacity} ${unit}`
                                        ) : (
                                            <div
                                                className={classes.noCapacity}
                                                title={this.displayText(
                                                    'missingCapacity',
                                                )}
                                            >
                                                {this.displayText('capacity')} ?{' '}
                                                {unit}
                                            </div>
                                        )}
                                    </div>

                                    <div className={classes.stockInfoPercent}>
                                        {capacity ? (
                                            `${capacityRemaining} ${unit}`
                                        ) : (
                                            <div
                                                className={classes.noCapacity}
                                                title={this.displayText(
                                                    'missingCapacity',
                                                )}
                                            >
                                                ? {unit}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })

        return (
            <>
                <div className={classes.tabTitle}>
                    {this.displayText('stockLevelTitle')}
                </div>
                {displayStocks}
            </>
        )
    }

    _renderWorkingTime() {
        const { equipmentInUse, workingEquipmentHours, error } = this.state
        const { classes, context } = this.props

        const renderEquipment = equipmentInUse
            .sort((a, b) => {
                const aName = getTranslatedTitleFromEvent(this, a)
                const bName = getTranslatedTitleFromEvent(this, b)

                if (!aName) return 1
                if (!bName) return -1

                return aName.localeCompare(bName)
            })
            .map((event, index) => {
                const equipment = event.detail?.equipment || null

                if (!equipment) return null

                const imgSrc = equipment.equipmentFamily?.type?.image
                    ? require(`images/${equipment.equipmentFamily?.type?.image}`)
                    : null
                const familyType =
                    equipment.equipmentFamily?.type?.translation[context] || ''
                const currentHours = workingEquipmentHours.find(
                    equimpentHours =>
                        equimpentHours.equipment.id === equipment.id,
                )

                return (
                    <div
                        className={classes.equipmentWrapper}
                        key={`equipment-${index}`}
                    >
                        <img
                            src={imgSrc}
                            alt={familyType}
                            className={classes.equipmentIcon}
                        />
                        <div className={classes.equipmentDetails}>
                            <div className={classes.equipmentLabel}>
                                {getTranslatedTitleFromEvent(this, event)}
                            </div>
                            <BoatOnNumberField
                                isDecimal
                                label={this.displayText('worktime')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            h
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                margin="dense"
                                onChange={e =>
                                    this.handleChangeEquipmentHours(
                                        e.target.value,
                                        index,
                                    )
                                }
                                value={currentHours?.hours}
                                error={error && currentHours?.hours === ''}
                            />
                        </div>
                    </div>
                )
            })

        return (
            <>
                <div className={classes.tabTitle}>
                    {this.displayText('workingTimeTitle')}
                </div>
                {renderEquipment}
            </>
        )
    }

    render() {
        const { tabIndex, boat } = this.state
        const {
            title,
            noCross = true,
            showFollowButton,
            boatsGroup,
            groups,
            isLoading,
        } = this.props

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    [
                        this.displayText('crew'),
                        this.displayText('stockLevel'),
                        this.displayText('worktime'),
                    ],
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    bodies: [
                        this._renderCrew(),
                        this._renderStockLevel(),
                        this._renderWorkingTime(),
                    ],
                })}
                {this._renderActions([
                    {
                        action: this.handleBackBtn,
                        label:
                            tabIndex === 0
                                ? this.displayText('cancel')
                                : this.displayText('back'),
                        type: 'text',
                    },
                    {
                        action: this.save,
                        label:
                            tabIndex < 2
                                ? this.displayText('next')
                                : this.displayText('save'),
                        disabled: isLoading,
                    },
                ])}
                <BoatOnModal
                    openCondition={this.state.openModal}
                    handleClose={this.handleCloseModal}
                    modalCores={{
                        newUser: (
                            <NewUserModal
                                handleSend={this.handleNewUser}
                                groupId={groups?.id}
                                boats={boatsGroup}
                                boatId={boat?.id}
                            />
                        ),
                    }}
                    maxWidth={{
                        newUser: 'xs',
                    }}
                    titles={{
                        newUser: this.displayText(`newUserTitle`),
                    }}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        subscriptions: state.group?.groupsMembers?.user?.sub || null,
        user: state.authentication.user,
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
        events: state.bob.events,
        isLoading: state.bob.loading > 0,
        boat: state.bob.boat,
        userEquipments: state.types?.userEquipments || [],
        currentBoat: state.bob?.boat?.id,
        linkedBoats: state.boat?.linkedBoats,
        linkedGroups: state.group?.linkedGroups,
        groups: state.group.groupsMembers,
        groupId: state.group.currentGroupId,
        boatsGroup: state.group.groupsMembers
            ? state.group.groupsMembers.boats
            : null,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(LogBookModal))
