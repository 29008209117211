export default theme => ({
    root: {
        width: '100%',
    },
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    root2: {
        width: `100%`,
        height: `80vh`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        flexDirection: `column`,
    },
    backgroundImage: {
        maxWidth: 300,
        objectFit: `contain`,
    },
    noBoatText: {
        maxWidth: 300,
        color: `#303F9F`,
        fontSize: 22,
        fontWeight: `bold`,
        textAlign: `center`,
        margin: 0,
        '&:hover': {
            cursor: `pointer`,
        },
    },
})
