import { workTimeConstants } from '../../constants/workTime.constants'
import { requestApi } from '../../services/api.service'

const postWorkTime = (body, userLink) => {
    return dispatch => {
        dispatch({ type: workTimeConstants.POST })
        return requestApi(`/work-times`, `POST`, body, false, false)
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    dispatch({ type: workTimeConstants.POST_SUCCESS })
                    dispatch(getWorkTimes(userLink.id))
                    if (userLink?.id) {
                        dispatch(getWorkTimesByManager(userLink.id))
                    }
                },
                error => {
                    console.error(error)
                    dispatch({ type: workTimeConstants.POST_FAILURE })
                },
            )
            .catch(error => {
                console.error(error)
                dispatch({ type: workTimeConstants.POST_FAILURE })
            })
    }
}

const updateWorkTime = (data, workTimeId, userLink) => {
    return dispatch => {
        dispatch({ type: workTimeConstants.UPDATE_WORK_TIME })
        return requestApi(
            `/work-times/${workTimeId}`,
            `PUT`,
            data,
            false,
            false,
        )
            .then(
                async result => {
                    if (!result || result.status === 404) return null
                    await dispatch({
                        type: workTimeConstants.UPDATE_WORK_TIME_SUCCESS,
                    })
                    dispatch(getWorkTimes(userLink.id))
                    if (userLink?.id) {
                        dispatch(getWorkTimesByManager(userLink.id))
                    }
                },
                error => {
                    console.error(error)
                    dispatch({
                        type: workTimeConstants.UPDATE_WORK_TIME_FAILURE,
                    })
                },
            )
            .catch(error => {
                console.error(error)
                dispatch({
                    type: workTimeConstants.UPDATE_WORK_TIME_FAILURE,
                })
            })
    }
}

export const downloadTimesheetWorktimePdf = async (
    worktimesExportOptions = [],
    context = 'FR',
) => {
    const contextData = context
    if (typeof contextData === 'string') contextData.toUpperCase()
    return requestApi(
        `/work-times/export/${contextData}/pdf`,
        `POST`,
        {
            worktimesExportOptions,
        },
        false,
        false,
    )
        .then(result => {
            return result.blob()
        })
        .then(blob => {
            if (blob) {
                const href = window.URL.createObjectURL(blob)
                const link = document.createElement(`a`)
                link.href = href
                link.setAttribute(`download`, `worktime-timesheet.pdf`)
                document.body.appendChild(link)
                link.click()
            }
        })
        .catch(error => console.error(error))
}

const getWorkTimes = userLinkId => {
    return dispatch => {
        dispatch({ type: workTimeConstants.GET_WORK_TIMES })

        return requestApi(`/work-times/user-link/${userLinkId}`, `GET`)
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    dispatch({
                        type: workTimeConstants.GET_WORK_TIMES_SUCCESS,
                        workTimes: result,
                    })
                },
                error => {
                    dispatch({ type: workTimeConstants.GET_WORK_TIMES_FAILURE })
                },
            )
            .catch(error => {
                dispatch({ type: workTimeConstants.GET_WORK_TIMES_FAILURE })
            })
    }
}
const getWorkTimesByManager = mangerLinkId => {
    return dispatch => {
        dispatch({ type: workTimeConstants.GET_WORK_TIMES_MANAGER })

        return requestApi(`/work-times/by-manager-link/${mangerLinkId}`, `GET`)
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    dispatch({
                        type: workTimeConstants.GET_WORK_TIMES_MANAGER_SUCCESS,
                        workTimesManager: result,
                    })
                },
                error => {
                    dispatch({
                        type: workTimeConstants.GET_WORK_TIMES_MANAGER_FAILURE,
                    })
                },
            )
            .catch(error => {
                dispatch({
                    type: workTimeConstants.GET_WORK_TIMES_MANAGER_FAILURE,
                })
            })
    }
}

const deleteWorkTime = ({ workTimeId, userLink }) => {
    return dispatch => {
        dispatch({ type: workTimeConstants.DELETE_WORK_TIME })

        return requestApi(`/work-times/${workTimeId}`, `DELETE`)
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    dispatch({
                        type: workTimeConstants.DELETE_WORK_TIME_SUCCESS,
                    })
                    dispatch(getWorkTimes(userLink.id))
                    if (userLink?.id) {
                        dispatch(getWorkTimesByManager(userLink.id))
                    }
                },
                error => {
                    dispatch({
                        type: workTimeConstants.DELETE_WORK_TIME_FAILURE,
                    })
                },
            )
            .catch(error => {
                dispatch({
                    type: workTimeConstants.DELETE_WORK_TIME_FAILURE,
                })
            })
    }
}

export const workTimeActions = {
    postWorkTime,
    getWorkTimes,
    getWorkTimesByManager,
    updateWorkTime,
    deleteWorkTime,
    downloadTimesheetWorktimePdf,
}
