export default {
    newNavigation: {
        FR: 'Nouvelle navigation',
        EN: 'New navigation',
    },
    boat: {
        FR: 'Mon bateau',
        EN: 'My boat',
    },
    distance: {
        FR: 'Distance',
        EN: 'Distance',
    },
    duration: {
        FR: 'Durée navigation',
        EN: 'Navigation time',
    },
    passengers: {
        FR: 'Passagers',
        EN: 'Passengers',
    },
    AISUsed: {
        FR: 'Utilisation AIS',
        EN: 'AIS used',
    },
    manualSetup: {
        FR: 'Navigation manuelle sans AIS',
        EN: 'Manual navigation without AIS',
    },
    loading: {
        FR: 'Chargement',
        EN: 'Loading',
    },
    NoNavigationWithFilters: {
        FR: `Oups, il n'y a rien ici 🧐. Essayez avec d'autres filtres`,
        EN: `Oups, there's nothing here 🧐. Try with other filters`,
    },
    NoNavigation: {
        FR: `Oups, il n'y a rien ici 🧐. Enregistrez votre première navigation en cliquant sur "Nouvelle navigation".`,
        EN: `Oups, there's nothing here 🧐. To create you first navigation, click on "New navigation".`,
    },
    seeMore: {
        FR: 'En voir plus',
        EN: 'See more',
    },
    validateDelete: {
        FR: 'Voulez-vous vraiment supprimer les navigations selectionnées ?',
        EN: 'Do you really want to delete the selected navigations ?',
    },
    downloadPdfWillTakeTime: {
        FR:
            'Le téléchargement du PDF peut prendre quelques minutes, pour faciliter le téléchargement et votre expérience nous vous enverrons un email avec le PDF généré.',
        EN:
            'The PDF download can take a few minutes, to facilitate the download and your experience we will send you an email with the generated PDF.',
    },
    downloadExcelWillTakeTime: {
        FR:
            "Le téléchargement de l'Excel peut prendre quelques minutes, pour faciliter le téléchargement et votre expérience nous vous enverrons un email avec l'Excel généré.",
        EN:
            'The Excel download can take a few minutes, to facilitate the download and your experience we will send you an email with the generated Excel.',
    },
    information: {
        FR: 'Information',
        EN: 'Information',
    },
    dowloadError: {
        FR:
            'Une erreur est survenue lors de la génération du pdf, réessayez ou contactez nous.',
        EN:
            'Something went wrong during the pdf generation, try again or contact us.',
    },
}
