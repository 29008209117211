export default theme => ({
    root: {
        position: 'relative',
        minHeight: 'calc(100vh - 94px)',
    },
    menuRoot: {
        alignSelf: 'flex-start',
        position: 'sticky',
        top: 0,
        [theme.breakpoints.down('sm')]: {
            position: 'static',
        },
    },
    profile: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        padding: '20px',
        paddingTop: '0',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    tab: {
        width: '68%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginLeft: 0,
        },
    },
    menu: {
        width: '18%',
        minWidth: '300px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    logout: {
        color: 'black',
        textDecoration: 'none',
        float: 'left',
        marginTop: '20px',
        '&:visited': {
            color: 'black',
            textDecoration: 'none',
        },
    },
})
