import React from 'react'
import BoatOnComponent from '../common/BoatOnComponent'
import Vector2 from '../../images/pricesPage/Vector2.png'
import Vector3 from '../../images/pricesPage/Vector3.png'
import Vector4 from '../../images/pricesPage/Vector4.png'
import Vector5 from '../../images/pricesPage/1.png'
import Vector6 from '../../images/pricesPage/2.png'
import Vector7 from '../../images/pricesPage/3.png'

import Ecran1 from '../../images/landingPage/ecran1.png'
import Ecran2 from '../../images/landingPage/ecran2.png'
import Ecran3 from '../../images/landingPage/ecran3.png'
import Ecran4 from '../../images/landingPage/ecran4.png'
import Ecran5 from '../../images/landingPage/ecran5.png'
import Ecran6 from '../../images/landingPage/ecran6.png'
import Ecran7 from '../../images/landingPage/ecran7.png'
import Arrow1 from '../../images/landingPage/arrow1.png'

import Background from '../../images/landingPage/background.jpg'
import wistream from '../../images/pricesPage/wistream-logo.png'
import sanlorenzo from '../../images/pricesPage/sanlorenzo-logo.png'
import hanse from '../../images/pricesPage/hanse-logo.png'
import beneteau from '../../images/pricesPage/beneteau-logo.png'
import lagoon from '../../images/pricesPage/lagoon-logo.png'
import photo2 from '../../images/pricesPage/face2.jpg'
import photo3 from '../../images/pricesPage/face3.jpeg'
import photo4 from '../../images/pricesPage/face4.jpg'
import photo5 from '../../images/pricesPage/face5.jpeg'
import icon1 from '../../images/rocketIcon.svg'
import icon2 from '../../images/chronoIcon.svg'
import icon3 from '../../images/ecoIcon.svg'
import { Typography } from '@material-ui/core'
import dictionary from './Dictionary/LandingPageDico'
import styles from './Styles/LandingPageCss'
import { withStyles } from '@material-ui/core'
import { Button } from '../common'

import ArrowForwardIcon from '@material-ui/icons/ArrowForward'

import Comment from '../PricesPage/Comment'
import { history } from '../../helpers/history'
import AppRoute from '../../constants/AppRoute'

const imagesDemo = [Ecran1, Ecran3, Ecran4, Ecran2, Ecran7, Ecran5, Ecran6]
const comments = [
    {
        nom: `Benoît`,
        photo: photo2,
        date: `29 juillet 2021`,
    },
    {
        nom: `Stéphane`,
        photo: photo3,
        date: `12 mai 2021`,
    },
    {
        nom: `Eric`,
        photo: photo4,
        date: `16 juin 2021`,
    },
    {
        nom: `Jean-Luc 33`,
        photo: photo5,
        date: `23 janvier 2021`,
    },
]

class LandingPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this._renderHeader = this._renderHeader.bind(this)
        this._renderExplanation = this._renderExplanation.bind(this)
        this._renderExplanationText = this._renderExplanationText.bind(this)
        this._renderPreFooter = this._renderPreFooter.bind(this)
        this._redirectPricing = this._redirectPricing.bind(this)
        this._redirectLogbook = this._redirectLogbook.bind(this)
    }

    _redirectLogbook() {
        window.open(`https://share.hsforms.com/1mRkCQiD2RlmIbm7XEGzbDQ30ve4`)
    }

    _bottomText() {
        const { classes } = this.props

        return (
            <div className={classes.bottomTextContainer}>
                <Typography component="h3" className={classes.bottomTextTitle}>
                    {' '}
                    {this.displayText(`seoStart`)}
                    <sup>{this.displayText(`subtitle2`)} </sup>
                    {this.displayText(`seoStart1`)}
                </Typography>
                <>
                    <h2>{this.displayText(`seoTitle1`)}</h2>
                    <Typography
                        component="h3"
                        className={classes.bottomTextTitle}
                    >
                        {this.displayText(`seoDesc1`)}
                    </Typography>
                    <h2>{this.displayText(`seoTitle2`)}</h2>
                    <Typography
                        component="h3"
                        className={classes.bottomTextTitle}
                    >
                        {this.displayText(`seoDesc2`)}
                        <ul>
                            <li>{this.displayText(`seoDesc2Li1`)}</li>
                            <li>{this.displayText(`seoDesc2Li2`)}</li>
                            <li>{this.displayText(`seoDesc2Li3`)}</li>
                            <li>{this.displayText(`seoDesc2Li4`)}</li>
                        </ul>
                    </Typography>
                    <h2>{this.displayText(`seoTitle3`)}</h2>
                    <Typography
                        component="h3"
                        className={classes.bottomTextTitle}
                    >
                        {this.displayText(`seoDesc3`)}
                    </Typography>
                    <h2>{this.displayText(`seoTitle4`)}</h2>
                    <Typography
                        component="h3"
                        className={classes.bottomTextTitle}
                    >
                        {this.displayText(`seoDesc4`)}
                        <ul>
                            <li>{this.displayText(`seoDesc4Li1`)}</li>
                            <li>{this.displayText(`seoDesc4Li2`)}</li>
                            <li>{this.displayText(`seoDesc4Li3`)}</li>
                            <li>{this.displayText(`seoDesc4Li4`)}</li>
                            <li>{this.displayText(`seoDesc4Li5`)}</li>
                        </ul>
                    </Typography>
                    <Typography
                        component="h3"
                        className={classes.bottomTextTitle}
                    >
                        {this.displayText(`seoDesc4Bis`)}
                        <ul>
                            <li>{this.displayText(`seoDesc4BisLi1`)}</li>
                            <li>{this.displayText(`seoDesc4BisLi2`)}</li>
                            <li>{this.displayText(`seoDesc4BisLi3`)}</li>
                            <li>{this.displayText(`seoDesc4BisLi4`)}</li>
                            <li>{this.displayText(`seoDesc4BisLi5`)}</li>
                        </ul>
                    </Typography>
                    <Typography
                        component="h3"
                        className={classes.bottomTextTitle}
                    >
                        {this.displayText(`seoDesc4Final`)}
                    </Typography>
                    <h2>{this.displayText(`seoTitle5`)}</h2>
                    <Typography
                        component="h3"
                        className={classes.bottomTextTitle}
                    >
                        {this.displayText(`seoDesc5`)}
                    </Typography>
                    <h2>{this.displayText(`seoTitle6`)}</h2>
                    <Typography
                        component="h3"
                        className={classes.bottomTextTitle}
                    >
                        {this.displayText(`seoDesc6`)}
                    </Typography>
                    <h2>{this.displayText(`seoTitle7`)}</h2>
                    <Typography
                        component="h3"
                        className={classes.bottomTextTitle}
                    >
                        {this.displayText(`seoDesc7`)}
                        <ol>
                            <li>{this.displayText(`seoDesc7Ol1`)}</li>
                            <li>{this.displayText(`seoDesc7Ol2`)}</li>
                            <li>{this.displayText(`seoDesc7Ol3`)}</li>
                        </ol>
                    </Typography>
                    <Typography
                        component="h3"
                        className={classes.bottomTextTitle}
                    >
                        {this.displayText(`seoDesc7Final`)}
                    </Typography>
                </>
            </div>
        )
    }

    _redirectPricing() {
        this.historyPush(history, AppRoute.PricesPage)
    }

    _renderHeader() {
        const { classes } = this.props

        return (
            <div className={classes.header}>
                <div className={classes.headerContainer}>
                    <div className={classes.titleContainer}>
                        <Typography className={classes.title}>
                            {' '}
                            {this.displayText(`title`)}{' '}
                        </Typography>
                        <Typography component="h1" className={classes.subtitle}>
                            {' '}
                            {this.displayText(`subtitle1`)}
                            <sup>{this.displayText(`subtitle2`)} </sup>{' '}
                            {this.displayText(`subtitle3`)}{' '}
                        </Typography>

                        <div className={classes.headerButtons}>
                            <Button
                                onClick={this._redirectPricing}
                                label={this.displayText(`tryFree`)}
                                classNameOverride={classes.normalButton}
                            />
                            <Button
                                onClick={this._redirectLogbook}
                                label={this.displayText(`askDemo`)}
                                classNameOverride={classes.whiteButton}
                            />
                        </div>
                    </div>
                    <div className={classes.youtubeContainer}>
                        <iframe
                            className={classes.youtube}
                            src={this.displayText('video')}
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        ></iframe>
                    </div>
                </div>
                <img
                    alt="vector"
                    title="vector"
                    src={Vector2}
                    className={`${classes.vector2} ${classes.vector}`}
                />
                <img
                    alt="vector"
                    title="vector"
                    src={Vector3}
                    className={`${classes.vector3} ${classes.vector}`}
                />
                <img
                    alt="vector"
                    title="vector"
                    src={Vector4}
                    className={`${classes.vector4} ${classes.vector}`}
                />

                <img
                    alt="vector"
                    title="vector"
                    src={Vector5}
                    className={`${classes.vector5} ${classes.vector}`}
                />
                <img
                    alt="vector"
                    title="vector"
                    src={Vector7}
                    className={`${classes.vector7} ${classes.vector}`}
                />
                <img
                    alt="vector"
                    title="vector"
                    src={Vector6}
                    className={`${classes.vector6} ${classes.vector}`}
                />
            </div>
        )
    }

    _renderPreFooter() {
        const { classes } = this.props

        return (
            <div className={classes.footerContainer}>
                <Typography className={classes.footerTitle}>
                    {this.displayText(`testTitle`)}
                </Typography>
                <Typography className={classes.footerSubtitle}>
                    {' '}
                    {this.displayText(`testSubtitle`)}{' '}
                </Typography>
                <div className={classes.preFooterButtons}>
                    <Button
                        onClick={this._redirectPricing}
                        label={this.displayText(`tryFree`)}
                        classNameOverride={classes.normalButton}
                    />
                    <Button
                        onClick={this._redirectLogbook}
                        label={this.displayText(`askDemo`)}
                        classNameOverride={classes.whiteButton}
                    />
                    <div className={classes.footerOffer}>
                        <img
                            src={Arrow1}
                            alt="arrow"
                            title="arrow"
                            className={classes.footerArrow}
                        />
                        <Typography className={classes.footerLittletext}>
                            {' '}
                            {this.displayText(`oneMonth`)}{' '}
                        </Typography>
                    </div>
                </div>
            </div>
        )
    }

    _renderExplanationText(title, sub, link) {
        const { classes } = this.props

        return (
            <div className={classes.explTexts}>
                <Typography component="h2" className={classes.explTitle}>
                    {' '}
                    {title}{' '}
                </Typography>
                <Typography component="h3" className={classes.explSubt}>
                    {' '}
                    {sub}{' '}
                </Typography>
                <div
                    className={classes.explLink}
                    onClick={this._redirectPricing}
                >
                    <Typography className={classes.explLinkText}>
                        {' '}
                        {this.displayText(`startNow`)}{' '}
                    </Typography>
                    <ArrowForwardIcon className={classes.explLinkIcon} />
                </div>
            </div>
        )
    }

    _renderExplanation(right, title, sub, img, link) {
        const { classes } = this.props

        return (
            <div
                className={classes.expl}
                style={{ flexDirection: right ? `row` : `row-reverse` }}
            >
                {this._renderExplanationText(title, sub, link)}

                <div className={classes.explImgs}>
                    <img
                        className={classes.explBackground}
                        src={Background}
                        alt="fond"
                        title="fond"
                    />
                    <img
                        className={classes.explDemo}
                        src={img}
                        alt="demo"
                        title="demo"
                    />
                </div>
            </div>
        )
    }

    render() {
        const { classes } = this.props

        return (
            <div style={{ overflowX: `clip` }}>
                {this._renderHeader()}
                <Typography className={classes.satisfaction}>
                    {' '}
                    {this.displayText(`satisfaction`)}{' '}
                </Typography>
                <div className={classes.part}>
                    <img
                        src={sanlorenzo}
                        alt="sanlorenzo"
                        title="sanlorenzo"
                        className={classes.logoImage}
                    />
                    <img
                        src={hanse}
                        alt="hanse"
                        title="hanse"
                        className={classes.logoImage}
                    />
                    <img
                        src={wistream}
                        alt="wistream"
                        title="wistream"
                        className={classes.logoImage}
                    />
                    <img
                        src={beneteau}
                        alt="beneteau"
                        title="beneteau"
                        className={classes.logoImage}
                    />
                    <img
                        src={lagoon}
                        alt="lagoon"
                        title="lagoon"
                        className={classes.logoImage}
                    />
                </div>
                <div className={classes.threePoints}>
                    <div className={classes.point}>
                        <div className={classes.pointIconContainer}>
                            <img
                                src={icon1}
                                alt="icon"
                                title="icon"
                                className={classes.pointIcon}
                            />
                        </div>
                        <Typography className={classes.pointTitle}>
                            {' '}
                            <span className={classes.pointSpeTitle}>
                                {' '}
                                {this.displayText(`iconBluePart1`)}{' '}
                            </span>{' '}
                            {this.displayText(`iconBlackPart1`)}{' '}
                        </Typography>
                        <p className={classes.pointText}>
                            {this.displayText(`iconText1`)}{' '}
                        </p>
                    </div>
                    <div className={classes.point}>
                        <div className={classes.pointIconContainer}>
                            <img
                                src={icon2}
                                alt="icon"
                                title="icon"
                                className={classes.pointIcon}
                            />
                        </div>
                        <Typography className={classes.pointTitle}>
                            {' '}
                            {this.displayText(`iconBlackPart2`)}{' '}
                            <span className={classes.pointSpeTitle}>
                                {' '}
                                {this.displayText(`iconBluePart2`)}{' '}
                            </span>{' '}
                            {this.displayText(`iconBlack2Part2`)}{' '}
                        </Typography>
                        <p className={classes.pointText}>
                            {' '}
                            {this.displayText(`iconText2`)}{' '}
                        </p>
                    </div>
                    <div className={classes.point}>
                        <div className={classes.pointIconContainer}>
                            <img
                                src={icon3}
                                alt="icon"
                                title="icon"
                                className={classes.pointIcon}
                            />
                        </div>
                        <Typography className={classes.pointTitle}>
                            {' '}
                            {this.displayText(`iconBlackPart3`)}{' '}
                            <span className={classes.pointSpeTitle}>
                                {' '}
                                {this.displayText(`iconBluePart3`)}{' '}
                            </span>{' '}
                            {this.displayText(`iconBlack2Part3`)}{' '}
                        </Typography>
                        <p className={classes.pointText}>
                            {' '}
                            {this.displayText(`iconText3`)}{' '}
                        </p>
                    </div>
                </div>
                <Typography
                    component="h2"
                    className={classes.title}
                    style={{
                        marginTop: 45,
                        marginBottom: 10,
                        padding: `0px 45px`,
                    }}
                >
                    {this.displayText('easiestMaintenanceApp')}
                </Typography>
                <Typography
                    component="h3"
                    className={classes.subtitle}
                    style={{
                        maxWidth: `900px`,
                        width: `100%`,
                        textAlign: `center`,
                        margin: `auto`,
                    }}
                >
                    {this.displayText('subEasiestMaintenanceApp')}
                </Typography>
                {imagesDemo.map((e, i) =>
                    this._renderExplanation(
                        i % 2 === 0,
                        this.displayText(`titleDemo${i + 1}`),
                        this.displayText(`subtitleDemo${i + 1}`),
                        e,
                        ``,
                    ),
                )}
                <Typography
                    style={{
                        maxWidth: `900px`,
                        width: `100%`,
                        textAlign: `center`,
                        margin: `auto`,
                        fontSize: `32px`,
                        fontWeight: `500`,
                        lineHeight: 1.5,
                        marginTop: 20,
                        marginBottom: 15,
                    }}
                >
                    {this.displayText('winningTime')}
                </Typography>
                <div className={classes.reviews}>
                    {comments.map((com, index) => (
                        <Comment
                            descr={this.displayText(`desc${index}`)}
                            {...com}
                            key={index}
                        />
                    ))}
                </div>
                {this._renderPreFooter()}
                {this._bottomText()}
            </div>
        )
    }
}

export default withStyles(styles)(LandingPage)
