export default {
    title: {
        FR: "Intitulé de l'évènement",
        EN: 'Event title',
    },
    allDay: {
        FR: 'Toute la journée',
        EN: 'All day',
    },
    valid: {
        FR: 'Je valide',
        EN: 'I valid',
    },
    once: {
        FR: 'Une seule fois',
        EN: 'Once',
    },
    everyday: {
        FR: 'Tous les jours',
        EN: 'Every day',
    },
    everyweek: {
        FR: 'Toutes les semaines',
        EN: 'Every week',
    },
    everymonth: {
        FR: 'Tous les mois',
        EN: 'Every month',
    },
    everyyear: {
        FR: 'Tous les ans',
        EN: 'Every year',
    },
    recurrence: {
        FR: 'Récurrence',
        EN: 'Recurrence',
    },
    addAlert: {
        FR: 'Ajouter une alerte',
        EN: 'Add an alert',
    },
    alert: {
        FR: 'Alerte',
        EN: 'Alert',
    },
    minute: {
        FR: 'min',
        EN: 'min',
    },
    day: {
        FR: 'jour',
        EN: 'day',
    },
    before: {
        FR: 'avant',
        EN: 'before',
    },
    essentialInfos: {
        FR: 'Informations essentielles',
        EN: 'Essential information',
    },
    details: {
        FR: 'Details',
        EN: 'Details',
    },
    location: {
        FR: 'Lieu',
        EN: 'Location',
    },
    guests: {
        FR: 'Invités',
        EN: 'Guests',
    },
    comments: {
        FR: 'Commentaires',
        EN: 'Comments',
    },
    attachments: {
        FR: 'Pièces jointes',
        EN: 'Attachments',
    },
}
