export default {
    valid: {
        FR: 'Valider',
        EN: 'Valid',
    },
    address: {
        FR: 'Adresse',
        EN: 'Address',
    },
    noData: {
        FR: `Malheureusement aucune donnée AIS n'est disponible ou les données sont trop anciennes. Veuillez entrer l'adresse actuelle.`,
        EN: `No AIS available or all data are outdated. Please enter actual address :`,
    },
}
