const AppMeta = {
    fr: {
        'routes.root': {
            title:
                'Stockage de bateaux, logiciel d’entretien, assurance I BoatOn.fr',
            description:
                'La plateforme leader pour gérer son bateau de A à Z. + 10 000 utilisateurs ✅ Carnet d’entretien et Logiciel nautisme pour boat manager ✅ Assurance bateau 40% moins cher ✅ Hivernage et stockage de bateaux à moteur ou voilier ✅ 100% sécurisé. ',
            type: '',
            image: '',
        },
        'routes.search-boat-parking': {
            title:
                'BoatOn Stock : gardiennage, hivernage et stockage de bateaux',
            description:
                'Costockage, entrepôt, hangar de stockage, garage entre particuliers et professionnels pour hivernage bateau, voilier, jet-ski, remorque ou camping-car. A louer près de chez vous à petit prix dans toute la France 🇫🇷',
            type: '',
            image: '',
        },
        'routes.get-boat-insurance': {
            title:
                'Assurance Bateau plaisance & yacht ▷ Comparateur gratuit I BoatOn',
            description:
                "Économisez sur votre contrat d'assurance bateau en comparant l'ensemble des offres afin de trouver celle qui correspond le mieux à vos besoins. Bateau, péniche, voilier, jet-ski ou superyacht réalisez un devis en ligne en quelques minutes !",
            type: '',
            image: '',
        },
        'routes.prices': {
            title:
                'BoatOn Book Essai gratuit I Maintenance yacht, pêche, commerce',
            description:
                'Essayez le BoatOn Book : 1 mois d’essai gratuit au logiciel de maintenance le plus malin du marché I ✅ Sans engagement ✅ Aucune formation ✅ Économique',
            type: '',
            image: '',
        },
        'routes.logbook.home': {
            title:
                'BoatOn Book | Maintenance et GMAO yachts et loueurs de bateaux',
            description:
                'Logiciel de maintenance, management et GMAO de tous les bateaux et yachts faits avec des marins et experts du yachting ✅ Simple ✅ Aucune formation nécessaire ✅ 100% sécurisé et certifié',
            type: '',
            image: '',
        },
        'routes.profile': {
            title: 'Votre profil Boaton',
            description: 'Votre profil Boaton',
            type: '',
            image: '',
        },
    },
    en: {
        'routes.root': {
            title: 'Boat storage, maintenance software, insurance I BoatOn.fr',
            description:
                'The leading platform to manage your boat from A to Z. + 10,000 users ✅ Maintenance book and Nautical software for boat manager ✅ Boat insurance 40% cheaper ✅ Wintering and storage of motor boats or sailboats ✅ 100% secure.',
            type: '',
            image: '',
        },
        'routes.search-boat-parking': {
            title: 'BoatOn Stock : boat storage and winter storage',
            description:
                'Shared storage, warehouse, storage shed, garage between individuals and professionals for wintering boat, sailboat, seadoo, trailer or motorhome. For rent near your home at low prices in France 🇫🇷',
            type: '',
            image: '',
        },
        'routes.get-boat-insurance': {
            title: 'Recreational boat & yacht insurance ▷ free quote I BoatOn',
            description:
                'Save on your boat insurance by comparing all the offers to find the one that best suits your needs. Boat, barge, sailboat, jet-ski or superyacht, get a quote online in a few minutes!',
            type: '',
            image: '',
        },
        'routes.prices': {
            title:
                'BoatOn Book Free Trial I Yacht maintenance, fishing, shipping',
            description:
                'Try the BoatOn Book: 1 month free trial to the smartest maintenance software on the market I ✅ No commitment ✅ No training ✅ Economical',
            type: '',
            image: '',
        },
        'routes.logbook.home': {
            title: 'BoatOn Book | Maintenance and PMS yachts and boats rental',
            description:
                'Maintenance, management and PMS software for all boats and yachts created with sailors and yachting experts ✅ Simple ✅ No training ✅ 100% safe and certified',
            type: '',
            image: '',
        },
    },
}

export default AppMeta
