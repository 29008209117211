import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from './BoatOnComponent'
import { Typography, makeStyles } from '@material-ui/core'
import dictionary from './Dictionary/ActivityConfiguration'
import ReactHtmlParser from 'react-html-parser'
import 'react-quill/dist/quill.snow.css'
import { Button } from '../common/BoatOnButton'
import { connect } from 'react-redux'
import { commentsActions } from '../../actions/bob/comments.actions'
import chargement from '../../images/anchorLoader.gif'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import ImageIcon from '@mui/icons-material/Image'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

// RTEditor
import { EditorState, convertToRaw, ContentState, Modifier } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import styles from './ActivityComponentCss'
import {
    addFileToComment,
    getImageToDisplayForComments,
    postDataBOBDocuments,
    removeDataBOBDocuments,
} from '../../actions'
import DisplayerImgModal from '../LogbookPage/DisplayerImgModal'
import { API_URL } from '../../services/config.service'
import PersonIcon from '@mui/icons-material/Person'
import BoatOnModal from './BoatOnModal'
import BoatOnModalCore from './BoatOnModalCore'
import BoatOnImageRender from './BoatOnImageRender'
import { ExpendableRow } from './UsefullComponents/ExpendableRow'
import {
    activityRenderTypes,
    activityDataTypes,
} from './Dictionary/ActivityConfiguration'
import { userActions } from '../../actions'
import { history } from '../../helpers'
import AppRoute from '../../constants/AppRoute'
import { fixIosHeaderBug } from '../../utils/usefullFunctions'
import {
    getLongFormatedDateTimeUTC,
    getSimpleFormatedDate,
    getSimpleFormatedDateTimeUTC,
} from './Utils'

class ActivityComponent extends BoatOnComponent {
    constructor(props) {
        super(props)

        this._renderPhraseActivity = this._renderPhraseActivity.bind(this)
        this._renderValueByType = this._renderValueByType.bind(this)
        this.onChangeComment = this.onChangeComment.bind(this)
        this.modifierCommentaire = this.modifierCommentaire.bind(this)
        this.supprimerCommentaire = this.supprimerCommentaire.bind(this)
        this.toggleDetails = this.toggleDetails.bind(this)
        this.addFileToComments = this.addFileToComments.bind(this)
        this.deleteDocument = this.deleteDocument.bind(this)
        this.submit = this.submit.bind(this)
        this.handleOpenDisplayer = this.handleOpenDisplayer.bind(this)
        this.handleCloseDisplayer = this.handleCloseDisplayer.bind(this)
        this.updateMentions = this.updateMentions.bind(this)

        this.dictionary = dictionary

        this.editorRef = React.createRef()

        this.state = {
            editorValue: '',
            editorState: EditorState.createEmpty(),
            list: [],
            selectedComment: null,
            showActivity: true,
            fileNoUrl: { comment: [] },
            displayNewFiles: { comment: [] },
            deletedFiles: { comment: [] },
            commentFiles: [],
            modaleFiles: [], //Fichiers à afficher dans la modale
            openDisplayerImg: false,
            indexModale: 0, //Index du fichier à afficher dans la
            loadingImage: false,
        }

        Object.byString = function(o, s) {
            s = s.replace(/\[(\w+)\]/g, '.$1')
            s = s.replace(/^\./, '')
            var a = s.split('.')
            for (var i = 0, n = a.length; i < n; ++i) {
                var k = a[i]
                if (k in o) {
                    o = o[k]
                } else {
                    return
                }
            }
            return o
        }
    }

    componentDidMount() {
        const { activities, comments } = this.props
        const { list } = this.state

        if (
            (activities.length > 0 || comments.length > 0) &&
            list.length === 0
        ) {
            // Combine l'activité et les commentaires
            const list = this.getList(activities, comments)

            this.setState({ list })
        }
    }

    componentDidUpdate(prevProps) {
        const { activities, comments } = this.props
        const { list } = this.state

        if (
            activities !== prevProps.activities ||
            comments !== prevProps.comments ||
            ((activities.length > 0 || comments.length > 0) &&
                list.length === 0)
        ) {
            // Combine l'activité et les commentaires
            const list = this.getList(activities, comments)

            this.setState({ list })
        }

        if (!prevProps.forceSave && this.props.forceSave) this.submit()

        this.updateMentions()
    }

    getList(activities, comments) {
        return (activities || [])
            .map(a => {
                return {
                    ...a,
                    type: 'activity',
                }
            })
            .concat(
                (comments || []).map(c => {
                    return {
                        ...c,
                        type: 'comment',
                    }
                }),
            )
            .sort((a, b) => {
                return new Date(a.timestamp) > new Date(b.timestamp) ? -1 : 1
            })
    }

    onChangeComment(editorState) {
        this.setState({
            editorState,
        })
    }

    // Charge le commentaire cliqué dans l'éditeur
    modifierCommentaire(item) {
        const { scrollToTop } = this.props

        // Transforme le message en HTML
        // Et réinitialise l'éditeur avec ce message
        const blocksFromHtml = htmlToDraft(item.message)
        const { contentBlocks, entityMap } = blocksFromHtml
        const contentState = ContentState.createFromBlockArray(
            contentBlocks,
            entityMap,
        )

        // Sélectionne les fichiers de ce commentaire

        this.setState(
            {
                selectedComment: item.id,
                editorState: EditorState.createWithContent(contentState),
                commentFiles: item.files,
                deletedFiles: {
                    comment: [],
                },
            },
            () => {
                scrollToTop()
            },
        )
    }

    // Supprime le commentaire cliqué
    supprimerCommentaire(commentaire) {
        const { selectedComment } = this.state

        if (window.confirm(this.displayText('validateDelete'))) {
            // Suppression des fichiers associés
            if (commentaire.files) {
                removeDataBOBDocuments(commentaire.files.map(f => f.id))
            }

            this.props.dispatch(commentsActions.deleteComment(commentaire.id))

            // Supprime le commentaire de la liste locale
            let newList = [...this.state.list].filter(item => {
                return item.id !== commentaire.id
            })

            this.setState({
                list: newList,
                selectedComment:
                    selectedComment === commentaire.id ? null : selectedComment,
            })
        }
    }

    // Affiche/masque l'activité
    toggleDetails() {
        this.setState({
            showActivity: !this.state.showActivity,
        })
    }

    async submit() {
        const { event, user, eventType } = this.props
        const {
            selectedComment,
            list,
            editorState,
            fileNoUrl,
            commentFiles,
            deletedFiles,
        } = this.state

        // Transforme le commentaire en HTML
        const commentHtml = draftToHtml(
            convertToRaw(editorState.getCurrentContent()),
        )

        //Return si le commentaire est vide et si pas de fichiers
        if (
            commentHtml.replace(/(<\/?[^>]+(>|$)|&nbsp;|\s)/g, '') === '' &&
            fileNoUrl.comment.length === 0
        ) {
            return
        }

        // Upload des nouveaux fichiers
        const files = await postDataBOBDocuments(fileNoUrl.comment, 1)

        // La liste locale des commentaire est mise à jour pour refléter instantanément les changements
        let newList

        if (selectedComment) {
            // Si images supprimées, alors suppression des image en back
            if (deletedFiles.comment.length > 0) {
                await removeDataBOBDocuments(deletedFiles.comment)
            }

            // Modifier le commentaire sélectionné
            newList = [...this.state.list]
            let comment = newList.find(item => item.id === selectedComment)
            comment.message = commentHtml
            comment.files = [...commentFiles, ...files]
            this.props.dispatch(commentsActions.updateComment(comment))
        } else {
            const body = {
                message: commentHtml,
                files: files,
            }

            switch (eventType) {
                case 'workTime':
                    body.workTimeId = event.id
                    break
                case 'leave':
                    body.userAbsenceId = event.id
                    break
                case 'navigation':
                    body.navigationId = event.id
                    break
                case 'order':
                    body.orderId = event.id
                default:
                    body.bobEventId = event.id
                    break
            }

            // Envoyer le commentaire
            this.props.dispatch(commentsActions.postComment(body))
            newList = [
                {
                    createdBy: user,
                    message: commentHtml,
                    timestamp: new Date(),
                    files: files.map(file => {
                        return {
                            id: file.id,
                            link: file.link,
                            date: new Date(),
                            fileType: {
                                id: 1,
                                fileType: 'image',
                                description: 'Image can include png, jpeg',
                            },
                        }
                    }),
                    type: 'comment',
                },
                ...list,
            ]
        }

        // Vider l'editeur, mettre à jour la liste locale et déselectionner le commentaire
        this.setState({
            editorState: EditorState.createEmpty(),
            list: newList,
            selectedComment: null,
            fileNoUrl: {
                comment: [],
            },
            displayNewFiles: {
                comment: [],
            },
        })
    }

    // Déclenche l'ouverture du dialog de sélection de fichier
    addFileToComments(event) {
        if (
            event?.target?.files &&
            [...event.target.files].find(file =>
                file.name.toLowerCase().includes('.heic'),
            )
        ) {
            // So we display a loading icon during the time of heic conversion
            this.setState({ loadingImage: true }, async () => {
                await addFileToComment(this, event, {
                    fileNoUrl: this.state.fileNoUrl.comment,
                    displayNewFiles: this.state.displayNewFiles.comment,
                })
                this.setState({ loadingImage: false })
            })
        } else {
            addFileToComment(this, event, {
                fileNoUrl: this.state.fileNoUrl.comment,
                displayNewFiles: this.state.displayNewFiles.comment,
            })
        }
    }

    deleteDocument(i) {
        const {
            commentFiles,
            displayNewFiles,
            fileNoUrl,
            deletedFiles,
        } = this.state

        const imgToDelete = commentFiles.concat(displayNewFiles.comment)[i]

        // Si il s'agit d'une image déjà enregistré
        if (imgToDelete.link) {
            this.setState({
                commentFiles: commentFiles.filter(f => f.id !== imgToDelete.id),
                deletedFiles: {
                    comment: [...deletedFiles.comment, imgToDelete.id],
                },
            })
        } else {
            // Si il s'agit d'une nouvelle image qui vient d'être ajoutée
            const index = displayNewFiles.comment.indexOf(imgToDelete)

            let newDisplayNewFiles = [...displayNewFiles.comment]
            newDisplayNewFiles.splice(index, 1)
            let newFileNoUrl = [...fileNoUrl.comment]
            newFileNoUrl.splice(index, 1)

            this.setState({
                displayNewFiles: {
                    comment: newDisplayNewFiles,
                },
                fileNoUrl: {
                    comment: newFileNoUrl,
                },
            })
        }
    }

    handleCloseDisplayer() {
        this.setState({
            openDisplayerImg: false,
            indexModale: 0,
            modaleFiles: [],
        })
    }

    handleOpenDisplayer(fileList, index) {
        this.setState({
            openDisplayerImg: 'displayImg',
            indexModale: index,
            modaleFiles: fileList,
        })
    }

    render() {
        const {
            classes,
            event,
            user,
            activityLoading,
            commentsLoading,
            linkedUsers,
        } = this.props
        const {
            showActivity,
            modaleFiles,
            openDisplayerImg,
            indexModale,
        } = this.state

        // Récupération de l'utilisateur qui a créé le bobevent/worktime/absence
        let linkedUser = null
        if (event.userLinkId) {
            linkedUser = linkedUsers.find(l => {
                return l.id === event.userLinkId
            })
        } else if (event?.createdBy) {
            linkedUser = linkedUsers.find(l => {
                return l?.user?.id === event?.createdBy
            })
        }

        const createdBy = linkedUser?.user
            ? linkedUser.user
            : linkedUser?.userSubscribe
            ? linkedUser.userSubscribe
            : null

        return (
            <div className={classes.tab}>
                <div className={classes.commentSection}>
                    {this._renderUserBadge({ user })}

                    <div className={classes.col}>
                        {this._renderCommentEditorSection()}
                    </div>
                </div>

                {activityLoading || commentsLoading ? (
                    <img
                        src={chargement}
                        alt="anchor"
                        margin="auto"
                        width="75"
                        height="75"
                        style={{
                            alignSelf: 'center',
                        }}
                    />
                ) : (
                    <div>
                        <Button
                            label={
                                showActivity
                                    ? this.displayText('hideDetails')
                                    : this.displayText('showDetails')
                            }
                            onClick={this.toggleDetails}
                            type="light"
                            classNameOverride={classes.toggleDetailsButton}
                        />
                        {this._renderActivityList(createdBy)}
                    </div>
                )}

                <BoatOnModal
                    openCondition={openDisplayerImg}
                    handleClose={this.handleCloseDisplayer}
                    modalCores={{
                        displayImg: (
                            <BoatOnModalCore
                                body={
                                    <BoatOnImageRender
                                        vertical
                                        multi
                                        bottomImage
                                        imgs={
                                            modaleFiles
                                                ? modaleFiles.slice()
                                                : []
                                        }
                                        index={indexModale}
                                    />
                                }
                            />
                        ),
                    }}
                    titles={{ displayImg: 'Mes documents' }}
                />
            </div>
        )
    }

    _renderCommentEditorSection() {
        const { classes, linkedUsers } = this.props
        const { selectedComment } = this.state

        const suggestions = linkedUsers.map(linkedUser => {
            if (linkedUser?.user) {
                return {
                    text: `${linkedUser.user.firstName} ${linkedUser.user.lastName}`,
                    value: `${linkedUser.user.firstName} ${linkedUser.user.lastName}`,
                }
            } else if (linkedUser?.userSubscribe) {
                return {
                    text: linkedUser.userSubscribe.mail,
                    value: linkedUser.userSubscribe.mail,
                }
            }
        })

        return (
            <>
                <Editor
                    editorClassName={classes.editor}
                    editorState={this.state.editorState}
                    localization={{
                        locale: this.context,
                    }}
                    toolbar={{
                        options: ['inline', 'list', 'link'],
                        inline: {
                            inDropdown: false,
                            options: ['bold', 'italic'],
                        },
                        list: {
                            inDropdown: false,
                            options: ['unordered', 'ordered'],
                        },
                    }}
                    mention={{
                        separator: '  ',
                        trigger: '@',
                        suggestions: suggestions,
                    }}
                    onEditorStateChange={this.onChangeComment}
                    wrapperClassName={classes.editorWrapper}
                    toolbarCustomButtons={[
                        <MentionCustomButton />,
                        <ImageUploadCustomButton />,
                    ]}
                    onBlur={() => fixIosHeaderBug()}
                />
                <input
                    onChange={e => {
                        this.addFileToComments(e)
                    }}
                    multiple
                    type="file"
                    id="fileComment"
                    ref="fileUploader"
                    style={{ display: 'none' }}
                />

                <DisplayerImgModal
                    file={getImageToDisplayForComments(
                        this.state.commentFiles,
                        this.state.displayNewFiles.comment,
                    )}
                    deleteDocumentNoUp={this.deleteDocument}
                    loadingImage={this.state.loadingImage}
                />

                <div className={classes.editorButtonsRow}>
                    <Button
                        type="primary"
                        onClick={this.submit}
                        label={
                            selectedComment
                                ? this.displayText('edit')
                                : this.displayText('save')
                        }
                    />
                    <Button
                        type="text"
                        label={this.displayText('cancel')}
                        style={{
                            color: 'black',
                        }}
                        onClick={() => {
                            // Vider le champ
                            this.setState({
                                selectedComment: null,
                                editorState: EditorState.createEmpty(),
                                commentFiles: [],
                                deletedFiles: {
                                    comment: [],
                                },
                                displayNewFiles: {
                                    comment: [],
                                },
                                fileNoUrl: {
                                    comment: [],
                                },
                            })
                        }}
                    />
                </div>
            </>
        )
    }

    _renderActivityList(createdBy) {
        const { classes, event, eventType } = this.props
        const { list, showActivity } = this.state

        const taskType =
            eventType === 'repair'
                ? 'task_created'
                : eventType === 'document'
                ? 'document_created'
                : eventType === 'transaction'
                ? 'transaction_created'
                : eventType === 'workTime'
                ? 'worktime_created'
                : eventType === 'leave'
                ? 'leave_created'
                : eventType === 'navigation'
                ? 'navigation_created'
                : eventType === 'order'
                ? 'order_created'
                : 'task_created'

        const creationType = [
            'CREATE_USER_ABSENCE',
            'CREATE_WORK_TIME',
            'CREATE_NAVIGATION',
            'CREATE_ORDER',
        ]

        return (
            <ul className={classes.activityList}>
                {list
                    .sort((a, b) => {
                        const isAPreferred = creationType.includes(
                            a.activityType?.type,
                        )
                        const isBPreferred = creationType.includes(
                            b.activityType?.type,
                        )

                        if (isAPreferred && !isBPreferred) return 1
                        else if (!isAPreferred && isBPreferred) return -1
                        else return 0
                    })
                    .map((listItem, index) => {
                        if (listItem.type === 'activity' && showActivity) {
                            return this._renderActivityLine(listItem, index)
                        } else if (listItem.type === 'comment') {
                            return this._renderCommentLine(listItem, index)
                        }
                    })}

                {createdBy &&
                    showActivity &&
                    event?.type !== 'workTime' &&
                    event?.type !== 'absence' && (
                        <li className={classes.activityListItem}>
                            {this._renderUserBadge({ user: createdBy })}
                            <div className={classes.col}>
                                <div
                                    className={classes.row}
                                    style={{ justifyContent: 'space-between' }}
                                >
                                    <div>
                                        {ReactHtmlParser(
                                            this._renderPhraseActivity(
                                                taskType,
                                                createdBy,
                                            ),
                                        )}
                                    </div>
                                    <Typography
                                        variant="body1"
                                        className={classes.date}
                                    >
                                        {getLongFormatedDateTimeUTC(
                                            new Date(event.timestamp),
                                            this.context,
                                        )}
                                    </Typography>
                                </div>
                            </div>
                        </li>
                    )}
            </ul>
        )
    }

    _renderActivityLine(listItem, index) {
        const { classes } = this.props
        const value = this._renderValueChange(listItem)

        if (!listItem?.activityType?.type) return <></>

        const string = this._renderPhraseActivity(
            listItem.activityType.type,
            listItem?.createdBy,
            listItem,
        )

        if (!string) return <></>

        return (
            <li className={classes.activityListItem} key={`index${index}`}>
                {/* Badge utilisateur */}
                {listItem.createdBy &&
                    this._renderUserBadge({ user: listItem.createdBy })}

                <div className={classes.col}>
                    <div
                        className={classes.row}
                        style={{ justifyContent: 'space-between' }}
                    >
                        <div>{ReactHtmlParser(string)}</div>
                        <Typography variant="body1" className={classes.date}>
                            {getLongFormatedDateTimeUTC(
                                new Date(listItem.timestamp),
                                this.context,
                            )}
                        </Typography>
                    </div>
                    {value && <div className={classes.row}>{value}</div>}
                </div>
            </li>
        )
    }

    onClickMention(id) {
        this.historyPush(
            history,
            AppRoute.LogBook.Settings.MyUsers,
            `?id=${id}`,
            {},
            {},
        )
    }

    updateMentions() {
        const { linkedUsers, subscriptions } = this.props
        const mentions = document.querySelectorAll('[data-mention]')

        mentions.forEach(mention => {
            const userLink = mention.getAttribute('data-value')
            const currentUser = linkedUsers.find(user => {
                if (user.user) {
                    const fullName = `${user.user.firstName} ${user.user.lastName}`
                    if (userLink === fullName) {
                        return true
                    }
                } else if (
                    user.userSubscribe &&
                    user.userSubscribe.mail === userLink
                ) {
                    return true
                }
                return false
            })

            mention.removeAttribute('href')
            if (
                currentUser &&
                userActions.checkSubscriptionPage(subscriptions, 7)
            ) {
                mention.classList.add('mention-clickable')
                mention.addEventListener('click', () =>
                    this.onClickMention(currentUser.id),
                )
            } else {
                mention.classList.add('mention-unclickable')
            }
        })
    }

    _renderCommentLine(listItem, index) {
        const { classes, user } = this.props

        return (
            <li className={classes.activityListItem} key={`index${index}`}>
                {/* Badge utilisateur */}
                {this._renderUserBadge({
                    user: listItem.createdBy,
                    showName: false,
                })}

                <div className={classes.col}>
                    {/* Nom de l'utilisateur + date */}
                    <div
                        className={classes.row}
                        style={{
                            justifyContent: 'space-between',
                        }}
                    >
                        {listItem.createdBy && (
                            <Typography variant="body1">
                                <b>
                                    {listItem.createdBy.firstName +
                                        ' ' +
                                        listItem.createdBy.lastName}
                                </b>
                            </Typography>
                        )}
                        <Typography variant="body1" className={classes.date}>
                            {getLongFormatedDateTimeUTC(
                                new Date(listItem.timestamp),
                                this.context,
                            )}
                        </Typography>
                    </div>

                    {/* Contenu du commentaire */}
                    <div className={classes.row}>
                        <Typography variant="body1" className={classes.comment}>
                            {ReactHtmlParser(listItem.message)}
                        </Typography>
                    </div>

                    {/* Fichiers du commentaire */}
                    <div className={classes.row}>
                        {listItem.files.map((file, i) => {
                            // On détermine si le fichier est un PDF
                            var pdf = false
                            if (file && file.link) {
                                let full = file.link.split('.')
                                if (full[full.length - 1] === 'pdf') {
                                    pdf = true
                                }
                            } else if (
                                file &&
                                file.infos &&
                                file.infos.type === 'application/pdf'
                            ) {
                                pdf = true
                            }

                            {
                                return pdf ? (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            this.handleOpenDisplayer(
                                                listItem.files.map(f => {
                                                    return {
                                                        ...f,
                                                        link:
                                                            API_URL +
                                                            '/files/' +
                                                            file.link,
                                                    }
                                                }),
                                                i,
                                            )
                                        }}
                                        className={classes.fileWrapper}
                                    >
                                        <PictureAsPdfIcon
                                            style={{
                                                width: 50,
                                                height: 50,
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <div
                                        key={index}
                                        onClick={() => {
                                            this.handleOpenDisplayer(
                                                listItem.files.map(f => {
                                                    return {
                                                        ...f,
                                                        link:
                                                            API_URL +
                                                            '/files/' +
                                                            file.link,
                                                    }
                                                }),
                                                i,
                                            )
                                        }}
                                        className={classes.fileWrapper}
                                    >
                                        <img
                                            src={
                                                API_URL + '/files/' + file.link
                                            }
                                            alt=""
                                            style={{
                                                width: 50,
                                                height: 50,
                                            }}
                                        />
                                    </div>
                                )
                            }
                        })}
                    </div>
                    {listItem?.createdBy?.email === user.email && (
                        <div className={classes.commentActionsRow}>
                            <span
                                onClick={() =>
                                    this.modifierCommentaire(listItem)
                                }
                            >
                                {this.displayText('edit')}
                            </span>
                            <span
                                onClick={() =>
                                    this.supprimerCommentaire(listItem)
                                }
                            >
                                {this.displayText('delete')}
                            </span>
                        </div>
                    )}
                </div>
            </li>
        )
    }

    /**
     * Renvoie le badge utilisateur contenant ses initiales, ou l'icone par défaut si c'est un userSubscribe
     */
    _renderUserBadge({ user, showName = false }) {
        const { classes } = this.props

        return (
            <>
                <div className={classes.userBadge}>
                    {user?.firstName ? (
                        `${user.firstName[0]}${user.lastName[0]}`
                    ) : (
                        <PersonIcon className={classes.userBadgeIcon} />
                    )}
                </div>
                {showName && user?.firstName && user?.lastName
                    ? `${user.firstName} ${user.lastName}`
                    : user?.mail}
            </>
        )
    }

    _renderPhraseActivity(type, user, listItem) {
        let t = this.displayText(type)

        if (!t) {
            return ''
        }

        // Fonction de surcharge pour les messages
        // Car les messages sont différents selon la valeur de l'item
        if (
            listItem &&
            this.dictionary[listItem.activityType.type].messageOverrideFunction
        ) {
            t =
                this.displayText(
                    this.dictionary[
                        listItem.activityType.type
                    ].messageOverrideFunction(listItem),
                ) ?? this.displayText(type)
        }

        // Remplace le nom de l'utilisateur
        t = t.replace('$user', `<b>${user?.firstName} ${user?.lastName}</b>`)

        const boldRegexp = new RegExp(/\*\*([^*]*)\*\*/, 'g')
        t = t.replaceAll(boldRegexp, (a, b) => {
            return '<b>' + b + '</b>'
        })

        return t
    }

    _renderValueChange(activity) {
        const { classes } = this.props

        if (!activity?.activityType?.type) return <></>

        const activityType = activity.activityType.type
        const dataType =
            this.dictionary?.[activityType]?.dataType || activityDataTypes.TEXT
        const renderType =
            this.dictionary?.[activityType]?.renderType ||
            activityRenderTypes.TEXT

        let value = activity.newTranslation
            ? this.displayTextApi(activity.newTranslation)
            : activity.newValue
        let oldValue = activity.oldTranslation
            ? this.displayTextApi(activity.oldTranslation)
            : activity.oldValue

        // Traitement particulier pour les utilisteurs
        if (dataType === activityDataTypes.USER) {
            const userId = parseInt(activity.newValue)
            const linkedUser = this.props.linkedUsers.find(
                l =>
                    (l?.userSubscribe?.id === userId &&
                        activityType.includes('USER_SUBSCRIBE')) ||
                    (l?.user?.id === userId &&
                        !activityType.includes('USER_SUBSCRIBE')),
            )

            const addedUser = linkedUser?.user || linkedUser?.userSubscribe

            value = [addedUser]

            // Utilisateur supprimé
            if (
                activityType === 'DELETE_USER_FROM_TASK' ||
                activityType === 'DELETE_USER_RTQ_FROM_TASK' ||
                activityType === 'DELETE_USER_SUBSCRIBE_RTQ_FROM_TASK' ||
                activityType === 'DELETE_USER_SUBSCRIBE_FROM_TASK'
            ) {
                // Récupération de l'utilisateur supprimé par son ID
                const deletedUserId = parseInt(activity.oldValue)

                const linkedUserDelete = this.props.linkedUsers.find(l => {
                    return (
                        l?.userSubscribe?.id === deletedUserId ||
                        l?.user?.id === deletedUserId
                    )
                })
                const deletedUser =
                    linkedUserDelete?.user || linkedUserDelete?.userSubscribe

                value = [deletedUser]
                oldValue = [deletedUser]
            }
        }

        if (activity.activityType.isEdited) {
            return (
                <ExpendableRow
                    className={classes.activityRow}
                    style={{ gap: 10 }}
                >
                    <div
                        className={`${classes.activityCol} ${classes.activityRemovedValue}`}
                    >
                        {this._renderValueByType(
                            oldValue,
                            renderType,
                            activity,
                        )}
                    </div>
                    <div
                        className={classes.activityCol}
                        style={{ alignSelf: 'center' }}
                    >
                        {'→'}
                    </div>
                    <div className={classes.activityCol}>
                        {this._renderValueByType(value, renderType, activity)}
                    </div>
                </ExpendableRow>
            )
        } else if (activity.activityType.isAdded) {
            return (
                <ExpendableRow className={classes.activityRow}>
                    {this._renderValueByType(value, renderType, activity)}
                </ExpendableRow>
            )
        } else if (activity.activityType.isDeleted) {
            return (
                <ExpendableRow
                    className={`${classes.activityRow} ${classes.activityRemovedValue}`}
                >
                    {this._renderValueByType(oldValue, renderType, activity)}
                </ExpendableRow>
            )
        }
    }

    _renderValueByType(value, renderType, activity) {
        const { classes } = this.props
        const activityType = activity.activityType.type
        const renderValue = this.dictionary?.[activityType]?.renderValue

        if (renderValue) {
            return (
                <Typography variant="body1" className={classes.textValue}>
                    {renderValue(this, value, activity)}
                </Typography>
            )
        }

        switch (renderType) {
            case activityRenderTypes.BADGE:
                // Si l'activité est un changement de status d'absence
                // On affiche un badge avec la couleur correspondante
                let backgroundColor = 'rgb(252, 212, 142)'
                let textColor = 'black'
                let border = '1px solid rgba(0, 0, 0, 0.23)'
                // Workaround
                if (activity.activityType.subVarKey === 'absenceStatus') {
                    border = 'none'
                    if (
                        value.toLowerCase() === 'accepted' ||
                        value.toLowerCase() === 'accepté'
                    ) {
                        backgroundColor = '#6BC070'
                        textColor = 'white'
                    } else if (
                        value.toLowerCase() === 'refused' ||
                        value.toLowerCase() === 'refusé'
                    ) {
                        backgroundColor = 'red'
                        textColor = 'white'
                    }
                }

                return (
                    <div
                        className={classes.badge}
                        style={{
                            background: backgroundColor,
                            color: textColor,
                            borderRadius: 16,
                            border: border,
                        }}
                    >
                        {value}
                    </div>
                )
            case activityRenderTypes.DATE:
                return (
                    <Typography variant="body1">
                        {getSimpleFormatedDate(new Date(value), this.context)}
                    </Typography>
                )
            case activityRenderTypes.DATETIME:
                return (
                    <Typography variant="body1">
                        {getSimpleFormatedDateTimeUTC(
                            new Date(value),
                            this.context,
                        )}
                    </Typography>
                )
            case activityRenderTypes.USER:
                return value.map((user, index) => {
                    return (
                        <div
                            className={classes.badgeContainer}
                            key={`user${index}`}
                        >
                            {this._renderUserBadge({
                                user: user,
                                showName: true,
                            })}
                        </div>
                    )
                })
            default:
                return (
                    <Typography variant="body1" className={classes.textValue}>
                        {value?.toString()}
                    </Typography>
                )
        }
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        comments: state.comments.comments,
        activityLoading: state.activity.loading,
        commentsLoading: state.comments.loading,
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
        events: state.bob.events,
        subscriptions: state.group?.groupsMembers?.user?.sub || null,
        orderStatusTypes: state.types.orderStatusTypes,
    }
}

const useStyles = makeStyles({
    buttonWrapper: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 6,
    },
    button: {
        border: '1px solid #F1F1F1',
        padding: 5,
        minWidth: 25,
        height: 20,
        borderRadius: 2,
        margin: '0px 4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        background: 'white',
        textTransform: 'capitalize',
        '&:hover': {
            boxShadow: '1px 1px 0px #BFBDBD',
            cursor: 'pointer',
        },
    },
    icon: {
        height: '20px !important',
        width: '20px !important',
    },
})

function MentionCustomButton({ editorState, onChange }) {
    // static propTypes = {
    //   onChange: PropTypes.func,
    //   editorState: PropTypes.object,
    // };

    const classes = useStyles()

    function addMention() {
        const contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            '@',
            editorState.getCurrentInlineStyle(),
        )
        onChange(
            EditorState.push(editorState, contentState, 'insert-characters'),
        )
    }

    return (
        <div className={classes.buttonWrapper}>
            <div className={classes.button} onClick={addMention}>
                <AlternateEmailIcon className={classes.icon} />
            </div>
        </div>
    )
}

function ImageUploadCustomButton() {
    const classes = useStyles()
    return (
        <div className={classes.buttonWrapper}>
            <div
                className={classes.button}
                onClick={() => document.querySelector('#fileComment').click()}
            >
                <ImageIcon className={classes.icon} />
            </div>
        </div>
    )
}

export default connect(mapStateToProps)(withStyles(styles)(ActivityComponent))
