import { getBOBBoat } from './bob/boat.actions'
import {
    bobEventsConstants,
    boatConstants,
    absenceConstants,
} from '../constants/'
import { requestApi } from '../services/api.service'
import {
    postDataBOBDocuments,
    removeDataBOBDocuments,
    duplicateFile,
} from './bob/documents.actions'
import {
    getBoatIdToShow,
    selectAvailableBoat,
    boatIsInMyFloat,
} from './bob.actions'
import { typesActions } from './types.actions'
import { absencesActions } from './bob/absences.actions'

export * from './bob/boat.actions'
export * from './bob/documents.actions'
export * from './bob/spendings.actions'
export * from './bob/checkups.actions'
export * from './bob/repairs.actions'
export * from './bob/details.actions'
export * from './bob/navigation.actions'

export const resetDataBOB = () => {
    return dispatch => {
        dispatch({ type: bobEventsConstants.RESET })
    }
}

export const setNewEvent = newEvent => {
    return dispatch => {
        dispatch({
            type: bobEventsConstants.BOBEVENTS_SETNEWEVENT,
            newEvent: newEvent,
        })
    }
}

export const unSetNewEvent = () => {
    return dispatch => {
        dispatch({
            type: bobEventsConstants.BOBEVENTS_UNSETNEWEVENT,
        })
    }
}

export const fetchDataBOB = (boatId = null) => {
    return dispatch => {
        if (boatId && boatId > 0) {
            dispatch(getBOBBoat(boatId))
            dispatch(requestBobEvents(boatId))
        } else {
            dispatch({ type: boatConstants.NO_BOAT })
        }
    }
}

const _getTypesForTheBoat = boatId => {
    return dispatch => {
        if (boatId) {
            dispatch(typesActions.requestUserCheckups(boatId || null))
            dispatch(typesActions.requestUserEquipmentFamilies(boatId || null))
            dispatch(typesActions.requestUserEquipments(boatId || null))
            dispatch(typesActions.requestUserParts(boatId || null))
        }
    }
}

export const initBobWithBoat = ({
    user = null,
    boatId = null,
    currentGroupId = null,
    onlyMyFloat = false,
    changeBoat = false,
}) => {
    const localBoatId = localStorage.getItem('boatIdSelect')

    if (
        (localBoatId && localBoatId > 0 && !changeBoat && !onlyMyFloat) ||
        (onlyMyFloat && user && boatIsInMyFloat(boatId, user))
    ) {
        boatId = localBoatId
    }

    if (changeBoat === false && boatId === null && !user)
        return dispatch => {
            dispatch({
                type: bobEventsConstants.BOBEVENTS_FAILURE,
                error: 'loading boat without id',
            })
        }
    else if (changeBoat === true && boatId === null && !user)
        return dispatch => {
            dispatch({
                type: bobEventsConstants.BOBEVENTS_FAILURE,
                error: 'updating boatId with null',
            })
        }
    else if (changeBoat === true && boatId === null && !user)
        return dispatch => {
            dispatch({
                type: bobEventsConstants.BOBEVENTS_FAILURE,
                error: 'updating boatId with null',
            })
        }

    if (!boatId && !user)
        return dispatch => {
            dispatch({
                type: bobEventsConstants.BOBEVENTS_FAILURE,
                error: 'user not loaded and boatId not set',
            })
        }

    if (
        (!boatId && !onlyMyFloat) ||
        (onlyMyFloat && currentGroupId === user?.ownGroup?.id)
    ) {
        // if no boatId, find boat from currentGroup
        boatId = getBoatIdToShow(user, currentGroupId)
    }

    if (!boatId) {
        // if still no boatId, select an available boat in all the list
        return dispatch => {
            boatId = dispatch(selectAvailableBoat(user, onlyMyFloat))
            if (boatId && boatId > 0) {
                // init bob
                dispatch(getBOBBoat(boatId))
                dispatch(_getTypesForTheBoat(boatId))
                dispatch(requestBobEvents(boatId))
            } else {
                dispatch({
                    type: bobEventsConstants.RESET,
                })
                dispatch({ type: boatConstants.NO_BOAT })
            }
        }
    } else {
        return dispatch => {
            if (boatId && boatId > 0) {
                // init bob
                dispatch(getBOBBoat(boatId))
                dispatch(_getTypesForTheBoat(boatId))
                dispatch(requestBobEvents(boatId))
            } else {
                dispatch({
                    type: bobEventsConstants.RESET,
                })
                dispatch({ type: boatConstants.NO_BOAT })
            }
        }
    }
}

export const getBobEvent = (boatId = null, fetchCallback = json => {}) => {
    if (!boatId) return null

    return requestApi('/bob-events/boat/' + boatId, 'GET').then(
        result => {
            return result
        },
        error => {
            console.error(error)
        },
    )
}

export const requestBobEvents = boatId => {
    return dispatch => {
        dispatch({ type: bobEventsConstants.REQUEST_BOBEVENTS })
        return requestApi('/bob-events/boat/' + boatId, 'GET').then(
            result => {
                dispatch({
                    type: bobEventsConstants.BOBEVENTS_SUCCESS,
                    events: result,
                })
            },
            error => {
                dispatch({ type: bobEventsConstants.BOBEVENTS_FAILURE, error })
            },
        )
    }
}

const removeField = obj => {
    if (Array.isArray(obj)) {
        return obj
            .map(v => (v && typeof v === 'object' ? removeField(v) : v))
            .filter(v => !(v === null))
    } else {
        return Object.entries(obj)
            .map(([k, v]) => [
                k,
                v && typeof v === 'object' ? removeField(v) : v,
            ])
            .reduce((a, [k, v]) => (v === null ? a : ((a[k] = v), a)), {})
    }
}

export const postAbsence = ({ body, filesToUpload }, language) => {
    return async dispatch => {
        dispatch({ type: absenceConstants.POST_ABSENCE })

        if (filesToUpload) {
            body.files = await postDataBOBDocuments(filesToUpload, 1)
        }

        return requestApi(
            `/user-absences`,
            'POST',
            body,
            false,
            true,
            language,
        ).then(
            async result => {
                await dispatch({
                    type: absenceConstants.POST_ABSENCE_SUCCESS,
                    events: result,
                })
                // dispatch(absencesActions.requestUserAbsences(body.userLinkId))
                // callback()
                return result[0]
            },
            error => {
                console.error(error)
                dispatch({ type: absenceConstants.POST_ABSENCE_ERROR, error })
            },
        )
    }
}

export const deleteAbsence = ({ absenceId, currentUserLink }) => {
    return dispatch => {
        dispatch({ type: absenceConstants.DELETE_ABSENCE })

        return requestApi(`/user-absences/${absenceId}`, `DELETE`)
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    dispatch({
                        type: absenceConstants.DELETE_ABSENCE_SUCCESS,
                    })
                    dispatch(
                        absencesActions.requestUserAbsences(currentUserLink.id),
                    )
                    if (currentUserLink?.id) {
                        dispatch(
                            absencesActions.requestUserAbsencesByManager(
                                currentUserLink.id,
                            ),
                        )

                        // récupération du total des jours de congés posés pour mettre à jour les compteurs
                        dispatch(
                            absencesActions.getTotalAbsenceForUserLink(
                                currentUserLink.id,
                            ),
                        )
                    }
                },
                error => {
                    dispatch({
                        type: absenceConstants.DELETE_ABSENCE_FAILURE,
                    })
                },
            )
            .catch(error => {
                dispatch({
                    type: absenceConstants.DELETE_ABSENCE_FAILURE,
                })
            })
    }
}

export const putAbsence = (
    { id, body, filesToUpload, filesToDelete },
    callback,
    language,
) => {
    return async dispatch => {
        dispatch({ type: absenceConstants.PUT_ABSENCE })

        // Upload les nouveaux fichiers
        if (filesToUpload && filesToUpload.length > 0) {
            body.files = await postDataBOBDocuments(filesToUpload, 1)
        }

        // Supprime du back les fichiers supprimés
        if (
            filesToDelete &&
            Array.isArray(filesToDelete) &&
            filesToDelete.length > 0
        ) {
            await removeDataBOBDocuments(filesToDelete)
        }

        return requestApi(
            `/user-absences/${id}`,
            'PUT',
            body,
            false,
            true,
            language,
        ).then(
            async result => {
                await dispatch({
                    type: absenceConstants.PUT_ABSENCE_SUCCESS,
                    events: result,
                })

                callback()
                return result[0]
            },
            error => {
                console.error(error)
                dispatch({ type: absenceConstants.PUT_ABSENCE_ERROR, error })
                callback()
            },
        )
    }
}

export const postBOBEvents = (
    boatId,
    eventTypeId,
    title,
    {
        files,
        duplicateFiles,
        detail,
        transaction,
        delimitedDate,
        description,
        checkup,
        inventory,
        actualDuration,
        attachedTo,
        protocol,
        expirationDate,
        capacity,
        callback = json => {},
    },
    language = null,
) => {
    return async dispatch => {
        dispatch({ type: bobEventsConstants.POST_BOBEVENTS })
        let body = {
            title: setTitle(title, checkup, detail),
            description: description || null,
        }
        if (expirationDate) {
            body.expirationDate = expirationDate
        }

        if (files) {
            body.files = await postDataBOBDocuments(files, 1)
        }
        if (duplicateFiles) {
            body.files = [
                ...body.files,
                ...(await duplicateFile(duplicateFiles.map(file => file.id))),
            ]
        }
        if (detail) {
            body.detail = detail
        }
        if (checkup) body.checkup = checkup
        if (transaction && transaction.amountIt !== '') {
            body.transaction = {
                ...transaction,
                amountIt:
                    transaction.amountIt && transaction.amountIt.toString(),
                amountEt:
                    transaction.amountEt && transaction.amountEt.toString(),
            }
        } else if (transaction && transaction.amountIt === '') {
            body.transaction = null
        }
        if (actualDuration !== null) {
            body.actualDuration = actualDuration
        }
        if (delimitedDate) body.delimitedDate = delimitedDate
        if (attachedTo) {
            body.attachedTo = attachedTo || []
            body.language = language
        }
        if (protocol) body.protocol = protocol
        if (capacity !== null) body.capacity = capacity

        body = removeField(body)
        return requestApi(
            `/bob-events/boat/${boatId}/eventType/${eventTypeId}`,
            'POST',
            body,
        ).then(
            result => {
                dispatch({
                    type: bobEventsConstants.POST_BOBEVENTS_SUCCESS,
                    events: result,
                })
                callback()
                // Update all types so we dont miss one created type with this event
                dispatch(typesActions.requestUserCheckups(boatId))
                dispatch(typesActions.requestUserEquipmentFamilies(boatId))
                dispatch(typesActions.requestUserEquipments(boatId))
                dispatch(typesActions.requestUserParts(boatId))
                return result[0]
            },
            error => {
                dispatch({ type: bobEventsConstants.BOBEVENTS_FAILURE, error })
            },
        )
    }
}

const setTitle = (title, checkup, detail) => {
    let titleToSet = title || 'title'
    if (checkup) {
        if (checkup.checkupType && checkup.checkupType.translation) {
            titleToSet = checkup.checkupType.translation.fr
        } else if (checkup && checkup.userCheckup && checkup.userCheckup.name) {
            titleToSet = checkup.userCheckup.name
        }
    } else if (detail) {
        if (
            detail.equipment &&
            detail.equipment.equipmentType &&
            detail.equipment.equipmentType?.translation?.fr
        ) {
            titleToSet = detail.equipment.equipmentType.translation.fr
        } else if (
            detail.equipment &&
            detail.equipment.userEquipment &&
            detail.equipment.userEquipment.name
        ) {
            titleToSet = detail.equipment.userEquipment.name
        } else if (
            detail.part &&
            detail.part.partType &&
            detail.part.partType?.translation?.fr
        ) {
            titleToSet = detail.part.partType.translation.fr
        } else if (
            detail.part &&
            detail.part.userPart &&
            detail.part.userPart.name
        ) {
            titleToSet = detail.part.userPart.name
        }
    }
    return titleToSet
}

export const putBOBEvents = (
    eventId,
    title,
    {
        newFiles,
        files,
        checkup,
        transaction,
        delimitedDate,
        description,
        eventTypeId,
        deletedFiles,
        detail,
        actualDuration,
        attachedTo,
        protocol,
        expirationDate,
        capacity,
        callback = json => {},
    },
    language = null,
) => {
    return async dispatch => {
        dispatch({ type: bobEventsConstants.POST_BOBEVENTS })
        let body = {}
        body = {
            title: setTitle(title, checkup, detail),
        }
        if (description) body.description = description
        if (protocol) body.protocol = protocol
        if (eventTypeId) body.eventType = { id: eventTypeId }
        if (expirationDate) body.expirationDate = expirationDate
        if (
            deletedFiles &&
            Array.isArray(deletedFiles) &&
            deletedFiles.length > 0
        ) {
            await removeDataBOBDocuments(deletedFiles)
        }
        if (detail) {
            body.detail = detail
        }
        if (newFiles) {
            body.files = [
                ...(await postDataBOBDocuments(newFiles, 1)),
                ...files,
            ]
        }
        if (actualDuration !== null) {
            body.actualDuration = actualDuration
        }
        if (detail) {
            body.detail = detail
            if (body.detail.buyDate === '') body.detail.buyDate = null
        }
        if (language) {
            body.language = language
        }
        if (checkup) body.checkup = checkup
        if (transaction) {
            if (transaction.amountIt === null || transaction.amountIt === '') {
                body.transaction = null
            } else {
                body.transaction = {
                    ...transaction,
                    amountIt:
                        transaction.amountIt && transaction.amountIt.toString(),
                    amountEt:
                        transaction.amountEt && transaction.amountEt.toString(),
                }
            }
        }
        if (delimitedDate) {
            body.delimitedDate = delimitedDate
            if (body.delimitedDate.startDate === '')
                body.delimitedDate.startDate = null
            if (body.delimitedDate.endDate === '')
                body.delimitedDate.endDate = null
        }
        if (attachedTo) body.attachedTo = attachedTo || []
        if (capacity !== null) body.capacity = capacity

        if (body.detail?.optionnalDetail?.capacity)
            body.detail.optionnalDetail.capacity = `${body.detail.optionnalDetail.capacity}`
        return requestApi(`/bob-events/${eventId}`, 'PUT', body).then(
            result => {
                dispatch({
                    type: bobEventsConstants.PUT_BOBEVENTS_SUCCESS,
                    events: result,
                })
                callback()
                const boatId = localStorage.getItem('boatIdSelect')
                // Update all types so we dont miss one created type with this event
                dispatch(typesActions.requestUserCheckups(boatId))
                dispatch(typesActions.requestUserEquipmentFamilies(boatId))
                dispatch(typesActions.requestUserEquipments(boatId))
                dispatch(typesActions.requestUserParts(boatId))
                return result[0]
            },
            error => {
                dispatch({ type: bobEventsConstants.BOBEVENTS_FAILURE, error })
            },
        )
    }
}

const setCheckup = (checkup, prevCheckup) => {
    if (!checkup) checkup = prevCheckup
    else {
        checkup = {
            ...prevCheckup,
            ...checkup,
        }
    }

    return checkup
}

const _setDetail = (detail, prevDetail, optionnalDetail) => {
    if (!detail) detail = prevDetail
    else {
        detail = {
            ...prevDetail,
            ...detail,
        }
    }
    if (optionnalDetail && detail) {
        detail = {
            ...detail,
            optionnalDetail: {
                ...prevDetail.optionnalDetail,
                ...optionnalDetail,
            },
        }
    }
    return detail
}

export const getNbMonthToAdd = checkup => {
    if (checkup && checkup.checkupType && checkup.checkupType.cycles)
        return checkup.checkupType.cycles.find(
            cycle => cycle.cycleType && cycle.cycleType.id === 1,
        )
    if (checkup && checkup.userCheckup && checkup.userCheckup.cycles)
        return checkup.userCheckup.cycles.find(
            cycle => cycle.cycleType && cycle.cycleType.id === 1,
        )
    return null
}

export const updateStateEvent = (
    props,
    {
        transaction = null,
        delimitedDate = null,
        title = null,
        description = null,
        files = null,
        eventTypeId = null,
        detail = null,
        optionnalDetail = null,
        checkup = null,
        inventory = null,
        protocol = null,
        actualDuration = undefined,
        name = 'event',
        negativeTransaction = false,
        expirationDate = null,
        capacity = null,
    },
) => {
    let negReg = new RegExp('^(-|[0-9.,])+$|^$')
    let reg = new RegExp('^[0-9.,]+$|^$')

    if (
        transaction &&
        transaction.amountIt &&
        ((!negativeTransaction && !reg.test(transaction.amountIt)) ||
            (negativeTransaction && !negReg.test(transaction.amountIt)))
    ) {
        transaction = null
    }
    props.setState(prevState => ({
        [name]: {
            ...prevState[name],
            actualDuration:
                actualDuration === undefined || isNaN(actualDuration)
                    ? prevState[name].actualDuration
                    : actualDuration,
            transaction: transaction
                ? { ...prevState[name].transaction, ...transaction }
                : prevState[name].transaction,
            delimitedDate: delimitedDate
                ? { ...prevState[name].delimitedDate, ...delimitedDate }
                : prevState[name].delimitedDate,
            title: title !== null ? title : prevState[name].title,
            description:
                description === null
                    ? prevState[name].description
                    : description,
            files: files || prevState[name].files,
            eventType: eventTypeId
                ? { id: eventTypeId }
                : prevState[name].eventType,
            checkup: setCheckup(checkup, prevState[name].checkup),
            detail: _setDetail(detail, prevState[name].detail, optionnalDetail),
            protocol: protocol !== null ? protocol : prevState[name].protocol,
            expirationDate:
                expirationDate !== null
                    ? expirationDate
                    : prevState[name].expirationDate,
            capacity: capacity !== null ? capacity : prevState[name].capacity,
        },
        inventory: inventory
            ? {
                  ...prevState.inventory,
                  equipmentFamily:
                      inventory.equipmentFamily ||
                      prevState.inventory.equipmentFamily,
                  equipments:
                      inventory.equipments || prevState.inventory.equipments,
                  parts: inventory.parts || prevState.inventory.parts,
              }
            : prevState.inventory,
    }))
}

export const setBOBStateEvent = (
    props,
    name = 'event',
    callback = () => {},
) => {
    props.setState(
        {
            [name]: {
                transaction: null,
                actualDuration: null,
                delimitedDate: {},
                title: '',
                description: '',
                protocol: '',
                files: [],
                eventType: {},
                detail: {},
                checkup: {},
            },
            deletedFiles: {
                ...props.state.deletedFiles,
                [name]: [],
            },
            fileNoUrl: {
                ...props.state.fileNoUrl,
                [name]: [],
            },
            displayNewFiles: {
                ...props.state.displayNewFiles,
                [name]: [],
            },
            inventory: { equipmentFamily: {}, equipment: {}, parts: [] },
        },
        () => callback(),
    )
}

export const copyBOBStateEvent = (props, { name = 'event', event = {} }) => {
    return props.setState({
        [name]: {
            transaction: null,
            actualDuration: null,
            delimitedDate: {},
            title: event.title || '',
            description: '',
            files: [],
            eventType: event.eventType || {},
            detail: event.detail
                ? {
                      part: event.detail.part,
                      equipment: event.detail.equipment,
                  }
                : {},
            checkup: event.checkup || {},
            protocol: event.protocol || '',
        },
        deletedFiles: {
            ...props.state.deletedFiles,
            [name]: [],
        },
        fileNoUrl: {
            ...props.state.fileNoUrl,
            [name]: [],
        },
        displayNewFiles: {
            ...props.state.displayNewFiles,
            [name]: [],
        },
    })
}

export const removeFilesFromBOBEvent = (
    bobEventId,
    handleAlert = json => {},
) => {
    return dispatch => {
        dispatch({ type: bobEventsConstants.PUT_BOBEVENTS })
        return requestApi(`/bob-events/${bobEventId}/files`, 'DELETE').then(
            result => {
                dispatch({
                    type: bobEventsConstants.DELETE_BOBEVENTS_SUCCESS,
                    eventUpdatedIds: [bobEventId],
                    eventDeletedIds: result,
                    deleteType: 'files',
                })
            },
            error => {
                dispatch({ type: bobEventsConstants.BOBEVENTS_FAILURE, error })
            },
        )
    }
}

export const removeTransactionFromBOBEvent = (
    bobEventId,
    handleAlert = json => {},
) => {
    return dispatch => {
        dispatch({ type: bobEventsConstants.PUT_BOBEVENTS })
        return requestApi(
            `/bob-events/${bobEventId}/transaction`,
            'DELETE',
        ).then(
            result => {
                dispatch({
                    type: bobEventsConstants.DELETE_BOBEVENTS_SUCCESS,
                    eventUpdatedIds: [bobEventId],
                    eventDeletedIds: result,
                    deleteType: 'transaction',
                })
            },
            error => {
                dispatch({ type: bobEventsConstants.BOBEVENTS_FAILURE, error })
            },
        )
    }
}

export const removeCheckupFromBOBEvent = (
    bobEventId,
    handleAlert = json => {},
) => {
    return dispatch => {
        dispatch({ type: bobEventsConstants.PUT_BOBEVENTS })
        return requestApi(`/bob-events/${bobEventId}/checkup`, 'DELETE').then(
            result => {
                dispatch({
                    type: bobEventsConstants.DELETE_BOBEVENTS_SUCCESS,
                    eventUpdatedIds: [bobEventId],
                    eventDeletedIds: result,
                    deleteType: 'checkup',
                })
            },
            error => {
                dispatch({ type: bobEventsConstants.BOBEVENTS_FAILURE, error })
            },
        )
    }
}

export const removeDetailFromBOBEvent = (bobEventId, callback = () => {}) => {
    return dispatch => {
        dispatch({ type: bobEventsConstants.PUT_BOBEVENTS })
        return requestApi(`/bob-events/${bobEventId}/detail`, 'DELETE').then(
            result => {
                dispatch({
                    type: bobEventsConstants.DELETE_BOBEVENTS_SUCCESS,
                    eventUpdatedIds: [bobEventId],
                    eventDeletedIds: result,
                    deleteType: 'detail',
                })
                callback()
                return result
            },
            error => {
                dispatch({ type: bobEventsConstants.BOBEVENTS_FAILURE, error })
            },
        )
    }
}

export const removeAllDetailsFromAnEvent = (
    bobEventId,
    callback = () => {},
) => {
    return dispatch => {
        dispatch({ type: bobEventsConstants.PUT_BOBEVENTS })
        return requestApi(
            `/bob-events/${bobEventId}/allDetails`,
            'DELETE',
        ).then(
            result => {
                dispatch({
                    type: bobEventsConstants.DELETE_BOBEVENTS_SUCCESS,
                    eventUpdatedIds: result,
                    deleteType: 'detail',
                })
                callback()
                return result
            },
            error => {
                dispatch({ type: bobEventsConstants.BOBEVENTS_FAILURE, error })
            },
        )
    }
}

export const removeDelimitedDateFromBOBEvent = (
    bobEventId,
    callback = () => {},
) => {
    return dispatch => {
        dispatch({ type: bobEventsConstants.PUT_BOBEVENTS })
        return requestApi(
            `/bob-events/${bobEventId}/delimitedDate`,
            'DELETE',
        ).then(
            result => {
                dispatch({
                    type: bobEventsConstants.DELETE_BOBEVENTS_SUCCESS,
                    eventUpdatedIds: [bobEventId],
                    eventDeletedIds: result,
                    deleteType: 'delimitedDate',
                })
                callback()
                return result
            },
            error => {
                dispatch({ type: bobEventsConstants.BOBEVENTS_FAILURE, error })
            },
        )
    }
}

export const getNextEvent = (events, event, asc = false) => {
    if (!events) return null
    if (asc) events = events.slice().reverse()
    let finded = null
    if (events) {
        finded = events.find(
            e =>
                e.checkup &&
                event.checkup &&
                e.checkup.id === event.checkup.id &&
                event.id !== e.id &&
                event.delimitedDate &&
                e.delimitedDate &&
                event.delimitedDate.startDate &&
                e.delimitedDate.startDate &&
                ((!asc &&
                    new Date(event.delimitedDate.startDate).getTime() <
                        new Date(e.delimitedDate.startDate).getTime()) ||
                    (asc &&
                        new Date(event.delimitedDate.startDate).getTime() >
                            new Date(e.delimitedDate.startDate).getTime())),
        )
    }
    return finded
}

export const getTotalHourPrev = (events, event) => {
    if (!events) return null
    let total = 0
    events = events.slice().reverse()
    events.forEach(e => {
        if (
            e.checkup &&
            event.checkup &&
            e.checkup.id === event.checkup.id &&
            event.id !== e.id &&
            event.delimitedDate &&
            e.delimitedDate &&
            event.delimitedDate.startDate &&
            e.delimitedDate.startDate &&
            new Date(event.delimitedDate.startDate).getTime() >
                new Date(e.delimitedDate.startDate).getTime() &&
            e.actualDuration &&
            e.delimitedDate.endDate
        ) {
            total = total + e.actualDuration
        }
    })
    return total
}

export const getHoursOfLastCheckup = (events, event) => {
    if (!events) return null
    let total = 0

    events = events.slice().reverse()

    for (const e of events) {
        if (
            e.checkup &&
            event.checkup &&
            (e.checkup.id === event.checkup.id ||
                (e.checkup.userCheckup &&
                    event.checkup.userCheckup &&
                    e.checkup.userCheckup.id ===
                        event.checkup.userCheckup.id) ||
                (e.checkup.checkupType &&
                    event.checkup.checkupType &&
                    e.checkup.checkupType.id ===
                        event.checkup.checkupType.id)) &&
            e.actualDuration &&
            e?.delimitedDate?.endDate &&
            e.actualDuration > total &&
            e.actualDuration < event.actualDuration
        ) {
            total = e.actualDuration
        }
    }
    return total
}

export const getElementWithDetails = (events, option) => {
    if ((option?.equipmentType?.id || option?.userEquipment?.id) && events)
        return events.find(
            event =>
                event?.detail?.equipment?.id === option.id &&
                event?.detail?.archived === null &&
                event?.delimitedDate?.startDate,
        )
    else if ((option?.partType?.id || option?.userPart?.id) && events)
        return events.find(
            event =>
                event?.detail?.part?.id === option.id &&
                event?.detail?.archived === null &&
                event?.delimitedDate?.startDate,
        )
    return false
}

export const getCheckupEquipment = event => {
    if (event.checkup) {
        const { checkup } = event
        if (
            checkup.equipments &&
            checkup.equipments.length > 0 &&
            checkup.equipments[0]
        ) {
            return checkup.equipments[0]
        } else if (
            checkup.parts &&
            checkup.parts.length > 0 &&
            checkup.parts[0].equipment
        ) {
            return checkup.parts[0].equipment
        }
    }
    return null
}

export const getNextEventAfterToday = (events, checkupId) => {
    const today = new Date().getTime()
    let finded = null
    if (events) {
        finded = events.find(
            e =>
                e.checkup &&
                e.checkup.id === checkupId &&
                e.delimitedDate &&
                e.delimitedDate.startDate &&
                today < new Date(e.delimitedDate.startDate).getTime(),
        )
    }
    return finded
}

export const getLastEvent = (events, checkupId) => {
    const today = new Date().getTime()
    let finded = null
    if (events) {
        finded = events.find(
            e =>
                e.checkup &&
                e.checkup.id === checkupId &&
                e.delimitedDate &&
                e.delimitedDate.startDate &&
                today >= new Date(e.delimitedDate.startDate).getTime(),
        )
    }
    return finded
}

export const editEventsAndAddEvents = (props, newAdds, newEdits) => {
    if (props.state.events) {
        let events = JSON.parse(JSON.stringify(props.state.events))
        let idEventsEdited = props.state.idEventsEdited.slice()

        events = events.map(event => {
            const newEdit =
                newEdits.find(newEdit => newEdit.id === event.id) || null
            if (newEdit) {
                idEventsEdited.push(event.id)
                return newEdit
            }
            return event
        })

        props.setState({
            events: events,
            idEventsEdited: idEventsEdited,
            addEvents: newAdds,
        })
    }
}

export const getEventLinkToElement = (
    events,
    { part = null, equipment = null },
) => {
    if (!events || (!part && !equipment)) return null

    if (equipment && events) {
        return events.find(
            event =>
                equipment.id &&
                event.detail &&
                event.detail.equipment &&
                event.detail.equipment.id === equipment.id,
        )
    }

    if (events) {
        return events.find(
            event =>
                part.id &&
                event.detail &&
                event.detail.part &&
                event.detail.part.id === part.id,
        )
    }
    return null
}

export const getCycle = ({ event = null, checkup = null }) => {
    let cycle = false
    if (event && event.checkup) checkup = event.checkup
    if (checkup) {
        if (checkup.checkupType && checkup.checkupType.cycles) {
            cycle = checkup.checkupType.cycles.find(
                cycle => cycle.cycleType && cycle.cycleType.id === 2,
            )
            if (!cycle)
                return checkup.checkupType.cycles.find(
                    cycle => cycle.cycleType && cycle.cycleType.id === 1,
                )
        } else if (checkup.userCheckup && checkup.userCheckup.cycles) {
            cycle = checkup.userCheckup.cycles.find(
                cycle => cycle.cycleType && cycle.cycleType.id === 2,
            )
            if (!cycle)
                cycle = checkup.userCheckup.cycles.find(
                    cycle => cycle.cycleType && cycle.cycleType.id === 1,
                )
            if (!cycle)
                cycle = checkup.userCheckup.cycles.find(
                    cycle => cycle.cycleType && cycle.cycleType.id === 3,
                )
        }
    }
    return cycle
}

export const getCycleHours = event => {
    if (event && event.checkup) {
        let cycle = null
        if (event.checkup.checkupType && event.checkup.checkupType.cycles) {
            cycle = event.checkup.checkupType.cycles.find(
                cycle => cycle.cycleType && cycle.cycleType.id === 2,
            )
        } else if (
            event.checkup.userCheckup &&
            event.checkup.userCheckup.cycles
        ) {
            cycle = event.checkup.userCheckup.cycles.find(
                cycle => cycle.cycleType && cycle.cycleType.id === 2,
            )
        }
        if (cycle && cycle.cycleLength) return cycle.cycleLength
    }
    return null
}

export const getCycleMonth = event => {
    if (event && event.checkup) {
        let cycle = null
        if (event.checkup.checkupType && event.checkup.checkupType.cycles) {
            cycle = event.checkup.checkupType.cycles.find(
                cycle => cycle.cycleType && cycle.cycleType.id === 1,
            )
        } else if (
            event.checkup.userCheckup &&
            event.checkup.userCheckup.cycles
        ) {
            cycle = event.checkup.userCheckup.cycles.find(
                cycle => cycle.cycleType && cycle.cycleType.id === 1,
            )
        }
        if (cycle && cycle.cycleLength) return cycle.cycleLength
    }
    return null
}

export const getCycleWeek = event => {
    if (event && event.checkup) {
        let cycle = null
        if (event.checkup.checkupType && event.checkup.checkupType.cycles) {
            cycle = event.checkup.checkupType.cycles.find(
                cycle => cycle.cycleType && cycle.cycleType.id === 3,
            )
        } else if (
            event.checkup.userCheckup &&
            event.checkup.userCheckup.cycles
        ) {
            cycle = event.checkup.userCheckup.cycles.find(
                cycle => cycle.cycleType && cycle.cycleType.id === 3,
            )
        }
        if (cycle && cycle.cycleLength) return cycle.cycleLength
    }
    return null
}

export const getElementStock = (
    events,
    { partId = null, equipmentId = null },
) => {
    let stock = 0
    if (partId) {
        events.forEach(event => {
            if (
                event.detail &&
                event.detail.part &&
                event.detail.part.id === partId &&
                !event.delimitedDate &&
                !event.detail.archived
            )
                stock += 1
        })
    } else if (equipmentId) {
        events.forEach(event => {
            if (
                event.detail &&
                event.detail.equipment &&
                event.detail.equipment.id === equipmentId &&
                !event.delimitedDate &&
                !event.detail.archived
            )
                stock += 1
        })
    }
    return stock
}

export const getElementStockByDate = (event, dateStart) => {
    let stock = 0

    if (event.detail.buyDate && new Date(event.detail.buyDate) < dateStart) {
        let buyDate = new Date(event.detail.buyDate),
            y = buyDate.getFullYear(),
            m = buyDate.getMonth()
        let lastBuyDay = new Date(y + (stock + 1), m, 1)
        if (dateStart < lastBuyDay) {
            stock += 1
        }
    } else if (
        event.detail.timestamp &&
        new Date(event.detail.timestamp) < dateStart
    ) {
        let buyDate = new Date(event.detail.timestamp),
            y = buyDate.getFullYear(),
            m = buyDate.getMonth()
        let lastTimeStampDay = new Date(y + (stock + 1), m, 1)
        if (dateStart < lastTimeStampDay) {
            stock += 1
        }
    }

    return stock
}

export const getNameElementFromDetail = (detail, lang) => {
    if (detail && detail.part) {
        const part = detail.part
        if (part.userPart && part.userPart.name) return part.userPart.name
        else if (
            part.partType &&
            lang === 'fr' &&
            part.partType?.translation?.fr
        )
            return part.partType.translation.fr
        else if (
            part.partType &&
            lang === 'en' &&
            part.partType?.translation?.en
        )
            return part.partType.translation.en
    } else if (detail && detail.equipment) {
        const equipment = detail.equipment
        if (equipment.userEquipment && equipment.userEquipment.name)
            return equipment.userEquipment.name
        else if (
            equipment.equipmentType &&
            lang === 'fr' &&
            equipment.equipmentType?.translation?.fr
        )
            return equipment.equipmentType.translation.fr
        else if (
            equipment.equipmentType &&
            lang === 'en' &&
            equipment.equipmentType?.translation?.en
        )
            return equipment.equipmentType.translation.en
    }
    return null
}

export const getNameFromRepair = (repair, context) => {
    if (repair?.checkup?.parts?.length > 0) {
        return (
            repair.checkup.parts[0].equipment?.equipmentType?.translation?.[
                context
            ] || repair.checkup.parts[0].equipment?.userEquipment?.name
        )
    } else if (repair?.checkup?.equipments?.length > 0) {
        return (
            repair.checkup?.equipments[0]?.equipmentType?.translation?.[
                context
            ] || repair.checkup?.equipments[0]?.userEquipment?.name
        )
    }

    return 'Error'
}

export const updateUserEquipmentName = (userEquipment = null, newTitle) => {
    if (!newTitle || !userEquipment.id) return null

    const body = {
        id: userEquipment.id,
        name: newTitle,
    }

    return dispatch => {
        dispatch({ type: bobEventsConstants.PUT_BOBEVENTS })
        return requestApi('/user-equipments/name', 'PUT', body).then(
            result => {
                dispatch({
                    type: bobEventsConstants.UPDATE_USEREQUIPMENT_NAME_SUCCESS,
                    userEquipment: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: bobEventsConstants.BOBEVENTS_PUT_FAILURE,
                    error: error,
                })
                console.error(error)
            },
        )
    }
}

export const updateEquipmentEngineType = (equipmentId, eventId, boatId) => {
    if (!equipmentId || !eventId) return null

    const body = {}

    return dispatch => {
        dispatch({ type: bobEventsConstants.PUT_BOBEVENTS })
        return requestApi(
            '/bob-events/' +
                eventId +
                '/engine-type/' +
                equipmentId +
                '/boat/' +
                boatId,
            'PUT',
            body,
        ).then(
            result => {
                dispatch({
                    type: bobEventsConstants.UPDATE_EQUIPMENTTYPE_NAME_SUCCESS,
                    events: result,
                })
                dispatch(typesActions.requestEquipmentTypes())
                return result[0]?.detail?.equipment || null
            },
            error => {
                dispatch({
                    type: bobEventsConstants.BOBEVENTS_PUT_FAILURE,
                    error: error,
                })
                console.error(error)
            },
        )
    }
}

export const updateEquipmentTypeName = (
    equipmentType = null,
    newTitle = null,
    boatId = null,
    lang = null,
) => {
    if (!newTitle || !equipmentType.id || !boatId || !lang) return null

    const body = {
        boatId: boatId,
        name: newTitle,
    }

    return dispatch => {
        dispatch({ type: bobEventsConstants.PUT_BOBEVENTS })
        return requestApi(
            '/equipment-types/name/' +
                equipmentType.id +
                '/' +
                lang.toLowerCase(),
            'PUT',
            body,
        ).then(
            result => {
                dispatch({
                    type: bobEventsConstants.UPDATE_EQUIPMENTTYPE_NAME_SUCCESS,
                    events: result,
                })
                dispatch(typesActions.requestEquipmentTypes())
                return result[0]?.detail?.equipment || null
            },
            error => {
                dispatch({
                    type: bobEventsConstants.BOBEVENTS_PUT_FAILURE,
                    error: error,
                })
                console.error(error)
            },
        )
    }
}

export const updateUserPartName = (userPart = null, newTitle) => {
    if (!newTitle || !userPart.id) return null

    const body = {
        id: userPart.id,
        name: newTitle,
    }

    return dispatch => {
        dispatch({ type: bobEventsConstants.PUT_BOBEVENTS })
        return requestApi(`/user-parts/name/${userPart.id}`, 'PUT', body).then(
            result => {
                dispatch({
                    type: bobEventsConstants.UPDATE_USERPART_NAME_SUCCESS,
                    userPart: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: bobEventsConstants.BOBEVENTS_PUT_FAILURE,
                    error: error,
                })
                console.error(error)
            },
        )
    }
}

export const updatePartTypeName = (
    partType = null,
    newTitle = null,
    boatId = null,
) => {
    if (!newTitle || !partType.id || !boatId) return null

    const body = {
        boatId: boatId,
        name: newTitle,
    }

    return dispatch => {
        dispatch({ type: bobEventsConstants.PUT_BOBEVENTS })
        return requestApi('/part-types/name/' + partType.id, 'PUT', body).then(
            result => {
                dispatch({
                    type: bobEventsConstants.UPDATE_PARTTYPE_NAME_SUCCESS,
                    events: result,
                })
                dispatch(typesActions.requestPartTypes())
                return result[0]?.detail?.part || null
            },
            error => {
                dispatch({
                    type: bobEventsConstants.BOBEVENTS_PUT_FAILURE,
                    error: error,
                })
                console.error(error)
            },
        )
    }
}
