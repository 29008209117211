export default {
    title: {
        FR: 'Paramètres du temps de travail',
        EN: 'Work time settings',
        DE: 'Arbeits',
        BG: 'Настройки на работното време',
        HR: 'Postavke radnog vremena',
        DA: 'Arbejdstidsindstillinger',
        ES: 'Configuración del tiempo de trabajo',
        ET: 'Tööaja seaded',
        FI: 'Työaikasetukset',
        EL: 'Ρυθμίσεις χρόνου εργασίας',
        HU: 'Munkaidő beállítások',
        GA: 'Socruithe ama oibre',
        IT: 'Impostazioni del tempo di lavoro',
        LV: 'Darba laika iestatījumi',
        LT: 'Darbo laiko nustatymai',
        MT: 'Settings tal-ħin tax-xogħol',
        NL: 'Werkuren instellingen',
        PL: 'Ustawienia czasu pracy',
        PT: 'Configurações de tempo de trabalho',
        RO: 'Setări de timp de lucru',
        SK: 'Nastavenia pracovného času',
        SL: 'Nastavitve delovnega časa',
        SV: 'Arbetstidsinställningar',
        CS: 'Nastavení pracovní doby',
        RU: 'Настройки рабочего времени',
        IS: 'Vinnutíma stillingar',
        JA: '労働時間設定',
        KO: '근무 시간 설정',
        UK: 'Налаштування робочого часу',
        ZH: '工作时间设置',
    },
    info: {
        FR: `Sélectionnez le ou les utilisateurs pour lesquels vous souhaitez paramétrer les temps de travail. Par défaut, les temps de travail affichés ci-dessous suivent la Convention du Travail Maritime de l'Organisation Internationale du Travail (OIT).`,
        EN: `Select the user(s) for whom you want to set the work time. By default, the work time displayed below follows the Maritime Labour Convention of the International Labour Organization (ILO).`,
        DE: `Wählen Sie den/die Benutzer aus, für die Sie die Arbeitszeit einstellen möchten. Standardmäßig folgt die unten angezeigte Arbeitszeit der Seearbeitskonvention der Internationalen Arbeitsorganisation (ILO).`,
        BG: `Изберете потребителите, за които искате да настроите работното време. По подразбиране работното време, което се вижда по-долу, следва Морската трудова конвенция на Международната организация на труда (МОТ).`,
        HR: `Odaberite korisnika (e) za koje želite postaviti radno vrijeme. Prema zadanim postavkama, radno vrijeme prikazano u nastavku slijedi Pomorsku radnu konvenciju Međunarodne organizacije rada (ILO).`,
        DA: `Vælg den (e) bruger (e), for hvem du vil indstille arbejdstiden. Som standard følger arbejdstiden, der vises nedenfor, Søfartsarbejdskonventionen fra Den Internationale Arbejdsorganisation (ILO).`,
        ES: `Seleccione el (los) usuario (s) para los que desea establecer el tiempo de trabajo. De forma predeterminada, el tiempo de trabajo que se muestra a continuación sigue la Convención del Trabajo Marítimo de la Organización Internacional del Trabajo (OIT).`,
        ET: `Valige kasutaja (d), kelle jaoks soovite tööaega seada. Vaikimisi järgib allpool kuvatud tööaeg Rahvusvahelise Tööorganisatsiooni (ILO) Meretöökonventsiooni.`,
        FI: `Valitse käyttäjä (t), joiden työaika haluat asettaa. Oletuksena alla näkyvä työaika noudattaa Kansainvälisen työjärjestön (ILO) Merityökonventiota.`,
        EL: `Επιλέξτε τον (τους) χρήστη (ς) για τον οποίο (ους) θέλετε να ορίσετε το χρόνο εργασίας. Από προεπιλογή, ο χρόνος εργασίας που εμφανίζεται παρακάτω ακολουθεί τη Σύμβαση Εργασίας της Ναυτιλίας του Διεθνούς Οργανισμού Εργασίας (ΔΟΕ).`,
        HU: `Válassza ki a (z) felhasználót (kat), akiknek beállítani szeretné a munkaidőt. Alapértelmezés szerint az alább látható munkaidő az ILO (International Labour Organization) Tengeri Munkaegyezményét követi.`,
        GA: `Roghnaigh an (na) úsáideoir (í) ar mhaith leat an t-am oibre a shocrú dóibh. De réir réamhshocraithe, leanann an t-am oibre a léirítear thíos Comhaontú Lucht Oibre na Farraige ón Eagraíocht Idirnáisiúnta Oibre (ILO).`,
        IT: `Seleziona l'utente (gli utenti) per i quali desideri impostare l'orario di lavoro. Per impostazione predefinita, l'orario di lavoro visualizzato di seguito segue la Convenzione sul lavoro marittimo dell'Organizzazione internazionale del lavoro (ILO).`,
        LV: `Atlasiet lietotāju (s), kuru darba laiku vēlaties iestatīt. Pēc noklusējuma zemāk redzamais darba laiks atbilst Starptautiskās Darba organizācijas (ILO) Jūras darba konvencijai.`,
        LT: `Pasirinkite naudotoją (us), kuriems norite nustatyti darbo laiką. Pagal numatytuosius nustatymus žemiau pateiktas darbo laikas atitinka Tarptautinės darbo organizacijos (ILO) Jūrų darbo konvenciją.`,
        MT: `Agħżel l-utent (ijiet) għalihom tixtieq tistabbilixxi l-ħin tax-xogħol. Skont id-dawn l-istabbilimenti, il-ħin tax-xogħol li jidher hawn taħt jsegwi l-Konvenzjoni tax-Xogħol tal-Baħar tal-Organizzazzjoni Internazzjonali tax-Xogħol (ILO).`,
        NL: `Selecteer de gebruiker (s) voor wie u de werktijd wilt instellen. Standaard volgt de hieronder weergegeven werktijd de Maritieme Arbeidsconventie van de Internationale Arbeidsorganisatie (ILO).`,
        PL: `Wybierz użytkownika (ów), dla których chcesz ustawić czas pracy. Domyślnie poniżej wyświetlany czas pracy odpowiada Konwencji Pracy Morskiej Międzynarodowej Organizacji Pracy (ILO).`,
        PT: `Selecione o (s) usuário (s) para os quais deseja definir o tempo de trabalho. Por padrão, o tempo de trabalho exibido abaixo segue a Convenção do Trabalho Marítimo da Organização Internacional do Trabalho (OIT).`,
        RO: `Selectați utilizatorul (ii) pentru care doriți să setați timpul de lucru. În mod implicit, timpul de lucru afișat mai jos urmează Convenția Muncii Maritime a Organizației Internaționale a Muncii (OIM).`,
        SK: `Vyberte používateľa (ov), pre ktorého (ch) chcete nastaviť pracovný čas. Predvolene nižšie zobrazený pracovný čas nasleduje Námornú pracovnú konvenciu Medzinárodnej organizácie práce (ILO).`,
        SL: `Izberite uporabnika (e), za katerega (e) želite nastaviti delovni čas. Privzeto spodaj prikazan delovni čas sledi Pomorski delovni konvenciji Mednarodne organizacije dela (ILO).`,
        SV: `Välj den (de) användare för vilka du vill ställa in arbetstiden. Som standard följer arbetstiden som visas nedan Internationella arbetsorganisationens (ILO) Sjöarbetskonvention.`,
        CS: `Vyberte uživatele (e), pro které chcete nastavit pracovní dobu. Výchozí pracovní doba zobrazená níže následuje Námořní pracovní dohodu Mezinárodní organizace práce (ILO).`,
        RU: `Выберите пользователя (ов), для которого (ых) вы хотите установить рабочее время. По умолчанию ниже отображаемое рабочее время соответствует Морской трудовой конвенции Международной организации труда (МОТ).`,
        IS: `Veldu notandann (a) sem þú vilt stilla vinnutímann fyrir. Sjálfgefið fylgir vinnutíminn sem birtist hér fyrir neðan Sjómannasamningi Alþjóða vinnuverndarstofnunarinnar (ILO).`,
        JA: `労働時間を設定するユーザーを選択します。デフォルトでは、以下に表示される労働時間は国際労働機関（ILO）の海事労働条約に従います。`,
        KO: `근무 시간을 설정할 사용자 (들)을 선택하십시오. 기본적으로 아래에 표시된 근무 시간은 국제 노동 기구 (ILO)의 해상 노동 협약을 따릅니다.`,
        UK: `Виберіть користувача (ів), для якого (их) ви хочете встановити робочий час. За замовчуванням нижче відображений робочий час відповідає Морській трудовій конвенції Міжнародної організації праці (МОП).`,
        ZH: `选择要设置工作时间的用户。默认情况下，下面显示的工作时间遵循国际劳工组织（ILO）的海事劳工公约。`,
    },
    startTime: {
        FR: 'Heure de début',
        EN: 'Start time',
        DE: 'Startzeit',
        BG: 'Време за стартиране',
        HR: 'Vrijeme početka',
        DA: 'Starttid',
        ES: 'Hora de inicio',
        ET: 'Algusaeg',
        FI: 'Aloitusaika',
        EL: 'Ώρα έναρξης',
        HU: 'Kezdési idő',
        GA: 'Am tosaithe',
        IT: 'Ora di inizio',
        LV: 'Sākuma laiks',
        LT: 'Pradžios laikas',
        MT: 'Ħin tal-bidu',
        NL: 'Starttijd',
        PL: 'Czas rozpoczęcia',
        PT: 'Hora de início',
        RO: 'Timp de start',
        SK: 'Čas začiatku',
        SL: 'Čas začetka',
        SV: 'Starttid',
        CS: 'Čas začátku',
        RU: 'Время начала',
        IS: 'Byrjunartími',
        JA: '開始時間',
        KO: '시작 시간',
        UK: 'Час початку',
        ZH: '开始时间',
    },
    endTime: {
        FR: 'Heure de fin',
        EN: 'End time',
        DE: 'Endzeit',
        BG: 'Крайно време',
        HR: 'Vrijeme završetka',
        DA: 'Sluttid',
        ES: 'Hora de finalización',
        ET: 'Lõppaeg',
        FI: 'Lopetusaika',
        EL: 'Ώρα λήξης',
        HU: 'Befejezési idő',
        GA: 'Am deiridh',
        IT: 'Ora di fine',
        LV: 'Beigu laiks',
        LT: 'Pabaigos laikas',
        MT: `Ħin ta 'l-aħħar`,
        NL: 'Eindtijd',
        PL: 'Czas zakończenia',
        PT: 'Hora de término',
        RO: 'Timpul de sfârșit',
        SK: 'Čas ukončenia',
        SL: 'Čas konca',
        SV: 'Sluttid',
        CS: 'Čas konce',
        RU: 'Время окончания',
        IS: 'Lokatími',
        JA: '終了時間',
        KO: '종료 시간',
        UK: 'Час закінчення',
        ZH: '结束时间',
    },
    addBreak: {
        FR: 'Ajouter une pause',
        EN: 'Add a break',
        DE: 'Pause hinzufügen',
        BG: 'Добави почивка',
        HR: 'Dodajte stanku',
        DA: 'Tilføj en pause',
        ES: 'Agregar un descanso',
        ET: 'Lisa paus',
        FI: 'Lisää tauko',
        EL: 'Προσθήκη διακοπής',
        HU: 'Szünet hozzáadása',
        GA: 'Cuir briseadh leis',
        IT: 'Aggiungi una pausa',
        LV: 'Pievienot pārtraukumu',
        LT: 'Pridėti pertrauką',
        MT: 'Żid pauza',
        NL: 'Pauze toevoegen',
        PL: 'Dodaj przerwę',
        PT: 'Adicionar uma pausa',
        RO: 'Adăugați o pauză',
        SK: 'Pridať prestávku',
        SL: 'Dodaj odmor',
        SV: 'Lägg till en paus',
        CS: 'Přidat přestávku',
        RU: 'Добавить перерыв',
        IS: 'Bæta við pásu',
        JA: '休憩を追加',
        KO: '휴식 추가',
        UK: 'Додати перерву',
        ZH: '添加休息',
    },
    startBreak: {
        FR: 'Début de la pause',
        EN: 'Start of the break',
        DE: 'Start der Pause',
        BG: 'Начало на почивката',
        HR: 'Početak stanke',
        DA: 'Start af pausen',
        ES: 'Inicio del descanso',
        ET: 'Pausi algus',
        FI: 'Tauon alku',
        EL: 'Έναρξη διακοπής',
        HU: 'Szünet kezdete',
        GA: 'Tús a tosú',
        IT: 'Inizio della pausa',
        LV: 'Pārtraukuma sākums',
        LT: 'Pertraukos pradžia',
        MT: 'Bidu tal-pawsa',
        NL: 'Begin van de pauze',
        PL: 'Początek przerwy',
        PT: 'Início da pausa',
        RO: 'Începutul pauzei',
        SK: 'Začiatok prestávky',
        SL: 'Začetek odmora',
        SV: 'Start av paus',
        CS: 'Začátek přestávky',
        RU: 'Начало перерыва',
        IS: 'Byrjun pásu',
        JA: '休憩の開始',
        KO: '휴식 시작',
        UK: 'Початок перерви',
        ZH: '休息开始',
    },
    endBreak: {
        FR: 'Fin de la pause',
        EN: 'End of the break',
        DE: 'Ende der Pause',
        BG: 'Край на почивката',
        HR: 'Kraj stanke',
        DA: 'Slutningen af pausen',
        ES: 'Fin del descanso',
        ET: 'Pausi lõpp',
        FI: 'Tauon loppu',
        EL: 'Τέλος της διακοπής',
        HU: 'Szünet vége',
        GA: 'Deireadh an tsos',
        IT: 'Fine della pausa',
        LV: 'Pārtraukuma beigas',
        LT: 'Pertraukos pabaiga',
        MT: 'Tmi tal-pawsa',
        NL: 'Einde van de pauze',
        PL: 'Koniec przerwy',
        PT: 'Fim da pausa',
        RO: 'Sfârșitul pauzei',
        SK: 'Koniec prestávky',
        SL: 'Konec odmora',
        SV: 'Slutet av paus',
        CS: 'Konec přestávky',
        RU: 'Конец перерыва',
        IS: 'Lok pásu',
        JA: '休憩の終了',
        KO: '휴식 종료',
        UK: 'Кінець перерви',
        ZH: '休息结束',
    },
    maxWorkTime: {
        FR: 'Indiquer une durée de travail maximum par jour',
        EN: 'Indicate a maximum working time per day',
        DE: 'Geben Sie eine maximale Arbeitszeit pro Tag an',
        BG: 'Посочете максимално работно време на ден',
        HR: 'Navedite maksimalno radno vrijeme dnevno',
        DA: 'Angiv en maksimal arbejdstid pr. dag',
        ES: 'Indique un tiempo de trabajo máximo por día',
        ET: 'Määrake maksimaalne tööaeg päevas',
        FI: 'Ilmoita maksimi työaika päivässä',
        EL: 'Καθορίστε μια μέγιστη χρόνο εργασίας ανά ημέρα',
        HU: 'Adjon meg egy maximális munkaidőt naponta',
        GA: 'Léirigh am oibre uasmhéidte in aghaidh an lae',
        IT: 'Indicare un tempo di lavoro massimo al giorno',
        LV: 'Norādiet maksimālo darba laiku dienā',
        LT: 'Nurodykite maksimalų darbo laiką per dieną',
        MT: 'Indika ż-żmien massimu taċ-ċavetta kuljum',
        NL: 'Geef een maximale werktijd per dag aan',
        PL: 'Podaj maksymalny czas pracy dziennie',
        PT: 'Indique um tempo de trabalho máximo por dia',
        RO: 'Indicați un timp de lucru maxim pe zi',
        SK: 'Uveďte maximálny pracovný čas za deň',
        SL: 'Navedite največji delovni čas na dan',
        SV: 'Ange en maximal arbetstid per dag',
        CS: 'Uveďte maximální pracovní dobu za den',
        RU: 'Укажите максимальное рабочее время в день',
        IS: 'Tilgreini hámarks vinnutíma á dag',
        JA: '1日あたりの最大労働時間を示します',
        KO: '하루 최대 근무 시간을 지정하십시오',
        UK: 'Вкажіть максимальний робочий час на день',
        ZH: '指定每天的最大工作时间',
    },
    minimumRestTime: {
        FR: 'Durée de repos minimum entre 2 jours',
        EN: 'Minimum rest time between 2 days',
        DE: 'Minimale Ruhezeit zwischen 2 Tagen',
        BG: 'Минимално време за почивка между 2 дни',
        HR: 'Minimalno vrijeme odmora između 2 dana',
        DA: 'Minimum hviletid mellem 2 dage',
        ES: 'Tiempo de descanso mínimo entre 2 días',
        ET: 'Minimaalne puhkeaeg kahe päeva vahel',
        FI: 'Vähimmäislepoaika kahden päivän välillä',
        EL: 'Ελάχιστος χρόνος ανάπαυσης μεταξύ 2 ημερών',
        HU: 'Minimális pihenőidő két nap között',
        GA: 'Am suíocháin is lú idir 2 lá',
        IT: 'Tempo di riposo minimo tra 2 giorni',
        LV: 'Minimālais atpūtas laiks starp 2 dienām',
        LT: 'Minimalus poilsio laikas tarp 2 dienų',
        MT: "Ħin minimu ta 'ripos bejn 2 ijiem",
        NL: 'Minimale rusttijd tussen 2 dagen',
        PL: 'Minimalny czas odpoczynku między 2 dniami',
        PT: 'Tempo de descanso mínimo entre 2 dias',
        RO: 'Timp minim de odihnă între 2 zile',
        SK: 'Minimálny čas odpočinku medzi 2 dňami',
        SL: 'Minimalni čas počitka med 2 dnevoma',
        SV: 'Minsta vilotid mellan 2 dagar',
        CS: 'Minimální doba odpočinku mezi 2 dny',
        RU: 'Минимальное время отдыха между 2 днями',
        IS: 'Lágmarks hvíldartími milli 2 daga',
        JA: '2日間の最小休息時間',
        KO: '2 일 사이의 최소 휴식 시간',
        UK: 'Мінімальний час відпочинку між 2 днями',
        ZH: '2天之间的最短休息时间',
    },
    start: {
        FR: 'Début',
        EN: 'Start',
        DE: 'Start',
        BG: 'Старт',
        HR: 'Početak',
        DA: 'Start',
        ES: 'Comienzo',
        ET: 'Algus',
        FI: 'Aloita',
        EL: 'Αρχή',
        HU: 'Kezdés',
        GA: 'Tosaigh',
        IT: 'Inizio',
        LV: 'Sākums',
        LT: 'Pradžia',
        MT: 'Bidu',
        NL: 'Start',
        PL: 'Początek',
        PT: 'Começar',
        RO: 'Start',
        SK: 'Začiatok',
        SL: 'Začetek',
        SV: 'Start',
        CS: 'Start',
        RU: 'Старт',
        IS: 'Byrjun',
        JA: 'スタート',
        KO: '스타트',
        UK: 'Старт',
        ZH: '开始',
    },
    end: {
        FR: 'Fin',
        EN: 'End',
        DE: 'Ende',
        BG: 'Край',
        HR: 'Kraj',
        DA: 'Ende',
        ES: 'Fin',
        ET: 'Lõpp',
        FI: 'Loppu',
        EL: 'Τέλος',
        HU: 'Vége',
        GA: 'Deireadh',
        IT: 'Fine',
        LV: 'Beigas',
        LT: 'Pabaiga',
        MT: 'Tmi',
        NL: 'Einde',
        PL: 'Koniec',
        PT: 'Fim',
        RO: 'Sfârșit',
        SK: 'Koniec',
        SL: 'Konec',
        SV: 'Slutet',
        CS: 'Konec',
        RU: 'Конец',
        IS: 'Lok',
        JA: '終わり',
        KO: '종료',
        UK: 'Кінець',
        ZH: '结束',
    },
    deleteGroup: {
        FR: 'Supprimer le groupe',
        EN: 'Delete group',
        DE: 'Gruppe löschen',
        BG: 'Изтрий групата',
        HR: 'Izbriši grupu',
        DA: 'Slet gruppen',
        ES: 'Eliminar grupo',
        ET: 'Kustuta grupp',
        FI: 'Poista ryhmä',
        EL: 'Διαγραφή ομάδας',
        HU: 'Csoport törlése',
        GA: 'Scrios an grúpa',
        IT: 'Elimina gruppo',
        LV: 'Dzēst grupu',
        LT: 'Ištrinti grupę',
        MT: 'Iħassar il-grupp',
        NL: 'Groep verwijderen',
        PL: 'Usuń grupę',
        PT: 'Excluir grupo',
        RO: 'Ștergeți grupul',
        SK: 'Vymazať skupinu',
        SL: 'Izbriši skupino',
        SV: 'Ta bort gruppen',
        CS: 'Smazat skupinu',
        RU: 'Удалить группу',
        IS: 'Eyða hópnum',
        JA: 'グループを削除',
        KO: '그룹 삭제',
        UK: 'Видалити групу',
        ZH: '删除组',
    },
    group: {
        FR: 'Groupe',
        EN: 'Group',
        DE: 'Gruppe',
        BG: 'Група',
        HR: 'Grupa',
        DA: 'Gruppe',
        ES: 'Grupo',
        ET: 'Grupp',
        FI: 'Ryhmä',
        EL: 'Ομάδα',
        HU: 'Csoport',
        GA: 'Grúpa',
        IT: 'Gruppo',
        LV: 'Grupa',
        LT: 'Grupė',
        MT: 'Grupp',
        NL: 'Groep',
        PL: 'Grupa',
        PT: 'Grupo',
        RO: 'Grup',
        SK: 'Skupina',
        SL: 'Skupina',
        SV: 'Grupp',
        CS: 'Skupina',
        RU: 'Группа',
        IS: 'Hópur',
        JA: 'グループ',
        KO: '그룹',
        UK: 'Група',
        ZH: '组',
    },
    remainingUsers: {
        FR: 'Reste des utilisateurs',
        EN: 'Remaining users',
        DE: 'Verbleibende Benutzer',
        BG: 'Оставащи потребители',
        HR: 'Preostali korisnici',
        DA: 'Resterende brugere',
        ES: 'Usuarios restantes',
        ET: 'Jäänud kasutajad',
        FI: 'Jäljellä olevat käyttäjät',
        EL: 'Υπόλοιποι χρήστες',
        HU: 'Maradék felhasználók',
        GA: 'Úsáideoirí atá fágtha',
        IT: 'Utenti rimanenti',
        LV: 'Atlikušie lietotāji',
        LT: 'Liko naudotojai',
        MT: 'Utenti li jkunu',
        NL: 'Overgebleven gebruikers',
        PL: 'Pozostali użytkownicy',
        PT: 'Usuários restantes',
        RO: 'Utilizatori rămași',
        SK: 'Zostávajúci používatelia',
        SL: 'Preostali uporabniki',
        SV: 'Återstående användare',
        CS: 'Zbývající uživatelé',
        RU: 'Оставшиеся пользователи',
        IS: 'Eftirfarandi notendur',
        JA: '残りのユーザー',
        KO: '남은 사용자',
        UK: 'Залишилися користувачі',
        ZH: '剩余用户',
    },
    groupName: {
        FR: 'Nom du groupe',
        EN: 'Group name',
        DE: 'Gruppenname',
        BG: 'Име на групата',
        HR: 'Naziv grupe',
        DA: 'Gruppenavn',
        ES: 'Nombre del grupo',
        ET: 'Grupi nimi',
        FI: 'Ryhmän nimi',
        EL: 'Όνομα ομάδας',
        HU: 'Csoport neve',
        GA: 'Ainm an ghrúpa',
        IT: 'Nome del gruppo',
        LV: 'Grupas nosaukums',
        LT: 'Grupės pavadinimas',
        MT: 'Isem tal-grupp',
        NL: 'Groepsnaam',
        PL: 'Nazwa grupy',
        PT: 'Nome do grupo',
        RO: 'Numele grupului',
        SK: 'Názov skupiny',
        SL: 'Ime skupine',
        SV: 'Gruppnamn',
        CS: 'Název skupiny',
        RU: 'Название группы',
        IS: 'Nafn hóps',
        JA: 'グループ名',
        KO: '그룹 이름',
        UK: 'Назва групи',
        ZH: '组名',
    },
    groupMembers: {
        FR: 'Membres du groupe',
        EN: 'Group members',
        DE: 'Gruppenmitglieder',
        BG: 'Членове на групата',
        HR: 'Članovi grupe',
        DA: 'Gruppemedlemmer',
        ES: 'Miembros del grupo',
        ET: 'Grupi liikmed',
        FI: 'Ryhmän jäsenet',
        EL: 'Μέλη της ομάδας',
        HU: 'Csoporttagok',
        GA: 'Ballaithe an ghrúpa',
        IT: 'Membri del gruppo',
        LV: 'Grupas dalībnieki',
        LT: 'Grupės nariai',
        MT: 'Membri tal-grupp',
        NL: 'Groepsleden',
        PL: 'Członkowie grupy',
        PT: 'Membros do grupo',
        RO: 'Membrii grupului',
        SK: 'Členovia skupiny',
        SL: 'Člani skupine',
        SV: 'Gruppmedlemmar',
        CS: 'Členové skupiny',
        RU: 'Члены группы',
        IS: 'Hópmeðlimir',
        JA: 'グループメンバー',
        KO: '그룹 멤버',
        UK: 'Члени групи',
        ZH: '组成员',
    },
    pageLoadingLabel: {
        FR: 'Chargement des paramètres...',
        EN: 'Loading settings...',
        DE: 'Einstellungen werden geladen...',
        BG: 'Зареждане на настройките...',
        HR: 'Učitavanje postavki...',
        DA: 'Indlæser indstillinger...',
        ES: 'Cargando ajustes...',
        ET: 'Sätete laadimine...',
        FI: 'Asetusten lataaminen...',
        EL: 'Φόρτωση ρυθμίσεων...',
        HU: 'Beállítások betöltése...',
        GA: 'Á bpríomhchumais...',
        IT: 'Caricamento delle impostazioni...',
        LV: 'Iestatījumu ielāde...',
        LT: 'Nustatymų įkėlimas...',
        MT: 'Tisħiħ tal-impostazzjonijiet...',
        NL: 'Instellingen laden...',
        PL: 'Ładowanie ustawień...',
        PT: 'Carregando configurações...',
        RO: 'Se încarcă setările...',
        SK: 'Načítanie nastavení...',
        SL: 'Nalaganje nastavitev...',
        SV: 'Laddar inställningar...',
        CS: 'Načítání nastavení...',
        RU: 'Загрузка настроек...',
        IS: 'Hlaða stillingum...',
        JA: '設定の読み込み中...',
        KO: '설정 로드 중...',
        UK: 'Завантаження налаштувань...',
        ZH: '正在加载设置...',
    },
    startDay: {
        FR: 'Début de journée',
        EN: 'Start of the day',
        DE: 'Start des Tages',
        BG: 'Начало на деня',
        HR: 'Početak dana',
        DA: 'Start af dagen',
        ES: 'Inicio del día',
        ET: 'Päeva algus',
        FI: 'Päivän alku',
        EL: 'Αρχή της ημέρας',
        HU: 'A nap kezdete',
        GA: 'Tús an lae',
        IT: 'Inizio della giornata',
        LV: 'Dienas sākums',
        LT: 'Dienos pradžia',
        MT: 'Bidu tal-gurnata',
        NL: 'Begin van de dag',
        PL: 'Początek dnia',
        PT: 'Início do dia',
        RO: 'Începutul zilei',
        SK: 'Začiatok dňa',
        SL: 'Začetek dneva',
        SV: 'Dagens början',
        CS: 'Začátek dne',
        RU: 'Начало дня',
        IS: 'Byrjun dags',
        JA: '日の始まり',
        KO: '하루 시작',
        UK: 'Початок дня',
        ZH: '一天的开始',
    },
    endDay: {
        FR: 'Fin de journée',
        EN: 'End of the day',
        DE: 'Ende des Tages',
        BG: 'Край на деня',
        HR: 'Kraj dana',
        DA: 'Slutningen af dagen',
        ES: 'Fin del día',
        ET: 'Päeva lõpp',
        FI: 'Päivän loppu',
        EL: 'Τέλος της ημέρας',
        HU: 'A nap vége',
        GA: 'Deireadh an lae',
        IT: 'Fine della giornata',
        LV: 'Dienas beigas',
        LT: 'Dienos pabaiga',
        MT: 'Tmi tal-gurnata',
        NL: 'Einde van de dag',
        PL: 'Koniec dnia',
        PT: 'Fim do dia',
        RO: 'Sfârșitul zilei',
        SK: 'Koniec dňa',
        SL: 'Konec dneva',
        SV: 'Slutet på dagen',
        CS: 'Konec dne',
        RU: 'Конец дня',
        IS: 'Lok dags',
        JA: '日の終わり',
        KO: '하루 끝',
        UK: 'Кінець дня',
        ZH: '一天的结束',
    },
}
