export const chronoConstants = {
    CURRENT_WORK_TIME_GET: 'CURRENT_WORK_TIME_GET',
    CURRENT_WORK_TIME_SUCCESS: 'CURRENT_WORK_TIME_SUCCESS',
    CURRENT_WORK_TIME_FAILURE: 'CURRENT_WORK_TIME_FAILURE',
    START_SEND: 'START_SEND',
    START_SUCCESS: 'START_SUCCESS',
    START_FAILURE: 'START_FAILURE',
    BREAK_SEND: 'BREAK_SEND',
    BREAK_SUCCESS: 'BREAK_SUCCESS',
    BREAK_FAILURE: 'BREAK_FAILURE',
    RESTART_SEND: 'RESTART_SEND',
    RESTART_SUCCESS: 'RESTART_SUCCESS',
    RESTART_FAILURE: 'RESTART_FAILURE',
    STOP_SEND: 'STOP_SEND',
    STOP_SUCCESS: 'STOP_SUCCESS',
    STOP_FAILURE: 'STOP_FAILURE',
}
