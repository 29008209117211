import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/ChatingPageCss'
import BoatOnComponent from '../common/BoatOnComponent'
import dictionary from './Dictionary/ChatingPageDico.js'
import ChatBox from '../common/UsefullComponents/ChatBox'
import Typography from '@material-ui/core/Typography'
import { history } from '../../helpers'
import AppRoute from '../../constants/AppRoute'

class ChatingPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    render() {
        const { classes, order, loading, messages } = this.props

        if (loading || !messages) return <div />

        return (
            <div className={classes.root}>
                <Typography
                    className={classes.linkBack}
                    onClick={() =>
                        this.historyPush(
                            history,
                            AppRoute.InqueriesParking,
                            '',
                            { state: { orderSelected: order } },
                            null,
                        )
                    }
                >
                    {this.displayText('comeBack')}
                </Typography>
                <ChatBox
                    messages={messages}
                    inqueryReceiverId={order.InqueryReceiverID}
                    update={() => this.props.update()}
                    inqueryId={order.InqueryID}
                    receiverUserId={order.SellerID}
                    orderId={order.OrderID}
                />
            </div>
        )
    }
}

export default withStyles(styles)(ChatingPage)
