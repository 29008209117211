export default theme => ({
    icon: {
        color: '#fff',
        minWidth: 56,
        padding: `8px 0`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        [theme.breakpoints.up('md')]: {
            minWidth: 72,
        },
    },
    iconMenu: {
        color: '#fff',
        objectFit: 'contain',
        width: '30px',
    },
    iconRepairMenu: {
        color: '#fff',
        width: '23px',
        height: '23px',
    },
    iconOpenBox: {
        width: '28px',
        height: '28px',
    },
    title: {
        fontSize: '16px',
    },
    root: Object.assign(
        { width: '100%', height: '100%' },
        theme.flex.rowSpacebetween,
        {
            '& .MuiListItem-button': {
                padding: 0,
            },
        },
    ),
    nav: {
        padding: 0,
        '& .MuiListItem-gutters': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    content: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: '0',
        },
    },
    contentBoat: {
        width: '100%',
        height: '100%',
    },
    accordionRoot: {
        background: '#4C59B2',
        borderRadius: 5,
        margin: 10,
        '&.Mui-expanded': {
            margin: 10,
        },
    },
    accordionSummaryRoot: {
        paddingInline: 10,
        justifyContent: 'flex-start',
        minHeight: 'unset !important',
    },
    accordionSummaryContent: {
        gap: 20,
        color: 'white',
        alignItems: 'center',
        marginBlock: '10px !important',
    },
    accordionDetailsRoot: {
        flexDirection: 'column',
        color: 'white',
        paddingLeft: 60,
        paddingTop: '0px !important',
    },
    subMenuItem: {
        fontWeight: 300,
        fontSize: 14,

        '&.active': {
            fontWeight: 500,
        },
    },
    newBadge: {
        marginLeft: 10,
        padding: '3px 6px',
        width: 'fit-content',
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        borderRadius: 7,
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 2,
    },
})
