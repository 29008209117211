/**
 * Call this function to repair in IOS the header go over the top of the screen
 *
 * @function fixIosHeaderBug
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {number} timeout - delay for change header to sticky (default: 200).
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 18/06/2024 - 15:31
 * @author Samuel.C
 */

export function fixIosHeaderBug(timeout = 200) {
    const isAppleDevice = window.navigator.userAgent.match(/iPhone|iPad|iPod/i)
    const onSafari = window.navigator.userAgent.match(/Safari\/\d*.\d/i)
    const header = document.getElementsByClassName('MuiAppBar-positionSticky')

    if (
        isAppleDevice &&
        onSafari &&
        header.length === 1 &&
        !!header[0]?.style
    ) {
        header[0].style.position = 'absolute'
        setTimeout(() => {
            header[0].style.position = 'sticky'
        }, timeout)
    }
}
