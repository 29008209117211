import React from 'react'
import BoatOnComponent from '../BoatOnComponent'
import InfoIcon from '@material-ui/icons/Info'
import Alert from '@material-ui/lab/Alert'
import AlertTitle from '@material-ui/lab/AlertTitle'
import styles from './Styles/BoatOnAlertCss'
import { withStyles } from '@material-ui/core/styles'
import WarningIcon from '@material-ui/icons/Warning'
import anchorLoader from '../../../images/anchorLoader.gif'

class BoatOnLoading extends BoatOnComponent {
    _addLink() {
        const { linkText, linkAction, classes } = this.props

        if (!linkText && !linkAction) return <></>
        else if (!linkText || !linkAction)
            console.error(`no linkText or linkAction`)

        return (
            <span onClick={linkAction} className={classes.link}>
                {linkText}
            </span>
        )
    }

    _renderTextAlert() {
        const { description, classes, severity } = this.props
        const classText = {
            info: classes.text,
            warning: classes.textWarning,
        }

        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                }}
            >
                <img
                    src={anchorLoader}
                    alt="anchor"
                    margin="auto"
                    width={100}
                    height={100}
                />
                <p>
                    {description}
                    {/* {this._addLink()} */}
                </p>
            </div>
        )
    }

    _renderComponentAlert() {
        const { component, classes, severity } = this.props
        const classText = {
            info: classes.text,
            warning: classes.textWarning,
        }

        return <div className={classText[severity || `info`]}>{component}</div>
    }

    render() {
        const {
            classes,
            severity,
            style,
            description,
            component,
            title,
            onClose,
        } = this.props
        const classContainer = {
            info: classes.container,
            warning: classes.containerWarning,
        }

        return (
            <div
                style={{
                    paddingBottom: 20,
                }}
            >
                {description && this._renderTextAlert()}
                {component && this._renderComponentAlert()}
            </div>
        )
    }
}

export default withStyles(styles)(BoatOnLoading)
