export default theme => ({
    root: {
        width: 'calc(100% - 50px)',
        margin: '25px',
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
        },
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    line: {
        width: 'calc(100% - 20px)',
        padding: '10px',
        height: '60px',
        border: '1px solid #c4c4c4',
        borderCollapse: 'collapse',
        '& > td': {
            padding: 10,
        },
        [theme.breakpoints.down('sm')]: {
            gap: 10,
            height: `fit-content`,
            width: '100%',
            // flexDirection: `column`,
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
            boxSizing: `border-box`,
        },
    },
    blueLine: {
        backgroundColor: '#F7F9FB',
    },
    checkbox: {
        width: 42,
    },
    icon: { width: 42 },
    actionMobile: {
        width: 207,
        height: 44,
        boxSizing: `border-box`,
        fontWeight: 400,
        fontSize: `14px`,
        justifyContent: `flex-start`,
        paddingLeft: 17,
    },
    iconsMobile: {
        minWidth: '90px',
        display: 'flex',
        flexDirection: 'column',
    },
    tag: {
        color: 'white',
        backgroundColor: '#A5A5A5',
        padding: '5px 8px',
        borderRadius: '5px',
        fontWeight: 'bold',
        maxWidth: '250px',
        textAlign: 'center',
    },
    greenTag: {
        backgroundColor: '#6BC070',
    },
    redTag: {
        backgroundColor: '#E36B61',
    },
    yellowTag: {
        backgroundColor: '#F4BA5C',
    },
})
