export default theme => ({
    root: Object.assign(theme.boxBoatOn, {
        padding: '20px',
        minWidth: '280px',
        maxWidth: '350px',
        boxSizing: 'border-box',
        width: '100%',
    }),
    buttons: Object.assign(theme.flex.rowSpacearound, { paddingTop: '10px' }),
    body: {
        margin: '15px 0',
    },
})
