import React from 'react'
import { getTranslatedTitleFromEvent } from '../../LogbookPage/LogbookNavigationPage/Utils'

export const activityRenderTypes = {
    TEXT: `TEXT`,
    DATE: `DATE`,
    DATETIME: `DATETIME`,
    USER: `USER`,
    BADGE: `BADGE`,
}

export const activityDataTypes = {
    FILE: `FILE`,
    TRANSLATION: `TRANSLATION`,
    USER: `USER`,
    TEXT: `TEXT`,
}

export default {
    // Description
    ADD_BOB_EVENT_DESCRIPTION: {
        FR: `$user a **ajouté** une description`,
        EN: `$user **added** a description`,
        renderType: activityRenderTypes.TEXT,
    },
    EDIT_BOB_EVENT_DESCRIPTION: {
        FR: `$user a **modifié** la description`,
        EN: `$user **edited** the description`,
        renderType: activityRenderTypes.TEXT,
    },
    DELETE_BOB_EVENT_DESCRIPTION: {
        FR: `$user a **supprimé** la description`,
        EN: `$user **deleted** the event description`,
        renderType: activityRenderTypes.TEXT,
    },

    // Protocol
    ADD_BOB_EVENT_PROTOCOL: {
        FR: `$user a **ajouté** le protocole à suivre`,
        EN: `$user **added** the protocol`,
    },
    EDIT_BOB_EVENT_PROTOCOL: {
        FR: `$user a **modifié** le protocole à suivre`,
        EN: `$user **edited** the protocol`,
    },
    DELETE_BOB_EVENT_PROTOCOL: {
        FR: `$user a **supprimé** le protocole à suivre`,
        EN: `$user **deleted** the protocol`,
    },

    // Durée
    ADD_BOB_EVENT_ACTUAL_DURATION: {
        FR: `$user a **ajouté** la durée`,
        EN: `$user **added** the duration`,
    },
    EDIT_BOB_EVENT_ACTUAL_DURATION: {
        FR: `$user a **modifié** la durée`,
        EN: `$user **edited** the duration`,
    },
    DELETE_BOB_EVENT_ACTUAL_DURATION: {
        FR: `$user a **supprimé** la durée`,
        EN: `$user **deleted** the duration`,
    },

    // Montant
    ADD_AMOUNT: {
        FR: `$user a **ajouté** le montant`,
        EN: `$user **added** the amount`,
        renderType: activityRenderTypes.TEXT,
    },
    EDIT_AMOUNT: {
        FR: `$user a **modifié** le montant`,
        EN: `$user **edited** the amount`,
        renderType: activityRenderTypes.TEXT,
    },
    DELETE_AMOUNT: {
        FR: `$user a **supprimé** le montant`,
        EN: `$user **deleted** the amount`,
        renderType: activityRenderTypes.TEXT,
    },

    // Date début
    ADD_START_DATE_BOB_EVENT: {
        FR: `$user a **ajouté** la date de début`,
        EN: `$user **added** the start date`,
        renderType: activityRenderTypes.DATE,
    },
    EDIT_START_DATE_BOB_EVENT: {
        FR: `$user a **modifié** la date de début`,
        EN: `$user **edited** the start date`,
        renderType: activityRenderTypes.DATE,
    },
    DELETE_START_DATE_BOB_EVENT: {
        FR: `$user a **supprimé** la date de début`,
        EN: `$user **deleted** the start date`,
        renderType: activityRenderTypes.DATE,
    },
    CREATE_USER_ABSENCE: {
        FR: `$user a **créé** l'absence`,
        EN: `$user **created** the absence`,
        renderType: activityRenderTypes.NONE,
    },
    ADD_START_DATE_ABSENCE: {
        FR: `$user a **ajouté** la date de début`,
        EN: `$user **added** the start date`,
        renderType: activityRenderTypes.DATE,
    },
    EDIT_START_DATE_ABSENCE: {
        FR: `$user a **modifié** la date de début`,
        EN: `$user **edited** the start date`,
        renderType: activityRenderTypes.DATE,
    },
    DELETE_START_DATE_ABSENCE: {
        FR: `$user a **supprimé** la date de début`,
        EN: `$user **deleted** the start date`,
        renderType: activityRenderTypes.DATE,
    },
    CREATE_WORK_TIME: {
        FR: `$user a **créé** le temps de travail`,
        EN: `$user **created** the work time`,
        renderType: activityRenderTypes.NONE,
    },
    ADD_START_DATE_WORK_TIME: {
        FR: `$user a **ajouté** la date de début`,
        EN: `$user **added** the start date`,
        renderType: activityRenderTypes.DATE,
    },
    EDIT_START_DATE_WORK_TIME: {
        FR: `$user a **modifié** la date de début`,
        EN: `$user **edited** the start date`,
        renderType: activityRenderTypes.DATE,
    },
    DELETE_START_DATE_WORK_TIME: {
        FR: `$user a **supprimé** la date de début`,
        EN: `$user **deleted** the start date`,
        renderType: activityRenderTypes.DATE,
    },

    // Date fin
    ADD_END_DATE_BOB_EVENT: {
        FR: `$user a **ajouté** la date de fin`,
        EN: `$user **added** the end date`,
        renderType: activityRenderTypes.DATE,
    },
    EDIT_END_DATE_BOB_EVENT: {
        FR: `$user a **modifié** la date de fin`,
        EN: `$user **edited** the end date`,
        renderType: activityRenderTypes.DATE,
    },
    DELETE_END_DATE_BOB_EVENT: {
        FR: `$user a **supprimé** la date de fin`,
        EN: `$user **deleted** the end date`,
        renderType: activityRenderTypes.DATE,
    },
    ADD_END_DATE_ABSENCE: {
        FR: `$user a **ajouté** la date de fin`,
        EN: `$user **added** the end date`,
        renderType: activityRenderTypes.DATE,
    },
    EDIT_END_DATE_ABSENCE: {
        FR: `$user a **modifié** la date de fin`,
        EN: `$user **edited** the end date`,
        renderType: activityRenderTypes.DATE,
    },
    DELETE_END_DATE_ABSENCE: {
        FR: `$user a **supprimé** la date de fin`,
        EN: `$user **deleted** the end date`,
        renderType: activityRenderTypes.DATE,
    },
    ADD_END_DATE_WORK_TIME: {
        FR: `$user a **ajouté** la date de fin`,
        EN: `$user **added** the end date`,
        renderType: activityRenderTypes.DATE,
    },
    EDIT_END_DATE_WORK_TIME: {
        FR: `$user a **modifié** la date de fin`,
        EN: `$user **edited** the end date`,
        renderType: activityRenderTypes.DATE,
    },
    DELETE_END_DATE_WORK_TIME: {
        FR: `$user a **supprimé** la date de fin`,
        EN: `$user **deleted** the end date`,
        renderType: activityRenderTypes.DATE,
    },

    // Archive
    ADD_ARCHIVE: {
        FR: `$user a **ajouté** une archive`,
        EN: `$user **added** an archive`,
    },
    DELETE_DETAIL_ARCHIVE: {
        FR: `$user a **supprimé** une archive`,
        EN: `$user **deleted** an archive`,
    },

    // Marque
    ADD_DETAIL_BRAND: {
        FR: `$user a **ajouté** la marque`,
        EN: `$user **added** the brand`,
    },
    EDIT_DETAIL_BRAND: {
        FR: `$user a **modifié** la marque`,
        EN: `$user **edited** the brand`,
    },
    DELETE_DETAIL_BRAND: {
        FR: `$user a **supprimé** la marque`,
        EN: `$user **deleted** the brand`,
    },

    // Numéro de série
    ADD_DETAIL_SERIAL_NUMBER: {
        FR: `$user a **ajouté** le numéro de série`,
        EN: `$user **added** the serial number`,
    },
    EDIT_DETAIL_SERIAL_NUMBER: {
        FR: `$user a **modifié** le numéro de série`,
        EN: `$user **edited** the serial number`,
    },
    DELETE_DETAIL_SERIAL_NUMBER: {
        FR: `$user a **supprimé** le numéro de série`,
        EN: `$user **deleted** the serial number`,
    },

    // Modèle
    ADD_DETAIL_MODEL: {
        FR: `$user a **ajouté** le modèle`,
        EN: `$user **added** the model`,
    },
    EDIT_DETAIL_MODEL: {
        FR: `$user a **modifié** le modèle`,
        EN: `$user **edited** the model`,
    },
    DELETE_DETAIL_MODEL: {
        FR: `$user a **supprimé** le modèle`,
        EN: `$user **deleted** the model`,
    },

    // Référence interne
    ADD_DETAIL_INTERNAL_REFERENCE: {
        FR: `$user a **ajouté** la référence interne`,
        EN: `$user **added** the internal reference`,
    },
    EDIT_DETAIL_INTERNAL_REFERENCE: {
        FR: `$user a **modifié** la référence interne`,
        EN: `$user **edited** the internal reference`,
    },
    DELETE_DETAIL_INTERNAL_REFERENCE: {
        FR: `$user a **supprimé** la référence interne`,
        EN: `$user **deleted** the internal reference`,
    },

    // Date d`achat
    ADD_DETAIL_BUY_DATE: {
        FR: '$user a **ajouté** la date d`achat',
        EN: `$user **added** the purchase date`,
        renderType: activityRenderTypes.DATE,
    },
    EDIT_DETAIL_BUY_DATE: {
        FR: '$user a **modifié** la date d`achat',
        EN: `$user **edited** the purchase date`,
        renderType: activityRenderTypes.DATE,
    },
    DELETE_DETAIL_BUY_DATE: {
        FR: '$user a **supprimé** la date d`achat',
        EN: `$user **deleted** the purchase date`,
        renderType: activityRenderTypes.DATE,
    },

    // Nombre d`heures
    ADD_DETAIL_HOUR_ACTUAL: {
        FR: '$user a **ajouté** le nombre d`heures',
        EN: `$user **added** the number of hours`,
    },
    EDIT_DETAIL_HOUR_ACTUAL: {
        FR: '$user a **modifié** le nombre d`heures',
        EN: `$user **edited** the number of hours`,
    },
    DELETE_DETAIL_HOUR_ACTUAL: {
        FR: '$user a **supprimé** le nombre d`heures',
        EN: `$user **deleted** the number of hours`,
    },

    // Heure de remplacement
    ADD_DETAIL_HOUR_REPLACEMENT: {
        FR: '$user a **ajouté** l`heure de remplacement',
        EN: `$user **added** the replacement hour`,
    },
    EDIT_DETAIL_HOUR_REPLACEMENT: {
        FR: '$user a **modifié** l`heure de remplacement',
        EN: `$user **edited** the replacement hour`,
    },
    DELETE_DETAIL_HOUR_REPLACEMENT: {
        FR: '$user a **supprimé** l`heure de remplacement',
        EN: `$user **deleted** the replacement hour`,
    },

    // Fournisseur
    ADD_DETAIL_PROVIDER: {
        FR: `$user a **ajouté** le fournisseur`,
        EN: `$user **added** the provider`,
    },
    EDIT_DETAIL_PROVIDER: {
        FR: `$user a **modifié** le fournisseur`,
        EN: `$user **edited** the provider`,
    },
    DELETE_DETAIL_PROVIDER: {
        FR: `$user a **supprimé** le fournisseur`,
        EN: `$user **deleted** the provider`,
    },

    // Fichier
    ADD_FILE_TO_BOB_EVENT: {
        FR: `$user a ajouté des **pièces jointes**`,
        EN: `$user **added** a file`,
        dataType: activityDataTypes.FILE,
        renderType: activityRenderTypes.FILE,
    },
    ADD_FILE_TO_ABSENCE: {
        FR: `$user a ajouté des **pièces jointes**`,
        EN: `$user **added** a file`,
        dataType: activityDataTypes.FILE,
        renderType: activityRenderTypes.FILE,
    },
    ADD_FILE_TO_WORK_TIME: {
        FR: `$user a ajouté des **pièces jointes**`,
        EN: `$user **added** a file`,
        dataType: activityDataTypes.FILE,
        renderType: activityRenderTypes.FILE,
    },

    // Assignation
    ADD_USER_TO_TASK: {
        FR: `$user a **ajouté** un responsable à la tâche`,
        EN: `$user **added** a responsible to the task`,
        renderType: activityRenderTypes.USER,
        dataType: activityDataTypes.USER,
    },
    DELETE_USER_FROM_TASK: {
        FR: `$user a **supprimé** un responsable de la tâche`,
        EN: `$user **deleted** a responsible from the task`,
        renderType: activityRenderTypes.USER,
        dataType: activityDataTypes.USER,
    },

    ADD_USER_SUBSCRIBE_TO_TASK: {
        FR: `$user a **ajouté** un responsable à la tâche`,
        EN: `$user **added** a responsible to the task`,
        renderType: activityRenderTypes.USER,
        dataType: activityDataTypes.USER,
    },
    DELETE_USER_SUBSCRIBE_FROM_TASK: {
        FR: `$user a **supprimé** un responsable de la tâche`,
        EN: `$user **deleted** a responsible from the task`,
        renderType: activityRenderTypes.USER,
        dataType: activityDataTypes.USER,
    },

    // Manager
    ADD_USER_RTQ_TO_TASK: {
        FR: `$user a **ajouté** un manager à la tâche`,
        EN: `$user **added** a manager to the task`,
        dataType: activityDataTypes.USER,
        renderType: activityRenderTypes.USER,
    },
    DELETE_USER_RTQ_FROM_TASK: {
        FR: `$user a **supprimé** un manager de la tâche`,
        EN: `$user **removed** a manager from the task`,
        dataType: activityDataTypes.USER,
        renderType: activityRenderTypes.USER,
    },
    ADD_USER_SUBSCRIBE_RTQ_TO_TASK: {
        FR: `$user a **ajouté** un manager à la tâche`,
        EN: `$user **added** a manager to the task`,
        dataType: activityDataTypes.USER,
        renderType: activityRenderTypes.USER,
    },
    DELETE_USER_SUBSCRIBE_RTQ_FROM_TASK: {
        FR: `$user a **supprimé** un manager de la tâche`,
        EN: `$user **removed** a manager from the task`,
        dataType: activityDataTypes.USER,
        renderType: activityRenderTypes.USER,
    },

    // Power TODO traduction
    ADD_DETAIL_POWER: {
        FR: `$user a **ajouté** la puissance`,
        EN: `$user **added** the power`,
    },
    EDIT_DETAIL_POWER: {
        FR: `$user a **modifié** la puissance`,
        EN: `$user **edited** the power`,
    },
    DELETE_DETAIL_POWER: {
        FR: `$user a **supprimé** la puissance`,
        EN: `$user **deleted** the power`,
    },

    // Famille d`équipement
    ADD_CHECKUP_EQUIPMENT_FAMILY: {
        FR: '$user a **ajouté** la famille d`équipement',
        EN: `$user **added** the equipment family`,
        renderType: activityRenderTypes.BADGE,
        dataType: activityDataTypes.TRANSLATION,
    },
    DELETE_CHECKUP_EQUIPMENT_FAMILY: {
        FR: '$user a **supprimé** la famille d`équipement',
        EN: `$user **deleted** the equipment family`,
        renderType: activityRenderTypes.BADGE,
        dataType: activityDataTypes.TRANSLATION,
    },

    // Equipement
    ADD_CHECKUP_EQUIPMENT: {
        FR: '$user a **ajouté** l`équipement',
        EN: `$user **added** the equipment`,
        dataType: activityDataTypes.TRANSLATION,
        renderType: activityRenderTypes.BADGE,
    },
    DELETE_CHECKUP_EQUIPMENT: {
        FR: '$user a **modifié** l`équipement',
        EN: `$user **edited** the equipment`,
        dataType: activityDataTypes.TRANSLATION,
        renderType: activityRenderTypes.BADGE,
    },

    // Checkup part
    ADD_CHECKUP_PART: {
        FR: `$user a **ajouté** une pièce`,
        EN: `$user **added** the equipment part`,
        renderType: activityRenderTypes.BADGE,
        dataType: activityDataTypes.TRANSLATION,
    },
    DELETE_CHECKUP_PART: {
        FR: `$user a **modifié** une pièce`,
        EN: `$user **edited** the equipment part`,
        renderType: activityRenderTypes.BADGE,
        dataType: activityDataTypes.TRANSLATION,
    },

    // Checkup
    EDIT_CHECKUP: {
        FR: '$user a **modifié** l`entretien',
        EN: `$user **edited** the checkup`,
        renderType: activityRenderTypes.BADGE,
    },

    // Type d`évènement
    EDIT_BOB_EVENT_TYPE: {
        FR: '$user a **modifié** le type d`évènement',
        EN: `$user **edited** the event type`,
        dataType: activityDataTypes.TRANSLATION,
    },

    // Titre
    EDIT_BOB_EVENT_TITLE: {
        FR: '$user a **modifié** le titre de l`évènement',
        EN: `$user **edited** the event title`,
    },

    EDIT_START_TIME: {
        FR: '$user a **modifié** l`heure de début',
        EN: `$user **edited** the start time`,
    },
    EDIT_END_TIME: {
        FR: '$user a **modifié** l`heure de fin',
        EN: `$user **edited** the end time`,
    },
    ADD_BREAK_START_TIME: {
        FR: `$user a **ajouté** une heure de début de pause`,
        EN: `$user **added** a break start time`,
    },
    ADD_BREAK_END_TIME: {
        FR: `$user a **ajouté** une heure de fin de pause`,
        EN: `$user **added** a break end time`,
    },
    DELETE_BREAK_START_TIME: {
        FR: `$user a **supprimé** une heure de début de pause`,
        EN: `$user **deleted** a break start time`,
    },
    DELETE_BREAK_END_TIME: {
        FR: `$user a **supprimé** une heure de fin de pause`,
        EN: `$user **deleted** a break end time`,
    },
    EDIT_WORK_TIME_COMMENT: {
        FR: `$user a **modifié** un commentaire`,
        EN: `$user **edited** a comment`,
    },
    ADD_WORK_TIME_COMMENT: {
        FR: `$user a **ajouté** un commentaire`,
        EN: `$user **added** a comment`,
    },
    DELETE_WORK_TIME_COMMENT: {
        FR: `$user a **supprimé** un commentaire`,
        EN: `$user **deleted** a comment`,
    },

    //Navigations
    CREATE_NAVIGATION: {
        FR: '$user a **créé** une navigation',
        EN: '$user **created** a navigation',
        renderType: activityRenderTypes.DATE,
    },
    EDIT_DEPARTING_PASSENGERS: {
        FR: '$user a **modifié** le nombre de passagers de départ',
        EN: '$user **edited** the number of departing passengers',
        renderValue: (parent, value) => {
            if (parseInt(value) > 0)
                return (
                    <div>
                        <b>↗</b>&nbsp;
                        {value} {parent.displayText('onboardPassengers')}
                    </div>
                )
            else
                return (
                    <div>
                        <b>↗</b>&nbsp;
                        {value || '0'} {parent.displayText('onboardPassenger')}
                    </div>
                )
        },
    },
    EDIT_DEPARTURE_ADDRESS: {
        FR: `$user a **modifié** l'adresse de départ de la navigation`,
        EN: '$user **edited** departure address',
    },
    ADD_NAVIGATION_COMMENT: {
        FR: '$user a **ajouté** un commentaire',
        EN: '$user **added** a comment',
    },
    EDIT_NAVIGATION_COMMENT: {
        FR: '$user a **modifié** un commentaire',
        EN: '$user **edited** a comment',
    },
    DELETE_NAVIGATION_COMMENT: {
        FR: '$user a **supprimé** un commentaire',
        EN: '$user **deleted** a comment',
    },
    EDIT_START_DATE_NAVIGATION: {
        FR: '$user a **modifié** la date de début de la navigation',
        EN: '$user **edited** the start date of the navigation',
        renderType: activityRenderTypes.DATETIME,
    },
    ADD_ARRIVAL_ADDRESS: {
        FR: `$user a **ajouté** une adresse d'arrivée de la navigation`,
        EN: '$user **added** the arrival address of the navigation',
    },
    EDIT_ARRIVAL_ADDRESS: {
        FR: `$user a **modifié** l'adresse d'arrivée de la navigation`,
        EN: '$user **edited** the arrival address of the navigation',
    },
    DELETE_ARRIVAL_ADDRESS: {
        FR: `$user a **supprimé** l'adresse d'arrivée de la navigation`,
        EN: '$user **deleted** the arrival address of the navigation',
    },
    ADD_END_DATE_NAVIGATION: {
        FR: '$user a **ajouté** la date de fin de la navigation',
        EN: '$user **added** the end date of the navigation',
        renderType: activityRenderTypes.DATETIME,
    },
    EDIT_END_DATE_NAVIGATION: {
        FR: '$user a **modifié** la date de fin de la navigation',
        EN: '$user **edited** the end date of the navigation',
        renderType: activityRenderTypes.DATETIME,
    },
    DELETE_END_DATE_NAVIGATION: {
        FR: '$user a **supprimé** la date de fin de la navigation',
        EN: '$user **deleted** the end date of the navigation',
        renderType: activityRenderTypes.DATETIME,
    },
    END_NAVIGATION: {
        FR: '$user a **terminé** la navigation',
        EN: '$user **ended** the navigation',
        renderType: activityRenderTypes.DATETIME,
    },
    ADD_STOP_OVER_START_DATE: {
        FR: `$user a **ajouté** une date d'arrivée à une escale`,
        EN: '$user **added** an arrival date in a stop over',
        renderType: activityRenderTypes.DATETIME,
    },
    EDIT_STOP_OVER_START_DATE: {
        FR: `$user a **modifié** une date d'arrivée à une escale`,
        EN: '$user **modified** an arrival date in a stop over',
        renderType: activityRenderTypes.DATETIME,
    },
    ADD_STOP_OVER_ADDRESS: {
        FR: '$user a **ajouté** une adresse à une escale',
        EN: '$user **added** an address in a stop over',
    },
    EDIT_STOP_OVER_ADDRESS: {
        FR: '$user a **modifié** une adresse à une escale',
        EN: '$user **modified** an address in a stop over',
    },
    ADD_BOARDING_PASSENGERS: {
        FR: '$user a **ajouté** des passagers montant à une escale',
        EN: '$user **added** boarding passengers in a stop over',
        renderValue: (parent, value) => {
            if (parseInt(value) > 0)
                return (
                    <div>
                        <b>↗</b>&nbsp;
                        {value} {parent.displayText('onboardPassengers')}
                    </div>
                )
            else
                return (
                    <div>
                        <b>↗</b>&nbsp;
                        {value || '0'} {parent.displayText('onboardPassenger')}
                    </div>
                )
        },
    },
    EDIT_BOARDING_PASSENGERS: {
        FR: '$user a **modifié** des passagers montant à une escale',
        EN: '$user **modified** boarding passengers in a stop over',
        renderValue: (parent, value) => {
            if (parseInt(value) > 0)
                return (
                    <div>
                        <b>↗</b>&nbsp;
                        {value} {parent.displayText('onboardPassengers')}
                    </div>
                )
            else
                return (
                    <div>
                        <b>↗</b>&nbsp;
                        {value || '0'} {parent.displayText('onboardPassenger')}
                    </div>
                )
        },
    },
    ADD_LEAVING_PASSENGERS: {
        FR: '$user a **ajouté** des passagers descendant à une escale',
        EN: '$user **added** leaving passengers in a stop over',
        renderValue: (parent, value) => {
            if (parseInt(value) > 0)
                return (
                    <div>
                        <b>↘</b>&nbsp;
                        {value} {parent.displayText('leavingPassengers')}
                    </div>
                )
            else
                return (
                    <div>
                        <b>↘</b>&nbsp;
                        {value || '0'} {parent.displayText('leavingPassenger')}
                    </div>
                )
        },
    },
    EDIT_LEAVING_PASSENGERS: {
        FR: '$user a **modifié** des passagers descendant à une escale',
        EN: '$user **modified** leaving passengers in a stop over',
        renderValue: (parent, value) => {
            if (parseInt(value) > 0)
                return (
                    <div>
                        <b>↘</b>&nbsp;
                        {value} {parent.displayText('leavingPassengers')}
                    </div>
                )
            else
                return (
                    <div>
                        <b>↘</b>&nbsp;
                        {value || '0'} {parent.displayText('leavingPassenger')}
                    </div>
                )
        },
    },
    ADD_STOP_OVER_END_DATE: {
        FR: `$user a **ajouté** une date de départ à une escale`,
        EN: '$user **added** an departure date in a stop over',
        renderType: activityRenderTypes.DATETIME,
    },
    EDIT_STOP_OVER_END_DATE: {
        FR: `$user a **modifié** une date de départ à une escale`,
        EN: '$user **modified** a departure date in a stop over',
        renderType: activityRenderTypes.DATETIME,
    },
    DELETE_STOP_OVER_END_DATE: {
        FR: `$user a **supprimé** une date de départ à une escale`,
        EN: '$user **deleted** a departure date in a stop over',
        renderType: activityRenderTypes.DATETIME,
    },
    CREATE_WORKING_EQUIPMENT_HOURS: {
        FR: `$user a **créé** un temps d'utilisation dans les equipements utilisés`,
        EN: '$user **created** a working time about equipements used',
        renderValue: (parent, value, activity) => {
            const currentNav = parent.props.event
            const currentWorktimeID =
                activity.linkBobActivityLogsAdditionalValues[0]
                    ?.workingEquipmentHourId
            const worktimeHours = currentNav?.workingEquipmentHours?.find(
                item => item.id === currentWorktimeID,
            )
            const equipementsId = worktimeHours?.equipment?.id
            const events = parent?.props?.events
            if (!events) return parent.displayText('loading')
            const currentEvent = events.find(
                event =>
                    event?.detail?.equipment?.id &&
                    event?.detail?.equipment?.id === equipementsId,
            )

            return (
                <div>
                    {`${getTranslatedTitleFromEvent(
                        parent,
                        currentEvent,
                    )} : ${value || 0} h`}
                </div>
            )
        },
    },
    UPDATE_WORKING_EQUIPMENT_HOURS: {
        FR: `$user a **modifié** un temps d'utilisation dans les equipements utilisés`,
        EN: '$user **modified** a working time about equipements used',
        renderValue: (parent, value, activity) => {
            const currentNav = parent.props.event
            const currentWorktimeID =
                activity.linkBobActivityLogsAdditionalValues[0]
                    ?.workingEquipmentHourId
            const worktimeHours = currentNav?.workingEquipmentHours?.find(
                item => item.id === currentWorktimeID,
            )
            const equipementsId = worktimeHours?.equipment?.id
            const events = parent?.props?.events
            if (!events) return parent.displayText('loading')
            const currentEvent = events.find(
                event =>
                    event?.detail?.equipment?.id &&
                    event?.detail?.equipment?.id === equipementsId,
            )
            return (
                <div>
                    {`${getTranslatedTitleFromEvent(
                        parent,
                        currentEvent,
                    )} : ${value || 0} h`}
                </div>
            )
        },
    },
    DELETE_WORKING_EQUIPMENT_HOURS: {
        FR: `$user a **supprimé** un temps d'utilisation dans les equipements utilisés`,
        EN: '$user **deleted** a working time about equipements used',
        renderValue: (parent, value, activity) => {
            const currentNav = parent.props.event
            const currentWorktimeID =
                activity.linkBobActivityLogsAdditionalValues[0]
                    ?.workingEquipmentHourId
            const worktimeHours = currentNav?.workingEquipmentHours?.find(
                item => item.id === currentWorktimeID,
            )
            const equipementsId = worktimeHours?.equipment?.id
            const events = parent?.props?.events
            if (!events) return parent.displayText('loading')
            const currentEvent = events.find(
                event =>
                    event?.detail?.equipment?.id &&
                    event?.detail?.equipment?.id === equipementsId,
            )

            return (
                <div>
                    {`${getTranslatedTitleFromEvent(
                        parent,
                        currentEvent,
                    )} : ${value || 0} h`}
                </div>
            )
        },
    },
    CREATE_FILLING_LEVEL: {
        FR: '$user a **créé** un niveau de réserve',
        EN: '$user **created** a filling level',
        renderValue: (parent, value, activity) => {
            const currentNav = parent.props.event
            const currentFillingID =
                activity.linkBobActivityLogsAdditionalValues[0]?.fillingLevelId
            const fillingLevel = currentNav.fillingLevels.find(
                item => item.id === currentFillingID,
            )
            const equipementsId = fillingLevel?.equipment.id
            const events = parent.props.events
            if (!events) return parent.displayText('loading')
            const currentEvent = events.find(
                event =>
                    event?.detail?.equipment?.id &&
                    event?.detail?.equipment?.id === equipementsId,
            )

            const name =
                getTranslatedTitleFromEvent(parent, currentEvent) ||
                parent.displayText('unknownEquipment')

            return <div>{`${name} : ${value || 0} %`}</div>
        },
    },
    UPDATE_FILLING_LEVEL: {
        FR: '$user a **modifié** un niveau de réserve',
        EN: '$user **modified** a filling level',
        renderValue: (parent, value, activity) => {
            const currentNav = parent?.props?.event
            const currentFillingID =
                activity?.linkBobActivityLogsAdditionalValues[0]?.fillingLevelId
            const fillingLevel = currentNav?.fillingLevels?.find(
                item => item.id === currentFillingID,
            )
            const equipementsId = fillingLevel?.equipment?.id
            const events = parent?.props?.events
            if (!events) return parent.displayText('loading')
            const currentEvent = events.find(
                event =>
                    event?.detail?.equipment?.id &&
                    event?.detail?.equipment?.id === equipementsId,
            )

            const name =
                getTranslatedTitleFromEvent(parent, currentEvent) ||
                parent.displayText('unknownEquipment')

            return <div>{`${name} : ${value || 0} %`}</div>
        },
    },
    DELETE_FILLING_LEVEL: {
        FR: '$user a **supprimé** un niveau de réserve',
        EN: '$user **deleted** a filling level',
        renderValue: (parent, value, activity) => {
            const currentNav = parent?.props?.event
            const currentFillingID =
                activity?.linkBobActivityLogsAdditionalValues[0]?.fillingLevelId
            const fillingLevel = currentNav?.fillingLevels?.find(
                item => item.id === currentFillingID,
            )
            const equipementsId = fillingLevel?.equipment?.id
            const events = parent?.props?.events
            if (!events) return parent.displayText('loading')
            const currentEvent = events.find(
                event =>
                    event?.detail?.equipment?.id &&
                    event?.detail?.equipment?.id === equipementsId,
            )

            const name =
                getTranslatedTitleFromEvent(parent, currentEvent) ||
                parent.displayText('unknownEquipment')

            return <div>{`${name} : ${value || 0} %`}</div>
        },
    },
    //Order
    CREATE_ORDER: {
        FR: `$user a **créé** une commande`,
        EN: `$user **created** an order`,
        renderType: activityRenderTypes.DATE,
    },
    UPDATE_ORDER_STATUS: {
        FR: `$user a **modifié** le status de la commande`,
        EN: `$user **modified** the order status`,
        renderValue: (parent, value) => {
            const orderType = parent.props.orderStatusTypes.find(
                type => type.id === parseInt(value),
            )

            if (!orderType) return parent.displayText('unknownStatus')

            return parent.displayTextApi(orderType.translation)
        },
    },
    CANCEL_ORDER: {
        FR: `$user a **annulé** la commande`,
        EN: `$user **cancel** the order`,
        renderType: activityRenderTypes.NONE,
    },
    UPDATE_AMOUNT_ORDER: {
        FR: `$user a **modifié** le montant de la commande`,
        EN: `$user **modified** the order amount`,
        renderValue: (_, value) => {
            return `${parseFloat(value).toFixed(2)} €`
        },
    },
    UPDATE_MANAGER_ORDER: {
        FR: `$user a **modifié** le manager la commande`,
        EN: `$user **modified** the manager of the order`,
        renderValue: (parent, value) => (
            <div className={parent.props.classes.badgeContainer}>
                {parent._renderUserBadge({
                    user: parent.props.linkedUsers.find(
                        user => user.id === parseInt(value),
                    )?.user,
                    showName: true,
                })}
            </div>
        ),
    },

    // Dico
    save: {
        FR: `Enregistrer`,
        EN: `Save`,
    },

    task_created: {
        FR: `$user a **créé** la tâche`,
        EN: `$user **created** the task`,
    },
    document_created: {
        FR: `$user a **créé** le document`,
        EN: `$user **created** the document`,
    },
    transaction_created: {
        FR: `$user a **créé** la transaction`,
        EN: `$user **created** the transaction`,
    },
    worktime_created: {
        FR: `$user a **créé** le temps de travail`,
        EN: `$user **created** the work time`,
    },
    leave_created: {
        FR: `$user a **créé** la demande d'absence`,
        EN: `$user **created** the leave request`,
    },
    cancel: {
        FR: `Annuler`,
        EN: `Cancel`,
    },
    edit: {
        FR: `Modifier`,
        EN: `Edit`,
    },
    delete: {
        FR: `Supprimer`,
        EN: `Delete`,
    },
    showDetails: {
        FR: `Afficher les détails`,
        EN: `Show details`,
    },
    hideDetails: {
        FR: `Masquer les détails`,
        EN: `Hide details`,
    },
    validateDelete: {
        FR: `Voulez-vous vraiment supprimer ce commentaire ?`,
        EN: `Do you really want to delete this comment ?`,
    },
    showAll: {
        FR: `Voir tout`,
        EN: `Show all`,
    },
    showLess: {
        FR: `Voir moins`,
        EN: `Show less`,
    },
    loading: {
        FR: 'Chargement ...',
        EN: 'Loading ...',
    },

    //Navigation
    onboardPassengers: {
        FR: 'passagers montés',
        EN: 'passengers onboarded',
    },
    onboardPassenger: {
        FR: 'passager monté',
        EN: 'passenger onboarded',
    },
    leavingPassengers: {
        FR: 'passagers descendus',
        EN: 'passengers leaved',
    },
    leavingPassenger: {
        FR: 'passager descendu',
        EN: 'passenger leaved',
    },
    unknownEquipment: {
        FR: 'Équipement inconnu',
        EN: 'Unknown equipment',
    },
    unknownStatus: {
        FR: 'Statut inconnu',
        EN: 'Unknown status',
    },

    EDIT_ABSENCE_STATUS: {
        FR: 'Le statut de la demande d`absence a été modifié',
        EN: `The leave request status has been modified`,
        renderType: activityRenderTypes.BADGE,
        messageOverrideFunction: item => {
            if (item.newTranslation.en === 'Accepted')
                return 'ABSENCE_STATUS_ACCEPTED'
            else if (item.newTranslation.en === 'Refused')
                return 'ABSENCE_STATUS_REFUSED'
        },
    },
    ABSENCE_STATUS_ACCEPTED: {
        FR: `$user a **validé** la demande d'absence`,
        EN: `$user **accepted** the leave request`,
    },
    ABSENCE_STATUS_REFUSED: {
        FR: `$user a **refusé** la demande d'absence`,
        EN: `$user **refused** the leave request`,
    },
}
