import { red } from '@material-ui/core/colors'

export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0,
            marginTop: 0,
        },
    },
    secondLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    searchFilterUnfold: {
        display: 'flex',
        width: '100%',
        minWidth: '475px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: '0',
            flexDirection: 'row',
            gap: '10px',
            flexWrap: 'wrap',
        },
    },
    recherche: {
        width: '40%',
        minWidth: '300px',
        marginRight: '10px',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 150px)',
            marginRight: 5,
            minWidth: '0',
        },
    },
    field: {
        width: '100%',
        margin: 'auto',
        fontSize: '16px',
    },
    filtersButton: {
        border: 0,
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    filtersMobileButton: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    unfoldAll: {
        width: '35%',
        minWidth: '350px',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'left',
            fontSize: '0.8em',
            minWidth: '0',
        },
    },
    hidde: {
        display: 'none',
    },
    filtersBar: {
        display: 'flex',
        marginTop: '15px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '5px',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        gap: 8,
    },
    filterButton: {
        backgroundColor: 'white',
        border: '1px solid #303F9F',
        margin: '0 20px',
        color: '#212121',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            margin: '0 10px',
        },
        fontSize: 14,
    },
    filterButtonFill: {
        backgroundColor: '#303F9F',
        border: '1px solid #303F9F',
        color: 'white',
        margin: '0 20px',
        fontWeight: 500,
        fontSize: 14,
    },
    divider: {
        marginTop: '5px',
        marginBottom: '25px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '5px',
        },
    },

    filterParts: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    textParts: {
        marginTop: '7px',
    },
    controlLabel: {
        marginLeft: '10px',
        marginRight: 0,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
    },
    paper: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '13px',
            maxWidth: '100vh',
            marginLeft: '0%',
            overflowY: 'hidden',
        },
    },
    rootPaper: {
        [theme.breakpoints.down('xs')]: {
            '& .MuiPopover-paper ': {
                position: 'initial',
                marginTop: '69px',
            },
        },
    },
    searchAndFilter: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
            width: '100%',
            '& .MuiButton-label': {
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
            },
        },
    },
    editableButton: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    boatsSelector: {
        minWidth: 300,
        '& div > div > div': {
            marginBottom: '0px !important',
        },
    },
    fullWidth: {
        width: 'unset',
    },
})
