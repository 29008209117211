import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../../common/BoatOnModalCore'
import BoatOnNumberField from '../../../../../common/UsefullComponents/BoatOnNumberField'
import InputAdornment from '@material-ui/core/InputAdornment'

import dictionary from './Dictionary/EditWorktimesModalDico'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/EditWorktimesModalCss'
import { editNavigation } from '../../../../../../actions'
import { getWorkingHours } from '../../Utils/eventsFilter'
import { getTranslatedTitleFromEvent, isSafariIOS } from '../../../Utils'

class EditWorktimesModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            navigation: null,
            equipmentInUse: [],
            updated: false,
        }

        this.save = this.save.bind(this)
        this.handleChangeValue = this.handleChangeValue.bind(this)
    }

    componentDidMount() {
        const { navigation, events } = this.props

        if (events) this._initState()

        this.setState({ navigation })
    }

    _initState() {
        const { events } = this.props
        const workingHours = getWorkingHours(events)

        this.setState({
            equipmentInUse: workingHours.equipmentInUse,
        })
    }

    save() {
        const { navigation } = this.state
        const { dispatch, onClose } = this.props

        dispatch(editNavigation(navigation))

        onClose()
    }

    handleChangeValue(value, equipmentId) {
        const navigation = JSON.parse(JSON.stringify(this.state.navigation))
        const index = navigation.workingEquipmentHours.findIndex(
            item => item.equipment.id === equipmentId,
        )

        if (value === '') value = 0

        if (index === -1) {
            navigation.workingEquipmentHours.push({
                equipment: { id: equipmentId },
                hours: value,
            })
        } else {
            navigation.workingEquipmentHours[index].hours = value
        }

        this.setState({
            navigation,
            updated: true,
        })
    }

    _renderBody() {
        const { classes, context, navigation } = this.props
        const { equipmentInUse } = this.state

        const renderEquipment = equipmentInUse
            .sort((a, b) => {
                const aName = getTranslatedTitleFromEvent(this, a)
                const bName = getTranslatedTitleFromEvent(this, b)

                if (!aName) return 1
                if (!bName) return -1

                return aName.localeCompare(bName)
            })
            .map((event, index) => {
                const equipment = event.detail?.equipment || null

                if (!equipment) return null

                const imgSrc = equipment.equipmentFamily?.type?.image
                    ? require(`images/${equipment.equipmentFamily?.type?.image}`)
                    : null
                const familyType =
                    equipment.equipmentFamily?.type?.translation[context] || ''
                const value = navigation.workingEquipmentHours.find(
                    item => item.equipment.id === equipment.id,
                )?.hours

                return (
                    <div
                        className={classes.equipmentWrapper}
                        key={`equipment-${index}`}
                    >
                        <img
                            src={imgSrc}
                            alt={familyType}
                            className={classes.equipmentIcon}
                        />
                        <div className={classes.equipmentDetails}>
                            <div className={classes.equipmentLabel}>
                                {getTranslatedTitleFromEvent(this, event)}
                            </div>
                            <BoatOnNumberField
                                isDecimal
                                label={this.displayText('worktime')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            h
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                margin="dense"
                                onChange={e =>
                                    this.handleChangeValue(
                                        e.target.value,
                                        equipment.id,
                                    )
                                }
                                value={value}
                                fullWidth={true}
                            />
                        </div>
                    </div>
                )
            })

        return (
            <div
                className={`${classes.root} ${
                    isSafariIOS() ? classes.iosRoot : ''
                }`}
            >
                <div className={classes.title}>
                    {this.displayText('titleWorktimes')}
                </div>
                <div className={classes.worktimesWrapper}>
                    {renderEquipment}
                </div>
            </div>
        )
    }

    render() {
        const { title, noCross = true, showFollowButton } = this.props
        const { updated } = this.state

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    null,
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    body: this._renderBody(),
                })}
                {this._renderActions([
                    {
                        action: this.save,
                        disabled: !updated,
                        label: this.displayText('save'),
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const id = url.split('/').slice(-1)[0]
    const navigation = state.logbook.navigations.find(
        nav => nav.id === parseInt(id),
    )

    return {
        navigation,
        events: state.bob?.events || [],
    }
}

export default connect(mapStateToProps)(withStyles(styles)(EditWorktimesModal))
