export default theme => ({
    // paper: {
    //     position: 'absolute',
    //     top: '50%',
    //     left: '50%',
    //     transform: 'translate(-50%, -50%)',
    //     width: '40vw',
    //     backgroundColor: theme.palette.background.paper,
    //     boxShadow: theme.shadows[5],
    //     padding: theme.spacing(4),
    //     outline: 'none',
    //     [theme.breakpoints.down('sm')]: {
    //         width: '65vw',
    //     },
    // },
    // preChoice: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    // },
    // dividers: {
    //     display: 'flex',
    //     flexDirection: 'row',
    //     justifyContent: 'center',
    //     width: '80%',
    //     marginTop: '25px',
    //     marginBottom: '25px',
    //     [theme.breakpoints.down('sm')]: {
    //         fontSize: '12px',
    //     },
    // },
    // divider: {
    //     width: '50%',
    //     marginTop: 'auto',
    //     marginBottom: 'auto',
    //     marginRight: '20px',
    //     marginLeft: '20px',
    //     [theme.breakpoints.down('sm')]: {
    //         fontSize: '12px',
    //     },
    // },
    // button: {
    //     width: '50%',
    //     [theme.breakpoints.down('sm')]: {
    //         width: '70%',
    //         fontSize: '12px',
    //     },
    // },
    // helpText: {
    //     marginBottom: '25px',
    //     textAlign: 'center',
    //     [theme.breakpoints.down('sm')]: {
    //         fontSize: '12px',
    //     },
    // },
    // subscribeTypeBox: {
    //     margin: '15px -32px',
    // },
    // subscribeType: {
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     margin: '7px 32px',
    // },
    // subTypeDetails: {
    //     flex: '1',
    //     display: 'flex',
    //     flexDirection: 'column',
    // },
    // afterFreeTryText: {
    //     fontSize: '14px',
    //     lineHeight: '16px',
    // },
    // subTextDetails: {
    //     fontSize: '20px',
    //     fontWeight: '800',
    //     lineHeight: '23px',
    // },
    // subPricing: {
    //     fontSize: '20px',
    //     fontWeight: '800',
    // },
})
