export default theme => ({
    modal: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        textAlign: 'center',
        justifyContent: 'center',
        marginRight: 'auto',
        marginLeft: 'auto',
    },
    button: Object.assign({ marginTop: '20px' }, theme.button.primary),
    checkTerms: {
        width: '100%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        marginRight: '20px',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            alignItems: 'flex-start',
        },
    },
    terms: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.8em',
            marginTop: 10,
        },
    },
    accept: {
        display: 'flex',
        flexWrap: 'wrap',
    },
})
