export default {
    save: {
        FR: 'Enregistrer',
        EN: `Save`,
    },
    titleCrew: {
        FR: `Membres de l'équipage`,
        EN: `Crew's members`,
    },
    captain: {
        FR: 'Capitaine',
        EN: 'Captain',
    },
    helpCaptain: {
        FR: 'Capitaine du trajet',
        EN: 'Captain of the travel',
    },
    crewInput: {
        FR: `Autres membres d'équipage`,
        EN: 'Other members of the crew',
    },
    helpCrew: {
        FR: `Reste de l'équipage sur le bateau`,
        EN: 'Rest of the crew on the boat',
    },
}
