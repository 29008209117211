import { withStyles } from '@material-ui/styles'
import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import styles from './Styles/PersoNewUserModalCss'
import dictionary from './Dictionary/PersoNewUserModalDictionary'
import { Button } from '../../../common'
import { Typography } from '@material-ui/core'
import BoatOnAlert from '../../../common/UsefullComponents/BoatOnAlert'

class PersoNewUserModal extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.container}>
                <Typography className={classes.title}>
                    {' '}
                    {this.displayText(`new1`)} {this.props.role}{' '}
                    {this.displayText(`new2`)}{' '}
                </Typography>
                <BoatOnAlert
                    severity={`info`}
                    description={this.displayText(`descr`)}
                />
                <div className={classes.buttons}>
                    <Typography
                        className={classes.button1}
                        onClick={this.props.closePerso}
                    >
                        {' '}
                        {this.displayText(`noPerso`)}{' '}
                    </Typography>
                    <Button
                        classNameOverride={classes.button2}
                        label={this.displayText(`perso`)}
                        onClick={this.props.openPerso}
                    />
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(PersoNewUserModal)
