export default theme => ({
    root: {
        textAlign: `center`,
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        flexDirection: `column`,
        '& p': {
            margin: 0,
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: `row`,
        },
    },
    year: {
        fontSize: `12px`,
        lineHeight: `12px`,
    },
    month: {
        fontSize: `12px`,
        lineHeight: `12px`,
    },
    day: {
        fontSize: `18px`,
        fontWeight: `500`,
        lineHeight: `19px`,
        [theme.breakpoints.down('sm')]: {
            fontSize: `12px`,
            fontWeight: `500`,
            margin: `0 4px !important`,
            lineHeight: `12px`,
        },
    },
})
