export default theme => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
    hourRow: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        padding: 10,
        alignItems: 'center',
        '&:hover': {
            background: 'lightgrey',
            cursor: 'pointer',
        },
    },
    selected: {
        background: 'lightgrey',
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    textButton: {
        fontSize: 14,
        alignSelf: 'flex-start',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
    },
    tooltip: {
        backgroundColor: '#3040a0',
        color: 'white',
        maxWidth: 300,
        border: '1px solid #dadde9',
        fontSize: '15px',
    },
    helpIcon: {
        marginTop: `-10px`,
        width: `20px`,
        height: `20px`,
        marginRight: `3px`,
        marginLeft: `3px`,
    },
    footer: {
        padding: `16px 24px`,
        alignItems: `center`,
        justifyContent: 'space-between',
    },
    totalTime: {
        fontWeight: 500,
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
    },
    allDayContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    absenceDurationContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    addDoc: {
        border: '1px dashed #c4c4c4',
        margin: '10px 0px',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            margin: '5px',
        },
    },
    disabled: {
        cursor: 'default !important',
    },
    doc: {
        margin: 'auto',
        display: 'block',
        padding: '10px',
        width: '50px',
        height: '50px',
        color: '#C4C4C4',
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
        gap: 0,
    },
    hoursAndMinutes: {
        display: 'flex',
        width: '100%',
        gap: 10,
    },
    flexColumnSpacebetween: theme.flex.rowSpacebetween,
    iconButton: {
        color: '#212121',
        width: 16,
        height: 16,
    },
    error: {
        color: '#d32f2f',
    },
})
