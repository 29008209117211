export default {
    crew: {
        FR: 'Équipage',
        EN: 'Crew',
    },
    stockLevel: {
        FR: 'Niveau réserves',
        EN: 'Filling levels',
    },
    cancel: {
        FR: 'Annuler',
        EN: 'Cancel',
    },
    back: {
        FR: 'Retour',
        EN: 'Back',
    },
    next: {
        FR: 'Suivant',
        EN: 'Next',
    },
    save: {
        FR: 'Enregistrer',
        EN: 'Save',
    },
    titleBoat: {
        FR: 'Bateau',
        EN: 'Boat',
    },
    titleCrew: {
        FR: "Membres de l'équipage",
        EN: "Crew's members",
    },
    captain: {
        FR: 'Capitaine',
        EN: 'Captain',
    },
    helpCaptain: {
        FR: 'Capitaine du trajet',
        EN: 'Captain of the travel',
    },
    crewInput: {
        FR: `Autres membres d'équipage`,
        EN: 'Other members of the crew',
    },
    helpCrew: {
        FR: `Reste de l'équipage sur le bateau`,
        EN: 'Rest of the crew on the boat',
    },
    stockLevelTitle: {
        FR: 'Vérifier les capacités et les niveaux de vos équipements.',
        EN: 'Check capacities and filling levels of yours equipments.',
    },
    workingTimeTitle: {
        FR: `Mettez à jour le nombre d'heures des équipements ci-dessous :`,
        EN: 'Update the worktime of your equipments below :',
    },
    worktime: {
        FR: `Nombre d'heures`,
        EN: 'Worktimes',
    },
    actualLevel: {
        FR: 'Niveau actuel',
        EN: 'Actual level',
    },
    capacity: {
        FR: 'Cap.',
        EN: 'Cap.',
    },
    addLabelButton: {
        FR: `Ajouter un utilisateur`,
        EN: `Add a user`,
    },
    newUserTitle: {
        FR: `Invitation d'un nouvel utilisateur`,
        EN: `Invite a new user`,
    },
    missingCapacity: {
        FR: `Il semble que la capacité de cet équipement n’a pas été complétée. Rendez-vous dans la page “Inventaire” pour la renseigner`,
        EN:
            'It seems that the capacity of this equipment has not been completed. Go to the "Inventory" page to fill it in.',
    },
}
