import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Button } from '../../common/BoatOnButton'
import BoatOnComponent from '../../common/BoatOnComponent'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import styles from './Styles/ParkingTabCss'
import dictionary from './Dictionary/ParkingTabDico'
import StatusTitle from '../../common/StatusTitle'
import BoatOnImageRender from '../../common/BoatOnImageRender'
import BoatOnModal from '../../common/BoatOnModal'
import ParkingModal from './ParkingModal'
import { getCurrentRouteKey } from '../../../languages/LocalizerUtils'
import AppRoute from '../../../constants/AppRoute'
import ChatingPage from '../ChatingPage'
import { history } from '../../../helpers'
import { parkingActions } from '../../../actions/parkings/parking.actions'
import { inqueryActions } from '../../../actions/inqueries/inquery.actions'
import { inqueriesActions } from '../../../actions/inqueries/inqueries.actions'

class ParkingTab extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            inquerySelected: false,
            openModal: null,
        }

        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.getParkingWithParkingId = this.getParkingWithParkingId.bind(this)
        this.handleOpenModalInfo = this.handleOpenModalInfo.bind(this)
        this.askInquery = this.askInquery.bind(this)
        this.makeNewInquery = this.makeNewInquery.bind(this)
    }

    componentDidMount() {
        const { parkingInqueries, loading } = this.props
        if (parkingInqueries.length === 0 && loading === 0)
            this.props.dispatch(inqueriesActions.getPersonalParkingInqueries())
    }

    componentShouldUpdate() {
        const { openModal } = this.state
        if (!openModal) return true
        return false
    }

    handleCloseModal() {
        this.setState({
            openModal: null,
        })
        this.props.dispatch(inqueryActions.unsetInquery())
    }

    handleOpenModalInfo(inquery) {
        this.setState({ openModal: `parkingModal` })
        this.props.dispatch(inqueryActions.setInquery(inquery))
    }

    getParkingWithParkingId(parkingId) {
        const { parkings } = this.state

        for (const key in parkings) {
            if (key === parkingId) {
                return parkings[key]
            }
        }
        return null
    }

    makeNewInquery() {
        this.historyPush(history, AppRoute.ParkingSearchPage)
    }

    // render button en bas pour ajouter des demandes
    askInquery(stateInquery) {
        const { classes } = this.props

        return (
            <Button
                className={classes.buttonNewInquery}
                type="primary"
                onClick={this.makeNewInquery}
                label={
                    stateInquery === 'noInquery'
                        ? this.displayText('makeFirst')
                        : this.displayText('makeNew')
                }
            />
        )
    }

    redirectNoDetails() {
        this.historyPush(history, AppRoute.InqueriesParking)
        this.forceUpdate()
    }

    renderTitle(inquery) {
        const { classes } = this.props

        return (
            <div className={classes.titleAndStatus}>
                <Typography className={classes.title}>
                    {this.displayTextApi(
                        inquery?.parkingInq?.parking?.titleBoaton,
                    )}
                </Typography>
                <StatusTitle status={inquery.inqueryStatus.id} />
            </div>
        )
    }

    renderInfos(inquery) {
        const { classes } = this.props
        const status = inquery?.inqueryStatus?.id || null
        const parking = inquery?.parkingInq?.parking || null
        const relation =
            inquery?.inqueryRelations.length > 0
                ? inquery?.inqueryRelations[0]
                : null
        const phone = relation?.user?.contactDetails?.find(
            contact => contact.contactType.id === 1,
        )
        if (!parking || !status || !relation) return <></>

        return (
            <div className={classes.infos}>
                <Typography gutterBottom className={classes.title}>
                    {this.renderTitle(inquery)}
                </Typography>
                <div>
                    {status === 1 || status === 3 || status === 4 ? (
                        <Typography className={classes.text}>
                            <span style={{ fontWeight: 'bold' }}>
                                {this.displayText('address')}
                            </span>{' '}
                            {parking?.address?.city}
                        </Typography>
                    ) : (
                        <Typography className={classes.text}>
                            <span style={{ fontWeight: 'bold' }}>
                                {this.displayText('address')}
                            </span>{' '}
                            {parking?.address?.fullAddress}
                        </Typography>
                    )}
                    {status === 1 || status === 3 || status === 4 ? (
                        <Typography>
                            {this.displayText('exactAddress')}
                        </Typography>
                    ) : (
                        <Typography className={classes.text}>
                            <span style={{ fontWeight: 'bold' }}>
                                {this.displayText('name')}
                            </span>{' '}
                            {relation.user.firstName} {relation.user.lastName}
                        </Typography>
                    )}
                    {status === 1 || status === 3 || status === 4 ? (
                        <div />
                    ) : (
                        <Typography className={classes.text}>
                            <span style={{ fontWeight: 'bold' }}>
                                {this.displayText('phone')}
                            </span>{' '}
                            {phone?.contact}
                        </Typography>
                    )}
                </div>
            </div>
        )
    }

    renderDatesAndButton(inquery) {
        const { classes } = this.props
        const startDate = new Date(
            inquery.delimitedDate.startDate,
        ).toLocaleDateString()
        const endDate = inquery.delimitedDate.endDate
            ? new Date(inquery.delimitedDate.endDate).toLocaleDateString()
            : null

        return (
            <div className={classes.datesAndButton}>
                <Typography wrap className={classes.date}>
                    {startDate} {' - '}
                    {endDate || this.displayText('unknown')}
                </Typography>
                <Button
                    size="small"
                    variant="contained"
                    type="primary"
                    classNameOverride={classes.button}
                    onClick={() => this.handleOpenModalInfo(inquery)}
                    label={this.displayText('moreInfos')}
                />
                <ChevronRightIcon
                    onClick={() => this.handleOpenModalInfo(inquery)}
                    className={classes.chevron}
                />
            </div>
        )
    }

    render() {
        const { classes, loading, parkingInqueries } = this.props

        const url = window.location.href
        var currentAppRoute = getCurrentRouteKey(
            url,
            this.getContext(),
            this.getMessages(),
        )

        if (loading > 0) return this.renderLoading('100')
        if (loading === 0 && parkingInqueries.length === 0) {
            return (
                <div>
                    <Typography className={classes.nothing}>
                        {this.displayText('noInquery')}
                    </Typography>
                    {this.askInquery('noInquery')}
                </div>
            )
        }

        if (currentAppRoute === AppRoute.ChatBox) this.redirectNoDetails()

        // Si la route pointe vers les details d'une demande alors on affiche la page de details (pas trouvé d'autre moyen avec des menus dans des menus dans des menus)
        if (currentAppRoute === AppRoute.ChatBox) {
            return <ChatingPage />
        }

        return (
            <div className={classes.root}>
                {parkingInqueries.map(inquery => {
                    const parking = inquery?.parkingInq?.parking || null
                    if (parking.id) {
                        let imgs = parkingActions.getImageToDisplay(
                            parking.files,
                        )

                        return (
                            <div key={inquery.id} className={classes.inquery}>
                                <div className={classes.img}>
                                    {<BoatOnImageRender imgs={imgs} />}
                                </div>
                                {this.renderInfos(inquery)}
                                {this.renderDatesAndButton(inquery)}
                            </div>
                        )
                    }
                    return <div />
                })}
                <div style={{ padding: '10px' }} />
                {this.askInquery()}
                <BoatOnModal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    openCondition={this.state.openModal}
                    handleClose={this.handleCloseModal}
                    titles={{
                        parkingModal: this.displayText('yourRent'),
                    }}
                    modalCores={{
                        parkingModal: (
                            <ParkingModal handleClose={this.handleCloseModal} />
                        ),
                    }}
                    maxWidth={{ parkingModal: `sm` }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        parkingInqueries: state.inqueries.personalParkingInqueries,
        loading: state.inqueries.loading,
        user: state.authentication.user,
        sellerInfo: state.fetch.sellerInfo,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ParkingTab))
