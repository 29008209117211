export default theme => ({
    container: {
        padding: 16,
        boxSizing: `border-box`,
        width: 260,
        minWidth: 260,
        height: 210,
        borderRadius: 8,
        flexDirection: `column`,
        alignItems: `center`,
        justifyContent: `center`,
        display: `flex`,
    },
    header: {
        width: `100%`,
        alignItems: `center`,
        justifyContent: `center`,
        display: `flex`,
        marginBottom: 10,
    },
    pdp: {
        width: 32,
        height: 32,
        borderRadius: `50%`,
        marginRight: 14,
    },
    nom: {
        flexGrow: 1,
        fontWeight: 700,
        fontSize: `16px`,
    },
    google: {
        width: 22,
        height: 22,
    },
    descr: {
        flexGrow: 1,
        color: `#545B64`,
        fontSize: `14px`,
    },
    meta: {
        width: `100%`,
        display: `flex`,
        alignItems: `center`,
        color: `#545B64`,
        fontSize: `11px`,
    },
    icon: {
        height: 13,
        width: 13,
        marginLeft: 28,
        marginRight: 4,
        color: `#F6AF2C`,
    },
})
