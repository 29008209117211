export default theme => ({
    tab: {
        display: 'flex',
        flexDirection: 'column',
    },
    activityList: {
        listStyleType: 'none',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        fontSize: 12,
        padding: 0,
    },
    activityListItem: {
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
        gap: 15,

        '& .mention-clickable': {
            color: theme.palette.primary.main,
            '&:hover': {
                cursor: 'pointer',
            },
        },
        '& .mention-unclickable': {
            color: theme.palette.primary.main,
        },
    },
    userBadge: {
        background: '#E5E7F0',
        color: '#303F9F',
        width: 30,
        height: 30,
        borderRadius: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: 16,
        overflow: 'hidden',
    },
    userBadgeIcon: {
        marginTop: '10px !important',
        width: '35px !important',
        height: '35px !important',
    },
    col: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        // overflow: "hidden"
    },
    activityCol: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '45%',
        alignSelf: 'start',
    },
    activityRemovedValue: {
        textDecoration: 'line-through',
    },
    row: {
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
    },
    activityRow: {
        display: 'flex',
        flex: '1',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        maxHeight: 80,
        overflow: 'hidden',
        gap: 10,
    },
    date: {
        textDecoration: 'underline',
        fontSize: 12,
    },
    badgeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    badge: {
        padding: 6,
        borderRadius: 6,
    },
    textValue: {
        // overflow: 'hidden',
        // '-webkit-box-orient': 'vertical',
        // '-webkit-line-clamp': 5,
        // display: '-webkit-box',
    },
    commentSection: {
        display: 'flex',
        flex: 1,
        gap: 10,
    },
    editor: {
        '& .public-DraftEditor-content[contenteditable=true]': {
            minHeight: 120,
        },
    },
    editorButtonsRow: {
        display: 'flex',
        marginTop: 10,
        marginBottom: 30,
        justifyContent: 'flex-start',
    },
    commentEditor: {
        width: '100%',
    },
    comment: {
        '& p': {
            margin: 0,
        },
    },
    editorWrapper: {
        border: 'solid 1px rgba(0, 0, 0, 0.10)',
        marginBottom: 10,
        // overflow: "hidden",
        // "& .DraftEditor-editorContainer":{
        //     height: "unset"
        // },
        '& .rdw-editor-main': {
            minHeight: 120,
            margin: 10,
            height: 'auto',
            cursor: 'text',
        },
        '& .public-DraftStyleDefault-block': {
            margin: 0,
        },
    },
    commentActionsRow: {
        display: 'flex',
        gap: 10,
        alignItems: 'center',
        '& > span': {
            color: '#303F9F',
            fontWeight: 500,
            cursor: 'pointer',
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
    toggleDetailsButton: {
        display: 'flex',
        justifyContent: 'flex-end',
        color: '#303F9F !important',
        fontSize: 12,
    },
    fileWrapper: {
        cursor: 'pointer',
    },
})
