export default {
    seoStart1: {
        FR: ` logiciel de maintenance intelligent 💪 et écoresponsable ♻️ de bateaux. Il a été développé en collaboration avec des particuliers et professionnels du monde nautique, dont des sociétés leaders de la maintenance de yachts et superyachts. Il est probablement la meilleure alternative à des logiciels PMS ou GMAO tels que Idea Yachts ou DocSea.`,
        EN: ` smart 💪 and eco-friendly ♻️ boat maintenance software. It has been developed in collaboration with individuals and professionals from the nautical world, including leading yacht and superyacht maintenance companies. It is probably the best alternative to PMS or CMMS software such as Idea Yachts or DocSea.`,
    },
    seoStart: {
        FR: `Le BoatOn Book est le 1`,
        EN: `BoatOn Book is the 1`,
    },
    seoDeploy: {
        FR: `Tout savoir sur le BoatOn Book et la GMAO de yachts`,
        EN: `All about BoatOn Book and CMMS for yachts`,
    },
    title: {
        FR: `Prenez un temps d'avance`,
        EN: `Get ahead of the game`,
    },
    subtitle1: {
        FR: `Le 1`,
        EN: `The 1`,
    },
    subtitle2: {
        FR: `er`,
        EN: `st`,
    },
    satisfaction: {
        FR: `+ 12 000 utilisateurs déjà satisfaits`,
        EN: `+ 12,000 satisfied users`,
    },
    oneMonth: {
        FR: `1 mois offert`,
        EN: `1 month free`,
    },
    testTitle: {
        FR: `Testez gratuitement le BoatOn Book`,
        EN: `Try the BoatOn Book for free`,
    },
    testSubtitle: {
        FR: `Créez votre compte en quelques minutes et bénéficiez d'1 mois d’essai gratuit. Abonnement sans engagement. 100% satisfait.`,
        EN: `Create your account in a few minutes and get 1 month free trial. Subscription without commitment. 100% satisfied.`,
    },
    desc0: {
        FR: `Très bonne appli que j'utilise pour mon semi rigide, personnes très sérieuses qui vous suivent et répondent à toute question. Je recommande fortement .`,
        EN: `Very good application that I use for my semi rigid, very serious people who follow you and answer any question. I highly recommend .`,
    },
    desc1: {
        FR: `Tout simplement la meilleure réponse à la gestion de mon  bateau. Facile et efficace`,
        EN: `Simply the best answer to managing my boat. Easy and efficient`,
    },
    desc2: {
        EN: `A dynamic team that listens to its customers! A big thank you to them for their responsiveness! I strongly recommend them.`,
        FR: `Une équipe dynamique et à l’écoute de ses clients ! Un grand merci à eux pour leur réactivité ! Je les recommande fortement.`,
    },
    desc3: {
        FR: `Equipe commerciale très réactive et à l'écoute de nos besoins.`,
        EN: `Very responsive sales team that listens to our needs.`,
    },
    desc4: {
        FR: `Super carnet d’entretien.
        J’ai eu le créateur au téléphone et il a pris le temps de bien tout expliquer. Bon courage pour la suite !`,
        EN: `Great service booklet.
        I had the founder on the phone and he took the time to explain everything well. Good luck with the rest!`,
    },
    startNow: {
        FR: `Démarrer maintenant`,
        EN: `Start now`,
    },
    subtitle3: {
        FR: `logiciel de maintenance de yachts intelligent & écoresponsable`,
        EN: `smart & eco-friendly maintenance software for yachts`,
    },
    tryFree: {
        FR: `Essayer gratuitement`,
        EN: `Try for free`,
    },
    askDemo: {
        FR: `Demander une démo`,
        EN: `Ask for a demo`,
    },
    iconText1: {
        FR: `Aucune installation, accessibilité sur smartphone ou ordinateur 24/24, collaboration en temps réel pour une gestion de bateaux en quelques clics.`,
        EN: `No installation, available on smartphone or computer 24/24, real-time collaboration for a boat management in a few clicks.`,
    },
    iconBlackPart1: {
        FR: `de gain de temps \n vs une gestion papier`,
        EN: `of time saving \n vs paper management`,
    },
    iconBluePart1: {
        FR: `40%`,
        EN: `40%`,
    },
    iconText2: {
        FR: `Pas besoin de longue présentation pour commencer à utiliser le BoatOn Book. En moins de 3 minutes, vous serez opérationnel.`,
        EN: `No need for a long presentation to start using the BoatOn Book. In less than 3 minutes you will be up and running.`,
    },
    iconBlackPart2: {
        FR: `Moins de`,
        EN: `Less than`,
    },
    iconBluePart2: {
        FR: `3 minutes`,
        EN: `3 minutes`,
    },
    iconBlack2Part2: {
        FR: `\n à prendre en main`,
        EN: `\n to take over`,
    },
    iconText3: {
        FR: `Bénéficiez de la plus grande base de données de prestataires nautiques et de nos alertes intelligentes pour prendre les meilleures décisions.`,
        EN: `Benefit from the largest database of marine providers and our smart alerts to make the best decisions.`,
    },
    iconBlackPart3: {
        FR: `Conseils`,
        EN: `Eco-friendly`,
    },
    iconBluePart3: {
        FR: `écoresponsables`,
        EN: `advice`,
    },
    iconBlack2Part3: {
        FR: `\n pour mieux réparer`,
        EN: `\n to better repair`,
    },
    titleDemo1: {
        FR: `Personnalisez et paramétrez vos tâches`,
        EN: `Customize and set up your tasks`,
    },
    subtitleDemo1: {
        FR: `Dates, pièces changées, prix payé, etc, la liste est longue des données à conserver pour entretenir un bateau. Enregistrez et planifiez toutes les opérations de maintenance que vous avez effectué. À chaque tâche validée, le BoatOn Book met à jour votre stock de pièces détachées.`,
        EN: `Dates, parts changed, price paid, etc., the list is long of data to keep to maintain a boat. Record and schedule all the maintenance operations you have performed. After each tasks, the BoatOn Book will update your spare parts inventory.`,
    },
    titleDemo4: {
        FR: `Optimisez votre stock de pièces et vos achats`,
        EN: `Optimize your parts inventory and purchasing`,
    },
    subtitleDemo4: {
        FR: `Retrouvez tous vos équipements et pièces de bord au même endroit, gérez leur stock et visualisez l’historique des remplacements effectués. Recevez des alertes en cas de rupture de stock ou de date de remplacement dépassée.`,
        EN: `Find all your equipment and on-board parts in one place, manage their stock and view the history of replacements made. Receive alerts in case of stock shortage or replacement date exceeded.`,
    },
    titleDemo2: {
        FR: `Enregistrez et partagez tous vos documents`,
        EN: `Save and share all your documents`,
    },
    subtitleDemo2: {
        FR: `Le coffre-fort de tous vos modes d’emploi, factures, attestations, permis, ... Enregistrez des photos, vidéos, pdf depuis l’appareil photo de votre smartphone ou votre ordinateur et partagez-les à tout moment.`,
        EN: `The safe for all your instructions, invoices, certificates, licenses, ... Save photos, videos, pdf from your smartphone camera or computer and share them anytime.`,
    },
    titleDemo3: {
        FR: `Visualisez et gérez votre budget`,
        EN: `Visualize and manage your budget`,
    },
    subtitleDemo3: {
        FR: `Les dépenses d’entretien ou achats s’enregistrent automatiquement dans une page dédiée. Contrôlez tous les mouvements, faites des statistiques et optimisez votre budget. `,
        EN: `Maintenance expenses or purchases are automatically recorded in a dedicated page. Control all movements, make statistics and optimize your budget. `,
    },
    titleDemo5: {
        FR: `Affectez des tâches & maîtrisez qui accède aux données`,
        EN: `Assign tasks & control who accesses data`,
    },
    subtitleDemo5: {
        FR: `Affectez des tâches à vos prestataires ou membres d’équipage, ️partagez des données et collaborez avec d'autres utilisateurs. Donnez-leur des accès personnalisés et décidez ce qu’ils peuvent faire et voir.`,
        EN: `Assign tasks to your providers or crew members, ️share data and collaborate with other users. Give them custom access and decide what they can do and see. `,
    },
    titleDemo6: {
        FR: `Suivez l’historique de chaque bateau en 1 coup d’œil`,
        EN: `Follow the history of each boat at a glance`,
    },
    subtitleDemo6: {
        FR: `Tous les évènements importants de votre bateau s’organisent automatiquement de manière chronologique. Visualisez en quelques secondes où que vous soyez les contrôles à venir et tous les évènements passés (révisions, locations, etc).
        Transmettez cet historique à la revente du bateau et valorisez la maintenance effectuée.`,
        EN: `All the important events of your boat are automatically organized in a chronological way. Visualize in a few seconds, wherever you are, the upcoming controls and all past events (revisions, rentals, etc.).
        Share this data when the boat is sold and benefit from the maintenance performed.`,
    },
    titleDemo7: {
        FR: `Contrôlez en 1 clic l’état de votre flotte`,
        EN: `Check the status of your fleet in 1 click`,
    },
    subtitleDemo7: {
        FR: `Visualisez en 1 clic toutes les informations importantes sur vos bateaux : tâches en retard ou à venir, gains et dépenses, marina. Profitez de statistiques et reporting automatiques : tâches, évolution du stock, gains et dépenses au fil du temps.  `,
        EN: `Visualize in 1 click all the important information about your boats: overdue or upcoming tasks, earnings and expenses, marina and take advantage of statistics and automatic reporting: tasks, stock evolution, earnings and expenses over time. `,
    },
    seoTitle1: {
        FR: 'Qu’est-ce qu’un logiciel GMAO ou PMS ?',
        EN: 'What is a CMMS or PMS software?',
    },
    seoDesc1: {
        FR:
            "La GMAO ou Gestion de Maintenance Assistée par Ordinateur est l’équivalent anglais du PMS ou Planned Maintenance System. Appliqué aux yachts, c’est un système de tâches planifiées qui permet aux particuliers, armateurs ou gestionnaires de flottes de yachts de planifier, effectuer et documenter l'entretien des bateaux à des intervalles réguliers conformes aux exigences de la classe du bateau et du fabricant. L'objectif est d'assurer la sécurité et la fiabilité du navire, y compris de tous les équipements de bord, en plus de la conformité à tous les lois en vigueur.",
        EN:
            'CMMS or Computerised Maintenance Management is the English equivalent of PMS or Planned Maintenance System. Applied to yachts, it is a planned tasks system that allows individuals, owners or fleet managers of yachts to plan, carry out and document the maintenance of boats at regular intervals in accordance with the requirements of the boat class and manufacturer. The aim is to ensure the safety and reliability of the vessel, including all on-board equipment, in addition to compliance with all applicable laws.',
    },
    seoTitle2: {
        FR: 'Quels sont les avantages ?',
        EN: 'What are the benefits?',
    },
    seoDesc2: {
        FR:
            'Un des premiers avantages d’un tel logiciel est de disposer d’une assistance automatisée à la gestion de la maintenance d’un yacht. Il existe plusieurs autres avantages aux logiciels ou progiciels de GMAO tels que :',
        EN:
            'One of the primary benefits of such software is to have automated assistance in managing the maintenance of a yacht. There are several other advantages to CMMS software or packages such as:',
    },
    seoDesc2Li1: {
        FR:
            'Gestion d’équipements avec leur inventaire, leurs caractéristiques, leurs dates d’acquisition, …',
        EN:
            'Management of equipment with their inventory, characteristics, acquisition dates, etc.',
    },
    seoDesc2Li2: {
        FR:
            'Gestion des maintenances de différents types (maintenance préventive, maintenance corrective, …) avec la génération des documents de gestion correspondants. Ces documents, notamment utilisés par les équipes techniques, marins ou prestataires natiques peuvent prendre la forme de demande d’intervention ou d’ordre de travaux par exemple',
        EN:
            'Management of different types of maintenance (preventive maintenance, corrective maintenance, etc.) with the generation of the corresponding management documents. These documents, used in particular by the technical teams, sailors or national service providers, can take the form of intervention requests or work orders, for example',
    },
    seoDesc2Li3: {
        FR:
            'Gestion des stocks des pièces détachées : nombre de pièces en stocks, localisation, alerte de remplacement, etc',
        EN:
            'Spare parts stock management: number of parts in stock, location, replacement alert, etc.',
    },
    seoDesc2Li4: {
        FR:
            'Gestion des coûts : dépenses liées à l’intervention de mécaniciens nautiques et autres prestataires, achats, reconstitution des stocks, etc',
        EN:
            'Cost management: expenses related to the intervention of marine mechanics and other service providers, purchases, restocking, etc.',
    },
    seoTitle3: {
        FR: 'Existe t-il des logiciels de GMAO gratuit pour les bateaux ?',
        EN: 'Is there any free CMMS software for boats?',
    },
    seoDesc3: {
        FR:
            'Ces logiciels proposent souvent des prix variables en fonction des fonctionnalités demandées mais la plupart ne sont pas gratuits. Le BoatOn Book est le seul logiciel qui offre un plan gratuit pour stocker tous les documents du yacht en ligne et les retrouver en quelques clics depuis un smartphone ou ordinateur.',
        EN:
            'These programs often offer varying prices depending on the features required but most are not free. The BoatOn Book is the only software that offers a free plan to store all yacht documents online and retrieve them in a few clicks from a smartphone or computer.',
    },
    seoTitle4: {
        FR: 'Comment choisir un logiciel GMAO de maintenance de yacht ?',
        EN: 'How to choose a CMMS software for yacht maintenance?',
    },
    seoDesc4: {
        FR: 'Il faut tout d’abord définir ses besoins. Nécessitez-vous :',
        EN: 'First of all, you need to define your needs. Do you need :',
    },
    seoDesc4Li1: {
        FR: 'un détail des interventions par équipement, bateau,',
        EN: 'details of interventions by equipment, vessel,',
    },
    seoDesc4Li2: {
        FR: 'une vision des coûts des interventions (horaires, financiers)',
        EN: 'a vision of the costs of the interventions (hourly, financial)',
    },
    seoDesc4Li3: {
        FR:
            'une gestion de missions des prestatires ou de l’équipage du yacht (internes et externes)',
        EN:
            'mission management of service providers or yacht crew (internal and external)',
    },
    seoDesc4Li4: {
        FR: 'une gestion de stock simplifiée (historique, suivi …)',
        EN: 'simplified stock management (history, follow-up, etc.)',
    },
    seoDesc4Li5: {
        FR: '...',
        EN: '...',
    },
    seoDesc4Bis: {
        FR:
            'Ensuite, il faudra analyser votre organisation et définir les critères clés :',
        EN:
            'Next, you will need to analyse your organisation and define the key criteria:',
    },
    seoDesc4BisLi1: {
        FR: 'Comment et avec quel outil gérez-vous votre service ?',
        EN: 'How and with what tools do you manage your service?',
    },
    seoDesc4BisLi2: {
        FR: 'Comment sont récupérées les demandes d’intervention ?',
        EN: 'How are requests for assistance collected?',
    },
    seoDesc4BisLi3: {
        FR: 'Qui donne les ordres aux prestataires ou à l’équipage ?',
        EN: 'Who gives the orders to the providers or the crew?',
    },
    seoDesc4BisLi4: {
        FR:
            'Quelles informations ramènent les marins ou prestataires au retour d’une intervention ?',
        EN:
            'What information do seafarers or providers bring back from an intervention?',
    },
    seoDesc4BisLi5: {
        FR: 'Quels indicateurs vous demande-t-on ?',
        EN: 'What indicators are you being asked for?',
    },
    seoDesc4Final: {
        FR:
            'Le prix enfin rentrera dans la balance. Le BoatOn Book est parmi les meilleures offres du marché et est une bonne alternative à des smart logiciels comme Idea Yachts, Deep Blue Soft, Smart Sailors ou DocSea.',
        EN:
            'Finally, the price will be a factor. The BoatOn Book is among the best offers on the market and is a good alternative to smart software like Idea Yachts, Deep Blue Soft, Smart Sailors or DocSea.',
    },
    seoTitle5: {
        FR: 'Le BoatOn Book : un outil tout-en-un facile à utiliser',
        EN: 'The BoatOn Book: an easy-to-use all-in-one tool',
    },
    seoDesc5: {
        FR:
            'Le logiciel a été conçu pour être pris en main en toute autonomie en 3 minutes. Aucune formation ou démonstration n’est nécessaire et on peut rapidement voir en un coup d’œil la vie d’un yacht, gérer son stock grâce à l’inventaire de tous les équipements et pièces de bord, contrôler les tâches effectués et programmer ceux à venir, visualiser les dépenses et revenus, stocker tout document et enfin avoir des rapports statistiques sur l’état du yacht ou de la flotte.',
        EN:
            'The software has been designed to be picked up independently in 3 minutes. No training or demonstration is required and you can quickly see at a glance the life of a yacht, manage its inventory with all the equipment and parts on board, check the tasks carried out and schedule future ones, view expenses and income, store any document and finally have statistical reports on the state of the yacht or the fleet.',
    },
    seoTitle6: {
        FR: '40% de gain de temps',
        EN: '40% time saved',
    },
    seoDesc6: {
        FR:
            'En utilisant le BoatOn Book, les plaisanciers ou gestionnaire de flottes de yachts ou superyachts gagnent en moyenne 40% de temps par rapport à leur gestion papier ou Excel. Fini les carnets, fichiers Excel, emails ou documents sur un drive partagé. Grâce à des bases de données en constante amélioration – + 250 entretiens sont préprogrammés dans le logiciel – la plupart des tâches chronophages se trouvent automatisées et les utilisateurs peuvent passer plus de temps à naviguer ou développer leur clientèle.',
        EN:
            'By using the BoatOn Book, yacht and superyacht owners and fleet managers save an average of 40% of their time compared to paper or Excel. No more notebooks, Excel files, emails or documents on a shared drive. Thanks to constantly improving databases - over 250 checkups are pre-programmed in the software - most time-consuming tasks are automated and users can spend more time sailing or developing their client base.',
    },
    seoTitle7: {
        FR: 'Un logiciel écoresponsable',
        EN: 'Eco-responsible software',
    },
    seoDesc7: {
        FR:
            'Le logiciel met en œuvre plusieurs outils pour aider le plaisancier ou gestionnaire de flotte de yachts dans sa transition écologique :',
        EN:
            'The software implements several tools to help the yachtsman or yacht fleet manager in his ecological transition:',
    },
    seoDesc7Ol1: {
        FR: 'Des alertes intelligentes pour réparer plutôt que remplacer',
        EN: 'Smart alerts to repair rather than replace',
    },
    seoDesc7Ol2: {
        FR:
            'Des statistiques sur les indicateurs clés du ou des yachts pour optimiser leur gestion',
        EN:
            'Statistics on the key indicators of the yacht(s) to optimise their management',
    },
    seoDesc7Ol3: {
        FR:
            'Un accès illimité à la 1ère base de données de prestataires nautiques en France. En effet, BoatOn a rassemblé +5500 sociétés et l’enrichit de critères écoresponsables : gestion de l’énergie, de l’eau (ex : sablage vs karcher), réduction des déchets, utilisation de produits recyclables, limitation des polluants et actions écologiques.',
        EN:
            'Unlimited access to the 1st database of nautical service providers in France. Indeed, BoatOn has gathered more than 5500 companies and enriches it with eco-responsible criteria: energy and water management (e.g. sandblasting vs karcher), waste reduction, use of recyclable products, limitation of pollutants and ecological actions.',
    },
    seoDesc7Final: {
        FR:
            'Les utilisateurs ont ainsi toutes les clés en main pour réduire l’impact environnemental des interventions réalisées sur leurs bateaux.',
        EN:
            'This gives users all the tools they need to reduce the environmental impact of work carried out on their boats.',
    },
    easiestMaintenanceApp: {
        FR: `Le logiciel de maintenance le plus simple du marché`,
        EN: `The simplest maintenance software on the market`,
    },
    subEasiestMaintenanceApp: {
        FR: `Du simple bateau de plaisance à la flotte de superyachts, le BoatOn Book optimise 
        l’entretien de vos navires`,
        EN: `From a single pleasure boat to a fleet of superyachts, the BoatOn Book optimizes 
        the maintenance of your vessels`,
    },
    winningTime: {
        FR: `Un logiciel qui fait gagner du temps`,
        EN: `Time-saving software`,
    },

    video: {
        FR: `https://www.youtube-nocookie.com/embed/HaZnzzU-8R8`,
        EN: `https://www.youtube-nocookie.com/embed/OIKE-UFsBMg`,
    },
}
