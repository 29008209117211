export default theme => ({
    root: {
        boxShadow: '0px 1px 4px 0px rgba(0, 0, 0, 0.25)',
        width: 'fit-content',
        display: 'flex',
        flexDirection: 'row',
        marginTop: 20,
        '&.started': {
            width: '100%',
        },
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    title: {
        fontSize: 14,
        fontWeight: 500,
    },
    total: {
        fontSize: 20,
        fontWeight: 500,
    },
    timerContainer: {
        paddingInline: 24,
        paddingBlock: 8,
        borderRadius: 8,
        background: '#EAEBF6',
        gap: 32,
        display: 'flex',
        width: '200px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    timerValue: {
        fontSize: 24,
        fontWeight: 500,
        textAlign: 'center',
        width: '200px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    row: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20,
        padding: 20,
    },
    simpleRow: {
        display: 'flex',
        // flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
    },
    col: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        padding: 20,
    },
    startButton: {
        display: 'flex',
        flex: 1,
        paddingBlock: 4,
    },
    workDaycol: {
        borderLeft: 'solid 1px #E6E9E9',
        gap: 10,
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 0,
        },
    },
    workDayList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        position: 'relative',
        gap: 8,
        marginLeft: 20,
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 10,
            left: '-19px',
            height: 'calc(100% - 20px)',
            border: 'dashed #EAEBF6 3px',
        },
    },
    workDayItem: {
        display: 'flex',
        flexDirection: 'row',
        gap: 8,

        '&:first-child': {
            '&::before': {
                content: '""',
                width: 13,
                height: 13,
                borderRadius: 10,
                backgroundColor: '#EAEBF6',
                position: 'absolute',
                top: 8,
                left: -23,
            },
        },

        '&:last-child': {
            '&::before': {
                content: '""',
                width: 13,
                height: 13,
                borderRadius: 10,
                backgroundColor: '#EAEBF6',
                position: 'absolute',
                bottom: 3,
                left: -23,
            },
        },
    },
    workDayCell: {
        borderRadius: 4,
        paddingInline: 12,
        border: '1px solid #E6E9E9',
    },
    dayFinished: {
        color: '#3040a0',
        display: 'flex',
        alignItems: 'center',
        fontWeight: 500,
        gap: 5,
    },
})
