export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc(100% - 40px)',
        padding: '20px',
        paddingTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0,
            margin: 0,
        },
    },
    noDoc: {
        textAlign: 'center',
        padding: '15px',
        fontSize: '22px',
    },
    hidden: {
        display: 'none',
    },
    menu: {
        width: '275px',
        display: 'flex',
        flexDirection: 'column',
        marginRight: '50px',
        marginLeft: 'auto',
        padding: '10px',
        paddingRight: 0,
    },
    button: {
        margin: 5,
        padding: 5,
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    buttonAdd: {
        margin: '30px 0',
        float: 'right',
        width: 220,
    },
    buttonSvg: {
        fontSize: '30px!important',
    },
    textField: {
        width: '100%',
    },
    twoTextField: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridGap: '15px',
        width: `100%`,
    },
    espaces: {
        paddingTop: '20px',
    },
    line: {
        width: '100%',
        padding: '10px',
        boxSizing: `border-box`,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '70px',
        [theme.breakpoints.down('sm')]: {
            height: `100%`,
            minHeight: `120px`,
            width: `100%`,
            margin: 0,
        },
    },
    blueLine: {
        backgroundColor: '#F7F9FB',
    },
    lateLine: {
        background: `rgba(255, 237, 236, 1)`,
        boxShadow: `-3px 0 0 0 #F44336`,
    },
    validLine: {
        background: `#EBF7EC`,
        boxShadow: `-3px 0 0 0 #6BC070`,
        [theme.breakpoints.down('sm')]: {
            borderBottom: `1px solid lightgray`,
        },
    },
    infos: {
        width: 'calc(100% - 132px)',
        display: 'contents',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 90px)',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 90px)',
            display: 'flex',
            flexDirection: 'column-reverse',
        },
    },
    titleContainer: {
        display: `flex`,
        width: `100%`,
        flexWrap: `wrap`,
        [theme.breakpoints.down('sm')]: {
            flexDirection: `column`,
        },
    },
    title: {
        fontWeight: 500,
    },
    subtitle: {
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: 'auto',
        fontWeight: 500,
        whiteSpace: 'break-spaces',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
        },
    },
    titleSeparator: {
        marginLeft: 5,
        marginRight: 5,
        [theme.breakpoints.down('sm')]: {
            display: `none`,
        },
    },
    pieces: {
        width: `fit-content`,
    },
    opName: {
        width: `fit-content`,
    },
    typeText: {
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: 'auto',
        fontWeight: 500,
    },
    type: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    titleAndType: {
        overflow: `hidden`,
        width: `calc(100% - 125px)`,
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            width: `100%`,
        },
    },
    validate: {
        display: 'block',
        margin: 'auto',
        marginTop: '20px',
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: '5px',
        },
    },
    date: {
        width: '100%',
        fontSize: '20px',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'clip',
            textAlign: 'left',
            marginLeft: 5,
        },
    },
    dates: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '125px',
        minWidth: '125px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '18px',
            width: '100%',
            minWidth: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
        },
    },
    link: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        textDecoration: 'none',
        color: '#000',
        width: '4%',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            //fontSize: '16px',
        },
    },
    firstLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    texte: {
        paddingBottom: '18px',
        paddingTop: '12px',
    },

    datetime: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '40%',
        marginLeft: '5px',
        paddingTop: '11px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
        },
    },
    recherche: {
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    field: {
        width: '98%',
        margin: 'auto',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    dividerH: {
        border: '0.5px solid #c4c4c4',
        height: '50px',
        marginRight: '10px',
        marginLeft: '10px',
    },
    dividerAmount: {
        border: '0.5px solid #c4c4c4',
        height: '15px',
        marginRight: '15px',
        marginLeft: '15px',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    icon: {
        width: '20px',
        height: '20px',
        marginRight: '15px',
    },
    iconAdd: {
        marginTop: '15px',
        display: 'block',
        marginRight: '50px',
        marginLeft: 'auto',
        '&:hover': {
            cursor: 'pointer',
        },
        width: '80px',
        height: '80px',
        [theme.breakpoints.down('sm')]: {
            width: '60px',
            height: '60px',
        },
    },
    paper: {
        width: '100%',
    },
    addDoc: {
        border: '1px dashed #c4c4c4',
        margin: '10px 0px',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            margin: '5px',
        },
    },
    textAddDate: {
        fontWeight: 500,
    },
    textRepair: {
        fontWeight: 500,
        marginTop: '10px',
    },
    textAdd: {
        margin: '10px 0px',
        fontWeight: 500,
    },
    doc: {
        margin: 'auto',
        display: 'block',
        padding: '10px',
        width: '50px',
        height: '50px',
        color: '#C4C4C4',
    },
    allPhotos: {
        width: '100%',
        minHeight: '5%',
        padding: '0 10px',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    images: {
        width: '100px',
        height: '100px',
        marginRight: '15px',
        position: 'relative',
    },
    pictures: {
        margin: 'auto',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        border: '0.5px solid #C4C4C4',
        position: 'relative',
    },
    importantImage: {
        width: '30rem',
        margin: 'auto',
        height: '100%',
        position: 'relative',
        display: 'inline-block',
    },
    cross: {
        position: 'absolute',
        right: 0,
        top: 0,
        '&:hover': {
            cursor: 'pointer',
        },
        zIndex: 1,
    },
    ifNeeded: {
        padding: '10px',
        fontWeight: 500,
        fontSize: '18px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    input: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            padding: 0,
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '4px',
        },
    },
    iconDisabled: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '3%',
        color: '#C1C1C1',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    icone: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        color: '#000',
        alignItems: 'center',
        width: '3%',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    labelInput: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    marginInput: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    loading: {
        textAlign: 'center',
    },
    amount: {
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: 'auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
            display: 'none',
        },
    },
    modalTitle: {
        fontSize: '24px',
        fontWeight: 500,
        textAlign: 'center',
        marginBottom: '10px',
    },
    dividerHidden: {
        border: '0.5px solid #c4c4c4',
        height: '50px',
        marginRight: '10px',
        marginLeft: '10px',
        visibility: 'hidden',
    },
    amountHidden: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '15%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '13px',
            width: '12%',
        },
        visibility: 'hidden',
    },
    rootRangDate: {
        display: 'flex',
        flexFlow: 'row wrap',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    day: {
        width: '34px',
        minWidth: '34px',
        height: '34px',
        minHeight: '34px',
        margin: '2px',
        textAlign: 'center',
        lineHeight: 0,
        fontWeight: 400,
    },
    unavailable: {
        width: '34px',
        color: 'grey',
        minWidth: '34px',
        height: '34px',
        minHeight: '34px',
        margin: '2px',
        textAlign: 'center',
        lineHeight: 0,
        fontWeight: 400,
    },
    case: {
        width: '100%',
    },
    daySelected: {
        width: '34px',
        minWidth: '34px',
        height: '34px',
        minHeight: '34px',
        margin: '2px',
        textAlign: 'center',
        backgroundColor: '#3040a0',
        borderRadius: '50%',
        color: '#fff',
        lineHeight: 0,
        fontWeight: 400,
        '&:hover': {
            backgroundColor: '#222e72',
        },
    },
    startDate: {
        marginLeft: '5px',
        marginRight: '5px',
        marginBottom: '10px',
        width: '138px',
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    endDate: {
        marginLeft: '30px',
        marginRight: '5px',
        width: '138px',
        marginBottom: '10px',
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            marginLeft: 0,
        },
    },
    page: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
            width: '100%',
        },
    },
    all: {
        width: '100px',
        marginTop: 0,
        marginBottom: 'auto',
        marginRight: '10px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: 0,
            marginBottom: '10px',
        },
    },
    testSwitch: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    testAAA: {
        backgroundColor: 'green',
    },
    chosePerson: {
        display: 'none',
    },
    btnHours: {
        marginTop: '5px',
        marginBottom: '10px',
        marginRight: '10px',
        backgroundColor: 'white',
        border: 'none',
        fontFamily: 'roboto',
        color: '#3040a0',
        fontSize: '1em',
        textDecoration: 'underline',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1em',
        },
    },
    iconButton: {
        margin: 5,
        padding: 5,
        color: 'black',
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    icons: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    iconsMobile: {
        display: 'none',
        [theme.breakpoints.down('md')]: {
            minWidth: '90px',
            display: 'flex',
            flexDirection: 'column',
        },
        [theme.breakpoints.down('sm')]: {
            order: 1,
        },
    },
    actionMobile: {
        width: 207,
        height: 44,
        boxSizing: `border-box`,
        fontWeight: 400,
        fontSize: `14px`,
        justifyContent: `flex-start`,
        paddingLeft: 17,
    },
    tooltip: {
        backgroundColor: '#3040a0',
        color: 'white',
        maxWidth: 300,
        border: '1px solid #dadde9',
        fontSize: '15px',
    },
    rootLabelTooltip: {
        display: `flex`,
    },
    helpIcon: {
        marginTop: `-10px`,
        width: `20px`,
        height: `20px`,
        marginRight: `3px`,
        marginLeft: `3px`,
    },
    circle: {
        color: 'black',
        '&:hover': {
            color: '#6BC070',
        },
    },
    buttonValid: {
        margin: 5,
        padding: 5,
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    greenColor: {
        color: '#6BC070',
        '&:hover': {
            color: 'black',
        },
    },
    tooltipCircle: {
        fontSize: '15px',
    },
    backgroundGreenColor: {
        backgroundColor: '#6BC070',
    },
    checkbox: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    checkboxMobile: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    followButtonLabel: {
        fontSize: 12,
    },

    amountContainer: {
        display: 'flex',
        flexDirection: 'row',
        columnGap: 15,
        flexWrap: 'wrap',
    },
    amountField: {
        flex: 1,
        minWidth: 150,
    },
    taxField: {
        maxWidth: 80,
    },
})
