export default [
    {
        skipper: {
            month: { ht: 5.54, ttc: 6.65 },
            year: { ht: 4.99, ttc: 5.99 },
        },
    },
    {
        bosco: {
            month: { ht: 54.44, ttc: 65.33 },
            year: { ht: 49.0, ttc: 58.8 },
        },
    },
    {
        captain: {
            month: { ht: 87.78, ttc: 105.33 },
            year: { ht: 79.0, ttc: 94.8 },
        },
    },
    {
        commandant: {
            month: { ht: 132.22, ttc: 158.67 },
            year: { ht: 119.0, ttc: 142.8 },
        },
    },
    {
        amiral: {
            month: { ht: 176.67, ttc: 212.0 },
            year: { ht: 159.0, ttc: 190.8 },
        },
    },
]
