import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import EditCalendarIcon from '@mui/icons-material/EditCalendar'
import DeleteIcon from '@mui/icons-material/Delete'
import BoatOnComponent from '../../../common/BoatOnComponent'
import dictionary from './Dictionary/BudgetTabDico'
import { GetApp } from '@material-ui/icons'

const styles = theme => ({
    saveBottom: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        right: '50%',
        transform: 'translate(+50%)',
        width: '100%',
        backgroundColor: 'white',
        borderTop: '3px solid #c4c4c4',
        borderRight: '3px solid #c4c4c4',
        borderLeft: '3px solid #c4c4c4',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        zIndex: 1499,
        padding: '12px',
        maxHeight: '7%',
    },
    button: {
        color: 'black',
        '&:hover': {
            backgroundColor: '#3040a0',
            color: 'white',
        },
        float: 'right',
        marginRight: '15px',
        marginLeft: '15px',
        width: '27%',
        fontSize: '17px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '9px',
            width: '30%',
            marginRight: '5px',
            marginLeft: '5px',
        },
    },
    buttonDelete: {
        color: 'black',
        backgroundColor: '#e46b62',
        '&:hover': {
            backgroundColor: '#e46b62',
            color: 'white',
        },
        float: 'right',
        marginRight: '15px',
        marginLeft: '15px',
        width: '27%',
        fontSize: '17px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '9px',
            width: '30%',
            marginRight: '5px',
            marginLeft: '5px',
        },
    },
})

class BudgetBar extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary
    }

    render() {
        const {
            classes,
            handleEdit,
            disabledEdit = false,
            handleDownload,
            disabledDelete = false,
            handleDelete,
            disabledDownload = false,
        } = this.props

        const isMobile = window.innerWidth < 600

        if (this.props.open) {
            return (
                <div className={classes.saveBottom}>
                    <Button
                        disabled={disabledEdit}
                        className={classes.button}
                        size="medium"
                        color="secondary"
                        variant="contained"
                        endIcon={!isMobile ? <EditCalendarIcon /> : <></>}
                        onClick={handleEdit}
                    >
                        {this.displayText('editDate')}
                    </Button>
                    {/* Masqué en attendant le back */}
                    <Button
                        disabled={disabledDownload}
                        className={classes.button}
                        size="medium"
                        color="secondary"
                        variant="contained"
                        endIcon={!isMobile ? <GetApp /> : <></>}
                        onClick={handleDownload}
                    >
                        {this.displayText('download')}
                    </Button>
                    <Button
                        disabled={disabledDelete}
                        className={classes.buttonDelete}
                        size="medium"
                        color="secondary"
                        variant="contained"
                        endIcon={!isMobile ? <DeleteIcon /> : <></>}
                        onClick={handleDelete}
                    >
                        {this.displayText('delete')}
                    </Button>
                </div>
            )
        } else {
            return null
        }
    }
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(withStyles(styles)(BudgetBar))
