import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './ChronometreCss'
import { Typography } from '@material-ui/core'
import BoatOnComponent from '../../../../common/BoatOnComponent'
import dictionary from './ChronometreDico'
import { Button } from '../../../../common'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import CheckIcon from '@mui/icons-material/Check'
import { chronoActions } from '../../../../../actions/bob/chrono.actions'
import { workTimeActions } from '../../../../../actions/bob/workTime.actions'
import anchorLoader from '../../../../../images/anchorLoader.gif'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs from 'dayjs'

dayjs.extend(localizedFormat)

class Chronometre extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary

        this.startChrono = this.startChrono.bind(this)
        this.pauseChrono = this.pauseChrono.bind(this)
        this.restartChrono = this.restartChrono.bind(this)
        this.finishDay = this.finishDay.bind(this)

        this.state = {
            isDayStarted: false,
            currentWorkTime: undefined,
            sections: [],
            now: dayjs(),
        }
    }

    componentDidMount() {
        const userLink = this._findUserLink()

        if (userLink) {
            // Récupération du work-time en cours
            this.props.dispatch(chronoActions.getCurrentWorkTime(userLink.id))
        }

        this.interval = setInterval(
            () => this.setState({ now: dayjs() }),
            10000,
        )
    }

    componentWillUnmount() {
        clearInterval(this.interval)
    }

    componentDidUpdate(prevProps) {
        const {
            user,
            groupMembers,
            workTimes,
            chronoLoading,
            workTimeLoading,
        } = this.props
        let { currentWorkTime } = this.props

        if (this.props !== prevProps) {
            if (groupMembers !== prevProps.groupMembers && user) {
                const userLink = this._findUserLink()

                if (userLink) {
                    // Récupération du work-time en cours
                    this.props.dispatch(
                        chronoActions.getCurrentWorkTime(userLink.id),
                    )
                }
            }

            if (
                workTimes &&
                ((chronoLoading === 0 && prevProps.chronoLoading > 0) ||
                    (workTimeLoading === 0 && prevProps.workTimeLoading > 0))
            ) {
                currentWorkTime =
                    !currentWorkTime === 0
                        ? workTimes.find(w => {
                              return (
                                  new Date(w.delimitedDate.startDate).setHours(
                                      0,
                                      0,
                                      0,
                                      0,
                                  ) <= new Date().setHours(0, 0, 0, 0) &&
                                  new Date(w.delimitedDate.endDate).setHours(
                                      0,
                                      0,
                                      0,
                                      0,
                                  ) >= new Date().setHours(0, 0, 0, 0)
                              )
                          })
                        : currentWorkTime
                if (
                    currentWorkTime?.delimitedDate?.endDate &&
                    currentWorkTime?.endTime
                ) {
                    this.setState({
                        isDayFinished: true,
                    })
                }

                let isDayStarted = false

                // Si un work time est défini
                if (currentWorkTime?.id !== undefined) {
                    isDayStarted = true
                    const sections = [
                        {
                            startTime: dayjs(
                                currentWorkTime.startTime,
                                'HH:mm:ss',
                            ),
                            endTime: undefined,
                        },
                    ]
                    // Création des sections pour générer la frise
                    currentWorkTime.breakTimes
                        .map(b => {
                            return {
                                id: b.id,
                                startTime: new dayjs()
                                    .hour(b.startTime.split(':')[0])
                                    .minute(b.startTime.split(':')[1])
                                    .second(b.startTime.split(':')[2]),
                                endTime: b.endTime
                                    ? new dayjs()
                                          .hour(b.endTime.split(':')[0])
                                          .minute(b.endTime.split(':')[1])
                                          .second(b.endTime.split(':')[2])
                                    : null,
                            }
                        })
                        .reverse()
                        .forEach(b => {
                            // Fin de la section de travail précédente
                            sections[sections.length - 1].endTime = b.startTime

                            // Si la pause a une heure de fin, c'est qu'une autre section de travail a été commencé
                            if (b.endTime) {
                                // Début de la section de travail suivante
                                sections.push({
                                    startTime: b.endTime,
                                    endTime: undefined,
                                })
                            }
                        })

                    if (
                        sections[sections.length - 1].endTime === undefined &&
                        currentWorkTime.endTime
                    )
                        sections[sections.length - 1].endTime = dayjs(
                            currentWorkTime.endTime,
                            'HH:mm:ss',
                        )

                    this.setState({
                        currentWorkTime,
                        isDayStarted,
                        sections,
                    })
                } else {
                    this.setState({
                        currentWorkTime: null,
                    })
                }
            }
        }
    }

    _findUserLink() {
        const { groupMembers } = this.props
        return groupMembers.find(
            gm =>
                (this.props?.user?.email &&
                    gm?.user?.email === this.props.user.email) ||
                gm?.userSubscribe?.mail === this.props.user.email,
        )
    }

    startChrono() {
        const userLink = this._findUserLink()
        const body = {
            responsibleLink: userLink,
            delimitedDate: {
                startDate: new Date(),
                endDate: undefined,
                comment: '',
            },
        }

        this.props.dispatch(chronoActions.start(body, userLink.id))
    }

    restartChrono() {
        const { currentWorkTime } = this.props

        const userLink = this._findUserLink()

        const body = {
            responsibleLink: userLink,
            delimitedDate: currentWorkTime.delimitedDate,
            breakTimes: currentWorkTime.breakTimes.map(b => {
                // Renvoi les breaks en terminant celui qui ne l'était pas
                if (!b.endTime) {
                    return {
                        ...b,
                        endTime: dayjs().format('HH:mm:ss'),
                    }
                }

                return b
            }),
        }

        this.props.dispatch(chronoActions.restart(body, userLink.id))
    }

    pauseChrono() {
        const { currentWorkTime } = this.props

        const userLink = this._findUserLink()
        const body = {
            responsibleLink: userLink,
            delimitedDate: currentWorkTime.delimitedDate,
            breakTimes: currentWorkTime.breakTimes,
        }

        this.props.dispatch(chronoActions.pause(body, userLink.id))
    }

    async finishDay() {
        const { currentWorkTime } = this.props

        const userLink = this._findUserLink()
        const body = {
            ...currentWorkTime,
            delimitedDate: {
                ...currentWorkTime.delimitedDate,
                endDate: new Date(),
            },
            endTime: dayjs().format('HH:mm:ss'),
        }

        await this.props.dispatch(chronoActions.stop(body, userLink.id))
        this.setState({
            isDayStarted: false,
            currentWorkTime: undefined,
            sections: [],
            now: dayjs(),
        })
        this.props.dispatch(workTimeActions.getWorkTimes(userLink.id))
    }

    _getTotalWorkTime() {
        const { sections, now } = this.state

        const totalMinuteWork = sections.reduce((acc, section) => {
            let end = section?.endTime?.second(1) || now.second(1)
            let start = section.startTime.second(0)
            if (end.diff(start, 'minutes') < 0) {
                start = start.add(-1, 'day')
            }
            const timeWork = end.diff(start, 'minutes')
            return acc + timeWork
        }, 0)

        return `${String(Math.floor(totalMinuteWork / 60)).padStart(
            2,
            '0',
        )}h${String(Math.floor(totalMinuteWork % 60)).padStart(2, '0')}`
    }

    _getCurrentWorkTime() {
        const { sections, now } = this.state
        const actualSession = sections.find(section => !section.endTime)

        if (!actualSession) return '00h00'

        const end = actualSession?.endTime?.second(1) || now.second(1)
        let start = actualSession.startTime.second(0)
        if (end.diff(start, 'minutes') < 0) {
            start = start.add(-1, 'day')
        }
        const totalMinuteActualWork = end.diff(start, 'minutes')

        return `${String(Math.floor(totalMinuteActualWork / 60)).padStart(
            2,
            '0',
        )}h${String(Math.floor(totalMinuteActualWork % 60)).padStart(2, '0')}`
    }

    render() {
        const { classes, chronoLoading, currentWorkTime } = this.props
        const { isDayStarted, isDayFinished, sections } = this.state
        const isDayPaused = !sections.find(section => !section.endTime)

        if (chronoLoading > 0 || this.state.currentWorkTime === undefined)
            return this.renderLoading(100)

        return (
            <div
                className={[
                    classes.root,
                    isDayStarted ? 'started' : undefined,
                ].join(' ')}
            >
                {/* Afficher le spinner si le chronomètre charge */}
                {chronoLoading > 0 ? (
                    <div
                        className={classes.row}
                        style={{ justifyContent: 'center' }}
                    >
                        <img
                            src={anchorLoader}
                            alt="anchor"
                            title="anchor"
                            margin="auto"
                            width={50}
                            height={50}
                        />
                    </div>
                ) : !isDayStarted ? (
                    <div className={classes.row}>
                        <Typography className={classes.title}>
                            {this.displayText('todayWorkTime')}
                        </Typography>
                        <Typography className={classes.total}>00:00</Typography>
                        <Button
                            label={this.displayText('start')}
                            startIcon={<TimerOutlinedIcon />}
                            type="primary"
                            classNameOverride={classes.startButton}
                            onClick={() => this.startChrono()}
                        ></Button>
                    </div>
                ) : (
                    <>
                        <div className={classes.col} style={{ gap: 16 }}>
                            <div
                                className={classes.simpleRow}
                                style={{ gap: 16 }}
                            >
                                <Typography className={classes.title}>
                                    {this.displayText('todayWorkTime')}
                                </Typography>
                                <Typography className={classes.total}>
                                    <Typography
                                        style={{
                                            fontSize: '20px',
                                            fontWeight: 500,
                                        }}
                                    >
                                        {this._getTotalWorkTime()}
                                    </Typography>
                                </Typography>
                            </div>
                            <div
                                className={classes.simpleRow}
                                style={{
                                    gap: 9,
                                }}
                            >
                                {/* Chronomètre en pause, journée non-terminée */}
                                {isDayPaused && !isDayFinished && (
                                    <>
                                        <Button
                                            label={this.displayText('start')}
                                            startIcon={<TimerOutlinedIcon />}
                                            type="primary"
                                            onClick={() => this.restartChrono()}
                                        ></Button>
                                        <Button
                                            label={this.displayText(
                                                'finishDay',
                                            )}
                                            startIcon={<CheckIcon />}
                                            type="secondary"
                                            onClick={() => this.finishDay()}
                                        ></Button>
                                    </>
                                )}

                                {/* Chronomètre actif, journée non terminée */}
                                {!isDayPaused && !isDayFinished && (
                                    <>
                                        <div className={classes.timerContainer}>
                                            <Typography
                                                className={classes.timerValue}
                                            >
                                                <Typography
                                                    style={{
                                                        fontSize: '24px',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    {this._getCurrentWorkTime()}
                                                </Typography>
                                            </Typography>
                                        </div>
                                        <Button
                                            label={this.displayText('stop')}
                                            startIcon={<TimerOutlinedIcon />}
                                            type="error"
                                            onClick={() => this.pauseChrono()}
                                        ></Button>
                                    </>
                                )}

                                {/* Journée terminée */}
                                {isDayFinished && (
                                    <div>
                                        <Typography
                                            className={classes.dayFinished}
                                        >
                                            {this.displayText('dayFinished')}
                                            <CheckIcon
                                                style={{
                                                    width: 20,
                                                    height: 20,
                                                }}
                                            />
                                        </Typography>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div
                            className={[classes.col, classes.workDaycol].join(
                                ' ',
                            )}
                        >
                            <Typography className={classes.title}>
                                {`${this.displayText('myWorkDay')} ${
                                    currentWorkTime?.delimitedDate?.startDate
                                        ? new dayjs(
                                              currentWorkTime.delimitedDate.startDate,
                                          )
                                              .locale(this.context)
                                              .format('LL')
                                        : new dayjs()
                                              .locale(this.context)
                                              .format('LL')
                                }`}
                            </Typography>
                            <div className={classes.workDayList}>
                                {sections.map((section, index) => {
                                    return (
                                        <div
                                            className={classes.workDayItem}
                                            key={`section-${index}`}
                                        >
                                            <div
                                                className={classes.workDayCell}
                                            >
                                                <Typography>
                                                    {section.startTime.format(
                                                        'HH:mm',
                                                    )}
                                                </Typography>
                                            </div>
                                            <div
                                                className={classes.workDayCell}
                                            >
                                                <Typography>
                                                    {section.endTime
                                                        ? section.endTime.format(
                                                              'HH:mm',
                                                          )
                                                        : this.displayText(
                                                              'running',
                                                          )}
                                                </Typography>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </>
                )}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        groupMembers: state.group?.groupsMembers?.linkRGU || [],
        chronoLoading: state.chrono.loading,
        currentWorkTime: state.chrono.workTime,
        workTimes: state.workTime.workTimes,
        workTimeLoading: state.workTime.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(Chronometre))
