import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../../BoatOnComponent'
import styles from './Styles/ModalRenderSignInCss'
import dictionary from './Dictionary/ModalRenderSignInDico'
import BoatOnModal from '../../BoatOnModal'
import RenderBoatCreate from '../RenderBoatCreate'
import RenderAccountCreate from '../RenderAccountCreate'

class ModalRenderSignIn extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            openModal: 'personnalInfos',
            boatId: null,
        }

        this.valid = this.valid.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    valid(next, boatId) {
        const { saveUpdateSubscription, goToRentParking } = this.props
        if (
            next &&
            typeof next === 'string' &&
            !saveUpdateSubscription &&
            !goToRentParking
        ) {
            this.setState({ openModal: next, boatId: boatId })
        } else {
            if (saveUpdateSubscription) {
                saveUpdateSubscription()
            }
            this.handleClose()
        }
    }

    componentWillUnmount() {
        this.props.handleClose()
    }

    handleClose() {
        this.setState({ openModal: false }, () => {
            this.props.handleClose()
        })
    }

    render() {
        return (
            <BoatOnModal
                openCondition={this.state.openModal}
                handleClose={this.handleClose}
                modalCores={{
                    personnalInfos: <RenderAccountCreate valid={this.valid} />,
                    boatInfos: (
                        <RenderBoatCreate
                            valid={this.valid}
                            close={this.handleClose}
                        />
                    ),
                }}
                titles={{
                    personnalInfos: this.displayText('accountCreate'),
                    boatInfos: this.displayText('boatAdd'),
                }}
                maxWidth={{
                    personnalInfos: `sm`,
                    boatInfos: `sm`,
                    enginesInfos: `sm`,
                }}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        state: state.open,
        listBoats: state.fetch.listboatmodels,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ModalRenderSignIn))
