export default theme => ({
    secondLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: '100%',
            marginTop: 10,
        },
    },
    mainContainer: {
        width: `100%`,
        paddingRight: `10px`,
        boxSizing: `border-box`,
        display: `contents`,
        [theme.breakpoints.down('sm')]: {
            paddingRight: `0`,
        },
    },
    extension: {
        margin: '0px !important',
        boxShadow: `none`,
        marginBottom: '25px !important',
        '& .MuiAccordionSummary-root': {
            overflow: `hidden`,
        },
        '&:before': {
            opacity: 0,
        },
    },

    PanelSummary: {
        [theme.breakpoints.down('md')]: {
            padding: 0,
        },
        boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
    },

    backgroundBlue: {
        backgroundColor: 'rgba(156, 184, 227, 0.3)',
        boxShadow: 'none',
        borderRadius: '7px',
    },
    expandedFixRadius: {
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
    },

    lineAccordeon: {
        paddingLeft: 10,
        display: `flex`,
        flexDirection: `row`,
        gap: 12,
        alignItems: `center`,
        fontSize: '16px',
    },
    showMore: {
        textDecoration: `underline`,
        color: `rgba(48, 63, 159, 1)`,
        fontWeight: `bold`,
        '&:hover': {
            cursor: `pointer`,
        },
    },
    expandButton: {
        width: '48px',
        paddingRight: 0,
        paddingLeft: 0,
        marginRight: 0,
        [theme.breakpoints.down('sm')]: {
            width: '30px',
        },
    },
    showMoreContainer: {
        marginTop: 40,
        width: `100%`,
        textAlign: `center`,
    },
    catIconTop: {
        width: 24,
        height: 24,
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: 0,
        width: 'calc(100% - 5px)',
        margin: 'auto',
    },
    categorie: {
        fontSize: `16px`,
        lineHeight: `19px`,
        fontWeight: `bold`,
    },
})
