export default theme => ({
    page: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        padding: 20,
        textAlign: 'center',
        paddingTop: '20vh',
        [theme.breakpoints.down('sm')]: {
            paddingTop: '40vh',
        },
    },
    redirectText: {
        fontSize: 16,
        fontWeight: 500,
    },
    error: {
        color: 'red',
    },
})
