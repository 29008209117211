import React from 'react'
import { PropTypes } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import Footer from './Footer'

import { Container, Grid, ButtonGroup } from '@material-ui/core'
import { connect } from 'react-redux'
import IconButton from '@material-ui/core/IconButton'
import EmailIcon from '@mui/icons-material/Email'
import Drawer from '@material-ui/core/Drawer'
import Divider from '@material-ui/core/Divider'
import styles from './Styles/NavigationCss'
import dictionary from './Dictionary/NavigationDico'
import logo from '../../images/logoBleu.png'
import BoatOnComponent from './BoatOnComponent'
import { LocalizedLink, LocalizedSwitcher } from '../../languages'
import AppRoute from './../../constants/AppRoute'
import { getCurrentRootRouteKey } from '../../languages/LocalizerUtils'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import Badge from '@material-ui/core/Badge'
import NotificationsIcon from '@material-ui/icons/Notifications'

import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import MenuList from '@material-ui/core/MenuList'

import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import PersonIcon from '@mui/icons-material/Person'

import { userActions, resetDataBOB, resetFetch } from '../../actions'
import { history } from '../../helpers'
import { Button } from '../common/BoatOnButton'
import MobileDrawer from './MobileDrawer/MobileDrawer'

// Icones
import boaticon from '../../images/boaticon-small.svg'
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined'
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined'
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined'
import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined'

class Navigation extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            hideCategory: props.hideCategory ? props.hideCategory : false,
            isMobileDrawerOpen: false,
            profileOpen: false,
            notificationOpen: false,
            anchorElem: null,
            location: window.location.pathname,
        }
        this.handleDrawerToggle = this.handleDrawerToggle.bind(this)
        this.toggleProfileButton = this.toggleProfileButton.bind(this)
        this.closeProfileButton = this.closeProfileButton.bind(this)
        this.toggleNotificationButton = this.toggleNotificationButton.bind(this)
        this.closeNotificationButton = this.closeNotificationButton.bind(this)
        this.logout = this.logout.bind(this)
        this.handleClickAccountChoice = this.handleClickAccountChoice.bind(this)
        this.handleCloseAccountChoice = this.handleCloseAccountChoice.bind(this)

        this.anchorProfilRef = React.createRef()
        this.anchorNotificationRef = React.createRef()
    }

    componentDidUpdate(_, prevState) {
        if (this.props.hideCategory !== this.state.hideCategory) {
            this.setState({ hideCategory: this.props.hideCategory })
        }

        if (prevState.location !== window.location.pathname) {
            this.setState({ location: window.location.pathname }, () => {
                if (document.getElementById('nav'))
                    document.getElementById('nav').scrollTo(0, 0)
            })
        }
    }

    handleClickAccountChoice(event) {
        this.setState({ anchorElem: event.currentTarget })
    }

    handleCloseAccountChoice() {
        this.setState({ anchorElem: null })
    }

    componentDidMount() {
        window.scrollTo(0, 0)
    }

    handleDrawerToggle() {
        this.setState({ isMobileDrawerOpen: !this.state.isMobileDrawerOpen })
    }

    drawer() {
        const { user, loadingUser } = this.props
        const isMobile = window.innerWidth < 960

        const firstMenu = [
            {
                title: this.displayText('BOstockage'),
                route: AppRoute.ParkingSearchPage,
            },
            {
                title: this.displayText('BOassurance'),
                route: AppRoute.InsurancePage,
            },
            {
                title: this.displayText('bob'),
                link: '/my-boat-logbook',
                route: AppRoute.LogBook.Home,
            },
            {
                title: this.displayText('tarifs'),
                link: '/prices',
                route: AppRoute.PricesPage,
            },
        ]

        const firstMenuMobile = [
            {
                title: this.displayText('tarifsBook'),
                route: AppRoute.PricesPageYacht,
                icon: LocalOfferOutlinedIcon,
                imageSource: undefined,
            },
            {
                title: this.displayText('bob'),
                route: AppRoute.LogBook.Home,
                imageSource: boaticon,
                icon: undefined,
            },
            {
                title: this.displayText('stockage'),
                route: AppRoute.ParkingSearchPage,
                icon: WarehouseOutlinedIcon,
            },
            {
                title: this.displayText('assurance'),
                route: AppRoute.InsurancePage,
                icon: SecurityOutlinedIcon,
            },
            {
                title: this.displayText('contact'),
                link: 'https://www.info.boaton.fr/contactez-nous',
                icon: MessageOutlinedIcon,
            },
        ]

        const secondMenu = user
            ? [
                  {
                      title: this.displayText('rentParking'),
                      link: '/rent-my-parking',
                      route: AppRoute.RentMyParking,
                  },
                  // { title: 'Le blog', link: '/le-blog' },
                  {
                      title: this.displayText('profile'),
                      link: '/profile',
                      route: AppRoute.Profile,
                  },
              ]
            : [
                  {
                      title: this.displayText('rentParking'),
                      link: '/rent-my-parking',
                      route: AppRoute.RentMyParking,
                  },
                  //  { title: this.displayText('blog'), link: '/le-blog' },
                  {
                      title: this.displayText('login'),
                      link: '/login',
                      route: AppRoute.Login,
                  },
              ]

        const secondMenuMobile = []

        let groupToComptes = user?.userGroup?.map(e => {
            return { nom: e.name, abrev: e.name.substring(0, 2), id: e.id }
        })
        if (!groupToComptes) groupToComptes = []

        return (
            <MobileDrawer
                firstMenu={isMobile ? firstMenuMobile : firstMenu}
                secondMenu={isMobile ? secondMenuMobile : secondMenu}
                loadingUser={loadingUser}
                user={user}
                phoneInfo={this.getPhoneInfo()}
                logout={this.logout}
            />
        )
    }

    getPhoneInfo() {
        const { classes } = this.props

        return (
            <>
                <EmailIcon className={classes.logotel} />
                <a
                    href="mailto:paul@boaton.fr"
                    className={classes.topBarPhone}
                    title="mail"
                >
                    <span className={classes.span}>paul@boaton.fr</span>
                </a>
            </>
        )
    }

    renderDependingOnSize() {
        const { classes, user } = this.props
        const { isMobileDrawerOpen } = this.state
        const isMobile = window.innerWidth < 960
        const url = window.location.pathname
        const pathname = getCurrentRootRouteKey(url, this.getMessages())
        const categorys = [
            {
                name: 'stockage',
                route: AppRoute.ParkingSearchPage,
            },
            {
                name: 'assurance',
                route: AppRoute.InsurancePage,
            },
            {
                name: 'bob',
                link: '/my-boat-logbook',
                route: AppRoute.LogBook.Home,
            },
            {
                name: 'tarifs',
                route: AppRoute.PricesPageYacht,
            },
            // { name: 'blog', link: '/le-blog' }, désactiver pour le moment (lien blog)
        ]

        let groupToComptes = user?.userGroup?.map(e => {
            return { nom: e.name, abrev: e.name.substring(0, 2), id: e.id }
        })
        if (!groupToComptes) groupToComptes = []

        const comptes = [
            {
                nom: this.displayText(`fleet`),
                abrev:
                    user?.firstName?.substring(0, 1) +
                    user?.lastName?.substring(0, 1),
                id: -1,
            },
            ...groupToComptes,
        ]
        const affiche = comptes.find(e => e.id === this.props.groupId)
        return (
            <Container className={classes.container} maxWidth="lg">
                <Grid
                    alignItems="center"
                    className={classes.logoContainer}
                    style={
                        isMobile
                            ? {
                                  justifyContent: user
                                      ? 'center'
                                      : 'space-between',
                              }
                            : {
                                  justifyContent: 'flex-start',
                              }
                    }
                    container
                    spacing={3}
                >
                    <Grid
                        container
                        item
                        xs={5}
                        sm={4}
                        md={2}
                        alignItems="center"
                        justifyContent="center"
                        style={isMobile ? { paddingBottom: 0 } : {}}
                    >
                        <LocalizedLink to={AppRoute.Home}>
                            <img
                                src={logo}
                                width="120px"
                                alt="Boaton"
                                title="Boaton"
                            />
                        </LocalizedLink>
                    </Grid>
                    {isMobile && (
                        <Grid
                            item
                            style={{
                                position: 'absolute',
                                right: 0,
                            }}
                            justifyContent="flex-end"
                        >
                            <div
                                className={classes.mobileDrawerButton}
                                aria-label="Open drawer"
                                onClick={() => {
                                    this.handleDrawerToggle()
                                }}
                            >
                                <PersonIcon
                                    style={{
                                        height: 50,
                                        width: 50,
                                        color: '#303F9F',
                                    }}
                                />
                            </div>
                            <Drawer
                                variant="temporary"
                                anchor="right"
                                open={isMobileDrawerOpen}
                                onClose={this.handleDrawerToggle}
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true,
                                }}
                            >
                                {this.drawer()}
                            </Drawer>
                        </Grid>
                    )}
                    <Grid
                        container
                        spacing={3}
                        item
                        xs
                        className={classes.hideMobile}
                    >
                        {categorys.map((category, id) => (
                            <Grid item key={id}>
                                <LocalizedLink
                                    to={category.route}
                                    link={category.link}
                                    style={{
                                        textDecoration: 'none',
                                        display: 'flex',
                                        flexDirection: 'row',
                                    }}
                                    fromNav={true}
                                >
                                    <Typography
                                        className={
                                            pathname &&
                                            pathname === category.route
                                                ? classes.activeTextCategory
                                                : classes.textCategory
                                        }
                                    >
                                        {this.displayText(category.name)}
                                    </Typography>
                                </LocalizedLink>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid
                        container
                        spacing={3}
                        item
                        sm={4}
                        md={3}
                        alignItems="center"
                        justifyContent="flex-end"
                        className={classes.hideMobile}
                    >
                        {this.renderProfileNavigation()}
                    </Grid>
                </Grid>
            </Container>
        )
    }

    toggleProfileButton() {
        this.setState(prevState => ({
            profileOpen: !prevState.profileOpen,
        }))
    }

    toggleNotificationButton() {
        this.setState(prevState => ({
            notificationOpen: !prevState.notificationOpen,
        }))
    }

    closeProfileButton() {
        this.setState(prevState => ({
            profileOpen: false,
        }))
    }

    closeNotificationButton() {
        this.setState(prevState => ({
            notificationOpen: false,
        }))
    }

    logout() {
        this.props.dispatch(userActions.logout())
        this.props.dispatch(resetDataBOB())
        this.props.dispatch(resetFetch())
        this.historyPush(history, AppRoute.Login)
    }

    renderProfileNavigation() {
        const { classes, user } = this.props
        const { profileOpen, notificationOpen } = this.state

        if (user || this.props.loadingUser > 0) {
            return (
                <>
                    <IconButton
                        aria-label="show 0 new notifications"
                        color="inherit"
                        onClick={this.toggleNotificationButton}
                        ref={this.anchorNotificationRef}
                    >
                        <Badge
                            badgeContent={0}
                            color="secondary"
                            overlap="rectangular"
                        >
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <Grid item>
                        <ButtonGroup
                            variant="text"
                            color="primary"
                            aria-label="split button"
                            ref={this.anchorProfilRef}
                            size="small"
                        >
                            <Button
                                classNameOverride={classes.buttonProfil}
                                size="medium"
                                style={{
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                }}
                                href={`${window.location.origin}/${
                                    this.context
                                }/${this.displayText('lien')}`}
                                label={this.displayText('pro')}
                            ></Button>

                            <Button
                                classNameOverride={classes.buttonProfilTwo}
                                type="primary"
                                label=""
                                size="small"
                                style={{
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderLeft: '1px solid black',
                                    minWidth: '46px',
                                }}
                                endIcon={<ArrowDropDownIcon />}
                                aria-controls={
                                    profileOpen
                                        ? 'split-button-menu'
                                        : undefined
                                }
                                aria-expanded={profileOpen ? 'true' : undefined}
                                aria-label="select merge strategy"
                                aria-haspopup="menu"
                                onClick={this.toggleProfileButton}
                            ></Button>
                        </ButtonGroup>
                        <Popper
                            open={profileOpen}
                            anchorEl={this.anchorProfilRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            placement="bottom-end"
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: 'center top',
                                    }}
                                >
                                    <Paper elevation={2}>
                                        <ClickAwayListener
                                            onClickAway={
                                                this.closeProfileButton
                                            }
                                        >
                                            <MenuList
                                                id="split-button-menu"
                                                className={classes.profileList}
                                            >
                                                <LocalizedLink
                                                    to={AppRoute.Profile}
                                                    className={
                                                        classes.profileItem
                                                    }
                                                >
                                                    <MenuItem>
                                                        {this.displayText(
                                                            'myAccount',
                                                        )}
                                                    </MenuItem>
                                                </LocalizedLink>
                                                <LocalizedLink
                                                    to={AppRoute.LogBook.Home}
                                                    className={
                                                        classes.profileItem
                                                    }
                                                >
                                                    <MenuItem>
                                                        {this.displayText(
                                                            'myBOB',
                                                        )}
                                                    </MenuItem>
                                                </LocalizedLink>
                                                <LocalizedLink
                                                    to={AppRoute.Inqueries}
                                                    className={
                                                        classes.profileItem
                                                    }
                                                >
                                                    <MenuItem>
                                                        {this.displayText(
                                                            'myRequest',
                                                        )}
                                                    </MenuItem>
                                                </LocalizedLink>
                                                <LocalizedLink
                                                    to={AppRoute.Messages}
                                                    className={
                                                        classes.profileItem
                                                    }
                                                >
                                                    <MenuItem>
                                                        {this.displayText(
                                                            'myMessage',
                                                        )}
                                                    </MenuItem>
                                                </LocalizedLink>
                                                <Divider
                                                    classes={{
                                                        root: classes.divider,
                                                    }}
                                                />
                                                <LocalizedLink
                                                    to={AppRoute.RentMyParking}
                                                    className={
                                                        classes.profileItem
                                                    }
                                                >
                                                    <MenuItem>
                                                        {this.displayText(
                                                            'rentMyPlace',
                                                        )}
                                                    </MenuItem>
                                                </LocalizedLink>
                                                <Divider
                                                    classes={{
                                                        root: classes.divider,
                                                    }}
                                                />
                                                <MenuItem
                                                    classes={{
                                                        root:
                                                            classes.profileMenuItem,
                                                    }}
                                                    onClick={this.logout}
                                                >
                                                    {this.displayText(
                                                        'disconnect',
                                                    )}
                                                    <ExitToAppIcon />
                                                </MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                        <Popper
                            open={notificationOpen}
                            anchorEl={this.anchorNotificationRef.current}
                            role={undefined}
                            transition
                            disablePortal
                            placement="bottom-end"
                        >
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: 'center top',
                                    }}
                                >
                                    <Paper elevation={2}>
                                        <ClickAwayListener
                                            onClickAway={
                                                this.closeNotificationButton
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.notificationBox
                                                }
                                            >
                                                {this.displayText(
                                                    'notification',
                                                )}
                                            </div>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </Grid>
                </>
            )
        } else {
            return (
                <div className={classes.loginSection}>
                    <LocalizedLink
                        to={AppRoute.Login}
                        className={classes.signinTextButton}
                    >
                        <Button
                            size="small"
                            style={{ marginRight: 10, height: '31px' }}
                            variant="contained"
                            type="secondary"
                            label={this.displayText('login')}
                            classes={{
                                root: classes.signinButton,
                            }}
                        ></Button>
                    </LocalizedLink>
                    <LocalizedLink
                        to={AppRoute.Register}
                        className={classes.signupTextButton}
                    >
                        <Button
                            style={{ height: '31px' }}
                            size="small"
                            color="primary"
                            label={this.displayText('register')}
                            variant="contained"
                            onClick={this.props.onRegisterClick}
                            classes={{
                                root: classes.signupButton,
                            }}
                        ></Button>
                    </LocalizedLink>
                </div>
            )
        }
    }

    render() {
        const { classes } = this.props
        const isMobile = window.innerWidth < 960

        return (
            <>
                <div className={classNames(classes.appBar)}>
                    <div className={classes.topbarcontainer}>
                        <Container
                            className={classes.topbar}
                            style={{ paddingLeft: 12, paddingRight: 12 }}
                        >
                            <div className={classes.infosBox}>
                                {this.getPhoneInfo()}
                                <p className={classes.topBarText}>
                                    <span className={classes.span}>|</span>
                                    <span className={classes.span}>
                                        {this.displayText('topBar')}
                                    </span>
                                </p>
                            </div>
                            <div>
                                <LocalizedSwitcher />
                            </div>
                        </Container>
                    </div>

                    <AppBar
                        color="inherit"
                        position="sticky"
                        elevation={isMobile ? 0 : 4}
                    >
                        <Toolbar disableGutters>
                            {this.renderDependingOnSize()}
                        </Toolbar>
                    </AppBar>
                </div>
                <div className={classes.root} id="nav">
                    <main className={classes.content} id="nav-content">
                        {this.props.children}
                    </main>
                    <Footer />
                </div>
            </>
        )
    }
}

Navigation.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        loadingUser: state.authentication.loading,
        groupId: state.group.currentGroupId,
    }
}

const NavigationConnect = connect(mapStateToProps)(
    withStyles(styles, { withTheme: true })(Navigation),
)

export default NavigationConnect
