import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from '../common/BoatOnComponent'
import styles from './Styles/BoatTabCss'
import BoatInfoPage from '../LogbookPage/LogbookNavigationPage/BoatInfoPage/BoatInfoPage'
import BoatSelecter from '../LogbookPage/LogbookNavigationPage/BoatSelecter'
import { initBobWithBoat } from '../../actions/bob.actions'

class BoatTab extends BoatOnComponent {
    componentDidMount() {
        const { user } = this.props
        if (user)
            this.props.dispatch(initBobWithBoat({ user, onlyMyFloat: true }))
    }

    componentDidUpdate(prevProps) {
        const { user } = this.props
        if (user && !prevProps.user)
            this.props.dispatch(initBobWithBoat({ user, onlyMyFloat: true }))
    }

    render() {
        const { classes, boat } = this.props
        return (
            <div className={classes.root}>
                <div className={classes.content}>
                    <BoatInfoPage
                        classes={{
                            root: classes.boatInfoRoot,
                        }}
                        profile={true}
                    />
                </div>
                <BoatSelecter onProfile={true} style={{ marginTop: 10 }} />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boat: state.bob.boat,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(BoatTab))
