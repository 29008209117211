export default {
    budget: {
        FR: 'Mon budget',
        EN: 'My budget',
    },
    income: {
        FR: 'Mes revenus',
        EN: 'My incomes',
    },
    expense: {
        FR: 'Mes dépenses',
        EN: 'My expenses',
    },
    search: {
        FR: 'Je recherche',
        EN: 'Search',
    },
    january: {
        FR: 'Janvier',
        EN: 'January',
    },
    february: {
        FR: 'Février',
        EN: 'february',
    },
    march: {
        FR: 'Mars',
        EN: 'Mars',
    },
    april: {
        FR: 'Avril',
        EN: 'April',
    },
    may: {
        FR: 'Mai',
        EN: 'May',
    },
    june: {
        FR: 'Juin',
        EN: 'June',
    },
    july: {
        FR: 'Juillet',
        EN: 'July',
    },
    august: {
        FR: 'Août',
        EN: 'August',
    },
    september: {
        FR: 'Septembre',
        EN: 'September',
    },
    october: {
        FR: 'Octobre',
        EN: 'October',
    },
    november: {
        FR: 'Novembre',
        EN: 'Novembre',
    },
    december: {
        FR: 'Décembre',
        EN: 'December',
    },
    download: {
        FR: 'Télécharger',
        EN: 'Download',
    },
    editDate: {
        FR: 'Modifier date',
        EN: 'Edit date',
    },
    delete: {
        FR: 'Supprimer',
        EN: 'Delete',
    },
    loading: {
        FR: 'Chargement',
        EN: 'Loading',
    },
}
