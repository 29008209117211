export const allParkingsConstants = {
    REQUEST_PARKINGS: `REQUEST_PARKINGS`,
    REQUEST_PARKINGS_SUCCESS: `REQUEST_PARKINGS_SUCCESS`,
    REQUEST_PARKINGS_FAILURE: `REQUEST_PARKINGS_FAILURE`,
    REQUEST_PERSONAL_PARKINGS: `REQUEST_PERSONAL_PARKINGS`,
    REQUEST_PERSONAL_PARKINGS_SUCCESS: `REQUEST_PERSONAL_PARKINGS_SUCCESS`,
    REQUEST_PERSONAL_PARKINGS_FAILURE: `REQUEST_PERSONAL_PARKINGS_FAILURE`,
    REQUEST_RESERVED_PARKINGS: `REQUEST_RESERVED_PARKINGS`,
    REQUEST_RESERVED_PARKINGS_SUCCESS: `REQUEST_RESERVED_PARKINGS_SUCCESS`,
    REQUEST_RESERVED_PARKINGS_FAILURE: `REQUEST_RESERVED_PARKINGS_FAILURE`,
    START_UPDATE_PARKING_TOTAL_VIEW: `START_UPDATE_PARKING_TOTAL_VIEW`,
    UPDATE_PARKING_TOTAL_VIEW_SUCCESS: `UPDATE_PARKING_TOTAL_VIEW_SUCCESS`,
    UPDATE_PARKING_TOTAL_VIEW_FAILURE: `UPDATE_PARKING_TOTAL_VIEW_FAILURE`,
    CHANGE_STATUS_PARKING: `CHANGE_STATUS_PARKING`,
    CHANGE_STATUS_PARKING_SUCCESS: `CHANGE_STATUS_PARKING_SUCCESS`,
    CHANGE_STATUS_PARKING_FAILURE: `CHANGE_STATUS_PARKING_FAILURE`,
}
