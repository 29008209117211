import { typesConstants } from '../constants'

const initialState = {
    listBoatModels: null,
    boatTypes: null,
    engineTypes: null,
    engineTypeDetails: null,
    eventTypes: null,
    checkupTypes: null,
    equipmentFamilyTypes: null,
    equipmentTypes: null,
    partTypes: null,
    userCheckups: null,
    userEquipmentFamilies: null,
    userParts: null,
    userEquipments: null,
    blockTypes: null,
    parkingOptionTypes: null,
    loading: 0,
    userUses: null,
    frequencies: null,
    presences: null,
    proBoatActivities: null,
    privateBoatActivities: null,
    navFrequencies: null,
    boatLicences: null,
    orderStatusTypes: null,
    leaveTypes: [],
    contractTypes: null,
    trainingTypes: null,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case typesConstants.REQUEST_BOATLICENCE_TYPES:
            return {
                ...prevState,
                boatLicences: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_BOATLICENCE_TYPES_SUCCESS:
            return {
                ...prevState,
                boatLicences: action.boatLicences,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_BOATLICENCE_TYPES_FAILURE:
            return {
                ...prevState,
                boatLicences: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_NAVFREQUENCY_TYPES:
            return {
                ...prevState,
                navFrequencies: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_NAVFREQUENCY_TYPES_SUCCESS:
            return {
                ...prevState,
                navFrequencies: action.navFrequencies,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_NAVFREQUENCY_TYPES_FAILURE:
            return {
                ...prevState,
                navFrequencies: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_PRIVATEBOATACTIVITY_TYPES:
            return {
                ...prevState,
                privateBoatActivities: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_PRIVATEBOATACTIVITY_TYPES_SUCCESS:
            return {
                ...prevState,
                privateBoatActivities: action.privateBoatActivities,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_PRIVATEBOATACTIVITY_TYPES_FAILURE:
            return {
                ...prevState,
                privateBoatActivities: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_PROBOATACTIVITY_TYPES:
            return {
                ...prevState,
                proBoatActivities: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_PROBOATACTIVITY_TYPES_SUCCESS:
            return {
                ...prevState,
                proBoatActivities: action.proBoatActivities,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_PROBOATACTIVITY_TYPES_FAILURE:
            return {
                ...prevState,
                proBoatActivities: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_PRESENCE_TYPES:
            return {
                ...prevState,
                presences: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_PRESENCE_TYPES_SUCCESS:
            return {
                ...prevState,
                presences: action.presences,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_PRESENCE_TYPES_FAILURE:
            return {
                ...prevState,
                presences: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_FREQUENCY_TYPES:
            return {
                ...prevState,
                frequencies: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_FREQUENCY_TYPES_SUCCESS:
            return {
                ...prevState,
                frequencies: action.frequencies,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_FREQUENCY_TYPES_FAILURE:
            return {
                ...prevState,
                frequencies: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_USERUSER_TYPES:
            return {
                ...prevState,
                userUses: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_USERUSER_TYPES_SUCCESS:
            return {
                ...prevState,
                userUses: action.userUses,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_USERUSER_TYPES_FAILURE:
            return {
                ...prevState,
                userUses: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_EVENTTYPES:
            return {
                ...prevState,
                eventTypes: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_EVENTTYPES_SUCCESS:
            return {
                ...prevState,
                eventTypes: action.eventTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_EVENTTYPES_FAILURE:
            return {
                ...prevState,
                eventTypes: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_LISTBOATMODELS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case typesConstants.LISTBOATMODELS_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case typesConstants.LISTBOATMODELS_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_BOATTYPES:
            return {
                ...prevState,
                boatTypes: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_BOATTYPES_SUCCESS:
            return {
                ...prevState,
                boatTypes: action.boatTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_BOATTYPES_FAILURE:
            return {
                ...prevState,
                boatTypes: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_ENGINETYPES:
            return {
                ...prevState,
                engineTypes: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_ENGINETYPES_SUCCESS:
            return {
                ...prevState,
                engineTypes: action.engineTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_ENGINETYPES_FAILURE:
            return {
                ...prevState,
                engineTypes: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_ENGINETYPEDETAILS:
            return {
                ...prevState,
                engineTypeDetails: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_ENGINETYPEDETAILS_SUCCESS:
            return {
                ...prevState,
                engineTypeDetails: action.engineTypeDetails,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_ENGINETYPEDETAILS_FAILURE:
            return {
                ...prevState,
                engineTypeDetails: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_CHECKUPTYPES:
            return {
                ...prevState,
                checkupTypes: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_CHECKUPTYPES_SUCCESS:
            return {
                ...prevState,
                checkupTypes: action.checkupTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_CHECKUPTYPES_FAILURE:
            return {
                ...prevState,
                checkupTypes: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_EQUIPMENTFAMILYTYPES:
            return {
                ...prevState,
                equipmentFamilyTypes: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_EQUIPMENTFAMILYTYPES_SUCCESS:
            return {
                ...prevState,
                equipmentFamilyTypes: action.equipmentFamilyTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_EQUIPMENTFAMILYTYPES_FAILURE:
            return {
                ...prevState,
                equipmentFamilyTypes: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_EQUIPMENTTYPES:
            return {
                ...prevState,
                equipmentTypes: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_EQUIPMENTTYPES_SUCCESS:
            return {
                ...prevState,
                equipmentTypes: action.equipmentTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_EQUIPMENTTYPES_FAILURE:
            return {
                ...prevState,
                equipmentTypes: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_PARTTYPES:
            return {
                ...prevState,
                partTypes: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_PARTTYPES_SUCCESS:
            return {
                ...prevState,
                partTypes: action.partTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_PARTTYPES_FAILURE:
            return {
                ...prevState,
                partTypes: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_USERCHECKUPS:
            return {
                ...prevState,
                userCheckups: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_USERCHECKUPS_SUCCESS:
            return {
                ...prevState,
                userCheckups: action.userCheckups,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_USERCHECKUPS_FAILURE:
            return {
                ...prevState,
                userCheckups: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_USEREQUIPMENTFAMILIES:
            return {
                ...prevState,
                userEquipmentFamilies: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_USEREQUIPMENTFAMILIES_SUCCESS:
            return {
                ...prevState,
                userEquipmentFamilies: action.userEquipmentFamilies,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_USEREQUIPMENTFAMILIES_FAILURE:
            return {
                ...prevState,
                userEquipmentFamilies: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_USEREQUIPMENTS:
            return {
                ...prevState,
                userEquipments: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_USEREQUIPMENTS_SUCCESS:
            return {
                ...prevState,
                userEquipments: action.userEquipments,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_USEREQUIPMENTS_FAILURE:
            return {
                ...prevState,
                userEquipments: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_USERPARTS:
            return {
                ...prevState,
                userParts: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_USERPARTS_SUCCESS:
            return {
                ...prevState,
                userParts: action.userParts,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_USERPARTS_FAILURE:
            return {
                ...prevState,
                userParts: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_BLOCKTYPES:
            return {
                ...prevState,
                blockTypes: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_BLOCKTYPES_SUCCESS:
            return {
                ...prevState,
                blockTypes: action.blockTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_BLOCKTYPES_FAILURE:
            return {
                ...prevState,
                blockTypes: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_PARKING_TYPES:
            return {
                ...prevState,
                parkingOptionTypes: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_PARKING_TYPES_SUCCESS:
            return {
                ...prevState,
                parkingOptionTypes: action.parkingOptionTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_PARKING_TYPES_FAILURE:
            return {
                ...prevState,
                parkingOptionTypes: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_LEAVE_TYPES:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_LEAVE_TYPES_SUCCESS:
            return {
                ...prevState,
                leaveTypes: action.leaveTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_LEAVE_TYPES_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_ORDER_STATUS_TYPES:
            return {
                ...prevState,
                orderStatusTypes: null,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_ORDER_STATUS_TYPES_SUCCESS:
            return {
                ...prevState,
                orderStatusTypes: action.orderStatusTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_ORDER_STATUS_TYPES_FAILURE:
            return {
                ...prevState,
                orderStatusTypes: null,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_CONTRACT_TYPES:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_CONTRACT_TYPES_SUCCESS:
            return {
                ...prevState,
                contractTypes: action.contractTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_CONTRACT_TYPES_FAILURE:
            console.error(action.error)
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_TRAINING_TYPES:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case typesConstants.REQUEST_TRAINING_TYPES_SUCCESS:
            return {
                ...prevState,
                trainingTypes: action.trainingTypes,
                loading: prevState.loading - 1,
            }
        case typesConstants.REQUEST_TRAINING_TYPES_FAILURE:
            console.error(action.error)
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        default:
            return prevState
    }
}
