import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../common/BoatOnModalCore'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/ExpIncPageCss'
import dictionary from './Dictionary/ExpIncPageDico'
import { putBOBEvents } from '../../../../actions/bob.actions'
import BoatOnDateSelector from '../../../common/UsefullComponents/BoatOnDateSelector'

class ModifyDateModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            selectedDate: new Date(),
            openModalConnection: true,
            openMenu: false,
            date: '',
            update: 0,
            edit: false,
            error: false,
        }

        this.save = this.save.bind(this)
    }

    async save() {
        const { selectedEvents } = this.props
        const { selectedDate } = this.state

        selectedEvents.forEach(({ id, title, delimitedDate }) => {
            this.props.dispatch(
                putBOBEvents(id, title, {
                    delimitedDate: {
                        ...delimitedDate,
                        startDate: selectedDate,
                        endDate: selectedDate,
                    },
                }),
            )
        })

        this.props.handleClose()
    }

    _body() {
        const { error, selectedDate } = this.state

        return (
            <BoatOnDateSelector
                id={'datePurchase'}
                required
                isDateBudget
                error={error}
                helperText={error ? this.displayText('errorDate') : ''}
                label={this.displayText('date')}
                onChange={value => {
                    this.setState({
                        selectedDate: value,
                    })
                }}
                value={selectedDate}
            />
        )
    }

    render() {
        const { title } = this.props

        return (
            <>
                {this._renderTitle(title)}
                {this._renderBody({
                    body: this._body(),
                })}
                {this._renderActions([
                    {
                        action: this.save,
                        label: this.displayText('validate'),
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        checkups: state.bob.checkups,
        checkupTypes: state.fetch.checkupTypes,
        boat: state.bob.boat,
        bob: state.bob,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ModifyDateModal))
