export default theme => ({
    saveBottom: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        right: '50%',
        transform: 'translate(+50%)',
        width: '100%',
        backgroundColor: 'white',
        borderTop: '3px solid #c4c4c4',
        borderRight: '3px solid #c4c4c4',
        borderLeft: '3px solid #c4c4c4',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        zIndex: 1499,
        padding: '12px',
        maxHeight: '7%',
    },
    button: {
        color: 'black',
        '&:hover': {
            backgroundColor: '#3040a0',
            color: 'white',
        },
        float: 'right',
        marginRight: '15px',
        marginLeft: '15px',
        width: '27%',
        fontSize: '17px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '9px',
            width: '30%',
            marginRight: '5px',
            marginLeft: '5px',
        },
    },
    buttonDelete: {
        color: 'black',
        backgroundColor: '#e46b62',
        '&:hover': {
            backgroundColor: '#e46b62',
            color: 'white',
        },
        float: 'right',
        marginRight: '15px',
        marginLeft: '15px',
        width: '27%',
        fontSize: '17px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '9px',
            width: '30%',
            marginRight: '5px',
            marginLeft: '5px',
        },
    },
})
