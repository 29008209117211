import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import {
    Button,
    Checkbox,
    Chip,
    DialogActions,
    IconButton,
    MenuItem,
    Popover,
    TextField,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core'
import {
    addFileToBobEvent,
    deleteFileFromBobEvent,
    getImageToDisplay,
    typesActions,
} from '../../../../../actions'
import styles from './CustomEventModalCss'
import dictionary from './CustomEventModalDico'
import { MobileTimePicker } from '@mui/x-date-pickers'
import HelpIcon from '@material-ui/icons/Help'
import AddIcon from '@material-ui/icons/Add'
import BoatOnBlock from '../../../../common/Blocks/BoatOnBlock'
import _uniqueId from 'lodash/uniqueId'
import { Button as BobButton } from '../../../../common/BoatOnButton'
import dayjs from 'dayjs'
import { Autocomplete } from '@material-ui/lab'
import { Calendar } from 'react-date-range'
import { fr, enGB } from 'date-fns/locale'
import DeleteIcon from '@material-ui/icons/Delete'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import {
    format,
    isWeekend,
    isSunday,
    isLastDayOfMonth,
    isMonday,
    isSameDay,
    isToday,
    isWithinInterval,
} from 'date-fns'
import DisplayerImgModal from '../../../DisplayerImgModal'

class CustomEventModal extends BoatOnModalCore {
    constructor(props) {
        super(props)

        this.state = {
            event: props.event || null,
            tabIndex: 0,
            selectedDate: null,
            title: '',
            location: '',
            startTime: null,
            endTime: null,
            timezone: null,
            recurrence: 'once', //Par défaut - Récurrence: Une seule fois
            reminderList: [
                {
                    value: 1,
                    unit: 'day',
                },
            ],
            allDay: false,
            isOpenCalendar: false,
            invitList: [],
            comments: '',
            fileNoUrl: { event: [] },
            displayNewFiles: { event: [] },
            deletedFiles: { event: [] },
            loadingImage: false,
        }
        this.save = this.save.bind(this)
        this._renderEssential = this._renderEssential.bind(this)
        this._renderActions = this._renderActions.bind(this)
        this._renderDetails = this._renderDetails.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.handleTimeChange = this.handleTimeChange.bind(this)
        this.customDayContent = this.customDayContent.bind(this)
        this.addReminder = this.addReminder.bind(this)
        this.displayAlertText = this.displayAlertText.bind(this)
        this._renderDocuments = this._renderDocuments.bind(this)
        this.clickDocument = this.clickDocument.bind(this)
        this.addDocument = this.addDocument.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)

        this.dateInputRef = React.createRef()

        this.dictionary = dictionary

        this.context = this.getContext()
        this.recurrenceOptions = [
            {
                label: 'once',
                value: 'once',
            },
            {
                label: 'everyday',
                value: 'everyday',
            },
            {
                label: 'everyweek',
                value: 'everyweek',
            },
            {
                label: 'everymonth',
                value: 'everymonth',
            },
            {
                label: 'everyyear',
                value: 'everyyear',
            },
        ]

        this.alertOptions = [
            {
                unit: 'minute',
                value: 10,
            },
            {
                unit: 'hour',
                value: 1,
            },
            {
                unit: 'hour',
                value: 2,
            },
            {
                unit: 'hour',
                value: 5,
            },
            {
                unit: 'hour',
                value: 12,
            },
            {
                unit: 'day',
                value: 1,
            },
        ]
    }

    /**
     * Gère la mise à jour des champs Heure début et Heure fin
     * @param {*} field
     * @param {*} value
     */
    handleTimeChange(field, value) {
        const { selectedDate, startTime, endTime } = this.state

        // Si le champ modifié est Heure de fin
        // et si l'heure de début est après l'heure de fin
        // alors ça veut dit qu'il s'agit d'un temps de travail sur deux jours
        // donc on modifie l'heure de fin pour ajouter 1 jour pour que les calculs soient corrects
        if (
            field === 'endTime' &&
            startTime &&
            dayjs(value).isBefore(dayjs(startTime))
        ) {
            this.setState({
                [field]: dayjs(value).add(1, 'day'),
            })

            return
        }

        this.setState({
            [field]: value,
        })
    }

    renderTags(value) {
        return value.map(option => {
            if (!option) {
                return <></>
            }

            const label = dayjs(value).format('DD/MM/YYYY')

            return (
                <Chip
                    size="medium"
                    variant="outlined"
                    onDelete={() => {
                        this.setState({
                            selectedDate: null,
                        })
                    }}
                    style={{
                        backgroundColor: '#fcd48e',
                    }}
                    label={label}
                />
            )
        })
    }

    clickDocument() {
        this.refs.fileUploader.click()
    }

    addDocument(event) {
        if (
            event?.target?.files &&
            [...event.target.files].find(file =>
                file.name.toLowerCase().includes('.heic'),
            )
        ) {
            // So we display a loading icon during the time of heic conversion
            this.setState({ loadingImage: true }, async () => {
                await addFileToBobEvent(this, event, {
                    fileNoUrl: this.state.fileNoUrl.event,
                    displayNewFiles: this.state.displayNewFiles.event,
                })
                this.setState({ loadingImage: false })
            })
        } else {
            addFileToBobEvent(this, event, {
                fileNoUrl: this.state.fileNoUrl.event,
                displayNewFiles: this.state.displayNewFiles.event,
            })
        }
    }

    deleteDocumentNoUp(i) {
        deleteFileFromBobEvent(this, i, {
            deletedFiles: this.state.deletedFiles.event,
            displayNewFiles: this.state.displayNewFiles.event,
            fileNoUrl: this.state.fileNoUrl.event,
            files: this.state.event.files,
        })
    }

    // Render l'onglet "Details"
    _renderDetails() {
        const { classes } = this.props
        const { event, location, invitList, comments } = this.state

        return (
            <div className={classes.content}>
                {/* Lieu */}
                <TextField
                    id="lieu"
                    variant="outlined"
                    label={this.displayText('location')}
                    margin={`normal`}
                    required
                    value={location}
                    onChange={e => {
                        this.setState({
                            location: e.target.value,
                        })
                    }}
                    className={classes.textField}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                />

                {/* Date selector */}
                <Autocomplete
                    multiple
                    limitTags={1}
                    id="limit-tags"
                    options={[]}
                    value={[]}
                    renderTags={this.renderTags}
                    open={false}
                    onOpen={() => {
                        // this.setState({
                        //     isOpenCalendar: true
                        // })
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            className={classes.textField}
                            margin={`normal`}
                            variant="outlined"
                            label={
                                <>
                                    {this.displayText('guests')}
                                    <Tooltip
                                        title={''}
                                        classes={{
                                            tooltip: classes.tooltip,
                                        }}
                                    >
                                        <HelpIcon
                                            className={classes.helpIcon}
                                        />
                                    </Tooltip>
                                </>
                            }
                            InputLabelProps={{
                                style: { pointerEvents: 'auto' },
                            }}
                        />
                    )}
                    sx={{ width: '500px' }}
                />

                {/* Commentaires */}
                <TextField
                    id="comments"
                    variant="outlined"
                    label={
                        <>
                            {this.displayText('comments')}
                            <Tooltip
                                title={this.displayText(`helpProtocol`)}
                                classes={{
                                    tooltip: classes.tooltip,
                                }}
                            >
                                <HelpIcon className={classes.helpIcon} />
                            </Tooltip>
                        </>
                    }
                    margin={`normal`}
                    multiline
                    minRows={5}
                    maxRows={5}
                    value={comments}
                    onChange={e => {
                        // this.handleChange({
                        //     protocol: e.target.value,
                        // })
                        this.setState({
                            comments: e.target.value,
                        })
                    }}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                        style: { pointerEvents: 'auto' },
                    }}
                />

                {/* Pièces jointes */}
                {this._renderDocuments()}
            </div>
        )
    }

    _renderDocuments() {
        const { classes } = this.props
        const { event, displayNewFiles } = this.state

        return (
            <>
                <Typography variant={`body1`}>
                    {this.displayText(`attachments`)}
                </Typography>
                <div className={classes.addDoc} onClick={this.clickDocument}>
                    <AddAPhotoIcon className={classes.doc} />
                </div>
                <input
                    onChange={e => {
                        this.addDocument(e)
                    }}
                    multiple
                    type="file"
                    id="fileLast"
                    ref="fileUploader"
                    style={{ display: 'none' }}
                />
                <DisplayerImgModal
                    file={getImageToDisplay(event, displayNewFiles.event)}
                    deleteDocumentNoUp={this.deleteDocumentNoUp}
                    loadingImage={this.state.loadingImage}
                />
            </>
        )
    }

    // Render l'onglet "Informations essentielles"
    _renderEssential() {
        const { classes } = this.props
        const {
            event,
            isOpenCalendar,
            selectedDate,
            startTime,
            endTime,
            title,
            allDay,
            recurrence,
            reminderList,
        } = this.state

        return (
            <div className={classes.content}>
                {/* Titre */}
                <TextField
                    id="title"
                    variant="outlined"
                    label={this.displayText('title')}
                    margin={`normal`}
                    required
                    value={title}
                    onChange={e => {
                        // this.handleChange({ eventTypeId: e.target.value })
                        this.setState({
                            title: e.target.value,
                        })
                    }}
                    className={classes.textField}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                />

                {/* Date selector */}
                <Autocomplete
                    multiple
                    limitTags={1}
                    id="limit-tags"
                    options={[]}
                    value={[selectedDate]}
                    renderTags={this.renderTags}
                    open={false}
                    onOpen={() => {
                        this.setState({
                            isOpenCalendar: true,
                        })
                    }}
                    renderInput={params => (
                        <TextField
                            {...params}
                            className={classes.textField}
                            margin={`normal`}
                            variant="outlined"
                            label={
                                <>
                                    {'Dates'}
                                    <Tooltip
                                        title={''}
                                        classes={{
                                            tooltip: classes.tooltip,
                                        }}
                                    >
                                        <HelpIcon
                                            className={classes.helpIcon}
                                        />
                                    </Tooltip>
                                </>
                            }
                            InputLabelProps={{
                                style: { pointerEvents: 'auto' },
                            }}
                        />
                    )}
                    sx={{ width: '500px' }}
                    ref={this.dateInputRef}
                />
                <Popover
                    open={isOpenCalendar}
                    onClose={() => {
                        this.setState({
                            isOpenCalendar: false,
                        })
                    }}
                    anchorReference={this.dateInputRef}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Calendar
                        className={classes.calendar}
                        weekStartsOn={1}
                        // date={'dd/MM/YYYY'}
                        dayDisplayFormat="dd/MM/YYYY"
                        onChange={value => {
                            this.setState({
                                selectedDate: value,
                                isOpenCalendar: false,
                            })
                        }}
                        date={selectedDate}
                        locale={this.context === 'fr' ? fr : enGB}
                        color="#D5EFFF"
                        showDateDisplay={false}
                        dayContentRenderer={this.customDayContent}
                        dateDisplayFormat={'P'}
                        classNames={{
                            startEdge: classes.startEdge,
                            endEdge: classes.endEdge,
                            monthAndYearPickers: classes.monthAndYearPickers,
                            calendarWrapper: classes.calendarWrapper,
                            dayPassive: classes.dayPassive,
                            dayNumber: classes.dayNumber,
                            weekDays: classes.weekDays,
                            inRange: classes.inRange,
                            month: classes.month,
                            days: classes.days,
                            weekDay: classes.weekDay,
                            dayToday: classes.dayToday,
                            dayEndPreview: classes.dayEndPreview,
                            dayInPreview: classes.dayInPreview,
                            dayStartPreview: classes.dayStartPreview,
                        }}
                    />
                </Popover>

                {/* All day */}
                <div className={classes.allDayContainer}>
                    {this.displayText('allDay')}
                    <Checkbox
                        checked={allDay}
                        onChange={() => {
                            // Désactive et vide les champs StartTime et EndTime
                            this.setState({
                                allDay: !allDay,
                                // startTime: null,
                                // endTime: null
                            })
                        }}
                        color="primary"
                    />
                </div>

                {/* Time pickers */}
                <div
                    className={classes.row}
                    style={{
                        gap: 10,
                    }}
                >
                    <div className={classes.col}>
                        <MobileTimePicker
                            label={this.displayText('startTime')}
                            amPm={true}
                            value={startTime}
                            onChange={newValue => {
                                this.handleTimeChange('startTime', newValue)
                            }}
                            disabled={this.state.allDay} //Désactivé si absent toute la journée
                        />
                    </div>
                    <div className={classes.col}>
                        <MobileTimePicker
                            label={this.displayText('endTime')}
                            amPm={false}
                            value={endTime}
                            onChange={newValue => {
                                this.handleTimeChange('endTime', newValue)
                            }}
                            disabled={this.state.allDay} //Désactivé si absent toute la journée
                        />
                    </div>
                </div>

                {/* Récurence */}
                <TextField
                    id="title"
                    variant="outlined"
                    label={this.displayText('recurrence')}
                    margin={`normal`}
                    required
                    value={recurrence}
                    select
                    onChange={e => {
                        this.setState({
                            recurrence: e.target.value,
                        })
                    }}
                    className={classes.textField}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                >
                    {this.recurrenceOptions.map(recurrence => {
                        return (
                            <MenuItem
                                key={recurrence.value}
                                value={recurrence.value}
                            >
                                {this.displayText(recurrence.label)}
                            </MenuItem>
                        )
                    })}
                </TextField>

                {/* Bouton Ajouter une alertre */}
                <Button
                    variant="text"
                    onClick={this.addReminder}
                    color="primary"
                    classes={{
                        root: classes.textButton,
                    }}
                >
                    <AddIcon />
                    {this.displayText('addAlert')}
                </Button>
                <div className={classes.col}>
                    {reminderList.map((reminder, index) => {
                        return (
                            <div className={classes.row} style={{ gap: 20 }}>
                                <TextField
                                    id={'reminder-' + index}
                                    variant="outlined"
                                    label={this.displayText('alert')}
                                    margin={`normal`}
                                    required
                                    value={reminder}
                                    select
                                    onChange={e => {
                                        const _reminderList = this.state
                                            .reminderList

                                        _reminderList[index] = e.target.value

                                        this.setState({
                                            reminderList: _reminderList,
                                        })
                                    }}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        classes: {
                                            root: classes.labelInput,
                                        },
                                    }}
                                    SelectProps={{
                                        renderValue: this.displayAlertText,
                                    }}
                                >
                                    {this.alertOptions.map(alertOption => {
                                        return (
                                            <MenuItem value={alertOption}>
                                                {this.displayAlertText(
                                                    alertOption,
                                                )}
                                            </MenuItem>
                                        )
                                    })}
                                </TextField>
                                <IconButton
                                    size={`small`}
                                    id={`delete-break`}
                                    onClick={() => {
                                        // Supprimer l'alerte de la liste
                                        let r = [...this.state.reminderList]
                                        r.splice(index, 1)
                                        this.setState({
                                            reminderList: r,
                                        })
                                    }}
                                    children={<DeleteIcon />}
                                />
                            </div>
                        )
                    })}
                </div>
            </div>
        )
    }

    displayAlertText({ unit, value }) {
        if (unit === 'minute') {
            return [
                value,
                this.displayText(unit),
                this.displayText('before'),
            ].join(' ')
        } else if (unit === 'day') {
            return [
                value,
                value > 1
                    ? this.displayText(unit) + 's'
                    : this.displayText(unit),
                this.displayText('before'),
            ].join(' ')
        }
    }

    addReminder() {
        this.setState({
            reminderList: [
                ...this.state.reminderList,
                {
                    unit: 'day',
                    value: 1,
                },
            ],
        })
    }

    save() {
        this.props.handleClose()
    }

    customDayContent(day) {
        const { classes } = this.props
        return (
            <span>
                <span>
                    {isSameDay(
                        new Date(day),
                        this?.state?.startDate
                            ? new Date(this.state.startDate)
                            : new Date(),
                    ) ? (
                        <div className={classes.startDateCustom}>
                            {format(day, 'd')}
                        </div>
                    ) : isSameDay(
                          new Date(day),
                          this?.state?.endDate
                              ? new Date(this.state.endDate)
                              : new Date(),
                      ) ? (
                        <div className={classes.endDateCustom}>
                            {format(day, 'd')}
                        </div>
                    ) : isToday(day) ? (
                        <div
                            className={
                                isWithinInterval(new Date(day), {
                                    start: this?.state?.startDate
                                        ? new Date(this.state.startDate)
                                        : new Date(),
                                    end: this?.state?.endDate
                                        ? new Date(this.state.endDate)
                                        : new Date(),
                                })
                                    ? classes.todayWithinIntervalCustom
                                    : classes.todayCustom
                            }
                        >
                            {format(day, 'd')}
                        </div>
                    ) : isWeekend(day) ? (
                        <div className={classes.weekEndCustom}>
                            {format(day, 'd')}
                        </div>
                    ) : isWithinInterval(new Date(day), {
                          start: this?.state?.startDate
                              ? new Date(this.state.startDate)
                              : new Date(),
                          end: this?.state?.endDate
                              ? new Date(this.state.endDate)
                              : new Date(),
                      }) ? (
                        <div className={classes.inRangeCustom}>
                            {format(day, 'd')}
                        </div>
                    ) : (
                        <span>{format(day, 'd')}</span>
                    )}{' '}
                    {!isSameDay(
                        this?.state?.startDate
                            ? new Date(this.state.startDate)
                            : new Date(),
                        this?.state?.endDate
                            ? new Date(this.state.endDate)
                            : new Date(),
                    ) &&
                    isWithinInterval(new Date(day), {
                        start: this?.state?.startDate
                            ? new Date(this.state.startDate)
                            : new Date(),
                        end: this?.state?.endDate
                            ? new Date(this.state.endDate)
                            : new Date(),
                    }) &&
                    isSameDay(
                        new Date(day),
                        this?.state?.startDate
                            ? new Date(this.state.startDate)
                            : new Date(),
                    ) &&
                    !isSunday(new Date(day)) &&
                    !isLastDayOfMonth(new Date(day)) ? (
                        <div className={classes.startDateFilledCustom}></div>
                    ) : !isSameDay(
                          this?.state?.startDate
                              ? new Date(this.state.startDate)
                              : new Date(),
                          this?.state?.endDate
                              ? new Date(this.state.endDate)
                              : new Date(),
                      ) &&
                      isWithinInterval(new Date(day), {
                          start: this?.state?.startDate
                              ? new Date(this.state.startDate)
                              : new Date(),
                          end: this?.state?.endDate
                              ? new Date(this.state.endDate)
                              : new Date(),
                      }) &&
                      isSameDay(
                          new Date(day),
                          this?.state?.endDate
                              ? new Date(this.state.endDate)
                              : new Date(),
                      ) &&
                      !isMonday(new Date(day)) &&
                      new Date(day).getDate() !== 1 ? (
                        <div className={classes.endDateFilledCustom}></div>
                    ) : (
                        ''
                    )}
                </span>
            </span>
        )
    }

    _getContextButton() {
        const { event } = this.state
        let baseTab = [
            {
                disabled: this.props.loading > 0,
                label: this.displayText(`valid`),
                action: this.save,
                type: `primary`,
            },
        ]
        return baseTab
    }

    _renderActions(CTAs) {
        const { classes } = this.props

        //TODO: allow multi button and position them
        if (!CTAs) return <></>

        return (
            <DialogActions className={classes.footer}>
                {CTAs.map(cta => {
                    return cta.permissions ? (
                        <BoatOnBlock
                            permissions={cta.permissions}
                            onlyOnePermission={cta.onlyOnePermission}
                            addedCondition={cta.addedCondition}
                            children={
                                <BobButton
                                    primary
                                    label={cta.label}
                                    onClick={cta.action}
                                    style={{
                                        minWidth: cta.minWidth || 0,
                                        margin: 10,
                                        height: `calc(100% - 20px)`,
                                    }}
                                    endIcon={cta.endIcon || null}
                                    disabled={cta.disabled}
                                    key={`cta.label-${_uniqueId()}`}
                                    type={cta.type}
                                    form={cta.form}
                                    size={`large`}
                                />
                            }
                        />
                    ) : (
                        <BobButton
                            primary
                            label={cta.label}
                            onClick={cta.action}
                            style={{
                                minWidth: cta.minWidth || 0,
                                margin: 10,
                                height: `calc(100% - 20px)`,
                            }}
                            endIcon={cta.endIcon || null}
                            disabled={cta.disabled}
                            key={`cta.label-${_uniqueId()}`}
                            type={cta.type}
                            form={cta.form}
                            size={`large`}
                        />
                    )
                })}
            </DialogActions>
        )
    }

    render() {
        const { boat, title: modalTitle } = this.props
        const { checkModalOpen, event, startTime, endTime, title } = this.state
        const boatId = boat?.id
        return (
            <>
                {this._renderTitle(modalTitle, true, [
                    this.displayText(`essentialInfos`),
                    this.displayText(`details`),
                ])}
                {this._renderBody({
                    bodies: [this._renderEssential(), this._renderDetails()],
                })}
                {this._renderActions(this._getContextButton())}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        boat: state.bob.boat,
        events: state.bob.events,
        checkupTypes: state.types.checkupTypes,
        newEvent: state.bob.newEvent,
        eventTypes: typesActions.getEventTypeCheckup(state.types.eventTypes),
        // leaveTypes: typesActions.getEventTypeLeave(state.types.eventTypes),
        loading: state.bob.loading,
        currentGroupId: state.group.currentGroupId,
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
        role: state.block.selectedGroup.userRole,
        groups: state.group.groupsMembers,
        groupId: state.group.currentGroupId,
        boatsGroup: state.group.groupsMembers
            ? state.group.groupsMembers.boats
            : null,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
    }
}

export default connect(mapStateToProps)(withStyles(styles)(CustomEventModal))
