import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@mui/icons-material/Delete'
import BoatOnComponent from '../../../common/BoatOnComponent'
import dictionary from './Dictionary/LogbookBottomBarDico'
import { GetApp } from '@material-ui/icons'
import { HiOutlineClipboard } from 'react-icons/all'
import { Tooltip } from '@material-ui/core'

const styles = theme => ({
    saveBottom: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        right: '50%',
        transform: 'translate(+50%)',
        width: '100%',
        backgroundColor: 'white',
        borderTop: '3px solid #c4c4c4',
        borderRight: '3px solid #c4c4c4',
        borderLeft: '3px solid #c4c4c4',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        zIndex: 1499,
        padding: '12px',
        maxHeight: '7%',
    },
    button: {
        color: 'black',
        '&:hover': {
            backgroundColor: '#3040a0',
            color: 'white',
        },
        float: 'right',
        marginRight: '15px',
        marginLeft: '15px',
        width: '27%',
        fontSize: '17px',
        pointerEvents: 'auto !important',
        [theme.breakpoints.down('sm')]: {
            fontSize: '9px',
            width: '30%',
            marginRight: '5px',
            marginLeft: '5px',
        },
    },
    buttonDelete: {
        color: 'black',
        backgroundColor: '#e46b62',
        '&:hover': {
            backgroundColor: '#e46b62',
            color: 'white',
        },
        float: 'right',
        marginRight: '15px',
        marginLeft: '15px',
        width: '27%',
        fontSize: '17px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '9px',
            width: '30%',
            marginRight: '5px',
            marginLeft: '5px',
        },
    },
})

class LogbookBottomBar extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary
    }

    renderDisabled() {
        const { classes, handleDownloadExcel, handleDownloadPdf } = this.props
        const isMobile = window.innerWidth < 600

        return (
            <>
                <Tooltip title={this.displayText('helper')}>
                    <Button
                        disabled={true}
                        className={classes.button}
                        size="medium"
                        color="secondary"
                        variant="contained"
                        endIcon={!isMobile ? <HiOutlineClipboard /> : <></>}
                        onClick={handleDownloadExcel}
                    >
                        {this.displayText('downloadExcel')}
                    </Button>
                </Tooltip>
                <Tooltip title={this.displayText('helper')}>
                    <Button
                        disabled={true}
                        className={classes.button}
                        size="medium"
                        color="secondary"
                        variant="contained"
                        endIcon={!isMobile ? <GetApp /> : <></>}
                        onClick={handleDownloadPdf}
                    >
                        {this.displayText('downloadPdf')}
                    </Button>
                </Tooltip>
            </>
        )
    }

    renderNormal() {
        const { classes, handleDownloadExcel, handleDownloadPdf } = this.props
        const isMobile = window.innerWidth < 600

        return (
            <>
                <Button
                    className={classes.button}
                    size="medium"
                    color="secondary"
                    variant="contained"
                    endIcon={!isMobile ? <HiOutlineClipboard /> : <></>}
                    onClick={handleDownloadExcel}
                >
                    {this.displayText('downloadExcel')}
                </Button>
                <Button
                    className={classes.button}
                    size="medium"
                    color="secondary"
                    variant="contained"
                    endIcon={!isMobile ? <GetApp /> : <></>}
                    onClick={handleDownloadPdf}
                >
                    {this.displayText('downloadPdf')}
                </Button>
            </>
        )
    }

    render() {
        const { classes, handleDelete, isDownloadDisabled } = this.props

        const isMobile = window.innerWidth < 600

        if (this.props.open) {
            return (
                <div className={classes.saveBottom}>
                    {isDownloadDisabled()
                        ? this.renderDisabled()
                        : this.renderNormal()}
                    <Button
                        className={classes.buttonDelete}
                        size="medium"
                        color="secondary"
                        variant="contained"
                        endIcon={!isMobile ? <DeleteIcon /> : <></>}
                        onClick={handleDelete}
                    >
                        {this.displayText('delete')}
                    </Button>
                </div>
            )
        } else {
            return null
        }
    }
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(withStyles(styles)(LogbookBottomBar))
