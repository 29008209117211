import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import {
    Checkbox,
    Chip,
    DialogActions,
    Divider,
    IconButton,
    MenuItem,
    Popover,
    TextField,
    Tooltip,
    Typography,
    withStyles,
} from '@material-ui/core'
import {
    addFileToBobEvent,
    deleteFileFromBobEvent,
    getImageToDisplay,
    postAbsence,
    putAbsence,
    typesActions,
} from '../../../../../actions'
import styles from './AbsenceModalCss'
import UserSelector from '../../../../common/UserSelector/UserSelector'
import dictionary from '../Dictionary/AbsenceModalDico'
import { MobileTimePicker } from '@mui/x-date-pickers'
import HelpIcon from '@material-ui/icons/Help'
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto'
import EditIcon from '@material-ui/icons/Edit'
import BoatOnBlock from '../../../../common/Blocks/BoatOnBlock'
import _uniqueId from 'lodash/uniqueId'
import { Button as BobButton } from '../../../../common/BoatOnButton'
import dayjs from 'dayjs'
import { Autocomplete } from '@material-ui/lab'
import DisplayerImgModal from '../../../DisplayerImgModal'
import { activityActions } from '../../../../../actions/activity.actions'
import { commentsActions } from '../../../../../actions/bob/comments.actions'
import BoatOnDateSelector from '../../../../common/UsefullComponents/BoatOnDateSelector'
import { settingsActions } from '../../../../../actions/bob/settings.actions'
import { getContextFromUrl } from '../../../../../languages/LocalizerUtils'
import { absencesActions } from '../../../../../actions/bob/absences.actions'
import worktimesUtils, {
    DEFAULT_NB_HOURS_PER_DAY,
} from '../../../../../utils/worktimesUtils'
import BoatOnAlert from '../../../../common/UsefullComponents/BoatOnAlert'
import { workTimeActions } from '../../../../../actions/bob/workTime.actions'
import BoatOnNumberField from '../../../../common/UsefullComponents/BoatOnNumberField'
import { Actions, PermissionsEntities } from '../../../../../constants'

class AbsenceModal extends BoatOnModalCore {
    constructor(props) {
        super(props)

        this.state = {
            event: props?.event
                ? {
                      ...props.event,
                      userLink: props.groupMembers.find(gm => {
                          return gm.id === props?.event?.userLinkId
                      }),
                  }
                : {
                      userLink: this.getCurrentUserLink(),
                      responsibleLink: null,
                      absenceReasonType: null,
                      comment: '',
                  },
            tabIndex: 0,
            usersSelectedRtq: [],
            assignedUser: [],
            errorAssignedUser: false,
            startTime: null,
            endTime: null,
            isOpenCalendar: false,
            selectedDateRanges: [],
            selectedDate: null, //Si on est en mode édition, on sélectionne le premier créneau puisqu'il ne peut y en avoir que un
            leaveType: null,
            fileNoUrl: { event: [] },
            displayNewFiles: { event: [] },
            deletedFiles: { event: [] },
            selectionField: 'startDate',
            allDay: true,
            nbOfMinutesAbsent: props?.event?.nbOfMinutesAbsent || 0,
            userGroup: null,
            openedDateRangeIndex: 0,
            errorFields: [],
            userLink: this.getCurrentUserLink(),

            splitWorkTimes: null,
            splitAbsences: null,
            // La modale est désactivée si l'absence est déjà enregistrée et si son statut est différent de "En attente de validation"
            disabled: props?.event?.id && props?.event?.absenceStatus?.id !== 1,
            byManager: false,
            // requestedUserAbsences: null, //Utilisé pour connaitre les absences de l'utilisateur sélectionné dans la modale
            userPaidVacationsData: null,
        }

        this.nbPaidVacationAllowedDefault = 25

        this.daysOfWeek = [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
        ]

        this.saveWorkTime = this.saveWorkTime.bind(this)
        this._renderEssential = this._renderEssential.bind(this)
        this._renderActions = this._renderActions.bind(this)
        this.calculateTotalTime = this.calculateTotalTime.bind(this)
        this.renderTags = this.renderTags.bind(this)
        this.handleTimeChange = this.handleTimeChange.bind(this)
        this._renderDocuments = this._renderDocuments.bind(this)
        this.clickDocument = this.clickDocument.bind(this)
        this.addDocument = this.addDocument.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.selectDateRange = this.selectDateRange.bind(this)
        this._renderUserSelector = this._renderUserSelector.bind(this)
        this._renderManagerSelector = this._renderManagerSelector.bind(this)
        this._renderTypeAbsence = this._renderTypeAbsence.bind(this)
        this.getDisabledDates = this.getDisabledDates.bind(this)
        this.getDisabledDays = this.getDisabledDays.bind(this)
        this.onDateRangeChange = this.onDateRangeChange.bind(this)
        this._renderDateRanges = this._renderDateRanges.bind(this)
        this.getCurrentUserLink = this.getCurrentUserLink.bind(this)
        this.findUserLinkById = this.findUserLinkById.bind(this)
        this.getRemainingPaidVacationsForSelectedUser = this.getRemainingPaidVacationsForSelectedUser.bind(
            this,
        )
        this.onlyTime = this.onlyTime.bind(this)
        this.earliest = this.earliest.bind(this)
        this.latest = this.latest.bind(this)
        this.splitEvents = this.splitEvents.bind(this)
        this.getAvailableTime = this.getAvailableTime.bind(this)
        this.getAvailableTimeSlotsForOneDay = this.getAvailableTimeSlotsForOneDay.bind(
            this,
        )
        this.getMaxTime = this.getMaxTime.bind(this)
        this.onUserChange = this.onUserChange.bind(this)
        this.onManagerChange = this.onManagerChange.bind(this)
        // this.validateOrRejectAbsence = this.validateOrRejectAbsence.bind(this)

        this.dateInputRef = React.createRef()

        this.dictionary = dictionary
    }

    _addErrors(fields) {
        this.setState({
            errorFields: fields,
        })
    }

    _handleError() {
        const {
            selectedDateRanges,
            nbOfMinutesAbsent,
            allDay,
            event,
        } = this.state

        const { absencesManager } = this.props

        const errors = []

        // Si l'utilisateur est un manager, il doit sélectionner un utilisateur
        if (absencesManager && absencesManager.length > 0 && !event.userLink) {
            errors.push('userLink')
        }

        if (!event.responsibleLink) {
            errors.push('responsibleLink')
        }

        if (selectedDateRanges.length === 0) {
            errors.push('selectedDateRanges')
        }

        if (!event?.absenceReasonType?.id) {
            errors.push('absenceReasonType')
        }

        if (!nbOfMinutesAbsent && allDay) {
            errors.push('nbOfMinutesAbsent')
        }

        for (const range of selectedDateRanges) {
            if (!range?.startDate && !allDay) {
                errors.push('startDate')
            }

            if (!range?.endDate && !allDay) {
                errors.push('endDate')
            }

            if (!range?.endTime && !allDay) {
                errors.push('endTime')
            }

            if (!range?.startTime && !allDay) {
                errors.push('startTime')
            }
        }

        this._addErrors(errors)

        return errors.length === 0
    }

    componentDidMount() {
        const {
            workTimes,
            absences,
            absencesManager,
            currentGroupId,
            leaveTypes,
            role,
        } = this.props

        const { event, userLink } = this.state

        if (!workTimes) {
            this.props.dispatch(workTimeActions.getWorkTimes(userLink.id))
        }
        if (!absences) {
            this.props.dispatch(
                absencesActions.requestUserAbsences(userLink.id),
            )
        }

        if (!absencesManager) {
            this.props.dispatch(
                absencesActions.requestUserAbsencesByManager(userLink.id),
            )
        }

        if (leaveTypes) {
            this.props.dispatch(typesActions.requestLeaveTypes())
        }

        if (event?.id) {
            // Lancement du chargement de l'activité et des commentaires lorsque la modale est montée
            // this.props.dispatch(activityActions.getBobEventActivity(event?.id))
            this.props.dispatch(activityActions.getAbsenceActivity(event?.id))
            this.props.dispatch(commentsActions.getAbsenceComments(event?.id))

            // parse les heures de début et fin
            const startTime = event?.absenceTime?.startTime
            const endTime = event?.absenceTime?.endTime
            let dt, et
            if (startTime) {
                dt = new Date()
                dt.setHours(parseInt(startTime.split(':')[0]))
                dt.setMinutes(parseInt(startTime.split(':')[1]))
                dt.setSeconds(parseInt(startTime.split(':')[2]))

                et = new Date()
                et.setHours(parseInt(endTime.split(':')[0]))
                et.setMinutes(parseInt(endTime.split(':')[1]))
                et.setSeconds(parseInt(endTime.split(':')[2]))
            }

            const selectedDateRanges = [
                {
                    startDate: new Date(event?.delimitedDate?.startDate),
                    endDate: new Date(event?.delimitedDate?.endDate),
                    startTime: dt ? dayjs(dt) : undefined,
                    endTime: et ? dayjs(et) : undefined,
                    //   génerer un id temporaire pour pouvoir identifier et filtrer plus facilement
                    temp_id: Math.random()
                        .toString(36)
                        .substr(2, 9),
                },
            ]

            this.setState({
                selectedDate: 0, //Sélection de la première et seule date
                selectedDateRanges,
                event: event,
                allDay:
                    !event?.absenceTime?.startTime &&
                    !event?.absenceTime?.endTime,
            })
        }

        // if (workTimes || absences) {
        //     this._sortAndSplitData(workTimes, absences)
        // }

        if (currentGroupId) {
            // Récupération des réglages des temps de travail
            this.props.dispatch(
                settingsActions.getGroupWorkTimeSettings({
                    groupId: currentGroupId,
                }),
            )

            // Récupération des réglages des congés payés
            this.props.dispatch(
                settingsActions.getPaidVacationsSettings({
                    groupId: currentGroupId,
                }),
            )

            // Récupération du total des jours de congés posé depuis le début de la période de calcul
            this.props.dispatch(
                absencesActions.getTotalAbsenceForUserLink(userLink.id),
            )
        }

        if (
            (absencesManager && absencesManager.length > 0) ||
            role.id === 1 ||
            role.id === 2 ||
            role.id === 3
        ) {
            this.setState({
                byManager: true,
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            workTimes,
            workTimeSettings,
            groups,
            paidVacationSettings,
            absencesLoading,
            groupAbsences,
            groupPaidVacationsAllowed,
            groupMembers,
        } = this.props
        const { event, splitWorkTimes, splitAbsences } = this.state

        const { userLink } = this.state

        if (workTimeSettings !== prevProps.workTimeSettings) {
            // Récupération des settings du groupe de l'utilisateur
            // Ou le groupe par défaut
            const userGroup =
                workTimeSettings.find(wtg =>
                    wtg.userLinks.find(ul => ul.id === userLink.id),
                ) ?? workTimeSettings.find(wtg => wtg.byDefault)

            this.setState({
                userGroup: userGroup,
            })
        }

        // Récupération de tous les congés pour tous les utilisateurs du groupe
        if (!groupAbsences && groups && absencesLoading === 0) {
            this.props.dispatch(
                absencesActions.getAllAbsencesForGroupMembers({
                    group: groups,
                }),
            )
        }

        // Récupération des congés payés autorisés pour tous les membres du groupe
        if (
            groupMembers?.length > 0 &&
            paidVacationSettings &&
            Object.keys(paidVacationSettings).length !== 0 &&
            absencesLoading === 0 &&
            !groupPaidVacationsAllowed
        ) {
            this.props.dispatch(
                absencesActions.getUserLinkPaidVacationsAllowed(
                    groupMembers,
                    paidVacationSettings,
                ),
            )
        }

        // Calcul et tri des congés payés
        if (
            groupAbsences &&
            groupPaidVacationsAllowed &&
            workTimeSettings &&
            !this.state.userPaidVacationsData
        ) {
            const data = groupAbsences.map(({ userLinkId, absences }) => {
                const userLink = groupMembers.find(l => l.id === userLinkId)

                // Récupération du groupe de temps de travail dans lequel l'utilisateur se trouve
                // Sinon le groupe par défaut
                const userWorkTimeSettings =
                    workTimeSettings.find(wtg =>
                        wtg.userLinks.find(ul => ul.id === userLinkId),
                    ) ?? workTimeSettings.find(wtg => wtg.byDefault)

                const userLinkAllowedVacations = groupPaidVacationsAllowed.find(
                    gpa => gpa.userLinkId === userLinkId,
                ).value

                // Pour tous les utilisateurs du groupe
                // On récupère toutes les absences qu'il a eu pour toutes les années OU pour l'année en cours
                // Et on calcul le total de ces absences pour ces années
                //
                // Si le cumul des congés est autorisé on process toutes les années pour connaitre le nombre de congés accumulés au fil des années
                // Sinon on utilise seulement l'année en cours
                const allUserAbsences = userLinkAllowedVacations
                    .filter(av => {
                        return (
                            paidVacationSettings?.allowPaidVacationAddition ||
                            av.year ===
                                worktimesUtils.getCurrentPeriodYear(
                                    paidVacationSettings,
                                )
                        )
                    })
                    .map((av, i) => {
                        const absencesForYear = worktimesUtils
                            .filterAbsenceForPeriod(
                                absences,
                                av.year,
                                paidVacationSettings,
                            )
                            .filter(
                                // Uniquement les event validés et de type congés payés
                                a =>
                                    a.absenceStatus.id === 2 &&
                                    a.absenceReasonType.id === 1,
                            )

                        return {
                            year: av.year,
                            absences: absencesForYear,
                            allowed:
                                av.nbAllowedVacations *
                                60 *
                                DEFAULT_NB_HOURS_PER_DAY,
                            absenceTime: absencesForYear.reduce((acc, a) => {
                                return (
                                    acc +
                                    worktimesUtils.calculateAbsenceDuration(
                                        a,
                                        userWorkTimeSettings,
                                    )
                                )
                            }, 0),
                        }
                    })
                const nbPaidVacationAllowed = worktimesUtils.getUserLinkAllowedVacations(
                    userLinkAllowedVacations,
                    paidVacationSettings,
                )

                // La colonne "Utilisés" affiche les congés payés posés et validés de la période en cours
                const used =
                    allUserAbsences.find(
                        a =>
                            a.year ===
                            worktimesUtils.getCurrentPeriodYear(
                                paidVacationSettings,
                            ),
                    )?.absenceTime ?? 0

                // La colonne "Congés payés restants" afficle les reste des congés payés de toutes les périodes
                const remainingVacations = allUserAbsences.reduce((acc, a) => {
                    return acc + (a.allowed - a.absenceTime)
                }, 0)

                return {
                    linkUserId: userLinkId,
                    user: userLink.user ?? userLink.userSubscribe,
                    nbPaidVacationAllowed,
                    used: used,
                    remainingVacations: remainingVacations,
                }
            })

            this.setState({
                userPaidVacationsData: data,
            })
        }

        // Traitement des Worktimes
        if (workTimes !== prevProps.workTimes) {
            this.setState({
                splitWorkTimes: this.splitEvents(workTimes) || [],
            })
        }

        // Récupération du dernier Manager
        if (
            splitWorkTimes !== prevState.splitWorkTimes ||
            splitAbsences !== prevState.splitAbsences
        ) {
            const data = (splitWorkTimes || [])
                .concat(splitAbsences || [])
                .sort((d1, d2) => {
                    return d1.delimitedDate.startDate <
                        d2.delimitedDate.startDate
                        ? 1
                        : -1
                })

            if (data?.[0]?.responsibleLink?.id) {
                this.setState({
                    event: {
                        ...this.state.event,
                        responsibleLink: data[0].responsibleLink,
                    },
                })
            }
        }

        // Récupération de traitement des absences de l'utilisateur sélectionné dans la modale
        // Par défaut l'utilisateur actuel
        if (
            workTimes !== prevProps.workTimes ||
            !this.state.splitWorkTimes ||
            groupAbsences !== prevProps.groupAbsences ||
            !this.state.splitAbsences ||
            event?.userLink?.id !== prevState?.event?.userLink?.id
        ) {
            let selectedUserAbsences = groupAbsences?.find(
                a => a.userLinkId === event?.userLink?.id,
            )?.absences

            if (
                selectedUserAbsences &&
                (JSON.stringify(this.state.splitAbsences) !==
                    JSON.stringify(this.splitEvents(selectedUserAbsences)) ||
                    !this.state.splitAbsences)
            ) {
                this.setState({
                    splitAbsences: this.splitEvents(selectedUserAbsences),
                })
            }
        }
    }

    _sortAndSplitData(workTimes, absences) {
        const data = (workTimes || []).concat(absences || []).sort((d1, d2) => {
            return d1.delimitedDate.startDate < d2.delimitedDate.startDate
                ? 1
                : -1
        })

        if (data?.[0]?.responsibleLink?.id) {
            this.setState({
                event: {
                    ...this.state.event,
                    responsibleLink: data[0].responsibleLink,
                },
            })
        }

        if (workTimes) {
            this.setState({
                splitWorkTimes: this.splitEvents(workTimes),
            })
        }

        if (absences) {
            this.setState({
                splitAbsences: this.splitEvents(absences),
            })
        }
    }

    /**
     * Récupère le user link de l'utilisateur connecté
     * @returns userLink de l'utilisateur connecté
     */
    getCurrentUserLink() {
        const { groupMembers, user } = this.props

        return groupMembers.find(
            gm =>
                (user?.email && gm?.user?.email === user.email) ||
                gm?.userSubscribe?.mail === user.email,
        )
    }

    findUserLinkById(id) {
        const { groupMembers } = this.props
        return groupMembers.find(gm => gm.id === id)
    }

    /**
     * Gère la mise à jour des champs Heure début et Heure fin
     * @param {*} field
     * @param {*} value
     */
    handleTimeChange(field, value) {
        const { selectedDateRanges, openedDateRangeIndex } = this.state

        const date = selectedDateRanges[openedDateRangeIndex]
        if (!date) {
            return
        }

        // Si le champ modifié est Heure de fin
        // et si l'heure de début est après l'heure de fin
        // alors ça veut dit qu'il s'agit d'un temps de travail sur deux jours
        // donc on modifie l'heure de fin pour ajouter 1 jour pour que les calculs soient corrects
        if (
            field === 'endTime' &&
            date.startTime &&
            dayjs(value).isBefore(dayjs(date.startTime))
        ) {
            selectedDateRanges[openedDateRangeIndex][field] = dayjs(value).add(
                1,
                'day',
            )

            return
        } else {
            selectedDateRanges[openedDateRangeIndex][field] = value
        }

        this.setState({
            selectedDateRanges,
        })
    }

    /**
     * Cette fonction va renvoyer un tableau de créneaux horaires disponibles
     *
     * Les absences sur plusieurs jours sont séparés en plusieurs évènements distincts
     * Et on va faire l'intersection de chacun des tableaux de min et max pour savoir
     * quelles sont les heures disponibles
     */
    getAvailableTime() {
        const {
            selectedDate: dateSelectedIndex,
            selectedDateRanges: dateRanges,
            event,
        } = this.state

        // Transforme le format des dateRanges pour pouvoir les traiter comme les autres evts
        let transformedDateRanges = dateRanges.map(d => {
            let a = {
                id: event?.id,
                delimitedDate: {
                    startDate: d.startDate,
                    endDate: d.endDate,
                },
                temp_id: d.temp_id,
            }

            if (d.startTime || d.endTime) {
                a.absenceTime = {
                    startTime: d.startTime,
                    endTime: d.endTime,
                }
            }

            return a
        })

        const editingRange = transformedDateRanges[dateSelectedIndex]
        if (!editingRange?.delimitedDate?.startDate) {
            return
        }

        let availableTimeSlots = []
        const splitCurrentWorkTime = this.splitEvents([editingRange])

        // On boucle sur tous les jours de la période sélectionnée
        // Pour récupérer les créneaux horaires disponibles
        // Et on fait l'intersection de tous les créneaux horaires disponibles
        // ex:
        // 1er jour: [2h-4h, 18h-22h]
        // 2eme jour: [3h-5h, 16h-22h]
        // Résultat: [3h-4h, 18h-22h]
        for (let evt of splitCurrentWorkTime) {
            // Récupération des créneaux horaires disponibles pour un jour
            let tempAvailableTimeSlot = this.getAvailableTimeSlotsForOneDay(evt)
            if (availableTimeSlots.length === 0) {
                availableTimeSlots = tempAvailableTimeSlot
                continue
            }

            let tempOverlappingTimeSlots = []
            for (let i = 0; i < tempAvailableTimeSlot.length; i++) {
                for (let j = 0; j < availableTimeSlots.length; j++) {
                    let firstStartTime = this.onlyTime(
                        tempAvailableTimeSlot[i].start,
                    )
                    let secondStartTime = this.onlyTime(
                        availableTimeSlots[j].start,
                    )

                    let firstEndTime = tempAvailableTimeSlot[i].end.isAfter(
                        tempAvailableTimeSlot[i].start,
                        'date',
                    )
                        ? this.onlyTime(tempAvailableTimeSlot[i].end).add(
                              1,
                              'day',
                          )
                        : this.onlyTime(tempAvailableTimeSlot[i].end)
                    let secondEndTime = availableTimeSlots[j].end.isAfter(
                        availableTimeSlots[j].start,
                        'date',
                    )
                        ? this.onlyTime(availableTimeSlots[j].end).add(1, 'day')
                        : this.onlyTime(availableTimeSlots[j].end)

                    if (
                        firstStartTime.isBefore(secondEndTime) &&
                        firstEndTime.isAfter(secondStartTime)
                    ) {
                        tempOverlappingTimeSlots.push({
                            start: this.latest([
                                firstStartTime,
                                secondStartTime,
                            ]),
                            end: this.earliest([firstEndTime, secondEndTime]),
                        })
                    }
                }
            }
            availableTimeSlots =
                tempOverlappingTimeSlots.length > 0
                    ? tempOverlappingTimeSlots
                    : null

            if (availableTimeSlots === null) {
                break
            }
        }

        return availableTimeSlots
    }

    getAvailableTimeSlotsForOneDay(event) {
        const {
            selectedDateRanges: dateRanges,
            splitWorkTimes,
            splitAbsences,
        } = this.state

        if (!event?.delimitedDate?.startDate) {
            return
        }
        // On sépare toutes les absences non sauvegardées en plusieurs absences distinctes
        // pour faciliter le traitement.
        // Et on filtre pour ne pas prendre en compte l'absence en cours d'édition
        const splitDateRanges = this.splitEvents(dateRanges).filter(dr => {
            return !(
                dayjs(dr.delimitedDate.startDate).isSame(
                    event.delimitedDate.startDate,
                ) &&
                event?.startTime === dr.startTime &&
                event?.endTime === dr.endTime
            )
        })

        // 1) Récupération des évènements qui chevauchent l'absence en cours d'édition
        const overlappingEvents = splitDateRanges
            .concat(splitWorkTimes)
            .concat(splitAbsences)

            // Récupération de tous les events qui chevauchent l'absence en cours d'édition
            // - Commencé la veille mais se termine aujourd'hui
            // - Commence aujourd'hui
            .filter(e => {
                if (!e) {
                    return false
                }
                // On filtre l'absence en cours d'édition pour ne pas créer de conflit
                if (event.id && e.id === event.id) {
                    return false
                }

                // Pas de contrainte avec les absences sur toute la journée
                if (e?.absenceStatus && !e?.absenceTime) {
                    return false
                }

                if (e.startTime === null) {
                    return false
                }

                if (
                    // Event de la veille
                    (dayjs(e.dateTimeStart).isBefore(
                        event.delimitedDate.startDate,
                        'date',
                    ) &&
                        dayjs(e.dateTimeEnd).isAfter(
                            event.delimitedDate.startDate,
                        )) ||
                    //OU
                    // Events du jours
                    dayjs(e.dateTimeStart).isSame(
                        event.delimitedDate.startDate,
                        'date',
                    )
                ) {
                    return true
                }
            })
            .sort((e1, e2) => {
                // Trier les évènements par date de début croissante
                return dayjs(e1.delimitedDate.startDate).isBefore(
                    e2.delimitedDate.startDate,
                )
                    ? -1
                    : 1
            })

        let availableTimeSlots = []

        // Si il n'y a pas d'evt qui chevauchent l'absence en cours d'édition
        // Aucune restriction
        // Utilisation du temp_id pour ne pas prendre les autres évènements de la même daterange
        if (
            overlappingEvents.length === 0 ||
            overlappingEvents.every(o => o.temp_id === event.temp_id)
        ) {
            availableTimeSlots.push({
                start: event.delimitedDate.startDate,
                end: event.delimitedDate.endDate.add(1, 'day'),
            })
            return availableTimeSlots
        }
        // Gestion du cas où il y'a le temps de placer une absence entre le début de la journée
        //  et le début du premier event
        const firstEventStart = overlappingEvents[0].dateTimeStart
        if (
            firstEventStart.isAfter(
                event.delimitedDate.startDate.startOf('day'),
            )
        ) {
            availableTimeSlots.push({
                start: event.delimitedDate.startDate.startOf('day'),
                end: firstEventStart,
            })
        }

        // 2) Pour chaque évènement qui chevauche l'absence en cours d'édition
        // On vérifie si il y'a un créneau disponible entre la fin de l'event et le début du prochain event
        for (let i = 0; i < overlappingEvents.length; i++) {
            // Si l'écart entre la fin de l'event
            // et le début du prochain event est supérieur au temps de repos minimum * 2
            // Alors on peut ajouter un créneau disponible
            let currentOverlappingEvent = overlappingEvents[i]

            if (currentOverlappingEvent.temp_id === event.temp_id) {
                continue
            }

            let currentEnd = currentOverlappingEvent.dateTimeEnd

            let nextOverlappingEvent = overlappingEvents[i + 1]
                ? overlappingEvents[i + 1]
                : null

            if (nextOverlappingEvent && currentEnd) {
                const nextStartMaxTime = nextOverlappingEvent.dateTimeStart

                if (nextStartMaxTime.diff(currentEnd, 'hour') >= 0) {
                    let slotStart = currentEnd.isBefore(
                        event.delimitedDate.startDate.startOf('day'),
                        'date',
                    )
                        ? event.delimitedDate.startDate.startOf('day')
                        : currentEnd

                    if (!slotStart.isSame(nextStartMaxTime, 'minute')) {
                        availableTimeSlots.push({
                            start: slotStart,
                            end: nextStartMaxTime,
                        })
                    }
                }
            }
        }

        // Gestion du cas où il y'a le temps de placer un event entre
        // la fin du dernier event et la fin de la journée
        // ex: WT: 06h-12h, Temps de repos min: 10h
        // créneau dispo: 22h-00h
        const lastEvent = overlappingEvents[overlappingEvents.length - 1]

        if (lastEvent) {
            let nextEnd = lastEvent.dateTimeEnd

            if (
                lastEvent.dateTimeEnd &&
                lastEvent.dateTimeEnd.isBefore(event.delimitedDate.endDate) &&
                nextEnd
            ) {
                // Si les deux heures sont différentes alors on ajoute un slot
                // Car on ne veut pas avoir un slot qui commence à 20h et se termine à 20h
                if (!nextEnd.isSame(event.delimitedDate.endDate, 'minute')) {
                    availableTimeSlots.push({
                        start: nextEnd,
                        end: event.delimitedDate.endDate,
                    })
                }
            }
        }

        return availableTimeSlots
    }

    /**
     * Retourne le dateTime de l'evt suivant
     */
    getMaxTime(a) {
        const {
            selectedDateRanges: dateRanges,
            splitWorkTimes,
            splitAbsences,
            event,
        } = this.state

        if (!a?.startDate) {
            return
        }

        let absence = {
            delimitedDate: {
                startDate: a.startDate,
                endDate: a.endDate,
            },
            dateTimeStart: dayjs(a.startDate),
            dateTimeEnd: dayjs(a.endDate),
            temp_id: a.temp_id,
        }

        if (absence?.startTime) {
            absence.dateTimeStart = absence.dateTimeStart
                .hour(absence?.startTime.hour())
                .minute(absence?.startTime.minute())
        }
        if (absence?.endTime) {
            absence.dateTimeEnd = absence.dateTimeEnd
                .hour(absence.endTime.hour())
                .minute(absence.endTime.minute())
        }

        // Sépare les dateRanges en plusieurs évènements
        // pour faciliter le traitement
        // Filtre le dateRanges en cours d'édition pour ne pas créer de conflit
        const splitDateRanges = this.splitEvents(dateRanges).filter(dr => {
            return dr.temp_id !== absence.temp_id
        })
        // Regroupe et tri tous les évènements
        // absences, worktimes et worktimes non enregistrés
        const allNextEvents = splitDateRanges
            .concat(splitWorkTimes)
            .concat(splitAbsences)
            .filter(e => {
                if (!e) return false
                if (event?.id && e.id === event?.id) {
                    return false
                }
                // Récupérer uniquement les évènements qui sont avant ou pendant le worktime en cours d'édition
                return dayjs(e.dateTimeStart).isSameOrAfter(
                    absence.dateTimeStart,
                )
            })
            .sort((wt1, wt2) => {
                // Trier par date de début décroiassante
                return dayjs(wt1.dateTimeStart).isBefore(
                    dayjs(wt2.dateTimeStart),
                )
                    ? -1
                    : 1
            })

        // Il y'a deux différentes heures qui peuvent être la plus tardive
        // 1- L'heure de fin maximum en fonction du temps de travail maximum
        // 2- L'heure de fin maximum en fonction de l'évènement suivant (worktime ou absence) et du temps de pause minimum

        // 1- Calcul de l'heure de fin maximum en fonction du temps de travail maximum
        // Si l'heure de début est définie
        let maxTimes = {
            maxTimeFromNextEvt: null,
            canBeOvernight: false,
        }

        // 2- Récupération de l'évènement suivant (worktime, absence ou worktime non enregistré)
        // const nextEvent = this.getNextEvent(editingWorkTime)
        const nextEvent = allNextEvents[0]
        // Vérifier si le prochain évènement est le jour même ou le lendemain
        if (
            nextEvent &&
            nextEvent.dateTimeStart.diff(absence.dateTimeStart, 'day') <= 1
        ) {
            maxTimes.maxTimeFromNextEvt = nextEvent.dateTimeStart

            if (
                dayjs(maxTimes.maxTimeFromNextEvt).isAfter(
                    dayjs(absence.dateTimeStart),
                    'date',
                )
            ) {
                maxTimes.canBeOvernight = true
            }
        }

        return maxTimes
    }

    _renderCommentField() {
        const { classes } = this.props
        const { event, disabled } = this.state

        return (
            <TextField
                id="details"
                variant="outlined"
                label={
                    <>
                        {this.displayText('comment')}
                        <Tooltip
                            title={this.displayText(`commentTooltip`)}
                            classes={{
                                tooltip: classes.tooltip,
                            }}
                        >
                            <HelpIcon className={classes.helpIcon} />
                        </Tooltip>
                    </>
                }
                margin={`normal`}
                multiline
                minRows={5}
                maxRows={5}
                value={event.comment || ''}
                onChange={e => {
                    this.setState({
                        event: {
                            ...event,
                            comment: e.target.value,
                        },
                    })
                }}
                className={classes.textField}
                InputProps={{
                    classes: {
                        input: classes.input,
                        marginDense: classes.marginInput,
                    },
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.labelInput,
                    },
                    style: { pointerEvents: 'auto' },
                }}
                disabled={disabled}
            />
        )
    }

    renderTags(value) {
        const { event } = this.state

        return value.map((option, index) => {
            if (!option) {
                return <></>
            }

            let label = ''
            if (dayjs(option.startDate).isSame(dayjs(option.endDate), 'day')) {
                label = dayjs(option.startDate).format('DD/MM/YYYY')
            } else {
                label =
                    dayjs(option.startDate).format('DD/MM') +
                    ' - ' +
                    dayjs(option.endDate).format('DD/MM/YYYY')
            }

            return (
                <Chip
                    size="medium"
                    variant="outlined"
                    clickable={!event.id}
                    // En mode édition on ne peut pas modifier la date
                    onClick={
                        !event.id
                            ? () => {
                                  this.selectDateRange(index)
                                  this.setState({
                                      isOpenCalendar: true,
                                  })
                              }
                            : undefined
                    }
                    key={index}
                    // En mode édition on ne peut pas modifier la date
                    onDelete={
                        !event.id
                            ? () => {
                                  // Supprime la range cliquée
                                  const _selectedDateRanges = this.state
                                      .selectedDateRanges
                                  _selectedDateRanges.splice(index, 1)
                                  const absenceTime = this._calculateTotalTimeOfAbsence(
                                      _selectedDateRanges,
                                  )
                                  this.setState({
                                      selectedDateRanges: _selectedDateRanges,
                                      nbOfMinutesAbsent: absenceTime.nbMinutes,
                                      nbOfDaysAbsent: absenceTime.nbDays,
                                  })
                              }
                            : undefined
                    }
                    style={{
                        backgroundColor: option.userPart // TODO: changer la condition quand il y aura le lien avec detail
                            ? '#c4c4c4'
                            : '#fcd48e',
                    }}
                    label={label}

                    // {...getTagProps({
                    //     index,
                    // })}
                />
            )
        })
    }

    clickDocument() {
        const { disabled } = this.state

        if (!disabled) this.refs.fileUploader.click()
    }

    addDocument(event) {
        if (
            event?.target?.files &&
            [...event.target.files].find(file =>
                file.name.toLowerCase().includes('.heic'),
            )
        ) {
            // So we display a loading icon during the time of heic conversion
            this.setState({ loadingImage: true }, async () => {
                await addFileToBobEvent(this, event, {
                    fileNoUrl: this.state.fileNoUrl.event,
                    displayNewFiles: this.state.displayNewFiles.event,
                })
                this.setState({ loadingImage: false })
            })
        } else {
            addFileToBobEvent(this, event, {
                fileNoUrl: this.state.fileNoUrl.event,
                displayNewFiles: this.state.displayNewFiles.event,
            })
        }
    }

    deleteDocumentNoUp(i) {
        deleteFileFromBobEvent(this, i, {
            deletedFiles: this.state.deletedFiles.event,
            displayNewFiles: this.state.displayNewFiles.event,
            fileNoUrl: this.state.fileNoUrl.event,
            files: this.state.event.files,
        })
    }

    _renderDocuments() {
        const { classes } = this.props
        const { event, displayNewFiles, disabled } = this.state

        return (
            <>
                <Typography variant={`body1`}>
                    {this.displayText(`documents`)}
                </Typography>
                <div
                    className={[
                        classes.addDoc,
                        disabled ? classes.disabled : undefined,
                    ].join(' ')}
                    onClick={this.clickDocument}
                >
                    <AddAPhotoIcon className={classes.doc} />
                </div>
                <input
                    onChange={e => {
                        this.addDocument(e)
                    }}
                    multiple
                    type="file"
                    id="fileLast"
                    ref="fileUploader"
                    style={{ display: 'none' }}
                    disabled={disabled}
                />
                <DisplayerImgModal
                    file={getImageToDisplay(event, displayNewFiles.event)}
                    deleteDocumentNoUp={this.deleteDocumentNoUp}
                    loadingImage={this.state.loadingImage}
                />
            </>
        )
    }

    // Sélectionne la date cliquée et permet de modifier ses heures d'absence
    selectDateRange(index) {
        this.setState({
            selectedDate: index,
        })
    }

    onUserChange(value) {
        const { event } = this.state
        const { groupMembers } = this.props

        const tempArray = []
        // SI c'est un nouvel utilisateur ajouté par la modale
        if (value?.emailAddUser) {
            tempArray.push(
                groupMembers.find(
                    u => u?.userSubscribe?.mail === value.emailAddUser,
                ),
            )
        } else {
            value.forEach(v => {
                if (
                    v?.user?.id ||
                    (v?.id && !v?.user && !v?.userSubscribe) ||
                    v?.userSubscribe
                )
                    tempArray.push(v)
            })
        }
        const selectedUser = tempArray[tempArray.length - 1]

        this.setState({
            event: {
                ...event,
                userLink: selectedUser,
            },
        })

        // // Récupération des absences de l'utilisateur sélectionné
        // // Afin de pouvoir déterminer quels jours seront désactivés
        if (selectedUser?.id) {
        }
    }

    onManagerChange(value) {
        const { event } = this.state
        const { groupMembers } = this.props

        const tempArray = []
        // SI c'est un nouvel utilisateur ajouté par la modale
        if (value?.emailAddUser) {
            tempArray.push(
                groupMembers.find(
                    u => u?.userSubscribe?.mail === value.emailAddUser,
                ),
            )
        } else {
            value.forEach(v => {
                if (
                    v?.user?.id ||
                    (v?.id && !v?.user && !v?.userSubscribe) ||
                    v?.userSubscribe
                )
                    tempArray.push(v)
            })
        }
        const selectedUser = tempArray[tempArray.length - 1]

        this.setState({
            event: {
                ...event,
                responsibleLink: selectedUser,
            },
        })
    }

    _renderUserSelector() {
        const { event, errorFields, disabled } = this.state

        return (
            <UserSelector
                onChange={this.onUserChange}
                label={this.displayText('user')}
                errorMessageMandatory={this.displayText('errorMandatoryUser')}
                value={[event.userLink]}
                error={
                    !event.userLink &&
                    errorFields.find(error => error === 'userLink')
                        ? 'error'
                        : undefined
                }
                tooltip={this.displayText('userTooltip')}
                isUserRtq={false}
                disabled={event?.id}
            />
        )
    }

    _renderManagerSelector() {
        const { errorFields, event, disabled } = this.state

        return (
            <UserSelector
                onChange={this.onManagerChange}
                label={this.displayText('manager')}
                errorMessageMandatory={this.displayText(
                    'errorMandatoryManager',
                )}
                value={[event.responsibleLink]}
                error={
                    errorFields.find(error => error === 'responsibleLink') &&
                    !event.responsibleLink
                        ? 'error'
                        : undefined
                }
                disabled={disabled}
                tooltip={this.displayText('managerTooltip')}
                isUserRtq={true}
            />
        )
    }

    _renderTypeAbsence() {
        const { event, errorFields, disabled } = this.state
        const { leaveTypes, classes } = this.props

        return (
            <TextField
                id="type"
                variant="outlined"
                label={this.displayText('typeAbsence')}
                margin={`none`}
                required
                error={
                    errorFields.find(error => error === 'absenceReasonType') &&
                    !event?.absenceReasonType?.id
                }
                helperText={
                    errorFields.find(error => error === 'absenceReasonType') &&
                    !event?.absenceReasonType?.id
                        ? this.displayText('mandatoryType')
                        : undefined
                }
                select
                value={event?.absenceReasonType?.id}
                onChange={e => {
                    // this.handleChange({ eventTypeId: e.target.value })
                    const absenceType = leaveTypes.find(
                        l => l.id === e.target.value,
                    )
                    this.setState({
                        event: {
                            ...this.state.event,
                            absenceReasonType: absenceType,
                        },
                    })
                }}
                className={classes.textField}
                InputLabelProps={{
                    classes: {
                        root: classes.labelInput,
                    },
                }}
                disabled={disabled}
            >
                {leaveTypes
                    .filter(t => t.id !== 2) //Supprime le type "Jour Travaillé"
                    .map(type => {
                        return (
                            <MenuItem key={type.id} value={type.id}>
                                {this.displayTextApi(type.translation)}
                            </MenuItem>
                        )
                    })}
            </TextField>
        )
    }

    _getNbMinutesBetweenTimes(startTime, endTime) {
        const start = dayjs()
            .hour(startTime.split(':')[0])
            .minute(startTime.split(':')[1])
        const end = dayjs()
            .hour(endTime.split(':')[0])
            .minute(endTime.split(':')[1])
        return end.diff(start, 'minutes')
    }

    _calculateTotalTimeOfAbsence(dateRanges) {
        const { userGroup } = this.state

        let nbMinutes = 0
        let nbDaysTotal = 0

        if (dateRanges.length === 0 || !userGroup) return nbMinutes

        for (const dates of dateRanges) {
            const nbDays = dayjs(dates.endDate).diff(dates.startDate, 'day') + 1
            nbDaysTotal += nbDays
            for (let i = 0; i < nbDays; i++) {
                const date = new Date(dates.startDate)
                date.setDate(date.getDate() + i)
                const dayName = date
                    .toLocaleDateString('en-US', {
                        weekday: 'long',
                    })
                    .toLowerCase()
                const daySettings = userGroup[`${dayName}Setting`]

                if (daySettings !== null) {
                    nbMinutes += this._getNbMinutesBetweenTimes(
                        daySettings.startTime,
                        daySettings.endTime,
                    )

                    if (daySettings?.breakTimes?.length > 0) {
                        for (const breakTime of daySettings.breakTimes) {
                            nbMinutes -= this._getNbMinutesBetweenTimes(
                                breakTime.startTime,
                                breakTime.endTime,
                            )
                        }
                    }
                } else {
                    // if (dayName === 'saturday' || dayName === 'sunday')
                    //     nbMinutes += 7 * 60
                }
            }
        }
        return {
            nbDays: nbDaysTotal,
            nbMinutes,
        }
    }

    onDateRangeChange(selectedStartDate, selectedEndDate) {
        const { selectedDateRanges, selectedDate } = this.state

        let indexEdit =
            selectedDate !== null ? selectedDate : selectedDateRanges.length - 1

        let absenceTime = null

        if (selectedDateRanges[indexEdit] === undefined) {
            const newRange = {
                startDate: selectedStartDate,
                endDate: selectedEndDate,
                // Génération d'un id temporaire pour pouvoir identifier et filtrer plus facilement
                temp_id: Math.random()
                    .toString(36)
                    .substr(2, 9),
            }

            absenceTime = this._calculateTotalTimeOfAbsence([
                ...selectedDateRanges,
                newRange,
            ])

            this.setState({
                selectedDateRanges: [...selectedDateRanges, newRange],
                selectedDate: selectedDateRanges.length,
                isOpenCalendar: false,
                nbOfMinutesAbsent: absenceTime.nbMinutes,
            })
            return
        }

        selectedDateRanges[indexEdit].startDate = selectedStartDate
        selectedDateRanges[indexEdit].endDate = selectedEndDate

        absenceTime = this._calculateTotalTimeOfAbsence(selectedDateRanges)

        this.setState({
            selectedDateRanges,
            selectedDate: indexEdit,
            isOpenCalendar: false,
            nbOfMinutesAbsent: absenceTime.nbMinutes,
        })
    }

    getDisabledDates() {
        const {
            selectedDate: dateSelectedIndex,
            selectedDateRanges: dateRanges,
            splitWorkTimes,
            splitAbsences,
        } = this.state
        const disabledDates = []

        // Pour chaque évènement (worktime ou absence) on va vérifier:
        //
        // 1 - Si l'évènement est une absence sur toute la journée
        //     Alors la journée est désactivée sans vérifier les autres évènements
        //
        // 2 - Vérifier entre le début de l'évènement et la fin de l'évènement précédent,
        //     est ce qu'il y'a au moins deux fois le temps de repos minimum + 1h
        //     ET que il y'a le temps de repos minimum + 1 heure entre minuit et le début de l'evt
        //     SAUF si l'evt précédent est une absence, auquel cas pas de temps de pause mini entre absence et worktime
        //     Ca va nous permettre de déterminer si il y'a le temps d'avoir un évènement et le temps de repos minimum avant cet évènement
        //
        //     ex: précédent evt: 14h-22h, evt: 14h-22H
        //         -> Il y'a le temps aujourd'hui avant 14h pour placer un evt
        //            Mais il n'y a pas le temps entre la veille 22h de placer 1 temps de repos, 1 evt (min 1h) et 1 autre temps de repos
        //
        //
        // 3 - Vérifier entre la fin de l'évènement et le début de l'évènement suivant,
        //     est ce qu'il y'a au moins deux fois le temps de repos minimum + 1h (?)
        //     SAUF si l'evt suivant est une absence, auquel cas pas de temps de pause mini entre absence et worktime
        //     Ca va nous permettre de déterminer si il y'a le temps d'avoir un évènement et le temps de repos minimum après cet évènement et avant le suivant
        //

        // Sépare les dateRanges en plusieurs évènements
        // pour faciliter le traitement
        const splitDateRanges = this.splitEvents(
            dateRanges.map(d => {
                let a = {
                    delimitedDate: {
                        startDate: d.startDate,
                        endDate: d.endDate,
                    },
                    temp_id: d.temp_id,
                }

                if (d.startTime || d.endTime) {
                    a.absenceTime = {
                        startTime: d.startTime,
                        endTime: d.endTime,
                    }
                }

                return a
            }),
        )

        // Regroupe et tri tous les évènements
        // absences, worktimes et worktimes non enregistrés
        const allEvents = splitDateRanges
            .concat(splitWorkTimes || [])
            .concat(
                splitAbsences
                    ? splitAbsences.filter(
                          a =>
                              a.absenceStatus.id === 2 ||
                              a.absenceStatus.id === 1,
                      )
                    : [],
            ) //Les absences en attente et validées bloquent les jours
            .sort((wt1, wt2) => {
                return dayjs(wt1.dateTimeStart).isBefore(
                    dayjs(wt2.dateTimeStart),
                )
                    ? -1
                    : 1
            })

        allEvents.forEach((evt, index) => {
            const previousEvent = allEvents[index - 1]
            const nextEvent = allEvents[index + 1]

            // Le temps minimum entre deux évènement nécessaire pour pouvoir en placer un autre
            const minimumTimeGapRequired = 1

            let hasTimeBefore = false
            let hasTimeAfter = false
            if (evt?.absenceStatus && !evt?.absenceTime) {
                hasTimeBefore = false
                hasTimeAfter = false
            } else {
                //
                // 1 - Vérification avant
                //
                if (evt?.dateTimeStart) {
                    if (!previousEvent || previousEvent?.absenceStatus) {
                        hasTimeBefore =
                            evt.dateTimeStart.hour() >= minimumTimeGapRequired
                    } else {
                        if (
                            evt.dateTimeStart.hour() >=
                                minimumTimeGapRequired &&
                            evt.dateTimeStart.diff(
                                previousEvent.dateTimeEnd,
                                'hour',
                            ) >= minimumTimeGapRequired
                        ) {
                            hasTimeBefore = true
                        }
                    }
                }

                //
                // 2 - Vérification après
                //
                if (evt?.dateTimeEnd) {
                    if (!nextEvent || !nextEvent?.dateTimeStart) {
                        hasTimeAfter =
                            24 - evt.dateTimeEnd.hour() >=
                            minimumTimeGapRequired
                    } else {
                        if (
                            24 - evt.dateTimeEnd.hour() >=
                                minimumTimeGapRequired &&
                            nextEvent.dateTimeStart.diff(
                                evt.dateTimeEnd,
                                'hour',
                            ) >= minimumTimeGapRequired
                        ) {
                            hasTimeAfter = true
                        }
                    }
                }
            }

            if (!hasTimeBefore && !hasTimeAfter) {
                disabledDates.push(evt.delimitedDate.startDate.toDate())
            }
        })

        return disabledDates
    }

    /**
     * Renvoi le nombre de vacances autorisés en minutes
     * Pour l'utilisateur sélectionné ou l'utilisateur actuel si le champ n'est pas présent
     */
    getRemainingPaidVacationsForSelectedUser() {
        const { paidVacationSettings, paidVacationsGroup } = this.props

        const { event } = this.state
        // Vérifie si la nouvelle valeur n'est pas inférieure au nombre de congés déjà utilisés

        if (!paidVacationsGroup || !paidVacationSettings || !event?.userLink)
            return null

        const userLink = this.findUserLinkById(event.userLink?.id)

        if (!userLink) return null

        const nbPaidVacationAllowed =
            userLink.nbPaidVacationAllowed ??
            paidVacationSettings?.nbPaidVacationAllowed ??
            this.nbPaidVacationAllowedDefault

        const nbDaysOffPerMonth = nbPaidVacationAllowed / 12

        // On défini la date de début de la période de calcul
        const startDate = dayjs()
            .month(paidVacationSettings.monthStartRefPeriod)
            .date(paidVacationSettings.dayStartRefPeriod)

        //Nombre de jours accumulés depuis le début de la période de calcul
        const nbDaysSinceStart =
            Math.abs(dayjs().diff(startDate, 'month')) * nbDaysOffPerMonth

        const nbMinutesSinceStart =
            nbDaysSinceStart * DEFAULT_NB_HOURS_PER_DAY * 60

        const paidVacationsUsed =
            paidVacationsGroup.find(u => u.userLinkId === event.userLink?.id)
                ?.paidVacationsUser ?? 0

        // Calcul du nombre de minutes disponibles
        // En soustrayant les minutes prises des minutes autorisées
        let nbMinutes = nbMinutesSinceStart - paidVacationsUsed

        return Math.floor(nbMinutes)
    }

    /**
     * Désactive le jour du calendrier si l'utilisateur n'a pas de réglages de temps de travail pour ce jour là
     * Permet d'empêcher l'utilisateur de poser une absence un jour où il ne travaille pas
     *
     * On utilise "getDisabledDays" pour ce calcul plutôt que "getDisabledDates"
     * Car "getDisabledDates" nécessite de créer un tableau avec la liste de tous les jours désactivés
     * Alors que "getDisabledDays" fait cette vérification uniquement sur les jours visibles dans la vue du calendrier
     *
     * @param {*} date Date à tester
     * @returns true si la date est désactivée, sinon false
     */
    getDisabledDays(date) {
        const { userGroup } = this.state
        let d = dayjs(date)
        if (
            userGroup &&
            userGroup[`${this.daysOfWeek[d.day()]}Setting`] === null
        ) {
            return true
        }
        return false
    }

    _renderDateSelector() {
        const { classes } = this.props
        const {
            event,
            isOpenCalendar,
            selectedDateRanges,
            selectedDate,
            errorFields,
            byManager,
        } = this.state
        const disabledDates = this.getDisabledDates()

        return (
            <>
                {/* Date selector */}
                <Autocomplete
                    multiple
                    id="multiple-limit-tags"
                    options={[]}
                    value={selectedDateRanges}
                    renderTags={this.renderTags}
                    open={false}
                    disabled={event?.id || (byManager && !event.userLink)} //désactivé en mode édition OU si la demande est faite par un manager et que le champ Utilisateur est vide
                    renderInput={params => (
                        <TextField
                            {...params}
                            className={classes.textField}
                            margin={`none`}
                            variant="outlined"
                            label={this.displayText('selectDays')}
                            InputLabelProps={{
                                style: { pointerEvents: 'auto' },
                            }}
                            error={
                                errorFields.find(
                                    error => error === 'selectedDateRanges',
                                ) && selectedDateRanges.length === 0
                            }
                            helperText={
                                errorFields.find(
                                    error => error === 'selectedDateRanges',
                                ) && selectedDateRanges.length === 0
                                    ? this.displayText('mandatoryDate')
                                    : undefined
                            }
                            disabled={true}
                            onClick={() => {
                                if (event?.id || (byManager && !event.userLink))
                                    return
                                this.setState({
                                    isOpenCalendar: true,
                                    selectedDate: selectedDateRanges.length,
                                })
                            }}
                        />
                    )}
                    sx={{ width: '500px' }}
                    ref={this.dateInputRef}
                />
                <Popover
                    open={isOpenCalendar}
                    onClose={() => {
                        this.setState({
                            isOpenCalendar: false,
                        })
                    }}
                    anchorReference={this.dateInputRef}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <BoatOnDateSelector
                        isDateRange
                        onDateChange={(start, end) => {
                            this.onDateRangeChange(start, end)
                        }}
                        maxDate={new Date()}
                        disabledDates={disabledDates}
                        disabledDay={this.getDisabledDays}
                        startDate={
                            selectedDate !== null &&
                            selectedDateRanges[selectedDate] !== undefined
                                ? selectedDateRanges[selectedDate].startDate
                                : undefined
                        }
                        endDate={
                            selectedDate !== null &&
                            selectedDateRanges[selectedDate] !== undefined
                                ? selectedDateRanges[selectedDate].endDate
                                : undefined
                        }
                    />
                </Popover>
            </>
        )
    }

    _renderDateRanges() {
        const { classes } = this.props
        const { selectedDateRanges, selectedDate } = this.state

        if (selectedDateRanges.length > 1) {
            return (
                <div className={classes.col}>
                    {selectedDateRanges.map(
                        ({ startDate, endDate, startTime, endTime }, index) => {
                            let dateString
                            if (dayjs(startDate).isSame(endDate)) {
                                // 12/03/2023
                                dateString = dayjs(startDate).format(
                                    'DD/MM/YYYY',
                                )
                            } else {
                                // 12/03 - 14/03/2023
                                dateString =
                                    dayjs(startDate).format('DD/MM') +
                                    ' - ' +
                                    dayjs(endDate).format('DD/MM/YYYY')
                            }
                            return (
                                <>
                                    <div
                                        key={index}
                                        className={[
                                            classes.hourRow,
                                            index === selectedDate
                                                ? classes.selected
                                                : undefined,
                                        ].join(' ')}
                                        onClick={() =>
                                            this.selectDateRange(index)
                                        }
                                    >
                                        <span style={{ flex: 1 }}>
                                            {dateString}
                                        </span>
                                        <span style={{ flex: 2 }}>
                                            {startTime?.format('HH:mm')} -{' '}
                                            {endTime?.format('HH:mm')}
                                        </span>
                                        <IconButton
                                            size={`small`}
                                            onClick={undefined}
                                            children={<EditIcon />}
                                            style={{}}
                                        />
                                    </div>
                                    <Divider />
                                </>
                            )
                        },
                    )}
                </div>
            )
        }
    }

    _renderHoursAndMinutes() {
        const { classes } = this.props
        const {
            errorFields,
            nbOfMinutesAbsent,
            disabled,
            selectedDateRanges,
        } = this.state

        const absenceTime = this._calculateTotalTimeOfAbsence(
            selectedDateRanges,
        )

        return (
            <div className={classes.hoursAndMinutes}>
                <BoatOnNumberField
                    isDecimal
                    fullWidth
                    required
                    error={
                        errorFields.find(
                            error => error === 'nbOfMinutesAbsent',
                        ) && !nbOfMinutesAbsent
                    }
                    helperText={
                        errorFields.find(
                            error => error === 'nbOfMinutesAbsent',
                        ) && !nbOfMinutesAbsent
                            ? this.displayText('mandatoryHours')
                            : undefined
                    }
                    type="number"
                    onKeyDown={e => {
                        if (
                            e.key === 'e' ||
                            e.key === 'E' ||
                            e.key === '-' ||
                            e.key === '+' ||
                            e.key === '.' ||
                            e.key === ','
                        ) {
                            e.preventDefault()
                        }
                    }}
                    id="details"
                    variant="outlined"
                    label={this.displayText('totalHours')}
                    margin={`normal`}
                    onChange={e => this._setNbMinutesAbsentFromHours(e)}
                    className={classes.textField}
                    value={this._getNbHoursFromNbMinutes()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={disabled}
                    max={absenceTime.nbMinutes / 60}
                    min={0}
                />
                <BoatOnNumberField
                    isDecimal
                    fullWidth
                    type="number"
                    onKeyDown={e => {
                        if (
                            e.key === 'e' ||
                            e.key === 'E' ||
                            e.key === '-' ||
                            e.key === '+' ||
                            e.key === '.' ||
                            e.key === ','
                        ) {
                            e.preventDefault()
                        }
                    }}
                    id="details"
                    variant="outlined"
                    label={this.displayText('numberOfMinutes')}
                    margin={`normal`}
                    onChange={e => this._setNbMinutesAbsentFromMinutes(e)}
                    className={classes.textField}
                    value={this._getNbMinutesFromNbMinutes()}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    disabled={disabled}
                    max={59}
                    min={0}
                />
            </div>
        )
    }

    _getLabelStringDates(startDate, endDate) {
        const url = window.location.pathname
        const context = getContextFromUrl(url)
        const stringStartDay = new Date(startDate).toLocaleDateString(
            context === 'fr' ? 'fr-FR' : 'en-US',
            {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            },
        )

        if (dayjs(startDate).isSame(dayjs(endDate), 'day')) {
            return (
                stringStartDay.charAt(0).toUpperCase() + stringStartDay.slice(1)
            )
        }

        const stringEndDay = new Date(endDate).toLocaleDateString(
            context === 'fr' ? 'fr-FR' : 'en-US',
            {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            },
        )

        return (
            stringStartDay.charAt(0).toUpperCase() +
            stringStartDay.slice(1) +
            ' - ' +
            stringEndDay.charAt(0).toUpperCase() +
            stringEndDay.slice(1)
        )
    }

    _renderTimePickers(range) {
        const { classes } = this.props
        const { errorFields, disabled } = this.state

        const availableTimeSlots = this.getAvailableTime()
        let maxTimeConstraints
        if (range?.startTime) {
            // Get max time
            // On calcul le temps maximum en plus des emplacements disponibles
            // Car la date de début ne doit pas être influencée par la date de fin
            maxTimeConstraints = this.getMaxTime(range)
        }

        return (
            <>
                <Typography style={{ marginBottom: 10 }}>
                    {this._getLabelStringDates(range.startDate, range.endDate)}
                </Typography>
                <div
                    className={classes.row}
                    style={{
                        gap: 10,
                        marginBottom: 15,
                    }}
                >
                    <div className={classes.col}>
                        <MobileTimePicker
                            label={this.displayText('startTime')}
                            ampm={false}
                            value={range?.startTime ?? null}
                            onChange={newValue => {
                                this.handleTimeChange('startTime', newValue)
                            }}
                            slotProps={{
                                textField: {
                                    className: classes.mobileTimePicker,
                                    error:
                                        errorFields.find(
                                            error => error === 'startTime',
                                        ) && !range?.startTime,
                                    helperText:
                                        errorFields.find(
                                            error => error === 'startTime',
                                        ) && !range?.startTime
                                            ? this.displayText('mandatoryHours')
                                            : undefined,
                                },
                            }}
                            shouldDisableTime={time => {
                                if (!availableTimeSlots) return

                                const timeToCheck = this.onlyTime(dayjs(time))

                                let isAvailable = false

                                for (const timeSlot of availableTimeSlots) {
                                    const start = this.onlyTime(timeSlot.start)
                                    let end = this.onlyTime(timeSlot.end)
                                    if (
                                        end.isBefore(start) ||
                                        end.isSame(start, 'date')
                                    )
                                        end = end.add(1, 'day')

                                    if (timeToCheck.isBetween(start, end)) {
                                        isAvailable = true
                                        break
                                    }
                                }

                                return !isAvailable
                            }}
                            disabled={disabled}
                        />
                    </div>
                    <div className={classes.col}>
                        <MobileTimePicker
                            label={this.displayText('endTime')}
                            ampm={false}
                            value={range?.endTime ?? null}
                            onChange={newValue => {
                                this.handleTimeChange('endTime', newValue)
                            }}
                            slotProps={{
                                textField: {
                                    className: classes.mobileTimePicker,
                                    error:
                                        errorFields.find(
                                            error => error === 'endTime',
                                        ) && !range?.endTime,
                                    helperText:
                                        errorFields.find(
                                            error => error === 'endTime',
                                        ) && !range?.endTime
                                            ? this.displayText('mandatoryHours')
                                            : undefined,
                                },
                            }}
                            shouldDisableTime={time => {
                                if (!availableTimeSlots || !maxTimeConstraints)
                                    return

                                const timeToCheck = this.onlyTime(dayjs(time))

                                const start = this.onlyTime(range?.startTime)

                                let end = maxTimeConstraints.maxTimeFromNextEvt
                                    ? this.onlyTime(
                                          maxTimeConstraints.maxTimeFromNextEvt,
                                      )
                                    : this.onlyTime(dayjs())

                                if (end.isBefore(start)) end = end.add(1, 'day')

                                if (timeToCheck.isAfter(end)) return true
                            }}
                        />
                    </div>
                </div>
            </>
        )
    }

    _getLabelDates(startDate, endDate) {
        let label = ''
        if (dayjs(startDate).isSame(dayjs(endDate), 'day')) {
            label = dayjs(startDate).format('DD/MM/YYYY')
        } else {
            if (dayjs(startDate).isSame(dayjs(endDate), 'year')) {
                label =
                    dayjs(startDate).format('DD/MM') +
                    ' - ' +
                    dayjs(endDate).format('DD/MM/YYYY')
            } else {
                label =
                    dayjs(startDate).format('DD/MM/YYYY') +
                    ' - ' +
                    dayjs(endDate).format('DD/MM/YYYY')
            }
        }

        return label
    }

    _renderClosedRange(absence, index) {
        const { classes } = this.props
        const { errorFields, disabled } = this.state
        const { startDate, endDate, startTime, endTime } = absence
        const error =
            errorFields.find(
                error => error === 'startTime' || error === 'endTime',
            ) &&
            (!startTime || !endTime)

        return (
            <>
                <div
                    className={`${classes.flexColumnSpacebetween} ${
                        error ? classes.error : ''
                    }`}
                >
                    <div
                        style={{
                            display: 'flex',
                            minWidth: 275,
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography>
                            {this._getLabelDates(startDate, endDate)}
                        </Typography>
                        {error && (
                            <Typography
                                className={classes.error}
                                style={{ marginLeft: 15 }}
                            >
                                {this.displayText('missingInformations')}
                            </Typography>
                        )}
                    </div>
                    <IconButton
                        size={`small`}
                        id={`delete-break`}
                        onClick={() => this.changeOpenedDay(index)}
                        children={<EditIcon />}
                        className={classes.iconButton}
                        disableRipple={disabled}
                    />
                </div>
                <Divider
                    orientation="horizontal"
                    style={{ margin: '10px 0px' }}
                />
            </>
        )
    }

    changeOpenedDay(index) {
        this.setState({
            openedDateRangeIndex: index,
        })
    }

    // Render l'onglet "Informations essentielles"
    _renderEssential() {
        const { classes } = this.props
        const {
            selectedDateRanges,
            openedDateRangeIndex,
            allDay,
            disabled,
            byManager,
            event,
        } = this.state

        const absenceTime = this._calculateTotalTimeOfAbsence(
            this.state.selectedDateRanges,
        )

        return (
            <div className={classes.content}>
                {disabled && (
                    <BoatOnAlert
                        severity="info"
                        description={
                            event?.absenceStatus?.id === 2
                                ? this.displayText('absenceValidated')
                                : event?.absenceStatus?.id === 3
                                ? this.displayText('absenceRejected')
                                : ''
                        }
                    />
                )}
                {byManager && this._renderUserSelector()}
                {this._renderManagerSelector()}
                {this._renderTypeAbsence()}
                {this._renderDateSelector()}

                {/* All day */}

                <div className={classes.absenceDurationContainer}>
                    <div className={classes.allDayContainer}>
                        {this.displayText('allDay')}
                        <Checkbox
                            checked={
                                // range?.startTime === null && range?.endTime === null
                                allDay
                            }
                            onClick={() => {
                                this.setState({
                                    allDay: !allDay,
                                    nbOfMinutesAbsent:
                                        !allDay === true
                                            ? absenceTime.nbMinutes
                                            : 0,
                                })
                            }}
                            color="primary"
                            disabled={disabled}
                        />
                    </div>

                    {!allDay
                        ? selectedDateRanges.map((range, index) =>
                              index === openedDateRangeIndex
                                  ? this._renderTimePickers(range)
                                  : this._renderClosedRange(range, index),
                          )
                        : this._renderHoursAndMinutes()}
                </div>

                {this._renderCommentField()}
                {this._renderDocuments()}
            </div>
        )
    }

    _setNbMinutesAbsentFromHours(e) {
        const { nbOfMinutesAbsent, selectedDateRanges } = this.state
        const absenceTime = this._calculateTotalTimeOfAbsence(
            selectedDateRanges,
        )

        if (absenceTime.nbMinutes / 60 <= e.target.value) {
            this.setState({
                nbOfMinutesAbsent: e.target.value * 60,
            })
        } else {
            this.setState({
                nbOfMinutesAbsent:
                    e.target.value * 60 + (nbOfMinutesAbsent % 60),
            })
        }
    }

    _setNbMinutesAbsentFromMinutes(e) {
        const { nbOfMinutesAbsent, selectedDateRanges } = this.state
        const absenceTime = this._calculateTotalTimeOfAbsence(
            selectedDateRanges,
        )

        if (absenceTime.nbMinutes / 60 <= nbOfMinutesAbsent / 60)
            this.setState({
                nbOfMinutesAbsent:
                    nbOfMinutesAbsent -
                    60 +
                    ((nbOfMinutesAbsent % 60) - e.target.value) * -1,
            })
        else
            this.setState({
                nbOfMinutesAbsent:
                    nbOfMinutesAbsent +
                    ((nbOfMinutesAbsent % 60) - e.target.value) * -1,
            })
    }

    _getNbMinutesFromNbMinutes() {
        let { nbOfMinutesAbsent } = this.state

        if (!nbOfMinutesAbsent) nbOfMinutesAbsent = 0

        return (nbOfMinutesAbsent % 60).toString().padStart(2, '0')
    }

    _getNbHoursFromNbMinutes() {
        let { nbOfMinutesAbsent } = this.state

        if (!nbOfMinutesAbsent) nbOfMinutesAbsent = 0

        return Math.floor(nbOfMinutesAbsent / 60)
            .toString()
            .padStart(2, '0')
    }

    /**
     *
     * @param {*} absenceStatusId Si défini, permet de modifier le status de l'absence
     * @returns
     */
    async saveWorkTime(absenceStatusId = undefined) {
        const {
            selectedDateRanges,
            comment,
            event,
            fileNoUrl,
            deletedFiles,
            nbOfMinutesAbsent,
        } = this.state
        const userLink = event?.userLink

        if (!this._handleError()) return
        const lang = this.context

        const nbTotalDays = selectedDateRanges.reduce(
            (acc, curr) =>
                acc +
                dayjs(curr.endDate).diff(dayjs(curr.startDate), 'day') +
                1,
            0,
        )

        if (event?.id) {
            const body = {
                id: event.id,
                responsibleLink: event.responsibleLink,
                userLinkId: event.userLinkId,
                absenceReasonType: event.absenceReasonType,
                absenceTime: selectedDateRanges[0].startTime &&
                    selectedDateRanges[0].endTime && {
                        id: event.absenceTime.id,
                        startTime: dayjs(
                            selectedDateRanges[0].startTime,
                        ).format('HH:mm:ss'),
                        endTime: dayjs(selectedDateRanges[0].endTime).format(
                            'HH:mm:ss',
                        ),
                    },
                delimitedDate: {
                    id: event.delimitedDate.id,
                    startDate: dayjs(selectedDateRanges[0].startDate),
                    endDate: dayjs(selectedDateRanges[0].endDate),
                },
                comment: event.comment,
                nbOfMinutesAbsent: nbOfMinutesAbsent,
                absenceStatus: event.absenceStatus,
            }

            // Mise à jour du statut de l'absence
            if (absenceStatusId) {
                body.absenceStatus = {
                    id: absenceStatusId,
                }
            }

            this.props.dispatch(
                putAbsence(
                    {
                        id: event.id,
                        body: body,
                        filesToUpload: fileNoUrl['event'],
                        filesToDelete: deletedFiles['event'],
                    },
                    () => {
                        this.refetchAbsences()
                        this.props.handleClose()
                    },
                    lang,
                ),
            )
        } else {
            // Si l'utilisateur est le même que le manager
            // Et si l'utilisateur est l'utilisateur actuellement connecté
            const currentUserLink = this.getCurrentUserLink()
            let isAutomaticallyValidated = false
            if (
                event?.responsibleLink?.id === event?.userLink?.id &&
                event?.responsibleLink?.id === currentUserLink?.id
            ) {
                isAutomaticallyValidated = true
            }
            Promise.allSettled(
                selectedDateRanges.map(dateRange => {
                    const nbDays =
                        dayjs(dateRange.endDate).diff(
                            dateRange.startDate,
                            'day',
                        ) + 1
                    return this.props.dispatch(
                        postAbsence(
                            {
                                body: {
                                    responsibleLink: event.responsibleLink,
                                    userLinkId: userLink.id,
                                    absenceReasonType: event.absenceReasonType,
                                    absenceTime: dateRange.startTime &&
                                        dateRange.endTime && {
                                            startTime: dayjs(
                                                dateRange.startTime,
                                            ).format('HH:mm:ss'),
                                            endTime: dayjs(
                                                dateRange.endTime,
                                            ).format('HH:mm:ss'),
                                        },
                                    absenceStatus: isAutomaticallyValidated
                                        ? {
                                              id: 2,
                                          }
                                        : undefined,
                                    delimitedDate: {
                                        startDate: dayjs(dateRange.startDate),
                                        endDate: dayjs(dateRange.endDate),
                                        comment: '',
                                    },
                                    comment,
                                    nbOfMinutesAbsent:
                                        (nbOfMinutesAbsent / nbTotalDays) *
                                        nbDays,
                                },
                                filesToUpload: fileNoUrl['event'],
                            },
                            lang,
                        ),
                    )
                }),
            ).then(() => {
                this.refetchAbsences()
                this.props.handleClose()
            })
        }
    }

    async refetchAbsences() {
        const { event } = this.state
        const currentUserLink = this.getCurrentUserLink()

        // Si l'utilisateur actuel est l'utilisateur affecté
        // On refetch les absences
        if (currentUserLink?.id === event?.userLink?.id) {
            await this.props.dispatch(
                absencesActions.requestUserAbsences(currentUserLink.id),
            )
        }
        // Si l'utilisateur actuel est le manager
        // On refetch les absences manager
        if (currentUserLink?.id === event?.responsibleLink?.id) {
            await this.props.dispatch(
                absencesActions.requestUserAbsencesByManager(
                    currentUserLink.id,
                ),
            )
        }
    }

    /**
     * Fonction utilisée pour recréer un objet dayjs en définissant uniquement l'heure
     * Pour permettre de faciliter les comparaisons d'heures
     */
    onlyTime(time) {
        if (!time) return undefined
        return dayjs(`${time.hour()}:${time.minute()}`, 'HH:mm')
    }

    /**
     * Renvoi la date la plus tôt parmi les dates passées en paramètre
     * @param {*} dates
     */
    earliest(dates) {
        // let earliest = dates[0]
        let earliest
        for (const date of dates) {
            if (date && (!earliest || date.isBefore(earliest))) {
                earliest = date
            }
        }
        return earliest
    }

    /**
     * Renvoi la date la plus tardive parmi les dates passées en paramètre
     * @param {*} dates
     */
    latest(dates) {
        let latest = dates[0]
        for (const date of dates) {
            if (date.isAfter(latest)) {
                latest = date
            }
        }
        return latest
    }

    /**
     * Sépare les évènements en plusieurs évènements si ils sont sur plusieurs jours
     * Pour faciliter le traitement
     */
    splitEvents(events) {
        const splitEvents = []
        events.forEach(evt => {
            // Ne pas prendre en compte les évènements sans date de début ou de fin
            if (
                !evt.delimitedDate ||
                !evt.delimitedDate.startDate ||
                !evt.delimitedDate.endDate
            )
                return

            const start = evt.delimitedDate.startDate
            const end = evt.delimitedDate.endDate
            const nbDays = dayjs(end).diff(dayjs(start), 'day') + 1 || 1

            for (let i = 0; i < nbDays; i++) {
                let actualStart = dayjs(start).add(i, 'day')
                let actualEnd = dayjs(end).subtract(nbDays - i - 2, 'day')

                let startTime, endTime
                // Si c'est une absence
                if (evt.absenceStatus || evt.absenceTime) {
                    // Si l'absence n'a pas de date de début ou de fin
                    // C'est qu'elle est toute la journée
                    // Donc on met les heures à 00:00:00 et 23:59:59
                    if (!evt.absenceTime) {
                        startTime = dayjs('00:00:00', 'HH:mm:ss')
                        endTime = dayjs('00:00:00', 'HH:mm:ss')
                    } else {
                        if (evt.absenceTime.startTime)
                            startTime = dayjs(
                                evt.absenceTime.startTime,
                                'HH:mm:ss',
                            )

                        if (evt.absenceTime.endTime)
                            endTime = dayjs(evt.absenceTime.endTime, 'HH:mm:ss')
                    }
                } else {
                    // Si c'est un worktime
                    // On transforme les heures HH:mm:ss du worktime en objet dayjs
                    // Les heures des worktimes non enregistrés sont déjà des objets dayjs
                    if (evt.startTime)
                        startTime = dayjs.isDayjs(evt.startTime)
                            ? evt.startTime
                            : dayjs(evt.startTime, 'HH:mm:ss')

                    if (evt.endTime)
                        endTime = dayjs.isDayjs(evt.endTime)
                            ? evt.endTime
                            : dayjs(evt.endTime, 'HH:mm:ss')
                }

                // Si l'évènement est sur une seule journée
                // On soustrait un jour à l'heure de fin
                if (
                    startTime &&
                    endTime &&
                    this.onlyTime(startTime).isBefore(this.onlyTime(endTime))
                ) {
                    actualEnd = actualEnd.subtract(1, 'day')
                }

                const dateTimeStart = startTime
                    ? actualStart
                          .hour(startTime.hour())
                          .minute(startTime.minute())
                    : undefined

                const dateTimeEnd = endTime
                    ? actualEnd.hour(endTime.hour()).minute(endTime.minute())
                    : undefined

                splitEvents.push({
                    ...evt,
                    delimitedDate: {
                        startDate: actualStart,
                        endDate: actualStart.isSame(actualEnd, 'day')
                            ? actualEnd.add(1, 'day')
                            : actualEnd,
                    },
                    dateTimeStart,
                    dateTimeEnd,
                })
            }
        })
        splitEvents.sort((wt1, wt2) => {
            return dayjs(wt1.delimitedDate.startDate).isBefore(
                dayjs(wt2.delimitedDate.startDate),
            )
                ? -1
                : 1
        })

        return splitEvents
    }

    calculateTotalTime() {
        const { selectedDateRanges } = this.state
        let total = 0

        for (const range of selectedDateRanges) {
            const { startTime, endTime, startDate, endDate } = range || {
                startTime: null,
                endTime: null,
                startDate: null,
                endDate: null,
            }
            const nbDays = dayjs(endDate).diff(dayjs(startDate), 'day') + 1
            if (startTime && endTime) {
                total += dayjs(endTime).diff(dayjs(startTime)) * nbDays
            }
        }

        const h = total / 3600000 - (total % 3600000) / 3600000

        const m = (total % 3600000) / 60000

        return (
            <span>
                {h.toFixed().padStart(2, '0')}h{m.toString().padStart(2, '0')}
            </span>
        )
    }

    _getContextButton() {
        const { event, selectedDateRanges, userPaidVacationsData } = this.state
        const { loading } = this.props

        let isSubmitDisabled = false

        if (!userPaidVacationsData) return []

        const dataForCurrentUser = userPaidVacationsData.find(
            data => data.linkUserId === event?.userLink?.id,
        )

        if (event?.absenceReasonType?.id === 1 && dataForCurrentUser) {
            // // Le bouton de validation est désactivé si il n'y a pas assez de congés payés
            const remainingPaidVacations = dataForCurrentUser.remainingVacations

            const totalAbsenceTime = this._calculateTotalTimeOfAbsence(
                selectedDateRanges,
            ).nbMinutes
            isSubmitDisabled = remainingPaidVacations < totalAbsenceTime
        }

        // Si le manager sélectionné est l'utilisateur actuel
        // Alors il a accès aux options de validation
        let isManager = false
        const userLink = this.getCurrentUserLink()
        if (userLink?.id === event?.responsibleLink?.id) {
            isManager = true
        }

        let baseTab = []

        // Si l'évènement est validé ou refusé,
        // Aucune option n'est disponible
        if (event?.absenceStatus && event?.absenceStatus.id !== 1)
            return baseTab

        // Si l'utilisateur est un manager
        // Et que l'absence a déjà été sauvegardée
        // Alors le manager a la possibilité de la valider ou de la refuser
        if (isManager && event?.id) {
            // Ajout du bouton Refuser
            baseTab.push({
                disabled: loading > 0 || isSubmitDisabled,
                label: this.displayText('reject'),
                action: () => {
                    this.submitActivity()
                    this.saveWorkTime(3)
                },
                type: `error`,
                permissions: {
                    action: Actions.Update,
                    entityName: PermissionsEntities.WorkTime,
                },
            })

            // Ajout du bouton Valider
            baseTab.push({
                disabled: loading > 0 || isSubmitDisabled,
                label: this.displayText('validate'),
                action: () => {
                    this.submitActivity()
                    this.saveWorkTime(2)
                },
                type: `primary`,
                permissions: {
                    action: Actions.Update,
                    entityName: PermissionsEntities.WorkTime,
                },
            })
        } else {
            baseTab.push({
                disabled: loading > 0 || isSubmitDisabled,
                label: event?.id
                    ? this.displayText(`update`)
                    : this.displayText(`saveTask`),
                action: () => this.saveWorkTime(),
                type: `primary`,
                onlyOnePermission: true,
                permissions: [
                    {
                        action: Actions.Update,
                        entityName: PermissionsEntities.WorkTime,
                    },
                    {
                        action: Actions.Create,
                        entityName: PermissionsEntities.WorkTime,
                    },
                ],
            })
        }

        return baseTab
    }

    _renderActions(CTAs) {
        const { allDay, selectedDateRanges, disabled, event } = this.state
        const { classes, loading } = this.props

        const { userPaidVacationsData } = this.state

        //TODO: allow multi button and position them
        if (!CTAs || loading || !userPaidVacationsData) return <></>

        let isOvertime = false
        let errorLabel = ''

        const dataForCurrentUser = userPaidVacationsData.find(
            data => data.linkUserId === event?.userLink?.id,
        )

        // Si le type d'absence est "congés payés"
        if (event?.absenceReasonType?.id === 1 && dataForCurrentUser) {
            // // Le bouton de validation est désactivé si il n'y a pas assez de congés payés
            // const paidVacationsUsed = userPaidVacationsData.find(data=>data.linkUserId==event?.userLink?.id).used;

            const remainingPaidVacations = dataForCurrentUser.remainingVacations

            const totalAbsenceTime = this._calculateTotalTimeOfAbsence(
                selectedDateRanges,
            ).nbMinutes
            isOvertime = remainingPaidVacations < totalAbsenceTime && !disabled

            const remainingVacationsDays = Math.floor(
                remainingPaidVacations / 60 / DEFAULT_NB_HOURS_PER_DAY,
            )
            const takenVacationsDays = Math.floor(
                totalAbsenceTime / 60 / DEFAULT_NB_HOURS_PER_DAY,
            )

            errorLabel = this.displayText('remainingPaidVacationsWarning')
                .replace('$daysRemaining', remainingVacationsDays)
                .replace('$daysTaken', takenVacationsDays)
        }

        return (
            <DialogActions className={classes.footer}>
                <span className={classes.totalTime}>
                    {this.displayText('total')}:{' '}
                    {allDay
                        ? `${this._getNbHoursFromNbMinutes() ??
                              '00'}h${this._getNbMinutesFromNbMinutes() ??
                              '00'}`
                        : this.calculateTotalTime()}
                    {isOvertime && (
                        <span className={classes.error}>{errorLabel}</span>
                    )}
                </span>
                <div className={classes.actions}>
                    {CTAs.map(cta => {
                        return cta.permissions ? (
                            <BoatOnBlock
                                permissions={cta.permissions}
                                onlyOnePermission={cta.onlyOnePermission}
                                addedCondition={cta.addedCondition}
                                children={
                                    <BobButton
                                        primary
                                        label={cta.label}
                                        onClick={cta.action}
                                        style={{
                                            minWidth: cta.minWidth || 0,
                                            margin: 10,
                                            height: `calc(100% - 20px)`,
                                        }}
                                        endIcon={cta.endIcon || null}
                                        disabled={cta.disabled}
                                        key={`cta.label-${_uniqueId()}`}
                                        type={cta.type}
                                        form={cta.form}
                                        size={`large`}
                                    />
                                }
                            />
                        ) : (
                            <BobButton
                                primary
                                label={cta.label}
                                onClick={cta.action}
                                style={{
                                    minWidth: cta.minWidth || 0,
                                    margin: 10,
                                    height: `calc(100% - 20px)`,
                                }}
                                endIcon={cta.endIcon || null}
                                disabled={cta.disabled}
                                key={`cta.label-${_uniqueId()}`}
                                type={cta.type}
                                form={cta.form}
                                size={`large`}
                            />
                        )
                    })}
                </div>
            </DialogActions>
        )
    }

    render() {
        const {
            title,
            event,
            activity,
            workTimeLoading,
            absencesLoading,
            noCross = true,
        } = this.props

        if (workTimeLoading > 0 || absencesLoading > 0) {
            return this.renderLoading(100, 100)
        }
        return (
            <>
                {this._renderTitle(title, noCross, [
                    this.displayText(`essentialInfos`),
                    event?.id ? this.displayText(`activity`) : undefined,
                ])}
                {this._renderBody({
                    bodies: [
                        this._renderEssential(),
                        event?.id
                            ? this._renderActivity(activity, event, 'leave')
                            : undefined,
                    ],
                })}
                {this._renderActions(this._getContextButton())}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        leaveTypes: state.types.leaveTypes,
        groups: state.group.groupsMembers,
        loading: state.bob.loading,
        currentGroupId: state.group.currentGroupId,
        groupMembers: state.group?.groupsMembers?.linkRGU || [],
        workTimes: state.workTime.workTimes,
        absences: state.absence.absences,
        absencesManager: state.absence.absencesManager,
        workTimeSettings: state.settings.workTimeSettings,
        paidVacationSettings: state.settings.paidVacationSettings,
        activity: state.activity.activity,
        paidVacationsGroup: state.absence.paidVacationsGroup,
        absencesLoading: state.absence.loading,
        workTimeLoading: state.workTime.loading,
        groupAbsences: state.absence.absencesGroup,
        groupPaidVacationsAllowed: state.absence.paidVacationsAllowed,
        role: state.block.selectedGroup.userRole,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(AbsenceModal))
