export default theme => ({
    root: {
        width: `64px`,
        height: `64px`,
        backgroundColor: `#E9EBED`,
        borderRadius: `8px`,
        [theme.breakpoints.down('xs')]: {
            width: `48px`,
            height: `48px`,
        },
    },
    letters: {
        fontSize: `24px`,
        lineHeight: `18px`,
        color: `#303F9F`,
        textTransform: `uppercase`,
        textAlign: `center`,
        paddingTop: `23px`,
        [theme.breakpoints.down('xs')]: {
            paddingTop: `15px`,
        },
    },
})
