export default {
    annualPrice: {
        FR: 'Tarif annuel',
        EN: 'annual price',
    },
    accept: {
        FR: 'Accepter',
        EN: 'Accept',
    },
    sendMessage: {
        FR: 'Envoyer un message',
        EN: 'Send a message',
    },
    refuse: {
        FR: 'Refuser',
        EN: 'Refuse',
    },
    seeDetails: {
        FR: "Voir les détails de l'offre",
        EN: 'See details of the offer',
    },
    expertAdvice: {
        FR: 'Conseils de nos experts',
        EN: 'Advice from our experts',
    },
    detailsOffer: {
        FR: "L'offre en détails",
        EN: 'The offer in detail',
    },
    cliqueOnLink: {
        FR: 'Cliquez sur les liens ci-dessous pour voir cette offre :',
        EN: 'Click on the links below to see this offer :',
    },
    subscribe: {
        FR: 'Souscrire',
        EN: 'Subscribe',
    },
    decline: {
        FR: 'Decliner',
        EN: 'Decline',
    },
    callback: {
        FR: 'Être rappeler par un conseiller',
        EN: 'Being called back by a counsellor',
    },
    accepted: {
        FR: 'Offre acceptée',
        EN: 'Offer accepted',
    },
    declined: {
        FR: 'Offre déclinée',
        EN: 'Offer declined',
    },
    status: {
        FR: 'Status de la demande',
        EN: 'Status of the application',
    },
    alert: {
        FR:
            'Votre demande de rappel à bien été enregistrée, vous allez être contacté très prochainement',
        EN:
            'Your request to be called back has been sent, you will be called back shortly',
    },
}
