import React from 'react'
import BoatOnComponent from '../BoatOnComponent'
import { withStyles } from '@material-ui/styles'
import styles from './Styles/BoatOnRenderDateCss'

class BoatOnRenderDate extends BoatOnComponent {
    checkDateIsValide() {
        const { date, classes, classesOverride } = this.props

        const year = date?.getFullYear()
        const day = date?.getDate()
        const month = date
            ?.toLocaleString('default', { month: 'short' })
            .toUpperCase()
        if (date && year && day && month) {
            return (
                <div
                    className={[classes.root, classesOverride?.root].join(' ')}
                >
                    <p className={classes.year}> {year} </p>
                    <p className={classes.day}> {day} </p>
                    <p className={classes.month}> {month} </p>
                </div>
            )
        } else {
            return <div className={classes.root}></div>
        }
    }

    render() {
        return <>{this.checkDateIsValide()}</>
    }
}

export default withStyles(styles)(BoatOnRenderDate)
