export default {
    seeMore: {
        FR: `En voir plus`,
        EN: `See more`,
    },
    dates: {
        FR: 'Par dates',
        EN: 'By dates',
    },
    hours: {
        FR: 'Par heures',
        EN: 'By hours',
    },
    recorded: {
        FR: "Vous n'avez aucune tâche enregistrée",
        EN: 'You have no recorded task',
    },
    repairDone: {
        FR: `Tâche effectuée`,
        EN: `Past task`,
    },
    repairToDo: {
        FR: `Future tâche`,
        EN: `Future task`,
    },
    addRepair: {
        FR: 'Ajouter une tâche',
        EN: 'Create new task',
    },
    createNextRepair: {
        FR: `Création d'une future tâche`,
        EN: `Creating a futur task`,
    },
    createLastRepair: {
        FR: `Enregistrement d'une tâche effectuée`,
        EN: `Recording of a task`,
    },
}
