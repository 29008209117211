import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import { DialogActions, withStyles } from '@material-ui/core'
import styles from './ValidTimesheetExportModalCss'
import dictionary from '../Dictionary/WorkTimePageDico'
import { Button as BobButton } from '../../../../common/BoatOnButton'
import InfoIcon from '@material-ui/icons/Info'

class ValidTimesheetExportModal extends BoatOnModalCore {
    constructor(props) {
        super(props)

        this.state = {}

        this.renderActions = this.renderActions.bind(this)
        this.getBody = this.getBody.bind(this)

        this.dateInputRef = React.createRef()

        this.dictionary = dictionary
    }

    renderActions() {
        const { classes, onGoBack, onContinue } = this.props

        return (
            <DialogActions className={classes.footer}>
                <div className={classes.actions}>
                    <BobButton
                        primary
                        label={this.displayText(`goBack`)}
                        style={{
                            minWidth: 0,
                            margin: 10,
                            height: `calc(100% - 20px)`,
                        }}
                        size={`large`}
                        type={`text`}
                        onClick={onGoBack}
                    />
                    <BobButton
                        primary
                        label={this.displayText(`continue`)}
                        style={{
                            minWidth: 0,
                            margin: 10,
                            height: `calc(100% - 20px)`,
                        }}
                        size={`large`}
                        type={`validate`}
                        onClick={onContinue}
                    />
                </div>
            </DialogActions>
        )
    }

    getBody() {
        const { classes } = this.props
        return (
            <div className={classes.warningBubble}>
                <InfoIcon className={classes.warningBubble__icon} />
                <p className={classes.warningBubble__p}>
                    {this.displayText(`warningBubbleText`)}
                </p>
            </div>
        )
    }

    render() {
        const { title } = this.props
        return (
            <>
                {this._renderTitle(title, true)}
                {this._renderBody({
                    body: this.getBody(),
                    hasDividers: false,
                })}
                {this.renderActions()}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(
    withStyles(styles)(ValidTimesheetExportModal),
)
