import React from 'react'
import { PropTypes } from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import BoatOnComponent from './BoatOnComponent'
import styles from './Styles/UnderNavigationCss'
import dictionary from './Dictionary/UnderNavigationDico'
import { history } from '../../helpers'

function TabContainer(props) {
    return (
        <div component="div" style={{ padding: 0 }}>
            {props.children}
        </div>
    )
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
}

class UnderNavigation extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            nav: props.nav || 0,
        }

        this.handleChange = this.handleChange.bind(this)
        this.renderMenu = this.renderMenu.bind(this)
    }

    handleChange(event, nav) {
        const { routes } = this.props
        this.setState({ nav })

        if (history) {
            for (var i = 0; i < routes.length; i++) {
                if (nav === i) {
                    this.historyPush(history, routes[i])
                }
            }
        }
    }

    renderMenu(classes) {
        const { nav } = this.state
        const { tabs, labs, routes } = this.props
        const matches = window.innerWidth > 600 ? false : true

        if (!tabs || !labs || !routes) {
            console.error('UnderNavigation needs tabs, labs and routes')
            return 'ERROR UnderNavigation'
        }

        if (tabs.length !== labs.length || tabs.length !== routes.length) {
            console.error(
                'UnderNavigation needs tabs, labs and routes with the same numbers of elements',
            )
            return 'ERROR UnderNavigation'
        }

        return (
            <div className={classes.root}>
                <AppBar position="static" className={classes.appBar}>
                    <Tabs
                        indicatorColor="primary"
                        value={nav}
                        onChange={this.handleChange}
                        variant="scrollable"
                        scrollButtons={matches ? 'on' : 'auto'}
                    >
                        {labs.map((el, i) => (
                            <Tab key={i} className={classes.title} label={el} />
                        ))}
                    </Tabs>
                </AppBar>
                {tabs.map(
                    (el, index) =>
                        nav === index && (
                            <TabContainer key={index}>{el}</TabContainer>
                        ),
                )}
            </div>
        )
    }

    render() {
        const { classes } = this.props

        return this.renderMenu(classes)
    }
}

UnderNavigation.propTypes = {
    classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(UnderNavigation)
