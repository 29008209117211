export default {
    boatName: {
        FR: 'Nom du bateau ',
        EN: 'Boat name ',
    },
    boatType: {
        FR: 'Type du bateau ',
        EN: 'Boat type ',
    },
    boatYear: {
        FR: 'Année du bateau ',
        EN: 'Boat year ',
    },
    boatMaker: {
        FR: 'Marque du bateau ',
        EN: 'Boat maker ',
    },
    boatModel: {
        FR: 'Modèle du bateau ',
        EN: 'Boat model ',
    },
    cta1: {
        FR: `Vous n'avez pas encore accès à cette fonctionnalité. Pour pouvoir ajouter un bateau passez à l'offre supérieure (compris à partir de l'abonnement Bosco) en vous rendant sur `,
        EN: `You do not have access to this feature yet. To add a boat, upgrade to the next level (included in the Captain subscription) by going to `,
    },
    cta21: {
        FR: `Nous vous rappelons que votre abonnement est de`,
        EN: `We remind you that your subscription is of`,
    },
    cta22: {
        FR: `par mois et bateau. L'ajout de ce bateau passera donc votre abonnement à `,
        EN: `per month and boat. The addition of this boat will increase your subscription to `,
    },
    understand: {
        FR: `J'ai compris`,
        EN: `I understand`,
    },
    espaceClient: {
        FR: `votre espace client`,
        EN: `your customer area`,
    },
    tarifs: {
        FR: `nos différentes offres`,
        EN: `our offers`,
    },
    boatRegistration: {
        FR: 'Immatriculation ',
        EN: 'Boat registration ',
    },
    nbEngine: {
        FR: 'Nombre de moteurs',
        EN: 'Engine numbers',
    },
    homePortOrStorage: {
        FR: 'Port ou lieu de stockage ',
        EN: 'Homeport or storage area ',
    },
    boatMoreInformations: {
        FR: 'Note sur mon bateau ',
        EN: 'Note on my boat ',
    },
    importantInformations: {
        FR: "Ecrivez ici d'autres informations importantes sur votre bateau",
        EN: 'Write here other important information about your boat',
    },
    noBoat: {
        FR:
            "Oups, il semble que vous n'ayez pas enregistré les informations de base de votre bateau. Cliquez sur le bouton ci-dessous pour les ajouter.",
        EN: "Warning, you've no boat registered",
    },
    SignBoat: {
        FR: 'Enregistrer votre bateau',
        EN: 'Signin your boat',
    },
    addBoat: {
        FR: 'Ajouter un bateau',
        EN: 'Add a new boat',
    },
    boat: {
        FR: 'Mon bateau',
        EN: 'My boat',
    },
    boatAdd: {
        FR: "Ajout d'un bateau",
        EN: 'Add a boat',
    },
    enginesAdd: {
        FR: 'Ajout des moteurs',
        EN: 'Add engines',
    },
    ttc: {
        FR: `TTC`,
        EN: `(incl. VAT)`,
    },
    chooseBoat: {
        FR: `Choisissez un type de bateau`,
        EN: `Choose a boat type`,
    },
    landingStage: {
        FR: 'Panne',
        EN: 'landingStage',
    },
    pontoon: {
        FR: 'Ponton',
        EN: 'Pontoon',
    },
    mmsiNumber: {
        FR: 'Numéro MMSI',
        EN: 'MMSI Number',
    },
    imoNumber: {
        FR: 'Numéro IMO',
        EN: 'IMO Number',
    },
    flag: {
        FR: 'Pavillon',
        EN: 'Flag',
    },

    fillingLevelTitle: {
        FR: 'Niveau des réserves',
        EN: 'Filling Levels',
    },
    updateFilligLevel: {
        FR: 'Mettre à jour le niveau des réserves',
        EN: 'Update filling levels',
    },
    workingHoursTitle: {
        FR: 'Heures de marche',
        EN: 'Worktimes',
    },
    workingHours: {
        FR: 'Mettre à jour les heures de marche',
        EN: 'Update worktimes',
    },

    pressToValid: {
        FR: `Appuyer sur 'Enter' pour valider`,
        EN: `Press 'Enter' to valid`,
    },
}
