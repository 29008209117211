import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import BoatOnNumberField from '../../../../common/UsefullComponents/BoatOnNumberField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { getTranslatedTitleFromEvent, isSafariIOS } from '../../Utils'

import dictionary from './Dictionary/EditWorkingHoursModalDico'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/EditWorkingHoursModalCss'
import { putBOBEvents } from '../../../../../actions'

class EditWorkingHoursModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            equipmentInUse: [],
            updated: false,
        }

        this.save = this.save.bind(this)
        this.handleChangeValue = this.handleChangeValue.bind(this)
    }

    componentDidMount() {
        const { equipmentInUse } = this.props

        this.setState({
            equipmentInUse: JSON.parse(JSON.stringify(equipmentInUse)),
        })
    }

    save() {
        const { equipmentInUse } = this.state
        const { dispatch, onClose } = this.props
        const oldEquipmentInUse = this.props.equipmentInUse

        let eventsChanged = equipmentInUse.map((event, index) => {
            const id = event.id
            const details = event.detail

            if (id && details && oldEquipmentInUse[index]) {
                if (
                    oldEquipmentInUse[index]?.detail?.hourActual ===
                    details.hourActual
                )
                    return null

                return event
            }
        })

        eventsChanged = eventsChanged.filter(item => item !== null)

        eventsChanged.forEach(event => {
            dispatch(
                putBOBEvents(event.id, event.title, {
                    detail: event.detail,
                }),
            )
        })

        onClose()
    }

    handleChangeValue(value, equipmentId) {
        let { equipmentInUse } = this.state

        const index = equipmentInUse.findIndex(
            event => event.detail?.equipment?.id === equipmentId,
        )

        if (index !== -1) {
            if (value === '') {
                value = 0
            }
            equipmentInUse[index].detail.hourActual = parseFloat(value)

            this.setState({
                equipmentInUse,
                updated: true,
            })
        }
    }

    _renderBody() {
        const { classes, context } = this.props
        const { equipmentInUse } = this.state

        const renderEquipment = equipmentInUse
            .sort((a, b) => {
                const aName = getTranslatedTitleFromEvent(this, a)
                const bName = getTranslatedTitleFromEvent(this, b)

                if (!aName) return 1
                if (!bName) return -1

                return aName.localeCompare(bName)
            })
            .map((event, index) => {
                const equipment = event.detail?.equipment || null

                if (!equipment) return null

                const imgSrc = equipment.equipmentFamily?.type?.image
                    ? require(`images/${equipment.equipmentFamily?.type?.image}`)
                    : null
                const familyType =
                    equipment.equipmentFamily?.type?.translation[context] || ''
                const value = event?.detail?.hourActual

                return (
                    <div
                        className={classes.equipmentWrapper}
                        key={`equipment-${index}`}
                    >
                        <img
                            src={imgSrc}
                            alt={familyType}
                            className={classes.equipmentIcon}
                        />
                        <div className={classes.equipmentDetails}>
                            <div className={classes.equipmentLabel}>
                                {getTranslatedTitleFromEvent(this, event)}
                            </div>
                            <BoatOnNumberField
                                isDecimal
                                label={this.displayText('worktime')}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            h
                                        </InputAdornment>
                                    ),
                                }}
                                variant="outlined"
                                margin="dense"
                                onChange={e =>
                                    this.handleChangeValue(
                                        e.target.value,
                                        equipment.id,
                                    )
                                }
                                min={0}
                                value={value}
                                fullWidth={true}
                            />
                        </div>
                    </div>
                )
            })

        return (
            <div
                className={`${classes.root} ${
                    isSafariIOS() ? classes.iosRoot : ''
                }`}
            >
                <div className={classes.title}>
                    {this.displayText('titleWorktimes')}
                </div>
                <div className={classes.worktimeWrapper}>{renderEquipment}</div>
            </div>
        )
    }

    render() {
        const { title, noCross = true, showFollowButton } = this.props
        const { updated } = this.state

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    null,
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    body: this._renderBody(),
                })}
                {this._renderActions([
                    {
                        action: this.save,
                        disabled: !updated,
                        label: this.displayText('save'),
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {}
}

export default connect(mapStateToProps)(
    withStyles(styles)(EditWorkingHoursModal),
)
