export default theme => ({
    root: {
        width: '100%',
    },
    inquery: Object.assign(
        { marginTop: '25px' },
        theme.boxBoatOn,
        theme.flex.rowSpacebetween,
    ),
    infos: Object.assign(
        {
            width: 'calc(70% - 200px)',
            minWidth: '250px',
            [theme.breakpoints.down('sm')]: {
                minWidth: 'calc(70%)',
            },
        },
        theme.flex.columnSpacebetween,
    ),
    datesAndButton: Object.assign(
        {
            position: 'relative',
            width: '30%',
            alignItems: 'center',
            paddingTop: '5px',
            paddingBottom: '5px',
            [theme.breakpoints.down('sm')]: {
                alignItems: 'flex-end',
                justifyContent: 'center',
            },
        },
        theme.flex.columnSpacebetween,
    ),
    info: {},
    title: Object.assign(
        { marginRight: '10px', width: '100%', whiteSpace: 'break-spaces' },
        theme.typography.subtitle,
    ),
    titleAndStatus: Object.assign(
        { flexWrap: 'wrap' },
        theme.flex.rowAlignCenter,
    ),
    img: {
        width: '180px',
        minWidth: '180px',
        marginRight: '20px',
        marginTop: 'auto',
        marginBottom: 'auto',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    dateTitle: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    date: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    chevron: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    paper: {
        display: 'inline-block',
        position: 'absolute',
        top: '0',
        left: '50%',
        transform: 'translate(-50%)',
        width: '50%',
        maxWidth: '600px',
        maxHeight: '100%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: 0,
        outline: 'none',
        overflow: 'scroll',
        [theme.breakpoints.down('sm')]: {
            padding: '10px',
            width: 'calc(100% - 20px)',
        },
    },
    nothing: {
        width: '100%',
        fontSize: '20px',
        textAlign: 'center',
        padding: '20px',
    },
    buttonNewInquery: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: '2px solid #3040a0',
        borderRadius: '5px',
        alignItems: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
        width: '100%',
    },
    iconAdd: {
        color: '#3040a0',
        marginLeft: 10,
    },
    buttonText: {
        textTransform: 'none',
    },
})
