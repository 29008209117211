import { absenceConstants } from '../constants'

const initialState = {
    absences: null,
    absencesManager: null,
    loading: 0,
    total: null,
    absencesGroup: null,
    paidVacationsAllowed: null,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case absenceConstants.POST_ABSENCE:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case absenceConstants.POST_ABSENCE_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case absenceConstants.POST_ABSENCE_ERROR:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case absenceConstants.PUT_ABSENCE:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case absenceConstants.PUT_ABSENCE_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case absenceConstants.PUT_ABSENCE_ERROR:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case absenceConstants.USER_ABSENCES_REQUEST:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case absenceConstants.USER_ABSENCES_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                absences: action.absences,
            }
        case absenceConstants.USER_ABSENCES_ERROR:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                absences: action.absences,
            }
        case absenceConstants.TOTAL_USER_ABSENCES_REQUEST:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case absenceConstants.TOTAL_USER_ABSENCES_SUCCESS:
            return {
                ...prevState,
                total: action.total,
                loading: prevState.loading - 1,
            }
        case absenceConstants.TOTAL_USER_ABSENCES_ERROR:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case absenceConstants.GROUP_ABSENCES_REQUEST:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case absenceConstants.GROUP_ABSENCES_SUCCESS:
            return {
                ...prevState,
                absencesGroup: action.result,
                loading: prevState.loading - 1,
            }
        case absenceConstants.GROUP_ABSENCES_ERROR:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case absenceConstants.USER_ABSENCES_MANAGER_REQUEST:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case absenceConstants.USER_ABSENCES_MANAGER_SUCCESS:
            return {
                ...prevState,
                absencesManager: action.absencesManager,
                loading: prevState.loading - 1,
            }
        case absenceConstants.USER_ABSENCES_MANAGER_ERROR:
            return {
                ...prevState,
                absencesManager: action.absencesManager,
                loading: prevState.loading - 1,
            }

        case absenceConstants.GET_USER_LINKS_PAID_VACATIONS_ALLOWED:
            return {
                ...prevState,
                loading: prevState.loading + 1,
                paidVacationsAllowed: initialState.paidVacationsAllowed,
            }
        case absenceConstants.GET_USER_LINKS_PAID_VACATIONS_ALLOWED_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                paidVacationsAllowed: action.paidVacationsAllowed,
            }
        case absenceConstants.GET_USER_LINKS_PAID_VACATIONS_ALLOWED_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }

        case absenceConstants.CLEAR_GROUP_ABSENCES:
            return {
                ...prevState,
                absencesGroup: initialState.absencesGroup,
                paidVacationsAllowed: initialState.paidVacationsAllowed,
            }

        case absenceConstants.RESET_USER_LINKS_PAID_VACATIONS:
            return {
                ...prevState,
                paidVacationsAllowed: initialState.paidVacationsAllowed,
            }
        default:
            return prevState
    }
}
