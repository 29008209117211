import { settingsConstants } from '../constants'

const initialState = {
    workTimeSettings: [],
    paidVacationSettings: {},
    loading: 0,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case settingsConstants.GET_GROUP_WORKTIME_SETTINGS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case settingsConstants.GET_GROUP_WORKTIME_SETTINGS_SUCCESS:
            return {
                ...prevState,
                workTimeSettings: action.workTimeSettings,
                loading: prevState.loading - 1,
            }
        case settingsConstants.GET_GROUP_WORKTIME_SETTINGS_FAILURE:
            return {
                ...prevState,
                workTimeSettings: {},
                loading: prevState.loading - 1,
            }
        case settingsConstants.POST_GROUP_WORKTIME_SETTINGS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case settingsConstants.POST_GROUP_WORKTIME_SETTINGS_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case settingsConstants.POST_GROUP_WORKTIME_SETTINGS_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case settingsConstants.PUT_GROUP_WORKTIME_SETTINGS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case settingsConstants.PUT_GROUP_WORKTIME_SETTINGS_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case settingsConstants.PUT_GROUP_WORKTIME_SETTINGS_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case settingsConstants.GET_GROUP_PAID_VACATIONS_SETTINGS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case settingsConstants.GET_GROUP_PAID_VACATIONS_SETTINGS_SUCCESS:
            return {
                ...prevState,
                paidVacationSettings: action.paidVacationsSettings,
                loading: prevState.loading - 1,
            }
        case settingsConstants.GET_GROUP_PAID_VACATIONS_SETTINGS_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case settingsConstants.POST_GROUP_PAID_VACATIONS_SETTINGS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case settingsConstants.POST_GROUP_PAID_VACATIONS_SETTINGS_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case settingsConstants.POST_GROUP_PAID_VACATIONS_SETTINGS_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case settingsConstants.PUT_GROUP_PAID_VACATIONS_SETTINGS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case settingsConstants.PUT_GROUP_PAID_VACATIONS_SETTINGS_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case settingsConstants.PUT_GROUP_PAID_VACATIONS_SETTINGS_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        default:
            return prevState
    }
}
