export default {
    save: {
        FR: `Enregistrer`,
        EN: `Save`,
    },
    info: {
        FR: `Vous pouvez paramétrer un nombre de jours différents par année`,
        EN: `You can set a different number of days per year`,
    },
    infoMultiple: {
        FR: `Lorsque plusieurs utilisateurs sont sélectionnés, seul le nombre de jours de la période en cours peut être modifiés`,
        EN: `When multiple users are selected, only the number of days in the current period can be modified`,
    },
    userList: {
        FR: `Modifier le nombre de congés autorisés pour les utilisateurs sélectionnés`,
        EN: `Modify the number of vacations authorized for selected users`,
    },
    from: {
        FR: `Du`,
        EN: `From`,
    },
    to: {
        FR: `Au`,
        EN: `To`,
    },
    days: {
        FR: `jours`,
        EN: `days`,
    },
    applyEverywhere: {
        FR: `Appliquer partout`,
        EN: `Apply everywhere`,
    },
    showMore: {
        FR: 'Afficher plus',
        EN: 'Show more',
    },
    reduce: {
        FR: 'Réduire',
        EN: 'Reduce',
    },
    errorCantBeLessThanUsed: {
        FR: `Le nombre de jours autorisés ne peut pas être inférieur au nombre de jours déjà pris sur cette période`,
        EN: `The number of days allowed cannot be less than the number of days already taken in this period`,
    },
}
