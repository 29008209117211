export default theme => ({
    rootBorder: Object.assign(
        {
            width: 'calc(100% - 30px)',
            height: 'inherit',
            padding: '15px',
            position: 'absolute',
        },
        theme.boxBoatOn,
    ),
    root: {
        width: 'calc(100% - 30px)',
        height: 'calc(100% - 30px)',
        padding: '15px',
        position: 'absolute',
        minHeight: 'calc(100% - 30px)',
    },
    me: {
        margin: '5px',
        marginRight: '20px',
        marginLeft: 'auto',
        marginBottom: '10px',
        maxWidth: '80%',
        alignSelf: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
    },
    borderMessageMe: {
        border: '2px solid #fec107',
        borderRadius: '25px',
        backgroundColor: '#fec107',
        color: 'white',
        padding: '10px',
        paddingRight: '15px',
        paddingLeft: '15px',
    },
    borderMessageOther: {
        border: '2px solid #3040a0',
        borderRadius: '25px',
        backgroundColor: '#3040a0',
        color: 'white',
        padding: '10px',
        paddingRight: '15px',
        paddingLeft: '15px',
    },
    other: {
        margin: '5px',
        maxWidth: '80%',
        alignSelf: 'flex-start',
        marginBottom: '10px',
        display: 'flex',
        flexDirection: 'column',
    },
    messagesBox: {
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
        width: '100%',
        height: 'calc(100% - 70px)',
        minHeight: 'calc(100% - 70px)',
    },
    field: {
        marginBottom: 0,
        marginTop: 'auto',
        position: 'relative',
        height: '45px',
        width: '100%',
    },
    divInput: {
        display: 'flex',
        flexDirection: 'row',
        border: '2px solid #fec107',
        borderRadius: '25px',
        position: 'relative',
        marginTop: '5px',
    },
    input: {
        width: 'calc(100% - 120px)',
        height: '100%',
        float: 'left',
        padding: '20px',
        fontSize: '18px',
        border: '0',
        borderRadius: '25px',
        '&:focus': {
            outline: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            height: '5px',
            width: '100%',
        },
    },
    validate: {
        borderRadius: 0,
        borderTopRightRadius: '25px',
        borderBottomRightRadius: '25px',
        boxShadow: 'none',
        marginRight: '-2px',
        width: '120px',
        [theme.breakpoints.down('sm')]: {
            width: '50px',
        },
    },
    read: Object.assign(
        {
            marginRight: '20px',
            whiteSpace: 'nowrap',
        },
        theme.flex.rowEnd,
    ),
    dateMessageMe: {
        textAlign: 'right',
        lineHeight: '1.5',
    },
    dateMessageOther: {
        textAlign: 'left',
        lineHeight: '1.5',
    },
    dateAndNameMe: Object.assign({ marginBottom: '5px' }, theme.flex.rowEnd),
    dateAndNameOther: Object.assign({ marginBottom: '5px' }, theme.flex.row),
    sendIcon: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    sendText: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
})
