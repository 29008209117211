export default {
    date: {
        FR: 'Date',
        EN: 'Date',
    },
    bobSub: {
        FR: `Ajoutez des équipements personnalisés et bien plus encore grâce au mode Capitaine de notre carnet de bord.
        Profitez d'1 mois gratuit pour tester la formule qui vous convient le mieux !`,
        EN: `Add custom equipment and much more with the Captain's Mode of our logbook.
        Get 1 month free to test the formula that suits you best!`,
    },
    callToActionFamily: {
        FR: `+ Ajouter une autre famille d'équipement`,
        EN: `+ Add another equipment family`,
    },
    callToActionEquip: {
        FR: `+ Ajouter un autre type d'équipement`,
        EN: `+ Add another type of equipment`,
    },
    cancel: {
        FR: `Annuler`,
        EN: `Cancel`,
    },
    more: {
        FR: `Je veux voir ça !`,
        EN: `I want to see !`,
    },
    or: {
        FR: 'ou',
        EN: 'or',
    },
    hours: {
        FR: "nombre d'heures",
        EN: 'Hours',
    },
    seeAll: {
        FR: 'Voir tout',
        EN: 'See all',
    },
    operation: {
        FR: 'Tâche',
        EN: 'Task',
    },
    chooseFamilyEquipment: {
        FR: "Famille d'équipements",
        EN: 'Equipment family',
    },
    chooseEquipment: {
        FR: 'Equipement',
        EN: 'Equipment',
    },
    choosePart: {
        FR: 'Pièces',
        EN: 'Parts',
    },
    validate: {
        FR: 'Je valide',
        EN: 'Validate',
    },
    equipmentFamily: {
        FR: "Famille d'équipement",
        EN: 'Equipment family',
    },
    equipment: {
        FR: 'Équipement',
        EN: 'Equipment',
    },
    parts: {
        FR: 'Pièce',
        EN: 'Parts',
    },
    errorCheckup: {
        FR: `Vous devez sélectionner l'opération effectuée.`,
        EN: `You must select the operation performed.`,
    },
    errorEquipmentFamily: {
        FR: `Vous devez sélectionner la famille d'équipement sur laquelle a été effectuée l'opération.`,
        EN: `You must select the equipment family on which the operation has been performed.`,
    },
    errorEquipment: {
        FR: `Vous devez sélectionner l'équipement sur lequel a été effectué l'opération.`,
        EN: `You must select the equipment on which the operation was performed.`,
    },
    errorParts: {
        FR: `Vous devez sélectionner la ou les pièce(s) sur laquel ou lesquelles a été effectué l'opération.`,
        EN: `You must select the part(s) on which the operation was performed.`,
    },
    errorEquipmentFamilyInv: {
        FR: `Vous devez sélectionner la famille d'équipement à ajouter.`,
        EN: `You must select the equipment family to add.`,
    },
    errorEquipmentInv: {
        FR: `Vous devez sélectionner l'équipement à ajouter.`,
        EN: `You must select the equipment to be added.`,
    },
    errorPartsInv: {
        FR: `Vous devez sélectionner la pièce à ajouter.`,
        EN: `You must select the part to add.`,
    },
    addInInventoryEquipment: {
        FR: `Cet équipement n'est pas dans votre inventaire, cliquez dessus pour l'ajouter`,
        EN: `Add this equipment to your inventory`,
    },
    editInventoryEquipment: {
        FR: `Éditer les informations de cet équipement`,
        EN: `Edit the informations of this equipment`,
    },
    addInInventoryPart: {
        FR: `Cette pièce n'est pas dans votre inventaire, cliquez dessus pour l'ajouter`,
        EN: `This part is not in your inventory, click on it to add it`,
    },
    notInTheInventory: {
        FR: `Cet élément n'est pas présent dans votre inventaire`,
        EN: `This element is not in your inventory`,
    },
    editInventoryPart: {
        FR: `Éditer les informations de cette pièce`,
        EN: `Edit the informations of this equipment`,
    },
    addEquipment: {
        FR: `Ajouter un équipement qui n'est pas dans votre inventaire`,
        EN: `Add equipment that is not in your inventory`,
    },
    alreadyLinked: {
        FR: `Déjà lié à cette opération`,
        EN: `Already linked to this operation`,
    },
    others: {
        FR: `Autre(s)`,
        EN: `Other(s)`,
    },
    chooseBoat: {
        FR: 'Bateaux associés',
        EN: 'Choose one or several boats',
    },
    boat: {
        FR: 'Bateau',
        EN: 'Boats',
    },
    noBoatSelected: {
        FR: "Aucun bateaux n'est ajouté à ce groupe",
        EN: 'No boats are added to this group',
    },
    noOption: {
        FR: `Votre abonnement ne vous permet pas de créer un élément personnalisé`,
        EN: `Your subscription does not allow you to create a custom item`,
    },
    selectAll: {
        FR: `Tout sélectionner`,
        EN: `Select all`,
    },
    engineSpecification: {
        FR: `Qualificatif`,
        EN: `Specification`,
    },
    errorEngineSelection: {
        FR: `Vous devez sélectionner une spécification pour votre moteur`,
        EN: `You must select a specification for your engine`,
    },
}
