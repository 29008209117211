export const navigationConstants = {
    REQUEST_NAVIGATIONS: 'REQUEST_NAVIGATIONS',
    POST_NEW_NAVIGATION: 'POST_NEW_NAVIGATION',
    GET_NAVIGATIONS_FROM_GROUP: 'GET_NAVIGATIONS_FROM_GROUP',
    GET_NAVIGATIONS_FROM_BOAT: 'GET_NAVIGATIONS_FROM_BOAT',
    GET_NAVIGATION_BY_ID: 'GET_NAVIGATION_BY_ID',
    UPDATE_NAVIGATION: 'UPDATE_NAVIGATION',
    DELETE_NAVIGATION: 'DELETE_NAVIGATION',
    ERROR_REQUEST: 'ERROR_REQUEST',
}
