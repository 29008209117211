import { bobOrdersConstants } from '../../constants/bobOrders.constants'

const initialState = {
    loading: 0,
    orders: [],
    newOrderForm: null,
}

export default function bobOrderReducer(prevState = initialState, action) {
    switch (action.type) {
        case bobOrdersConstants.LOAD_ORDERS:
        case bobOrdersConstants.GENERATE_ORDER_FORM:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case bobOrdersConstants.ORDER_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case bobOrdersConstants.POST_ORDER_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                orders: prevState.orders,
            }
        case bobOrdersConstants.UPDATE_ORDER_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                orders: prevState.orders.map(order =>
                    order.id === action.payload.id ? action.payload : order,
                ),
            }
        case bobOrdersConstants.DELETE_ORDER_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                orders: prevState.orders.filter(
                    order => order.id !== action.payload,
                ),
            }
        case bobOrdersConstants.GET_ORDERS_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                orders: action.payload,
            }
        case bobOrdersConstants.GENERATE_ORDER_FORM_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                newOrderForm: action.payload,
            }
        case bobOrdersConstants.GENERATE_ORDER_FORM_FAILURE:
            return {
                ...prevState,
                newOrderForm: null,
                loading: prevState.loading - 1,
            }
        default:
            return prevState
    }
}
