export const bobOrdersConstants = {
    LOAD_ORDERS: 'LOAD_ORDERS',
    POST_ORDER_SUCCESS: 'POST_ORDER_SUCCESS',
    UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
    DELETE_ORDER_SUCCESS: 'DELETE_ORDER_SUCCESS',
    GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
    ORDER_FAILURE: 'ORDER_FAILURE',
    GENERATE_ORDER_FORM: 'GENERATE_ORDER_FORM',
    GENERATE_ORDER_FORM_SUCCESS: 'GENERATE_ORDER_FORM_SUCCESS',
    GENERATE_ORDER_FORM_FAILURE: 'GENERATE_ORDER_FORM_FAILURE',
}
