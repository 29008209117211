import { calendarEventConstants } from '../constants'

const initialState = {
    calendarEvent: null,
    loading: 0,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case calendarEventConstants.DECLINE_CALENDAR_EVENT_INVITATION:
        case calendarEventConstants.REQUEST_CALENDAR_EVENT:
        case calendarEventConstants.ACCEPT_CALENDAR_EVENT_INVITATION:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case calendarEventConstants.CALENDAR_EVENT_SUCCESS:
            return {
                ...prevState,
                calendarEvent: action.calendarEvent,
                loading: prevState.loading - 1,
            }
        case calendarEventConstants.DECLINE_CALENDAR_EVENT_INVITATION_FAILURE:
        case calendarEventConstants.ACCEPT_CALENDAR_EVENT_INVITATION_FAILURE:
        case calendarEventConstants.CALENDAR_EVENT_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case calendarEventConstants.DECLINE_CALENDAR_EVENT_INVITATION_SUCCESS:
        case calendarEventConstants.ACCEPT_CALENDAR_EVENT_INVITATION_SUCCESS:
            return {
                ...prevState,
                calendarEvent: {
                    ...prevState.calendarEvent,
                    calendarEventInvitations: prevState.calendarEvent.calendarEventInvitations.map(
                        invitation => {
                            if (invitation.token === action.invitation.token)
                                return action.invitation
                            return invitation
                        },
                    ),
                },
                loading: prevState.loading - 1,
            }
        default:
            return prevState
    }
}
