import { redirectActions } from '../../actions/redirect.actions'
import AppRoute from '../../constants/AppRoute'
import { Actions, PermissionsEntities } from '../../constants'

export const redirectConfig = {
    ABSENCE: {
        eventFn: redirectActions.getAbsenceByIdForRedirect,
        redirectRoute: AppRoute.LogBook.WorkTime,
        modaleName: 'absence',
        permission: {
            action: Actions.Read,
            entityName: PermissionsEntities.WorkTime,
        },
    },
    WORKTIME: {
        eventFn: redirectActions.getWorkTimeByIdForRedirect,
        redirectRoute: AppRoute.LogBook.WorkTime,
        modalName: 'workTime',
        permission: {
            action: Actions.Read,
            entityName: PermissionsEntities.WorkTime,
        },
    },
    REPAIR: {
        eventFn: redirectActions.getBobEventByIdForRedirect,
        modalName: 'editRepair',
        redirectRoute: AppRoute.LogBook.Repair,
        permission: {
            action: Actions.Read,
            entityName: PermissionsEntities.Checkup,
        },
    },
    BUDGET: {
        eventFn: redirectActions.getBobEventByIdForRedirect,
        redirectRoute: AppRoute.LogBook.ExpensesAndIncome,
        modalName: '',
        permission: {
            action: Actions.Read,
            entityName: PermissionsEntities.Transaction,
        },
    },
}
