export default {
    downloadExcel: {
        FR: 'Télécharger un Excel',
        EN: 'Download Excel',
    },
    downloadPdf: {
        FR: 'Télécharger un PDF',
        EN: 'Download PDF',
    },
    delete: {
        FR: 'Supprimer',
        EN: 'Delete',
    },
    helper: {
        FR: `Une ou plusieurs navigation non terminées sont actuellement sélectionnées, c'est pourquoi l'export ne peut pas être effectué.`,
        EN: `One or more unfinished navigation are currently selected, that's why the export can't be done.`,
    },
}
