export const inqueriesConstants = {
    REQUEST_PERSONAL_PARKING_INQUERIES: `REQUEST_PERSONAL_PARKING_INQUERIES`,
    REQUEST_PERSONAL_PARKING_INQUERIES_SUCCESS: `REQUEST_PERSONAL_PARKING_INQUERIES_SUCCESS`,
    REQUEST_PERSONAL_PARKING_INQUERIES_FAILURE: `REQUEST_PERSONAL_PARKING_INQUERIES_FAILURE`,
    REQUEST_RECEIVED_PARKING_INQUERIES: `REQUEST_RECEIVED_PARKING_INQUERIES`,
    REQUEST_RECEIVED_PARKING_INQUERIES_SUCCESS: `REQUEST_RECEIVED_PARKING_INQUERIES_SUCCESS`,
    REQUEST_RECEIVED_PARKING_INQUERIES_FAILURE: `REQUEST_RECEIVED_PARKING_INQUERIES_FAILURE`,
    REQUEST_PERSONAL_INSURANCE_INQUERIES: `REQUEST_PERSONAL_INSURANCE_INQUERIES`,
    REQUEST_PERSONAL_INSURANCE_INQUERIES_SUCCESS: `REQUEST_PERSONAL_INSURANCE_INQUERIES_SUCCESS`,
    REQUEST_PERSONAL_INSURANCE_INQUERIES_FAILURE: `REQUEST_PERSONAL_INSURANCE_INQUERIES_FAILURE`,
}
