import React from 'react'
import dictionary from './Dictionary/CommentDico.js'
import styles from './Styles/CommentCss'
import { Paper, Typography, withStyles } from '@material-ui/core'
import BoatOnComponent from '../common/BoatOnComponent.js'
import StarIcon from '@material-ui/icons/Star'
import google from '../../images/pricesPage/googlelogo.png'

class Comment extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary

        this.state = {
            byYear: false,
        }

        this._changeTab = this._changeTab.bind(this)
    }

    _changeTab() {
        this.setState({ byYear: !this.state.byYear })
    }

    render() {
        const { classes, descr, nom, photo, date } = this.props

        return (
            <Paper elevation={1} className={classes.container}>
                <div className={classes.header}>
                    <img
                        className={classes.pdp}
                        src={photo}
                        alt="profilepic"
                        title="profilepic"
                    />
                    <Typography className={classes.nom}> {nom} </Typography>
                    <img
                        className={classes.google}
                        src={google}
                        alt="google"
                        title="google"
                    />
                </div>
                <Typography className={classes.descr}> {descr} </Typography>

                <div className={classes.meta}>
                    <Typography> {date} </Typography>
                    <StarIcon className={classes.icon} />
                    <Typography> 5 </Typography>
                </div>
            </Paper>
        )
    }
}

export default withStyles(styles)(Comment)
