export default {
    download: {
        FR: 'Télécharger',
        EN: 'Download',
    },
    loading: {
        FR: `Chargement...`,
        EN: `Loading...`,
    },
}
