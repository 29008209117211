export default theme => ({
    root: {
        padding: '0 20px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 15,
    },
    message: {
        fontSize: 14,
        color: '#212121',
    },
})
