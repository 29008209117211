export default theme => ({
    root: {
        padding: `0px 50px`,
        [theme.breakpoints.down('sm')]: {
            padding: '0',
        },
    },
    backLink: {
        color: `rgba(0, 0, 0, 0.54)`,
        textDecoration: `none`,
    },
    body: {
        marginTop: `16px`,
    },
})
