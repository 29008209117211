export const bobEventsConstants = {
    REQUEST_BOBEVENTS: 'REQUEST_BOBEVENTS',
    PUT_BOBEVENTS: 'PUT_BOBEVENTS',
    POST_BOBEVENTS: 'POST_BOBEVENTS',
    BOBEVENTS_SUCCESS: 'BOBEVENTS_SUCCESS',
    BOBEVENTS_FAILURE: 'BOBEVENTS_FAILURE',
    BOBEVENTS_SETNEWEVENT: 'BOBEVENTS_SETNEWEVENT',
    BOBEVENTS_UNSETNEWEVENT: 'BOBEVENTS_UNSETNEWEVENT',
    PUT_BOBEVENTS_SUCCESS: 'PUT_BOBEVENTS_SUCCESS',
    POST_BOBEVENTS_SUCCESS: 'POST_BOBEVENTS_SUCCESS',
    DELETE_BOBEVENTS_SUCCESS: 'DELETE_BOBEVENTS_SUCCESS',
    RESET: `RESET`,
    UPDATE_USEREQUIPMENT_NAME_SUCCESS: `UPDATE_USEREQUIPMENT_NAME_SUCCESS`,
    BOBEVENTS_PUT_FAILURE: `BOBEVENTS_PUT_FAILURE`,
    UPDATE_USERPART_NAME_SUCCESS: `UPDATE_USERPART_NAME_SUCCESS`,
    UPDATE_EQUIPMENTTYPE_NAME_SUCCESS: `UPDATE_EQUIPMENTTYPE_NAME_SUCCESS`,
    UPDATE_PARTTYPE_NAME_SUCCESS: `UPDATE_PARTTYPE_NAME_SUCCESS`,
}
