export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #c4c4c4',
        padding: '10px',
        margin: '10px',
        marginTop: '10px',
        width: 'calc(85% - 30px)',
        maxWidth: 1025,
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            width: '100%',
            margin: 0,
            padding: 0,
        },
    },
    filter: {
        marginLeft: '30px',
        marginRight: '20px',
        width: 'calc(100% - 52px)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 45px)',
            marginRight: '15px',
            marginLeft: '30px',
        },
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% - 35px)',
            marginRight: '5px',
            marginLeft: '30px',
        },
    },
    textField: {
        width: 'calc(30% - 20px)',
        minWidth: '100px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
        },
    },
    datePicker: {},
    input: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
            padding: 0,
            paddingTop: '10px',
            paddingBottom: '10px',
            paddingLeft: '4px',
        },
    },
    labelInput: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    marginInput: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    line: {
        minHeight: '200px',
        maxHeight: '400px',
        height: '400px',
    },
    //** DateRange */
    rootDateRange: {
        display: 'flex',
        flexFlow: 'row wrap',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    // page: {
    //     display: 'flex',
    //     flexFlow: 'row wrap',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     width: '100%',
    //     [theme.breakpoints.down('sm')]: {
    //         justifyContent: 'space-between',
    //     },
    // },
    pageRightAlign: {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'flex-end',
        },
    },
    startDate: {
        marginBottom: '10px',
        marginTop: '10px',
        width: '115px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    endDate: {
        marginLeft: '5px',
        width: '115px',
        marginBottom: '10px',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            marginLeft: 0,
        },
    },
    paper: {
        marginTop: '250px',
        marginLeft: '27%',
        [theme.breakpoints.down('xs')]: {
            marginTop: '69px',
            maxWidth: '100vh',
            marginLeft: '0%',
            // '& .MuiPaper-root ': {},
            // '& .MuiPopover-paper': {},
        },
    },
    rootPaper: {
        [theme.breakpoints.down('xs')]: {
            '& .MuiPopover-paper ': {
                position: 'initial',
                borderRadius: '12px',
            },
        },
    },
})
