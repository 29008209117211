export const companiesConstants = {
    GET_VAT_TYPES: `GET_VAT_TYPES`,
    GET_VAT_TYPES_SUCCESS: `GET_VAT_TYPES_SUCCESS`,
    GET_VAT_TYPES_FAILED: `GET_VAT_TYPES_FAILED`,
    REQUEST_CREATE_COMPANY: `REQUEST_CREATE_COMPANY`,
    REQUEST_CREATE_COMPANY_SUCCESS: `REQUEST_CREATE_COMPANY_SUCCESS`,
    REQUEST_CREATE_COMPANY_FAILED: `REQUEST_CREATE_COMPANY_FAILED`,
    REQUEST_UPDATE_COMPANY: `REQUEST_UPDATE_COMPANY`,
    REQUEST_UPDATE_COMPANY_SUCCESS: `REQUEST_UPDATE_COMPANY_SUCCESS`,
    REQUEST_UPDATE_COMPANY_FAILED: `REQUEST_UPDATE_COMPANY_FAILED`,
    REQUEST_GET_COMPANY: `REQUEST_GET_COMPANY`,
    REQUEST_GET_COMPANY_SUCCESS: `REQUEST_GET_COMPANY_SUCCESS`,
    REQUEST_GET_COMPANY_FAILED: `REQUEST_GET_COMPANY_FAILED`,
    REQUEST_GET_SIREN_BY_COMPANY: `REQUEST_GET_SIREN_BY_COMPANY`,
    REQUEST_GET_SIREN_BY_COMPANY_SUCCESS: `REQUEST_GET_SIREN_BY_COMPANY_SUCCESS`,
    REQUEST_GET_SIREN_BY_COMPANY_FAILED: `REQUEST_GET_SIREN_BY_COMPANY_FAILED`,
}
