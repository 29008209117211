export default theme => ({
    radioGroup: {
        flexDirection: 'row',
    },
    radioBtn: {
        '&.Mui-checked': {
            color: theme.palette.primary.main,
        },
    },
    text: {
        fontSize: 14,
        minHeight: '20vh',
    },
    mmsiContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
})
