import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import BoatOnModalCore from '../BoatOnModalCore'
import styles from './Styles/RenderAccountCreateCss'
import dictionary from './Dictionary/RenderAccountCreateDico'
import InputAdornment from '@material-ui/core/InputAdornment'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import EmailIcon from '@material-ui/icons/Email'
import LockIcon from '@material-ui/icons/Lock'
import { userActions } from '../../../actions'
import { isValidPhoneNumber } from 'react-phone-number-input'
import PhoneField from '../PhoneField'
import { Checkbox } from '@material-ui/core'
import { Typography } from '@material-ui/core'

class RenderAccountCreate extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            showPassword: false,
            firstNameCreate: '',
            lastNameCreate: '',
            emailCreate: '',
            phoneCreate: '',
            passwordCreate: '',
            passwordCreateValid: '',
            focused: false,
        }

        this.handleChange = this.handleChange.bind(this)
        this.signin = this.signin.bind(this)
        this.valid = this.valid.bind(this)
    }

    handleChange(value, key) {
        this.setState({ focused: key })
        this.setState({ [key]: value })
    }

    signin() {
        const { valid } = this.props
        new Promise((resolve, reject) => {
            this.props.dispatch(
                userActions.signin(
                    {
                        email: this.state.emailCreate,
                        password: this.state.passwordCreate.trim(),
                        firstName: this.state.firstNameCreate,
                        lastName: this.state.lastNameCreate,
                        contactDetails: [
                            {
                                contact: this.state.phoneCreate,
                                contactType: { id: 1 },
                            },
                        ],
                    },
                    this.context,
                    resolve,
                    reject,
                ),
            )
        })
            .then(() => valid('boatInfos'))
            .catch(() => valid('personnalInfos'))
    }

    valid(e) {
        this.setState({ focused: false })
        e.preventDefault()
        if (
            /^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(
                this.state.emailCreate,
            ) &&
            this.state.passwordCreate.length >= 6 &&
            this.state.passwordCreateValid === this.state.passwordCreate &&
            this.state.phoneCreate &&
            isValidPhoneNumber(this.state.phoneCreate)
        ) {
            this.signin()
        }
    }

    _body() {
        const { classes, userLoading } = this.props
        return (
            <form id="form-signin" onSubmit={this.valid}>
                <div id="firstEtape" className={classes.modal}>
                    <TextField
                        id="firstName"
                        label={this.displayText('firstName')}
                        margin="normal"
                        placeholder="Paul"
                        required
                        variant="outlined"
                        value={this.state.firstNameCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'firstNameCreate')
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AssignmentIndIcon />
                                </InputAdornment>
                            ),
                        }}
                        disabled={userLoading > 0}
                    />
                    <TextField
                        id="lastName"
                        label={this.displayText('lastName')}
                        margin="normal"
                        placeholder="Grenier"
                        required
                        variant="outlined"
                        value={this.state.lastNameCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'lastNameCreate')
                        }}
                        fullWidth
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <AssignmentIndIcon />
                                </InputAdornment>
                            ),
                        }}
                        disabled={userLoading > 0}
                    />
                    <TextField
                        id="email"
                        label={this.displayText('email')}
                        margin="normal"
                        placeholder="paulgrenier@email.com"
                        error={
                            !/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(
                                this.state.emailCreate,
                            ) &&
                            this.state.emailCreate !== '' &&
                            this.state.focused !== 'emailCreate'
                        }
                        helperText={
                            !/^\w+([-.]?\w+)*@\w+([-.]?\w+)*(\.\w{2,3})+$/.test(
                                this.state.emailCreate,
                            ) &&
                            this.state.emailCreate !== '' &&
                            this.state.focused !== 'emailCreate'
                                ? this.displayText('invalidEmail')
                                : ''
                        }
                        required
                        variant="outlined"
                        value={this.state.emailCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'emailCreate')
                        }}
                        fullWidth
                        inputProps={{
                            spellcheck: 'false',
                            autocomplete: 'off',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <EmailIcon />
                                </InputAdornment>
                            ),
                        }}
                        disabled={userLoading > 0}
                    />
                    <PhoneField
                        value={this.state.phoneCreate}
                        placeholder="+33 6 06 06 06 06"
                        onChange={value => {
                            this.handleChange(value, 'phoneCreate')
                        }}
                        error={
                            (this.state.passwordCreate !== '' ||
                                this.state.passwordCreateValid !== '') &&
                            !isValidPhoneNumber(this.state.phoneCreate || '') &&
                            this.state.focused !== 'phoneCreate'
                        }
                        disabled={userLoading > 0}
                    />
                    <TextField
                        id="password"
                        label={this.displayText('password')}
                        margin="normal"
                        type="password"
                        required
                        variant="outlined"
                        placeholder="**********"
                        error={
                            this.state.passwordCreate.length < 6 &&
                            this.state.passwordCreate !== '' &&
                            this.state.focused !== 'passwordCreate'
                        }
                        helperText={
                            this.state.passwordCreate.length < 6 &&
                            this.state.passwordCreate !== '' &&
                            this.state.focused !== 'passwordCreate'
                                ? this.displayText('nbCharacterPassword')
                                : ''
                        }
                        value={this.state.passwordCreate}
                        onChange={e => {
                            this.handleChange(e.target.value, 'passwordCreate')
                        }}
                        fullWidth
                        inputProps={{
                            spellcheck: 'false',
                            autocomplete: 'off',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                        }}
                        disabled={userLoading > 0}
                    />
                    <TextField
                        id="password"
                        label={this.displayText('repeatPassword')}
                        margin="normal"
                        type="password"
                        required
                        variant="outlined"
                        placeholder="**********"
                        error={
                            this.state.passwordCreate !==
                                this.state.passwordCreateValid &&
                            this.state.passwordCreateValid !== '' &&
                            this.state.focused !== 'passwordCreateValid'
                        }
                        helperText={
                            this.state.passwordCreate !==
                                this.state.passwordCreateValid &&
                            this.state.passwordCreateValid !== '' &&
                            this.state.focused !== 'passwordCreateValid'
                                ? this.displayText('diffPassword')
                                : ''
                        }
                        value={this.state.passwordCreateValid}
                        onChange={e => {
                            this.handleChange(
                                e.target.value,
                                'passwordCreateValid',
                            )
                        }}
                        fullWidth
                        inputProps={{
                            spellcheck: 'false',
                            autocomplete: 'off',
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <LockIcon />
                                </InputAdornment>
                            ),
                        }}
                        disabled={userLoading > 0}
                    />
                    <div className={classes.checkTerms}>
                        <Checkbox
                            style={{ display: 'flex', textAlign: 'center' }}
                            required
                            disabled={userLoading > 0}
                        />

                        <Typography className={classes.terms}>
                            {this.displayText('approuve')}{' '}
                            <a
                                style={{
                                    flexWrap: 'wrap',
                                    color: '#3040a0',
                                }}
                                href="https://www.info.boaton.fr/cgv"
                            >
                                {this.displayText('termsOfUse')}
                            </a>
                        </Typography>
                    </div>
                    <div className={classes.checkTerms}>
                        <Checkbox
                            style={{ display: 'flex', textAlign: 'center' }}
                            disabled={userLoading > 0}
                        />

                        <Typography className={classes.terms}>
                            {this.displayText('receiveEmail')}
                        </Typography>
                    </div>
                </div>
            </form>
        )
    }

    render() {
        const { title, userLoading } = this.props

        return (
            <>
                {this._renderTitle(title)}
                {this._renderBody({
                    body: this._body(),
                })}
                {this._renderActions([
                    {
                        label: this.displayText('register'),
                        form: 'form-signin',
                        disabled: userLoading > 0,
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        userLoading: state.authentication.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(RenderAccountCreate))
