export const blockConstants = {
    REQUEST_GROUP: 'REQUEST_GROUP',
    REQUEST_GROUP_SUCCESS: 'REQUEST_GROUP_SUCCESS',
    REQUEST_GROUP_FAILURE: 'REQUEST_GROUP_FAILURE',
    PERSONNAL_GROUP: 'PERSONNAL_GROUP',
}

export const Actions = {
    Read: 'read',
    Create: 'create',
    Update: 'update',
    Delete: 'delete',
}

export const PermissionsEntities = {
    Boat: 'Boat',
    LinkRoleGroupUser: 'LinkRoleGroupUser',
    Checkup: 'Checkup',
    InventoryDetail: 'InventoryDetail',
    Transaction: 'Transaction',
    File: 'File',
    Navigation: 'Navigation',
    WorkTime: 'WorkTime',
    Absence: 'Absence', // for now Absence is not used, we use the WorkTime for both
    Order: 'Order',
    WorkTimeSetting: 'WorkTimeSetting',
    PaidVacationSetting: 'PaidVacationSetting',
}

export const PermissionsPageTypeIds = {
    Fleet: 8,
    Calendar: 11,
    BoatOnBookChrono: 2,
}
