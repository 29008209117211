import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import styles from './Styles/ModalAmountItCheckCss'
import dictionary from './Dictionary/ModalAmountItCheckDico'
import BoatOnModalCore from '../BoatOnModalCore'

class ModalAmountItCheck extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.save = this.save.bind(this)
    }

    async save() {
        await this.props.isConfirmedToggler()
        await this.props.save()
        this.props.handleCloseCheckModal()
    }

    _body() {
        return (
            <>
                <Typography>{this.displayText('checkAmountTitle')}</Typography>
                <Typography>{this.displayText('confirmAmount')}</Typography>
            </>
        )
    }

    render() {
        return (
            <>
                {this._renderTitle('Confirmation de montant')}
                {this._renderBody({
                    body: this._body(),
                })}
                {this._renderActions([
                    {
                        label: this.displayText('answer_yes'),
                        action: this.save,
                    },
                    {
                        label: this.displayText('answer_no'),
                        action: this.props.handleCloseCheckModal,
                        style: { margin: '1rem' },
                    },
                ])}
            </>
        )
    }
}

export default withStyles(styles)(ModalAmountItCheck)
