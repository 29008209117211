import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/DisplayerImgModalCss'
import Clear from '@material-ui/icons/Clear'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import BoatOnImageRender from '../common/BoatOnImageRender'
import BoatOnModalCore from '../common/BoatOnModalCore'
import BoatOnModal from '../common/BoatOnModal'
import BoatOnComponent from '../common/BoatOnComponent'

class DisplayerImgModal extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.state = {
            openDisplayerImg: false,
        }

        this.handleCloseDisplayer = this.handleCloseDisplayer.bind(this)
        this.handleOpenDisplayer = this.handleOpenDisplayer.bind(this)
    }

    componentDidMount() {
        let file = []
        if (this.props.file) file = this.props.file
        this.setState({ files: file })
    }

    componentDidUpdate(prevProps) {
        let file = []
        if (this.props.file) file = this.props.file
        if (prevProps.file && file && prevProps.file.length !== file.length) {
            this.setState({ files: file })
        }
    }

    handleCloseDisplayer() {
        this.setState({ openDisplayerImg: false, index: 0 })
    }

    handleOpenDisplayer(index) {
        this.setState({ openDisplayerImg: 'displayImg', index })
    }

    render() {
        const { classes, loadingImage, deleteDocumentNoUp } = this.props
        const { index, openDisplayerImg, files } = this.state

        if (loadingImage) {
            return this.renderLoading('100')
        }

        return (
            <div className={classes.allPhotos}>
                {files &&
                    files.map((img, i) => {
                        let pdf = false

                        if (img && img.link) {
                            let full = img.link.split('.')

                            if (full[full.length - 1] === 'pdf') pdf = true
                        } else if (
                            img &&
                            img.infos &&
                            img.infos.type === 'application/pdf'
                        )
                            pdf = true

                        return (
                            <div key={i} className={classes.images}>
                                <div key={i} className={classes.pictures}>
                                    <div
                                        key={i}
                                        className={classes.importantImage}
                                    >
                                        {deleteDocumentNoUp && (
                                            <Clear
                                                className={classes.cross}
                                                onClick={() =>
                                                    deleteDocumentNoUp(i)
                                                }
                                            />
                                        )}
                                        {pdf ? (
                                            <div
                                                key={i}
                                                onClick={() =>
                                                    this.handleOpenDisplayer(i)
                                                }
                                            >
                                                <PictureAsPdfIcon
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                <div
                                                    style={{
                                                        backgroundImage: `url(${
                                                            img.link
                                                                ? img.link
                                                                : img.blop
                                                        })`,
                                                        width: '100%',
                                                        height: '100%',
                                                        opacity: '0.5',
                                                        backgroundPosition:
                                                            'center',
                                                        backgroundSize: 'cover',
                                                    }}
                                                />
                                                <img
                                                    src={
                                                        img.link
                                                            ? img.link
                                                            : img.blop
                                                    }
                                                    alt={
                                                        img.link
                                                            ? img.link
                                                            : img.blop
                                                    }
                                                    style={{
                                                        maxWidth: '100%',
                                                        maxHeight: '100%',
                                                        margin: 'auto',
                                                        position: 'absolute',
                                                        top: 0,
                                                        bottom: 0,
                                                        right: 0,
                                                        left: 0,
                                                    }}
                                                    onClick={() =>
                                                        this.handleOpenDisplayer(
                                                            i,
                                                        )
                                                    }
                                                />
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                <BoatOnModal
                    openCondition={openDisplayerImg}
                    handleClose={this.handleCloseDisplayer}
                    modalCores={{
                        displayImg: (
                            <BoatOnModalCore
                                body={
                                    <BoatOnImageRender
                                        vertical
                                        multi
                                        bottomImage
                                        imgs={files ? files.slice() : []}
                                        index={index}
                                    />
                                }
                            />
                        ),
                    }}
                    titles={{ displayImg: 'Mes documents' }}
                />
            </div>
        )
    }
}

/**
 * This component will display files and user can click on it to display it bigger
 *
 * @class DisplayerImgModal
 * @extends {BoatOnComponent}
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {Boolean} loadingImage - if true, add loading effect.
 * @param {[File]} file - array of the files to display.
 * @param {DeleteDocumentNoUp} deleteDocumentNoUp - Callback to delete card.
 *
 * @region ```
 * Object
 * ```
 * @typedef {Object} File
 * @property {String} link - optional (required if from bdd): url of the file.
 * @property {String} id - optional (required if from bdd): id of the file.
 * @property {String} blop - optional (required if not in bdd): blop of the file.
 * @property {String} infos - optional (required if not in bdd): infos of the file.
 *
 * @region ```
 * Callbacks
 * ```
 *
 * @callback DeleteDocumentNoUp
 * @param {Number} index - index of the file.
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 11/06/2024 - 12:20
 * @author Samuel.C
 */
export default withStyles(styles)(DisplayerImgModal)
