export default theme => ({
    container: {
        height: 474,
        padding: 24,
        boxSizing: `border-box`,
        width: `100%`,
    },
    title: {
        fontWeight: `bold`,
        marginBottom: 24,
        fontSize: `16px`,
    },
    field: {
        width: `100%`,
        color: `black`,
        marginTop: 0,
        '& .MuiSelect-selectMenu': {
            display: `flex`,
            alignItems: `center`,
            justifyContent: `space-between`,
        },
    },
    menuItemRole: {
        display: `flex`,
        alignItems: `center`,
        justifyContent: `space-between`,
    },
    advice: {
        color: `#838EA4`,
        fontSize: `12px`,
        marginBottom: 40,
    },
    button: {
        float: `right`,
        height: 52,
        width: 122,
    },
})
