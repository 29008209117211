export default theme => ({
    root: {
        padding: 15,
        border: 'solid 1px rgba(0, 0, 0, 0.1)',
        borderRadius: 15,
        marginBottom: 15,
        cursor: 'pointer',
        minHeight: 200,
        display: 'flex',
        flexDirection: 'column',
    },

    header: {
        display: 'flex',
        flexDirection: 'row',
        height: 40,
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: 'solid 1px rgba(0, 0, 0, 0.1)',
        paddingBottom: 5,
        marginBottom: 10,
    },
    headerLeft: {
        display: 'flex',
        flexDirection: 'row',
    },
    boatIcon: {
        height: 25,
        marginRight: 10,
        margin: 'auto',
    },
    title: {
        fontWeight: 600,
        fontSize: 16,
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
        },
    },
    subtitle: {
        fontSize: 12,
        fontWeight: 400,
        color: '#7B7D7E',
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
        },
    },

    infoWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 10,
    },
    infoLabel: {
        fontSize: 12,
        fontWeight: 400,
        color: '#7B7D7E',
        marginBottom: 5,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            marginBottom: 0,
        },
    },
    infoValue: {
        fontWeight: 400,
        fontSize: 14,
        [theme.breakpoints.down('sm')]: {
            fontSize: 10,
        },
    },
    dataWrapper: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
    },
    data: {
        display: 'grid',
        flex: 2,
        columnGap: 20,
        gridTemplateAreas: `
            "distance captain passengers"
            "navigation-time crew passengers"
        `,

        [theme.breakpoints.down('sm')]: {
            flex: 1,
            gridTemplateAreas: `
                "distance"
                "navigation-time"
                "captain"
                "passengers"
            `,
        },
    },
    dataStatus: {
        display: 'grid',
        flex: 2,
        columnGap: 20,
        gridTemplateAreas: `
            "status captain passengers"
            "distance captain passengers"
            "navigation-time crew passengers"
        `,

        [theme.breakpoints.down('sm')]: {
            flex: 1,
            gridTemplateAreas: `
                "status"
                "distance"
                "navigation-time"
                "captain"
                "passengers"
            `,
        },
    },
    dataNoDistance: {
        display: 'grid',
        flex: 2,
        columnGap: 20,
        gridTemplateAreas: `
            "captain navigation-time passengers"
            "crew . passengers"
        `,

        [theme.breakpoints.down('sm')]: {
            flex: 1,
            gridTemplateAreas: `
                "navigation-time"
                "captain"
                "passengers"
            `,
        },
    },
    dataNoDistanceStatus: {
        display: 'grid',
        flex: 2,
        columnGap: 20,
        gridTemplateAreas: `
            "status navigation-time passengers"
            "captain navigation-time passengers"
            "crew . passengers"
        `,

        [theme.breakpoints.down('sm')]: {
            flex: 1,
            gridTemplateAreas: `
                "status"
                "navigation-time"
                "captain"
                "passengers"
            `,
        },
    },
    navigationStatus: {
        padding: '4px 8px',
        borderRadius: 4,
        width: 'fit-content',
        height: 'fit-content',
        color: 'white',
        fontSize: 10,
    },
    notStarted: {
        backgroundColor: theme.palette.warning.main,
    },
    inProgress: {
        backgroundColor: theme.palette.info.main,
    },
    captainWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 10,
        fontSize: 14,
    },
    captainBadge: {
        background: '#E5E7F0',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        width: 20,
        height: 20,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: 8,
        overflow: 'hidden',
        marginRight: 5,
    },
    badgesContainer: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 5,
    },
    badge: {
        background: '#E5E7F0',
        border: '1px solid',
        borderColor: theme.palette.primary.main,
        color: theme.palette.primary.main,
        width: 20,
        height: 20,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: 8,
        overflow: 'hidden',
        marginLeft: -5,
    },
    placeContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 8,
    },
    passengers: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontWeight: 400,
        fontSize: 14,
    },
    passengersIcon: {
        height: 17,
        marginRight: 5,
        marginLeft: 5,
        [theme.breakpoints.down('sm')]: {
            height: 16,
        },
    },
    stepContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingRight: 30,
    },
    stepName: {
        color: theme.palette.primary.main,
        fontSize: 14,
        width: '100%',
        fontWeight: 500,
    },
    placeIcon: {
        color: theme.palette.primary.main,
        height: '14px !important',
        width: '14px !important',
    },
    seats: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 14,
        justifySelf: 'start',
        width: '50%',
    },
    seatIcon: {
        height: '16px !important',
        width: '16px !important',
    },

    mapWrapper: {
        flex: 1,
        height: 150,
        '& .leaflet-container': {
            height: 150,
            minHeight: 0,
        },
        '& .leaflet-control-zoom': {
            display: 'none',
        },
    },
})
