import { chronoConstants } from '../../constants/chrono.constants'
import { requestApi } from '../../services/api.service'

const getCurrentWorkTime = userLinkId => {
    return dispatch => {
        dispatch({ type: chronoConstants.CURRENT_WORK_TIME_GET })

        return requestApi(
            `/work-times/current-work-time/user-link/${userLinkId}`,
            'GET',
            null,
        )
            .then(
                result => {
                    if (result.status === 404) {
                        dispatch({
                            type: chronoConstants.CURRENT_WORK_TIME_FAILURE,
                            workTime: {},
                        })
                    }
                    dispatch({
                        type: chronoConstants.CURRENT_WORK_TIME_SUCCESS,
                        workTime: result,
                    })
                },
                error => {
                    dispatch({
                        type: chronoConstants.CURRENT_WORK_TIME_FAILURE,
                        workTime: {},
                    })
                },
            )
            .catch(error => {
                dispatch({ type: chronoConstants.CURRENT_WORK_TIME_FAILURE })
            })
    }
}

const start = (body, userLinkId) => {
    return dispatch => {
        dispatch({ type: chronoConstants.START_SEND })

        return requestApi(
            `/work-times/start/user-link/${userLinkId}`,
            `POST`,
            body,
            false,
            false,
        )
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    dispatch({ type: chronoConstants.START_SUCCESS })
                    dispatch(getCurrentWorkTime(userLinkId))
                    return result
                },
                error => {
                    console.error(error)
                    dispatch({ type: chronoConstants.START_FAILURE })
                    return null
                },
            )
            .catch(error => {
                console.error(error)
                dispatch({ type: chronoConstants.START_FAILURE })
            })
    }
}

const stop = (body, userLinkId) => {
    return dispatch => {
        dispatch({ type: chronoConstants.STOP_SEND })

        return requestApi(
            `/work-times/stop/user-link/${userLinkId}`,
            `PUT`,
            body,
            false,
            false,
        )
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    dispatch({ type: chronoConstants.STOP_SUCCESS })
                    dispatch(getCurrentWorkTime(userLinkId))
                    return result
                },
                error => {
                    console.error(error)
                    dispatch({ type: chronoConstants.STOP_FAILURE })
                    return null
                },
            )
            .catch(error => {
                console.error(error)
                dispatch({ type: chronoConstants.STOP_FAILURE })
            })
    }
}

const pause = (body, userLinkId) => {
    return dispatch => {
        dispatch({ type: chronoConstants.BREAK_SEND })

        return requestApi(
            `/work-times/break/user-link/${userLinkId}`,
            `PUT`,
            body,
            false,
            false,
        )
            .then(
                result => {
                    if (!result || result.status === 404) return null
                    dispatch({
                        type: chronoConstants.BREAK_SUCCESS,
                    })
                    dispatch(getCurrentWorkTime(userLinkId))
                    // return result
                },
                error => {
                    console.error(error)
                    dispatch({ type: chronoConstants.BREAK_FAILURE })
                    return null
                },
            )
            .catch(error => {
                console.error(error)
                dispatch({ type: chronoConstants.BREAK_FAILURE })
            })
    }
}

const restart = (body, userLinkId) => {
    return dispatch => {
        dispatch({ type: chronoConstants.RESTART_SEND })

        return requestApi(
            `/work-times/restart/user-link/${userLinkId}`,
            `PUT`,
            body,
            false,
            false,
        )
            .then(
                async result => {
                    if (!result || result.status === 404) return null
                    dispatch({ type: chronoConstants.RESTART_SUCCESS })
                    dispatch(getCurrentWorkTime(userLinkId))
                },
                error => {
                    console.error(error)
                    dispatch({ type: chronoConstants.RESTART_FAILURE })
                    return null
                },
            )
            .catch(error => {
                console.error(error)
                dispatch({ type: chronoConstants.RESTART_FAILURE })
            })
    }
}

export const chronoActions = {
    getCurrentWorkTime,
    start,
    stop,
    pause,
    restart,
}
