import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/RepairValidatorCss'
import dictionary from './Dictionary/RepairValidatorDico'
import { Typography } from '@material-ui/core'

class RepairValidator extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary
    }

    renderLabel() {
        const { classes, status } = this.props

        if (!status) return <></>

        const { late, valid } = status

        if (late) {
            return (
                <Typography
                    className={`${classes.rootLabel} ${classes.redLabel}`}
                >
                    {this.displayText('late').toUpperCase()}
                </Typography>
            )
        } else if (valid) {
            return (
                <Typography
                    className={`${classes.rootLabel} ${classes.greenLabel}`}
                >
                    {this.displayText('valid').toUpperCase()}
                </Typography>
            )
        } else {
            return <></>
        }
    }

    render() {
        const { classes } = this.props

        return <div className={classes.root}>{this.renderLabel()}</div>
    }
}

export default withStyles(styles)(RepairValidator)
