export const inqueryConstants = {
    CHECK_AVAILABILITY: `CHECK_AVAILABILITY`,
    CHECK_AVAILABILITY_SUCCESS: `CHECK_AVAILABILITY_SUCCESS`,
    CHECK_AVAILABILITY_FAILED: `CHECK_AVAILABILITY_FAILED`,
    RESET_AVAILABILITY: `RESET_AVAILABILITY`,
    CREATE_INQUERY_PARKING: `CREATE_INQUERY_PARKING`,
    CREATE_INQUERY_PARKING_SUCCESS: `CREATE_INQUERY_PARKING_SUCCESS`,
    CREATE_INQUERY_PARKING_FAILED: `CREATE_INQUERY_PARKING_FAILED`,
    REQUEST_INQUERY: `REQUEST_INQUERY`,
    REQUEST_INQUERY_SUCCESS: `REQUEST_INQUERY_SUCCESS`,
    REQUEST_INQUERY_FAILED: `REQUEST_INQUERY_FAILED`,
    ACCEPT_INQUERY: `ACCEPT_INQUERY`,
    ACCEPT_INQUERY_SUCCESS: `ACCEPT_INQUERY_SUCCESS`,
    ACCEPT_INQUERY_FAILED: `ACCEPT_INQUERY_FAILED`,
    REFUSE_INQUERY: `REFUSE_INQUERY`,
    REFUSE_INQUERY_SUCCESS: `REFUSE_INQUERY_SUCCESS`,
    REFUSE_INQUERY_FAILED: `REFUSE_INQUERY_FAILED`,
    CANCEL_INQUERY: `CANCEL_INQUERY`,
    CANCEL_INQUERY_SUCCESS: `CANCEL_INQUERY_SUCCESS`,
    CANCEL_INQUERY_FAILED: `CANCEL_INQUERY_FAILED`,
    SET_INQUERY: `SET_INQUERY`,
    UNSET_INQUERY: `UNSET_INQUERY`,
    GET_SESSION_URL: `GET_SESSION_URL`,
    GET_SESSION_URL_SUCCESS: `GET_SESSION_URL_SUCCESS`,
    GET_SESSION_URL_FAILED: `GET_SESSION_URL_FAILED`,
    CREATE_INQUERY_INSURANCE: `CREATE_INQUERY_INSURANCE`,
    CREATE_INQUERY_INSURANCE_SUCCESS: `CREATE_INQUERY_INSURANCE_SUCCESS`,
    CREATE_INQUERY_INSURANCE_FAILED: `CREATE_INQUERY_INSURANCE_FAILED`,
}
