export default {
    insuranceOffer: {
        FR: 'Mon assurance',
        EN: 'Insurance',
    },
    findParking: {
        FR: 'Mon stockage',
        EN: 'My storage',
    },
    myRepairs: {
        FR: 'Mes entretiens',
        EN: 'My repairs',
    },
}
