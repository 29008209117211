export default theme => ({
    container: {
        height: `fit-content`,
        padding: 24,
    },
    title: {
        fontWeight: 700,
        fontSize: `16px`,
        marginBottom: 34,
    },
    button2: {
        height: 52,
        width: 164,
    },
    buttons: {
        display: `flex`,
        justifyContent: `flex-end`,
        alignItems: `center`,
        width: `100%`,
        marginTop: 42,
    },
    button1: {
        fontWeight: 700,
        color: `#212121`,
        opacity: 0.5,
        marginRight: 28,
        fontSize: `16px`,
        '&:hover': {
            cursor: `pointer`,
        },
    },
})
