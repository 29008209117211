export default {
    insurance: {
        FR: 'Assurance',
        EN: 'Insurance',
    },
    receive: {
        FR: 'Reçue',
        EN: 'Receive',
    },
    sendToPro: {
        FR: 'Envoyé',
        EN: 'Send',
    },
    treated: {
        FR: 'Traitée',
        EN: 'Traited',
    },
    accepted: {
        FR: 'Accepté',
        EN: 'Accepted',
    },
    declined: {
        FR: 'Décliné',
        EN: 'Declined',
    },
    currentInsurer: {
        FR: 'Mon assureur actuel',
        EN: 'Current insurer',
    },
    dateEndContract: {
        FR: 'Date de fin de contrat',
        EN: 'Date of the contract end',
    },
    annualPremium: {
        FR: 'Prime annuelle',
        EN: 'Annual premium',
    },
    amountGood: {
        FR: 'Valeurs des biens assurés',
        EN: 'Value of insured assets',
    },
    seeOffers: {
        FR: 'Voir mes offres',
        EN: 'See offers',
    },
    info: {
        FR: 'Informations complémentaires',
        EN: 'Further information',
    },
    noMoreInfo: {
        FR: "Pas d'informations supplémentaires",
        EN: 'No further information',
    },
}
