export const settingsConstants = {
    GET_GROUP_WORKTIME_SETTINGS: 'GET_GROUP_WORKTIME_SETTINGS',
    GET_GROUP_WORKTIME_SETTINGS_SUCCESS: 'GET_GROUP_WORKTIME_SETTINGS_SUCCESS',
    GET_GROUP_WORKTIME_SETTINGS_FAILURE: 'GET_GROUP_WORKTIME_SETTINGS_FAILURE',
    POST_GROUP_WORKTIME_SETTINGS: 'POST_GROUP_WORKTIME_SETTINGS',
    POST_GROUP_WORKTIME_SETTINGS_SUCCESS:
        'POST_GROUP_WORKTIME_SETTINGS_SUCCESS',
    POST_GROUP_WORKTIME_SETTINGS_FAILURE:
        'POST_GROUP_WORKTIME_SETTINGS_FAILURE',
    PUT_GROUP_WORKTIME_SETTINGS: 'PUT_GROUP_WORKTIME_SETTINGS',
    PUT_GROUP_WORKTIME_SETTINGS_SUCCESS: 'PUT_GROUP_WORKTIME_SETTINGS_SUCCESS',
    PUT_GROUP_WORKTIME_SETTINGS_FAILURE: 'PUT_GROUP_WORKTIME_SETTINGS_FAILURE',
    DELETE_GROUP_WORKTIME_SETTINGS: 'DELETE_GROUP_WORKTIME_SETTINGS',
    DELETE_GROUP_WORKTIME_SETTINGS_SUCCESS:
        'DELETE_GROUP_WORKTIME_SETTINGS_SUCCESS',
    DELETE_GROUP_WORKTIME_SETTINGS_FAILURE:
        'DELETE_GROUP_WORKTIME_SETTINGS_FAILURE',
    GET_GROUP_PAID_VACATIONS_SETTINGS: 'GET_GROUP_PAID_VACATIONS_SETTINGS',
    GET_GROUP_PAID_VACATIONS_SETTINGS_SUCCESS:
        'GET_GROUP_PAID_VACATIONS_SETTINGS_SUCCESS',
    GET_GROUP_PAID_VACATIONS_SETTINGS_FAILURE:
        'GET_GROUP_PAID_VACATIONS_SETTINGS_FAILURE',
    POST_GROUP_PAID_VACATIONS_SETTINGS: 'POST_GROUP_PAID_VACATIONS_SETTINGS',
    POST_GROUP_PAID_VACATIONS_SETTINGS_SUCCESS:
        'POST_GROUP_PAID_VACATIONS_SETTINGS_SUCCESS',
    POST_GROUP_PAID_VACATIONS_SETTINGS_FAILURE:
        'POST_GROUP_PAID_VACATIONS_SETTINGS_FAILURE',
    PUT_GROUP_PAID_VACATIONS_SETTINGS: 'PUT_GROUP_PAID_VACATIONS_SETTINGS',
    PUT_GROUP_PAID_VACATIONS_SETTINGS_SUCCESS:
        'PUT_GROUP_PAID_VACATIONS_SETTINGS_SUCCESS',
    PUT_GROUP_PAID_VACATIONS_SETTINGS_FAILURE:
        'PUT_GROUP_PAID_VACATIONS_SETTINGS_FAILURE',
    DELETE_GROUP_PAID_VACATIONS_SETTINGS:
        'DELETE_GROUP_PAID_VACATIONS_SETTINGS',
    DELETE_GROUP_PAID_VACATIONS_SETTINGS_SUCCESS:
        'DELETE_GROUP_PAID_VACATIONS_SETTINGS_SUCCESS',
    DELETE_GROUP_PAID_VACATIONS_SETTINGS_FAILURE:
        'DELETE_GROUP_PAID_VACATIONS_SETTINGS_FAILURE',
}
