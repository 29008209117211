import sunny from '../../../../../../../src/images/weather/sunny.svg'
import sunny_cloudy from '../../../../../../../src/images/weather/sunny_cloudy.svg'
import sunny_rainy_snowy from '../../../../../../../src/images/weather/sunny_rainy_snowy.svg'
import sunny_rainy from '../../../../../../../src/images/weather/sunny_rainy.svg'
import sunny_snowy from '../../../../../../../src/images/weather/sunny_snowy.svg'
import sunny_snowy_2 from '../../../../../../../src/images/weather/sunny_snowy_2.svg'
import sunny_snowy_stormy from '../../../../../../../src/images/weather/sunny_snowy_stormy.svg'
import sunny_stormy from '../../../../../../../src/images/weather/sunny_stormy.svg'
import sunny_stormy_2 from '../../../../../../../src/images/weather/sunny_stormy_2.svg'
import sunny_veiled from '../../../../../../../src/images/weather/sunny_veiled.svg'
import veiled from '../../../../../../../src/images/weather/veiled.svg'
import veiled_night from '../../../../../../../src/images/weather/veiled_night.svg'
import clear_night from '../../../../../../../src/images/weather/clear_night.svg'
import cloudy_night from '../../../../../../../src/images/weather/cloudy_night.svg'
import cloudy from '../../../../../../../src/images/weather/cloudy.svg'
import rainy_snowy from '../../../../../../../src/images/weather/rainy_snowy.svg'
import rainy_stormy from '../../../../../../../src/images/weather/rainy_stormy.svg'
import rainy from '../../../../../../../src/images/weather/rainy.svg'
import rainy_2 from '../../../../../../../src/images/weather/rainy_2.svg'
import rainy_3 from '../../../../../../../src/images/weather/rainy_3.svg'
import snowy from '../../../../../../../src/images/weather/snowy.svg'
import snowy_2 from '../../../../../../../src/images/weather/snowy_2.svg'
import snowy_3 from '../../../../../../../src/images/weather/snowy_3.svg'
import stormy from '../../../../../../../src/images/weather/stormy.svg'
import stormy_2 from '../../../../../../../src/images/weather/stormy_2.svg'

const lowLevelPrecipitation = 2.5 // Threshold for low-level precipitation
const highLevelPrecipitation = 7.6 // Threshold for high-level precipitation
const lowLevelStormPressure = 1000 // Threshold for low-level storm
const highLevelStormPressure = 980 // Threshold for high-level storm
const lowLevelSnowDepth = 0.1 // Threshold for low-level snow
const highLevelSnowDepth = 0.3 // Threshold for high-level snow
const lowLevelCloudCover = 20 // Threshold for low-level cloud cover
const highLevelCloudCover = 80 // Threshold for high-level cloud cover
const lowLevelVisibility = 10 // Threshold for low-level visibility
const highLevelVisibility = 5 // Threshold for high-level visibility

const getColorTemperature = temperature => {
    const tier = [
        { max: -20, color: '#9532CA' },
        { max: -18, color: '#9400D5' },
        { max: -16, color: '#7F16D3' },
        { max: -14, color: '#712CD9' },
        { max: -12, color: '#5D45DF' },
        { max: -10, color: '#4A5CE0' },
        { max: -8, color: '#4773E3' },
        { max: -6, color: '#568BE7' },
        { max: -4, color: '#68A0F0' },
        { max: -2, color: '#78B7F4' },
        { max: 0, color: '#86CEF8' },
        { max: 2, color: '#7EFFD2' },
        { max: 4, color: '#6FF3B0' },
        { max: 6, color: '#5FE88D' },
        { max: 8, color: '#4BDE67' },
        { max: 10, color: '#36D243' },
        { max: 12, color: '#48D22C' },
        { max: 14, color: '#78DD21' },
        { max: 16, color: '#A5E815' },
        { max: 18, color: '#D2F30A' },
        { max: 20, color: '#FEFE01' },
        { max: 22, color: '#FED603' },
        { max: 24, color: '#FEB802' },
        { max: 26, color: '#FF9501' },
        { max: 28, color: '#FF7300' },
        { max: 30, color: '#FE5501' },
        { max: 32, color: '#F23C00' },
        { max: 34, color: '#D92E00' },
        { max: 36, color: '#BC1F00' },
        { max: 38, color: '#A31000' },
        { max: 40, color: '#8F0001' },
        { max: 42, color: '#C81487' },
        { max: 44, color: '#DD6F93' },
        { max: 46, color: '#FFBFCC' },
    ]

    const number = parseFloat(temperature)

    for (const t of tier) {
        if (number <= t.max) {
            return t.color
        }
    }

    return '#FFBFCC'
}

const getColorWind = windSpeed => {
    const tier = [
        { max: 1, color: '#86CEF8' },
        { max: 3, color: '#7EFFD2' },
        { max: 6, color: '#6FF3B0' },
        { max: 10, color: '#A5E815' },
        { max: 16, color: '#D2F30A' },
        { max: 21, color: '#FEFE01' },
        { max: 27, color: '#FED603' },
        { max: 33, color: '#FEB802' },
        { max: 40, color: '#FF9501' },
        { max: 47, color: '#FF7300' },
        { max: 55, color: '#FE5501' },
        { max: 63, color: '#F23C00' },
        { max: Infinity, color: '#D92E00' },
    ]

    const number = parseFloat(windSpeed)

    for (const t of tier) {
        if (number <= t.max) {
            return t.color
        }
    }

    return '#D92E00'
}

const getColorWaves = waveHeight => {
    const tier = [
        { max: 0, color: '#FFFFFF' },
        { max: 0.1, color: '#E8EAFF' },
        { max: 0.5, color: '#CDD1FF' },
        { max: 1.25, color: '#A2A9FF' },
        { max: 2.5, color: '#7882FF' },
        { max: 4, color: '#5662FF' },
        { max: 6, color: '#FEB802' },
        { max: 9, color: '#FF7300' },
        { max: 14, color: '#FE5501' },
        { max: Infinity, color: '#D92E00' },
    ]

    const number = parseFloat(waveHeight)

    for (const t of tier) {
        if (number <= t.max) {
            return t.color
        }
    }

    return '#D92E00'
}

const getColorPrecipitation = precipitation => {
    const tier = [
        { max: 0, color: '#FFFFFF' },
        { max: 0.1, color: '#86CEF8' },
        { max: 0.2, color: '#7EFFD2' },
        { max: 0.5, color: '#6FF3B0' },
        { max: 1, color: '#5FE88D' },
        { max: 2, color: '#A5E815' },
        { max: 5, color: '#D2F30A' },
        { max: 10, color: '#FEFE01' },
        { max: 15, color: '#FED603' },
        { max: 20, color: '#FEB802' },
        { max: 25, color: '#FF7300' },
        { max: 30, color: '#F23C00' },
        { max: Infinity, color: '#D92E00' },
    ]

    const number = parseFloat(precipitation)

    for (const t of tier) {
        if (number <= t.max) {
            return t.color
        }
    }

    return '#D92E00'
}

const getColorVisibility = visibility => {
    const tier = [
        { max: 5, color: '#5E5E5E' },
        { max: 7.5, color: '#7B7B7B' },
        { max: 10, color: '#9E9E9E' },
        { max: 12.5, color: '#B9B9B9' },
        { max: 15, color: '#C9C9C9' },
        { max: 17.5, color: '#D7D7D7' },
        { max: 20, color: '#E8E8E8' },
        { max: 22.5, color: '#EEEEEE' },
        { max: 25, color: '#F5F5F5' },
        { max: Infinity, color: '#FFFFFF' },
    ]

    const number = parseFloat(visibility)

    for (const t of tier) {
        if (number <= t.max) {
            return t.color
        }
    }

    return '#FFFFFF'
}

const generateAStep = (
    startDate,
    endDate,
    departureAddress,
    arrivalAddress,
    isStopOver = false,
) => {
    const startDateTime = new Date(startDate).getTime()
    const endDateTime = new Date(endDate).getTime()
    const totalHours = Math.floor((endDateTime - startDateTime) / 3600000 - 1)

    return [
        {
            address: departureAddress,
            delimitedDate: {
                startDate: roundUpperHour(
                    new Date(startDateTime),
                ).toISOString(),
                endDate: roundLowerHour(new Date(endDateTime)).toISOString(),
            },
            isStopOver: isStopOver,
            firstStepNbHours:
                isStopOver || departureAddress.id === arrivalAddress.id
                    ? totalHours
                    : Math.floor(totalHours / 2),
            secondStepNbHours:
                isStopOver || departureAddress.id === arrivalAddress.id
                    ? 0
                    : Math.ceil(totalHours / 2),
            totalNbHours: totalHours,
        },
    ]
}

const getNavigationSteps = navigation => {
    const steps = []
    const stopOvers = navigation.stopOvers
    if (stopOvers.length === 0) {
        steps.push(
            ...generateAStep(
                roundLowerHour(navigation.delimitedDate.startDate),
                roundToNearestHour(navigation.delimitedDate.endDate),
                navigation.departureAddress,
                navigation.arrivalAddress,
            ),
        )
    } else {
        // generate a step between departure and first stopover, then between all stopovers, then between last stopover and arrival
        steps.push(
            ...generateAStep(
                roundLowerHour(navigation.delimitedDate.startDate),
                roundToNearestHour(stopOvers[0].delimitedDate.startDate),
                navigation.departureAddress,
                stopOvers[0].address,
            ),
        )
        stopOvers.forEach((stopOver, index) => {
            if (index > 0) {
                steps.push(
                    ...generateAStep(
                        roundToNearestHour(
                            stopOvers[index - 1].delimitedDate.endDate,
                        ),
                        roundToNearestHour(stopOver.delimitedDate.startDate),
                        stopOvers[index - 1].address,
                        stopOver.address,
                    ),
                )
            }
            steps.push(
                ...generateAStep(
                    roundToNearestHour(stopOver.delimitedDate.startDate),
                    roundToNearestHour(stopOver.delimitedDate.endDate),
                    stopOver.address,
                    stopOver.address,
                    true,
                ),
            )
        })
        steps.push(
            ...generateAStep(
                roundToNearestHour(
                    stopOvers[stopOvers.length - 1].delimitedDate.endDate,
                ),
                roundUpperHour(navigation.delimitedDate.endDate),
                stopOvers[stopOvers.length - 1].address,
                navigation.arrivalAddress,
            ),
        )
    }
    return steps
}

const roundUpperHour = date => {
    return new Date(
        new Date(
            new Date(date).setHours(new Date(date).getHours() + 1),
        ).setMinutes(0, 0, 0),
    )
}

const roundToNearestHour = date => {
    let hours = date.getHours()
    let minutes = date.getMinutes()

    if (minutes >= 30) {
        hours += 1
    }

    let roundedDate = new Date(date)
    roundedDate.setHours(hours, 0, 0, 0)

    return roundedDate
}

const roundLowerHour = date => {
    return new Date(new Date(date).setMinutes(0, 0, 0))
}

const getStormyWeather = weather => {
    const { pressure, cloudCover, snowDepth, precipitation } = weather
    const isLowLevelStorm =
        pressure < lowLevelStormPressure && pressure >= highLevelStormPressure

    if (snowDepth > 0) {
        return weatherIcons.sunny_snowy_stormy
    }
    if (precipitation > 0.3) {
        return weatherIcons.rainy_stormy
    }
    if (isLowLevelStorm) {
        if (cloudCover < 50) return weatherIcons.sunny_stormy
        return weatherIcons.stormy
    }
    if (cloudCover < 50) return weatherIcons.sunny_stormy_2
    return weatherIcons.stormy_2
}

const getSnowyWeather = weather => {
    const { snowDepth, cloudCover, precipitation } = weather

    if (snowDepth > highLevelSnowDepth) {
        return weatherIcons.snowy_3
    }
    if (snowDepth > lowLevelSnowDepth) {
        if (cloudCover < 50) {
            if (precipitation > lowLevelPrecipitation)
                return weatherIcons.sunny_rainy_snowy
            return weatherIcons.sunny_snowy_2
        }
        if (precipitation > lowLevelPrecipitation)
            return weatherIcons.rainy_snowy
        return weatherIcons.snowy_2
    }
    if (cloudCover < 50) return weatherIcons.sunny_snowy
    return weatherIcons.snowy
}

const getPrecipitationWeather = weather => {
    const { precipitation, cloudCover } = weather

    if (precipitation > highLevelPrecipitation) return weatherIcons.rainy_3
    if (precipitation > lowLevelPrecipitation) return weatherIcons.rainy_2
    if (cloudCover < 20) return weatherIcons.sunny_rainy
    return weatherIcons.rainy
}

const getVeiledWeather = (weather, isNight) => {
    const { visibility } = weather

    if (visibility < highLevelVisibility) {
        return isNight ? weatherIcons.veiled_night : weatherIcons.sunny_veiled
    }
    return weatherIcons.veiled
}

const getCloudCoverWeather = (weather, isNight) => {
    const { cloudCover } = weather

    if (cloudCover > highLevelCloudCover) return weatherIcons.cloudy
    if (cloudCover > lowLevelCloudCover)
        return isNight ? weatherIcons.cloudy_night : weatherIcons.sunny_cloudy
    return isNight ? weatherIcons.clear_night : weatherIcons.sunny
}

const getGlobalNavigationWeather = weatherArray => {
    const iconsArray = []

    for (const weather of weatherArray) {
        const isNight = weather.sunsetTime
            ? new Date(weather.recordTime) > new Date(weather.sunsetTime)
            : false
        iconsArray.push(getWeatherIcon(weather, isNight))
    }
    return iconsArray
        .reduce((acc, cur, ind, arr) => {
            if (arr.indexOf(cur) === ind) {
                return [...acc, [cur, 1]]
            } else {
                acc[acc.indexOf(acc.find(e => e[0] === cur))] = [
                    cur,
                    acc[acc.indexOf(acc.find(e => e[0] === cur))][1] + 1,
                ]
                return acc
            }
        }, [])
        .sort((a, b) => b[1] - a[1])
        .filter((cur, ind, arr) => cur[1] === arr[0][1])
        .map(cur => cur[0])
}

const getWeatherIcon = (weather, isNight) => {
    const {
        cloudCover,
        precipitation,
        snowDepth,
        pressure,
        visibility,
    } = weather

    if (pressure && pressure < 1000) {
        // Didn't work well it really checky but for now we don't have another way to check if it's stormy
        return getStormyWeather(weather)
    }

    if (snowDepth && snowDepth > 0) {
        return getSnowyWeather(weather)
    }

    if (precipitation && precipitation > 0) {
        return getPrecipitationWeather(weather)
    }

    if (visibility && visibility < lowLevelVisibility) {
        return getVeiledWeather(weather, isNight)
    }

    if (cloudCover && cloudCover > 10) {
        return getCloudCoverWeather(weather, isNight)
    }

    return weatherIcons.sunny
}

const weatherIcons = {
    clear_night: clear_night,
    cloudy_night: cloudy_night,
    cloudy: cloudy,
    rainy_snowy: rainy_snowy,
    rainy_stormy: rainy_stormy,
    rainy: rainy,
    rainy_2: rainy_2,
    rainy_3: rainy_3,
    snowy: snowy,
    snowy_2: snowy_2,
    snowy_3: snowy_3,
    stormy: stormy,
    stormy_2: stormy_2,
    sunny: sunny,
    sunny_cloudy: sunny_cloudy,
    sunny_rainy_snowy: sunny_rainy_snowy,
    sunny_rainy: sunny_rainy,
    sunny_snowy: sunny_snowy,
    sunny_snowy_2: sunny_snowy_2,
    sunny_snowy_stormy: sunny_snowy_stormy,
    sunny_stormy: sunny_stormy,
    sunny_stormy_2: sunny_stormy_2,
    sunny_veiled: sunny_veiled,
    veiled_night: veiled_night,
    veiled: veiled,
}

export const stepUtils = {
    generateAStep,
    getNavigationSteps,
    roundUpperHour,
    roundLowerHour,
}

export const colorUtils = {
    getColorTemperature,
    getColorWind,
    getColorWaves,
    getColorPrecipitation,
    getColorVisibility,
}

export const weatherUtils = {
    getGlobalNavigationWeather,
}

function toRadians(degrees) {
    return (degrees * Math.PI) / 180
}

export function haversineDistance(coord1, coord2) {
    const R = 6371 // Earth's radius in kilometers

    const lat1 = toRadians(coord1[0])
    const lon1 = toRadians(coord1[1])
    const lat2 = toRadians(coord2[0])
    const lon2 = toRadians(coord2[1])

    const dLat = lat2 - lat1
    const dLon = lon2 - lon1

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(lat1) *
            Math.cos(lat2) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2)
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    const distance = R * c

    return distance
}

/**
 * Return distance in km from coordinates
 *
 * @function getDistanceFromCoordinates
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {Array<float, float>} coordinates - array of all the coordinates of the travel.
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 05/07/2024 - 10:28
 * @author Samuel.C
 */

export function getDistanceFromCoordinates(coordinates) {
    let totalDist = 0

    for (let i = 0; i < coordinates.length - 1; i++) {
        totalDist += haversineDistance(coordinates[i], coordinates[i + 1])
    }

    return totalDist
}
