export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    main: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    bluePoint: {
        color: '#3040a0',
        fontSize: '24px',
        lineHeight: '1.2',
    },
    point: {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        marginLeft: '25px',
        lineHeight: '1.2',
        zIndex: '1',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    intro: {
        width: '100%',
        maxWidth: 800,
        padding: '10px',
        paddingTop: '40px',
        textAlign: 'left',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 20px)',
            marginTop: '-20px',
        },
        backgroundColor: 'white',
        zIndex: '2',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '25px',
    },
    boatIcon: {
        width: '100px',
        marginRight: 'auto',
        marginLeft: 'auto',
        zIndex: '2',
    },
    title: {
        fontWeight: 500,
        marginTop: 'auto',
        marginBottom: 'auto',
        [theme.breakpoints.down('sm')]: {
            fontSize: '12px',
        },
    },
    mobileTitle: {
        fontWeight: 500,
        zIndex: 99,
        paddingInline: 10,
    },
    line: {
        marginLeft: 'auto',
        marginRight: 'auto',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        width: '100%',
        height: '100px',
        [theme.breakpoints.down('sm')]: {
            height: '65px',
        },
    },
    fill: {
        width: '100px',
        position: 'absolute',
        [theme.breakpoints.down('sm')]: {
            width: '65px',
        },
        backgroundColor: 'white',
        borderRadius: 50,
    },
    date: {
        width: '50px',
        textAlign: 'center',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'clip',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '30px',
            fontSize: '12px',
        },
        zIndex: '2',
    },
    dates: {
        position: 'relative',
        alignSelf: 'center',
    },
    circleDate: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '90px',
        width: '100px',
        [theme.breakpoints.down('sm')]: {
            width: '65px',
            height: '50px',
        },
    },
    schema: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    link: {
        height: '100px',
        width: '3px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '-7px',
        marginBottom: '-7px',
        zIndex: '2',
    },
    linkLineLeft: {
        position: 'absolute',
        width: `45%`,
        maxWidth: '350px',
        top: 0,
        right: 'calc(50% + 48px)',
        [theme.breakpoints.down('sm')]: {
            width: '40%',
            right: 'calc(50% + 30px)',
        },
        [theme.breakpoints.down('xs')]: {
            width: '40%',
            right: 'calc(50% + 30px)',
        },
    },
    linkLineRight: {
        position: 'absolute',
        width: `45%`,
        maxWidth: '350px',
        top: 0,
        left: 'calc(50% + 48px)',
        [theme.breakpoints.down('sm')]: {
            width: '40%',
            left: 'calc(50% + 30px)',
        },
        [theme.breakpoints.down('xs')]: {
            width: '40%',
            left: 'calc(50% + 30px)',
        },
    },
    linkLineIcon: {
        height: '12px',
        width: '100%',
    },
    infosLeft: {
        marginLeft: '20px',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
        marginBottom: '-10px',
    },
    infosRight: {
        marginRight: '20px',
        display: 'flex',
        flexDirection: 'row-reverse',
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
        marginBottom: '-10px',
    },
    iconLeft: {
        marginLeft: '0',
        marginRight: '15px',
        height: '40px',
        width: '40px',
        [theme.breakpoints.down('sm')]: {
            height: '20px',
            width: '20px',
            marginRight: '5px',
        },
        marginBottom: '5px',
    },
    iconRight: {
        marginLeft: '15px',
        marginRight: '0',
        height: '40px',
        width: '40px',
        [theme.breakpoints.down('sm')]: {
            height: '20px',
            width: '20px',
            marginLeft: '5px',
        },
        marginBottom: '5px',
    },
    yearLabel: {
        fontSize: '250px',
        color: 'black',
        padding: '10px',
        opacity: '0.2',
        [theme.breakpoints.down('sm')]: {
            fontSize: '100px',
        },
        lineHeight: '0 !important',
        marginTop: 90,
    },
    year: {
        display: 'block',
        position: 'fixed !important',
        top: 300,
        left: 'calc((100% - 310px) / 2 - 250px)',
        [theme.breakpoints.down('sm')]: {
            right: '10px',
            left: 'calc(50% - 100px)',
        },
        zIndex: '0',
        height: 200,
        userSelect: 'none',
        pointerEvents: 'none',
    },
    firstLine: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    all: {
        backgroundColor: 'hsla(231, 9%, 55%, 0.4)',
        zIndex: '11000001',
        width: 'calc(100% - 40px)',
        padding: '20px',
        height: 'calc(100% - 40px)',
        marginBottom: '5px',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#fff',
        },
    },
    popuptext: {
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
        zIndex: '1',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
        whiteSpace: 'pre-line',
    },
    texts: {
        width: '90%',
        margin: 'auto',
        marginTop: '13vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '10px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            width: 'calc(100% - 10px)',
        },
        backgroundColor: '#fff',
        border: '5px solid #3040a0',
        boxShadow: '0px 1px 20px 0.1px #000',
        borderRadius: 5,
    },
    st: {
        verticalAlign: 'super',
        fontSize: '70%',
    },
    snackbarMessage: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'space-between',
    },
    snackbar: {
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    root2: {
        width: `100%`,
        height: `80vh`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        flexDirection: `column`,
    },
    backgroundImage: {
        maxWidth: 300,
        objectFit: `contain`,
    },
    noBoatText: {
        maxWidth: 300,
        color: `#303F9F`,
        fontSize: 22,
        fontWeight: `bold`,
        textAlign: `center`,
        margin: 0,
        '&:hover': {
            cursor: `pointer`,
        },
    },
})
