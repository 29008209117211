export default theme => ({
    root: {
        width: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    circle: {
        color: 'black',
    },
    button: {
        margin: 5,
        padding: 5,
        [theme.breakpoints.down('sm')]: {
            margin: 0,
        },
    },
    rootLabel: {
        padding: `5px 8px`,
        textAlign: `center`,
        color: 'white',
        borderRadius: 3,
        marginRight: 5,
        fontWeight: `bold`,
        lineHeight: `15px`,
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    redColor: {
        color: '#f44336',
    },
    orangeColor: {
        color: '#F4BA5C',
    },
    greenColor: {
        color: '#6BC070',
    },
    redLabel: {
        backgroundColor: '#f44336',
        width: '85px',
    },
    orangeLabel: {
        backgroundColor: '#F4BA5C',
        width: '165px',
    },
    greenLabel: {
        backgroundColor: '#6BC070',
        width: '65px',
    },
})
