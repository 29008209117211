export default theme => ({
    root: { width: '80%', height: '100%', position: 'relative' },
    rootFlate: {
        width: '100%',
        height: '40px',
        position: 'relative',
        minWidth: '75px',
        maxWidth: '300px',
        marginBottom: 8,
    },
    pointeur: {
        top: '5px',
        right: '50%',
        position: 'absolute',
    },
    pointeur_plate: {
        top: '8px',
        left: '0',
        position: 'absolute',
    },
    value: {
        bottom: '-10px',
        textAlign: 'center',
        position: 'absolute',
        width: '100%',
    },
    jauge: {
        top: '5px',
        right: '50%',
        position: 'absolute',
        transform: `translate(50%)`,
    },
    invertedJauge: {
        transform: `translate(50%) scaleX(-1)`,
    },
    jauge_flate: {
        top: '10px',
        right: '50%',
        position: 'absolute',
        transform: `translate(50%)`,
    },
    minValue: {
        bottom: '-5px',
        textAlign: 'right',
        position: 'absolute',
        width: 'calc(50% - 22px)',
        fontSize: '10px',
        marginRight: 'calc(50% + 22px)',
    },
    maxValue: {
        bottom: '-5px',
        textAlign: 'left',
        position: 'absolute',
        width: 'calc(50% - 22px)',
        fontSize: '10px',
        marginLeft: 'calc(50% + 22px)',
    },
    minValueFlate: {
        bottom: '-10px',
        textAlign: 'left',
        position: 'absolute',
        width: '100%',
        fontSize: '15px',
    },
    maxValueFlate: {
        bottom: '-10px',
        textAlign: 'right',
        position: 'absolute',
        width: '100%',
        fontSize: '15px',
    },
    middleValue: {
        top: '-8px',
        textAlign: 'center',
        position: 'absolute',
        width: '100%',
        fontSize: '10px',
    },
    value75: {
        top: '12px',
        textAlign: 'left',
        position: 'absolute',
        width: 'calc(50% - 25px)',
        fontSize: '10px',
        marginLeft: 'calc(50% + 25px)',
    },
})
