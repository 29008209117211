import React, { Component } from 'react'
import boaticon from '../../../../../../../images/coqueIcon.png'
import { AttachFile } from '@mui/icons-material'
import BoatOnComponent from '../../../../../../common/BoatOnComponent'
import CalendarPageDico from '../../../Dictionary/CalendarPageDico'

export class EventList extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = CalendarPageDico
    }

    render() {
        const {
            classes,
            event,
            eventInfo,
            actionsButtonRef,
            openActionsMenu,
            openDocModal,
        } = this.props

        return (
            <div className={classes.eventListContainer}>
                <div
                    className={classes.fcEventList}
                    style={{
                        backgroundColor: `${event.backgroundColor}`,
                    }}
                >
                    <img
                        src={
                            event.type.photo
                                ? require(`images/EventBobIcon/${event.type.photo}`)
                                : ''
                        }
                        alt={
                            this.displayTextApi(event.type.translation) +
                            ' icon'
                        }
                        className={classes.icon}
                    />
                    <div className={classes.col}>
                        <div
                            className={[classes.row, classes.fcEventTitle].join(
                                ' ',
                            )}
                        >
                            {/* Equipment */}
                            {event.equipmentName !== 'Error' && (
                                <span>
                                    {event.equipmentName}
                                    {'\u00A0-\u00A0'}
                                </span>
                            )}

                            {/* Titre */}
                            <span>{event.title}</span>
                        </div>
                        <span>
                            {this.displayTextApi(event.type.translation)}
                        </span>
                    </div>
                </div>
                {/* Type et Montant */}
                <div
                    className={classes.row}
                    style={{
                        justifyContent: 'space-between',
                    }}
                >
                    {/* Type de l'événement */}
                    <div>
                        {/* Montant */}
                        {event.transaction && (
                            <b className={classes.amountContainer}>
                                {event.type.spending &&
                                    event.transaction.amountIt !== 0 && (
                                        <span style={{ color: 'red' }}>
                                            -{event.transaction.amountIt}€
                                        </span>
                                    )}

                                {event.type.earning &&
                                    event.transaction.amountIt !== 0 && (
                                        <span style={{ color: 'green' }}>
                                            +{event.transaction.amountIt}€
                                        </span>
                                    )}
                            </b>
                        )}
                    </div>
                    <div
                        className={classes.dotContainer}
                        ref={actionsButtonRef}
                        onClick={openActionsMenu}
                    >
                        <span className={classes.dot}></span>
                        <span className={classes.dot}></span>
                        <span className={classes.dot}></span>
                    </div>
                </div>
                <div className={classes.row}>{event.description}</div>
                <div className={classes.row} style={{ gap: 10 }}>
                    {event.assignee && (
                        <span className={classes.assigneeContainer}>
                            <div className={classes.fcAssigneeBadge}>
                                {event.assignee.firstName[0] +
                                    '' +
                                    event.assignee.lastName[0]}
                            </div>
                            <span>
                                {event.assignee.firstName +
                                    ' ' +
                                    event.assignee.lastName}
                            </span>
                        </span>
                    )}

                    {/* Nom du bateau */}
                    {event.boat && (
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 5,
                            }}
                        >
                            <img src={boaticon} className={classes.boaticon} />
                            <b>{event.boat.name}</b>
                        </span>
                    )}

                    {/* Fichiers */}
                    {event.files?.length > 0 && (
                        <span
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                cursor: 'pointer',
                            }}
                            onClick={openDocModal}
                        >
                            <AttachFile />
                            {this.displayText('documentsAndPhotos')}
                        </span>
                    )}
                </div>
            </div>
        )
    }
}

export default EventList
