export default {
    title: {
        FR: 'Mes utilisateurs',
        EN: 'My users',
    },
    cancel: {
        FR: `Annuler`,
        EN: `Cancel`,
    },
    attente: {
        FR: `En attente`,
        EN: `Waiting`,
    },
    newName: {
        FR: `Choisissez un nouveau nom pour votre groupe`,
        EN: `Choose a new name for your group`,
    },
    chooseName: {
        FR: `Votre groupe n'a pas encore de nom, ajoutez-en un afin de commencer à inviter des utilisateurs`,
        EN: `Your group does not have a name yet, add one in order to start inviting users`,
    },
    name: {
        FR: `Nom du groupe`,
        EN: `Group name`,
    },
    validerNom: {
        FR: `Valider`,
        EN: `Submit`,
    },
    delete: {
        FR: `Supprimer`,
        EN: `Delete`,
    },
    role0: {
        FR: `Administrateur`,
        EN: `Administrator`,
    },
    role1: {
        FR: `Superviseur`,
        EN: `Supervisor`,
    },
    role2: {
        FR: `Capitaine`,
        EN: `Captain`,
    },
    role3: {
        FR: `Marin`,
        EN: `Sailor`,
    },
    role4: {
        FR: `Propriétaire`,
        EN: `Owner`,
    },
    role5: {
        FR: `Prestataire`,
        EN: `Provider`,
    },
    roleDesc0: {
        FR: `Niveau le plus élevé, possède tous les droits`,
        EN: `Highest level, has all rights`,
    },
    roleDesc1: {
        FR: `Possède tous les droits sauf celui d'ajouter de nouveaux utilisateurs`,
        EN: `Has all rights except the right to add new users`,
    },
    roleDesc2: {
        FR: `Possède tous les droits liés à la gestion d'un bateau de votre flotte`,
        EN: `Has all the rights related to the management of a boat in your fleet`,
    },
    roleDesc3: {
        FR: `Accès à toutes les fonctions de base nécessaires à un membre d'équipage`,
        EN: `Access to all basic functions needed by a crew member`,
    },
    roleDesc4: {
        FR: `Peut visualiser toutes les informations de votre flotte mais ne peut rien modifier`,
        EN: `Can view all the information of your fleet but cannot modify anything`,
    },
    roleDesc5: {
        FR: `Peut accèder aux tâches où il est notifié et changer les pièces liées`,
        EN: `Can access the tasks where it is notified and change the related parts`,
    },
    areYouSure1: {
        FR: `Êtes vous sur de vouloir supprimer`,
        EN: `Are you sure you want to delete`,
    },
    areYouSure2: {
        FR: `de votre liste d'utilisateurs ?`,
        EN: `from your user list ?`,
    },
    search: {
        FR: `Je recherche`,
        EN: `I search`,
    },
    actif: {
        FR: `Actif`,
        EN: `Active`,
    },
    suspendu: {
        FR: `Désactivé`,
        EN: `Desactivated`,
    },
    compte: {
        FR: `Compte`,
        EN: `Account`,
    },
    role: {
        FR: `Rôle`,
        EN: `Role`,
    },
    email: {
        FR: `Email`,
        EN: `Email`,
    },
    status: {
        FR: `Statut du compte`,
        EN: `State of the account`,
    },
    addUser: {
        FR: `Ajouter un utilisateur`,
        EN: `Add a new user`,
    },
    thisUser: {
        FR: ` cet utilisateur`,
        EN: ` this user`,
    },
}
