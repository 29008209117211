import { activityConstants } from '../constants/activity.constants'
import { requestApi } from '../services/api.service'

const getBobEventActivity = eventId => {
    return dispatch => {
        dispatch({
            type: activityConstants.REQUEST_ACTIVITY,
        })

        return requestApi(
            `/bob-activity-logs/bob-event/${eventId}`,
            'GET',
        ).then(
            result => {
                dispatch({
                    type: activityConstants.ACTIVITY_SUCCESS,
                    activity: result,
                })
            },
            error => {
                dispatch({ type: activityConstants.ACTIVITY_FAILURE })
            },
        )
    }
}

const getWorkTimeActivity = workTimeId => {
    return dispatch => {
        dispatch({
            type: activityConstants.REQUEST_ACTIVITY,
        })

        return requestApi(
            `/bob-activity-logs/work-time/${workTimeId}`,
            'GET',
        ).then(
            result => {
                dispatch({
                    type: activityConstants.ACTIVITY_SUCCESS,
                    activity: result,
                })
            },
            error => {
                dispatch({ type: activityConstants.ACTIVITY_FAILURE })
            },
        )
    }
}

const getAbsenceActivity = absenceId => {
    return dispatch => {
        dispatch({
            type: activityConstants.REQUEST_ACTIVITY,
        })

        return requestApi(
            `/bob-activity-logs/absence/${absenceId}`,
            'GET',
        ).then(
            result => {
                dispatch({
                    type: activityConstants.ACTIVITY_SUCCESS,
                    activity: result,
                })
            },
            error => {
                dispatch({ type: activityConstants.ACTIVITY_FAILURE })
            },
        )
    }
}

const getNavigationActivity = navigationId => {
    return dispatch => {
        dispatch({
            type: activityConstants.REQUEST_ACTIVITY,
        })

        return requestApi(
            `/bob-activity-logs/navigation/${navigationId}`,
            'GET',
        ).then(
            result => {
                dispatch({
                    type: activityConstants.ACTIVITY_SUCCESS,
                    activity: result,
                })
            },
            error => {
                dispatch({ type: activityConstants.ACTIVITY_FAILURE })
            },
        )
    }
}

const getOrderActivity = orderId => {
    return dispatch => {
        dispatch({
            type: activityConstants.REQUEST_ACTIVITY,
        })

        return requestApi(`/bob-activity-logs/order/${orderId}`, 'GET').then(
            result => {
                dispatch({
                    type: activityConstants.ACTIVITY_SUCCESS,
                    activity: result,
                })
            },
            error => {
                dispatch({ type: activityConstants.ACTIVITY_FAILURE })
            },
        )
    }
}

export const activityActions = {
    getBobEventActivity,
    getWorkTimeActivity,
    getAbsenceActivity,
    getNavigationActivity,
    getOrderActivity,
}
