export default theme => ({
    container: {
        border: `1px solid #E9EFF2`,
        overflow: `hidden`,
        display: 'flex',
        flexDirection: 'column',
        minHeight: 500,
        paddingBottom: 100,
        borderRadius: 5,
        width: '80%',
    },
    noNameContainer: {
        display: `flex`,
        wdith: `100%`,
        margin: `auto`,
        alignItems: `center`,
        justifyContent: `center`,
        flexDirection: `column`,
        maxWidth: 300,
        textAlign: `center`,
        fontSize: `15px`,
    },
    changeTitleText: {
        fontWeight: 500,
        fontSize: `16px`,
    },
    changeTitle: {
        display: `flex`,
        height: 150,
        alignItems: `center`,
        justifyContent: `center`,
        padding: 15,
        textAlign: `center`,
        flexDirection: `column`,
    },
    subHeader: {
        display: `flex`,
        alignItems: `center`,
        '& > h2': {
            marginRight: 2,
        },
        '&:hover': {
            cursor: `pointer`,
        },
    },
    subTitle: {
        color: `rgba(0,0,0,0.7)`,
        fontSize: `15px`,
    },
    iconSubTitle: {
        color: `rgba(0,0,0,0.7)`,
        height: 15,
    },
    noNameSub: {
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        marginTop: 20,
    },
    noNameIcon: {
        height: 250,
        width: 250,
        color: `#30322F`,
        opacity: 0.8,
    },
    noNameField: {
        marginRight: 20,
        width: `100%`,
    },
    addUser: {
        height: 52,
        width: 216,
    },
    header: {
        display: `flex`,
        height: `fit-content`,
        paddingLeft: 24,
        paddingTop: 24,
        paddingRight: 24,
        paddingBottom: 10,
        justifyContent: `space-between`,
        width: `100%`,
        boxSizing: `border-box`,
        [theme.breakpoints.down(`sm`)]: {
            alignItems: `center`,
            flexDirection: `column`,
            paddingBottom: 20,
        },
    },
    headerText: {
        [theme.breakpoints.down(`sm`)]: {
            marginBottom: 15,
        },
    },
    activity: {
        background: `#309F84`,
    },
    inactivity: {
        background: `#CD2318`,
    },
    waiting: {
        background: `#E19716`,
    },
    status: {
        borderRadius: 4,
        padding: `4px 13px`,
        color: `white`,
        width: `fit-content`,
    },
    field: {
        margin: '24px 10px',
        boxSizing: `border-box`,
    },
    title: {
        fontWeight: `bold`,
        fontSize: `24px`,
    },
    titleDelete: {
        fontSize: `16px`,
        textAlign: `center`,
        marginBottom: 20,
    },
    buttonsDelete: {
        display: `flex`,
        width: 210,
        alignItems: `center`,
        justifyContent: 'space-between',
        '& > div': {
            margin: `0 7px`,
        },
    },
    deleteButton: {
        background: `#F4F4F4`,
    },
    containerDelete: {
        display: `flex`,
        alignItems: `center`,
        flexDirection: `column`,
        justifyContent: `center`,
        boxSizing: `border-box`,
        height: 200,
        width: `100%`,
        padding: 40,
    },
    table: {
        borderCollapse: `collapse`,
        width: `100%`,
        minWidth: 750,
    },
    tableContainer: {
        width: `100%`,
        overflowX: `auto`,
    },
    appBarContainer: {
        width: `100%`,
        boxSizing: `border-box`,
        padding: `0 24px`,
    },
    firstColumn: {
        paddingLeft: 24,
    },
    columnsTitles: {
        height: 51,
        background: `#F6F8FA`,
        borderBottom: `1px solid #E9EFF2`,
        textAlign: `left`,
        fontWeight: `bold`,
        fontSize: `16px`,
    },
    rows: {
        overflowX: `auto`,
        fontWeight: `400`,
        fontSize: `16px`,
        height: 51,
        borderBottom: `1px solid #E9EFF2`,
    },
    row: {
        overflow: `hidden`,
        width: `23%`,
    },
    icons: {
        display: `flex`,
        alignItems: `center`,
        width: `10%`,
        height: 51,
    },
    icon: {
        width: 25,
        height: 25,
        margin: `0 5px`,
        color: `black`,
        '&:hover': {
            cursor: `pointer`,
        },
    },
})
