import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/MyDocumentPageCss'
import MyDocumentList from './MyDocumentList'
import ModalConnection from '../../../common/UsefullComponents/ModalConnection/ModalConnection'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import catamaranicon from '../../../../images/catamaranicon.svg'
import ModalNoBoat from '../../../common/UsefullComponents/ModalConnection/ModalNoBoat'
import dictionary from './Dictionary/MyDocumentListDico'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'
import { Actions, PermissionsEntities } from '../../../../constants'

class MyDocumentPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.initializeReactGA()

        this.state = {
            selectedBoat: 0,
            resetSave: false,
            openModalConnection: true,
        }

        const options = {
            autoConfig: true,
            debug: false,
        }
        ReactPixel.init('181677145678471', options)

        ReactPixel.pageView()

        this.handleClose = this.handleClose.bind(this)
        this.handleUpdated = this.handleUpdated.bind(this)
    }

    initializeReactGA() {
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/logbook/my-documents')
    }

    handleClose() {
        this.setState({ openModalConnection: false })
    }

    handleUpdated() {
        this.setState({ resetSave: false })
    }

    render() {
        const { classes, user } = this.props
        const { resetSave } = this.state
        const { boat, loading } = this.props
        if (!user) {
            return (
                <ModalConnection
                    open={this.state.openModalConnection}
                    handleClose={() => {
                        this.handleClose()
                    }}
                />
            )
        }

        if (!boat && loading === 0) {
            return (
                <div className={classes.root2}>
                    <img
                        className={classes.backgroundImage}
                        src={catamaranicon}
                        alt="catamaranicon"
                    />
                    <p onClick={this.openModal} className={classes.noBoatText}>
                        {this.displayText(`noBoat`)}
                    </p>
                    {
                        <BoatOnBlock
                            permissions={{
                                action: Actions.Create,
                                entityName: PermissionsEntities.Boat,
                            }}
                            children={
                                <ModalNoBoat
                                    open={this.state.openModalNoBoat}
                                    handleClose={this.handleClose}
                                />
                            }
                        />
                    }
                </div>
            )
        }

        if (loading !== 0) return this.renderLoading(`100px`, `100px`, true)

        return (
            <div className={classes.root} key="alerts">
                <div className={classes.main}>
                    <MyDocumentList
                        selectedBoat={this.state.selectedBoat}
                        update={resetSave}
                        handleUpdated={this.handleUpdated}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        boat: state.bob.boat,
        loading: state.bob.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(MyDocumentPage))
