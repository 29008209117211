export default {
    /*******ModalInventoryCheck*******/
    checkAmountTitle: {
        FR: 'Vous avez saisi un montant de 0€.',
        EN: 'You have entered an amount of 0€.',
    },
    confirmAmount: {
        FR: 'Voulez-vous confirmer ?',
        EN: 'Would you like to confirm ?',
    },
    answer_yes: {
        FR: 'Oui',
        EN: 'Yes',
    },
    answer_no: {
        FR: 'Non',
        EN: 'No',
    },
    validate: {
        FR: 'Je valide',
        EN: 'Validate',
    },
}
