export default theme => ({
    title: {
        fontWeight: `bold`,
        fontSize: `24px`,
    },
    container: {
        // width: `calc(100vw - 100px)`,
        display: `flex`,
        flexDirection: 'column',
        gap: 20,
        padding: 20,
        marginLeft: 12, //workaround
        marginTop: 10, //workaround
        paddingInline: 60,
        // width: '100%',
        width: '80%',
        minHeight: 500,

        [theme.breakpoints.down('sm')]: {
            width: '100%',
            boxShadow: 'none',
            padding: 0,
            margin: 0,
            boxSizing: 'border-box',
        },
    },
    tabButton: {
        fontSize: 14,
        textTransform: 'none',
    },
    tabPanel: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        padding: 0,
    },
    hidden: {
        display: 'none',
    },
    alert: {
        border: '1px solid #9CB8E3 !important',
        background: 'rgba(156, 184, 227, 0.10) !important',
        color: 'black',
        marginBottom: 10,
    },
    alertIcon: {
        alignItems: 'center',
        color: '#303F9F !important',
    },
    selectContainer: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
    },
    select: {
        width: '33%',
    },
    selectInput: {
        display: 'flex !important',
        gap: 10,
    },
    selectItemRoot: {
        display: 'flex',
        flexDirection: 'row',
        '&:hover': {
            background: '#E5E7F0',
        },
    },
    selectItemGroupName: {
        fontSize: 14,
        fontWeight: 500,
        flex: 1,
    },
    selectBadgeContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        gap: 5,
    },
    selectBadge: {
        width: 20,
        height: 20,
        background: '#E5E7F0',
        border: 'solid white 1px',
        borderRadius: 20,
        color: '#303F9F',
        marginLeft: -7,
        fontSize: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 900,
    },
    badgesExtra: {
        fontWeight: 500,
        fontSize: 12,
    },
    editGroupIcon: {
        height: '20px !important',
        width: '20px !important',
        color: '#212121',
    },
    autocompleteOverride: {
        margin: '0px !important',
    },
    daysContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    dayRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',
        gap: 10,
    },
    dayInfo: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    dayName: {
        display: 'flex',
        width: 70,
    },
    dayNameDisabled: {
        color: 'rgba(0, 0, 0, 0.38)',
    },
    addBreakButton: {
        fontSize: 14,
        display: 'flex',
        flexShrink: 0,
    },
    breakList: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    breakRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    timePickerInput: {
        // width: 130,
    },
    tableContainer: {
        borderRadius: 5,
        overflow: 'hidden',
        border: 'solid 1px #EAECF5',
    },
    tableHeader: {
        background: '#EAECF5',
    },
    badgeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        fontSize: 14,
    },
    userBadge: {
        background: '#E5E7F0',
        color: '#303F9F',
        width: 30,
        height: 30,
        borderRadius: 15,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: 12,
        overflow: 'hidden',
    },
    userBadgeIcon: {
        marginTop: '10px !important',
        width: '35px !important',
        height: '35px !important',
    },
    footerBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        position: 'fixed',
        bottom: 0,
        right: '50%',
        transform: 'translate(+50%)',
        width: '100%',
        backgroundColor: 'white',
        borderTop: '3px solid #c4c4c4',
        borderRight: '3px solid #c4c4c4',
        borderLeft: '3px solid #c4c4c4',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        zIndex: 12,
        padding: '12px',
        maxHeight: '7%',
    },
    footerButton: {
        color: 'black',
        '&:hover': {
            backgroundColor: '#3040a0',
            color: 'white',
        },
        float: 'right',
        marginRight: '15px',
        marginLeft: '15px',
        width: '27%',
        fontSize: '17px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '10px',
            width: 'auto',
            marginRight: '5px',
            marginLeft: '5px',
        },
    },
    popoverRoot: {
        width: 360,
        borderRadius: 12,
    },
    popoverContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 20,
        padding: 23,
    },
    popoverTitle: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    popoverTextField: {
        width: 75,
    },
    nbPaidVacationAllowedCell: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
        fontSize: 14,
    },
    nbPaidVacationAllowedInput: {
        width: 50,
        '& input[type=number]': {
            '-moz-appearance': 'textfield',
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
        },
    },
    pageLoadingLabel: {
        fontWeight: 500,
    },
    searchField: {
        width: 200,
    },
    cell: {
        fontSize: 14,
    },
    mobileCell: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
    },
    mobileRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
    },
    mobileOnly: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'revert',
        },
    },
    desktopOnly: {
        display: 'revert',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    currentPeriod: {
        fontWeight: 500,
    },
})
