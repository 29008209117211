import { inqueriesConstants } from '../../constants'

const initialState = {
    personalParkingInqueries: [],
    receivedParkingInqueries: [],
    personalInsuranceInqueries: [],
    loading: 0,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case inqueriesConstants.REQUEST_PERSONAL_PARKING_INQUERIES:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case inqueriesConstants.REQUEST_PERSONAL_PARKING_INQUERIES_SUCCESS:
            return {
                ...prevState,
                personalParkingInqueries: action.personalParkingInqueries,
                loading: prevState.loading - 1,
            }
        case inqueriesConstants.REQUEST_PERSONAL_PARKING_INQUERIES_FAILURE:
            return {
                ...prevState,
                personalParkingInqueries: [],
                loading: prevState.loading - 1,
                error: action.error,
            }
        case inqueriesConstants.REQUEST_RECEIVED_PARKING_INQUERIES:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case inqueriesConstants.REQUEST_RECEIVED_PARKING_INQUERIES_SUCCESS:
            return {
                ...prevState,
                receivedParkingInqueries: action.receivedParkingInqueries,
                loading: prevState.loading - 1,
            }
        case inqueriesConstants.REQUEST_RECEIVED_PARKING_INQUERIES_FAILURE:
            return {
                ...prevState,
                receivedParkingInqueries: [],
                loading: prevState.loading - 1,
                error: action.error,
            }
        case inqueriesConstants.REQUEST_PERSONAL_INSURANCE_INQUERIES:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case inqueriesConstants.REQUEST_PERSONAL_INSURANCE_INQUERIES_SUCCESS:
            return {
                ...prevState,
                personalInsuranceInqueries: action.personalInsuranceInqueries,
                loading: prevState.loading - 1,
            }
        case inqueriesConstants.REQUEST_PERSONAL_INSURANCE_INQUERIES_FAILURE:
            return {
                ...prevState,
                personalInsuranceInqueries: [],
                loading: prevState.loading - 1,
                error: action.error,
            }
        default:
            return prevState
    }
}
