export default {
    diffPassword: {
        FR: 'Les deux mots de passe ne sont pas identiques',
        EN: 'The two passwords are not identical',
    },
    newPassword: {
        FR: 'Nouveau mot de passe',
        EN: 'New password',
    },
    repeatPassword: {
        FR: 'Répéter le mot de passe',
        EN: 'Repeat the new password',
    },
    nbCharacterPassword: {
        FR: 'Votre mot de passe doit faire au moins 6 caractères',
        EN: 'Your password must be at least 6 characters',
    },
    lastPassword: {
        FR: 'Ancien mot de passe',
        EN: 'Previous password',
    },
    submit: {
        FR: 'Valider',
        EN: 'Submit',
    },
    samePassword: {
        FR: "Le nouveau mot de passe et l'ancien ne peuvent pas être identique",
        EN: 'The new password and the old one cannot be identical',
    },
}
