export default {
    late: {
        FR: `En retard`,
        EN: `Overdue`,
    },
    soon: {
        FR: `Bientôt à échéance`,
        EN: `Soon to expire`,
    },
    valid: {
        FR: `Validée`,
        EN: `Validated`,
    },
}
