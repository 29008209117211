import { downloadConstants } from '../constants/download.constants'

const initialState = {
    loading: 0,
    error: false,
}

export default function download(prevState = initialState, action) {
    switch (action.type) {
        case downloadConstants.START_DOWNLOAD:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case downloadConstants.DOWNLOAD_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                error: false,
            }
        case downloadConstants.DOWNLOAD_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                error: true,
            }
        default:
            return prevState
    }
}
