export default {
    save: {
        FR: 'Enregistrer',
        EN: 'Save',
    },
    cancel: {
        FR: 'Annuler',
        EN: 'Cancel',
    },

    crewMembers: {
        FR: `Membres de l'équipage`,
        EN: 'Crew members',
    },
    captain: {
        FR: 'Capitaine',
        EN: 'Captain',
    },
    crewInput: {
        FR: `Autres membres d’équipage`,
        EN: 'Crew members',
    },

    travel: {
        FR: 'Trajet',
        EN: 'Travel',
    },
    start: {
        FR: 'Départ',
        EN: 'Departure',
    },
    stopover: {
        FR: 'Escale',
        EN: 'Stopover',
    },
    end: {
        FR: 'Arrivée',
        EN: 'Arrival',
    },

    captainJudgment: {
        FR: 'Appréciation du capitaine',
        EN: 'Captain judgment',
    },

    photodocs: {
        FR: 'Photos et Documents',
        EN: 'Photos and Documents',
    },
}
