import React from 'react'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import dictionary from './Dictionary/LoadingRepairModalDico'
import BoatOnAlert from '../../../../common/UsefullComponents/BoatOnAlert'

class LoadingRepairModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    _body() {
        return (
            <>
                <BoatOnAlert
                    severity={`info`}
                    description={
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            {this.displayText('weAreLoading')}
                            <div style={{ width: '60px' }}>
                                {this.renderLoading('50px')}
                            </div>
                        </div>
                    }
                    style={{ marginBottom: 0 }}
                />
            </>
        )
    }

    render() {
        const { title } = this.props

        return (
            <>
                {this._renderTitle(title, true)}
                {this._renderBody({
                    body: this._body(),
                })}
            </>
        )
    }
}

export default LoadingRepairModal
