export default theme => ({
    root: {
        display: `flex`,
        justifyContent: `space-between`,
        flexDirection: `row`,
        [theme.breakpoints.down('sm')]: {
            flexDirection: `column-reverse`,
        },
    },
    boatInfoRoot: {
        margin: '0',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    boatInfos: {
        margin: '10px 10px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: '20px 0 0 0',
        },
    },
    content: {
        width: 'calc(100% - 310px)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginTop: '20px',
        },
    },
})
