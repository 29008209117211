export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        padding: 20,
        gap: 10,
        overflowY: 'auto',
        overflowX: 'hidden',
    },
    divider: {
        borderBottomWidth: 'inherit !important',
        marginTop: '10px !important',
        marginBottom: '10px !important',
    },
    marginTitle: {
        marginBottom: 20,
    },

    tooltip: {
        backgroundColor: '#3040a0',
        color: 'white',
        maxWidth: 300,
        border: '1px solid #dadde9',
        fontSize: 15,
    },
    helpIcon: {
        marginTop: -10,
        width: 15,
        height: 15,
        marginRight: 3,
        marginLeft: 3,
    },

    travelsStepsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
    },
    titleRowContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    editIcon: {
        height: 20,
        width: 20,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    travelRowContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    travelVerticalContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    stepTime: {
        fontSize: 16,
    },
    travelStepTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    travelStepName: {
        fontSize: 12,
        fontWeight: 400,
        color: '#7B7D7E',
    },
    travelPlace: {
        fontSize: 12,
        color: theme.palette.primary.main,
    },
    placeIcon: {
        width: 14,
        height: 14,
        color: theme.palette.primary.main,
    },
    seatIcon: {
        width: 16,
        height: 16,
    },
    seatContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        fontSize: 12,
    },

    captainJudgment: {
        fontSize: 12,
    },

    documentContainer: {
        width: '100%',
        border: 'solid 1px rgba(0, 0, 0, 0.2)',
        borderRadius: 5,
        height: 70,
        borderStyle: 'dashed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    AddDocIcon: {
        color: 'grey',
        height: 30,
        width: 30,
        opacity: 0.5,
    },
})
