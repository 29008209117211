export default {
    valid: {
        FR: 'Confirmer',
        EN: 'Validate',
    },
    cancel: {
        FR: 'Annuler',
        EN: 'Cancel',
    },
    question: {
        FR:
            'Êtes-vous sur de vouloir supprimer cette navigation ? Toutes les données correspondantes seront effacées et ne pourront pas être récupérées.',
        EN:
            'Are you sure you want to delete this navigation? All corresponding data will be deleted and cannot be recovered.',
    },
}
