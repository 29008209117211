export default theme => ({
    root: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
    },
})
