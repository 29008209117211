export const navigationApiDataConstants = {
    REQUEST_NAVIGATION_WEATHER: 'REQUEST_NAVIGATION_WEATHER',
    REQUEST_NAVIGATION_WEATHER_SUCCESS: 'REQUEST_NAVIGATION_WEATHER_SUCCESS',
    REQUEST_NAVIGATION_WEATHER_ERROR: 'REQUEST_NAVIGATION_WEATHER_ERROR',
    REQUEST_CURRENT_WEATHER: 'REQUEST_CURRENT_WEATHER',
    REQUEST_CURRENT_WEATHER_SUCCESS: 'REQUEST_CURRENT_WEATHER_SUCCESS',
    REQUEST_CURRENT_WEATHER_ERROR: 'REQUEST_CURRENT_WEATHER_ERROR',
    REQUEST_NAVIGATION_AIS: 'REQUEST_NAVIGATION_AIS',
    REQUEST_NAVIGATION_AIS_SUCCESS: 'REQUEST_NAVIGATION_AIS_SUCCESS',
    REQUEST_NAVIGATION_AIS_ERROR: 'REQUEST_NAVIGATION_AIS_ERROR',
    SWITCH_NAVIGATION: 'SWITCH_NAVIGATION',
    REQUEST_NAVIGATION_LAST_AIS_SUCCESS: 'REQUEST_NAVIGATION_LAST_AIS_SUCCESS',
    REQUEST_NAVIGATION_AIS_BY_GROUP: 'REQUEST_NAVIGATION_AIS_BY_GROUP',
}
