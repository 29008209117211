export default theme => ({
    root: {
        paddingTop: 10,
    },
    filterContainer: {
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 0,
        },
    },
    newNavigation: {
        marginTop: 20,
        marginBottom: 20,
    },
    noContent: {},
    seeMore: {
        width: 'fit-content',
        margin: 'auto',
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        fontSize: 20,
        padding: '15px 0px',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    filterExportAdd: {
        display: 'flex',
        flexDirection: 'row',
    },
})
