export default {
    return: {
        FR: `Retour`,
        EN: 'Return',
    },
    continue: {
        FR: 'Continuer',
        EN: 'Continue',
    },
}
