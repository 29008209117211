export default theme => ({
    alert: {
        '&.MuiPaper-root': {
            backgroundColor: `${theme.palette.primary.main}19`,
            border: `1px solid ${theme.palette.primary.main}19`,
        },
        '&.MuiPaper-root>.MuiAlert-icon': {
            color: theme.palette.primary.main,
        },
        '&.MuiPaper-root>.MuiAlert-message': {
            color: theme.palette.primary.main,
        },
    },
})
