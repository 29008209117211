export default {
    refresh: {
        FR: 'Actualiser la recherche quand je déplace la carte',
        EN: 'Refresh the search when I move the map',
    },
    liveSearch: {
        FR: `Recherche en temps réelle`,
        EN: `Live searching`,
    },
}
