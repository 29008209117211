import React from 'react'
import { connect } from 'react-redux'
import BoatOnComponent from '../common/BoatOnComponent'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/PaymentTabCss'
import dictionary from './Dictionary/PaymentTabDico'
import Typography from '@material-ui/core/Typography'
import { userActions } from '../../actions/user.actions'
import { Button } from '../common/BoatOnButton'
import { CLIENT_ID } from '../../services/config.service'

class PaymentTab extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            stripeAccountID: null,
            urlStripeAccount: null,
            urlStripeCustomer: null,
            urlStripeSetup: null,
            locationDone: null,
        }
        this._showPortailClient = this._showPortailClient.bind(this)
        this._showExpressPortail = this._showExpressPortail.bind(this)
    }

    componentDidMount() {
        if (!this.state.urlStripeSetup && !this.state.urlStripeAccount) {
            this.calculateUrlToStripe()
        }
    }

    componentDidUpdate(prevProps) {
        if (
            !this.state.urlStripeSetup &&
            (!this.state.urlStripeAccount || !this.state.urlStripeCustomer) &&
            prevProps.update &&
            !this.props.update
        )
            this.calculateUrlToStripe()
    }

    async calculateUrlToStripe() {
        const { user } = this.props

        if (!user) {
            return
        }
        if (user.stripeAccountId) {
            const urlLoginLink = await this.props.dispatch(
                userActions.getStripeLoginLink(user.stripeAccountId),
            )
            this.setState({ urlStripeAccount: urlLoginLink.url })

            return
        }
        let phone = ''
        const email = user.email || ''
        const firstName = user.firstName || ''
        const lastName = user.lastName || ''
        const contactDetail =
            user.contactDetails.find(
                contact => contact?.contactType?.id === 1,
            ) || null
        if (contactDetail) phone = contactDetail.contact
        const city = user?.address?.city || ''
        const zipcode = user?.address?.zipcode || ''
        const fullAddress = user?.address?.fullAddress || ''

        phone =
            phone.indexOf('+33') === 0
                ? phone.substr(3) // format phone number de stripe +33 x xx xx xx, il n'y a pas 0
                : phone.substr(1)

        if (email) {
            const urlStripeSetup = `https://connect.stripe.com/express/oauth/authorize?response_type=code&amp;client_id=${CLIENT_ID}&amp;&stripe_user[first_name]=${firstName}&amp;&stripe_user[last_name]=${lastName}&amp;stripe_user[email]=${email}&amp;stripe_user[country]=FR&amp;stripe_user[phone_number]=${phone}&amp;stripe_user[url]=https%3A%2F%2Fwww.boaton.fr&amp;stripe_user[city]=${city}&amp;stripe_user[zip]=${zipcode}&amp;stripe_user[street_address]=${fullAddress}&amp;redirect_uri=https://boaton.fr/profile&amp;scope=read_write`
            this.setState({ urlStripeSetup })
        }
    }

    async _showPortailClient() {
        const url = await userActions.showClientPortail()
        window.location.assign(url)
    }

    _showExpressPortail() {
        const { user } = this.props
        if (user.stripeAccountId && this.state.urlStripeAccount) {
            window.location.assign(this.state.urlStripeAccount)
        } else if (!user.stripeAccountId && this.state.urlStripeSetup) {
            window.location.assign(this.state.urlStripeSetup)
        }
    }

    renderPaiement() {
        const { classes, user } = this.props
        if (user && user.stripeCustomerId) {
            // a changer avec la reponse du nombre de parking > 0 && urlStripeCustomer
            return (
                <div className={classes.container}>
                    <Typography className={classes.subtitle}>
                        {this.displayText('paymentHistory')}
                    </Typography>
                    <Typography>
                        {this.displayText('textPaymentHistory')}
                    </Typography>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="connection"
                        title="connection"
                        className={classes.connectButton}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this._showPortailClient}
                            classNameOverride={classes.button}
                            label={this.displayText('paiementButton')}
                        ></Button>
                    </a>
                </div>
            )
        } else return <></>
    }

    renderMode() {
        const { classes, user } = this.props
        if (user && user.stripeCustomerId) {
            return (
                <div className={classes.container}>
                    <Typography className={classes.subtitle}>
                        {this.displayText('mode')}
                    </Typography>
                    <Typography>{this.displayText('textMode')}</Typography>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="connection"
                        title="connection"
                        className={classes.connectButton}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            onClick={this._showPortailClient}
                            classNameOverride={classes.button}
                            label={this.displayText('modeButton')}
                        ></Button>
                    </a>
                </div>
            )
        } else return <></>
    }

    renderSub() {
        const { classes, user, userMore, parking } = this.props
        if (user && user.stripeCustomerId) {
            return (
                <div className={classes.container}>
                    <Typography className={classes.subtitle}>
                        {this.displayText('subBob')}
                    </Typography>
                    <Typography>
                        {this.displayText('textSubBobFirst')}{' '}
                        {this.displayText('textSubBobSecond')}
                    </Typography>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        alt="connection"
                        title="connection"
                        className={classes.connectButton}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            classNameOverride={classes.button}
                            onClick={this._showPortailClient}
                            label={this.displayText('subButton')}
                        ></Button>
                    </a>
                </div>
            )
        } else return <></>
    }
    renderReceive() {
        const { classes, user, parking } = this.props
        if (parking.length > 0 && user && !user.stripeAccountId) {
            return (
                <div className={classes.container}>
                    <Typography className={classes.subtitle}>
                        {this.displayText('transferHistory')}
                    </Typography>
                    <Typography>
                        {this.displayText('textTransferHistory')}
                    </Typography>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        title="transfert"
                        alt="transfert"
                        className={classes.connectButton}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            classNameOverride={classes.button}
                            label={this.displayText('receiveButton')}
                            onClick={this._showExpressPortail}
                        ></Button>
                    </a>
                </div>
            )
        } else if (parking.length > 0 && user && user.stripeAccountId) {
            return (
                <div className={classes.container}>
                    <Typography className={classes.subtitle}>
                        {this.displayText('transferHistory')}
                    </Typography>
                    <Typography>
                        {this.displayText('textTransferHistory')}
                    </Typography>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        title="transfert"
                        alt="transfert"
                        className={classes.connectButton}
                    >
                        <Button
                            color="primary"
                            variant="contained"
                            classNameOverride={classes.button}
                            label={this.displayText('receiveButton')}
                            onClick={this._showExpressPortail}
                        ></Button>
                    </a>
                </div>
            )
        } else return <></>
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <Typography className={classes.title}>
                    {this.displayText('paiementAndReceive')}
                </Typography>
                {this.renderMode()}
                {this.renderSub()}
                {this.renderPaiement()}
                {this.renderReceive()}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        locationDone: state.fetch.locationDone
            ? state.fetch.locationDone.success
            : null,
        portail: state.fetch.portail,
        parking: state.allParkings.personalParkings,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(PaymentTab))
