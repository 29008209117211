import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import BoatOnComponent from './BoatOnComponent'
import dictionary from './Dictionary/SaveBarDico'

const styles = theme => ({
    save: {
        color: 'black',
        '&:hover': {
            backgroundColor: '#3040a0',
            color: 'white',
        },
        float: 'right',
        marginRight: '15px',
        marginLeft: '15px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    saveBottom: {
        display: 'flex',
        flexDirection: 'row',
        position: 'fixed',
        bottom: 0,
        right: '50%',
        transform: 'translate(+50%)',
        width: '100%',
        backgroundColor: 'white',
        borderTop: '3px solid #c4c4c4',
        borderRight: '3px solid #c4c4c4',
        borderLeft: '3px solid #c4c4c4',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
        zIndex: 1500,
        padding: '12px',
    },
    elementModif: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
})

class SaveBar extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            save: props.save ? JSON.parse(JSON.stringify(props.save)) : null,
        }

        this.handleSave = this.handleSave.bind(this)
        this.getNbModif = this.getNbModif.bind(this)
    }

    componentDidUpdate = props => {
        if ((this.state.save === false && props.save) || props.resetSave) {
            this.setState({ save: JSON.parse(JSON.stringify(props.save)) })
            if (props.resetSave) this.props.handleResetSave()
        }
    }

    async handleSave() {
        const isOk = await this.props.onSave()

        if (isOk) this.setState({ save: false })
    }

    getNbModif(nb, save, newSave) {
        for (const key in save) {
            if (typeof save[key] === 'object' && newSave)
                nb = this.getNbModif(nb, save[key], newSave[key])
            else if (
                (!newSave || save[key] !== newSave[key]) &&
                key !== 'UserID' &&
                key !== 'id'
            ) {
                nb += 1
            }
        }
        return nb
    }

    render() {
        const { classes } = this.props
        var nbModif = this.props.nbModif

        if (!nbModif) {
            let moreSave = this.getNbModif(0, this.state.save, this.props.new)
            let moreNew = this.getNbModif(0, this.props.new, this.state.save)
            nbModif = moreSave > moreNew ? moreSave : moreNew
        }

        if (nbModif > 0) {
            return (
                <div className={classes.saveBottom}>
                    <Button
                        type="submit"
                        className={classes.save}
                        color="secondary"
                        variant="contained"
                        onClick={this.handleSave}
                    >
                        {this.displayText('save')}
                    </Button>
                    <Button
                        type="reset"
                        className={classes.save}
                        color="secondary"
                        variant="contained"
                        onClick={e => {
                            this.props.onCancel(
                                JSON.parse(JSON.stringify(this.state.save)),
                            )
                        }}
                    >
                        {this.displayText('cancel')}
                    </Button>
                    <Typography className={classes.elementModif}>
                        {nbModif}{' '}
                        {nbModif === 1
                            ? this.displayText('oneElement')
                            : this.displayText('multiElement')}
                    </Typography>
                </div>
            )
        }
        return null
    }
}

export default withStyles(styles)(SaveBar)
