export default {
    save: {
        FR: 'Enregistrer',
        EN: 'Save',
    },
    startAlert: {
        FR:
            'Vous êtes sur le point de démarrer votre navigation, indiquez le nombre de passagers montés à bord.',
        EN:
            'You will start your navigation, indicate the number of passengers on board.',
    },
    endStopoverAlert: {
        FR:
            'Vous êtes sur le point de terminer votre escale, indiquez le nombre de passagers descendus et montés à bord.',
        EN:
            'You will end your stopover, indicate the number of passengers landed and boarded.',
    },
    passengersNumber: {
        FR: 'Nombre de passagers',
        EN: 'Number of passengers',
    },
    passengersNumberOut: {
        FR: 'Nombre de passagers descendus',
        EN: 'Number of passengers landed',
    },
    passengersNumberIn: {
        FR: 'Nombre de passagers montés',
        EN: 'Number of passengers boarded',
    },
    aisWithoutAddress: {
        FR: `Remplir une adresse`,
        EN: 'Fill an address',
    },
}
