export default {
    date: {
        FR: 'Date',
        EN: 'Date',
    },
    boat: {
        FR: 'Mon bateau',
        EN: 'My boat',
    },
    distance: {
        FR: 'Distance',
        EN: 'Distance',
    },
    duration: {
        FR: 'Durée navigation',
        EN: 'Navigation time',
    },
    passengers: {
        FR: 'Passagers',
        EN: 'Passengers',
    },
    captain: {
        FR: 'Capitaine',
        EN: 'Captain',
    },
    crew: {
        FR: `Membres d'équipage`,
        EN: `Crew members`,
    },
    passengersOnBoard: {
        FR: 'Passagers à bord',
        EN: 'Passengers on board',
    },
    inProgress: {
        FR: 'Navigation en cours',
        EN: 'Navigation in progress',
    },
    notStarted: {
        FR: 'Navigation prête',
        EN: 'Navigation ready',
    },
    nauticMille: {
        FR: 'M',
        EN: 'Nm',
    },
}
