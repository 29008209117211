import React from 'react'
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
} from 'react-stripe-elements'
import { withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'
import Typography from '@material-ui/core/Typography'
import BoatOnComponent from '../BoatOnComponent'
import styles from './Styles/CreditCardCss'
import dictionary from './Dictionary/CreditCardDico'

class CreditCard extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    render() {
        const { classes, loading } = this.props

        const lang = this.context
        if (!loading) {
            this.renderLoading('100px')
        }

        return (
            <div className={classes.root}>
                <Typography variant={`subtitle2`} gutterBottom>
                    {this.displayText('creditCard')}
                </Typography>
                <div className={classes.firstLine}>
                    <CardNumberElement
                        className={classes.cardNumber}
                        style={{
                            base: {
                                color: '#000',
                                fontSize: '20px',
                                height: '100%',
                            },
                        }}
                    />
                    <CardExpiryElement
                        className={classes.cardExpiry}
                        style={{
                            base: {
                                color: '#000',
                                fontSize: '20px',
                                height: '100%',
                            },
                        }}
                        placeholder={lang === 'en' ? 'MM/YY' : 'MM/AA'}
                    />
                    <CardCVCElement
                        className={classes.cardCvc}
                        style={{
                            base: {
                                color: '#000',
                                fontSize: '20px',
                                height: '100%',
                            },
                        }}
                    />
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        loading: state.fetch.loading,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(CreditCard))
