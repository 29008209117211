import { boatsConstants } from '../constants'

const initialState = {
    boats: null,
    linkedBoats: null,
    loading: 0,
    numberBoats: undefined,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case boatsConstants.REQUEST_BOATS:
            return {
                ...prevState,
                boats: null,
                loading: prevState.loading + 1,
            }
        case boatsConstants.POST_BOATS:
            return {
                ...prevState,
                boats: null,
                loading: prevState.loading + 1,
            }
        case boatsConstants.BOATS_SUCCESS:
            return {
                ...prevState,
                boats: action.boats,
                loading: prevState.loading - 1,
            }
        case boatsConstants.POST_BOATS_TO_GROUP:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case boatsConstants.BOATS_TO_GROUP_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case boatsConstants.BOATS_UPDATE_SUCCESS:
            const updateBoats = prevState.boats.map(boat => {
                if (boat.id === action.boat.id) {
                    return action.boat
                }
                return boat
            })
            return {
                ...prevState,
                boats: updateBoats,
            }
        case boatsConstants.BOATS_FAILURE:
            return {
                ...prevState,
                boats: null,
                loading: prevState.loading - 1,
            }
        case boatsConstants.BOATS_TO_GROUP_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case boatsConstants.REQUEST_NB_BOATS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
                numberBoats: undefined,
            }
        case boatsConstants.REQUEST_NB_BOATS_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                numberBoats: action.numberBoats,
            }
        case boatsConstants.REQUEST_NB_BOATS_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                numberBoats: undefined,
            }
        case boatsConstants.RESET_BOATS:
            return { ...initialState, loading: prevState.loading }
        case boatsConstants.REQUEST_LINKED_BOATS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case boatsConstants.LINKED_BOATS_SUCCESS:
            return {
                ...prevState,
                linkedBoats: action.linkedBoats,
                loading: prevState.loading - 1,
            }
        case boatsConstants.LINKED_BOATS_FAILURE:
            return {
                ...prevState,
                linkedBoats: [],
                loading: prevState.loading - 1,
            }
        case boatsConstants.UPDATE_LINKED_BOAT_WITHOUT_LOADING:
            return {
                ...prevState,
                linkedBoats: prevState.linkedBoats.map(boat => {
                    if (boat.id === action.boat.id)
                        return {
                            ...boat,
                            ...action.boat,
                            boatType: {
                                ...boat.boatType,
                            },
                        }
                    return boat
                }),
            }
        default:
            return prevState
    }
}
