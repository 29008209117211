export const absenceConstants = {
    POST_ABSENCE: 'POST_ABSENCE',
    POST_ABSENCE_SUCCESS: 'POST_ABSENCE_SUCCESS',
    POST_ABSENCE_ERROR: 'POST_ABSENCE_ERROR',
    PUT_ABSENCE: 'PUT_ABSENCE',
    PUT_ABSENCE_SUCCESS: 'PUT_ABSENCE_SUCCESS',
    PUT_ABSENCE_ERROR: 'PUT_ABSENCE_ERROR',
    DELETE_ABSENCE: 'DELETE_ABSENCE',
    DELETE_ABSENCE_SUCCESS: 'DELETE_ABSENCE_SUCCESS',
    DELETE_ABSENCE_FAILURE: 'DELETE_ABSENCE_FAILURE',
    USER_ABSENCES_REQUEST: 'USER_ABSENCES_REQUEST',
    USER_ABSENCES_SUCCESS: 'USER_ABSENCES_SUCCESS',
    USER_ABSENCES_ERROR: 'USER_ABSENCES_ERROR',
    TOTAL_USER_ABSENCES_REQUEST: 'TOTAL_USER_ABSENCES_REQUEST',
    TOTAL_USER_ABSENCES_SUCCESS: 'TOTAL_USER_ABSENCES_SUCCESS',
    TOTAL_USER_ABSENCES_ERROR: 'TOTAL_USER_ABSENCES_ERROR',
    GROUP_ABSENCES_REQUEST: 'GROUP_ABSENCES_REQUEST',
    GROUP_ABSENCES_SUCCESS: 'GROUP_ABSENCES_SUCCESS',
    GROUP_ABSENCES_ERROR: 'GROUP_ABSENCES_ERROR',
    USER_ABSENCES_MANAGER_REQUEST: 'USER_ABSENCES_MANAGER_REQUEST',
    USER_ABSENCES_MANAGER_SUCCESS: 'USER_ABSENCES_MANAGER_SUCCESS',
    USER_ABSENCES_MANAGER_ERROR: 'USER_ABSENCES_MANAGER_ERROR',
    CLEAR_GROUP_ABSENCES: 'CLEAR_GROUP_ABSENCES',
    GET_USER_LINKS_PAID_VACATIONS_ALLOWED:
        'GET_USER_LINKS_PAID_VACATIONS_ALLOWED',
    GET_USER_LINKS_PAID_VACATIONS_ALLOWED_SUCCESS:
        'GET_USER_LINKS_PAID_VACATIONS_ALLOWED_SUCCESS',
    GET_USER_LINKS_PAID_VACATIONS_ALLOWED_FAILURE:
        'GET_USER_LINKS_PAID_VACATIONS_ALLOWED_FAILURE',
    RESET_USER_LINKS_PAID_VACATIONS: 'RESET_USER_LINKS_PAID_VACATIONS',
}
