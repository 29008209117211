import {
    groupConstants,
    boatsConstants,
    bobEventsConstants,
    absenceConstants,
} from '../constants'
import { requestApi } from '../services/api.service'
import { userActions } from './user.actions'
import { getGroupById } from './'
import { absencesActions } from './bob/absences.actions'

const requestUserGroups = idGroupe => {
    return dispatch => {
        dispatch({ type: groupConstants.GET_GROUPS })
        return requestApi(`/user-groups/${idGroupe}`, `GET`).then(
            result => {
                dispatch({
                    type: groupConstants.GET_GROUPS_SUCCESS,
                    groups: result,
                    groupId: result.id,
                })
                return result
            },
            error => {
                dispatch({ type: groupConstants.GET_GROUPS_FAILURE, error })
            },
        )
    }
}

const requestRoles = () => {
    return dispatch => {
        dispatch({ type: groupConstants.GET_ROLES })
        return requestApi(`/user-roles`, `GET`).then(
            result => {
                dispatch({
                    type: groupConstants.GET_ROLES_SUCCESS,
                    roles: result,
                })
                return result
            },
            error => {
                dispatch({ type: groupConstants.GET_ROLES_FAILURE, error })
            },
        )
    }
}

const deleteUserFromGroup = linkId => {
    return dispatch => {
        dispatch({ type: groupConstants.REMOVE_USER_GROUP })
        return requestApi(`/link-role-group-user/${linkId}`, `DELETE`).then(
            result => {
                dispatch({
                    type: groupConstants.REMOVE_USER_GROUP_SUCCESS,
                    groups: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: groupConstants.REMOVE_USER_GROUP_FAILURE,
                    error,
                })
            },
        )
    }
}

const changeGroupName = (nomGroupe, id) => {
    return dispatch => {
        dispatch({ type: groupConstants.CHANGE_NAME })
        return requestApi(`/user-groups/${id}`, `PUT`, {
            name: nomGroupe,
        }).then(
            result => {
                dispatch({
                    type: groupConstants.CHANGE_NAME_SUCCESS,
                    groups: result,
                })
                userActions.init()
                return result
            },
            error => {
                dispatch({ type: groupConstants.CHANGE_NAME_FAILURE, error })
            },
        )
    }
}

const createGroup = nomGroupe => {
    return dispatch => {
        dispatch({ type: groupConstants.CREATE_GROUP })
        return requestApi(`/user-groups`, `POST`, { name: nomGroupe }).then(
            result => {
                dispatch({
                    type: groupConstants.CREATE_GROUP_SUCCESS,
                    groupId: result.id,
                    groups: result,
                })
                userActions.init()
                return result
            },
            error => {
                dispatch({ type: groupConstants.CREATE_GROUP_FAILURE, error })
            },
        )
    }
}

const addUserToGroup = (idGroupe, role, boatGroup, email, language) => {
    var boatGroupId = []
    for (var i = 0; i < boatGroup.length; i++) {
        boatGroupId.push({ id: boatGroup[i].id })
    }
    return dispatch => {
        dispatch({ type: groupConstants.ADD_USER_GROUP })
        return requestApi(
            `/link-role-group-user`,
            `POST`,
            {
                userRole: { id: role },
                userSubscribe: { mail: email },
                userGroup: { id: idGroupe },
                boats: boatGroupId,
            },
            false,
            true,
            language,
        ).then(
            result => {
                dispatch({
                    type: groupConstants.ADD_USER_GROUP_SUCCESS,
                    groups: result,
                    groupId: result.id,
                })
                return result
            },
            error => {
                dispatch({ type: groupConstants.ADD_USER_GROUP_FAILURE, error })
            },
        )
    }
}

const updateUserLink = data => {
    return dispatch => {
        dispatch({ type: groupConstants.EDIT_USER_LINK })
        return requestApi(
            `/link-role-group-user/updateUser/${data.userLinkId}`,
            `PUT`,
            data,
        ).then(
            result => {
                dispatch({
                    type: groupConstants.EDIT_USER_LINK_SUCCESS,
                })
                dispatch(requestUserGroups(data.userGroupId))
            },
            error => {
                dispatch({
                    type: groupConstants.EDIT_USER_LINK_FAILURE,
                    error,
                })
            },
        )
    }
}

const updateMultipleUserLink = (usersToUpdate, userGroupId) => {
    return dispatch => {
        dispatch({ type: groupConstants.EDIT_USER_LINK })

        Promise.allSettled(
            usersToUpdate.map(user => {
                return new Promise((resolve, reject) => {
                    requestApi(
                        `/link-role-group-user/updateUser/${user.userLinkId}`,
                        `PUT`,
                        user,
                    )
                        .then(result => {
                            resolve(result)
                        })
                        .catch(error => {
                            reject()
                        })
                })
            }),
        ).then(
            results => {
                dispatch({
                    type: groupConstants.EDIT_USER_LINK_SUCCESS,
                })
                dispatch(requestUserGroups(userGroupId))
            },
            error => {
                dispatch({
                    type: groupConstants.EDIT_USER_LINK_FAILURE,
                    error,
                })
            },
        )
    }
}

const editUserToGroup = (
    idLinkRGU,
    companyName,
    siret,
    address,
    phone,
    userId,
    role,
    companyId,
    contactId,
    userGroupId,
    boats,
    right,
    hasCompany,
    canEditCompany,
) => {
    return dispatch => {
        dispatch({ type: groupConstants.EDIT_USER_GROUP })
        let addressFinal = address
            ? address.fullText
                ? {
                      fullAddress: address.fullText,
                      latitude: address.latitude.toString(),
                      longitude: address.longitude.toString(),
                  }
                : {
                      fullAddress: address.fullAddress,
                      latitude: address.latitude.toString(),
                      longitude: address.longitude.toString(),
                  }
            : null

        let contactFinal = phone
            ? [
                  {
                      contact: phone,
                      contactType: {
                          id: 1,
                      },
                  },
              ]
            : null
        if (contactId) {
            contactFinal = [{ ...contactFinal[0], id: contactId }]
        }
        const companyFinal = companyId
            ? {
                  id: companyId,
                  companyName: companyName,
                  siret: siret,
                  address: addressFinal,
                  contactDetails: contactFinal,
              }
            : companyName !== ''
            ? {
                  companyName: companyName,
                  siret: siret,
                  address: addressFinal,
                  contactDetails: contactFinal,
              }
            : null

        const data = userId
            ? {
                  userRole: role,
                  user: {
                      id: userId,
                      company:
                          hasCompany && canEditCompany
                              ? companyFinal
                              : undefined,
                  },
                  userGroupId: userGroupId,
                  boats: boats.map(boat => ({ id: boat.id })),
                  rights: right,
                  hasCompany: hasCompany,
              }
            : {
                  userRole: role,
                  userGroupId: userGroupId,
                  boats: boats.map(boat => ({ id: boat.id })),
                  rights: right,
                  hasCompany: hasCompany,
              }
        return requestApi(
            `/link-role-group-user/updateUser/${idLinkRGU}`,
            `PUT`,
            data,
        ).then(
            result => {
                dispatch({
                    type: groupConstants.EDIT_USER_GROUP_SUCCESS,
                    groups: result.group,
                    roles: result.roles,
                })
                return result
            },
            error => {
                dispatch({
                    type: groupConstants.EDIT_USER_GROUP_FAILURE,
                    error,
                })
            },
        )
    }
}

const getRoles = async () => {
    let result = await requestApi(`/user-roles`, `GET`)
    return result
}

const _updateAllState = id => {
    if (id) {
        return dispatch => {
            dispatch(getGroupById(id))
            dispatch({ type: boatsConstants.RESET_BOATS })
            dispatch({ type: bobEventsConstants.RESET })
            localStorage.setItem('groupIdSelect', id)
        }
    }
}

const changeCurrentGroup = id => {
    if (id !== null && !isNaN(id)) {
        return dispatch => {
            dispatch({
                type: groupConstants.START_CHANGE_GROUP,
                groupId: id,
            })
            dispatch(_updateAllState(id))
            dispatch(requestUserGroups(id))
            dispatch(absencesActions.clearGroupAbsences())
            dispatch({
                type: groupConstants.END_CHANGE_GROUP,
            })
            dispatch({
                type: absenceConstants.RESET_USER_LINKS_PAID_VACATIONS,
            })
        }
    }
}

const getFleetInfos = groupId => {
    return dispatch => {
        dispatch({ type: groupConstants.GET_FLEET_INFOS })
        return requestApi(`/user-groups/${groupId}/fleet-infos`, `GET`).then(
            result => {
                dispatch({
                    type: groupConstants.GET_FLEET_INFOS_SUCCESS,
                    fleetInfos: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: groupConstants.GET_FLEET_INFOS_FAILURE,
                    error,
                })
            },
        )
    }
}

const requestUserGroupLinked = id => {
    return dispatch => {
        dispatch({ type: groupConstants.GET_LINKED_GROUP })

        requestApi(`/user-groups/${id}`, 'GET').then(
            result => {
                dispatch({
                    type: groupConstants.GET_LINKED_GROUP_SUCCESS,
                    linkedGroup: result,
                })
            },
            error => {
                dispatch({ type: groupConstants.GET_LINKED_GROUP_FAILURE })
            },
        )
    }
}

const getAdditionalUserInfo = (userId, companyId) => {
    return dispatch => {
        dispatch({ type: groupConstants.REQUEST_ADDITIONAL_USER_INFO })

        requestApi(
            `/link-user-company/user/${userId}/company/${companyId}`,
            'GET',
        ).then(
            result => {
                dispatch({
                    type: groupConstants.GET_ADDITIONAL_USER_INFO_SUCCESS,
                    additionalUserInfo: result,
                })
            },
            error => {
                dispatch({
                    type: groupConstants.GET_ADDITIONAL_USER_INFO_FAILURE,
                })
            },
        )
    }
}

const editAdditionalUserInfo = additionalUserInfo => {
    return dispatch => {
        dispatch({ type: groupConstants.REQUEST_ADDITIONAL_USER_INFO })

        if (additionalUserInfo?.id) {
            requestApi(
                `/link-user-company/${additionalUserInfo.id}`,
                'PUT',
                additionalUserInfo,
            ).then(
                result => {
                    dispatch({
                        type:
                            groupConstants.UPDATE_ADDITIONAL_USER_INFO_SUCCESS,
                        additionalUserInfo: result,
                    })
                },
                error => {
                    dispatch({
                        type:
                            groupConstants.UPDATE_ADDITIONAL_USER_INFO_FAILURE,
                    })
                },
            )
        } else {
            requestApi(`/link-user-company`, 'POST', additionalUserInfo).then(
                result => {
                    dispatch({
                        type:
                            groupConstants.UPDATE_ADDITIONAL_USER_INFO_SUCCESS,
                        additionalUserInfo: result,
                    })
                },
                error => {
                    dispatch({
                        type:
                            groupConstants.UPDATE_ADDITIONAL_USER_INFO_FAILURE,
                    })
                },
            )
        }
    }
}

export const groupActions = {
    changeGroupName,
    requestUserGroups,
    deleteUserFromGroup,
    addUserToGroup,
    getRoles,
    changeCurrentGroup,
    createGroup,
    editUserToGroup,
    requestRoles,
    getFleetInfos,
    updateUserLink,
    updateMultipleUserLink,
    requestUserGroupLinked,
    getAdditionalUserInfo,
    editAdditionalUserInfo,
}
