export default theme => ({
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40vw',
        backgroundColor: theme.palette.background.paper,
        border: '5px solid #3040a0',
        borderRadius: 5,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    preChoice: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    dividers: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '80%',
        marginTop: '25px',
        marginBottom: '25px',
    },
    divider: {
        width: '50%',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginRight: '20px',
        marginLeft: '20px',
    },
    button: {
        marginTop: '25px',
        margin: 'auto',
        display: 'block',
    },
    helpText: {
        fontSize: '1.2rem',
    },
})
