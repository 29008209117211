import React from 'react'
import { connect } from 'react-redux'
import BoatOnComponent from '../../common/BoatOnComponent'
import styles from './Styles/HourAlertCss'
import { withStyles } from '@material-ui/core/styles'
import BoatOnAlert from '../../common/UsefullComponents/BoatOnAlert'
import dictionary from './Dictionary/HourAlertDico'
import { TextField, Typography } from '@material-ui/core'
import { Button } from '../../common/BoatOnButton'
import { putBOBEvents } from '../../../actions'
import BoatOnBlock from '../../common/Blocks/BoatOnBlock'
import { fixIosHeaderBug } from '../../../utils/usefullFunctions'
import { Actions, PermissionsEntities } from '../../../constants'

const nbDayForInPeriod = 30
const nbDayForOutPeriod = 15
const isInPeriod =
    (new Date().getMonth() >= 0 && new Date().getMonth() <= 3) ||
    (new Date().getMonth() >= 8 && new Date().getMonth() <= 11)
const isOutPeriod = new Date().getMonth() >= 4 && new Date().getMonth() <= 7

class HourAlert extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary

        this.state = {
            events: null,
            newEvents: null,
            lastTimePopupSeen: JSON.parse(
                localStorage.getItem('lastTimePopupSeen'),
            ),
        }
    }

    componentDidMount() {
        if (this.props.events) {
            this.initState()
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.events && !prevProps.events) {
            this.initState()
        }
    }

    whenShouldBeHourUpdated(lastHourActualUpdate) {
        const today = new Date()
        const lastUpdate = new Date(lastHourActualUpdate)
        const dayBetweenBothDate = Math.ceil(
            (today.getTime() - lastUpdate.getTime()) / (1000 * 3600 * 24),
        )

        if (isInPeriod && dayBetweenBothDate >= nbDayForInPeriod) return true
        else if (isOutPeriod && dayBetweenBothDate >= nbDayForOutPeriod)
            return true
        return false
    }

    initState() {
        const events = this.props.events.filter(
            event =>
                event?.detail?.equipment &&
                event?.delimitedDate?.startDate &&
                !event?.detail?.archived &&
                (event?.detail?.hourReplacement !== null ||
                    event?.detail?.hourActual !== null) &&
                (!event?.detail?.lastHourActualUpdate ||
                    (event?.detail?.lastHourActualUpdate &&
                        this.whenShouldBeHourUpdated(
                            event.detail.lastHourActualUpdate,
                        ))),
        )

        this.setState({
            events: events,
            newEvents: events.map(event => ({
                ...event,
                detail: { ...event.detail, hourActual: null },
            })),
        })
    }

    updateNewEventHour(event, e) {
        const { newEvents } = this.state
        const regex = /^[0-9\b]+$/
        if (e.target.value === '' || regex.test(e.target.value)) {
            this.setState({
                newEvents: newEvents.map(newEvent => {
                    if (newEvent.id === event.id) {
                        newEvent.detail.hourActual =
                            e.target.value === ''
                                ? null
                                : parseInt(e.target.value)
                        return newEvent
                    }
                    return newEvent
                }),
            })
        }
    }

    saveNewHour(event) {
        const { newEvents } = this.state
        const newEvent = newEvents.find(newEvent => newEvent.id === event.id)
        newEvent.detail.lastHourActualUpdate = new Date()
        this.props.dispatch(
            putBOBEvents(event.id, event.title, {
                detail: newEvent.detail,
            }),
        )
    }

    _renderLine(event) {
        const { classes } = this.props
        const { newEvents } = this.state
        const eventName = event.detail?.equipment?.userEquipment?.name
            ? event.detail?.equipment?.userEquipment?.name
            : this.displayTextApi(
                  event.detail?.equipment.equipmentType.translation,
              )
        const newEvent = newEvents.find(newEvent => newEvent.id === event.id)

        return (
            <div className={classes.line} key={eventName}>
                <Typography
                    style={{ fontWeight: 'bold' }}
                    className={classes.text}
                >
                    {eventName}
                </Typography>
                <div className={classes.group}>
                    <Typography
                        className={classes.text}
                        style={{ minWidth: '195px' }}
                    >
                        {this.displayText('nbSavedHour')}
                    </Typography>
                    <Typography
                        style={{ fontWeight: 'bold' }}
                        className={classes.text}
                    >
                        {event.detail.hourActual || 0} h
                    </Typography>
                </div>
                <div className={classes.groupNoWrap}>
                    <div className={classes.group}>
                        <Typography
                            className={classes.hiddeMobile}
                            style={{ color: 'red', minWidth: '155px' }}
                        >
                            {this.displayText('nbActualHour')}
                        </Typography>
                        <TextField
                            InputProps={{
                                className: classes.input,
                            }}
                            className={classes.hiddeDesktop}
                            id="new-hour"
                            margin="dense"
                            placeholder={this.displayText('nbActualHour')}
                            variant="outlined"
                            value={newEvent?.detail?.hourActual || ''}
                            onChange={e => this.updateNewEventHour(event, e)}
                            fullWidth
                            onBlur={() => fixIosHeaderBug()}
                        />
                        <TextField
                            InputProps={{
                                className: classes.input,
                            }}
                            className={classes.hiddeMobile}
                            id="new-hour"
                            margin="dense"
                            placeholder={'h'}
                            variant="outlined"
                            value={newEvent?.detail?.hourActual || ''}
                            onChange={e => this.updateNewEventHour(event, e)}
                            style={{ width: '100px' }}
                            onBlur={() => fixIosHeaderBug()}
                        />
                    </div>
                    <Button
                        label={this.displayText('validate')}
                        onClick={() => {
                            if (
                                newEvent?.detail?.hourActual === null ||
                                (event?.detail?.hourActual &&
                                    newEvent?.detail?.hourActual <
                                        event?.detail?.hourActual)
                            ) {
                                alert(this.displayText('errorHour'))
                            } else this.saveNewHour(event)
                        }}
                        disabled={newEvent?.detail?.hourActual === null}
                    />
                </div>
            </div>
        )
    }

    renderList() {
        const { events } = this.state
        const { classes } = this.props

        return (
            <div className={classes.content}>
                {events.map(event => this._renderLine(event))}
            </div>
        )
    }

    render() {
        const { events, lastTimePopupSeen } = this.state
        const { boat } = this.props

        if (
            events === null ||
            events.length === 0 ||
            (lastTimePopupSeen?.[boat.id] &&
                !this.whenShouldBeHourUpdated(lastTimePopupSeen?.[boat.id]))
        )
            return <></>

        return (
            <BoatOnBlock
                permissions={[
                    {
                        action: Actions.Read,
                        entityName: PermissionsEntities.InventoryDetail,
                    },
                    {
                        action: Actions.Update,
                        entityName: PermissionsEntities.InventoryDetail,
                    },
                ]}
                children={
                    <BoatOnAlert
                        severity="warning"
                        component={this.renderList()}
                        title={this.displayText('title')}
                        onClose={() => {
                            const newlastTimePopupSeen = {
                                ...lastTimePopupSeen,
                                [boat.id]: new Date(),
                            }
                            localStorage.setItem(
                                'lastTimePopupSeen',
                                JSON.stringify(newlastTimePopupSeen),
                            )
                            this.setState({
                                lastTimePopupSeen: newlastTimePopupSeen,
                            })
                        }}
                    />
                }
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        events: state.bob.events,
        boat: state.bob.boat,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(HourAlert))
