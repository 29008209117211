import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../../common/BoatOnModalCore'
import BoatOnAutoComplete from '../../../../../common/BoatOnAutoComplete'

import HelpIcon from '@material-ui/icons/Help'

import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/EditCrewModalCss'
import dictionary from './Dictionary/EditCrewModalDico'

import { editNavigation } from '../../../../../../actions'

class EditCrewModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            error: 0,

            selectedCaptain: [],
            selectedCrew: [],

            updated: false,
        }

        this.handleCaptainChange = this.handleCaptainChange.bind(this)
        this.handleCrewChange = this.handleCrewChange.bind(this)
        this.getUserName = this.getUserName.bind(this)
        this.isValid = this.isValid.bind(this)
        this.save = this.save.bind(this)
    }

    isValid() {
        const { selectedCaptain, selectedCrew } = this.state

        if (selectedCaptain.length === 0 || selectedCrew.length === 0) {
            this.setState({ error: true })
            return false
        }

        return true
    }

    save() {
        const { selectedCaptain, selectedCrew } = this.state
        const { dispatch, onClose, navigation } = this.props

        if (this.isValid()) {
            navigation.captainLinkId = selectedCaptain[0].id

            navigation.navigationMembers = selectedCrew.map(user => {
                const existingMember = navigation.navigationMembers.find(
                    item => user.id === item.userLinkId,
                )
                if (existingMember) return existingMember

                return {
                    userLinkId: user.id,
                }
            })

            dispatch(editNavigation(navigation))

            onClose()
        }
    }

    componentDidMount() {
        const { navigation, linkedUsers = [] } = this.props

        if (navigation) {
            const selectedCaptain = linkedUsers.filter(
                user => user.id === navigation.captainLinkId,
            )
            const selectedCrew = linkedUsers.filter(user => {
                const navigationMembers = navigation.navigationMembers.map(
                    user => user.userLinkId,
                )

                return navigationMembers.includes(user.id)
            })

            this.setState({
                selectedCaptain,
                selectedCrew,
            })
        }
    }

    handleCaptainChange(_, selectedCaptain) {
        let { selectedCrew } = this.state

        if (selectedCaptain.length > 1) selectedCaptain = [selectedCaptain[1]]

        if (selectedCaptain.length === 1) {
            const index = selectedCrew.findIndex(
                item => item.id === selectedCaptain[0].id,
            )

            if (index !== -1) selectedCrew.splice(index, 1)
        }

        this.setState({
            selectedCaptain,
            selectedCrew,
            updated: true,
        })
    }

    handleCrewChange(_, selectedCrew) {
        this.setState({
            selectedCrew,
            updated: true,
        })
    }

    getUserName(elem) {
        const { linkedUsers } = this.props

        if (elem?.user?.id) {
            let name = ''
            linkedUsers.forEach(link => {
                if (link?.user?.id === elem?.user?.id)
                    name = `${link?.user?.firstName} ${link?.user?.lastName}`
            })
            return name
        } else if (elem?.userSubscribe?.id && elem?.userSubscribe?.mail)
            return elem?.userSubscribe?.mail
        return null
    }

    isOptionEqualToValue(opt, value) {
        if (
            (value?.userSubscribe?.id &&
                value?.userSubscribe?.id === opt?.userSubscribe?.id) ||
            (value?.user?.id && value?.user?.id === opt?.user?.id)
        ) {
            return true
        }
        return false
    }

    isAlreadySelected(opt, selectedOpt) {
        return selectedOpt?.some(
            elem =>
                (opt?.user?.id && opt?.user?.id === elem?.user?.id) ||
                (opt?.userSubscribe?.id &&
                    elem?.userSubscribe?.id === opt?.userSubscribe?.id),
        )
    }

    filterUsers(opt, input) {
        const firstLast = opt?.user?.id
            ? `${opt?.user?.firstName} ${opt?.user?.lastName}`
            : null
        const lastFirst = opt?.user?.id
            ? `${opt?.user?.lastName} ${opt?.user?.firstName}`
            : null

        if (
            !input?.inputValue ||
            (opt?.user?.id &&
                ((firstLast &&
                    firstLast.toLowerCase().includes(input.inputValue)) ||
                    (lastFirst &&
                        lastFirst.toLowerCase().includes(input.inputValue)))) ||
            (opt?.userSubscribe?.id &&
                opt?.userSubscribe?.mail
                    .toLowerCase()
                    .includes(input.inputValue.toLowerCase()))
        ) {
            return true
        }
        return false
    }

    _body() {
        const { classes, linkedUsers = [] } = this.props
        const { error, selectedCaptain, selectedCrew } = this.state

        return (
            <div className={classes.root}>
                <div className={classes.title}>
                    {this.displayText('titleCrew')}
                </div>
                <BoatOnAutoComplete
                    required
                    error={error && selectedCaptain.length === 0}
                    autoComplete
                    multiple
                    renderTagCondition={option =>
                        !option.user && option.userSubscribe
                    }
                    getOptionSelected={this.isOptionEqualToValue}
                    margin="dense"
                    value={selectedCaptain}
                    conditionAlready={opt =>
                        this.isAlreadySelected(opt, selectedCaptain)
                    }
                    options={linkedUsers}
                    label={
                        <>
                            {this.displayText('captain')}
                            <Tooltip
                                title={this.displayText('helpCaptain')}
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <HelpIcon className={classes.helpIcon} />
                            </Tooltip>
                        </>
                    }
                    onChange={this.handleCaptainChange}
                    getElementToShow={this.getUserName}
                    getName={this.getUserName}
                    filterOptions={this.filterUsers}
                />

                <BoatOnAutoComplete
                    required
                    error={error && selectedCrew.length === 0}
                    autoComplete
                    renderTagCondition={option =>
                        !option.user && option.userSubscribe
                    }
                    getOptionSelected={this.isOptionEqualToValue}
                    margin="dense"
                    multiple
                    value={selectedCrew}
                    conditionAlready={opt =>
                        this.isAlreadySelected(opt, [
                            ...selectedCaptain,
                            ...selectedCrew,
                        ])
                    }
                    options={linkedUsers}
                    label={
                        <>
                            {this.displayText('crewInput')}
                            <Tooltip
                                title={this.displayText('helpCrew')}
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <HelpIcon className={classes.helpIcon} />
                            </Tooltip>
                        </>
                    }
                    onChange={this.handleCrewChange}
                    getElementToShow={this.getUserName}
                    getName={this.getUserName}
                    filterOptions={this.filterUsers}
                />
            </div>
        )
    }

    render() {
        const { title, noCross = true, showFollowButton } = this.props
        const { updated } = this.state

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    null,
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    body: this._body(),
                })}
                {this._renderActions([
                    {
                        action: this.save,
                        disabled: !updated,
                        label: this.displayText('save'),
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    const isMobile = window.innerWidth < 600
    const url = window.location.pathname
    const id = url.split('/').slice(-1)[0]
    const navigation = state.logbook?.navigations?.find(
        nav => nav.id === parseInt(id),
    )

    return {
        isMobile,
        navigation,
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
    }
}

export default connect(mapStateToProps)(withStyles(styles)(EditCrewModal))
