const breadcrumbNameMap = {
    fr: {
        'routes.root': 'Accueil',
        'routes.inqueries': 'Mes demandes',
        'routes.inqueries.insurance': 'Assurances',
        'routes.inqueries.insurance.details': 'Détails',
        'routes.inqueries.repairs': 'Réparations',
        'routes.inqueries.parking': 'Parkings',
        'routes.inqueries.chatbox': 'Messagerie',
        'routes.inquery': 'Demande',
        'routes.login': 'Connexion',
        'routes.profile': 'Profil',
        'routes.404': 'Erreur 404',
        'routes.rent-my-parking': 'Louer votre emplacement',
        'routes.messages': 'Mes messages',
        'routes.forget': 'Forget',
        'routes.parkingid': 'Parking',
        'routes.booking': 'Réservation',
        'routes.personnal-infos': 'Informations personnelles',
        'routes.payment-infos': 'Information paiement',
        'routes.parkings-infos': 'Information parking',
        'routes.boat-infos': 'Mon Bateau',
        'routes.blog': 'Blog',
        'routes.search-boat-parking': 'Cherchez un parking',
        'routes.get-boat-insurance': 'Trouver une assurance',
        'routes.profile.boat-infos': 'Mon Bateau',
        'routes.mainsite': 'Nos offres',

        'routes.logbook.pricing': 'Nos offres',
        'routes.logbook.home': 'Journal de bord',
        'routes.logbook.alerts': 'Mes alertes',
        'routes.logbook.inventory': 'Mon inventaire',
        'routes.logbook.fleet': 'Ma flotte',
        'routes.logbook.repairs': 'Mes tâches',
        'routes.logbook.informations': 'Mes informations',
        'routes.logbook.exps-a-incm': 'Mes depenses et revenus',
        'routes.logbook.documents': 'Mes documents',
        'routes.logbook.book': 'Boaton book',
        'routes.logbook.calendar': 'Mon calendrier',
        'routes.logbook.worktime': 'Temps de travail et absences',
        'routes.logbook.order-form': 'Bon de commande',
        'routes.logbook.my-users': 'Mes utilisateurs',
        'routes.logbook.settings.paid-vacations': 'Paramètres des congés payés',
        'routes.logbook.settings.worktimes': 'Paramétrage des temps de travail',
        'routes.redirect': 'Redirect',
        'routes.logbook.logbook': 'Mon journal de bord',
        'routes.logbook.logbookid': 'Ma navigation',
    },
    en: {
        'routes.root': 'Home',
        'routes.inqueries': 'Inqueries',
        'routes.inqueries.insurance': 'Insurances',
        'routes.inqueries.insurance.details': 'Details',
        'routes.inqueries.repairs': 'Repairs',
        'routes.inqueries.parking': 'Parkings',
        'routes.inqueries.chatbox': 'Chat',
        'routes.inquery': 'Inquery',
        'routes.login': 'Login',
        'routes.profile': 'Profile',
        'routes.404': 'Error 404',
        'routes.rent-my-parking': 'Rent my parking',
        'routes.messages': 'Messages',
        'routes.forget': 'Forget',
        'routes.parkingid': 'Parking',
        'routes.booking': 'Booking',
        'routes.personnal-infos': 'Personnal informations',
        'routes.payment-infos': 'Payment informations',
        'routes.parkings-infos': 'Parkings informations',
        'routes.boat-infos': 'My Boat',
        'routes.blog': 'Blog',
        'routes.search-boat-parking': 'Search boat parking',
        'routes.get-boat-insurance': 'Get boat insurance',
        'routes.prices': 'Our offers',
        'routes.mainsite': 'Our offers',

        'routes.logbook.pricing': 'Our offers',
        'routes.logbook.home': 'My boat logbook',
        'routes.logbook.alerts': 'My alerts',
        'routes.logbook.inventory': 'My inventory',
        'routes.logbook.fleet': 'My fleet',
        'routes.logbook.repairs': 'My repairs',
        'routes.logbook.informations': 'Informations',
        'routes.logbook.exps-a-incm': 'My expenses and income',
        'routes.logbook.documents': 'My documents',
        'routes.logbook.calendar': 'My Calendar',
        'routes.logbook.worktime': 'Work time and leave',
        'routes.logbook.book': 'The Boaton Book',
        'routes.logbook.order-form': 'Order form',
        'routes.logbook.my-users': 'My users',
        'routes.logbook.settings.paid-vacations': 'Paid leave settings',
        'routes.logbook.settings.worktimes': 'Work times settings',
        'routes.redirect': 'Redirect',
        'routes.logbook.logbook': 'My logbook',
        'routes.logbook.logbookid': 'My navigation',
    },
}

export default breadcrumbNameMap
