export default theme => ({
    root: {
        display: 'flex',
        flexFlow: 'row ',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    rootBoat: {
        display: 'flex',
        flexFlow: 'column ',
        alignItems: 'center',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },

    testGlobal: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },

    btnView: {
        marginTop: '8px',
        marginLeft: '8px',
        height: '38px',
        boxShadow: 'none',
    },

    test1: {
        width: '100%',
    },

    test2: {
        marginLeft: '1%',
    },

    textTextfield: {
        backgrounColor: 'red',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.4em',
        },
    },

    attachRotate: {
        rotate: '25deg',
        width: '18px',
        height: '18px',
    },

    deleteIcon: {
        width: '15px',
        height: '15px',
    },
    validate: {
        display: 'block',
        margin: 'auto',
        marginTop: '20px',
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: '5px',
        },
    },
    inputList: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    field: {
        width: `100%`,
        color: `black`,
        marginTop: 0,
        '& .MuiSelect-selectMenu': {
            display: `flex`,
            alignItems: `center`,
            justifyContent: `space-between`,
        },
        '& .MuiInputBase-root': {
            padding: '14px !important',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-marginDense': {
            transform: 'translate(14px, 20px) scale(1)',
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
        },
    },
    popupIndicatorOpen: {
        transform: 'none',
    },
    optionDiv: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    option: {
        width: '100%',
        padding: 0,
        borderLeft: '2px solid transparent',
        '&:hover': {
            borderLeft: '2px solid #3040a0',
        },
        '&[aria-selected=true]': {
            backgroundColor: '#fff',
            '&:hover': {
                borderLeft: '2px solid #3040a0',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
        },
    },
    paper: {
        margin: 0,
        transition: 'none',
        width: '100%',
    },
    outlinedInput: {
        border: 'none',
    },
    groupLabel: {
        lineHeight: '1',
        paddingTop: '5px',
    },
    rootNotAttributed: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '5px 0px',
        cursor: 'pointer',
    },
    listbox: {
        padding: 0,
    },
    engineTextfield: {
        '& .MuiOutlinedInput-root': {
            maxHeight: '50px',
        },
        marginTop: 8,
    },
})
