export default theme => ({
    picto: {
        width: '25px',
        height: '25px',
        marginRight: '10px',
    },
    pictoAndText: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    popupIndicatorOpen: {
        transform: 'none',
    },
    optionDiv: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingRight: 10,
    },
    option: {
        padding: 0,
        borderLeft: '2px solid transparent',
        '&:hover': {
            borderLeft: '2px solid #3040a0',
        },
        '&[aria-selected=true]': {
            backgroundColor: '#fff',
            '&:hover': {
                borderLeft: '2px solid #3040a0',
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
        },
    },
    paper: {
        margin: 0,
        transition: 'none',
    },
    outlinedInput: {
        border: 'none',
    },
    groupLabel: {
        lineHeight: '1',
        paddingTop: '5px',
    },
    rootNotAttributed: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '5px 0px',
        borderLeft: '2px solid transparent',
        '&:hover': {
            borderLeft: '2px solid #3040a0',
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        cursor: 'pointer',
    },
})
