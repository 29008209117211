export default {
    email: {
        FR: `Email`,
        EN: `Email`,
    },
    role: {
        FR: `Choisissez son rôle`,
        EN: `Choose a role`,
    },
    invite: {
        FR: `Invitation d'un nouvel utilisateur`,
        EN: `Invite a new user`,
    },
    send: {
        FR: `Envoyer`,
        EN: `Send`,
    },
    care: {
        FR: `Si vous souhaitez personnaliser les accès de cet utilisateur, envoyez l'invitation et modifiez son profil`,
        EN: `Ìf you want to custom the rights of this user, send the invitation then edit his profile`,
    },
    role0: {
        FR: `Administrateur`,
        EN: `Administrator`,
    },
    role1: {
        FR: `Superviseur`,
        EN: `Supervisor`,
    },
    role2: {
        FR: `Capitaine`,
        EN: `Captain`,
    },
    role3: {
        FR: `Marin`,
        EN: `Sailor`,
    },
    role4: {
        FR: `Propriétaire`,
        EN: `Owner`,
    },
    role5: {
        FR: `Prestataire`,
        EN: `Provider`,
    },
    roleDesc0: {
        FR: `Niveau le plus élevé, possède tous les droits`,
        EN: `Highest level, has all rights`,
    },
    roleDesc1: {
        FR: `Possède tous les droits sauf celui d'ajouter de nouveaux utilisateurs`,
        EN: `Has all rights except the right to add new users`,
    },
    roleDesc2: {
        FR: `Possède tous les droits liés à la gestion d'un bateau de votre flotte`,
        EN: `Has all the rights related to the management of a boat in your fleet`,
    },
    roleDesc3: {
        FR: `Accès à toutes les fonctions de base nécessaires à un membre d'équipage`,
        EN: `Access to all basic functions needed by a crew member`,
    },
    roleDesc4: {
        FR: `Peut visualiser toutes les informations de votre flotte mais ne peut rien modifier`,
        EN: `Can view all the information of your fleet but cannot modify anything`,
    },
    roleDesc5: {
        FR: `Peut accèder aux tâches où il est notifié et changer les pièces liées`,
        EN: `Can access the task where it is notified and change the related parts`,
    },
    errorEmail: {
        FR: `Merci de saisir une adresse e-mail valide`,
        EN: `Please, can you white a valid e-mail address`,
    },
    errorRole: {
        FR: `Merci d'attribuer un role à l'utilisateur`,
        EN: `Please, can you attribute a user role`,
    },
    errorBoat: {
        FR: `Merci d'attribuer un bateau à l'utilisateur`,
        EN: `Please, can you attribute a boat for this user`,
    },
}
