export default {
    typeAbsence: {
        FR: "Type d'absence",
        EN: 'Leave type',
    },
    next: {
        FR: `Suivant`,
        EN: `Next`,
    },
    saveTask: {
        FR: `Enregistrer`,
        EN: `Save`,
    },
    validate: {
        FR: 'Valider',
        EN: 'Validate',
    },
    reject: {
        FR: 'Refuser',
        EN: 'Reject',
    },
    update: {
        FR: `Mettre à jour`,
        EN: `Update`,
    },
    essentialInfos: {
        FR: 'Informations essentielles',
        EN: 'Essentil information',
    },
    activity: {
        FR: "Fil d'activité",
        EN: `Activity thread`,
    },
    startTime: {
        FR: 'Heure de début *',
        EN: 'Start time *',
    },
    endTime: {
        FR: 'Heure de fin *',
        EN: 'End time *',
    },
    addBreak: {
        FR: 'Ajouter une pause',
        EN: 'Add a break',
    },
    startTimeBreak: {
        FR: 'Début de la pause',
        EN: 'Start of the break',
    },
    endTimeBreak: {
        FR: 'Fin de la pause',
        EN: 'End of the break',
    },
    comment: {
        FR: 'Commentaires',
        EN: 'Comments',
    },
    commentTooltip: {
        FR: 'Commentaire tooltip',
        EN: 'Comment tooltip',
    },
    allDay: {
        FR: 'Absent toute la journée ?',
        EN: 'Absent all day?',
    },
    user: {
        FR: 'Utilisateur',
        EN: 'User',
    },
    manager: {
        FR: 'Manager',
        EN: 'Manager',
    },
    errorMandatoryManager: {
        FR: 'Vous devez sélectionner un manager',
        EN: 'You must select a manager',
    },
    errorMandatoryUser: {
        FR: 'Vous devez sélectionner un utilisateur',
        EN: 'You must select a user',
    },
    day: {
        FR: 'jour(s)',
        EN: 'day(s)',
    },
    totalHours: {
        FR: `Nombre d'heures total`,
        EN: 'Total number of hours',
    },
    numberOfMinutes: {
        FR: 'Nombre de minutes',
        EN: 'Number of minutes',
    },
    mandatoryDate: {
        FR: 'Vous devez sélectionner au moins une date',
        EN: 'You must select at least one date',
    },
    mandatoryType: {
        FR: `Vous devez sélectionner un type d'absence`,
        EN: 'You must select a leave type',
    },
    mandatoryHours: {
        FR: `Vous devez saisir un nombre d'heures`,
        EN: 'You must enter a number of hours',
    },
    remainingPaidVacationsWarning: {
        FR:
            'Oups, on dirait qu’il ne vous reste pas assez de congés disponibles. Vous avez pris $daysTaken jour(s) et il vous reste $daysRemaining jour(s) de congés à prendre',
        EN:
            'Oops, it looks like you don’t have enough available leave. You have taken $daysTaken day(s) and you have $daysRemaining day(s) of leave left to take',
    },
    selectDays: {
        FR: 'Sélectionnez le(s) jour(s) de cette absence',
        EN: 'Select the day(s) of this absence',
    },
    userTooltip: {
        FR: `Identifiez ici la personne qui fait la demande d'absence`,
        EN: 'Identify here the person who makes the leave request',
    },
    managerTooltip: {
        FR: `Identifiez ici la personne en charge d'approuver la demande d'absence`,
        EN: 'Identify here the person in charge of approving the leave request',
    },
    total: {
        FR: 'Total',
        EN: 'Total',
    },
    absenceValidated: {
        FR:
            'Cette demande absence a été validée. Elle ne peut donc plus être modifiée.',
        EN:
            'This demande leave has been validated. It can no longer be modified.',
    },
    absenceRejected: {
        FR:
            'Cette demande absence a été refusée. Elle ne peut donc plus être modifiée.',
        EN:
            'This demande leave has been rejected. It can no longer be modified.',
    },
}
