import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import BoatOnNumberField from '../../../../common/UsefullComponents/BoatOnNumberField'
import { getContextFromUrl } from '../../../../../languages/LocalizerUtils'
import InputAdornment from '@material-ui/core/InputAdornment'
import { putBOBEvents } from '../../../../../actions'
import { getTranslatedTitleFromEvent, isSafariIOS } from '../../Utils'

import dictionary from './Dictionary/EditFillingLevelsModalDico'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/EditFillingLevelsModalCss'

class EditFillingLevelsModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            equipmentCapacities: [],
            updated: false,
        }

        this.onChangeLevel = this.onChangeLevel.bind(this)
        this.save = this.save.bind(this)
    }

    componentDidMount() {
        const { equipmentCapacities } = this.props

        this.setState({
            equipmentCapacities: JSON.parse(
                JSON.stringify(equipmentCapacities),
            ),
        })
    }

    save() {
        const { equipmentCapacities } = this.state
        const { dispatch, onClose } = this.props
        const oldEquipmentCapacities = this.props.equipmentCapacities

        let eventsChanged = equipmentCapacities.map((event, index) => {
            const id = event.id
            const details = event.detail

            if (id && details && oldEquipmentCapacities[index]) {
                if (
                    oldEquipmentCapacities[index]?.detail?.optionnalDetail
                        ?.fillingLevel === details.optionnalDetail?.fillingLevel
                )
                    return null

                return event
            }
        })

        eventsChanged = eventsChanged.filter(item => item !== null)

        eventsChanged.forEach(event => {
            dispatch(
                putBOBEvents(event.id, event.title, {
                    detail: event.detail,
                }),
            )
        })

        onClose()
    }

    onChangeLevel(value, equipmentId) {
        const { equipmentCapacities } = this.state

        const index = equipmentCapacities.findIndex(
            event => event.detail.equipment.id === equipmentId,
        )

        if (index !== -1) {
            equipmentCapacities[index].detail.optionnalDetail = {
                ...equipmentCapacities[index].detail.optionnalDetail,
                fillingLevel: +value,
            }
        }

        this.setState({
            equipmentCapacities,
            updated: true,
        })
    }

    _renderBody() {
        const { classes } = this.props

        const { equipmentCapacities } = this.state

        const renderStockLevel = equipmentCapacities
            .sort((a, b) => {
                const aName = getTranslatedTitleFromEvent(this, a)
                const bName = getTranslatedTitleFromEvent(this, b)

                if (!aName) return 1
                if (!bName) return -1

                return aName.localeCompare(bName)
            })
            .map((event, index) => {
                const equipment = event.detail?.equipment || null

                if (!equipment) return null

                const imgSrc = equipment.equipmentFamily?.type?.image
                    ? require(`images/${equipment.equipmentFamily?.type?.image}`)
                    : null
                const value = event.detail?.optionnalDetail?.fillingLevel
                const unit = event.capacity
                    ? 'L'
                    : equipment.equipmentType.optionnalDetailActivated?.unit
                const capacity = event.detail.optionnalDetail?.capacity
                const capacityRemaining = (value * capacity) / 100
                return (
                    <div
                        className={classes.stockContainer}
                        key={`stocklevelItem-${index}`}
                    >
                        <div className={classes.stockIconWrapper}>
                            <img
                                src={imgSrc}
                                alt={event.title}
                                className={classes.stockIcon}
                            />
                        </div>
                        <div className={classes.stockVerticalContainer}>
                            {getTranslatedTitleFromEvent(this, event)}
                            <div className={classes.stockRowContainer}>
                                <BoatOnNumberField
                                    label={this.displayText('actualLevel')}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                    variant="outlined"
                                    margin="dense"
                                    onChange={e =>
                                        this.onChangeLevel(
                                            e.target.value,
                                            equipment.id,
                                        )
                                    }
                                    className={classes.stockInput}
                                    value={value}
                                    max={100}
                                    min={0}
                                    isDecimal
                                />
                                <div className={classes.stockInfoContainer}>
                                    <div className={classes.stockInfo}>
                                        {capacity ? (
                                            `${this.displayText(
                                                'capacity',
                                            )} ${capacity} ${unit}`
                                        ) : (
                                            <div
                                                className={classes.noCapacity}
                                                title={this.displayText(
                                                    'missingCapacity',
                                                )}
                                            >
                                                {this.displayText('capacity')} ?{' '}
                                                {unit}
                                            </div>
                                        )}
                                    </div>
                                    <div className={classes.stockInfo}>
                                        {capacity ? (
                                            `${capacityRemaining} ${unit}`
                                        ) : (
                                            <div
                                                className={classes.noCapacity}
                                                title={this.displayText(
                                                    'missingCapacity',
                                                )}
                                            >
                                                ? {unit}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })

        return (
            <div
                className={`${classes.root} ${
                    isSafariIOS() ? classes.iosRoot : ''
                }`}
            >
                <div className={classes.title}>
                    {this.displayText('titleLevels')}
                </div>
                <div className={classes.stockWrapper}>{renderStockLevel}</div>
            </div>
        )
    }

    render() {
        const { title, noCross = true, showFollowButton } = this.props

        const { updated } = this.state

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    null,
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    body: this._renderBody(),
                })}
                {this._renderActions([
                    {
                        action: this.save,
                        disabled: !updated,
                        label: this.displayText('save'),
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const context = getContextFromUrl(url)

    return {
        context,
    }
}

export default connect(mapStateToProps)(
    withStyles(styles)(EditFillingLevelsModal),
)
