import Typography from '@material-ui/core/Typography'
import React from 'react'
import FiberManualRecord from '@material-ui/icons/FiberManualRecord'
import BoatOnComponent from './BoatOnComponent'
import { withStyles } from '@material-ui/core/styles'
import dictionary from './Dictionary/StatusTitleDico'
import styles from './Styles/StatusTitleCss.js'

class StatusTitle extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {}
    }

    render() {
        const { classes } = this.props
        const type = this.props.type ? this.props.type : 'basic'
        const status = parseInt(this.props.status) - 1
        const statusLabel = {
            basic: [
                this.displayText('waiting'),
                this.displayText('accepted'),
                this.displayText('declined'),
                this.displayText('canceled'),
            ],
            insurance: [
                this.displayText('received'),
                this.displayText('accepted'),
                this.displayText('declined'),
                this.displayText('canceled'),
                this.displayText('traited'),
            ],
        }
        const statusColor = {
            basic: ['#fec107', '#3040a0', '#ef5350', '#ef5350'],
            insurance: [
                '#c4c4c4',
                '#fec107',
                '#ef5350',
                '#1c856a',
                '#3040a0',
                '',
                '#ef5350',
            ],
        }
        const statusCss = {
            basic: [
                classes.statusSend,
                classes.statusAccepted,
                classes.statusDeclined,
                classes.statusDeclined,
            ],
            insurance: [
                classes.statusWaiting,
                classes.statusSend,
                classes.statusDeclined,
                classes.statusTreated,
                classes.statusAccepted,
                '',
                classes.statusDeclined,
            ],
        }

        return (
            <div className={classes.line}>
                <FiberManualRecord
                    style={{
                        margin: '0',
                        marginRight: '10px',
                        color: statusColor[type][status],
                    }}
                />
                <Typography className={statusCss[type][status]}>
                    ({statusLabel[type][status]})
                </Typography>
            </div>
        )
    }
}

export default withStyles(styles)(StatusTitle)
