import { requestApi } from '../../services/api.service'
import { updateStateEvent } from '../bob.actions'
import { API_URL } from '../../services/config.service'
import { getContextFromUrl } from '../../languages/LocalizerUtils'
import { convertHeicToJpeg } from '../../utils/files'

export const removeDataBOBDocuments = async (
    files,
    handleAlert = json => {},
) => {
    const promises = []
    files.forEach(async file => {
        promises.push(
            await requestApi('/files/' + file, 'DELETE').then(
                _ => {
                    return file
                },
                error => {
                    console.error('cannot delete file')
                    return error
                },
            ),
        )
    })
    return await Promise.all(promises)
}

export const deleteFileFromBobEvent = (
    props,
    index,
    {
        deletedFiles = [],
        displayNewFiles = [],
        fileNoUrl = [],
        files = [],
        name = 'event',
    },
) => {
    deletedFiles = deletedFiles.slice()
    displayNewFiles = displayNewFiles.slice()
    fileNoUrl = fileNoUrl.slice()
    files = files.slice()
    if (files && index > files.length - 1) {
        displayNewFiles.splice(index - files.length, 1)
        fileNoUrl.splice(index - files.length, 1)
        props.setState({
            displayNewFiles: {
                ...props.state.displayNewFiles,
                [name]: displayNewFiles,
            },
        })
        props.setState({
            fileNoUrl: { ...props.state.fileNoUrl, [name]: fileNoUrl },
        })
    } else if (files) {
        if (files[index] && files[index].link && files[index].id)
            deletedFiles.push(files[index].id)
        props.setState({
            deletedFiles: { ...props.state.deletedFiles, [name]: deletedFiles },
        })
        files.splice(index, 1)
        updateStateEvent(props, {
            files: files,
            name: name,
        })
    }
}

export const deleteFileFromComment = (
    props,
    index,
    {
        deletedFiles = [],
        displayNewFiles = [],
        fileNoUrl = [],
        files = [],
        name = 'event',
    },
) => {
    deletedFiles = deletedFiles.slice()
    displayNewFiles = displayNewFiles.slice()
    fileNoUrl = fileNoUrl.slice()
    files = files.slice()
    if (files && index > files.length - 1) {
        displayNewFiles.splice(index - files.length, 1)
        fileNoUrl.splice(index - files.length, 1)
        props.setState({
            displayNewFiles: {
                ...props.state.displayNewFiles,
                [name]: displayNewFiles,
            },
        })
        props.setState({
            fileNoUrl: { ...props.state.fileNoUrl, [name]: fileNoUrl },
        })
    } else if (files) {
        if (files[index] && files[index].link && files[index].id)
            deletedFiles.push(files[index].id)
        props.setState({
            deletedFiles: { ...props.state.deletedFiles, [name]: deletedFiles },
        })
        files.splice(index, 1)
        updateStateEvent(props, {
            files: files,
            name: name,
        })
    }
}

export const addFileToBobEvent = async (
    props,
    event,
    { fileNoUrl = [], displayNewFiles = [], name = 'event' },
) => {
    var arrayNoUrl = fileNoUrl.slice()
    var newArray = displayNewFiles.slice()
    const url = window.location.pathname
    const context = getContextFromUrl(url)
    for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i]
        if (file.name.toLowerCase().includes('.heic')) {
            file = await convertHeicToJpeg(file)
        }
        if (file.size > 20000000) {
            alert(
                context === 'en'
                    ? 'Your file must not exceed 20 MB'
                    : 'Votre fichier ne doit pas dépasser 20 MO',
            )
        } else if (
            file.type.split('/')[0] !== 'image' &&
            (file.type.split('/')[0] !== 'application' ||
                file.type.split('/')[1] !== 'pdf')
        ) {
            alert(
                context === 'en'
                    ? 'Only images or PDFs are accepted'
                    : 'Seuls les images ou les PDF sont acceptés',
            )
        } else {
            newArray.push({
                blop: URL.createObjectURL(file),
                infos: file,
            })

            arrayNoUrl.push(file)
            props.setState({
                displayNewFiles: {
                    ...props.state.displayNewFiles,
                    [name]: newArray,
                },
                fileNoUrl: { ...props.state.fileNoUrl, [name]: arrayNoUrl },
            })
        }
    }
}

export const addFileToComment = async (
    props,
    event,
    { fileNoUrl = [], displayNewFiles = [], name = 'comment' },
) => {
    var arrayNoUrl = fileNoUrl.slice()
    var newArray = displayNewFiles.slice()
    const url = window.location.pathname
    const context = getContextFromUrl(url)
    for (let i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i]
        if (file.name.toLowerCase().includes('.heic')) {
            file = await convertHeicToJpeg(file)
        }
        if (file.size > 20000000) {
            alert(
                context === 'en'
                    ? 'Your file must not exceed 20 MB'
                    : 'Votre fichier ne doit pas dépasser 20 MO',
            )
        } else if (
            file.type.split('/')[0] !== 'image' &&
            (file.type.split('/')[0] !== 'application' ||
                file.type.split('/')[1] !== 'pdf')
        ) {
            alert(
                context === 'en'
                    ? 'Only images or PDFs are accepted'
                    : 'Seuls les images ou les PDF sont acceptés',
            )
        } else {
            newArray.push({
                blop: URL.createObjectURL(file),
                infos: file,
            })

            arrayNoUrl.push(file)
            props.setState({
                displayNewFiles: {
                    ...props.state.displayNewFiles,
                    [name]: newArray,
                },
                fileNoUrl: { ...props.state.fileNoUrl, [name]: arrayNoUrl },
            })
        }
    }
}

export const getImageToDisplay = (event, displayNewFiles) => {
    const imgs = []
    if (event && event.files && event.files.length > 0) {
        for (let i = 0; i < event.files.length; i++) {
            imgs.push({
                link: API_URL + '/files/' + event.files[i].link,
                id: event.files[i].id,
            })
        }
    }
    if (displayNewFiles) {
        displayNewFiles.forEach(file => {
            imgs.push({
                blop: file.blop,
                infos: file.infos,
            })
        })
    }
    return imgs
}

export const getImageToDisplayForComments = (files, displayNewFiles) => {
    const imgs = []
    if (files && files.length > 0) {
        for (let i = 0; i < files.length; i++) {
            imgs.push({
                link: API_URL + '/files/' + files[i].link,
                id: files[i].id,
                index: i,
            })
        }
    }
    if (displayNewFiles) {
        displayNewFiles.forEach(file => {
            imgs.push({
                blop: file.blop,
                infos: file.infos,
            })
        })
    }

    return imgs
}

export const postDataBOBDocuments = async (
    files,
    filesType,
    handleUpload = json => {},
) => {
    var formData = new FormData()
    files.forEach((file, i) => {
        formData.append('files', file, file.name)
    })
    return await requestApi(
        '/files/uploadFile/fileType/' + filesType,
        'FILES',
        formData,
    ).then(
        result => {
            return result
        },
        error => {
            console.error('cannot upload file')
            return error
        },
    )
}

export const duplicateFile = async (files, handleUpload = json => {}) => {
    if (files && files.length > 0)
        return await requestApi(
            '/files/duplicateFile/' + JSON.stringify(files),
            'GET',
        ).then(
            result => {
                return result
            },
            error => {
                console.error('cannot upload file')
                return error
            },
        )
    else return []
}
