export default theme => ({
    root: { height: 'calc(50vh)', marginTop: '20px', marginBottom: '75px' },
    linkBack: {
        display: 'block',
        textDecoration: 'none',
        color: theme.palette.primary.main,
        margin: '6px 0',
        fontSize: '14px',
        '&:hover': {
            textDecoration: 'underline',
        },
        marginBottom: '20px',
    },
})
