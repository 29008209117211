export default theme => {
    return {
        //* isDateDisplay #######################################################################################################
        root: {
            display: 'flex',
            flexFlow: 'row wrap',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
        page: {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'space-between',
            },
        },
        pageRightAlign: {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                justifyContent: 'flex-end',
            },
        },
        startDate: {
            marginBottom: '10px',
            marginTop: '10px',
            width: '115px',
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                marginRight: 0,
            },
        },
        endDate: {
            marginLeft: '5px',
            width: '115px',
            marginBottom: '10px',
            marginTop: '10px',
            [theme.breakpoints.down('sm')]: {
                marginRight: 0,
                marginLeft: 0,
            },
        },
        paper: {
            marginTop: '120px',
            marginLeft: '33%',
            [theme.breakpoints.down('xs')]: {
                marginTop: '69px',
                maxWidth: '100vh',
                marginLeft: '0%',
            },
        },
        rootPaper: {
            [theme.breakpoints.down('xs')]: {
                '& .MuiPopover-paper ': {
                    position: 'initial',
                    borderRadius: '12px',
                },
            },
        },
        paperModal: {
            marginTop: '197px',
            marginLeft: '35%',
            [theme.breakpoints.down('xs')]: {
                marginTop: '63px',
                maxWidth: '100vh',
                marginLeft: '0%',
            },
        },
        //* isDateDisplay Custom #######################################################################################################
        startDateCustom: {
            color: '#FFFFFF',
            zIndex: 0,
            right: '0px',
            clipPath: 'ellipse(20px 20px)',
            backgroundColor: '#3040a0',
            fontWeight: 400,
            width: '50px',
            lineHeight: '44px',
        },
        startDateFilledCustom: {
            zIndex: -1,
            position: 'absolute',
            background: '#D5EFFF',
            width: '25px',
            height: '40px',
            top: '0px',
            right: '0px',
        },
        endDateFilledCustom: {
            zIndex: -1,
            position: 'absolute',
            background: '#D5EFFF',
            width: '25px',
            height: '40px',
            top: '0px',
            left: '0px',
        },
        endDateCustom: {
            color: '#FFFFFF',
            zIndex: 0,
            right: '0px',
            clipPath: 'ellipse(20px 20px)',
            backgroundColor: '#3040a0',
            fontWeight: 400,
            width: '50px',
            lineHeight: '44px',
        },
        inRangeCustom: { fontWeight: '400', color: '#333333' },
        todayCustom: { fontWeight: '400' },
        todayWithinIntervalCustom: { fontWeight: '400', color: '#333333' },
        weekEndCustom: { fontWeight: '400', color: '#333333' },
        startEdge: {
            backgroundColor: '#FFFFFF',
        },
        endEdge: {
            backgroundColor: '#FFFFFF',
        },
        dayNumber: {
            fontWeight: '400',
            color: '#333333',
            '& span': { zIndex: 1 },
        },
        calendarWrapper: {
            fontSize: '14px',
            fontWeight: '400',
            maxWidth: '100%',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },
        inRange: {
            '& .rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge': {
                borderTopLeftRadius: '20px',
                borderBottomLeftRadius: '20px',
                left: '6px',
                [theme.breakpoints.down('sm')]: {
                    left: '6px',
                },
            },
            '& .rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge': {
                borderTopRightRadius: '20px',
                borderBottomRightRadius: '20px',
                right: '6px',
                [theme.breakpoints.down('sm')]: {
                    right: '6px',
                },
            },
        },
        weekDays: {
            fontFamily: 'Roboto',
            borderBottom: '1px solid #E5E5E5',
            padding: '0px 0px 10px 0px',
            textTransform: 'capitalize',
        },
        weekDay: { paddingBottom: '10px', color: '#9C9C9C', fontWeight: 200 },
        month: {
            padding: '0px 0px 15px 0px',
            maxWidth: '100%',
        },
        monthAndYearWrapper: { paddingTop: '0px' },
        monthAndYearPickers: {
            '& select': {
                fontFamily: 'Roboto',
                textTransform: 'capitalize',
                color: '#333333',
                fontSize: '16px',
                [theme.breakpoints.down('xs')]: {
                    fontSize: '16px',
                },
                fontWeight: 400,
            },
        },
        dayPassive: { visibility: 'hidden' },
        dayStartPreview: {
            border: 'none',
            '& .rdrDayStartPreview, .rdrDayInPreview, .rdrDayEndPreview': {
                position: 'absolute',
                border: '0px solid transparent',
            },
        },
        dayInPreview: { border: 'none' },
        dayEndPreview: { border: 'none' },
        days: {
            fontSize: '14px',
            paddingTop: '10px',
            paddingBottom: '8px',
            '& button': { marginBottom: '-10px', height: '50px' },
            zIndex: 1,
        },
        dayToday: {
            '&.rdrDayToday:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span:after, .rdrDayToday:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span:after': {
                backgroundColor: '#3040a0', //#D5EFFF
            },
            '& .rdrDayNumber span:after': {
                content: '',
                backgroundColor: '#3040a0',
                width: '13px',
                bottom: '8px',
            },
            '& .rdrDayNumber span': { fontWeight: 400, color: '#333333' },
        },
        selected: {
            zIndex: 1,
            clipPath: 'ellipse(20px 20px)',
            height: '41px',
            top: ' 4px',
        },
        dayHovered: {
            background: 'transparent',
            clipPath: 'ellipse(20px 20px)',
            zIndex: 1,
        },
        textField: {
            width: '100% ',
        },
        //* isDateReplacement  #######################################################################################################

        dayDisabled: {
            backgroundColor: 'white',
            '& .rdrDayNumber span': { color: '#aeb9bf' },
        },
        twoTextField: {
            display: 'flex',
            flexDirection: 'row',
        },
        input: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                padding: 0,
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '4px',
            },
        },
        labelInput: {
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
            },
        },
        marginInput: {
            [theme.breakpoints.down('sm')]: {
                width: '100%',
            },
        },
    }
}
