export default {
    connection: {
        FR: 'Connexion',
        EN: 'Connection',
        DE: 'Verbindung',
        BG: 'връзка',
        HR: 'Veza',
        DA: 'Forbindelse',
        ES: 'Conexión',
        ET: 'Ühendus',
        FI: 'Yhteys',
        EL: 'Σύνδεση',
        HU: 'Kapcsolat',
        GA: 'Ceangal',
        IT: 'Collegamento',
        LV: 'Savienojums',
        LT: 'Ryšys',
        MT: 'Kunnessjoni',
        NL: 'Verbinding',
        PL: 'Połączenie',
        PT: 'Conexão',
        RO: 'Conexiune',
        SK: 'Pripojenie',
        SL: 'Povezava',
        SV: 'Anslutning',
        CS: 'Připojení',
        RU: 'Соединение',
        IS: 'Tenging',
        JA: '接続',
        KO: '접속',
        UK: "З'єднання",
        ZH: '连接',
    },
    yes: {
        FR: 'Oui',
        EN: 'Yes',
        DE: 'Ja',
        BG: 'да',
        HR: 'Da',
        DA: 'Ja',
        ES: 'Sí',
        ET: 'Jah',
        FI: 'Kyllä',
        EL: 'Ναι',
        HU: 'Igen',
        GA: 'Tá',
        IT: 'Sì',
        LV: 'Jā',
        LT: 'Taip',
        MT: 'Iva',
        NL: 'Ja',
        PL: 'Tak',
        PT: 'Sim',
        RO: 'Da',
        SK: 'Áno',
        SL: 'Da',
        SV: 'Ja',
        CS: 'Ano',
        RU: 'Да',
        IS: 'Já',
        JA: 'はい',
        KO: '예',
        UK: 'Так',
        ZH: '是',
    },
    no: {
        FR: 'Non',
        EN: 'No',
        DE: 'Nein',
        BG: 'не',
        HR: 'Ne',
        DA: 'Ingen',
        ES: 'No',
        ET: 'Ei',
        FI: 'Ei',
        EL: 'Όχι',
        HU: 'Nem',
        GA: 'Níl',
        IT: 'No',
        LV: 'Nē',
        LT: 'Ne',
        MT: 'Le',
        NL: 'Nee',
        PL: 'Nie',
        PT: 'Não',
        RO: 'Nu',
        SK: 'Nie',
        SL: 'Ne',
        SV: 'Nej',
        CS: 'Ne',
        RU: 'Нет',
        IS: 'Nei',
        JA: 'いいえ',
        KO: '아니',
        UK: 'Ні',
        ZH: '没有',
    },
    // PERSONAL INFOS //
    firstName: {
        FR: 'Prénom',
        EN: 'Firstname',
        DE: 'Vorname',
        BG: 'име',
        HR: 'Ime',
        DA: 'Fornavn',
        ES: 'Nombre',
        ET: 'Eesnimi',
        FI: 'Etunimi',
        EL: 'Όνομα',
        HU: 'Keresztnév',
        GA: 'Ainm',
        IT: 'Nome',
        LV: 'Vārds',
        LT: 'Vardas',
        MT: 'Isem',
        NL: 'Voornaam',
        PL: 'Imię',
        PT: 'Nome',
        RO: 'Prenume',
        SK: 'Meno',
        SL: 'Ime',
        SV: 'Förnamn',
        CS: 'Jméno',
        RU: 'Имя',
        IS: 'Fornafn',
        JA: 'ファーストネーム',
        KO: '이름',
        UK: "Ім'я",
        ZH: '名字',
    },
    lastName: {
        FR: 'Nom',
        EN: 'Lastname',
        DE: 'Nachname',
        BG: 'фамилия',
        HR: 'Prezime',
        DA: 'Efternavn',
        ES: 'Apellido',
        ET: 'Perekonnanimi',
        FI: 'Sukunimi',
        EL: 'Επίθετο',
        HU: 'Vezetéknév',
        GA: 'Cognomen',
        IT: 'Cognome',
        LV: 'Uzvārds',
        LT: 'Pavardė',
        MT: 'Kunjom',
        NL: 'Achternaam',
        PL: 'Nazwisko',
        PT: 'Sobrenome',
        RO: 'Nume de familie',
        SK: 'Priezvisko',
        SL: 'Priimek',
        SV: 'Efternamn',
        CS: 'Příjmení',
        RU: 'Фамилия',
        IS: 'Eftirnafn',
        JA: '苗字',
        KO: '성',
        UK: 'Прізвище',
        ZH: '姓',
    },
    email: {
        FR: 'E-Mail',
        EN: 'E-Mail',
        DE: 'E-Mail',
        BG: 'E-поща',
        HR: 'E-mail',
        DA: 'E-mail',
        ES: 'Correo electrónico',
        ET: 'E-post',
        FI: 'Sähköposti',
        EL: 'Ηλεκτρονικό ταχυδρομείο',
        HU: 'E-mail',
        GA: 'Ríomhphost',
        IT: 'E-mail',
        LV: 'E-pasts',
        LT: 'El. paštas',
        MT: 'E-mail',
        NL: 'E-mail',
        PL: 'E-mail',
        PT: 'E-mail',
        RO: 'E-mail',
        SK: 'E-mail',
        SL: 'E-pošta',
        SV: 'E-post',
        CS: 'E-mail',
        RU: 'E-mail',
        IS: 'Tölvupóstur',
        JA: 'Eメール',
        KO: '이메일',
        UK: 'E-mail',
        ZH: '电子邮件',
    },
    phoneNumber: {
        FR: 'Téléphone',
        EN: 'Number phone',
        DE: 'Telefonnummer',
        BG: 'Телефонен номер',
        HR: 'Broj telefona',
        DA: 'Telefonnummer',
        ES: 'Número de teléfono',
        ET: 'Telefoninumber',
        FI: 'Puhelinnumero',
        EL: 'Αριθμός τηλεφώνου',
        HU: 'Telefonszám',
        GA: 'Uimhir theileafóin',
        IT: 'Numero di telefono',
        LV: 'Telefona numurs',
        LT: 'Telefono numeris',
        MT: 'Numru tat-telefown',
        NL: 'Telefoonnummer',
        PL: 'Numer telefonu',
        PT: 'Número de telefone',
        RO: 'Număr de telefon',
        SK: 'Telefónne číslo',
        SL: 'Telefonska številka',
        SV: 'Telefonnummer',
        CS: 'Telefonní číslo',
        RU: 'Номер телефона',
        IS: 'Símanúmer',
        JA: '電話番号',
        KO: '전화 번호',
        UK: 'Номер телефону',
        ZH: '电话号码',
    },
    password: {
        FR: 'Mot de passe',
        EN: 'Password',
        DE: 'Passwort',
        BG: 'парола',
        HR: 'Lozinka',
        DA: 'Adgangskode',
        ES: 'Contraseña',
        ET: 'Parool',
        FI: 'Salasana',
        EL: 'Κωδικός πρόσβασης',
        HU: 'Jelszó',
        GA: 'Pasfhocal',
        IT: "Parola d'ordine",
        LV: 'Parole',
        LT: 'Slaptažodis',
        MT: 'Passord',
        NL: 'Wachtwoord',
        PL: 'Hasło',
        PT: 'Senha',
        RO: 'Parolă',
        SK: 'Heslo',
        SL: 'Geslo',
        SV: 'Lösenord',
        CS: 'Heslo',
        RU: 'Пароль',
        IS: 'Lykilorð',
        JA: 'パスワード',
        KO: '암호',
        UK: 'Пароль',
        ZH: '密码',
    },
    // BOAT INFOS //
    boatName: {
        FR: 'Nom du bateau',
        EN: 'Boat name',
        DE: 'Bootsname',
        BG: 'име на лодка',
        HR: 'Ime broda',
        DA: 'Bådnavn',
        ES: 'Nombre del barco',
        ET: 'Paadi nimi',
        FI: 'Veneen nimi',
        EL: 'Όνομα σκάφους',
        HU: 'Hajó neve',
        GA: 'Ainm báid',
        IT: 'Nome della barca',
        LV: 'Kuga nosaukums',
        LT: 'Valties pavadinimas',
        MT: 'Isem tal-baħar',
        NL: 'Bootnaam',
        PL: 'Nazwa łodzi',
        PT: 'Nome do barco',
        RO: 'Numele bărcii',
        SK: 'Meno lode',
        SL: 'Ime čolna',
        SV: 'Båtnamn',
        CS: 'Jméno lodi',
        RU: 'Имя лодки',
        IS: 'Nafn báts',
        JA: 'ボート名',
        KO: '보트 이름',
        UK: "Ім'я човна",
        ZH: '船名',
    },
    boatType: {
        FR: 'Type du bateau',
        EN: 'Boat type',
        DE: 'Bootstyp',
        BG: 'тип лодка',
        HR: 'Vrsta broda',
        DA: 'Bådtype',
        ES: 'Tipo de barco',
        ET: 'Paadi tüüp',
        FI: 'Veneen tyyppi',
        EL: 'Τύπος σκάφους',
        HU: 'Hajó típusa',
        GA: 'Cineál báid',
        IT: 'Tipo di barca',
        LV: 'Kuga tips',
        LT: 'Valties tipas',
        MT: 'Tip tal-baħar',
        NL: 'Boottype',
        PL: 'Typ łodzi',
        PT: 'Tipo de barco',
        RO: 'Tipul bărcii',
        SK: 'Typ lode',
        SL: 'Vrsta čolna',
        SV: 'Båttyp',
        CS: 'Typ lodi',
        RU: 'Тип лодки',
        IS: 'Tegund báts',
        JA: 'ボートタイプ',
        KO: '보트 유형',
        UK: 'Тип човна',
        ZH: '船型',
    },
    boatYear: {
        FR: 'Année du bateau',
        EN: 'Boat year',
        DE: 'Bootsjahr',
        BG: 'година на лодка',
        HR: 'Godina broda',
        DA: 'Bådår',
        ES: 'Año del barco',
        ET: 'Paadi aasta',
        FI: 'Veneen vuosi',
        EL: 'Έτος σκάφους',
        HU: 'Hajó éve',
        GA: 'Bliain báid',
        IT: 'Anno della barca',
        LV: 'Kuga gads',
        LT: 'Valties metai',
        MT: 'Senan tal-baħar',
        NL: 'Bootjaar',
        PL: 'Rok łodzi',
        PT: 'Ano do barco',
        RO: 'Anul bărcii',
        SK: 'Rok lode',
        SL: 'Leto čolna',
        SV: 'Båtår',
        CS: 'Rok lodi',
        RU: 'Год лодки',
        IS: 'Ár báts',
        JA: 'ボートの年',
        KO: '보트 연도',
        UK: 'Рік човна',
        ZH: '船年',
    },
    boatMaker: {
        FR: 'Marque du bateau',
        EN: 'Boat Maker',
        DE: 'Bootsmacher',
        BG: 'марка на лодка',
        HR: 'Proizvođač broda',
        DA: 'Bådmager',
        ES: 'Fabricante de barcos',
        ET: 'Paadi valmistaja',
        FI: 'Veneen valmistaja',
        EL: 'Κατασκευαστής σκάφους',
        HU: 'Hajógyártó',
        GA: 'Déantóir báid',
        IT: 'Costruttore di barche',
        LV: 'Kuga ražotājs',
        LT: 'Valties gamintojas',
        MT: 'Manifattur tal-baħar',
        NL: 'Bootmaker',
        PL: 'Producent łodzi',
        PT: 'Fabricante de barcos',
        RO: 'Producătorul bărcii',
        SK: 'Výrobca lode',
        SL: 'Proizvajalec čolna',
        SV: 'Båttillverkare',
        CS: 'Výrobce lodi',
        RU: 'Производитель лодки',
        IS: 'Bátagerðarmaður',
        JA: 'ボートメーカー',
        KO: '보트 제작자',
        UK: 'Виробник човна',
        ZH: '船制造商',
    },
    boatModel: {
        FR: 'Modèle du bateau',
        EN: 'Boat model',
        DE: 'Bootmodell',
        BG: 'модел на лодка',
        HR: 'Model broda',
        DA: 'Bådmodel',
        ES: 'Modelo de barco',
        ET: 'Paadi mudel',
        FI: 'Veneen malli',
        EL: 'Μοντέλο σκάφους',
        HU: 'Hajó modell',
        GA: 'Múnla báid',
        IT: 'Modello di barca',
        LV: 'Kuga modelis',
        LT: 'Valties modelis',
        MT: 'Model tal-baħar',
        NL: 'Bootmodel',
        PL: 'Model łodzi',
        PT: 'Modelo de barco',
        RO: 'Modelul bărcii',
        SK: 'Model lode',
        SL: 'Model čolna',
        SV: 'Båtmodell',
        CS: 'Model lodi',
        RU: 'Модель лодки',
        IS: 'Bátarmódel',
        JA: 'ボートモデル',
        KO: '보트 모델',
        UK: 'Модель човна',
        ZH: '船型',
    },
    boatRegistration: {
        FR: 'Immatriculation',
        EN: 'Boat registration',
        DE: 'Bootsregistrierung',
        BG: 'регистрация на лодка',
        HR: 'Registracija broda',
        DA: 'Bådregistrering',
        ES: 'Registro de barcos',
        ET: 'Paadi registreerimine',
        FI: 'Veneen rekisteröinti',
        EL: 'Εγγραφή σκάφους',
        HU: 'Hajó regisztráció',
        GA: 'Clárú báid',
        IT: 'Registrazione della barca',
        LV: 'Kuga reģistrācija',
        LT: 'Valties registracija',
        MT: 'Reġistrazzjoni tal-baħar',
        NL: 'Bootregistratie',
        PL: 'Rejestracja łodzi',
        PT: 'Registro de barcos',
        RO: 'Înregistrarea bărcii',
        SK: 'Registrácia lode',
        SL: 'Registracija čolna',
        SV: 'Båtregistrering',
        CS: 'Registrace lodi',
        RU: 'Регистрация лодки',
        IS: 'Bátaskráning',
        JA: 'ボート登録',
        KO: '보트 등록',
        UK: 'Реєстрація човна',
        ZH: '船登记',
    },
    boatEngine: {
        FR: 'Moteur',
        EN: 'Engine',
        DE: 'Motor',
        BG: 'двигател',
        HR: 'Motor',
        DA: 'Motor',
        ES: 'Motor',
        ET: 'Mootor',
        FI: 'Moottori',
        EL: 'Κινητήρας',
        HU: 'Motor',
        GA: 'Inneall',
        IT: 'Motore',
        LV: 'Dzinējs',
        LT: 'Variklis',
        MT: 'Inġinerija',
        NL: 'Motor',
        PL: 'Silnik',
        PT: 'Motor',
        RO: 'Motor',
        SK: 'Motor',
        SL: 'Motor',
        SV: 'Motor',
        CS: 'Motor',
        RU: 'Двигатель',
        IS: 'Vélmenni',
        JA: 'エンジン',
        KO: '엔진',
        UK: 'Двигун',
        ZH: '发动机',
    },
    homePortOrStorage: {
        FR: 'Port ou lieu de stockage',
        EN: 'Harbour or storage area',
        DE: 'Heimathafen oder Lagerort',
        BG: 'пристанище или складово място',
        HR: 'Luka ili skladište',
        DA: 'Hjemhavn eller lagerområde',
        ES: 'Puerto o área de almacenamiento',
        ET: 'Kodu sadam või ladustamisala',
        FI: 'Kotisatama tai varastointialue',
        EL: 'Λιμάνι ή περιοχή αποθήκευσης',
        HU: 'Kikötő vagy raktárterület',
        GA: 'Cé dhúnadh nó limistéar stórála',
        IT: 'Porto o area di stoccaggio',
        LV: 'Ostas vai glabāšanas vieta',
        LT: 'Namų uostas arba saugojimo plotas',
        MT: 'Port jew żona tal-ħażna',
        NL: 'Thuishaven of opslagplaats',
        PL: 'Port macierzysty lub obszar magazynowy',
        PT: 'Porto ou área de armazenamento',
        RO: 'Port sau zonă de depozitare',
        SK: 'Domovský prístav alebo skladovací priestor',
        SL: 'Domovinska luka ali območje za shranjevanje',
        SV: 'Hemhamn eller lagringsområde',
        CS: 'Domovský přístav nebo skladovací prostor',
        RU: 'Домашний порт или складское помещение',
        IS: 'Heimahöfn eða geymslu svæði',
        JA: 'ハーバーや保管エリア',
        KO: '항구 또는 저장 영역',
        UK: 'Домашній порт або зона зберігання',
        ZH: '港口或存储区',
    },
    // BOAT TYPE //
    engine: {
        FR: 'Moteur',
        EN: 'Engine',
        DE: 'Motor',
        BG: 'двигател',
        HR: 'Motor',
        DA: 'Motor',
        ES: 'Motor',
        ET: 'Mootor',
        FI: 'Moottori',
        EL: 'Κινητήρας',
        HU: 'Motor',
        GA: 'Inneall',
        IT: 'Motore',
        LV: 'Dzinējs',
        LT: 'Variklis',
        MT: 'Inġinerija',
        NL: 'Motor',
        PL: 'Silnik',
        PT: 'Motor',
        RO: 'Motor',
        SK: 'Motor',
        SL: 'Motor',
        SV: 'Motor',
        CS: 'Motor',
        RU: 'Двигатель',
        IS: 'Vélmenni',
        JA: 'エンジン',
        KO: '엔진',
        UK: 'Двигун',
        ZH: '发动机',
    },
    sailing: {
        FR: 'Voilier',
        EN: 'Sailing',
        DE: 'Segeln',
        BG: 'плаване',
        HR: 'Jedrenje',
        DA: 'Sejlads',
        ES: 'Vela',
        ET: 'Purjetamine',
        FI: 'purjehdus',
        EL: 'Ιστιοπλοΐα',
        HU: 'Vitorlázás',
        GA: 'Seoltóireacht',
        IT: 'Vela',
        LV: 'Burinieku',
        LT: 'Buriavimas',
        MT: 'Vela',
        NL: 'Zeilen',
        PL: 'Żeglarstwo',
        PT: 'Vela',
        RO: 'Navigație cu vele',
        SK: 'Plachtenie',
        SL: 'Jadranje',
        SV: 'Segling',
        CS: 'Plachtění',
        RU: 'Парусный спорт',
        IS: 'Sigling',
        JA: 'セーリング',
        KO: '세일링',
        UK: 'Парусний спорт',
        ZH: '帆船',
    },
    pneumatic: {
        FR: 'Semi-Rigide',
        EN: 'Pneumatic boat',
        DE: 'Schlauchboot',
        BG: 'пневматична лодка',
        HR: 'Pneumatski brod',
        DA: 'Pneumatisk båd',
        ES: 'Bote neumático',
        ET: 'Pneumaatiline paat',
        FI: 'Pneumaattinen vene',
        EL: 'Πνευματικό σκάφος',
        HU: 'Pneumatikus hajó',
        GA: 'Bád pneamataice',
        IT: 'Barca pneumatica',
        LV: 'Pneimatiskā laiva',
        LT: 'Pneumatinis valtis',
        MT: 'Barka pneumatika',
        NL: 'Pneumatische boot',
        PL: 'Łódź pneumatyczna',
        PT: 'Barco pneumático',
        RO: 'Barcă pneumatică',
        SK: 'Pneumatická loď',
        SL: 'Pnevmatska ladja',
        SV: 'Pneumatisk båt',
        CS: 'Pneumatická loď',
        RU: 'Пневматическая лодка',
        IS: 'Loðnabátur',
        JA: 'パワーボート',
        KO: '보트',
        UK: 'Пневматичний човен',
        ZH: '气动船',
    },
    catamaran: {
        FR: 'Catamaran',
        EN: 'Catamaran',
        DE: 'Katamaran',
        BG: 'катамаран',
        HR: 'Katamaran',
        DA: 'Katamaran',
        ES: 'Catamarán',
        ET: 'Katamaraan',
        FI: 'Katamaraani',
        EL: 'Καταμαράν',
        HU: 'Katamarán',
        GA: 'Catamarán',
        IT: 'Catamarano',
        LV: 'Katamarāns',
        LT: 'Katamaranas',
        MT: 'Katamaran',
        NL: 'Catamaran',
        PL: 'Katamaran',
        PT: 'Catamarã',
        RO: 'Catamaran',
        SK: 'Katamarán',
        SL: 'Katamaran',
        SV: 'Katamaran',
        CS: 'Katamarán',
        RU: 'Катамаран',
        IS: 'Katamaran',
        JA: 'カタマラン',
        KO: '카타마란',
        UK: 'Катамаран',
        ZH: '双体船',
    },
    barge: {
        FR: 'Péniche',
        EN: 'Barge',
        DE: 'Lastkahn',
        BG: 'баржа',
        HR: 'Brodica',
        DA: 'Lejebåd',
        ES: 'Barcaza',
        ET: 'Praam',
        FI: 'Proomu',
        EL: 'Βάρκα',
        HU: 'Bárka',
        GA: 'Báirge',
        IT: 'Chiatta',
        LV: 'Bāka',
        LT: 'Barža',
        MT: 'Bargi',
        NL: 'Binnenvaartschip',
        PL: 'Barka',
        PT: 'Barcaça',
        RO: 'Barjă',
        SK: 'Bárka',
        SL: 'Barka',
        SV: 'Pråm',
        CS: 'Bárka',
        RU: 'Баржа',
        IS: 'Flýtibátur',
        JA: 'バージ',
        KO: '바지',
        UK: 'Баржа',
        ZH: '驳船',
    },
    jetSki: {
        FR: 'Jet-Ski',
        EN: 'Jet-Ski',
        DE: 'Jet-Ski',
        BG: 'джет-ски',
        HR: 'Jet-ski',
        DA: 'Jet-ski',
        ES: 'Jet-ski',
        ET: 'Jet-ski',
        FI: 'Jet-ski',
        EL: 'Jet-ski',
        HU: 'Jet-ski',
        GA: 'Jet-ski',
        IT: 'Jet-ski',
        LV: 'Jet-ski',
        LT: 'Jet-ski',
        MT: 'Jet-ski',
        NL: 'Jet-ski',
        PL: 'Jet-ski',
        PT: 'Jet-ski',
        RO: 'Jet-ski',
        SK: 'Jet-ski',
        SL: 'Jet-ski',
        SV: 'Jet-ski',
        CS: 'Jet-ski',
        RU: 'Гидроцикл',
        IS: 'Jet-ski',
        JA: 'ジェットスキー',
        KO: '제트 스키',
        UK: 'Гідроцикл',
        ZH: '水上摩托',
    },
    // TRANSACTION TYPE
    repair: {
        FR: 'Réparation',
        EN: 'Repair',
        DE: 'Reparatur',
        BG: 'ремонт',
        HR: 'Popravak',
        DA: 'Reparation',
        ES: 'Reparación',
        ET: 'Parandus',
        FI: 'Korjaus',
        EL: 'Επισκευή',
        HU: 'Javítás',
        GA: 'Deisiú',
        IT: 'Riparazione',
        LV: 'Remonts',
        LT: 'Remontas',
        MT: 'Riparazzjoni',
        NL: 'Reparatie',
        PL: 'Naprawa',
        PT: 'Reparação',
        RO: 'Reparare',
        SK: 'Oprava',
        SL: 'Popravilo',
        SV: 'Reparation',
        CS: 'Oprava',
        RU: 'Ремонт',
        IS: 'Viðgerð',
        JA: '修理',
        KO: '수리',
        UK: 'Ремонт',
        ZH: '修理',
    },
    insurance: {
        FR: 'Assurance',
        EN: 'Insurance',
        DE: 'Versicherung',
        BG: 'застраховка',
        HR: 'Osiguranje',
        DA: 'Forsikring',
        ES: 'Seguro',
        ET: 'Kindlustus',
        FI: 'Vakuutus',
        EL: 'Ασφάλιση',
        HU: 'Biztosítás',
        GA: 'Árachas',
        IT: 'Assicurazione',
        LV: 'Apdrošināšana',
        LT: 'draudimas',
        MT: 'Assigurazzjoni',
        NL: 'Verzekering',
        PL: 'Ubezpieczenie',
        PT: 'Seguro',
        RO: 'Asigurare',
        SK: 'Poistenie',
        SL: 'Zavarovanje',
        SV: 'Försäkring',
        CS: 'Pojištění',
        RU: 'Страхование',
        IS: 'Tryggingar',
        JA: '保険',
        KO: '보험',
        UK: 'Страхування',
        ZH: '保险',
    },
    storage: {
        FR: 'Stockage',
        EN: 'Storage',
        DE: 'Lagerung',
        BG: 'съхранение',
        HR: 'Pohrana',
        DA: 'Opbevaring',
        ES: 'Almacenamiento',
        ET: 'Ladustamine',
        FI: 'Säilytys',
        EL: 'Αποθήκευση',
        HU: 'Tárolás',
        GA: 'Stóráil',
        IT: 'Stoccaggio',
        LV: 'Glabāšana',
        LT: 'Saugojimas',
        MT: 'Stoħbija',
        NL: 'Opslag',
        PL: 'Przechowywanie',
        PT: 'Armazenamento',
        RO: 'Depozitare',
        SK: 'Skladovanie',
        SL: 'Shranjevanje',
        SV: 'Förvaring',
        CS: 'Skladování',
        RU: 'Хранение',
        IS: 'Geymsla',
        JA: '保管',
        KO: '저장',
        UK: 'Зберігання',
        ZH: '存储',
    },
    layout: {
        FR: 'Aménagement',
        EN: 'Layout',
        DE: 'Layout',
        BG: 'разпределение',
        HR: 'Raspored',
        DA: 'Layout',
        ES: 'Diseño',
        ET: 'Paigutus',
        FI: 'Asettelu',
        EL: 'Διάταξη',
        HU: 'Elrendezés',
        GA: 'Leagan amach',
        IT: 'Layout',
        LV: 'Izkārtojums',
        LT: 'Išdėstymas',
        MT: 'Layout',
        NL: 'Indeling',
        PL: 'Układ',
        PT: 'Layout',
        RO: 'Aspect',
        SK: 'Rozloženie',
        SL: 'Postavitev',
        SV: 'Layout',
        CS: 'Rozložení',
        RU: 'Макет',
        IS: 'Skipulag',
        JA: 'レイアウト',
        KO: '레이아웃',
        UK: 'Макет',
        ZH: '布局',
    },
    expertise: {
        FR: 'Expertise',
        EN: 'Expertise',
        DE: 'Expertise',
        BG: 'експертиза',
        HR: 'Ekspertiza',
        DA: 'Ekspertise',
        ES: 'Peritaje',
        ET: 'Ekspertiis',
        FI: 'Asiantuntijalausunto',
        EL: 'Εμπειρογνωμοσύνη',
        HU: 'Szakvélemény',
        GA: 'Eolais',
        IT: 'Perizia',
        LV: 'Ekspertīze',
        LT: 'Ekspertizė',
        MT: 'Eżpertiza',
        NL: 'Expertise',
        PL: 'Ekspertyza',
        PT: 'Peritagem',
        RO: 'Expertiză',
        SK: 'Expertíza',
        SL: 'Ekspertiza',
        SV: 'Expertis',
        CS: 'Expertíza',
        RU: 'Экспертиза',
        IS: 'Sérfræðingar',
        JA: '専門家',
        KO: '전문가',
        UK: 'Експертиза',
        ZH: '专业知识',
    },
    equipmentBuy: {
        FR: "Achat d'équipement",
        EN: 'Purchase of equipment',
        DE: 'Ausrüstung kaufen',
        BG: 'покупка на оборудване',
        HR: 'Kupnja opreme',
        DA: 'Køb af udstyr',
        ES: 'Compra de equipo',
        ET: 'Varustuse ostmine',
        FI: 'Varusteiden ostaminen',
        EL: 'Αγορά εξοπλισμού',
        HU: 'Felszerelés vásárlása',
        GA: 'Ceannach trealamh',
        IT: 'Acquisto di attrezzatura',
        LV: 'Aprīkojuma iegāde',
        LT: 'Įrangos pirkimas',
        MT: 'Ixtrar tal-eqipament',
        NL: 'Aankoop van apparatuur',
        PL: 'Zakup sprzętu',
        PT: 'Compra de equipamento',
        RO: 'Achiziționarea de echipamente',
        SK: 'Nákup vybavenia',
        SL: 'Nakup opreme',
        SV: 'Köp av utrustning',
        CS: 'Nákup vybavení',
        RU: 'Покупка оборудования',
        IS: 'Kaup á búnaði',
        JA: '機器の購入',
        KO: '장비 구매',
        UK: 'Придбання обладнання',
        ZH: '购买设备',
    },
    fuel: {
        FR: 'Carburant',
        EN: 'Fuel',
        DE: 'Kraftstoff',
        BG: 'гориво',
        HR: 'Gorivo',
        DA: 'Brændstof',
        ES: 'Combustible',
        ET: 'Kütus',
        FI: 'Polttoaine',
        EL: 'Καύσιμο',
        HU: 'Üzemanyag',
        GA: 'Breosla',
        IT: 'Carburante',
        LV: 'Degviela',
        LT: 'Kuras',
        MT: 'Karburant',
        NL: 'Brandstof',
        PL: 'Paliwo',
        PT: 'Combustível',
        RO: 'Combustibil',
        SK: 'Palivo',
        SL: 'Gorivo',
        SV: 'Bränsle',
        CS: 'Palivo',
        RU: 'Топливо',
        IS: 'Eldsneyti',
        JA: '燃料',
        KO: '연료',
        UK: 'Паливо',
        ZH: '燃料',
    },
    adminFees: {
        FR: 'Frais administratifs',
        EN: 'Administrative expenses',
        DE: 'Verwaltungskosten',
        BG: 'административни разходи',
        HR: 'Administrativni troškovi',
        DA: 'Administrative udgifter',
        ES: 'Gastos administrativos',
        ET: 'Halduskulud',
        FI: 'Hallintokulut',
        EL: 'Διοικητικά έξοδα',
        HU: 'Adminisztratív költségek',
        GA: 'Costais riaracháin',
        IT: 'Spese amministrative',
        LV: 'Administratīvie izdevumi',
        LT: 'Administraciniai mokesčiai',
        MT: 'Spizji amministrattivi',
        NL: 'Administratieve kosten',
        PL: 'Opłaty administracyjne',
        PT: 'Despesas administrativas',
        RO: 'Taxe administrative',
        SK: 'Administratívne poplatky',
        SL: 'Upravne pristojbine',
        SV: 'Administrativa avgifter',
        CS: 'Správní poplatky',
        RU: 'Административные расходы',
        IS: 'Stjórnunar- og rekstrarfjárhæðir',
        JA: '管理費',
        KO: '행정 비용',
        UK: 'Адміністративні витрати',
        ZH: '行政费用',
    },
    buyBoat: {
        FR: 'Achat du bateau',
        EN: 'Purchase of the boat',
        DE: 'Kauf des Bootes',
        BG: 'покупка на лодка',
        HR: 'Kupnja broda',
        DA: 'Køb af båd',
        ES: 'Compra del barco',
        ET: 'Paadi ostmine',
        FI: 'Veneen ostaminen',
        EL: 'Αγορά του σκάφους',
        HU: 'Hajó vásárlása',
        GA: 'Ceannach an báid',
        IT: 'Acquisto della barca',
        LV: 'Kuga iegāde',
        LT: 'Valties pirkimas',
        MT: 'Ixtrar tal-baħar',
        NL: 'Aankoop van de boot',
        PL: 'Zakup łodzi',
        PT: 'Compra do barco',
        RO: 'Cumpărarea bărcii',
        SK: 'Nákup lode',
        SL: 'Nakup čolna',
        SV: 'Köp av båt',
        CS: 'Nákup lodi',
        RU: 'Покупка лодки',
        IS: 'Kaup á báti',
        JA: 'ボートの購入',
        KO: '보트 구매',
        UK: 'Придбання човна',
        ZH: '购买船',
    },
    rentBoat: {
        FR: 'Location du bateau',
        EN: 'Boat rental',
        DE: 'Bootsverleih',
        BG: 'наем на лодка',
        HR: 'Najam broda',
        DA: 'Bådleje',
        ES: 'Alquiler de barcos',
        ET: 'Paadi rent',
        FI: 'Veneen vuokraus',
        EL: 'Ενοικίαση σκάφους',
        HU: 'Hajókölcsönzés',
        GA: 'Cíos báid',
        IT: 'Noleggio della barca',
        LV: 'Kuga noma',
        LT: 'Valties nuoma',
        MT: 'Ixtrar tal-baħar',
        NL: 'Bootverhuur',
        PL: 'Wynajem łodzi',
        PT: 'Aluguer de barcos',
        RO: 'Închirierea bărcii',
        SK: 'Prenájom lode',
        SL: 'Najem čolna',
        SV: 'Båtuthyrning',
        CS: 'Pronájem lodi',
        RU: 'Аренда лодки',
        IS: 'Bátleiga',
        JA: 'ボートのレンタル',
        KO: '보트 임대',
        UK: 'Оренда човна',
        ZH: '租船',
    },
    sellBoat: {
        FR: 'Vente du bateau',
        EN: 'Boat sale',
        DE: 'Bootsverkauf',
        BG: 'продажба на лодка',
        HR: 'Prodaja broda',
        DA: 'Bådsalg',
        ES: 'Venta de barcos',
        ET: 'Paadi müük',
        FI: 'Veneen myynti',
        EL: 'Πώληση σκάφους',
        HU: 'Hajó eladás',
        GA: 'Díol báid',
        IT: 'Vendita della barca',
        LV: 'Kuga pārdošana',
        LT: 'Valties pardavimas',
        MT: 'Bejgħ tal-baħar',
        NL: 'Bootverkoop',
        PL: 'Sprzedaż łodzi',
        PT: 'Venda de barcos',
        RO: 'Vânzarea bărcii',
        SK: 'Predaj lode',
        SL: 'Prodaja čolna',
        SV: 'Båtförsäljning',
        CS: 'Prodej lodi',
        RU: 'Продажа лодки',
        IS: 'Bátasala',
        JA: 'ボートの販売',
        KO: '보트 판매',
        UK: 'Продаж човна',
        ZH: '卖船',
    },
    permisNav: {
        FR: 'Permis de navigation',
        EN: 'Navigation permits',
        DE: 'Navigationserlaubnisse',
        BG: 'разрешения за навигация',
        HR: 'Dozvole za navigaciju',
        DA: 'Navigationslicenser',
        ES: 'Permisos de navegación',
        ET: 'Navigatsiooniload',
        FI: 'Navigointiluvat',
        EL: 'Άδειες πλοήγησης',
        HU: 'Navigációs engedélyek',
        GA: 'Ceadúnais seoltóireachta',
        IT: 'Permessi di navigazione',
        LV: 'Navigācijas atļaujas',
        LT: 'Navigacijos leidimai',
        MT: 'Permessi ta’ navigazzjoni',
        NL: 'Navigatievergunningen',
        PL: 'Zezwolenia na żeglugę',
        PT: 'Permissões de navegação',
        RO: 'Permise de navigație',
        SK: 'Navigačné povolenia',
        SL: 'Dovoljenja za navigacijo',
        SV: 'Navigeringsbehörigheter',
        CS: 'Navigační povolení',
        RU: 'Навигационные разрешения',
        IS: 'Stýriheimildir',
        JA: '航行許可証',
        KO: '항해 허가',
        UK: 'Навігаційні дозволи',
        ZH: '航行许可证',
    },
    circulationCard: {
        FR: 'Carte de circulation',
        EN: 'Circulation card',
        DE: 'Zirkulationskarte',
        BG: 'карта за циркулация',
        HR: 'Kartica kretanja',
        DA: 'Cirkulationskort',
        ES: 'Tarjeta de circulación',
        ET: 'Ringluskart',
        FI: 'Kiertokortti',
        EL: 'Κάρτα κυκλοφορίας',
        HU: 'Keringési kártya',
        GA: 'Cárta ciorruithe',
        IT: 'Carta di circolazione',
        LV: 'Aplūkošanas karte',
        LT: 'Cirkuliacijos kortelė',
        MT: 'Karta ta’ ċirkulazzjoni',
        NL: 'Circulatiekaart',
        PL: 'Karta obiegu',
        PT: 'Cartão de circulação',
        RO: 'Card de circulație',
        SK: 'Karta obehov',
        SL: 'Karta kroženja',
        SV: 'Cirkulationskort',
        CS: 'Karta oběhu',
        RU: 'Карта циркуляции',
        IS: 'Hringrásarkort',
        JA: '循環カード',
        KO: '순환 카드',
        UK: 'Карта обігу',
        ZH: '循环卡',
    },
    certificateConformity: {
        FR: 'Attestation de conformité',
        EN: 'Certificate of conformity',
        DE: 'Konformitätsbescheinigung',
        BG: 'сертификат за съответствие',
        HR: 'Potvrda o sukladnosti',
        DA: 'Certifikat om overensstemmelse',
        ES: 'Certificado de conformidad',
        ET: 'Vastavussertifikaat',
        FI: 'Vastaavuustodistus',
        EL: 'Πιστοποιητικό συμμόρφωσης',
        HU: 'Megfelelőségi tanúsítvány',
        GA: 'Teastas comhlíonta',
        IT: 'Certificato di conformità',
        LV: 'Atbilstības sertifikāts',
        LT: 'Atitikties sertifikatas',
        MT: 'Ċertifikat ta’ konformità',
        NL: 'Conformiteitsverklaring',
        PL: 'Certyfikat zgodności',
        PT: 'Certificado de conformidade',
        RO: 'Certificat de conformitate',
        SK: 'Certifikát o zhode',
        SL: 'Potrdilo o skladnosti',
        SV: 'Konformitetsintyg',
        CS: 'Certifikát o shodě',
        RU: 'Сертификат соответствия',
        IS: 'Samræðisvottorð',
        JA: '適合証明書',
        KO: '일치성증명서',
        UK: 'Сертифікат відповідності',
        ZH: '合格证',
    },
    engineManual: {
        FR: 'Manuel du moteur',
        EN: 'Engine manual',
        DE: 'Motorhandbuch',
        BG: 'ръководство за двигателя',
        HR: 'Priručnik motora',
        DA: 'Motorhåndbog',
        ES: 'Manual del motor',
        ET: 'Mootori käsiraamat',
        FI: 'Moottorin käyttöohje',
        EL: 'Εγχειρίδιο κινητήρα',
        HU: 'Motor kézikönyv',
        GA: 'Treoirleabhar inneall',
        IT: 'Manuale del motore',
        LV: 'Dzinēja rokasgrāmata',
        LT: 'Variklio vadovas',
        MT: 'Manwal tal-magna',
        NL: 'Motorhandleiding',
        PL: 'Podręcznik silnika',
        PT: 'Manual do motor',
        RO: 'Manual de motor',
        SK: 'Manuál motora',
        SL: 'Motorjev priročnik',
        SV: 'Motorhandbok',
        CS: 'Manuál motoru',
        RU: 'Руководство по двигателю',
        IS: 'Handbók vél',
        JA: 'エンジンマニュアル',
        KO: '엔진 매뉴얼',
        UK: 'Посібник з двигуном',
        ZH: '发动机手册',
    },
    ownerManual: {
        FR: 'Manuel du propriétaire',
        EN: 'Owner manual',
        DE: 'Eigentümerhandbuch',
        BG: 'ръководство на собственика',
        HR: 'Priručnik vlasnika',
        DA: 'Ejermanual',
        ES: 'Manual del propietario',
        ET: 'Omaniku käsiraamat',
        FI: 'Omistajan käsikirja',
        EL: 'Εγχειρίδιο ιδιοκτήτη',
        HU: 'Tulajdonos kézikönyv',
        GA: 'Treoirleabhar úinéara',
        IT: 'Manuale del proprietario',
        LV: 'Īpašnieka rokasgrāmata',
        LT: 'Savininko vadovas',
        MT: 'Manwal tal-magna',
        NL: 'Eigenaarshandleiding',
        PL: 'Podręcznik właściciela',
        PT: 'Manual do proprietário',
        RO: 'Manualul proprietarului',
        SK: 'Manuál vlastníka',
        SL: 'Priročnik lastnika',
        SV: 'Ägarmanual',
        CS: 'Manuál majitele',
        RU: 'Руководство владельца',
        IS: 'Eiganda handbók',
        JA: 'オーナーマニュアル',
        KO: '소유자 매뉴얼',
        UK: 'Посібник власника',
        ZH: '业主手册',
    },
    expertReport: {
        FR: "Rapport d'expertise",
        EN: 'Expert report',
        DE: 'Expertenbericht',
        BG: 'експертен доклад',
        HR: 'Izvješće stručnjaka',
        DA: 'Ekspert rapport',
        ES: 'Informe de experto',
        ET: 'Ekspertaruanne',
        FI: 'Asiantuntijalausunto',
        EL: 'Έκθεση ειδικού',
        HU: 'Szakértői jelentés',
        GA: 'Tuairisc saineolaithe',
        IT: 'Relazione di esperti',
        LV: 'Ekspertu ziņojums',
        LT: 'Ekspertų ataskaita',
        MT: 'Rapport tal-espert',
        NL: 'Expert rapport',
        PL: 'Raport eksperta',
        PT: 'Relatório de especialista',
        RO: 'Raport de expert',
        SK: 'Správa odborníka',
        SL: 'Poročilo strokovnjaka',
        SV: 'Expert rapport',
        CS: 'Zpráva odborníka',
        RU: 'Экспертный отчет',
        IS: 'Sérfræðingar skýrsla',
        JA: '専門家の報告',
        KO: '전문가 보고서',
        UK: 'Експертний звіт',
        ZH: '专家报告',
    },
    maintenance: {
        FR: 'Maintenance',
        EN: 'Maintenance',
        DE: 'Wartung',
        BG: 'поддръжка',
        HR: 'Održavanje',
        DA: 'Vedligeholdelse',
        ES: 'Mantenimiento',
        ET: 'Hooldus',
        FI: 'Huolto',
        EL: 'Συντήρηση',
        HU: 'Karbantartás',
        GA: 'Cothabháil',
        IT: 'Manutenzione',
        LV: 'Uzturēšana',
        LT: 'Priežiūra',
        MT: 'Manutenzjoni',
        NL: 'Onderhoud',
        PL: 'Konserwacja',
        PT: 'Manutenção',
        RO: 'Întreținere',
        SK: 'Údržba',
        SL: 'Vzdrževanje',
        SV: 'Underhåll',
        CS: 'Údržba',
        RU: 'Техническое обслуживание',
        IS: 'Viðhald',
        JA: 'メンテナンス',
        KO: '유지 보수',
        UK: 'Обслуговування',
        ZH: '维护',
    },
    other: {
        FR: 'Autre',
        EN: 'Other',
        DE: 'Andere',
        BG: 'други',
        HR: 'Drugi',
        DA: 'Andet',
        ES: 'Otro',
        ET: 'Muud',
        FI: 'Muu',
        EL: 'Άλλο',
        HU: 'Egyéb',
        GA: 'Eile',
        IT: 'Altro',
        LV: 'Cits',
        LT: 'Kitas',
        MT: 'L-ieħor',
        NL: 'Anders',
        PL: 'Inny',
        PT: 'Outro',
        RO: 'Altele',
        SK: 'Iné',
        SL: 'Drugo',
        SV: 'Annat',
        CS: 'Jiný',
        RU: 'Другой',
        IS: 'Annað',
        JA: 'その他',
        KO: '다른',
        UK: 'Інший',
        ZH: '其他',
    },
    lastControl: {
        FR: 'Dernier contrôle',
        EN: 'Last control',
        DE: 'Letzte Kontrolle',
        BG: 'последен контрол',
        HR: 'Zadnja kontrola',
        DA: 'Sidste kontrol',
        ES: 'Último control',
        ET: 'Viimane kontroll',
        FI: 'Viimeisin tarkastus',
        EL: 'Τελευταίος έλεγχος',
        HU: 'Utolsó ellenőrzés',
        GA: 'Deireadh seiceála',
        IT: 'Ultimo controllo',
        LV: 'Pēdējā kontrole',
        LT: 'Paskutinis kontrolė',
        MT: 'Kontroll aħħar',
        NL: 'Laatste controle',
        PL: 'Ostatnia kontrola',
        PT: 'Último controle',
        RO: 'Ultimul control',
        SK: 'Posledná kontrola',
        SL: 'Zadnji nadzor',
        SV: 'Senaste kontroll',
        CS: 'Poslední kontrola',
        RU: 'Последний контроль',
        IS: 'Síðasti stjórn',
        JA: '最終コントロール',
        KO: '마지막 제어',
        UK: 'Останній контроль',
        ZH: '最后控制',
    },
    nextControl: {
        FR: 'Prochain contrôle',
        EN: 'Next control',
        DE: 'Nächste Kontrolle',
        BG: 'следващ контрол',
        HR: 'Sljedeća kontrola',
        DA: 'Næste kontrol',
        ES: 'Próximo control',
        ET: 'Järgmine kontroll',
        FI: 'Seuraava tarkastus',
        EL: 'Επόμενος έλεγχος',
        HU: 'Következő ellenőrzés',
        GA: 'An chéad seiceáil eile',
        IT: 'Prossimo controllo',
        LV: 'Nākamā kontrole',
        LT: 'Kitas kontrolė',
        MT: 'Kontroll li jmiss',
        NL: 'Volgende controle',
        PL: 'Następna kontrola',
        PT: 'Próximo controle',
        RO: 'Următorul control',
        SK: 'Ďalšia kontrola',
        SL: 'Naslednji nadzor',
        SV: 'Nästa kontroll',
        CS: 'Další kontrola',
        RU: 'Следующий контроль',
        IS: 'Næsta stjórn',
        JA: '次のコントロール',
        KO: '다음 제어',
        UK: 'Наступний контроль',
        ZH: '下一个控制',
    },
    // POPULAR WORDS
    next: {
        FR: 'Suivant',
        EN: 'Next',
        DE: 'Nächster',
        BG: 'следващ',
        HR: 'Sljedeći',
        DA: 'Næste',
        ES: 'Siguiente',
        ET: 'Järgmine',
        FI: 'Seuraava',
        EL: 'Επόμενος',
        HU: 'Következő',
        GA: 'Ar aghaidh',
        IT: 'Il prossimo',
        LV: 'Nākamais',
        LT: 'Kitas',
        MT: 'Li jmiss',
        NL: 'Volgende',
        PL: 'Następny',
        PT: 'Próximo',
        RO: 'Următorul',
        SK: 'Ďalší',
        SL: 'Naslednji',
        SV: 'Nästa',
        CS: 'Další',
        RU: 'Следующий',
        IS: 'Næsta',
        JA: '次',
        KO: '다음',
        UK: 'Наступний',
        ZH: '下一个',
    },
    previous: {
        FR: 'Précédent',
        EN: 'Previous',
        DE: 'Vorherige',
        BG: 'предишен',
        HR: 'Prethodni',
        DA: 'Tidligere',
        ES: 'Anterior',
        ET: 'Eelmine',
        FI: 'Edellinen',
        EL: 'Προηγούμενος',
        HU: 'Előző',
        GA: 'Roimhe',
        IT: 'Precedente',
        LV: 'Iepriekšējais',
        LT: 'Ankstesnis',
        MT: 'Preċedenti',
        NL: 'Vorige',
        PL: 'Poprzedni',
        PT: 'Anterior',
        RO: 'Anterior',
        SK: 'Predchádzajúci',
        SL: 'Prejšnji',
        SV: 'Föregående',
        CS: 'Předchozí',
        RU: 'Предыдущий',
        IS: 'Fyrri',
        JA: '前',
        KO: '이전',
        UK: 'Попередній',
        ZH: '以前',
    },
    price: {
        FR: 'Prix',
        EN: 'Price',
        DE: 'Preis',
        BG: 'цена',
        HR: 'Cijena',
        DA: 'Pris',
        ES: 'Precio',
        ET: 'Hind',
        FI: 'Hinta',
        EL: 'Τιμή',
        HU: 'Ár',
        GA: 'Praghas',
        IT: 'Prezzo',
        LV: 'Cena',
        LT: 'Kaina',
        MT: 'Prezz',
        NL: 'Prijs',
        PL: 'Cena',
        PT: 'Preço',
        RO: 'Preț',
        SK: 'Cena',
        SL: 'Cena',
        SV: 'Pris',
        CS: 'Cena',
        RU: 'Цена',
        IS: 'Verð',
        JA: '価格',
        KO: '가격',
        UK: 'Ціна',
        ZH: '价格',
    },
    month: {
        FR: 'mois',
        EN: 'month',
        DE: 'Monat',
        BG: 'месец',
        HR: 'mjesec',
        DA: 'måned',
        ES: 'mes',
        ET: 'kuu',
        FI: 'kuukausi',
        EL: 'μήνας',
        HU: 'hónap',
        GA: 'mí',
        IT: 'mese',
        LV: 'mēnesis',
        LT: 'mėnuo',
        MT: 'xahar',
        NL: 'maand',
        PL: 'miesiąc',
        PT: 'mês',
        RO: 'lună',
        SK: 'mesiac',
        SL: 'mesec',
        SV: 'månad',
        CS: 'měsíc',
        RU: 'месяц',
        IS: 'mánuður',
        JA: '月',
        KO: '달',
        UK: 'місяць',
        ZH: '月',
    },
    day: {
        FR: 'jour',
        EN: 'day',
        DE: 'Tag',
        BG: 'ден',
        HR: 'dan',
        DA: 'dag',
        ES: 'día',
        ET: 'päev',
        FI: 'päivä',
        EL: 'ημέρα',
        HU: 'nap',
        GA: 'lá',
        IT: 'giorno',
        LV: 'diena',
        LT: 'diena',
        MT: 'jum',
        NL: 'dag',
        PL: 'dzień',
        PT: 'dia',
        RO: 'zi',
        SK: 'deň',
        SL: 'dan',
        SV: 'dag',
        CS: 'den',
        RU: 'день',
        IS: 'dagur',
        JA: '日',
        KO: '일',
        UK: 'день',
        ZH: '天',
    },
    year: {
        FR: 'an',
        EN: 'year',
        DE: 'Jahr',
        BG: 'година',
        HR: 'godina',
        DA: 'år',
        ES: 'año',
        ET: 'aasta',
        FI: 'vuosi',
        EL: 'έτος',
        HU: 'év',
        GA: 'bliain',
        IT: 'anno',
        LV: 'gads',
        LT: 'metai',
        MT: 'sena',
        NL: 'jaar',
        PL: 'rok',
        PT: 'ano',
        RO: 'an',
        SK: 'rok',
        SL: 'leto',
        SV: 'år',
        CS: 'rok',
        RU: 'год',
        IS: 'ár',
        JA: '年',
        KO: '년',
        UK: 'рік',
        ZH: '年',
    },
    boatOnPro: {
        FR: 'BoatOn Pro',
        EN: 'BoatOn Pro',
        DE: 'BoatOn Pro',
        BG: 'BoatOn Pro',
        HR: 'BoatOn Pro',
        DA: 'BoatOn Pro',
        ES: 'BoatOn Pro',
        ET: 'BoatOn Pro',
        FI: 'BoatOn Pro',
        EL: 'BoatOn Pro',
        HU: 'BoatOn Pro',
        GA: 'BoatOn Pro',
        IT: 'BoatOn Pro',
        LV: 'BoatOn Pro',
        LT: 'BoatOn Pro',
        MT: 'BoatOn Pro',
        NL: 'BoatOn Pro',
        PL: 'BoatOn Pro',
        PT: 'BoatOn Pro',
        RO: 'BoatOn Pro',
        SK: 'BoatOn Pro',
        SL: 'BoatOn Pro',
        SV: 'BoatOn Pro',
        CS: 'BoatOn Pro',
        RU: 'BoatOn Pro',
        IS: 'BoatOn Pro',
        JA: 'BoatOn Pro',
        KO: 'BoatOn Pro',
        UK: 'BoatOn Pro',
        ZH: 'BoatOn Pro',
    },
    loadingText: {
        FR: `Nous sommes en train de charger vos informations, veuillez patienter...`,
        EN: `We are loading your information, please wait...`,
        DE: `Wir laden Ihre Informationen, bitte warten Sie...`,
        BG: `Зареждаме вашата информация, моля изчакайте...`,
        HR: `Učitavamo vaše informacije, molimo pričekajte...`,
        DA: `Vi indlæser dine oplysninger, vent venligst...`,
        ES: `Estamos cargando su información, espere por favor...`,
        ET: `Laadime teie teavet, palun oodake...`,
        FI: `Lataamme tietojasi, odota...`,
        EL: `Φορτώνουμε τις πληροφορίες σας, παρακαλώ περιμένετε...`,
        HU: `Betöltjük az információkat, kérjük várjon...`,
        GA: `Táimid ag luchtú do chuid eolais, fan le do thoil...`,
        IT: `Stiamo caricando le tue informazioni, attendi...`,
        LV: `Mēs ielādējam jūsu informāciju, lūdzu, uzgaidiet...`,
        LT: `Mes įkeliaime jūsų informaciją, prašome palaukti...`,
        MT: `Qed inkiżbu l-informazzjoni tiegħek, jekk jogħġbok stenna...`,
        NL: `We laden uw informatie, even geduld...`,
        PL: `Ładujemy twoje informacje, proszę czekać...`,
        PT: `Estamos a carregar as suas informações, por favor aguarde...`,
        RO: `Încărcăm informațiile dvs., vă rugăm să așteptați...`,
        SK: `Načítavame vaše informácie, prosím čakajte...`,
        SL: `Nalagamo vaše informacije, prosimo počakajte...`,
        SV: `Vi laddar din information, var god vänta...`,
        CS: `Načítáme vaše informace, prosím čekejte...`,
        RU: `Мы загружаем вашу информацию, пожалуйста, подождите...`,
        IS: `Við erum að hlaða inn upplýsingum þínum, vinsamlegast bíddu...`,
        JA: `情報を読み込んでいます、お待ちください...`,
        KO: `정보를 로드 중입니다. 잠시만 기다려주세요...`,
        UK: `Ми завантажуємо вашу інформацію, будь ласка, зачекайте...`,
        ZH: `我们正在加载您的信息，请稍等...`,
    },
    monday: {
        FR: 'Lundi',
        EN: 'Monday',
        DE: 'Montag',
        BG: 'понеделник',
        HR: 'ponedjeljak',
        DA: 'mandag',
        ES: 'lunes',
        ET: 'esmaspäev',
        FI: 'maanantai',
        EL: 'Δευτέρα',
        HU: 'hétfő',
        GA: 'Dé Luain',
        IT: 'lunedì',
        LV: 'pirmdiena',
        LT: 'pirmadienis',
        MT: 'It-Tnejn',
        NL: 'maandag',
        PL: 'poniedziałek',
        PT: 'segunda-feira',
        RO: 'luni',
        SK: 'pondelok',
        SL: 'ponedeljek',
        SV: 'måndag',
        CS: 'pondělí',
        RU: 'понедельник',
        IS: 'mánudagur',
        JA: '月曜日',
        KO: '월요일',
        UK: 'понеділок',
        ZH: '星期一',
    },
    tuesday: {
        FR: 'Mardi',
        EN: 'Tuesday',
        DE: 'Dienstag',
        BG: 'вторник',
        HR: 'utorak',
        DA: 'tirsdag',
        ES: 'martes',
        ET: 'teisipäev',
        FI: 'tiistai',
        EL: 'Τρίτη',
        HU: 'kedd',
        GA: 'Dé Máirt',
        IT: 'martedì',
        LV: 'otrdiena',
        LT: 'antradienis',
        MT: 'It-Tlieta',
        NL: 'dinsdag',
        PL: 'wtorek',
        PT: 'terça-feira',
        RO: 'marți',
        SK: 'utorok',
        SL: 'torek',
        SV: 'tisdag',
        CS: 'úterý',
        RU: 'вторник',
        IS: 'þriðjudagur',
        JA: '火曜日',
        KO: '화요일',
        UK: 'вівторок',
        ZH: '星期二',
    },
    wednesday: {
        FR: 'Mercredi',
        EN: 'Wednesday',
        DE: 'Mittwoch',
        BG: 'сряда',
        HR: 'srijeda',
        DA: 'onsdag',
        ES: 'miércoles',
        ET: 'kolmapäev',
        FI: 'keskiviikko',
        EL: 'Τετάρτη',
        HU: 'szerda',
        GA: 'Dé Céadaoin',
        IT: 'mercoledì',
        LV: 'trešdiena',
        LT: 'trečiadienis',
        MT: 'Il-Erbgħa',
        NL: 'woensdag',
        PL: 'środa',
        PT: 'quarta-feira',
        RO: 'miercuri',
        SK: 'streda',
        SL: 'sreda',
        SV: 'onsdag',
        CS: 'středa',
        RU: 'среда',
        IS: 'miðvikudagur',
        JA: '水曜日',
        KO: '수요일',
        UK: 'середа',
        ZH: '星期三',
    },
    thursday: {
        FR: 'Jeudi',
        EN: 'Thursday',
        DE: 'Donnerstag',
        BG: 'четвъртък',
        HR: 'četvrtak',
        DA: 'torsdag',
        ES: 'jueves',
        ET: 'neljapäev',
        FI: 'torstai',
        EL: 'Πέμπτη',
        HU: 'csütörtök',
        GA: 'Déardaoin',
        IT: 'giovedì',
        LV: 'ceturtdiena',
        LT: 'ketvirtadienis',
        MT: 'Il-Ħamis',
        NL: 'donderdag',
        PL: 'czwartek',
        PT: 'quinta-feira',
        RO: 'joi',
        SK: 'štvrtok',
        SL: 'četrtek',
        SV: 'torsdag',
        CS: 'čtvrtek',
        RU: 'четверг',
        IS: 'fimmtudagur',
        JA: '木曜日',
        KO: '목요일',
        UK: 'четвер',
        ZH: '星期四',
    },
    friday: {
        FR: 'Vendredi',
        EN: 'Friday',
        DE: 'Freitag',
        BG: 'петък',
        HR: 'petak',
        DA: 'fredag',
        ES: 'viernes',
        ET: 'reede',
        FI: 'perjantai',
        EL: 'Παρασκευή',
        HU: 'péntek',
        GA: 'Dé hAoine',
        IT: 'venerdì',
        LV: 'piektdiena',
        LT: 'penktadienis',
        MT: 'Il-Ġimgħa',
        NL: 'vrijdag',
        PL: 'piątek',
        PT: 'sexta-feira',
        RO: 'vineri',
        SK: 'piatok',
        SL: 'petek',
        SV: 'fredag',
        CS: 'pátek',
        RU: 'пятница',
        IS: 'föstudagur',
        JA: '金曜日',
        KO: '금요일',
        UK: "п'ятниця",
        ZH: '星期五',
    },
    saturday: {
        FR: 'Samedi',
        EN: 'Saturday',
        DE: 'Samstag',
        BG: 'събота',
        HR: 'subota',
        DA: 'lørdag',
        ES: 'sábado',
        ET: 'laupäev',
        FI: 'lauantai',
        EL: 'Σάββατο',
        HU: 'szombat',
        GA: 'Dé Sathairn',
        IT: 'sabato',
        LV: 'sestdiena',
        LT: 'šeštadienis',
        MT: 'Is-Sibt',
        NL: 'zaterdag',
        PL: 'sobota',
        PT: 'sábado',
        RO: 'sâmbătă',
        SK: 'sobota',
        SL: 'sobota',
        SV: 'lördag',
        CS: 'sobota',
        RU: 'суббота',
        IS: 'laugardagur',
        JA: '土曜日',
        KO: '토요일',
        UK: 'субота',
        ZH: '星期六',
    },
    sunday: {
        FR: 'Dimanche',
        EN: 'Sunday',
        DE: 'Sonntag',
        BG: 'неделя',
        HR: 'nedjelja',
        DA: 'søndag',
        ES: 'domingo',
        ET: 'pühapäev',
        FI: 'sunnuntai',
        EL: 'Κυριακή',
        HU: 'vasárnap',
        GA: 'Dé Domhnaigh',
        IT: 'domenica',
        LV: 'svētdiena',
        LT: 'sekmadienis',
        MT: 'Il-Ħadd',
        NL: 'zondag',
        PL: 'niedziela',
        PT: 'domingo',
        RO: 'duminică',
        SK: 'nedeľa',
        SL: 'nedelja',
        SV: 'söndag',
        CS: 'neděle',
        RU: 'воскресенье',
        IS: 'sunnudagur',
        JA: '日曜日',
        KO: '일요일',
        UK: 'неділя',
        ZH: '星期日',
    },
    hours: {
        FR: 'heures',
        EN: 'hours',
        DE: 'Stunden',
        BG: 'часове',
        HR: 'sati',
        DA: 'timer',
        ES: 'horas',
        ET: 'tundi',
        FI: 'tuntia',
        EL: 'ώρες',
        HU: 'óra',
        GA: 'uair',
        IT: 'ore',
        LV: 'stundas',
        LT: 'valandos',
        MT: 'siegħa',
        NL: 'uren',
        PL: 'godziny',
        PT: 'horas',
        RO: 'ore',
        SK: 'hodiny',
        SL: 'ure',
        SV: 'timmar',
        CS: 'hodiny',
        RU: 'часы',
        IS: 'klukkustundir',
        JA: '時間',
        KO: '시간',
        UK: 'години',
        ZH: '小时',
    },
    save: {
        FR: `Enregistrer`,
        EN: `Save`,
        DE: `Speichern`,
        BG: `Запази`,
        HR: `Spremi`,
        DA: `Gem`,
        ES: `Guardar`,
        ET: `Salvesta`,
        FI: `Tallenna`,
        EL: `Αποθήκευση`,
        HU: `Mentés`,
        GA: `Sábháil`,
        IT: `Salva`,
        LV: `Saglabāt`,
        LT: `Išsaugoti`,
        MT: `Salva`,
        NL: `Opslaan`,
        PL: `Zapisz`,
        PT: `Salvar`,
        RO: `Salvează`,
        SK: `Uložiť`,
        SL: `Shrani`,
        SV: `Spara`,
        CS: `Uložit`,
        RU: `Сохранить`,
        IS: `Vista`,
        JA: `セーブ`,
        KO: `저장`,
        UK: `Зберегти`,
        ZH: `保存`,
    },
    remainingUsers: {
        FR: 'Reste des utilisateurs',
        EN: 'Remaining users',
        DE: 'Verbleibende Benutzer',
        BG: 'Оставащи потребители',
        HR: 'Preostali korisnici',
        DA: 'Resterende brugere',
        ES: 'Usuarios restantes',
        ET: 'Jäänud kasutajad',
        FI: 'Jäljellä olevat käyttäjät',
        EL: 'Υπόλοιποι χρήστες',
        HU: 'Maradék felhasználók',
        GA: 'Úsáideoirí atá fágtha',
        IT: 'Utenti rimanenti',
        LV: 'Atlikušie lietotāji',
        LT: 'Liko vartotojai',
        MT: 'Utenti li fadal',
        NL: 'Overgebleven gebruikers',
        PL: 'Pozostali użytkownicy',
        PT: 'Usuários restantes',
        RO: 'Utilizatori rămași',
        SK: 'Zostávajúci používatelia',
        SL: 'Preostali uporabniki',
        SV: 'Återstående användare',
        CS: 'Zbývající uživatelé',
        RU: 'Оставшиеся пользователи',
        IS: 'Eftirfarandi notendur',
        JA: '残りのユーザー',
        KO: '남은 사용자',
        UK: 'Залишилися користувачі',
        ZH: '剩余用户',
    },
    amountEt: {
        FR: 'Montant HT',
        EN: 'Amount excl. tax',
    },
    amountIt: {
        FR: 'Montant TTC',
        EN: 'Amount incl. tax',
    },
    vat: {
        FR: 'TVA',
        EN: 'VAT',
    },
}
