export default {
    equipment: {
        FR: `Edition de l'équipement`,
        EN: `Editing the equipment`,
    },
    part: {
        FR: `Edition de la pièce`,
        EN: `Editing the part`,
    },
    typeEv: {
        FR: `Choisissez un type d'évènement`,
        EN: `Choose an event type`,
    },
    addPart: {
        FR: `Ajout de la pièce`,
        EN: `Adding the part`,
    },
    addEquipment: {
        FR: `Ajout de l'équipement`,
        EN: `Adding the equipment`,
    },
    errorChoiceType: {
        FR: `Vous devez choisir un type de transaction.`,
        EN: `You must choose a transaction type.`,
    },
    typeRepair: {
        FR: 'Type de tâche',
        EN: 'Repair type',
    },
    details: {
        FR: `Détails`,
        EN: `Details`,
    },
    essentialInfos: {
        FR: `Informations essentielles`,
        EN: `Essential information`,
    },
    euro: {
        FR: '€',
        EN: '€',
    },
    chosePerson: {
        FR: "Choisir le responsable de l'opération",
        EN: 'Choose the person in charge of the operation',
    },
    errorDate: {
        FR: `Vous devez choisir une date.`,
        EN: `You must choose a date.`,
    },
    errorActualCycleLengthNext: {
        FR: `Indiquez ici le nombre d'heures auquel va être fait ce prochain contrôle.`,
        EN: `Please enter the current number of hours your equipment has been in operation.`,
    },
    errorActualCycleLengthLast: {
        FR: `Indiquez ici le nombre d'heures auquel a été fait ce dernier contrôle. Nous pourrons ainsi calculer la prochaine échéance.`,
        EN: `Please enter the current number of hours your equipment has been in operation. This will allow us to calculate the next inspection deadline.`,
    },
    actualCycleHours: {
        FR: `Nombre d'heures`,
        EN: `Current time of use in hours`,
    },
    checkupDate: {
        FR: `Date`,
        EN: `Date`,
    },
    amount: {
        FR: `Montant (TTC)`,
        EN: `Amount (incl. VAT)`,
    },
    notes: {
        FR: `Notes complémentaires`,
        EN: `Additional notes`,
    },
    conclude: {
        FR: `Conclusions et recommandations`,
        EN: `Conclusions and recommendations`,
    },
    helpHoursLast: {
        FR: `Indiquez ici le nombre d'heures auquel cette tâche a été effectué`,
        EN: `Indicate here the number of hours at which this task was conducted`,
    },
    helpHours: {
        FR: `Indiquez le nombre d'heures auquel il faudra effectuer cette tâche`,
        EN: `Indicate the number of hours that will be required to perform this task`,
    },
    helpDateLast: {
        FR: `Indiquez ici la date à laquelle cette tâche a été effectué`,
        EN: `Indicate here the date on which this task was conducted`,
    },
    helpDate: {
        FR: `Sélectionnez la date à laquelle il faudra effectuer cette tâche`,
        EN: `Select the date on which you want to perform this task`,
    },
    next: {
        FR: `Suivant`,
        EN: `Next`,
    },
    validateTask: {
        FR: `Enregistrer`,
        EN: `Save`,
    },
    validate: {
        FR: `Valider la tâche`,
        EN: `Validate the task`,
    },
    unvalidate: {
        FR: `Dévalider la tâche`,
        EN: `Unvalidate the task`,
    },
    update: {
        FR: `Mettre à jour`,
        EN: `Update`,
    },
    documents: {
        FR: `Photos et documents`,
        EN: `Photos and documents`,
    },
    responsible: {
        FR: `Opérateur`,
        EN: `Operator`,
    },
    supervisor: {
        FR: `Manager`,
        EN: `Manager`,
    },
    tooltipResponsible: {
        FR: `Renseignez le ou les responsables de cette opération. Ils recevront un e-mail les invitant à se connecter et visualiser vos instructions.`,
        EN: `Inform the person or persons responsible for this operation. They will receive an e-mail inviting them to log in and view your instructions.`,
    },
    tooltipSupervisor: {
        FR: `Renseignez le ou les managers chargés de suivre la résolution de cette tâche. Ils recevront un e-mail les invitant à se connecter et traiter la tâche.`,
        EN: `Fill in the manager(s) responsible for following the resolution of this task. They will receive an email inviting them to connect and treat the task.`,
    },
    addLabelButton: {
        FR: `Ajouter un utilisateur`,
        EN: `Add a user`,
    },
    newUserTitle: {
        FR: `Invitation d'un nouvel utilisateur`,
        EN: `Invite a new user`,
    },
    helpGreyChip: {
        FR: `Cet utilisateur n'a pas encore rejoint votre flotte`,
        EN: `This user has not yet joined your fleet`,
    },
    protocol: {
        FR: `Protocole suivi`,
        EN: `Indications followed`,
    },
    protocolFollow: {
        FR: `Protocole à suivre`,
        EN: `Indications to follow`,
    },
    helpProtocol: {
        FR: `Une fois que cette tâche sera validé, ce protocole sera copié automatiquement si vous optez pour la planification automatique de la même tâche. Vous n'aurez donc pas besoin de saisir de nouveau ces informations`,
        EN: `Once this checkup is validated, these indications will be copied automatically to the next task. You will not need to re-enter this information`,
    },
    validEventTitle: {
        FR: `Validation de l'opération`,
        EN: `Checkup confirmation`,
    },
    cancel: {
        FR: `Annuler`,
        EN: `Go back`,
    },
    unvalidEventTitle: {
        FR: `Annulation de la validation`,
        EN: `Cancellation of the validation`,
    },
    activity: {
        FR: "Fil d'activité",
        EN: 'Activity feed',
    },

    amountEt: {
        FR: 'Montant HT',
        EN: 'Amount VAT excl.',
    },
    amountIt: {
        FR: 'Montant TTC',
        EN: 'Amount VAT incl.',
    },
    tax: {
        FR: 'TVA',
        EN: 'VAT',
    },
}
