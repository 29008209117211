export default {
    titleOneEquipmentAvailable: {
        FR: `équipement de rechange`,
        EN: `spare equipment`,
    },
    titleEquipmentsAvailable: {
        FR: `équipements de rechange`,
        EN: `spare equipments`,
    },
    titleActualEquipment: {
        FR: `Équipement en cours d'utilisation`,
        EN: `Equipment in use`,
    },
    titleOneEquipmentChanged: {
        FR: `équipement remplacé`,
        EN: `replaced equipment`,
    },
    titleReplaced: {
        FR: `remplacé(es)`,
        EN: `equipments`,
    },
    titleOnePartAvailable: {
        FR: `pièce de rechange`,
        EN: `spare part`,
    },
    titlePartsAvailable: {
        FR: `pièces de rechange`,
        EN: `spare parts`,
    },
    titleActual: {
        FR: `En cours d'utilisation`,
        EN: `In use`,
    },
    titleOnePartChanged: {
        FR: `pièce remplacée`,
        EN: `replaced part`,
    },
    titlePartsChanged: {
        FR: `Pièces remplacées`,
        EN: `Replaced parts`,
    },
    replacement: {
        FR: 'Remplacé le',
        EN: 'Replace',
    },
    startDate: {
        FR: 'Mis(e) en service le',
        EN: 'Commissioning',
    },
    notSet: {
        FR: 'Non renseigné',
        EN: 'Not set',
    },
    addStock: {
        FR: `Ajouter du stock`,
        EN: `Add stock`,
    },
    renewedPartsMonth: {
        FR: 'Pièce à renouveler dans les 3 mois',
        EN: 'Part to be renewed within 3 months',
    },
    renewedEquipmentMonth: {
        FR: 'Équipement à renouveler dans les 3 mois',
        EN: 'Equipment to be renewed within 3 months',
    },
    dateReplaceDeprecated: {
        FR: 'La date de remplacement est dépassée',
        EN: 'The replacement date is deprecated',
    },
    inStock: {
        FR: 'En stock',
        EN: 'In stock',
    },
    equipmentInStock: {
        FR: 'Équipement en stock',
        EN: 'Equipment in stock',
    },
    validate: {
        FR: 'Validé',
        EN: 'Validate',
    },
    validateUnarchiveEquipment: {
        FR: `Êtes-vous sur de vouloir remettre cet équipement en cours d'utilisation ?`,
        EN: `Are you sure you want to put this equipment back in use?`,
    },
    validateUnarchivePart: {
        FR: `Êtes-vous sur de vouloir remettre cette pièce en cours d'utilisation ?`,
        EN: `Are you sure you want to put this part back in use?`,
    },
    replaceEquipment: {
        FR: `Cliquez ici lorsque cet équipement est remplacé`,
        EN: `Click here when this equipment is replaced`,
    },
    replacePart: {
        FR: `Cliquez ici lorsque cette pièce est remplacée`,
        EN: `Click here when this part is replaced`,
    },
    actualyUsed: {
        FR: `utilisé(e) sur le bateau `,
        EN: `used on the boat`,
    },
    actualyAvailable: {
        FR: `disponible(s) pour un remplacement`,
        EN: `available for replacement`,
    },
    listReplaced1: {
        FR: `Liste des`,
        EN: `List of replaced`,
    },
    listReplaced2: {
        FR: `remplacé(es) sur le bateau`,
        EN: `on the boat`,
    },
    model: {
        FR: `Modèle`,
        EN: `Model`,
    },
    maker: {
        FR: `Marque`,
        EN: `Maker`,
    },
    toReplace: {
        FR: `À remplacer le`,
        EN: `To replace the`,
    },
    controlHourLabel: {
        FR: `Contrôlé à`,
        EN: `Controlled at`,
    },
    replaceHourLabel: {
        FR: `À remplacer dans`,
        EN: `To be replaced in`,
    },
    replaced: {
        FR: `Remplacé(es) le`,
        EN: `Replaced the`,
    },
    deleteElement: {
        FR: `Êtes-vous sûr de vouloir supprimer cet élément ?`,
        EN: `Are you sure you want to remove this element?`,
    },
    desc1: {
        FR: `Chouette, vous êtes en train d'ajouter du stock pour`,
        EN: `Great, you're adding stock for`,
    },
    desc2: {
        FR: `. Est-ce le même modèle ?`,
        EN: `. Is it the same model?`,
    },
    yes: {
        FR: `Oui`,
        EN: `Yes`,
    },
    no: {
        FR: `Non`,
        EN: `No`,
    },
    confirmCreatingUserEngine: {
        FR: `Il est préférable d'utiliser les moteurs déjà connu de BoatOn (moteur, moteur babord, moteur tribord) pour un meilleur suivi de votre bateau. Êtes-vous sûr de vouloir créer votre propre moteur ?`,
        EN: `It is better to use the engines already known to BoatOn (engine, port engine, starboard engine) for better monitoring of your boat. Are you sure you want to create your own engine?`,
    },
}
