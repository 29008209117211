export default theme => ({
    // Titre de la vue actuelle
    fcCurentViewTitle: {
        display: 'flex',
        justifySelf: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
            marginBottom: 0,
        },
    },

    fcHeader: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
        },
    },

    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 20,
        },
    },

    //Custom toolbar
    fcToolbar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 18,
        paddingBottom: 18,
        borderBottom: 'solid 1px grey',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            gap: 10,
        },
    },

    // Section de la toolbar
    fcSection: {
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'space-between',
        },
    },

    fcFilterContainer: {
        display: 'flex',
        flexDirection: 'row',
        gap: 12,
    },

    input: {
        height: 40,
        paddingLeft: 12,
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'lightgrey',
    },
    // Style du sélecteur du mode de vue
    viewDropdown: {
        fontSize: '1em',
        height: 40,
        width: 'auto',
        border: 'none',
        backgroundColor: '#f5f6f7',
        borderRadius: 5,
        display: 'flex',
        flexDirection: 'row',
        overflow: 'hidden',
        cursor: 'pointer',
        color: 'black',
        fontWeight: 500,
        textAlign: 'center',
    },

    actionDropdown: {
        fontSize: '1rem',
        color: 'white',
        height: 40,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            flex: 1,
        },
    },

    fcButton: {
        height: 40,
        fontSize: '1em',
        verticalAlign: 'middle',
        border: 'none',
        cursor: 'pointer',
        borderRadius: 8,
        overflow: 'hidden',
    },
    small: {
        minWidth: '0px !important',
        width: 30,
    },

    fcIcon: {
        speak: 'none',
        '-webkit-font-smoothing': 'antialiased',
        display: 'inline-block',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: 400,
        height: '1em',
        lineHeight: 1,
        textAlign: 'center',
        textTransform: 'none',
        userSelect: 'none',
        width: '1em',
        fontFamily: 'fcicons !important',
    },

    fcButtonGroup: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
    },

    /* Création de la class secondary pour les boutons */
    fcButtonSecondary: {
        backgroundColor: '#f5f6f7 !important;',
        color: 'rgba(0, 0, 0, 0.87) !important',
    },

    // Styles des filtres
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        marginTop: '10px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0,
            marginTop: 0,
        },
    },
    secondLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
    },
    searchFilterUnfold: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: '0',
            flexDirection: 'column',
            gap: '10px',
        },
    },
    recherche: {
        // width: '40%',
        // minWidth: '300px',
        marginRight: '30px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: 0,
            minWidth: '0',
        },
    },
    field: {
        width: '100%',
        margin: 'auto',
        fontSize: '16px',
        border: 'solid 1px lightgrey',
        borderRadius: 5,
    },
    filtersButton: {
        height: 40,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            display: 'flex',
            flex: 1,
        },
    },
    filtersMobileButton: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    unfoldAll: {
        width: '35%',
        minWidth: '350px',
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'left',
            fontSize: '0.8em',
            minWidth: '0',
        },
    },
    hidde: {
        display: 'none',
    },
    filtersBar: {
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            gap: 3,
            justifyContent: 'space-evenly',
        },
        gap: 8,
        flex: 5,
        flexWrap: 'wrap',
    },
    filterButton: {
        backgroundColor: 'white',
        border: '1px solid #303F9F',
        margin: '0 20px',
        color: '#212121',
        fontWeight: 500,
        [theme.breakpoints.down('sm')]: {
            margin: '0 10px',
        },
        fontSize: 14,
    },
    filterButtonFill: {
        backgroundColor: '#303F9F',
        border: '1px solid #303F9F',
        color: 'white',
        margin: '0 20px',
        fontWeight: 500,
        fontSize: 14,
    },
    divider: {
        marginTop: '15px',
        marginBottom: '25px',
        [theme.breakpoints.down('sm')]: {
            marginTop: '5px',
        },
    },

    filterParts: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    textParts: {
        marginTop: '7px',
    },
    controlLabel: {
        marginLeft: '10px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    paper: {
        [theme.breakpoints.down('xs')]: {
            borderRadius: '13px',
            maxWidth: '100vh',
            marginLeft: '0%',
            overflowY: 'hidden',
            // '& .MuiPaper-root ': {},
            // '& .MuiPopover-paper': {},
        },
    },
    iconMenu: {
        width: 20,
        height: 20,
        'mix-blend-mode': 'exclusion',
        color: 'white',
    },
    rootPaper: {
        [theme.breakpoints.down('xs')]: {
            '& .MuiPopover-paper ': {
                position: 'initial',
                marginTop: '69px',
            },
        },
    },

    addEventMenu: {
        minWidth: '300px',
    },
    addEventMenuItem: {
        justifyContent: 'center !important',
        gap: 10,
    },
    exportButton: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#303F9F',
        marginBottom: 5,
        height: 40,
        cursor: 'pointer',
    },
    dayCell: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 40,
        width: 40,
        borderRadius: 20,
        '&.current': {
            backgroundColor: '#303F9F',
            color: 'white !important',
        },
        fontWeight: 500,
    },
    dayLetter: {
        color: 'lightgrey',
        fontWeight: 'normal',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        marginBottom: 15,
        flex: 1,
    },
})
