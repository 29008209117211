import { inqueryConstants } from '../../constants'
import { inqueriesActions } from './inqueries.actions'
import { requestApi } from '../../services/api.service'
import { parkingActions } from '../parkings/parking.actions'
import { postDataBOBDocuments } from '../bob/documents.actions'

const generateInquery = (infosRent, message) => {
    return dispatch => {
        const delimitedDate = {
            startDate: infosRent.startDate,
            endDate: infosRent.endDate,
        }

        const platformType = { id: 1 }
        const parkingInq = {
            length: parseFloat(infosRent.length).toFixed(2),
            width: parseFloat(infosRent.width).toFixed(2),
            height: parseFloat(infosRent.height).toFixed(2),
            parking: { id: infosRent.parking.id },
            mensualisation: infosRent.mensualisation === 1 ? true : false,
            definedEnd: infosRent.endDate ? true : false,
            priceToCheck: parseFloat(
                parkingActions.getPriceOfOneTransactionIt({
                    mensualisation: infosRent.mensualisation,
                    parking: infosRent.parking,
                    startDate: infosRent.startDate,
                    endDate: infosRent.endDate,
                    length: infosRent.length,
                    width: infosRent.width,
                }),
            ).toFixed(2),
        }
        const inqueryRelation = [
            {
                messages: [{ message: message, messageType: { id: 1 } }],
            },
        ]

        dispatch({
            type: inqueryConstants.CREATE_INQUERY_PARKING,
        })
        return requestApi(`/inqueries/parking`, 'POST', {
            delimitedDate,
            platformType,
            parkingInq,
            inqueryRelations: message ? inqueryRelation : [],
        }).then(
            result => {
                dispatch({
                    type: inqueryConstants.CREATE_INQUERY_PARKING_SUCCESS,
                    inquery: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: inqueryConstants.CREATE_INQUERY_PARKING_FAILED,
                    error: error,
                })
            },
        )
    }
}

const createInfoInsurance = (
    useInfos,
    insuranceInfos,
    boatInfos,
    accountInfos,
) => {
    return async dispatch => {
        dispatch({
            type: inqueryConstants.CREATE_INQUERY_INSURANCE,
        })

        const inqueryStatus = {
            id: 1,
        }
        const platformType = { id: 1 }

        const insuranceInq = {
            actualInsurance: insuranceInfos.currentInsurer,
            currentPrice: parseInt(insuranceInfos.currentPrice),
            insuredValue: parseInt(insuranceInfos.insuredValue),
            expirationDate: new Date(useInfos.expirationDate).toISOString(),
            boatUseType: useInfos.use,
            privateBoatActivities: useInfos.privateBoatActiviesTags,
            navFrequencyType: useInfos.frequencyOfNavigation,
            proBoatActivities: useInfos.proBoatActiviesTags,
            isBoatMainHome: useInfos.mainHousing,
            isBoatToBeRented: useInfos.loanedBoat,
            rentFrequencyType: useInfos.loanFrequency,
            presenceType: useInfos.skipperPresent,
            isBoatLoanedOnDock: useInfos.boatLoanedOnDock,
            loanTool: useInfos.loanTool,
            navigationProgram: useInfos.navigationProgram,
            isAnnexe: useInfos.isAnnexe,
            isEngineAnnexe: useInfos.isEngineAnnexe,
            engineModelAnnexe: useInfos.engineModelAnnexe,
            annexeModel: useInfos.annexeModel,
            isTrailer: useInfos.doYouWantToInsureTrailer,
            trailerValue: parseInt(useInfos.trailerValue),
            boat: {
                id: boatInfos.boatIdSelected,
            },
            boatPurchaseDate: boatInfos.boatPurchaseDate,
            pastInsuranceTrouble: insuranceInfos.pastInsuranceTrouble,
            inqueryIsOnBehalfCompany: accountInfos.yourInqueryIsOnBehalf,
            ownershipPeriod: insuranceInfos.ownershipPeriod,
            boatLicenceType: accountInfos.boatLicence,
            insuranceNote: '',
        }

        if (useInfos.attachedFiles) {
            insuranceInq.attachedFiles =
                (await postDataBOBDocuments(
                    useInfos.attachedFiles.map(file => file.infos),
                    3,
                )) || []
        }

        return requestApi(`/inqueries/insurance`, 'POST', {
            inqueryStatus,
            platformType,
            insuranceInq,
            inqueryRelation: [],
        }).then(
            result => {
                dispatch({
                    type: inqueryConstants.CREATE_INQUERY_INSURANCE_SUCCESS,
                    inquery: result,
                })
                return result
            },
            error => {
                dispatch({
                    type: inqueryConstants.CREATE_INQUERY_INSURANCE_FAILED,
                    error: error,
                })
            },
        )
    }
}

const checkAvailabilityParking = (
    parkingId,
    length,
    width,
    startDate,
    endDate,
) => {
    return dispatch => {
        let query = `?parkingId=${parkingId}&length=${length}&width=${width}&startDate=${startDate}`

        if (endDate) query += `&endDate=${endDate}`

        if (parkingId !== null) {
            requestApi(`/inqueries/space${query}`, 'GET').then(
                result => {
                    dispatch({
                        type: inqueryConstants.CHECK_AVAILABILITY_SUCCESS,
                        isAvailable: result,
                    })
                },
                error => {
                    dispatch({
                        type: inqueryConstants.CHECK_AVAILABILITY_FAILED,
                        error: error,
                    })
                },
            )
        }
    }
}

const resetAvailabilityParking = () => {
    return dispatch => {
        dispatch({
            type: inqueryConstants.RESET_AVAILABILITY,
        })
    }
}

const getInquery = id => {
    return dispatch => {
        dispatch({
            type: inqueryConstants.REQUEST_INQUERY,
        })

        requestApi(`/inqueries/${id}`, 'GET').then(
            result => {
                dispatch({
                    type: inqueryConstants.REQUEST_INQUERY_SUCCESS,
                    parkingInquery: result,
                })
            },
            error => {
                dispatch({
                    type: inqueryConstants.REQUEST_INQUERY_FAILURE,
                    error: error,
                })
            },
        )
    }
}

const getSessionUrl = (infos, language) => {
    return dispatch => {
        dispatch({
            type: inqueryConstants.GET_SESSION_URL,
        })

        return requestApi(
            `/inqueries/session`,
            'POST',
            infos,
            false,
            true,
            language,
        ).then(
            result => {
                dispatch({
                    type: inqueryConstants.GET_SESSION_URL_SUCCESS,
                })
                return result?.url
            },
            error => {
                dispatch({
                    type: inqueryConstants.GET_SESSION_URL_FAILED,
                    error: error,
                })
            },
        )
    }
}

const acceptInquery = (id, language, callback) => {
    return dispatch => {
        dispatch({
            type: inqueryConstants.ACCEPT_INQUERY,
        })

        requestApi(
            `/inqueries/status/accept/${id}`,
            'PUT',
            {},
            false,
            true,
            language,
        ).then(
            result => {
                dispatch({
                    type: inqueryConstants.ACCEPT_INQUERY_SUCCESS,
                    parkingInquery: result,
                })
                callback()
            },
            error => {
                dispatch({
                    type: inqueryConstants.ACCEPT_INQUERY_FAILURE,
                    error: error,
                })
            },
        )
    }
}

const refuseInquery = (id, language, callback) => {
    return dispatch => {
        dispatch({
            type: inqueryConstants.REFUSE_INQUERY,
        })

        requestApi(
            `/inqueries/status/refuse/${id}`,
            'PUT',
            {},
            false,
            true,
            language,
        ).then(
            result => {
                dispatch({
                    type: inqueryConstants.REFUSE_INQUERY_SUCCESS,
                    parkingInquery: result,
                })
                callback()
            },
            error => {
                dispatch({
                    type: inqueryConstants.REFUSE_INQUERY_FAILURE,
                    error: error,
                })
            },
        )
    }
}

const cancelInquery = (id, language) => {
    return dispatch => {
        dispatch({
            type: inqueryConstants.CANCEL_INQUERY,
        })

        requestApi(
            `/inqueries/status/cancel/${id}`,
            'PUT',
            {},
            false,
            true,
            language,
        ).then(
            result => {
                dispatch({
                    type: inqueryConstants.CANCEL_INQUERY_SUCCESS,
                    parkingInquery: result,
                })
                dispatch(inqueriesActions.getPersonalParkingInqueries())
            },
            error => {
                dispatch({
                    type: inqueryConstants.CANCEL_INQUERY_FAILED,
                    error: error,
                })
            },
        )
    }
}

const setInquery = inquery => {
    return dispatch => {
        dispatch({
            type: inqueryConstants.SET_INQUERY,
            parkingInquery: inquery,
        })
    }
}

const unsetInquery = () => {
    return dispatch => {
        dispatch({
            type: inqueryConstants.UNSET_INQUERY,
        })
    }
}

export const inqueryActions = {
    checkAvailabilityParking,
    generateInquery,
    getInquery,
    refuseInquery,
    acceptInquery,
    cancelInquery,
    setInquery,
    unsetInquery,
    resetAvailabilityParking,
    getSessionUrl,
    createInfoInsurance,
}
