export default theme => {
    return {
        root: {
            flexGrow: 1,
            overflowY: `auto`,
            height: `calc(100vh - 94px)`,
            display: `flex`,
            flexDirection: `column`,
            justifyContent: `space-between`,
        },
        container: {
            alignItems: `center`,
            [theme.breakpoints.down('sm')]: {
                height: 100,
                display: 'flex',
            },
        },
        buttonImage: {
            display: `flex`,
            boxSizing: `border-box`,
            borderRadius: 4,
            fontWeight: `bold`,
            alignItems: `center`,
            justifyContent: `center`,
            textAlign: `center`,
            color: `#303F9F`,
            background: `#E5E7F0`,
            marginRight: 10,
        },
        menuChoixUt: {
            marginTop: 13,
        },
        menuItemsAccount: {
            maxWidth: 188,
        },
        expandIcon: {
            height: 20,
            width: 20,
            marginLeft: 15,
            transition: `0.4s`,
            transform: `rotateZ(0deg)`,
        },
        expandIconActivated: {
            transform: `rotateZ(-90deg)`,
        },
        openMenu: {
            display: `flex`,
            marginRight: 12,
            alignItems: `center`,
            fontSize: `14px`,
            boxSizing: `border-box`,
            maxWidth: 188,
            height: 50,
            border: `1px solid rgba(193, 193, 193, 0.55)`,
            '& .MuiButton-label': {
                padding: 0,
            },
        },
        openMenuMobile: {
            display: `flex`,
            alignItems: `center`,
            fontSize: `14px`,
            boxSizing: `border-box`,
            width: `100%`,
            maxWidth: `250px`,
            border: `none`,
            height: 56,
        },
        appBar: {
            width: '100%',
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        content: {
            flexGrow: 1,
            backgroundColor: '#fff',
            [theme.breakpoints.down('sm')]: {
                marginTop: 0,
                padding: '0px 0px 35px 0px',
            },
            overflowX: `clip`,
        },
        flex: {
            width: '100%',
            height: '100%',
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textDecorationLine: 'none',
            paddingRight: '10px',
            paddingLeft: '10px',
            color: '#000',
            '&:hover': {
                color: '#fff',
            },
            fontSize: '14px',
            lineHeight: '1.25',
        },
        category: {
            border: '1px solid #c4c4c4',
            width: '160px',
            height: '30px',
            margin: '3px',
            marginTop: '10px',
            marginBottom: '10px',
            boxShadow: '0px 2px 10px 0.5px #c4c4c4',
            borderRadius: 5,
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        textCategory: {
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            textAlign: 'left',
            color: '#000',
            fontSize: '14px',
            fontWeight: '500',
        },
        logotel: {
            marginTop: '2px',
            marginBottom: '2px',
            fill: '#fff',
            width: '15px !important',
        },
        textPhone: {
            width: '155px',
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 'auto',
            marginBottom: 'auto',
            marginRight: '10px',
            border: '2px solid #303F9F',
            borderRadius: '5px',
            textAlign: 'left',
            color: '#303f9f',
            fontSize: '14px',
            fontWeight: 'bold',
        },
        flexImage: {
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 5,
            paddingBottom: 10,
            marginRight: 10,
            textDecorationLine: 'none',
            color: '#000',
        },
        store: {
            width: '150px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginLeft: '20px',
        },
        menu: {
            width: '100%',
            height: '64px',
            padding: '0 40px',
        },
        containedSizeSmall: {
            padding: '4px 20px',
        },
        topbarcontainer: {
            height: '30px',
            backgroundColor: '#3040a0',
            color: '#fff',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        topbar: {
            height: '30px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        infosBox: {
            display: 'flex',
            alignItems: 'center',
        },
        span: {
            margin: '0 8px',
        },
        topBarPhone: {
            fontSize: '12px',
            margin: '0',
            color: '#fff',
            textDecoration: 'none',
            fontWeight: '800',
        },
        topBarText: {
            fontSize: '12px',
            margin: '0',
        },
        firstPart: {
            display: 'flex',
            flexDirection: 'row',
        },
        secondPart: {
            display: 'flex',
            flexDirection: 'row',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        hideMobile: {
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        buttonMenu: {
            textTransform: 'capitalize',
            fontSize: '22px',
            fontWeight: 'normal',
            paddingLeft: 20,
            paddingRight: 20,
        },
        buttonProfil: {
            fontSize: '1rem',
            color: 'white',
            height: 35,
        },

        buttonProfilTwo: {
            fontSize: '1rem',
            color: 'white',
            height: 35,
        },
        menuOpener: {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            fontSize: '1rem',
            fontWeight: '400',
            color: 'white',
            width: 3,
        },

        li: {
            padding: 0,
            height: '100%',
            whiteSpace: 'normal',
        },
        active: {
            paddingRight: '10px',
            paddingLeft: '10px',
            height: '100%',
            color: '#fff',
            backgroundColor: '#3040a0',
            lineHeight: '1.25',
        },
        applications: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '245px',
            height: '36px',
            margin: 'auto',
            marginLeft: '20px',
            [theme.breakpoints.down('sm')]: {
                display: 'none',
            },
        },
        profileList: {
            minWidth: '300px',
        },
        profileMenuItem: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        divider: {
            margin: '8px 0',
        },
        image: {
            width: '120px',
            height: '36px',
        },
        imgCategory: {
            borderTopLeftRadius: 5,
            borderBottomLeftRadius: 5,
        },
        menuMobile: {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        menuMobileItem: {
            marginRight: theme.spacing(2),
        },
        notificationBox: {
            padding: '10px',
        },
        profileItem: {
            color: '#000',
            textDecoration: 'none!important',
        },
        loginSection: {
            display: 'flex',
        },
        signinButton: {
            marginRight: '5px',
            '&:hover': {
                backgroundColor: '#d5d5d5',
            },
        },
        signupButton: {
            marginLeft: '5px',
        },
        signinTextButton: {
            color: '#000',
            textDecoration: 'none!important',
        },
        signupTextButton: {
            color: '#fff',
            textDecoration: 'none!important',
        },
        activeTextCategory: {
            color: '#3040a0',
            height: '30px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'left',
            textAlign: 'left',
            fontSize: '14px',
            fontWeight: '500',
            position: 'relative',
            '&::before': {
                content: `''`,
                position: 'absolute',
                bottom: '-5px',
                width: '40px',
                height: '2px',
                backgroundColor: '#3040A0',
                marginLeft: 'calc(50% - 20px)',
            },
        },
        mobileDrawerButton: {
            height: 40,
            width: 40,
            border: '2px solid #303F9F',
            borderRadius: '100%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
        },
        drawerPaper: {
            minWidth: '50%',
            overflow: 'hidden',
        },
        logoContainer: {
            justifyContent: 'space-between',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'space-between',
            },
            [theme.breakpoints.down('xs')]: {
                justifyContent: 'space-between',
            },

            '& > div:first-child': {
                [theme.breakpoints.up('sm')]: {
                    justifyContent: 'flex-start',
                    paddingInline: 6,
                },
            },
        },
    }
}
