import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import BoatOnModalCore from '../../../common/BoatOnModalCore'
import dictionary from './Dictionary/InventoryRelacementModalDico'
import styles from './Styles/InventoryRelacementModalCss'
import { Typography, Checkbox } from '@material-ui/core'
import {
    putBOBEvents,
    copyBOBStateEvent,
} from '../../../../actions/bob.actions'
import BoatOnModal from '../../../common/BoatOnModal'
import InventoryModal from './InventoryModal/InventoryModal'

class InventoryRelacementModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            eventSelected: null,
            openModal: null,
        }

        this.handleChangeSelected = this.handleChangeSelected.bind(this)
        this.replace = this.replace.bind(this)
        this.addStock = this.addStock.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.added = this.added.bind(this)
    }

    handleChangeSelected(checked, event) {
        if (checked) this.setState({ eventSelected: event })
        else this.setState({ eventSelected: null })
    }

    replace() {
        const { eventSelected } = this.state
        const { equipment, part } = this.props
        const archive = equipment || part
        this.props.dispatch(
            putBOBEvents(archive.id, archive.title, {
                detail: { ...archive.detail, archived: new Date() },
            }),
        )
        this.props.dispatch(
            putBOBEvents(eventSelected.id, eventSelected.title, {
                delimitedDate: {
                    startDate: new Date(),
                    endDate: null,
                },
                detail: {
                    ...eventSelected.detail,
                    hourActual: archive.detail.hourReplacement
                        ? archive.detail.hourReplacement
                        : null,
                },
            }),
        )
        this.props.handleClose()
        this.props.saveMenuModalIndex(0)
    }

    addStock() {
        const { part, equipment } = this.props
        copyBOBStateEvent(this, { event: part || equipment })
        this.setState({ openModal: 'addStock' })
    }

    handleCloseModal() {
        this.setState({ openModal: null })
    }

    added() {
        const { equipment, part } = this.props
        const archive = equipment || part

        this.props.dispatch(
            putBOBEvents(archive.id, archive.title, {
                detail: { ...archive.detail, archived: new Date() },
            }),
        )
        this.props.saveMenuModalIndex(0)
        this.handleCloseModal()
        this.props.handleClose()
    }

    renderLine(event, index) {
        const { classes } = this.props

        return (
            <div
                className={`${classes.rootLine} ${
                    index % 2 !== 0 ? classes.backgroundColorWhite : ''
                }`}
            >
                <Typography className={classes.div33}>
                    {event.detail.brand || '-'}
                </Typography>
                <Typography className={classes.div33}>
                    {event.detail.model || '-'}
                </Typography>
                <Typography className={classes.div33}>
                    {event.detail.serialNumber || '-'}
                </Typography>
                <div className={classes.icons}>
                    <Checkbox
                        checked={
                            this.state.eventSelected &&
                            this.state.eventSelected.id === event.id
                        }
                        onChange={e => {
                            this.handleChangeSelected(e.target.checked, event)
                        }}
                    />
                </div>
            </div>
        )
    }

    _body() {
        const { classes, equipment, part, replace } = this.props

        return (
            <>
                <Typography variant={`body1`}>
                    {(equipment &&
                        this.displayText('youWillReplaceEquipment')) ||
                        (part && this.displayText('youWillReplacePart'))}
                </Typography>
                <div className={classes.table}>
                    <div className={classes.headerTable}>
                        <Typography className={classes.tableTitle}>
                            {this.displayText('model')}
                        </Typography>
                        <Typography className={classes.tableTitle}>
                            {this.displayText('maker')}
                        </Typography>
                        <Typography className={classes.tableTitle}>
                            {this.displayText('serialNumber')}
                        </Typography>
                    </div>
                    <div className={classes.scrollableBody}>
                        {replace.map((event, index) =>
                            this.renderLine(event, index),
                        )}
                    </div>
                </div>
                <BoatOnModal
                    openCondition={this.state.openModal}
                    handleClose={this.handleCloseModal}
                    modalCores={{
                        addStock: (
                            <InventoryModal
                                handleCloseAdd={this.added}
                                event={this.state.event}
                                onlyDetails
                            />
                        ),
                    }}
                    titles={{
                        addStock: this.displayText('inventaire'),
                    }}
                />
            </>
        )
    }

    render() {
        const { title, equipment, part } = this.props
        const { eventSelected } = this.state

        return (
            <>
                {this._renderTitle(title)}
                {this._renderBody({
                    body: this._body(),
                })}
                {this._renderActions([
                    {
                        label:
                            (equipment &&
                                this.displayText('addNewEquipment')) ||
                            (part && this.displayText('addNewPart')),
                        action: this.addStock,
                    },
                    {
                        label:
                            (equipment &&
                                this.displayText('replaceEquipment')) ||
                            (part && this.displayText('replacePart')),
                        action: this.replace,
                        disabled: eventSelected ? false : true,
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    return { boat: state.bob.boat }
}

export default connect(mapStateToProps)(
    withStyles(styles)(InventoryRelacementModal),
)
