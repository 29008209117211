import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Breadcrumbs, Link } from '@material-ui/core'
import breadcrumbNameMap from '../../languages/AppBreadcrumb'
import Typography from '@material-ui/core/Typography'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import { useIntl } from 'react-intl'
import HomeIcon from '@material-ui/icons/Home'

const BoatonBreadcrumbs = ({ parameters, props }) => {
    const { pathname } = useLocation()
    const { locale, messages } = useIntl()
    const useStyles = makeStyles(theme => ({
        link: {
            display: 'flex',
        },
        icon: {
            marginRight: theme.spacing(0.5),
            width: 20,
            height: 20,
        },
    }))
    const classes = useStyles()

    if (parameters)
        var parametersValue = Object.keys(parameters).map(k => parameters[k])

    var pathnames = pathname
        .split('/')
        .filter(x => x !== 'fr' && x !== 'en')
        .filter(x => x)

    var breadcrumbsSchema = {
        '@context': 'https://schema.org/',
        '@type': 'BreadcrumbList',
        itemListElement: [],
    }

    /*
      https://github.com/mui-org/material-ui/issues/18676#issuecomment-562844166
    */
    return (
        <Breadcrumbs aria-label="breadcrumb" {...props}>
            <Link
                color="inherit"
                href={'/' + locale + '/'}
                className={classes.link}
            >
                <HomeIcon className={classes.icon} />
                {breadcrumbNameMap[locale]['routes.root']}
            </Link>
            {breadcrumbsSchema['itemListElement'].push({
                '@type': 'ListItem',
                position: 1,
                name: 'Home',
                item: 'https://www.boaton.fr/' + locale + '/',
            })}
            {pathnames.map((value, index) => {
                const last = index === pathnames.length - 1
                const page = pathnames.slice(0, index + 1).join('/')
                const name = `/${page}`
                const to = '/' + locale + name
                var routeKey = getRouteKey(to)
                var text = routeKey
                    ? breadcrumbNameMap[locale][routeKey]
                    : (parametersValue && parametersValue.shift()) || ''

                {
                    breadcrumbsSchema['itemListElement'].push({
                        '@type': 'ListItem',
                        position: index + 2,
                        name: page,
                        item: 'https://www.boaton.fr' + to,
                    })
                }
                return last ? (
                    <Typography color="textPrimary" key={index}>
                        {text}
                    </Typography>
                ) : (
                    <Link color="inherit" href={to} key={index}>
                        {text}
                    </Link>
                )
            })}
            <Helmet
                script={[
                    {
                        type: 'application/ld+json',
                        innerHTML: `
                        ${JSON.stringify(breadcrumbsSchema)}`,
                    },
                ]}
            ></Helmet>
        </Breadcrumbs>
    )

    function getRouteKey(pathname) {
        if (!pathname) return
        let [, route] = pathname.split('/' + locale)
        let routeKey = Object.keys(messages).find(key => {
            return messages[key] === route
        })
        return routeKey
    }
}

export default BoatonBreadcrumbs
