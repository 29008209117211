import { allParkingsConstants } from '../../constants'
import { requestApi } from '../../services/api.service'

const getAllParkings = ({
    minPrice = null,
    maxPrice = null,
    parkingOptionTypes = null,
    minHeight = null,
    minWidth = null,
    minLength = null,
    startDate = null,
    endDate = null,
}) => {
    return dispatch => {
        dispatch({
            type: allParkingsConstants.REQUEST_PARKINGS,
        })

        let query = `?`

        if (minPrice) query += `minPrice=${minPrice}`
        if (maxPrice)
            query +=
                query === `?` ? `maxPrice=${maxPrice}` : `&maxPrice=${maxPrice}`
        if (minHeight)
            query +=
                query === `?`
                    ? `minHeight=${minHeight}`
                    : `&minHeight=${minHeight}`
        if (minWidth)
            query +=
                query === `?` ? `minWidth=${minWidth}` : `&minWidth=${minWidth}`
        if (minLength)
            query +=
                query === `?`
                    ? `minLength=${minLength}`
                    : `&minLength=${minLength}`
        if (startDate)
            query +=
                query === `?`
                    ? `startDate=${startDate}`
                    : `&startDate=${startDate}`
        if (endDate)
            query +=
                query === `?` ? `endDate=${endDate}` : `&endDate=${endDate}`
        if (parkingOptionTypes)
            query +=
                query === `?`
                    ? `parkingOptionTypes=${JSON.stringify(parkingOptionTypes)}`
                    : `&parkingOptionTypes=${JSON.stringify(
                          parkingOptionTypes,
                      )}`

        requestApi(`/parkings${query !== `?` ? query : ``}`, 'GET').then(
            result => {
                dispatch({
                    type: allParkingsConstants.REQUEST_PARKINGS_SUCCESS,
                    parkings: result,
                })
            },
            error => {
                dispatch({
                    type: allParkingsConstants.REQUEST_PARKINGS_FAILURE,
                    error: error,
                })
            },
        )
    }
}

const getPersonalParkings = () => {
    return dispatch => {
        dispatch({
            type: allParkingsConstants.REQUEST_PERSONAL_PARKINGS,
        })

        requestApi(`/parkings/user`, 'GET').then(
            result => {
                return dispatch({
                    type:
                        allParkingsConstants.REQUEST_PERSONAL_PARKINGS_SUCCESS,
                    personalParkings: result,
                })
            },
            error => {
                dispatch({
                    type:
                        allParkingsConstants.REQUEST_PERSONAL_PARKINGS_FAILURE,
                    error: error,
                })
            },
        )
    }
}

const getReservedParkings = userId => {
    return dispatch => {
        dispatch({
            type: allParkingsConstants.REQUEST_RESERVED_PARKINGS,
        })

        if (!userId) {
            dispatch({
                type: allParkingsConstants.REQUEST_RESERVED_PARKINGS_FAILURE,
                error: 'userId null',
            })
        }

        requestApi(`//TODO: TO BE SET`, 'GET').then(
            result => {
                dispatch({
                    type:
                        allParkingsConstants.REQUEST_RESERVED_PARKINGS_SUCCESS,
                    reservedParkings: result,
                })
            },
            error => {
                dispatch({
                    type:
                        allParkingsConstants.REQUEST_RESERVED_PARKINGS_FAILURE,
                    error: error,
                })
            },
        )
    }
}

export const allParkingsActions = {
    getAllParkings,
    getPersonalParkings,
    getReservedParkings,
}
