export default {
    comeBack: {
        FR: "< Revenir à mes demandes d'assurances",
        EN: '< Go back to my insurance applications',
    },
    yourRequest: {
        FR: 'Votre demande',
        EN: 'Your request',
    },
    yourOffers: {
        FR: 'Vos offres',
        EN: 'Your offers',
    },
    congrats: {
        FR: "Bravo: vous êtes sur le point d'économiser ",
        EN: 'Congratulations: you are about to save ',
    },
    congratsEnd: {
        FR: 'Bravo: vous avez économisé ',
        EN: 'Congratulations: you just saved ',
    },
    letsGo: {
        FR: 'A vous de jouer !',
        EN: "Now it's up to you!",
    },
    wp: {
        FR: 'Bien joué !',
        EN: 'Well done !',
    },
    moreInfos: {
        FR: "+ D'infos",
        EN: '+ Info',
    },
    receive: {
        FR: 'Reçue',
        EN: 'Receive',
    },
    accepted: {
        FR: 'Accepté',
        EN: 'Accepted',
    },
    declined: {
        FR: 'Décliné',
        EN: 'Declined',
    },
    expertOffer: {
        FR:
            'Voilà les offres que nos experts ont sélectionné pour vous, à vous de jouer !',
        EN:
            "These are the offers that our experts have selected for you, it's up to you!",
    },
}
