export default theme => ({
    inquery: {
        border: '1px solid #c4c4c4',
        borderRadius: '5px',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        padding: '20px',
        width: 'calc(100% - 40px)',
        marginBottom: '25px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: '12px',
            width: 'calc(100% - 24px)',
        },
    },
    info: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    line: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    firstPart: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '50%',
        minWidth: '350px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: '100%',
        },
    },
    titleFont: {
        fontSize: '22px',
        fontWeight: '400',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
            fontWeight: '500',
        },
    },
    secondPart: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '50%',
        minWidth: '350px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: '100%',
        },
    },
    buttonBox: {
        minWidth: '150px',
        width: '150px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            minWidth: '25px',
            width: '25px',
        },
    },
    allElement: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        alignItems: 'center',
        flexWrap: 'wrap',
        [theme.breakpoints.down('sm')]: {
            fontSize: '7px',
            marginBottom: '10px',
            height: '100%',
        },
    },
    button: {
        margin: 'auto',
        fontSize: '14px',
        position: 'absolute',
        bottom: '50%',
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    chevron: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            width: '50px',
            height: '100px',
            margin: '-10px',
        },
    },
    textTitle: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginRight: '10px',
    },
})
