import { userService } from '../services/user.service'
import { API_URL } from './config.service'

// en attente de la nouvelle table des bateaux

/*const requestListBoatModel = () => {
    return fetch('/types', {
        method: 'GET',
        headers: userService.getAuthHeader(),
        body: JSON.stringify({}),
    })
        .then(response => {
            return response.json()
        })
        .catch(error => {
            console.error('cannot GET the list of boat')
        })
}*/

const requestBoatTypes = () => {
    return fetch(API_URL + '/types/boat', {
        method: 'GET',
        headers: userService.getWithoutAuthHeader(),
    })
        .then(async result => {
            const json = await result.json()
            if (json.statusCode === 401) {
                return Promise.reject(json.message)
            }
            return json
        })
        .catch(error => {
            console.error('cannot GET the list of boats types')
            return Promise.reject(error)
        })
}

export const typesService = {
    requestBoatTypes,
    //requestListBoatModel,
}
