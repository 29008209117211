export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
    },
    title: {
        fontSize: 14,
    },
    flexContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
    },

    equipmentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 15,
    },
    equipmentIcon: {
        height: 24,
        width: 24,
    },
    equipmentDetails: {
        flex: 1,
    },
    equipmentLabel: {
        fontSize: 16,
    },

    bigTitle: {
        fontSize: 14,
        fontWeight: 600,
        marginBottom: 5,
    },

    documentContainer: {
        width: '100%',
        border: 'solid 1px rgba(0, 0, 0, 0.2)',
        borderRadius: 5,
        height: 70,
        borderStyle: 'dashed',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    AddDocIcon: {
        color: 'grey',
        height: 70,
        width: 70,
        opacity: 0.5,
    },

    //Filling Levels
    title: {
        fontSize: 14,
    },

    stockContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 15,
    },
    stockVerticalContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    stockRowContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
        gap: 15,
    },
    stockColContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    stockInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
    },
    stockIconContainer: {
        height: 35,
        width: 35,
        backgroundColor: theme.palette.primary.main,
        borderRadius: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    stockIcon: {
        height: 25,
        width: 25,
        filter: 'invert(1)',
    },
    stockInput: {
        width: '60%',
    },
    stockInfo: {
        fontSize: 14,
        fontWeight: 200,
        color: 'grey',
    },

    //arrival date
    dateTimeSection: {
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
    },
    inputDate: {
        flex: 1,
    },
    inputTime: {
        flex: 1,
        fontSize: '14px !important',
        '& .MuiInputLabel-root': {
            fontSize: '14px !important',
        },
        '& .MuiInputBase-input': {
            fontSize: '14px !important',
        },
    },

    noCapacity: {
        '&:hover': {
            cursor: 'help',
        },
    },
})
