import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import MenuList from '@material-ui/core/MenuList'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import IconButton from '@material-ui/core/IconButton'
import Drawer from '@material-ui/core/Drawer'
import Typography from '@material-ui/core/Typography'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import dictionary from './Dictionary/ProfileMenuDico'
import styles from './Styles/ProfileMenuCss'
import { history } from '../../helpers'
import BoatOnComponent from '../common/BoatOnComponent'
import AppRoute from '../../constants/AppRoute'

class ProfileMenu extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            mobileOpen: false,
            charged: false,
        }

        this.handleDrawerToggle = this.handleDrawerToggle.bind(this)
    }

    handleChange(value, key) {
        this.setState({ [key]: value })
    }

    handleDrawerToggle() {
        this.setState({ mobileOpen: !this.state.mobileOpen })
    }

    drawer() {
        const { parkingMenu, parkingInqueries, user } = this.props
        const parkings = parkingMenu || []
        return (
            <MenuList>
                <ProfileMenuTab
                    parent={this}
                    appRoute={AppRoute.PersonnalInfos}
                    onClick={() =>
                        this.historyPush(history, AppRoute.PersonnalInfos)
                    }
                />
                <ProfileMenuTab
                    parent={this}
                    appRoute={AppRoute.BoatInfos}
                    onClick={() =>
                        this.historyPush(history, AppRoute.BoatInfos)
                    }
                />
                {parkings.length !== 0 ||
                user?.stripeCustomerId ||
                user?.stripeAccountId ? (
                    <ProfileMenuTab
                        parent={this}
                        appRoute={AppRoute.PaymentInfos}
                        onClick={() =>
                            this.historyPush(history, AppRoute.PaymentInfos)
                        }
                    />
                ) : null}
                {parkings.length > 0 ? (
                    <ProfileMenuTab
                        parent={this}
                        appRoute={AppRoute.ParkingInfos}
                        onClick={() =>
                            this.historyPush(history, AppRoute.ParkingInfos)
                        }
                    />
                ) : null}
                <ProfileMenuTab
                    parent={this}
                    appRoute={AppRoute.Inqueries}
                    onClick={() =>
                        this.historyPush(history, AppRoute.Inqueries)
                    }
                />
            </MenuList>
        )
    }

    getTextForRoute(route) {
        switch (route) {
            case AppRoute.PersonnalInfos:
                return 'personnalInfos'
            case AppRoute.PaymentInfos:
                return 'paymentInfos'
            case AppRoute.BoatInfos:
                return 'boatInfos'
            case AppRoute.ParkingInfos:
                return 'parkingsInfos'
            case AppRoute.Inqueries:
                return 'inqueries'
            case AppRoute.InsuranceDetails:
                return 'inqueries'
            case AppRoute.ChatBox:
                return 'inqueries'
            case AppRoute.InsuranceInqueries:
                return 'inqueries'
            case AppRoute.InqueriesParking:
                return 'inqueries'
            case AppRoute.Messages:
                return 'messages'
            default:
                console.error('not find')
        }
        return null
    }

    render() {
        const { classes, menu, user, loadingParking } = this.props
        const { mobileOpen } = this.state

        if (!user && loadingParking > 0) return <></>
        return (
            <div className={classes.root}>
                <div className={classes.desktop}>{this.drawer()}</div>
                <div className={classes.mobile}>
                    <IconButton
                        color="inherit"
                        aria-label="Open drawer"
                        edge="start"
                        onClick={this.handleDrawerToggle}
                        className={classes.menuButton}
                    >
                        <Typography className={classes.menuTitle}>
                            {this.displayText(this.getTextForRoute(menu))}
                        </Typography>
                        <KeyboardArrowDown />
                    </IconButton>
                    <Drawer
                        variant="temporary"
                        anchor="top"
                        open={mobileOpen}
                        onClose={this.handleDrawerToggle}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {this.drawer()}
                    </Drawer>
                </div>
            </div>
        )
    }
}

function ProfileMenuTab({ parent, appRoute, onClick }) {
    const { classes, menu } = parent.props
    const isTab = new RegExp('/?' + appRoute + '.*|' + appRoute).test(menu)
    const text = parent.getTextForRoute(appRoute)

    return (
        <MenuItem
            className={isTab ? classes.menuItemSelected : classes.menuItem}
            onClick={onClick}
        >
            <ListItemText
                classes={isTab ? { primary: classes.text } : {}}
                primary={parent.displayText(text)}
            />
        </MenuItem>
    )
}

function mapStateToProps(state) {
    return {
        parkingMenu: state.allParkings.personalParkings,
        user: state.authentication.user,
        loadingParking: state.allParkings.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ProfileMenu))
