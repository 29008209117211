export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        '@media (max-width: 500px)': {
            width: '100%',
        },
    },
    title: {
        fontWeight: 500,
    },
    moreEventsLink: {
        borderRadius: '15px !important',
        border: 'none !important',
        width: 28,
        height: 28,
        display: 'flex !important',
        flexDirection: 'column-reverse',
        overflow: 'visible !important',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 2,
    },
    dot: {
        width: 5,
        height: 5,
        aspectRatio: 1,
        borderRadius: 3,
    },
    dayMarker: {
        borderRadius: 20,
        overflow: 'hidden',
        aspectRatio: 1,
        width: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: 3,
        '&.today': {
            color: 'white',
            backgroundColor: '#2a3892',
        },
    },
    actionMenu: {
        // minWidth: 150,
    },
    actionMenuItem: {
        padding: '0px 10px !important',
        display: 'flex',
        gap: 7,
    },
    moreLink: {
        fontWeight: 500,
    },
    calendarContainer: {
        '& .fc-scroller-harness': {
            maxHeight: '75vh',
            overflowY: 'auto',
            scrollbarWidth: 'none',
        },
        '& .fc-scroller-harness::-webkit-scrollbar': {
            width: 0,
        },
    },
})
