import React from 'react'
import BoatOnModalCore from './BoatOnModalCore'

import { Alert } from '@mui/material'
import { Info as InfoIcon } from '@mui/icons-material/'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/BoatOnConfirmationModalCss'
import dictionary from './Dictionary/BoatOnConfirmationModalDico'

/**
 * Used to create a modal of confirmation
 *
 * @class BoatOnConfirmationModal
 * @extends {BoatOnModalCore}
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {String} title - Title of the modal.
 * @param {onClose} onClose - Function that close the modal call if canceled.
 * @param {callback} callback - Function that make the action needed call if valided.
 * @param {Override} override - object to override the component.
 * @param {String} message - message to display in the modal
 *
 *
 * @region ```
 * Callbacks
 * ```
 *
 * @callback onClose
 *
 * @callback callback
 *
 * @region ```
 * Objects
 * ```
 *
 * @typedef {Object} Override
 * @property {string} return - text for cancel button.
 * @property {string} continue - text for continue button.
 * @property {string} continueButtonClass - class to apply on continue button.
 * @property {Node} icon - icon of the alert.
 * @property {string} alertClass - class to apply on Alert.
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 29/08/2024 - 14:30
 * @author Samuel.C
 */

class BoatOnConfirmationModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    _body() {
        const { classes, message, override } = this.props

        return (
            <Alert
                variant="outlined"
                severity={'info'}
                icon={
                    override?.icon !== undefined ? (
                        override.icon
                    ) : (
                        <InfoIcon className={classes.alertIcon} />
                    )
                }
                className={
                    override?.alertClass ? override.alertClass : classes.alert
                }
            >
                {message}
            </Alert>
        )
    }

    render() {
        const {
            title,
            noCross = true,
            showFollowButton,
            onClose,
            callback,
            override,
            message,
        } = this.props

        if (!onClose)
            console.error('BoatOnConfirmationModal: onClose cannot be empty')
        if (!callback)
            console.error('BoatOnConfirmationModal: callback cannot be empty')
        if (!message)
            console.error('BoatOnConfirmationModal: message cannot be empty')

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    null,
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    body: this._body(),
                    hasDividers: false,
                })}
                {this._renderActions([
                    {
                        action: onClose,
                        label: override?.return
                            ? override.return
                            : this.displayText('return'),
                        type: 'text',
                    },
                    {
                        action: callback,
                        label: override?.continue
                            ? override.continue
                            : this.displayText('continue'),
                        className: override?.continueButtonClass
                            ? override.continueButtonClass
                            : undefined,
                        type: 'primary',
                    },
                ])}
            </>
        )
    }
}

export default withStyles(styles)(BoatOnConfirmationModal)
