export default {
    overdue: {
        FR: 'tâche en retard',
        EN: 'overdue task',
    },
    overduePl: {
        FR: 'tâches en retard',
        EN: 'overdue tasks',
    },
    maintenance: {
        FR: 'tâche dans les 3 prochains mois',
        EN: 'task in the next 3 months',
    },
    maintenancePl: {
        FR: 'tâches dans les 3 prochains mois',
        EN: 'task in the next 3 months',
    },
    earnedIn: {
        FR: 'gagné en',
        EN: 'earned in',
    },
    spentOn: {
        FR: 'dépensé en',
        EN: 'spent on',
    },
    earnedInPl: {
        FR: 'gagnés en',
        EN: 'earned in',
    },
    spentOnPl: {
        FR: 'dépensés en',
        EN: 'spent on',
    },
    details: {
        FR: 'Détails',
        EN: 'Details',
    },
    notSet: {
        FR: 'Non défini',
        EN: 'Not set',
    },
    type1: {
        FR: `Taches validés`,
        EN: `Validated tasks`,
    },
    type2: {
        FR: `Taches à effectuer`,
        EN: `Tasks to be done`,
    },
    type3: {
        FR: `Taches en retard`,
        EN: `Tasks overdue`,
    },
    piece1: {
        FR: `Pièces en stock`,
        EN: `Pieces in stock`,
    },
    piece2: {
        FR: `Pièces en rupture de stock`,
        EN: `Pieces out of stock`,
    },
    filter1: {
        FR: `Nombre de tâches à effectuer`,
        EN: `Number of tasks to be done`,
    },
    filter2: {
        FR: `Nombre de tâches validées`,
        EN: `Number of validated tasks`,
    },
    filter3: {
        FR: `Nombre de tâches en retard`,
        EN: `Number of tasks overdue`,
    },
    filter4: {
        FR: `Gains`,
        EN: `Earnings`,
    },
    filter5: {
        FR: `Dépenses`,
        EN: `Expenses`,
    },
    filter6: {
        FR: `Nombre de pièces en stock`,
        EN: `Number of pieces in stock`,
    },
    filter7: {
        FR: `Nombre de pièces en rupture de stock`,
        EN: `Number of pieces out of stock`,
    },
    noBoat: {
        FR: `Vous n'avez pas enregistré de bateau`,
        EN: `You have not registered any boat`,
    },
    startDate: {
        FR: 'Du',
        EN: 'From',
    },
    endDate: {
        FR: 'Au',
        EN: 'All',
    },
    empty: {
        FR: 'Tout',
        EN: 'All',
    },
    dateEmpty: {
        FR: 'jj/mm/aaaa',
        EN: 'mm/dd/yyyy',
    },
    myFleet: {
        FR: 'Ma flotte',
        EN: 'My fleet',
    },
}
