import { filterConstants } from '../constants/filter.constants'

const initialState = {
    parkingFilter: {
        price: [-1, -1],
        width: [-1, -1],
        length: [-1, -1],
        height: [-1, -1],
        startDate: null,
        endDate: null,
        mapStatus: true,
        rowsPerPage: 20,
        page: 0,
        parkingIdHovered: null,
        address: null,
        mensualisation: false,
    },
    bobFilter: {
        searchString: '',
        rangeDate: { start: null, end: null },
        inStock: false,
        toRenew: false,
        missing: false,
        userEquipmentFamily: [],
        userEquipment: [],
        equipmentFamilyType: [],
        equipmentType: [],
        checkupTypesChecked: [],
        workEventTypesChecked: [],
        absenceStatesChecked: [],
        workTimeOptionsChecked: [],
        commissioningDate: { start: null, end: null },
        replacementDate: { start: null, end: null },
        rangeAmount: { min: null, max: null },
        rangeHour: { min: null, max: null },
        userAttachedUserRole: [],
        userAttachedRoleType: [],
        userManagerSearch: [],
        unregisteredManagerSearch: [],
        selectedBoat: [],
        selectedBoatByFleet: [],
        selectedBoatsByFleet: [],
        selectedCrew: [],
        orderStatusTypesChecked: [],
        orderOperatorChecked: [],
        equipmentPartsChecked: [],
        navigationStatusChecked: [],
    },
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case filterConstants.FILTER_ACTION:
            return {
                ...prevState,
                parkingFilter: { ...prevState.parkingFilter, ...action.filter },
            }
        case filterConstants.FILTER_BOB_UPDATE:
            if (!action.filter) return prevState

            return {
                ...prevState,
                bobFilter: {
                    searchString:
                        action.filter.searchString === null
                            ? prevState.bobFilter.searchString
                            : action.filter.searchString,
                    rangeDate:
                        action.filter.rangeDate === null
                            ? prevState.bobFilter.rangeDate
                            : action.filter.rangeDate,
                    commissioningDate:
                        action.filter.commissioningDate === null
                            ? prevState.bobFilter.commissioningDate
                            : action.filter.commissioningDate,
                    replacementDate:
                        action.filter.replacementDate === null
                            ? prevState.bobFilter.replacementDate
                            : action.filter.replacementDate,
                    inStock:
                        action.filter.inStock === null
                            ? prevState.bobFilter.inStock
                            : action.filter.inStock,
                    toRenew:
                        action.filter.toRenew === null
                            ? prevState.bobFilter.toRenew
                            : action.filter.toRenew,
                    missing:
                        action.filter.missing === null
                            ? prevState.bobFilter.missing
                            : action.filter.missing,
                    userEquipmentFamily:
                        action.filter.userEquipmentFamily === null
                            ? prevState.bobFilter.userEquipmentFamily
                            : action.filter.userEquipmentFamily,
                    userEquipment:
                        action.filter.userEquipment === null
                            ? prevState.bobFilter.userEquipment
                            : action.filter.userEquipment,
                    equipmentFamilyType:
                        action.filter.equipmentFamilyType === null
                            ? prevState.bobFilter.equipmentFamilyType
                            : action.filter.equipmentFamilyType,
                    equipmentType:
                        action.filter.equipmentType === null
                            ? prevState.bobFilter.equipmentType
                            : action.filter.equipmentType,
                    checkupTypesChecked:
                        action.filter.checkupTypesChecked === null
                            ? prevState.bobFilter.checkupTypesChecked
                            : action.filter.checkupTypesChecked,
                    rangeAmount:
                        action.filter.rangeAmount === null
                            ? prevState.bobFilter.rangeAmount
                            : action.filter.rangeAmount,
                    rangeHour:
                        action.filter.rangeHour === null
                            ? prevState.bobFilter.rangeHour
                            : action.filter.rangeHour,
                    userAttachedUserRole:
                        action.filter.userAttachedUserRole === null
                            ? prevState.bobFilter.userAttachedUserRole
                            : action.filter.userAttachedUserRole,
                    userAttachedRoleType:
                        action.filter.userAttachedRoleType === null
                            ? prevState.bobFilter.userAttachedRoleType
                            : action.filter.userAttachedRoleType,
                    userManagerSearch:
                        action.filter.userManagerSearch === null
                            ? prevState.bobFilter.userManagerSearch
                            : action.filter.userManagerSearch,
                    unregisteredManagerSearch:
                        action.filter.unregisteredManagerSearch === null
                            ? prevState.bobFilter.unregisteredManagerSearch
                            : action.filter.unregisteredManagerSearch,
                    workEventTypesChecked:
                        action.filter.workEventTypesChecked === null
                            ? prevState.bobFilter.workEventTypesChecked
                            : action.filter.workEventTypesChecked,
                    absenceStatesChecked:
                        action.filter.absenceStatesChecked === null
                            ? prevState.bobFilter.absenceStatesChecked
                            : action.filter.absenceStatesChecked,
                    workTimeOptionsChecked:
                        action.filter.workTimeOptionsChecked === null
                            ? prevState.bobFilter.workTimeOptionsChecked
                            : action.filter.workTimeOptionsChecked,
                    selectedBoat:
                        action.filter.selectedBoat === null
                            ? prevState.bobFilter.selectedBoat
                            : action.filter.selectedBoat,
                    selectedBoatByFleet:
                        action.filter.selectedBoatByFleet === null
                            ? prevState.bobFilter.selectedBoatByFleet
                            : action.filter.selectedBoatByFleet,
                    selectedBoatsByFleet:
                        action.filter.selectedBoatsByFleet === null
                            ? prevState.bobFilter.selectedBoatsByFleet
                            : action.filter.selectedBoatsByFleet,
                    selectedCrew:
                        action.filter.selectedCrew === null
                            ? prevState.bobFilter.selectedCrew
                            : action.filter.selectedCrew,
                    orderStatusTypesChecked:
                        action.filter.orderStatusTypesChecked === null
                            ? prevState.bobFilter.orderStatusTypesChecked
                            : action.filter.orderStatusTypesChecked,
                    orderOperatorChecked:
                        action.filter.orderOperatorChecked === null
                            ? prevState.bobFilter.orderOperatorChecked
                            : action.filter.orderOperatorChecked,
                    equipmentPartsChecked:
                        action.filter.equipmentPartsChecked === null
                            ? prevState.bobFilter.equipmentPartsChecked
                            : action.filter.equipmentPartsChecked,
                    navigationStatusChecked:
                        action.filter.navigationStatusChecked === null
                            ? prevState.bobFilter.navigationStatusChecked
                            : action.filter.navigationStatusChecked,
                },
            }
        case filterConstants.FILTER_BOB_RESET:
            return {
                ...prevState,
                bobFilter: initialState.bobFilter,
            }
        default:
            return prevState
    }
}
