export default {
    service: {
        FR: 'Nos services',
        EN: 'Our service',
    },
    fleet: {
        FR: `Flotte Perso`,
        EN: `My fleet`,
    },
    requests: {
        FR: 'Mes demandes',
        EN: 'Requests',
    },
    profile: {
        FR: 'Mon profil',
        EN: 'Profile',
    },
    lien: {
        FR: 'mon-profil',
        EN: 'my-profile',
    },
    pro: {
        FR: 'Profil',
        EN: 'Profile',
    },
    messages: {
        FR: 'Mes messages',
        EN: 'My messages',
    },
    login: {
        FR: 'Connexion',
        EN: 'Login',
    },
    register: {
        FR: 'Inscription',
        EN: 'Sign up',
    },
    rentParking: {
        FR: 'Louer mon emplacement',
        EN: 'Rent my place',
    },
    topBar: {
        FR: 'La 1ère application pour gérer votre bateau de A à Z',
        EN: 'The 1st application to manage your boat from A to Z',
    },
    stockage: {
        FR: 'BoatOn Stock',
        EN: 'BoatOn Stock',
    },
    assurance: {
        FR: 'BoatOn Assur',
        EN: 'BoatOn Assur',
    },
    bob: {
        FR: `BoatOn Book`,
        EN: `BoatOn Book`,
    },
    blog: {
        FR: 'Le blog',
        EN: 'Blog',
    },
    tarifs: {
        FR: `Tarifs`,
        EN: `Pricing`,
    },
    tarifsBook: {
        FR: 'Tarifs du BoatOn Book',
        EN: 'BoatOn Book pricing',
    },
    myAccount: {
        FR: 'Mon compte',
        EN: 'My account',
    },
    myBoat: {
        FR: 'Mon bateau',
        EN: 'My boat',
    },
    myBOB: {
        FR: 'Mon BoatOn Book',
        EN: 'My BoatOn Book',
    },
    myRequest: {
        FR: 'Mes demandes',
        EN: 'Requests',
    },
    myMessage: {
        FR: 'Mes messages',
        EN: 'Messages',
    },
    rentMyPlace: {
        FR: 'Louer mon emplacement',
        EN: 'Rent my place',
    },
    forum: {
        FR: 'Le forum',
        EN: 'Forum',
    },
    disconnect: {
        FR: 'Déconnexion',
        EN: 'Log out',
    },
    notification: {
        FR: "Vous n'avez pas de notification",
        EN: 'You do not have a notification',
    },
    contact: {
        FR: 'Nous contacter',
        EN: 'Contact us',
    },
}
