export default theme => ({
    container: {
        width: `100%`,
        borderRadius: `10px`,
        boxSizing: `border-box`,
        display: `flex`,
        marginBottom: 30,
        padding: `5px 10px`,
        alignItems: `center`,
        height: `fit-content`,
        border: `1px solid rgba(83, 150, 231, 0.7)`,
        background: `rgba(83, 150, 231, 0.1)`,
        fontSize: `16px`,
        [theme.breakpoints.down(`sm`)]: {
            fontSize: `14px`,
        },
    },
    infoIcon: {
        color: `#303F9F`,
        width: 40,
        height: 40,
        marginRight: 10,
        margin: `auto`,
        [theme.breakpoints.down(`sm`)]: {
            marginRight: 0,
        },
    },
    text: {
        color: `#303F9F`,
    },
    textWarning: {
        color: `#ff9800`,
    },
    link: {
        display: `inline`,
        fontWeight: `bold`,
        '&:hover': {
            cursor: `pointer`,
        },
        textDecoration: `underline`,
        color: `#303F9F`,
    },
    containerWarning: {
        width: `100%`,
        borderRadius: `10px`,
        boxSizing: `border-box`,
        display: `flex`,
        marginBottom: 30,
        padding: `10px 15px`,
        height: `fit-content`,
        background: `linear-gradient(0deg, rgba(249, 177, 89, 0.15), rgba(249, 177, 89, 0.15)), #FFFFFF`,
        fontSize: `16px`,
        border: `1px solid rgba(249, 177, 89, 0.4)`,
        alignItems: `flex-start`,
        [theme.breakpoints.down('sm')]: {
            flexDirection: `column`,
            alignItems: `center`,
            position: `relative`,
        },
    },
    message: {
        width: '100%',
    },
    warningMessage: {
        [theme.breakpoints.down('sm')]: {
            padding: '15px',
            boxSizing: `border-box`,
        },
    },
    icon: {
        height: '100%',
        alignItems: 'flex-start',
        boxSizing: `border-box`,
        paddingTop: 4,
    },
    action: {
        height: '100%',
        alignItems: 'flex-start',
        boxSizing: `border-box`,
    },
    warningAction: {
        [theme.breakpoints.down('sm')]: {
            order: -1,
            position: `absolute`,
            right: `20px`,
            top: `12px`,
        },
    },
    title: {
        fontSize: 18,
        color: 'black',
        fontWeight: 'bold',
    },
    warningTitle: {
        // different title because modal for warning is different for now
        fontSize: 18,
        color: 'black',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            textAlign: `center`,
            marginBottom: 15,
        },
    },
    root: {
        position: 'relative',
    },
    closeIcon: {
        position: 'absolute',
        top: 10,
        right: 10,
        '&:hover': {
            cursor: 'pointer',
        },
        opacity: 0.5,
    },
})
