export default {
    title: {
        FR: 'Paramètres des congés payés',
        EN: 'Paid leave settings',
    },
    startDatePeriodLabel: {
        FR: 'Début de la période de référence pour le calcul des congés payés',
        EN: 'Start of reference period for calculating paid leave',
    },
    paidVacationsByUser: {
        FR: 'Congés payés par utilisateur',
        EN: 'Paid vacations per user',
    },
    defaultParameters: {
        FR: 'Paramètres par défaut',
        EN: 'Default parameters',
    },
    user: {
        FR: 'Utilisateur',
        EN: 'User',
    },
    allowedVacations: {
        FR: 'Congés autorisés',
        EN: 'Allowed vacations',
    },
    used: {
        FR: 'Utilisés',
        EN: 'Used',
    },
    remainingVacations: {
        FR: 'Congés payés restants',
        EN: 'Remaining vacations',
    },
    download: {
        FR: 'Télécharger',
        EN: 'Download',
    },
    editAllowedVacations: {
        FR: 'Modifier le nombre de congés autorisés',
        EN: 'Edit the number of allowed days off',
    },
    labelModalAllowedDays: {
        FR:
            'Modifier le nombre de congés autorisés pour les utilisateurs sélectionnés',
        EN: 'Edit the number of allowed paid vacations for the selected users',
    },
    save: {
        FR: 'Enregistrer',
        EN: 'Save',
    },
    checkAllGroupMembers: {
        FR: 'Sélectionner tous les membres du groupe',
        EN: 'Select all the group members',
    },
    selectAll: {
        FR: 'Tout sélectionner',
        EN: 'Select all',
    },
    month: {
        FR: 'Mois',
        EN: 'Month',
    },
    day: {
        FR: 'Jour',
        EN: 'Day',
    },
    days: {
        FR: 'Jours',
        EN: 'Days',
    },
    pageLoadingLabel: {
        FR: 'Chargement des paramètres...',
        EN: 'Loading settings...',
    },
    alertModalMinimumAllowedDays: {
        FR: 'Vous ne pouvez pas définir un nombre de jours inférieur à 0',
        EN: 'You cannot set a number of days less than 0',
    },
    alertModalMinimumDaysAlreadyTaken: {
        FR:
            'Vous ne pouvez pas définir un nombre de jours inférieur au nombre de jours déjà pris',
        EN:
            'You cannot set a number of days less than the number of days already taken',
    },
    shortDay: {
        FR: 'j',
        EN: 'd',
    },
    searchFieldPlaceholder: {
        FR: 'Je recherche',
        EN: 'I search',
    },
    allowedDays: {
        FR: 'Jours autorisés',
        EN: 'Allowed days',
    },
    remaining: {
        FR: 'Restants',
        EN: 'Remaining',
    },
    currentPeriod: {
        FR: 'Période actuelle',
        EN: 'Current period',
    },
}
