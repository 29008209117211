import { requestApi } from '../../services/api.service'
import { trainingConstants } from '../../constants/trainings.constants'
import {
    postDataBOBDocuments,
    removeDataBOBDocuments,
} from './documents.actions'

const postTraining = (training, { newFiles, files, deletedFiles }) => {
    return async dispatch => {
        if (
            deletedFiles &&
            Array.isArray(deletedFiles) &&
            deletedFiles.length > 0
        ) {
            await removeDataBOBDocuments(deletedFiles)
        }

        if (newFiles) {
            training.files = [
                ...(await postDataBOBDocuments(newFiles, 1)),
                ...files,
            ]
        }

        dispatch({ type: trainingConstants.REQUEST_TRAINING })
        return requestApi(`/trainings`, `POST`, training)
            .then(
                result => {
                    if (!result || result.status === 404) return null

                    dispatch({
                        type: trainingConstants.POST_TRAINING_SUCCESS,
                        training: result,
                    })
                },
                error => {
                    console.error(error)
                    dispatch({ type: trainingConstants.POST_TRAINING_FAILURE })
                },
            )
            .catch(error => {
                console.error(error)
                dispatch({ type: trainingConstants.POST_TRAINING_FAILURE })
            })
    }
}

const updateTraining = (updatedTraining, { newFiles, files, deletedFiles }) => {
    return async dispatch => {
        if (
            deletedFiles &&
            Array.isArray(deletedFiles) &&
            deletedFiles.length > 0
        ) {
            await removeDataBOBDocuments(deletedFiles)
        }

        if (newFiles) {
            updatedTraining.files = [
                ...(await postDataBOBDocuments(newFiles, 1)),
                ...files,
            ]
        }

        dispatch({ type: trainingConstants.REQUEST_TRAINING })
        return requestApi(
            `/trainings/${updatedTraining.id}`,
            `PUT`,
            updatedTraining,
        )
            .then(
                result => {
                    if (!result || result.status === 404) return null

                    dispatch({
                        type: trainingConstants.PUT_TRAINING_SUCCESS,
                        training: result,
                    })
                },
                error => {
                    console.error(error)
                    dispatch({ type: trainingConstants.PUT_TRAINING_FAILURE })
                },
            )
            .catch(error => {
                console.error(error)
                dispatch({ type: trainingConstants.PUT_TRAINING_FAILURE })
            })
    }
}

const getAllTraining = (groupId, userId) => {
    return dispatch => {
        dispatch({ type: trainingConstants.REQUEST_TRAINING })

        return requestApi(`/trainings/group/${groupId}/user/${userId}`, `GET`)
            .then(
                result => {
                    if (!result || result.status === 404) return null

                    dispatch({
                        type: trainingConstants.GET_ALL_TRAINING_SUCCESS,
                        trainings: result,
                    })
                },
                error => {
                    console.error(error)
                    dispatch({
                        type: trainingConstants.GET_ALL_TRAINING_FAILURE,
                    })
                },
            )
            .catch(error => {
                console.error(error)
                dispatch({
                    type: trainingConstants.GET_ALL_TRAINING_FAILURE,
                })
            })
    }
}

const getAllTrainingByUser = (groupId, userId) => {
    return dispatch => {
        dispatch({ type: trainingConstants.REQUEST_TRAINING })

        return requestApi(`/trainings/group/${groupId}/user/${userId}`, `GET`)
            .then(
                result => {
                    if (!result || result.status === 404) return null

                    dispatch({
                        type: trainingConstants.GET_ALL_TRAINING_SUCCESS,
                        trainings: result,
                    })
                },
                error => {
                    console.error(error)
                    dispatch({
                        type: trainingConstants.GET_ALL_TRAINING_FAILURE,
                    })
                },
            )
            .catch(error => {
                console.error(error)
                dispatch({
                    type: trainingConstants.GET_ALL_TRAINING_FAILURE,
                })
            })
    }
}

const getAllTrainingByUserSubscribe = (groupId, userSubscribeId) => {
    return dispatch => {
        dispatch({ type: trainingConstants.REQUEST_TRAINING })

        return requestApi(
            `/trainings/group/${groupId}/userSubscribe/${userSubscribeId}`,
            `GET`,
        )
            .then(
                result => {
                    if (!result || result.status === 404) return null

                    dispatch({
                        type: trainingConstants.GET_ALL_TRAINING_SUCCESS,
                        trainings: result,
                    })
                },
                error => {
                    console.error(error)
                    dispatch({
                        type: trainingConstants.GET_ALL_TRAINING_FAILURE,
                    })
                },
            )
            .catch(error => {
                console.error(error)
                dispatch({
                    type: trainingConstants.GET_ALL_TRAINING_FAILURE,
                })
            })
    }
}

const getTrainingByUser = groupId => {
    return dispatch => {
        dispatch({ type: trainingConstants.REQUEST_TRAINING })

        return requestApi(`/trainings/group/${groupId}`, `GET`)
            .then(
                result => {
                    if (!result || result.status === 404) return null

                    dispatch({
                        type: trainingConstants.GET_ALL_TRAINING_SUCCESS,
                        trainings: result,
                    })
                },
                error => {
                    console.error(error)
                    dispatch({
                        type: trainingConstants.GET_ALL_TRAINING_FAILURE,
                    })
                },
            )
            .catch(error => {
                console.error(error)
                dispatch({
                    type: trainingConstants.GET_ALL_TRAINING_FAILURE,
                })
            })
    }
}

const deleteTraining = (trainingId, groupId) => {
    return dispatch => {
        dispatch({ type: trainingConstants.REQUEST_TRAINING })
        return requestApi(`/trainings/${trainingId}/group/${groupId}`, `DELETE`)
            .then(
                result => {
                    if (result?.status === 404) return null
                    dispatch({
                        type: trainingConstants.DELETE_TRAINING_SUCCESS,
                        trainingId: trainingId,
                    })
                },
                error => {
                    console.error(error)
                    dispatch({
                        type: trainingConstants.DELETE_TRAINING_FAILURE,
                    })
                },
            )
            .catch(error => {
                console.error(error)
                dispatch({
                    type: trainingConstants.DELETE_TRAINING_FAILURE,
                })
            })
    }
}

export const trainingActions = {
    postTraining,
    getAllTraining,
    getAllTrainingByUser,
    getAllTrainingByUserSubscribe,
    getTrainingByUser,
    deleteTraining,
    updateTraining,
}
