export default {
    mensuel: {
        FR: `Mensuel`,
        EN: `Monthly`,
        DE: `Monatlich`,
        BG: `Месечно`,
        HR: `Mjesečno`,
        DA: `Månedligt`,
        ES: `Mensual`,
        ET: `Kuine`,
        FI: `Kuukausittain`,
        EL: `Μηνιαίος`,
        HU: `Havi`,
        GA: `Míosúil`,
        IT: `Mensile`,
        LV: `Mēneša`,
        LT: `Mėnesinis`,
        MT: `Kull xahar`,
        NL: `Maandelijks`,
        PL: `Miesięcznie`,
        PT: `Mensal`,
        RO: `Lunar`,
        SK: `Mesačne`,
        SL: `Mesečno`,
        SV: `Månadsvis`,
        CS: `Měsíčně`,
        RU: `Ежемесячно`,
        IS: `Mánaðarlega`,
        JA: `毎月`,
        KO: `매월`,
        UK: `Щомісячно`,
        ZH: `每月`,
    },
    free: {
        FR: `GRATUIT`,
        EN: `FREE`,
        DE: `KOSTENLOS`,
        BG: `Безплатно`,
        HR: `BESPLATNO`,
        DA: `GRATIS`,
        ES: `GRATIS`,
        ET: `TASUTA`,
        FI: `ILMAISEKSI`,
        EL: `ΔΩΡΕΑΝ`,
        HU: `INGYENES`,
        GA: `SAOR IN AISCE`,
        IT: `GRATUITO`,
        LV: `BEZMAKSAS`,
        LT: `NEMOKAMAI`,
        MT: `BLA ĦLAS`,
        NL: `GRATIS`,
        PL: `ZA DARMO`,
        PT: `GRÁTIS`,
        RO: `GRATUIT`,
        SK: `ZADARMO`,
        SL: `BREZPLAČNO`,
        SV: `GRATIS`,
        CS: `ZDARMA`,
        RU: `БЕСПЛАТНО`,
        IS: `ÓKEYPIS`,
        JA: `無料`,
        KO: `무료`,
        UK: `Безкоштовно`,
        ZH: `免费`,
    },
    sansEngagement: {
        FR: `Sans engagement`,
        EN: `Without commitment`,
        DE: `Ohne Verpflichtung`,
        BG: `Без задължение`,
        HR: `Bez obveze`,
        DA: `Uden forpligtelse`,
        ES: `Sin compromiso`,
        ET: `Kohustusteta`,
        FI: `Sitoutumatta`,
        EL: `Χωρίς δέσμευση`,
        HU: `Kötelezettség nélkül`,
        GA: `Gan tiomantas`,
        IT: `Senza impegno`,
        LV: `Bez saistību`,
        LT: `Be įsipareigojimų`,
        MT: `B’xejn impenju`,
        NL: `Zonder verplichting`,
        PL: `Bez zobowiązań`,
        PT: `Sem compromisso`,
        RO: `Fără angajament`,
        SK: `Bez záväzkov`,
        SL: `Brez obveznosti`,
        SV: `Utan åtaganden`,
        CS: `Bez závazků`,
        RU: `Без обязательств`,
        IS: `Án skuldbindinga`,
        JA: `契約なし`,
        KO: `약정 없음`,
        UK: `Без зобов'язань`,
        ZH: `无约束力`,
    },
    annuel: {
        FR: `Annuel`,
        EN: `Annually`,
        DE: `Jährlich`,
        BG: `Годишно`,
        HR: `Godišnje`,
        DA: `Årligt`,
        ES: `Anual`,
        ET: `Aastas`,
        FI: `Vuotuinen`,
        EL: `Ετήσιος`,
        HU: `Évente`,
        GA: `Bliantúil`,
        IT: `Annuale`,
        LV: `Gada`,
        LT: `Metinis`,
        MT: `Annwali`,
        NL: `Jaarlijks`,
        PL: `Rocznie`,
        PT: `Anual`,
        RO: `Anual`,
        SK: `Ročne`,
        SL: `Letno`,
        SV: `Årligen`,
        CS: `Ročně`,
        RU: `Ежегодно`,
        IS: `Árlega`,
        JA: `毎年`,
        KO: `매년`,
        UK: `Щорічно`,
        ZH: `每年`,
    },
    eco: {
        FR: `Économisez 10%`,
        EN: `Save 10%`,
        DE: `Sparen Sie 10%`,
        BG: `Запазете 10%`,
        HR: `Uštedite 10%`,
        DA: `Spar 10%`,
        ES: `Ahorra un 10%`,
        ET: `Säästa 10%`,
        FI: `Säästä 10%`,
        EL: `Εξοικονομήστε 10%`,
        HU: `Mentse meg 10%-ot`,
        GA: `Sábháil 10%`,
        IT: `Risparmia il 10%`,
        LV: `Ietaupiet 10%`,
        LT: `Sutaupykite 10%`,
        MT: `Iċċaqal 10%`,
        NL: `Bespaar 10%`,
        PL: `Oszczędź 10%`,
        PT: `Poupe 10%`,
        RO: `Economisiți 10%`,
        SK: `Ušetrite 10%`,
        SL: `Prihranite 10%`,
        SV: `Spara 10%`,
        CS: `Ušetřete 10%`,
        RU: `Сэкономьте 10%`,
        IS: `Sparaðu 10%`,
        JA: `10%を節約`,
        KO: `10%를 절약하다`,
        UK: `Збережіть 10%`,
        ZH: `省下10%`,
    },
    try1: {
        FR: `Essayer maintenant`,
        EN: `Try now`,
        DE: `Jetzt ausprobieren`,
        BG: `Опитайте сега`,
        HR: `Isprobajte sada`,
        DA: `Prøv nu`,
        ES: `Prueba ahora`,
        ET: `Proovi nüüd`,
        FI: `Kokeile nyt`,
        EL: `Δοκιμάστε τώρα`,
        HU: `Próbálja ki most`,
        GA: `Triail anois`,
        IT: `Prova ora`,
        LV: `Mēģiniet tagad`,
        LT: `Išbandykite dabar`,
        MT: `Ipprova issa`,
        NL: `Probeer nu`,
        PL: `Wypróbuj teraz`,
        PT: `Experimente agora`,
        RO: `Încercați acum`,
        SK: `Vyskúšajte teraz`,
        SL: `Poskusite zdaj`,
        SV: `Prova nu`,
        CS: `Vyzkoušejte nyní`,
        RU: `Попробуйте сейчас`,
        IS: `Reyndu núna`,
        JA: `今すぐ試す`,
        KO: `지금 시도해보세요`,
        UK: `Спробуйте зараз`,
        ZH: `现在尝试`,
    },
    try2: {
        FR: `Essayer gratuitement`,
        EN: `Try for free`,
        DE: `Kostenlos ausprobieren`,
        BG: `Опитайте безплатно`,
        HR: `Isprobajte besplatno`,
        DA: `Prøv gratis`,
        ES: `Pruebe gratis`,
        ET: `Proovige tasuta`,
        FI: `Kokeile ilma maksutta`,
        EL: `Δοκιμάστε δωρεάν`,
        HU: `Próbálja ki ingyen`,
        GA: `Triail saor in aisce`,
        IT: `Prova gratuitamente`,
        LV: `Izmēģiniet bez maksas`,
        LT: `Išbandykite nemokamai`,
        MT: `Ipprova għall-biex`,
        NL: `Probeer gratis`,
        PL: `Wypróbuj za darmo`,
        PT: `Experimente gratuitamente`,
        RO: `Încercați gratuit`,
        SK: `Vyskúšajte zadarmo`,
        SL: `Preizkusite brezplačno`,
        SV: `Prova gratis`,
        CS: `Vyzkoušejte zdarma`,
        RU: `Попробуйте бесплатно`,
        IS: `Reyndu frítt`,
        JA: `無料で試す`,
        KO: `무료로 시도해보세요`,
        UK: `Спробуйте безкоштовно`,
        ZH: `免费试用`,
    },
    monthFree: {
        FR: `1 mois gratuit`,
        EN: `1 month free`,
        DE: `1 Monat kostenlos`,
        BG: `1 месец безплатно`,
        HR: `1 mjesec besplatno`,
        DA: `1 måned gratis`,
        ES: `1 mes gratis`,
        ET: `1 kuu tasuta`,
        FI: `1 kuukausi ilmaiseksi`,
        EL: `1 μήνας δωρεάν`,
        HU: `1 hónap ingyen`,
        GA: `1 mí saor in aisce`,
        IT: `1 mese gratuito`,
        LV: `1 mēnesis bez maksas`,
        LT: `1 mėnuo nemokamai`,
        MT: `1 xahar bla ħlas`,
        NL: `1 maand gratis`,
        PL: `1 miesiąc za darmo`,
        PT: `1 mês grátis`,
        RO: `1 lună gratuită`,
        SK: `1 mesiac zadarmo`,
        SL: `1 mesec brezplačno`,
        SV: `1 månad gratis`,
        CS: `1 měsíc zdarma`,
        RU: `1 месяц бесплатно`,
        IS: `1 mánuður frítt`,
        JA: `1ヶ月無料`,
        KO: `1개월 무료`,
        UK: `1 місяць безкоштовно`,
        ZH: `1个月免费`,
    },
    info: {
        FR: `ttc / mois / bateau`,
        EN: `VAT INCLUDED / month / boat`,
        DE: `inkl. MwSt. / Monat / Boot`,
        BG: `вкл. ДДС / месец / лодка`,
        HR: `PDV UKLJUČEN / mjesec / brod`,
        DA: `MOMS INKLUDERET / måned / båd`,
        ES: `IVA INCLUIDO / mes / barco`,
        ET: `KM sisaldab / kuu / paat`,
        FI: `SIS. ALV / kuukausi / vene`,
        EL: `ΦΠΑ ΣΥΜΠΕΡΙΛΑΜΒΑΝΕΤΑΙ / μήνας / σκάφος`,
        HU: `ÁFA TARTALMAZZA / hónap / hajó`,
        GA: `CTA SAOTHARNA / mí / bád`,
        IT: `IVA INCLUSA / mese / barca`,
        LV: `PVN IETVERTS / mēnesis / laiva`,
        LT: `PVM ĮSKAIČIUOTAS / mėnuo / valtis`,
        MT: `VAT INKLUDIT / xahar / dgħajsa`,
        NL: `BTW INBEGREPEN / maand / boot`,
        PL: `W CENIE / miesiąc / łódka`,
        PT: `IVA INCLUÍDO / mês / barco`,
        RO: `TVA INCLUS / lună / barcă`,
        SK: `DPH ZAHRNUTÁ / mesiac / loď`,
        SL: `DDV VKLJUČEN / mesec / čoln`,
        SV: `MOMS INGÅR / månad / båt`,
        CS: `DPH ZAHRNUTO / měsíc / loď`,
        RU: `НДС ВКЛЮЧЕН / месяц / лодка`,
        IS: `VSK INNIFALIN / mánuður / bátur`,
        JA: `税込 / 月 / ボート`,
        KO: `부가세 포함 / 월 / 보트`,
        UK: `ПДВ ВКЛЮЧЕНО / місяць / човен`,
        ZH: `含税 / 月 / 船`,
    },
    infoHT: {
        FR: `ht / mois / bateau`,
        EN: `excl. VAT / month / boat`,
        DE: `EXKL. MwSt. / Monat / Boot`,
        BG: `БЕЗ ДДС / месец / лодка`,
        HR: `BEZ PDV-a / mjesec / brod`,
        DA: `UDEN MOMS / måned / båd`,
        ES: `SIN IVA / mes / barco`,
        ET: `KM ETA / kuu / paat`,
        FI: `ILMAN ALV / kuukausi / vene`,
        EL: `ΧΩΡΙΣ ΦΠΑ / μήνας / σκάφος`,
        HU: `ÁFA NÉLKÜL / hónap / hajó`,
        GA: `GAN CTA / mí / bád`,
        IT: `SENZA IVA / mese / barca`,
        LV: `BEZ PVN / mēnesis / laiva`,
        LT: `BE PVM / mėnuo / valtis`,
        MT: `BLA VAT / xahar / dgħajsa`,
        NL: `ZONDER BTW / maand / boot`,
        PL: `BEZ VAT / miesiąc / łódka`,
        PT: `SEM IVA / mês / barco`,
        RO: `FĂRĂ TVA / lună / barcă`,
        SK: `BEZ DPH / mesiac / loď`,
        SL: `BREZ DDV / mesec / čoln`,
        SV: `UTAN MOMS / månad / båt`,
        CS: `BEZ DPH / měsíc / loď`,
        RU: `БЕЗ НДС / месяц / лодка`,
        IS: `ÁN VSK / mánuður / bátur`,
        JA: `税別 / 月 / ボート`,
        KO: `부가세 별도 / 월 / 보트`,
        UK: `БЕЗ ПДВ / місяць / човен`,
        ZH: `不含税 / 月 / 船`,
    },
    droit0: {
        FR: `Stockez tous vos documents`,
        EN: `Store all your documents`,
        DE: `Speichern Sie alle Ihre Dokumente`,
        BG: `Съхранявайте всичките си документи`,
        HR: `Pohranite sve svoje dokumente`,
        DA: `Gem alle dine dokumenter`,
        ES: `Almacene todos sus documentos`,
        ET: `Salvestage kõik oma dokumendid`,
        FI: `Säilytä kaikki asiakirjasi`,
        EL: `Αποθηκεύστε όλα τα έγγραφά σας`,
        HU: `Mentse el az összes dokumentumát`,
        GA: `Stóráil do chuid cáipéisí go léir`,
        IT: `Archivia tutti i tuoi documenti`,
        LV: `Saglabājiet visus savus dokumentus`,
        LT: `Saugokite visus savo dokumentus`,
        MT: `Ħażin kull dokument tiegħek`,
        NL: `Sla al uw documenten op`,
        PL: `Przechowuj wszystkie swoje dokumenty`,
        PT: `Armazene todos os seus documentos`,
        RO: `Stocați toate documentele dvs.`,
        SK: `Uložte všetky svoje dokumenty`,
        SL: `Shranite vse svoje dokumente`,
        SV: `Lagra alla dina dokument`,
        CS: `Uložte všechny své dokumenty`,
        RU: `Храните все свои документы`,
        IS: `Geymið allar skjöl ykkar`,
        JA: `すべてのドキュメントを保存`,
        KO: `모든 문서를 저장하세요`,
        UK: `Зберігайте всі свої документи`,
        ZH: `存储您的所有文件`,
    },
    droit1: {
        FR: `Suivez l’historique de chaque bateau`,
        EN: `Follow the history of each boat`,
        DE: `Verfolgen Sie die Geschichte jedes Bootes`,
        BG: `Следете историята на всяка лодка`,
        HR: `Pratite povijest svakog broda`,
        DA: `Følg historien om hver båd`,
        ES: `Siga la historia de cada barco`,
        ET: `Jälgige iga paadi ajalugu`,
        FI: `Seuraa jokaisen veneen historiaa`,
        EL: `Ακολουθήστε την ιστορία κάθε σκάφους`,
        HU: `Kövesse minden hajó történetét`,
        GA: `Lean ar aiséirí gach bád`,
        IT: `Segui la storia di ogni barca`,
        LV: `Seko katras laivas vēsturi`,
        LT: `Sekite kiekvieno laivo istoriją`,
        MT: `Segwi l-istorja ta 'kull dgħajsa`,
        NL: `Volg de geschiedenis van elke boot`,
        PL: `Śledź historię każdego statku`,
        PT: `Siga a história de cada barco`,
        RO: `Urmați istoria fiecărei bărci`,
        SK: `Sledujte históriu každej lode`,
        SL: `Sledite zgodovino vsakega čolna`,
        SV: `Följ historiken för varje båt`,
        CS: `Sledujte historii každé lodě`,
        RU: `Следите за историей каждой лодки`,
        IS: `Fylgið sögu hvers báts`,
        JA: `各船の履歴を追う`,
        KO: `각 보트의 역사를 따르세요`,
        UK: `Слідкуйте за історією кожного човна`,
        ZH: `跟随每艘船的历史`,
    },
    droit2: {
        FR: `Personnalisez et paramétrez vos tâches`,
        EN: `Customize and set up your tasks`,
        DE: `Passen Sie Ihre Aufgaben an und richten Sie sie ein`,
        BG: `Персонализирайте и настройте задачите си`,
        HR: `Prilagodite i postavite svoje zadatke`,
        DA: `Tilpas og konfigurer dine opgaver`,
        ES: `Personalice y configure sus tareas`,
        ET: `Kohandage ja seadke oma ülesanded`,
        FI: `Mukauta ja aseta tehtäväsi`,
        EL: `Προσαρμόστε και ρυθμίστε τις εργασίες σας`,
        HU: `Testreszabja és állítsa be a feladatait`,
        GA: `Saincheap agus cumraigh do chuid tascanna`,
        IT: `Personalizza e configura i tuoi compiti`,
        LV: `Pielāgojiet un iestatiet savas uzdevumus`,
        LT: `Priderinkite ir nustatykite savo užduotis`,
        MT: `Kustomizza u setta l-għażliet tiegħek`,
        NL: `Pas uw taken aan en stel ze in`,
        PL: `Dostosuj i skonfiguruj swoje zadania`,
        PT: `Personalize e configure suas tarefas`,
        RO: `Personalizați și configurați sarcinile dvs.`,
        SK: `Prispôsobte a nastavte svoje úlohy`,
        SL: `Prilagodite in nastavite svoje naloge`,
        SV: `Anpassa och konfigurera dina uppgifter`,
        CS: `Přizpůsobte a nastavte své úkoly`,
        RU: `Настройте и настройте свои задачи`,
        IS: `Sérsníðið og stillið verkefnin þín`,
        JA: `タスクをカスタマイズして設定します`,
        KO: `작업을 사용자 정의하고 설정하세요`,
        UK: `Налаштуйте та налаштуйте свої завдання`,
        ZH: `自定义并设置您的任务`,
    },
    droit3: {
        FR: `Gérez votre budget`,
        EN: `Manage your budget`,
        DE: `Verwalten Sie Ihr Budget`,
        BG: `Управлявайте бюджета си`,
        HR: `Upravljajte svojim proračunom`,
        DA: `Administrer din budget`,
        ES: `Administre su presupuesto`,
        ET: `Haldage oma eelarvet`,
        FI: `Hallitse budjettiasi`,
        EL: `Διαχειριστείτε τον προϋπολογισμό σας`,
        HU: `Kezelje a költségvetését`,
        GA: `Bainistigh do bhuiséad`,
        IT: `Gestisci il tuo budget`,
        LV: `Pārvaldiet savu budžetu`,
        LT: `Valdykite savo biudžetą`,
        MT: `Immaniġġja l-baġit tiegħek`,
        NL: `Beheer uw budget`,
        PL: `Zarządzaj swoim budżetem`,
        PT: `Gerencie seu orçamento`,
        RO: `Administrați-vă bugetul`,
        SK: `Spravujte svoj rozpočet`,
        SL: `Upravljajte svoj proračun`,
        SV: `Hantera din budget`,
        CS: `Spravujte svůj rozpočet`,
        RU: `Управляйте своим бюджетом`,
        IS: `Stjórnaðu fjármála þínum`,
        JA: `予算を管理する`,
        KO: `예산을 관리하세요`,
        UK: `Керуйте своїм бюджетом`,
        ZH: `管理您的预算`,
    },
    droit4: {
        FR: `Créez un journal de bord automatique`,
        EN: `Create an automatic logbook`,
        DE: `Erstellen Sie ein automatisches Logbuch`,
        BG: `Създайте автоматичен дневник`,
        HR: `Izradite automatski dnevnik`,
        DA: `Opret en automatisk logbog`,
        ES: `Cree un cuaderno de bitácora automático`,
        ET: `Looge automaatne logiraamat`,
        FI: `Luo automaattinen lokikirja`,
        EL: `Δημιουργήστε ένα αυτόματο ημερολόγιο`,
        HU: `Hozzon létre automatikus naplót`,
        GA: `Cruthaigh dialann uathoibríoch`,
        IT: `Crea un diario automatico`,
        LV: `Izveidojiet automātisku žurnālu`,
        LT: `Sukurkite automatinį žurnalą`,
        MT: `Oħloq ġurnal awtomatiku`,
        NL: `Maak een automatisch logboek`,
        PL: `Utwórz automatyczny dziennik`,
        PT: `Crie um diário automático`,
        RO: `Creați un jurnal automat`,
        SK: `Vytvorte automatický denník`,
        SL: `Ustvarite samodejni dnevnik`,
        SV: `Skapa en automatisk loggbok`,
        CS: `Vytvořte automatický deník`,
        RU: `Создайте автоматический журнал`,
        IS: `Búðu til sjálfvirka skráningarbók`,
        JA: `自動ログブックを作成する`,
        KO: `자동 로그북을 생성하세요`,
        UK: `Створіть автоматичний журнал`,
        ZH: `创建自动日志`,
    },
    droit5: {
        FR: `Ajoutez plusieurs bateaux`,
        EN: `Add multiple boats`,
        DE: `Fügen Sie mehrere Boote hinzu`,
        BG: `Добавете няколко лодки`,
        HR: `Dodajte više brodova`,
        DA: `Tilføj flere både`,
        ES: `Agregar varios barcos`,
        ET: `Lisage mitu paati`,
        FI: `Lisää useita veneitä`,
        EL: `Προσθέστε πολλά σκάφη`,
        HU: `Adj hozzá több hajót`,
        GA: `Cuir ghearráin éagsúla leis`,
        IT: `Aggiungi più barche`,
        LV: `Pievienojiet vairākas laivas`,
        LT: `Pridėkite kelis laivus`,
        MT: `Żidu dgħajjef multipli`,
        NL: `Voeg meerdere boten toe`,
        PL: `Dodaj wiele łodzi`,
        PT: `Adicione vários barcos`,
        RO: `Adăugați mai multe bărci`,
        SK: `Pridajte viacero lodí`,
        SL: `Dodajte več čolnov`,
        SV: `Lägg till flera båtar`,
        CS: `Přidejte více lodí`,
        RU: `Добавьте несколько лодок`,
        IS: `Bættu við mörgum bátum`,
        JA: `複数のボートを追加する`,
        KO: `여러 보트를 추가하세요`,
        UK: `Додайте кілька човнів`,
        ZH: `添加多艘船只`,
    },
    droit6: {
        FR: `Visualisez en 1 coup d’œil l’état de votre flotte`,
        EN: `See the status of your fleet at a glance`,
        DE: `Sehen Sie den Status Ihrer Flotte auf einen Blick`,
        BG: `Вижте състоянието на вашата флота с един поглед`,
        HR: `Pogledajte status vaše flote u trenu`,
        DA: `Se status for din flåde med et enkelt blik`,
        ES: `Vea el estado de su flota de un vistazo`,
        ET: `Vaadake oma laevastiku olekut ühe pilguga`,
        FI: `Näe laivastosi tila yhdellä silmäyksellä`,
        EL: `Δείτε την κατάσταση του στόλου σας με μια ματιά`,
        HU: `Lássa a flottája állapotát egy pillantásra`,
        GA: `Feic stádas do bhád i gcian amháin`,
        IT: `Visualizza lo stato della tua flotta in un colpo d'occhio`,
        LV: `Redziet savas flotes stāvokli vienā skatienā`,
        LT: `Vieno žvilgsnio metu pamatysite savo laivyno būseną`,
        MT: `Ara l-istatus tal-flokk tiegħek b'ħarsa waħda`,
        NL: `Bekijk de status van uw vloot in één oogopslag`,
        PL: `Zobacz stan swojej floty od razu`,
        PT: `Veja o estado da sua frota num piscar de olhos`,
        RO: `Vedeți starea flotei dvs. dintr-o privire`,
        SK: `Zobrazte stav vášho flotily na prvý pohľad`,
        SL: `Poglejte si stanje svoje flote na prvi pogled`,
        SV: `Se status för din flotta med en enda blick`,
        CS: `Podívejte se na stav vaší flotily na první pohled`,
        RU: `Увидеть состояние вашего флота с одного взгляда`,
        IS: `Sjáðu stöðu flotans þíns á einn augabragð`,
        JA: `一目でフロートの状態を見る`,
        KO: `한눈에 귀하의 함대 상태를 확인하세요`,
        UK: `Подивіться стан вашого флоту одним поглядом`,
        ZH: `一目了然地查看您的船队状态`,
    },
    droit7: {
        FR: `Ajoutez des utilisateurs & affectez des tâches`,
        EN: `Add users & assign tasks`,
        DE: `Benutzer hinzufügen und Aufgaben zuweisen`,
        BG: `Добавете потребители и задайте задачи`,
        HR: `Dodajte korisnike i dodijelite zadatke`,
        DA: `Tilføj brugere og tildel opgaver`,
        ES: `Agregar usuarios y asignar tareas`,
        ET: `Lisage kasutajaid ja määrake ülesandeid`,
        FI: `Lisää käyttäjiä ja anna tehtäviä`,
        EL: `Προσθέστε χρήστες και αναθέστε εργασίες`,
        HU: `Adjon hozzá felhasználókat és rendeljen hozzájuk feladatokat`,
        GA: `Cuir úsáideoirí agus tascanna iomparacha leis`,
        IT: `Aggiungi utenti e assegna loro compiti`,
        LV: `Pievienojiet lietotājus un piešķiriet uzdevumus`,
        LT: `Pridėkite vartotojus ir priskirkite jiems užduotis`,
        MT: `Żid utenti u issaħħu x-xogħlijiet`,
        NL: `Voeg gebruikers toe en wijs taken toe`,
        PL: `Dodaj użytkowników i przypisz im zadania`,
        PT: `Adicione usuários e atribua tarefas`,
        RO: `Adăugați utilizatori și atribuiți sarcini`,
        SK: `Pridajte používateľov a priraďte úlohy`,
        SL: `Dodajte uporabnike in dodelite naloge`,
        SV: `Lägg till användare och tilldela uppgifter`,
        CS: `Přidejte uživatele a přiřaďte úkoly`,
        RU: `Добавьте пользователей и назначьте задачи`,
        IS: `Bættu við notendum og úthlutaðu verkefnum þeim`,
        JA: `ユーザーを追加してタスクを割り当てます`,
        KO: `사용자를 추가하고 작업을 할당하세요`,
        UK: `Додайте користувачів та призначте їм завдання`,
        ZH: `添加用户并分配任务`,
    },
    droit8: {
        FR: `Synchronisez et gérez vos calendriers`,
        EN: `Synchronize and manage your calendars`,
        DE: `Synchronisieren und verwalten Sie Ihre Kalender`,
        BG: `Синхронизирайте и управлявайте календарите си`,
        HR: `Sinkronizirajte i upravljajte svojim kalendarima`,
        DA: `Synkroniser og administrer dine kalendere`,
        ES: `Sincronice y administre sus calendarios`,
        ET: `Sünkroonige ja haldage oma kalendreid`,
        FI: `Synkronoi ja hallinnoi kalentereitasi`,
        EL: `Συγχρονίστε και διαχειριστείτε τα ημερολόγιά σας`,
        HU: `Szinkronizálja és kezelje naptárait`,
        GA: `Sincronaigh agus bainistigh do chalaoirí`,
        IT: `Sincronizza e gestisci i tuoi calendari`,
        LV: `Sinhronizējiet un pārvaldiet savus kalendārus`,
        LT: `Sinchronizuokite ir valdykite savo kalendorius`,
        MT: `Sinsikronizza u ġħażel il-kaċċaturi tiegħek`,
        NL: `Synchroniseer en beheer uw agenda's`,
        PL: `Synchronizuj i zarządzaj swoimi kalendarzami`,
        PT: `Sincronize e gerencie seus calendários`,
        RO: `Sincronizați și gestionați calendarele dvs.`,
        SK: `Synchronizujte a spravujte svoje kalendáre`,
        SL: `Sinhronizirajte in upravljajte svoje koledarje`,
        SV: `Synkronisera och hantera dina kalendrar`,
        CS: `Synchronizujte a spravujte své kalendáře`,
        RU: `Синхронизируйте и управляйте своими календарями`,
        IS: `Samstilla og stjórna dagatalum þínum`,
        JA: `カレンダーを同期して管理します`,
        KO: `캘린더를 동기화하고 관리하세요`,
        UK: `Синхронізуйте та керуйте своїми календарями`,
        ZH: `同步和管理您的日历`,
    },
    droit9: {
        FR: `Accédez à vos données hors connexion`,
        EN: `Access your data offline`,
        DE: `Greifen Sie offline auf Ihre Daten zu`,
        BG: `Достъп до данните ви в офлайн режим`,
        HR: `Pristupite svojim podacima izvan mreže`,
        DA: `Få adgang til dine data offline`,
        ES: `Acceda a sus datos sin conexión`,
        ET: `Juurdepääs andmetele ilma internetiühenduseta`,
        FI: `Pääse tietoihisi offline-tilassa`,
        EL: `Αποκτήστε πρόσβαση στα δεδομένα σας χωρίς σύνδεση στο διαδίκτυο`,
        HU: `Hozzáférés az adataidhoz kapcsolat nélkül`,
        GA: `Seoladh le do shonraí as líne`,
        IT: `Accedi ai tuoi dati offline`,
        LV: `Piekļūt jūsu datiem bezsaistē`,
        LT: `Pasiekite savo duomenis be ryšio`,
        MT: `Aċċessjar id-data tiegħek offlajn`,
        NL: `Toegang tot uw gegevens offline`,
        PL: `Dostęp do danych w trybie offline`,
        PT: `Acesse seus dados offline`,
        RO: `Accesați datele dvs. fără conexiune`,
        SK: `Prístup k vašim údajom offline`,
        SL: `Dostop do vaših podatkov brez povezave`,
        SV: `Få åtkomst till dina data offline`,
        CS: `Přístup k vašim datům offline`,
        RU: `Доступ к вашим данным в автономном режиме`,
        IS: `Aðgangur að gögnum þínum án netþjónustu`,
        JA: `オフラインでデータにアクセスします`,
        KO: `오프라인에서 데이터에 액세스하세요`,
        UK: `Доступ до ваших даних в автономному режимі`,
        ZH: `脱机访问您的数据`,
    },
    droit10: {
        FR: `Optimisez votre stock de pièces `,
        EN: `Optimize your parts inventory `,
        DE: `Optimieren Sie Ihren Teilebestand `,
        BG: `Оптимизирайте инвентара си от части `,
        HR: `Optimizirajte svoj inventar dijelova `,
        DA: `Optimer din reservedelsbeholdning `,
        ES: `Optimice su inventario de piezas `,
        ET: `Optimeerige oma varuosade inventuuri `,
        FI: `Optimoi osavaru varastossa `,
        EL: `Βελτιστοποιήστε το απόθεμα των ανταλλακτικών σας `,
        HU: `Optimalizálja alkatrész-raktárát `,
        GA: `Feabhsaigh do stoc píosaí `,
        IT: ` Ottimizza il tuo inventario di pezzi `,
        LV: `Optimizējiet savu daļu krājumu `,
        LT: `Optimizuokite savo dalių inventorius `,
        MT: `Ottimizza l-inventarju tal-partijiet tiegħek `,
        NL: `Optimaliseer uw onderdelenvoorraad `,
        PL: `Optymalizuj swój magazyn części `,
        PT: `Otimize seu estoque de peças `,
        RO: `Optimizați stocul dvs. de piese `,
        SK: `Optimalizujte svoj sklad dielov `,
        SL: `Optimizirajte zaloge delov `,
        SV: `Optimera ditt delar lager `,
        CS: `Optimalizujte svůj sklad dílů `,
        RU: `Оптимизируйте запасы деталей `,
        IS: `Staðværið hluta hlutar þíns `,
        JA: `部品在庫を最適化する `,
        KO: `부품 재고를 최적화하세요 `,
        UK: `Оптимізуйте запаси деталей `,
        ZH: `优化零件库存 `,
    },
    droit11: {
        FR: `Analysez les statistiques de votre flotte`,
        EN: `Analyze your fleet statistics`,
        DE: `Analysieren Sie Ihre Flottenstatistiken`,
        BG: `Анализирайте статистиката на вашата флота`,
        HR: `Analizirajte statistiku svoje flote`,
        DA: `Analyser dine flådestatistikker`,
        ES: `Analice las estadísticas de su flota`,
        ET: `Analüüsige oma laevastiku statistikat`,
        FI: `Analysoi laivastosi tilastoja`,
        EL: `Αναλύστε τις στατιστικές του στόλου σας`,
        HU: `Elemezze flottastatisztikáit`,
        GA: `Anailís a dhearbhúchán do staitisticí do bhád `,
        IT: `Analizza le statistiche della tua flotta `,
        LV: `Analizējiet savas flotes statistiku `,
        LT: `Analizuokite savo laivyno statistiką `,
        MT: `Analizza l-istatistikiet tal-frota tiegħek `,
        NL: `Analyseer uw vlootstatistieken `,
        PL: `Analizuj statystyki swojej floty `,
        PT: `Analise as estatísticas da sua frota `,
        RO: `Analizați statisticile flotei dvs. `,
        SK: `Analyzujte štatistiky svojej flotily `,
        SL: `Analizirajte statistiko svoje flote `,
        SV: `Analysera din flottstatistik `,
        CS: `Analyzujte statistiky své flotily `,
        RU: `Анализируйте статистику вашего флота `,
        IS: `Greinaðu bátastatistíkku þína `,
        JA: `フリート統計を分析する `,
        KO: `함대 통계를 분석하세요 `,
        UK: `Аналізуйте статистику свого флоту `,
        ZH: `分析您的船队统计数据 `,
    },
    droit12: {
        FR: 'Passez vos commandes',
        EN: 'Place your orders',
        DE: 'Platzieren Sie Ihre Bestellungen',
        BG: 'Поставете вашите поръчки',
        HR: 'Postavite svoje narudžbe',
        DA: 'Placer dine ordrer',
        ES: 'Realice sus pedidos',
        ET: 'Esitage oma tellimused',
        FI: 'Tee tilauksesi',
        EL: 'Κάντε τις παραγγελίες σας',
        HU: 'Helyezze el rendeléseit',
        GA: 'Cuir do chuid orduithe',
        IT: 'Effettua i tuoi ordini',
        LV: 'Iesniedziet savas pasūtījumus',
        LT: 'Pateikite savo užsakymus',
        MT: 'Ippjana l-ordni tiegħek',
        NL: 'Plaats uw bestellingen',
        PL: 'Złóż zamówienia',
        PT: 'Faça seus pedidos',
        RO: 'Plasați comenzile',
        SK: 'Predložte svoje objednávky',
        SL: 'Oddajte svoja naročila',
        SV: 'Placera dina beställningar',
        CS: 'Dejte své objednávky',
        RU: 'Разместите свои заказы',
        IS: 'Settu inn pöntunina þín',
        JA: '注文をする',
        KO: '주문하세요',
        UK: 'Зробіть ваші замовлення',
        ZH: '下订单',
    },
    droit13: {
        FR: `Gérez les absences et le temps de travail de vos équipes`,
        EN: `Manage absences and working time for your teams`,
        DE: `Verwalten Sie Abwesenheiten und Arbeitszeit für Ihre Teams`,
        BG: `Управлявайте отсъствията и работното време на вашите екипи`,
        HR: `Upravljajte odsutnostima i radnim vremenom svojih timova`,
        DA: `Administrer fravær og arbejdstid for dine hold`,
        ES: `Administre las ausencias y el tiempo de trabajo de sus equipos`,
        ET: `Haldke ära puudumised ja tööaeg oma meeskondadele`,
        FI: `Hallitse poissaoloja ja työaikaa tiimeillesi`,
        EL: `Διαχειριστείτε τις απουσίες και τον χρόνο εργασίας των ομάδων σας`,
        HU: `Kezelje a hiányzásokat és a munkaidőt csapatai számára`,
        GA: `Bainistigh na heasnaimh agus an t-am oibre do do fhoireann`,
        IT: `Gestisci le assenze e il tempo di lavoro per le tue squadre`,
        LV: `Pārvaldiet neesošos un darba laiku savām komandām`,
        LT: `Valdykite nebuvimus ir darbo laiką savo komandoms`,
        MT: `Ippjanja u l-ħin taċ-ċavetta tal-ħaddiema tiegħek`,
        NL: `Beheer afwezigheid en werktijd voor uw teams`,
        PL: `Zarządzaj nieobecnościami i czasem pracy dla swoich zespołów`,
        PT: `Gerencie faltas e tempo de trabalho para suas equipes`,
        RO: `Gestionați absențele și timpul de lucru pentru echipele dvs.`,
        SK: `Spravujte absencie a pracovný čas svojich tímov`,
        SL: `Upravljajte odsotnostmi in delovnim časom svojih ekip`,
        SV: `Hantera frånvaro och arbetstid för dina lag`,
        CS: `Spravujte absence a pracovní dobu svých týmů`,
        RU: `Управляйте отсутствиями и рабочим временем своих команд`,
        IS: `Stjórnaðu vandræðum og vinnutíma liðsins þíns`,
        JA: `チームの欠勤と労働時間を管理します`,
        KO: `팀의 결근 및 근무 시간을 관리하세요`,
        UK: `Керуйте відсутністю та робочим часом для своїх команд`,
        ZH: `管理团队的缺勤和工作时间`,
    },
    startForFree: {
        FR: `Commencez gratuitement. Aucune carte de crédit n'est
        requise.`,
        EN: `Start for free. No credit card required.`,
        DE: `Starten Sie kostenlos. Keine Kreditkarte erforderlich.`,
        BG: `Започнете безплатно. Не е необходима кредитна карта.`,
        HR: `Započnite besplatno. Nije potrebna kreditna kartica.`,
        DA: `Start gratis. Ingen kreditkort påkrævet.`,
        ES: `Comience gratis. No se requiere tarjeta de crédito.`,
        ET: `Alustage tasuta. Krediitkaarti pole vaja.`,
        FI: `Aloita ilmaiseksi. Ei luottokorttia tarvita.`,
        EL: `Ξεκινήστε δωρεάν. Δεν απαιτείται πιστωτική κάρτα.`,
        HU: `Kezdje ingyen. Nincs szükség hitelkártyára.`,
        GA: `Tosaigh saor in aisce. Ní gá cárta creidmheasa.`,
        IT: `Inizia gratuitamente. Nessuna carta di credito richiesta.`,
        LV: `Sāciet bez maksas. Kredītkarte nav nepieciešama.`,
        LT: `Pradėkite nemokamai. Kredito kortelės nereikia.`,
        MT: `Ibda b'xejn. Ebda karta tal-kreditu meħtieġa.`,
        NL: `Begin gratis. Geen creditcard vereist.`,
        PL: `Zacznij za darmo. Nie wymagana karta kredytowa.`,
        PT: `Comece gratuitamente. Não é necessário cartão de crédito.`,
        RO: `Începeți gratuit. Nu este necesară nicio carte de credit.`,
        SK: `Začnite zadarmo. Kreditná karta nie je potrebná.`,
        SL: `Začnite brezplačno. Kreditna kartica ni potrebna.`,
        SV: `Börja gratis. Ingen kreditkort krävs.`,
        CS: `Začněte zdarma. Kreditní karta není vyžadována.`,
        RU: `Начните бесплатно. Кредитная карта не требуется.`,
        IS: `Byrjið ókeypis. Engin kreditkort er krafað.`,
        JA: `無料で始める。クレジットカードは必要ありません。`,
        KO: `무료로 시작하세요. 신용카드가 필요하지 않습니다.`,
        UK: `Почніть безкоштовно. Кредитна карта не потрібна.`,
        ZH: `免费开始。无需信用卡。`,
    },
}
