export const boatsConstants = {
    REQUEST_LINKED_BOATS: 'REQUEST_LINKED_BOATS',
    LINKED_BOATS_SUCCESS: 'LINKED_BOATS_SUCCESS',
    LINKED_BOATS_FAILURE: 'LINKED_BOATS_FAILURE',
    REQUEST_BOATS: 'REQUEST_BOATS',
    POST_BOATS: 'POST_BOATS',
    BOATS_SUCCESS: 'BOATS_SUCCESS',
    BOATS_FAILURE: 'BOATS_FAILURE',
    POST_BOATS_TO_GROUP: 'POST_BOATS_TO_GROUP',
    BOATS_TO_GROUP_SUCCESS: 'BOATS_TO_GROUP_SUCCESS',
    BOATS_TO_GROUP_FAILURE: 'BOATS_TO_GROUP_FAILURE',
    BOATS_UPDATE_SUCCESS: 'BOATS_UPDATE_SUCCESS',
    RESET_BOATS: `RESET_BOATS`,
    REQUEST_NB_BOATS: 'REQUEST_NB_BOATS',
    REQUEST_NB_BOATS_SUCCESS: 'REQUEST_NB_BOATS_SUCCESS',
    REQUEST_NB_BOATS_FAILURE: 'REQUEST_NB_BOATS_FAILURE',
    UPDATE_LINKED_BOAT_WITHOUT_LOADING: 'UPDATE_LINKED_BOAT_WITHOUT_LOADING',
}
