import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../../common/BoatOnModalCore'
import AddressSearch from '../../../../../common/UsefullComponents/AddressSearch'

import { Alert } from '@mui/material'
import { Info as InfoIcon } from '@mui/icons-material/'

import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/ShareModalCss'
import dictionary from './Dictionary/FillAddressAisModalDico'

class ShareModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            remplacementAddress: null,
            error: false,
        }

        this.onChangeAddress = this.onChangeAddress.bind(this)
    }

    valid() {
        const { remplacementAddress } = this.state

        if (!remplacementAddress) {
            this.setState({ error: true })
            return
        }

        this.props.onValid(remplacementAddress)
    }

    onChangeAddress(address) {
        this.setState({
            remplacementAddress: address,
        })
    }

    _renderBody() {
        const { classes } = this.props
        const { remplacementAddress, error } = this.state

        return (
            <div className={classes.root}>
                <Alert
                    variant="outlined"
                    severity="info"
                    className={classes.alert}
                    icon={<InfoIcon className={classes.alertIcon} />}
                >
                    {this.displayText('noData')}
                </Alert>
                <AddressSearch
                    required
                    label={this.displayText('address')}
                    variant="outlined"
                    margin="dense"
                    style={{ width: '100%' }}
                    setAddress={this.onChangeAddress}
                    fullAddressInfo={remplacementAddress}
                    error={error}
                />
            </div>
        )
    }

    render() {
        const { title, noCross = true, showFollowButton } = this.props

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    null,
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    body: this._renderBody(),
                })}
                {this._renderActions([
                    {
                        action: () => this.valid(),
                        label: this.displayText('valid'),
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    const isMobile = window.innerWidth < 600

    return {
        isMobile,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ShareModal))
