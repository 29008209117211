export default theme => ({
    phoneInput: Object.assign(
        {
            width: '100%',
            '& .MuiSelect-root': {
                height: '1.1876em !important',
            },
        },
        theme.flex.row,
    ),
})
