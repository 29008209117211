export default {
    next: {
        FR: `Suivant`,
        EN: `Next`,
    },
    validateTask: {
        FR: `Enregistrer`,
        EN: `Save`,
    },
    update: {
        FR: `Mettre à jour`,
        EN: `Update`,
    },
    essentialInfos: {
        FR: 'Informations essentielles',
        EN: 'Essential information',
    },
    startTime: {
        FR: 'Heure de début *', // required not working with the input used
        EN: 'Start time *',
    },
    endTime: {
        FR: 'Heure de fin *', // required not working with the input used
        EN: 'End time *',
    },
    addBreak: {
        FR: 'Ajouter une pause',
        EN: 'Add a break',
    },
    startTimeBreak: {
        FR: 'Début de la pause *', // required not working with the input used
        EN: 'Start of the break *',
    },
    endTimeBreak: {
        FR: 'Fin de la pause *', // required not working with the input used
        EN: 'End of the break *',
    },
    comment: {
        FR: 'Commentaires',
        EN: 'Comments',
    },
    commentTooltip: {
        FR: 'Commentaire tooltip',
        EN: 'Comment tooltip',
    },
    mandatoryDate: {
        FR: 'Vous devez sélectionner au moins une date',
        EN: 'You must select at least one date',
    },
    errorMandatoryUser: {
        FR: 'Vous devez sélectionner un utilisateur',
        EN: 'You must select a user',
    },
    activity: {
        FR: "Fil d'activité",
        EN: 'Activity feed',
    },
    user: {
        FR: 'Utilisateur',
        EN: 'User',
    },
    manager: {
        FR: 'Manager',
        EN: 'Manager',
    },
    errorMandatoryManager: {
        FR: 'Vous devez sélectionner un manager',
        EN: 'You must select a manager',
    },
    errorMandatoryUser: {
        FR: 'Vous devez sélectionner un utilisateur',
        EN: 'You must select a user',
    },
    errorStartTimeAfterEndTime: {
        FR: `L'heure de début doit être avant l'heure de fin`,
        EN: `Start time should be before end time`,
    },
    errorEndTimeBeforeStartTime: {
        FR: `L'heure de fin doit être après l'heure de début`,
        EN: `End time should be after start time`,
    },
    errorBreakTimeBeforeStartTime: {
        FR: `L'heure de début de la pause doit être après l'heure de début de journée`,
        EN: `Break start time must be after the work day start time`,
    },
    errorBreakTimeAfterEndTime: {
        FR: `L'heure de fin de la pause doit être avant l'heure de fin de la journée`,
        EN: `Break end time must be before the work day end time`,
    },
    errorEmptyField: {
        FR: `Ce champ ne peut pas être vide`,
        EN: `This field cannot be empty`,
    },
    errorTooMuchWork: {
        FR: `Le nombre d'heures effectuées est supérieur au nombre d'heures paramétrées`,
        EN: `The number of hours worked is greater than the number of hours setted in parameters`,
    },
    sameHours: {
        FR: `Appliquer les mêmes horaires partout`,
        EN: `Apply the same hours everywhere`,
    },
    missingInformations: {
        FR: 'Il y a des informations manquantes',
        EN: 'There is missing information',
    },
    userTooltip: {
        FR: `Identifiez ici la personne qui a effectué le temps de travail`,
        EN: `Identify here the person who performed the work time`,
    },
    managerTooltip: {
        FR: `Identifiez ici la personne qui doit contrôler ou approuver ce temps de travail`,
        EN: `Identify here the person who must control or approve this work time`,
    },
    noModification: {
        FR:
            'Vous ne pouvez pas modifier ce temps de travail. En revanche, vous pouvez le supprimer et en créer un nouveau.',
        EN:
            'You cannot edit this work time. However, you can delete it and create another one.',
    },
}
