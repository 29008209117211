export default {
    todayWorkTime: {
        FR: "Temps travaillé aujourd'hui",
        EN: 'Time worked today',
    },
    start: {
        FR: 'Démarrer',
        EN: 'Start',
    },
    timer: {
        FR: 'Timer',
        EN: 'Timer',
    },
    myWorkDay: {
        FR: 'Ma journée de travail du',
        EN: 'My work day',
    },
    stop: {
        FR: 'Stopper',
        EN: 'Stop',
    },
    finishDay: {
        FR: 'Finir ma journée',
        EN: 'End my day',
    },
    dayFinished: {
        FR: 'Journée terminée',
        EN: 'Day finished',
    },
    running: { FR: 'En cours', EN: 'Running' },
}
