import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../../common/BoatOnModalCore'
import BoatOnAutoComplete from '../../../../../common/BoatOnAutoComplete'
import DisplayerImgModal from '../../../../DisplayerImgModal'

import { Divider, TextField } from '@mui/material'
import {
    Help as HelpIcon,
    Edit as EditIcon,
    Place as PlaceIcon,
    AirlineSeatReclineNormal as SeatIcon,
    AddAPhoto as AddAPhotoIcon,
} from '@material-ui/icons'

import {
    getImageToDisplay,
    addFileToBobEvent,
    deleteFileFromBobEvent,
    editNavigation,
    postDataBOBDocuments,
    removeDataBOBDocuments,
} from '../../../../../../actions/bob.actions'

import { Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/EditNavigationModalCss'
import dictionary from './Dictionary/EditNavigationModalDico'
import BoatOnLoading from '../../../../../common/UsefullComponents/BoatOnLoading'

class EditNavigationModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            navigation: null,
            loadingImage: false,
            fileNoUrl: { navigation: [] },
            displayNewFiles: { navigation: [] },
            deletedFiles: { navigation: [] },
        }

        this.inputDocRef = React.createRef()

        this.handleCaptainChange = this.handleCaptainChange.bind(this)
        this.handleCrewChange = this.handleCrewChange.bind(this)
        this.getUserName = this.getUserName.bind(this)
        this.handleChangeComment = this.handleChangeComment.bind(this)
        this.deleteDocumentNoUp = this.deleteDocumentNoUp.bind(this)
        this.addDocument = this.addDocument.bind(this)
        this.save = this.save.bind(this)
        this.uploadNewImages = this.uploadNewImages.bind(this)
    }

    componentDidMount() {
        this.setState({
            navigation: this.props.navigation,
        })
    }

    async uploadNewImages() {
        const { fileNoUrl, deletedFiles, navigation } = this.state
        const files = await postDataBOBDocuments(fileNoUrl.navigation, 1)

        files.forEach(file => ({
            ...file,
            file_type_id: 1,
        }))

        removeDataBOBDocuments(deletedFiles.navigation)

        return [...navigation.files, ...files]
    }

    async save() {
        const { navigation } = this.state
        const { dispatch, onClose } = this.props

        navigation.files = await this.uploadNewImages()
        dispatch(editNavigation(navigation))

        onClose()
    }

    handleCaptainChange(_, value) {
        const { navigation } = this.state

        if (value.length > 1) value = [value[1]]

        if (value.length === 1) {
            const index = navigation.navigationMembers.findIndex(
                member => member.userLinkId === value[0].id,
            )

            if (index !== -1) navigation.navigationMembers.splice(index, 1)
        }

        navigation.captainLinkId = value ? value[0].id : null

        this.setState({ navigation })
    }

    handleCrewChange(_, value) {
        let { navigation } = this.state
        const oldNav = this.props.navigation

        navigation.navigationMembers = value.map(member => {
            const index = oldNav.navigationMembers.findIndex(
                oldMember => oldMember.userLinkId === member.id,
            )

            if (index !== -1) return oldNav.navigationMembers[index]

            return {
                userLinkId: member.id,
            }
        })

        this.setState({ navigation })
    }

    isAlreadySelected(opt, selectedOpt) {
        return selectedOpt?.some(
            elem =>
                (opt?.user?.id && opt?.user?.id === elem?.user?.id) ||
                (opt?.userSubscribe?.id &&
                    elem?.userSubscribe?.id === opt?.userSubscribe?.id),
        )
    }

    getUserName(elem) {
        const { linkedUsers } = this.props

        if (elem?.user?.id) {
            let name = ``

            linkedUsers.forEach(link => {
                if (link?.user?.id === elem?.user?.id)
                    name = `${link?.user?.firstName} ${link?.user?.lastName}`
            })

            return name
        } else if (elem?.userSubscribe?.id && elem?.userSubscribe?.mail)
            return elem?.userSubscribe?.mail
        return null
    }

    filterUsers(opt, input) {
        const firstLast = opt?.user?.id
            ? `${opt?.user?.firstName} ${opt?.user?.lastName}`
            : null
        const lastFirst = opt?.user?.id
            ? `${opt?.user?.lastName} ${opt?.user?.firstName}`
            : null

        if (
            !input?.inputValue ||
            (opt?.user?.id &&
                ((firstLast &&
                    firstLast.toLowerCase().includes(input.inputValue)) ||
                    (lastFirst &&
                        lastFirst.toLowerCase().includes(input.inputValue)))) ||
            (opt?.userSubscribe?.id &&
                opt?.userSubscribe?.mail
                    .toLowerCase()
                    .includes(input.inputValue.toLowerCase()))
        ) {
            return true
        }
        return false
    }

    isOptionEqualToValue(opt, value) {
        if (
            (value?.userSubscribe?.id &&
                value?.userSubscribe?.id === opt?.userSubscribe?.id) ||
            (value?.user?.id && value?.user?.id === opt?.user?.id)
        ) {
            return true
        }
        return false
    }

    displayHours(time) {
        const datetime = new Date(time)
        const h = datetime.getHours()
        const m = datetime.getMinutes()

        return `${h}h${m < 10 ? '0' : ''}${m}`
    }

    handleChangeComment(e) {
        const { navigation } = this.state

        navigation.comment = e.target.value

        this.setState({ navigation })
    }

    deleteDocumentNoUp(i) {
        const {
            deletedFiles,
            displayNewFiles,
            fileNoUrl,
            navigation,
        } = this.state

        deleteFileFromBobEvent(this, i, {
            deletedFiles: deletedFiles.navigation,
            displayNewFiles: displayNewFiles.navigation,
            fileNoUrl: fileNoUrl.navigation,
            files: navigation.files,
            name: 'navigation',
        })
    }

    addDocument(event) {
        const { fileNoUrl, displayNewFiles } = this.state

        if (
            event.target?.files &&
            [...event.target.files].find(file =>
                file.name.toLowerCase().includes('.heic'),
            )
        ) {
            // So we display a loading icon during the time of heic conversion
            this.setState({ loadingImage: true }, async () => {
                await addFileToBobEvent(this, event, {
                    fileNoUrl: fileNoUrl.event,
                    displayNewFiles: displayNewFiles.event,
                    name: 'navigation',
                })
                this.setState({ loadingImage: false })
            })
        } else {
            addFileToBobEvent(this, event, {
                fileNoUrl: fileNoUrl.event,
                displayNewFiles: displayNewFiles.event,
                name: 'navigation',
            })
        }
    }

    _renderCrew() {
        const { classes, linkedUsers } = this.props
        const { navigation, error } = this.state

        const selectedCaptain = linkedUsers.filter(
            opt => navigation.captainLinkId === opt.id,
        )
        const selectedCrew = linkedUsers.filter(user => {
            const navigationMembers = navigation.navigationMembers.map(
                user => user.userLinkId,
            )

            return navigationMembers.includes(user.id)
        })

        return (
            <div>
                <div className={classes.sectionTitle}>
                    {this.displayText('crewMembers')}
                </div>
                <BoatOnAutoComplete
                    required
                    error={error && selectedCaptain.length === 0}
                    autoComplete
                    disableClearable
                    multiple
                    renderTagCondition={option =>
                        !option.user && option.userSubscribe
                    }
                    getOptionSelected={this.isOptionEqualToValue}
                    margin="dense"
                    value={selectedCaptain}
                    conditionAlready={opt =>
                        this.isAlreadySelected(opt, selectedCaptain)
                    }
                    options={linkedUsers}
                    label={
                        <>
                            {this.displayText('captain')}
                            <Tooltip
                                title={this.displayText('helpCaptain')}
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <HelpIcon className={classes.helpIcon} />
                            </Tooltip>
                        </>
                    }
                    onChange={this.handleCaptainChange}
                    getElementToShow={this.getUserName}
                    getName={this.getUserName}
                    filterOptions={this.filterUsers}
                />
                <BoatOnAutoComplete
                    required
                    autoComplete
                    disableClearable
                    renderTagCondition={option =>
                        !option.user && option.userSubscribe
                    }
                    getOptionSelected={this.isOptionEqualToValue}
                    margin="dense"
                    multiple
                    value={selectedCrew}
                    conditionAlready={opt =>
                        this.isAlreadySelected(opt, [
                            ...selectedCaptain,
                            ...selectedCrew,
                        ])
                    }
                    options={linkedUsers}
                    label={
                        <>
                            {this.displayText('crewInput')}
                            <Tooltip
                                title={this.displayText('helpCrew')}
                                classes={{ tooltip: classes.tooltip }}
                            >
                                <HelpIcon className={classes.helpIcon} />
                            </Tooltip>
                        </>
                    }
                    onChange={this.handleCrewChange}
                    getElementToShow={this.getUserName}
                    getName={this.getUserName}
                    filterOptions={this.filterUsers}
                />
            </div>
        )
    }

    _renderTravelSteps() {
        const { classes, changeModal } = this.props
        const { navigation } = this.state

        let passengers = navigation.nbDepartingPassengers

        const stopoversOrdered =
            navigation.stopOvers.sort(
                (a, b) => a.delimitedDate.startDate - b.delimitedDate.endDate,
            ) || []

        let _renderSteps = stopoversOrdered.map((stop, index) => {
            passengers += stop.nbBoardingPassengers
            passengers -= stop.nbLeavingPassengers

            return (
                <div
                    className={classes.travelRowContainer}
                    key={`stepover-${index}`}
                >
                    <div className={classes.travelVerticalContainer}>
                        <div className={classes.stepTime}>
                            {this.displayHours(stop.delimitedDate.startDate)} -{' '}
                            {this.displayHours(stop.delimitedDate.endDate)}
                        </div>
                        <div className={classes.travelStepTitle}>
                            <div className={classes.travelStepName}>
                                {this.displayText('stopover')}
                            </div>
                            <PlaceIcon className={classes.placeIcon} />
                            <div className={classes.travelPlace}>
                                {stop.address?.fullText?.split(',')[0] || ''}
                            </div>
                        </div>
                    </div>
                    <div className={classes.seatContainer}>
                        <SeatIcon className={classes.seatIcon} />
                        {passengers}
                    </div>
                </div>
            )
        })

        return (
            <div>
                <div
                    className={`${classes.titleRowContainer} ${classes.marginTitle}`}
                >
                    <div className={classes.sectionTitle}>
                        {this.displayText('travel')}
                    </div>
                    <EditIcon
                        className={classes.editIcon}
                        onClick={() => changeModal('editTravel')}
                    />
                </div>
                <div className={classes.travelsStepsContainer}>
                    <div className={classes.travelRowContainer}>
                        <div className={classes.travelVerticalContainer}>
                            <div className={classes.stepTime}>
                                {this.displayHours(
                                    navigation.delimitedDate.startDate,
                                )}
                            </div>
                            <div className={classes.travelStepTitle}>
                                <div className={classes.travelStepName}>
                                    {this.displayText('start')}
                                </div>
                                <PlaceIcon className={classes.placeIcon} />
                                <div className={classes.travelPlace}>
                                    {navigation.departureAddress?.fullText?.split(
                                        ',',
                                    )[0] || ''}
                                </div>
                            </div>
                        </div>
                        <div className={classes.seatContainer}>
                            <SeatIcon className={classes.seatIcon} />
                            {navigation.nbDepartingPassengers}
                        </div>
                    </div>
                    {_renderSteps}
                    <div className={classes.travelRowContainer}>
                        <div className={classes.travelVerticalContainer}>
                            <div className={classes.stepTime}>
                                {this.displayHours(
                                    navigation.delimitedDate.endDate,
                                )}
                            </div>
                            <div className={classes.travelStepTitle}>
                                <div className={classes.travelStepName}>
                                    {this.displayText('end')}
                                </div>
                                <PlaceIcon className={classes.placeIcon} />
                                <div className={classes.travelPlace}>
                                    {navigation.arrivalAddress?.fullText?.split(
                                        ',',
                                    )[0] || ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    _renderJugment() {
        const { classes } = this.props
        const { navigation } = this.state

        return (
            <TextField
                multiline
                label={this.displayText('captainJudgment')}
                className={classes.captainJudgment}
                variant="outlined"
                margin="dense"
                fullWidth
                minRows={3}
                onChange={this.handleChangeComment}
                value={navigation.comment}
            />
        )
    }

    _renderPhoto() {
        const { classes } = this.props
        const { displayNewFiles, loadingImage, navigation } = this.state

        return (
            <>
                <div className={classes.sectionTitle}>
                    {this.displayText('photodocs')}
                </div>
                <div
                    className={classes.documentContainer}
                    onClick={() => this.inputDocRef?.current?.click()}
                >
                    <AddAPhotoIcon className={classes.AddDocIcon} />
                </div>
                <input
                    onChange={this.addDocument}
                    type="file"
                    id="file"
                    accept="image/*"
                    ref={this.inputDocRef}
                    style={{ display: 'none' }}
                    multiple
                    required
                />

                <DisplayerImgModal
                    file={getImageToDisplay(
                        navigation,
                        displayNewFiles.navigation,
                    )}
                    deleteDocumentNoUp={this.deleteDocumentNoUp}
                    loadingImage={loadingImage}
                />
            </>
        )
    }

    _renderBody() {
        const { classes, loading } = this.props
        const { navigation } = this.state

        if (loading > 0 || !navigation) return <BoatOnLoading />

        return (
            <div className={classes.root}>
                {this._renderCrew()}
                <Divider className={classes.divider} />
                {this._renderTravelSteps()}
                <Divider className={classes.divider} />
                {this._renderJugment()}
                {this._renderPhoto()}
            </div>
        )
    }

    render() {
        const { title, noCross = true, showFollowButton, onClose } = this.props

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    null,
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    body: this._renderBody(),
                })}
                {this._renderActions([
                    {
                        action: onClose,
                        type: 'text',
                        label: this.displayText('cancel'),
                    },
                    {
                        action: this.save,
                        label: this.displayText('save'),
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const id = url.split('/').slice(-1)[0]
    const navigation = state.logbook?.navigations?.find(
        nav => nav.id === parseInt(id),
    )

    return {
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
        boat: state.bob.boat,
        loading: state.logbook.loading,
        navigation,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(EditNavigationModal))
