export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #c4c4c4',
        padding: '10px',
        margin: 'auto',
        marginTop: '10px',
        width: 'calc(100% - 20px)',
        alignItems: 'center',
    },
    textAndField: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: `center`,
        margin: '8px',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    text: {
        width: '25%',
        margin: 'auto',
        textAlign: 'right',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            width: '100%',
        },
    },
    field: {
        width: '70%',
        margin: 'auto',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    fieldPhone: {
        display: 'flex',
        width: '70%',
        margin: 'auto',
        fontSize: '16px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        '& .MuiSelect-root': {
            height: '1.1876em !important',
        },
    },
    changeButton: {
        width: '50%',
        margin: '20px',
    },
    password: {
        width: '70%',
        marginRight: '15px',
        textAlign: 'right',
        cursor: 'pointer',
        color: '#3040a0',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
})
