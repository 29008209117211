export default theme => ({
    header: {
        position: `relative`,
        height: 486,
        display: `flex`,
        alignItems: `center`,
        width: `100%`,
        flexDirection: `column`,
        [theme.breakpoints.down('sm')]: {
            height: `350px`,
        },
    },
    container: {
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        width: `100%`,
        flexDirection: `column`,
        overflow: `hidden`,
        paddingTop: 40,
    },
    title: {
        lineHeight: `40px`,
        textAlign: `center`,
        fontSize: `40px`,
        color: `#303F9F`,
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            marginTop: 50,
        },
    },
    subtitle: {
        width: 350,
        marginTop: 20,
        lineHeight: 1.5,
        textAlign: `center`,
        fontSize: `22px`,
        '& sup': {
            fontSize: `14px`,
            marginRight: 4,
        },
    },
    tabs: {
        display: `flex`,
        border: `2px solid #3040A0`,
        borderRadius: `100px`,
        padding: 2,
        color: `black`,
        marginTop: 40,
        marginBottom: 20,
        zIndex: 1,
        background: `white`,
    },
    part: {
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        flexWrap: `wrap`,
        '& img': {
            margin: `10px 30px`,
            height: 50,
        },
    },
    cards: {
        width: `100%`,
        display: `flex`,
        flexWrap: `wrap`,
        alignItems: `center`,
        justifyContent: `center`,
        '& > div': {
            margin: `0 7px`,
        },
        marginTop: -160,
        [theme.breakpoints.down('sm')]: {
            '& > div': {
                margin: 0,
                paddingTop: 25,
                paddingBottom: 25,
            },
            marginTop: 0,
        },
    },
    tab: {
        '&:hover': {
            cursor: `pointer`,
        },
        transition: `0.2s`,
        background: `#ffffff`,
        textAlign: `center`,
        borderRadius: `100px`,
        padding: 14,
    },
    active: {
        color: `white`,
        background: `#303F9F`,
    },
    vector: {
        width: `100%`,
        position: `absolute`,
        left: 0,
        bottom: 0,
        maxHeight: 190,
        minHeight: 160,
    },
    vector1: {
        bottom: 47,
        [theme.breakpoints.down('sm')]: {
            display: `none`,
        },
    },
    vector2: {
        [theme.breakpoints.down('sm')]: {
            display: `none`,
        },
    },
    vector3: {
        minHeight: 0,
        [theme.breakpoints.down('sm')]: {
            display: `none`,
        },
    },
    vector4: {
        minHeight: 0,
        [theme.breakpoints.down('sm')]: {
            display: `none`,
        },
    },
    vector5: {
        display: `none`,
        [theme.breakpoints.down('sm')]: {
            display: `inline-block`,
            bottom: -50,
        },
    },
    vector6: {
        display: `none`,
        [theme.breakpoints.down('sm')]: {
            display: `inline-block`,
            bottom: -125,
        },
    },
    vector7: {
        display: `none`,
        [theme.breakpoints.down('sm')]: {
            display: `inline-block`,
            bottom: -100,
        },
    },
    moreFonc: {
        '&:hover': {
            cursor: `pointer`,
        },
        fontSize: `16px`,
        color: `#3040A0`,
        marginTop: 24,
        marginBottom: 15,
        textAlign: `center`,
    },
    specificFonc: {
        textAlign: `center`,
        fontSize: `16px`,
        color: `rgba(0, 0, 0, 0.5)`,
    },
    specificFonc1: {
        textAlign: `center`,
        fontSize: `16px`,
        color: `rgba(0, 0, 0, 0.5)`,
        marginBottom: 50,
    },
    contactUs: {
        textAlign: `center`,
        '&:hover': {
            cursor: `pointer`,
        },
        color: `#3040A0`,
        textDecoration: `underline`,
        marginBottom: 48,
    },
    confidence: {
        textAlign: `center`,
        fontWeight: 700,
        fontSize: `32px`,
        marginBottom: 52,
    },
    reviews: {
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        '& > div': {
            margin: `0 12px`,
        },
        margin: `52px 0`,
        [theme.breakpoints.down('sm')]: {
            width: `100%`,
            padding: `20px 0`,
            overflowX: `auto`,
        },
    },
})
