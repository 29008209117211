export default {
    youWillReplaceEquipment: {
        FR: `Vous êtes sur le point de remplacer cet équipement! Choisissez dans la liste l'équipement que vous avez mis en service ou créez-en un nouveau.`,
        EN: `You are about to replace this equipment! Choose from the list the equipment you have commissioned or create a new one.`,
    },
    youWillReplacePart: {
        FR: `Vous êtes sur le point de remplacer cette pièce! Choisissez dans la liste la pièce que vous avez mis en service ou créez-en une nouvelle.`,
        EN: `You are about to replace this part! Choose from the list the part you have commissioned or create a new one.`,
    },
    replaceEquipment: {
        FR: `Remplacer par cet équipement`,
        EN: `Replace with this equipment`,
    },
    replacePart: {
        FR: `Remplacer par cette pièce`,
        EN: `Replace with this part`,
    },
    addNewEquipment: {
        FR: `Ajouter un nouvel équipement`,
        EN: `Add a new equipment`,
    },
    addNewPart: {
        FR: `Ajouter une nouvelle pièce`,
        EN: `Add a new part`,
    },
    serialNumber: {
        FR: 'Numéro de série',
        EN: 'Serial number',
    },
    model: {
        FR: `Modèle`,
        EN: `Model`,
    },
    maker: {
        FR: `Marque`,
        EN: `Maker`,
    },
}
