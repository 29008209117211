export default {
    validate: {
        FR: 'Envoyer',
        EN: 'Send',
    },
    enjoyBoating: {
        FR: 'Profitez de votre bateau, nous nous occupons du reste',
        EN: 'Enjoy your boat, we take care of the rest',
    },
    email: {
        FR:
            'Un email avec votre nouveau mot de passe vous a été envoyé. Vérifiez votre dossier spams si vous ne le voyez pas',
        EN: `An email  with your new password has been sent to you. Check your spams folder if you don't see it`,
    },
}
