import { requestApi } from '../../services/api.service'
import { navigationApiDataConstants } from '../../constants/navigationApiData.constants'

export const switchNavigation = navigationId => {
    return dispatch => {
        dispatch({
            type: navigationApiDataConstants.SWITCH_NAVIGATION,
            navigationId,
        })
        dispatch(getWeatherByNavigation(navigationId))
        dispatch(getAisByNavigation(navigationId))
    }
}

const getWeatherByNavigation = navigationId => {
    return dispatch => {
        dispatch({
            type: navigationApiDataConstants.REQUEST_NAVIGATION_WEATHER,
        })
        requestApi(`/weather-datas/navigation/${navigationId}`, 'GET').then(
            result => {
                dispatch({
                    type:
                        navigationApiDataConstants.REQUEST_NAVIGATION_WEATHER_SUCCESS,
                    weatherArray: result,
                })
            },
            error => {
                dispatch({
                    type:
                        navigationApiDataConstants.REQUEST_NAVIGATION_WEATHER_ERROR,
                })
                console.error(error)
            },
        )
    }
}

export const getWeatherByCoordinates = (lat, lng) => {
    return dispatch => {
        dispatch({
            type: navigationApiDataConstants.REQUEST_CURRENT_WEATHER,
        })
        requestApi(`/weather-datas/lat/${lat}/lng/${lng}`, 'GET').then(
            result => {
                dispatch({
                    type:
                        navigationApiDataConstants.REQUEST_CURRENT_WEATHER_SUCCESS,
                    currentWeather: result,
                })
            },
            error => {
                dispatch({
                    type:
                        navigationApiDataConstants.REQUEST_CURRENT_WEATHER_ERROR,
                })
                console.error(error)
            },
        )
    }
}

export const getAisByNavigation = navigationId => {
    return dispatch => {
        dispatch({
            type: navigationApiDataConstants.REQUEST_NAVIGATION_AIS,
        })
        requestApi(`/navigations/${parseInt(navigationId)}/ais`, 'GET').then(
            result => {
                dispatch({
                    type:
                        navigationApiDataConstants.REQUEST_NAVIGATION_AIS_SUCCESS,
                    ais: result?.data,
                    navigationId: parseInt(navigationId),
                })
            },
            error => {
                dispatch({
                    type:
                        navigationApiDataConstants.REQUEST_NAVIGATION_AIS_ERROR,
                })
                console.error(error)
            },
        )
    }
}

export const getLastAisData = mmsi => {
    return dispatch => {
        dispatch({
            type: navigationApiDataConstants.REQUEST_NAVIGATION_AIS,
        })
        requestApi(`/navigations/${parseInt(mmsi)}/lastAis`, 'GET').then(
            result => {
                dispatch({
                    type:
                        navigationApiDataConstants.REQUEST_NAVIGATION_LAST_AIS_SUCCESS,
                    lastAis: result,
                    mmsi: mmsi,
                })
            },
            error => {
                dispatch({
                    type:
                        navigationApiDataConstants.REQUEST_NAVIGATION_AIS_ERROR,
                })
                console.error(error)
            },
        )
    }
}

export const getAisBoatsFromGroupId = (groupId, duration) => {
    return dispatch => {
        dispatch({
            type: navigationApiDataConstants.REQUEST_NAVIGATION_AIS,
        })

        requestApi(
            `/navigations/${groupId}/ais/userGroup${
                duration ? `?duration=${duration}` : ''
            }`,
            'GET',
        ).then(
            result => {
                dispatch({
                    type:
                        navigationApiDataConstants.REQUEST_NAVIGATION_AIS_BY_GROUP,
                    aisByGroup: result,
                })
            },
            error => {
                dispatch({
                    type:
                        navigationApiDataConstants.REQUEST_NAVIGATION_AIS_ERROR,
                })
                console.error(error)
            },
        )
    }
}
