import { parkingConstants } from '../../constants'
import { allParkingsConstants } from '../../constants'
import { requestApi } from '../../services/api.service'
import { duplicateFile, postDataBOBDocuments } from '../bob/documents.actions'
import { API_URL } from '../../services/config.service'

const checkBody = parkingInfos => {
    const {
        width,
        length,
        height,
        titleBoaton,
        address,
        parkingPrice,
    } = parkingInfos // needed infos

    if (
        !(
            width &&
            length &&
            height &&
            titleBoaton &&
            address &&
            parkingPrice &&
            parkingPrice?.priceMonth?.price &&
            parkingPrice?.dailyPrice?.price
        )
    ) {
        return null
    }

    return parkingInfos
}

const updateParking = (parkingId, parkingInfos, fileUpdated) => {
    return async dispatch => {
        dispatch({
            type: parkingConstants.START_UPDATE_PARKING,
        })

        const newFiles = parkingInfos.files.filter(file => !file.id)
        const body = checkBody(parkingInfos)
        body.id = parkingId

        if (newFiles && newFiles.length > 0) {
            body.files = [
                ...fileUpdated.filter(file => !file.blop),
                ...(await postDataBOBDocuments(newFiles, 2)),
            ]
        } else {
            body.files = [...fileUpdated]
        }

        if (!body || !parkingId)
            return dispatch({
                type: parkingConstants.SET_PARKING_FAILURE,
                error: `Some information are missing`,
            })

        requestApi(`/parkings`, 'PUT', body).then(
            result => {
                dispatch({
                    type: parkingConstants.SET_PARKING_SUCCESS,
                    parking: result,
                })
            },
            error => {
                dispatch({
                    type: parkingConstants.SET_PARKING_FAILURE,
                    error: error,
                })
            },
        )
    }
}

const createParking = (parkingInfos, fileUpdated) => {
    return async dispatch => {
        dispatch({
            type: parkingConstants.START_UPDATE_PARKING,
        })

        const newFiles = parkingInfos.files.filter(file => !file.id)
        const duplicateFiles = fileUpdated
            ?.filter(file => !file.blop && file.id)
            .map(file => file.id)
        const body = checkBody(parkingInfos)

        if (newFiles && newFiles.length > 0) {
            body.files = [
                ...(await duplicateFile(duplicateFiles)),
                ...(await postDataBOBDocuments(newFiles, 2)),
            ]
        } else if (duplicateFiles && duplicateFiles.length > 0)
            body.files = [...(await duplicateFile(duplicateFiles))]
        if (!body)
            return dispatch({
                type: parkingConstants.SET_PARKING_FAILURE,
                error: `Some information are missing`,
            })
        body.platformType = { id: 1 }

        return requestApi(`/parkings`, 'POST', body).then(
            result => {
                dispatch({
                    type: parkingConstants.SET_PARKING_SUCCESS,
                    parking: result,
                })
            },
            error => {
                dispatch({
                    type: parkingConstants.SET_PARKING_FAILURE,
                    error: error,
                })
            },
        )
    }
}

const resetParking = () => {
    return dispatch => {
        dispatch({
            type: parkingConstants.RESET_PARKING,
        })
    }
}

const getParking = (parkingId = null) => {
    return dispatch => {
        dispatch({
            type: parkingConstants.START_UPDATE_PARKING,
        })

        if (parkingId === null)
            return dispatch({
                type: parkingConstants.SET_PARKING_FAILURE,
                error: `ParkingId is missing`,
            })

        requestApi(`/parkings/${parkingId}`, 'GET').then(
            result => {
                dispatch({
                    type: parkingConstants.SET_PARKING_SUCCESS,
                    parking: result,
                })
            },
            error => {
                dispatch({
                    type: parkingConstants.SET_PARKING_FAILURE,
                    error: error,
                })
            },
        )
    }
}

export const getImageToDisplay = (imgsDb, displayNewFiles) => {
    const imgs = []
    if (imgsDb && imgsDb.length > 0) {
        for (let i = 0; i < imgsDb.length; i++) {
            imgs.push({
                link: API_URL + '/files/' + imgsDb[i].link,
                id: imgsDb[i].id,
            })
        }
    }
    if (displayNewFiles) {
        displayNewFiles.forEach(file => {
            imgs.push({
                blop: file.blop,
                infos: file.infos,
            })
        })
    }
    return imgs
}

export const getParkingPriceDay = price => {
    return price?.dailyPrice?.price ? price.dailyPrice.price : 0
}

export const getMinParkingPriceMonth = (price, nbMonth) => {
    if (nbMonth < 6 && price?.priceMonth?.price)
        return price.priceMonth.price || 0.0
    else if (nbMonth < 12 && price?.price6Month?.price)
        return price.price6Month.price || price?.priceMonth?.price || 0.0
    return (
        price?.price12Month?.price ||
        price?.price6Month?.price ||
        price?.priceMonth?.price ||
        0.0
    )
}

const updateTotalView = (parkingId = null) => {
    return dispatch => {
        if (parkingId !== null) {
            dispatch({
                type: allParkingsConstants.START_UPDATE_PARKING_TOTAL_VIEW,
            })
            requestApi(`/parkings/total-view/${parkingId}`, 'PUT', {}).then(
                result => {
                    dispatch({
                        type:
                            allParkingsConstants.UPDATE_PARKING_TOTAL_VIEW_SUCCESS,
                        parkingUpdated: result,
                    })
                },
                error => {
                    dispatch({
                        type:
                            allParkingsConstants.UPDATE_PARKING_TOTAL_VIEW_FAILURE,
                        error: error,
                    })
                },
            )
        }
    }
}

const activateParking = (parkingId = null) => {
    return dispatch => {
        if (parkingId !== null) {
            dispatch({
                type: allParkingsConstants.CHANGE_STATUS_PARKING,
            })
            requestApi(`/parkings/activate/${parkingId}`, 'PUT', {}).then(
                result => {
                    dispatch({
                        type:
                            allParkingsConstants.CHANGE_STATUS_PARKING_SUCCESS,
                        parkingUpdated: result,
                    })
                },
                error => {
                    dispatch({
                        type:
                            allParkingsConstants.CHANGE_STATUS_PARKING_FAILURE,
                        error: error,
                    })
                },
            )
        }
    }
}

const deactivateParking = (parkingId = null) => {
    return dispatch => {
        if (parkingId !== null) {
            dispatch({
                type: allParkingsConstants.CHANGE_STATUS_PARKING,
            })
            requestApi(`/parkings/deactivate/${parkingId}`, 'PUT', {}).then(
                result => {
                    dispatch({
                        type:
                            allParkingsConstants.CHANGE_STATUS_PARKING_SUCCESS,
                        parkingUpdated: result,
                    })
                },
                error => {
                    dispatch({
                        type:
                            allParkingsConstants.CHANGE_STATUS_PARKING_FAILURE,
                        error: error,
                    })
                },
            )
        }
    }
}

const nbDaySelected = (start, end) => {
    const startDate = new Date(start)
    const endDate = new Date(end)
    const oneDay = 24 * 60 * 60 * 1000
    return Math.round(
        Math.abs((startDate.getTime() - endDate.getTime()) / oneDay),
    )
}

// Important d'utiliser cette fonction car elle gère le mois de février et année bissextile
const nbMonthSelected = (start, end) => {
    if (!end) return 0
    const startDate = new Date(start)
    const endDate = new Date(end)
    var timeDiff = Math.abs(endDate.getTime() - startDate.getTime())
    return Math.round(timeDiff / (2e3 * 3600 * 365.25))
}

function getPriceOfOneTransactionIt({
    mensualisation,
    parking,
    startDate,
    endDate,
    length,
    width,
}) {
    const priceEt = getPriceOfOneTransactionEt({
        mensualisation,
        parking,
        startDate,
        endDate,
        length,
        width,
    })
    const vatId = parking.vatId
    const priceWithBoatonTax = (priceEt + 0.25) / 0.866
    if (vatId && mensualisation) {
        switch (vatId) {
            case 1:
                return (priceEt + (priceEt * 20) / 100 + 0.25) / 0.866
            case 2:
                return (priceEt + (priceEt * 10) / 100 + 0.25) / 0.866
            case 3:
                return (priceEt + (priceEt * 5.5) / 100 + 0.25) / 0.866
            default:
                return priceWithBoatonTax
        }
    } else {
        return priceWithBoatonTax
    }
}

function getBoatOnFees(priceIt) {
    return priceIt * 0.12
}

function getPriceOfOneTransactionEt({
    mensualisation,
    parking,
    startDate,
    endDate,
    length,
    width,
}) {
    if (mensualisation === 1) {
        return (
            parkingActions.getMinParkingPriceMonth(
                parking.parkingPrice,
                parkingActions.nbMonthSelected(startDate, endDate),
            ) *
            length *
            width
        )
    } else {
        return (
            parkingActions.getParkingPriceDay(parking.parkingPrice) *
            width *
            length *
            parkingActions.nbDaySelected(startDate, endDate)
        )
    }
}

function getTotalPriceForInqueryEt({
    mensualisation,
    parking,
    startDate,
    endDate,
    length,
    width,
}) {
    const priceOfOneTransaction = getPriceOfOneTransactionEt({
        mensualisation,
        parking,
        startDate,
        endDate,
        length,
        width,
    })
    if (mensualisation === 1) {
        const nbMonths = parkingActions.nbMonthSelected(startDate, endDate)
        return priceOfOneTransaction * nbMonths
    } else {
        return priceOfOneTransaction
    }
}

function getTotalPriceForInqueryIt({
    mensualisation,
    parking,
    startDate,
    endDate,
    length,
    width,
}) {
    const priceOfOneTransaction = getPriceOfOneTransactionIt({
        mensualisation,
        parking,
        startDate,
        endDate,
        length,
        width,
    })
    if (mensualisation === 1) {
        const nbMonths = parkingActions.nbMonthSelected(startDate, endDate)
        return priceOfOneTransaction * nbMonths
    } else {
        return priceOfOneTransaction
    }
}

export const parkingActions = {
    createParking,
    updateParking,
    getParking,
    resetParking,
    getImageToDisplay,
    getParkingPriceDay,
    getMinParkingPriceMonth,
    updateTotalView,
    activateParking,
    deactivateParking,
    nbDaySelected,
    nbMonthSelected,
    getPriceOfOneTransactionEt,
    getPriceOfOneTransactionIt,
    getTotalPriceForInqueryEt,
    getTotalPriceForInqueryIt,
    getBoatOnFees,
}
