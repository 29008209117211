export default {
    new1: {
        FR: `Bravo, un nouveau`,
        EN: `Congratulations, a new`,
    },
    new2: {
        FR: `a rejoint votre équipage`,
        EN: `joined your crew`,
    },
    descr: {
        FR: `Votre invitation est bien partie. Vous pouvez personnaliser les bateaux et modules auxquels cet utilisateur a accès à tout moment.`,
        EN: `Your invitation is gone. You can customize the boats and modules this user has access to at any time.`,
    },
    noPerso: {
        FR: `Ne pas personnaliser`,
        EN: `Do not customize`,
    },
    perso: {
        FR: `Personnaliser`,
        EN: `Customize`,
    },
}
