import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/InqueriesPageCss.js'

import UnderNavigation from '../common/UnderNavigation'
import InsuranceTab from './Insurance/InsuranceTab'
import ParkingTab from './Parking/ParkingTab'
import AppRoute from '../../constants/AppRoute'
import BoatOnComponent from '../common/BoatOnComponent'
import dictionary from './Dictionary/InqueriesPageDico'
import { getCurrentRouteKey } from '../../languages/LocalizerUtils'

class InqueriesPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    getNav(routes, currentAppRoute) {
        for (let i = 0; i < routes.length; i++) {
            if (
                new RegExp('/?' + routes[i] + '.*|' + routes[i]).test(
                    currentAppRoute,
                )
            )
                return i
        }
        return 0
    }

    render() {
        let currentAppRoute = getCurrentRouteKey(
            this.props.location.pathname,
            this.getContext(),
            this.getMessages(),
        )
        let tabs = [
            <InsuranceTab location={this.props.location} />,
            <ParkingTab location={this.props.location} />,
        ]
        let routes = [AppRoute.InsuranceInqueries, AppRoute.InqueriesParking]
        let labs = [
            this.displayText('insuranceOffer'),
            this.displayText('findParking'),
        ]
        let nav = this.getNav(routes, currentAppRoute)

        return (
            <div className={this.props.classes.root}>
                <UnderNavigation
                    routes={routes}
                    tabs={tabs}
                    nav={nav}
                    labs={labs}
                />
            </div>
        )
    }
}

export default withStyles(styles)(InqueriesPage)
