export default {
    sent: {
        FR: 'Envoyé',
        EN: 'Sent',
    },
    readed: {
        FR: 'Lu le : ',
        EN: 'Readed the : ',
    },
    send: {
        FR: 'Envoyer',
        EN: 'Send',
    },
}
