import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { LocalizedLink } from '../../languages'
import BoatOnComponent from './BoatOnComponent'
import styles from './Styles/BackLinkedPageCss'
import dictionary from './Dictionary/BackLinkedPageDico'

class BackLinkedPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
    }

    renderBackLink() {
        const { backLink, classes } = this.props
        return (
            <LocalizedLink to={backLink} className={classes.backLink}>
                {'< '} &nbsp;{this.displayText(`return`)}
            </LocalizedLink>
        )
    }

    render() {
        const { classes, backLink, body } = this.props

        if (!backLink) {
            console.error('No backling set!')
            return <></>
        }

        if (!body) {
            console.error('No body set!')
            return <></>
        }

        return (
            <div className={classes.root}>
                {this.renderBackLink()}{' '}
                <div className={classes.body}>{body()}</div>
            </div>
        )
    }
}
export default withStyles(styles)(BackLinkedPage)
