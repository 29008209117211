export default {
    active: {
        FR: 'Actif',
        EN: 'Active',
    },
    inactive: {
        FR: 'Inactif',
        EN: 'Inactive',
    },
    totalView: {
        FR: 'Votre emplacement a été vu ',
        EN: 'Your place was seen ',
    },
    time: {
        FR: 'fois',
        EN: 'time(s)',
    },
    youHave: {
        FR: 'Vous avez actuellement',
        EN: 'You avec actually',
    },
    location: {
        FR: 'emplacement(s) à louer',
        EN: 'place(s) in renting',
    },
    edit: {
        FR: 'Modifier mon annonce',
        EN: 'Edit my place',
    },
    clone: {
        FR: 'Dupliquer',
        EN: 'Duplicate',
    },
    activate: {
        FR: 'Activer',
        EN: 'Activate',
    },
    desactivate: {
        FR: 'Désactiver',
        EN: 'Desactivate',
    },
    activated: {
        FR: 'Activé',
        EN: 'Activate',
    },
    desactivated: {
        FR: 'Désactivé',
        EN: 'Desactivate',
    },
    alertActivate: {
        FR: "Votre parking vient d'être activé",
        EN: 'Your parking lot has just been activated',
    },
    alertDesactivate: {
        FR: "Votre parking vient d'être désactivé",
        EN: 'Your parking lot has just been deactivated',
    },
    activeBis: {
        FR: 'Activé',
        EN: 'Activated',
    },
    disableBis: {
        FR: 'Désactivé',
        EN: 'Disabled',
    },
    pitch: {
        FR: 'souhaite louer votre emplacement',
        EN: 'wishes to rent your pitch',
    },
    currently: {
        FR: 'Actuellement',
        EN: 'Currently',
    },
    noParking: {
        FR: "Vous n'avez publié aucun parking",
        EN: 'You have not published any parking',
    },
    have: {
        FR: 'Vous avez',
        EN: 'You have',
    },
    ad: {
        FR: 'Cette annonce a été vue',
        EN: 'This ad has been viewed',
    },
    times: {
        FR: 'fois',
        EN: 'times',
    },
    fromThe: {
        FR: 'à partir du',
        EN: 'from the',
    },
    from: {
        FR: 'du',
        EN: 'from',
    },
    at: {
        FR: 'au',
        EN: 'at',
    },
    month: {
        FR: '/ mois',
        EN: '/ month',
    },
    answer: {
        FR: 'Répondre',
        EN: 'Answer',
    },
    the: {
        FR: 'le',
        EN: 'the',
    },
}
