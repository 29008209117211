export default {
    arrival: {
        FR: 'A partir du',
        EN: 'From',
    },
    departure: {
        FR: 'Départ',
        EN: 'Departure',
    },
    ended: {
        FR: 'Location à durée indéterminée',
        EN: 'Open-ended rental',
    },
    detailsLocalization: {
        FR: 'Détails du lieu',
        EN: 'Details of the location',
    },
    textLocalization: {
        FR:
            'Les coordonnées de la personne qui vous accueillera vous seront communiquées une fois votre demande de location acceptée.',
        EN:
            'The contact details of the person who will welcome you will be communicated to you once your rental request has been accepted.',
    },
    particularInfo: {
        FR: 'Informations particulières',
        EN: 'Special information',
    },
    sendMessage: {
        FR: "Envoyer un message à l'hôte",
        EN: 'Send a message to the host',
    },
    showAnnouncement: {
        FR: "Afficher l'annonce",
        EN: 'Show the announcement',
    },
    copyAddress: {
        FR: "Copier l'adresse",
        EN: 'Copy the address',
    },
    getItinerary: {
        FR: "Obtenir l'itinéraire",
        EN: 'Get the itinerary',
    },
    howToGo: {
        FR: "Comment s'y rendre ?",
        EN: 'How to get there?',
    },
    address: {
        FR: 'Adresse',
        EN: 'Address',
    },
    infoPayment: {
        FR: 'Informations de paiement',
        EN: 'Payment information',
    },
    getReceipt: {
        FR: 'Obtenir le reçu',
        EN: 'Get the receipt',
    },
    printInfo: {
        FR: 'Imprimer les informations de la location',
        EN: 'Print rental information',
    },
    amount: {
        FR: 'Montant',
        EN: 'Amount',
    },
    name: {
        FR: 'Nom du contact',
        EN: 'Contact name',
    },
    phone: {
        FR: 'Téléphone',
        EN: 'Phone',
    },
    perMonth: {
        FR: ' / mois',
        EN: ' / month',
    },
    addressCopied: {
        FR: 'Adresse copiée',
        EN: 'Address copied',
    },
    textDecline: {
        FR:
            "Cet emplacement n'est malheureusement pas disponible et vous ne serez pas facturé pour cette demande. Mais pas d'inquiétude, il y a d'autres options sur notre site internet boaton.fr.",
        EN:
            "Unfortunately this pitch is not available and you will not be charged for this request. But don't worry, there are other options on our website boaton.fr.",
    },
    textCancel: {
        FR:
            "Vous avez annulé votre demande, mais il y a d'autres options sur notre site internet.",
        EN:
            'You have cancelled your request, but there are other options on our website.',
    },
    textGoTo: {
        FR:
            'Rendez-vous sur la page "Stockage" pour trouver une autre solution.',
        EN: 'Go to the "Storage" page to find another solution.',
    },
    findAnotherPlace: {
        FR: 'Trouver une autre place',
        EN: 'Find another place',
    },
    detailsRequest: {
        FR: 'Détails de votre demande',
        EN: 'Details of your request',
    },
    toStock: {
        FR: 'Bien stocké :',
        EN: 'Good stored :',
    },
    lengthNeed: { FR: 'Longueur souhaitée :', EN: 'Desired length:' },
    widthNeed: { FR: 'Largeur souhaitée :', EN: 'Desired width:' },
    surfaceNeed: { FR: 'Surface souhaitée :', EN: 'Desired surface:' },
    cancel: { FR: `Annuler`, EN: `Cancel` },
}
