export default theme => ({
    root: {
        display: 'grid',
        gap: 15,
        gridTemplateAreas: `
            "map"
            "speed"
            "weather"
        `,
        [theme.breakpoints.down('sm')]: {
            gridTemplateAreas: `
                "data"
                "map"
                "speed"
                "weather"
            `,
        },
    },
    titleSection: {
        fontSize: 14,
        marginBottom: 10,
    },

    dataContainer: {
        gridArea: 'data',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        gap: 30,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            flexFlow: 'row wrap',
        },
    },
    dataItem: {
        display: 'flex',
        flexDirection: 'column',
    },
    dataValue: {
        fontSize: 32,
    },
    dataUnit: {
        fontSize: 14,
        fontWeight: 400,
    },
    dataTitle: {
        fontSize: 12,
        fontWeight: 400,
        color: '#7B7D7E',
    },

    mapContainer: {
        position: 'relative',
        gridArea: 'map',
        height: 400,
        '& .leaflet-container': {
            height: 400,
        },
        [theme.breakpoints.down('xs')]: {
            width: 'calc(100% + 40px)', // this is done so that call fill all screen size
            marginLeft: -20,
        },
    },
    dataInMapContainer: {
        position: 'absolute',
        backgroundColor: 'white',
        borderRadius: 10,
        height: 40,
        bottom: 10,
        left: 10,
        zIndex: 500,
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        padding: 10,
    },
    dataValueInMap: {
        fontSize: 24,
    },
    dataTitleInMap: {
        fontSize: 10,
        fontWeight: 400,
        color: '#7B7D7E',
    },

    speedGraphContainer: {
        gridArea: 'speed',
        maxHeight: 200,
    },

    weatherContainer: {
        gridArea: 'weather',
    },
    weather: {
        height: 150,
        backgroundColor: 'green',
    },
    boatOnWeather: {
        width: '100%',
        display: 'grid',
        '& h1': {
            fontSize: 14,
            fontWeight: 500,
            marginBottom: 8,
        },
    },
    boatOnWeatherCardArea: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center',
        },
        flexWrap: 'wrap',
    },
    boatOnWeatherCardAreaCard: {
        width: 'calc(100% / 4)',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 10,
            gap: 0,
            minHeight: 180,
            boxSizing: 'border-box',
        },
        height: 150,
        backgroundColor: '#303F9F1A',
        padding: 23,
        borderRadius: 10,
        display: 'flex',
        justifyContent: 'space-between',
    },
    boatOnWeatherCardAreaCardBody: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    boatOnWeatherCardAreaCardBodyIcon: {
        width: 100,
        height: 100,
    },
    boatOnWeatherCardAreaCardBodyIconImg: {
        width: '100%',
    },
    boatOnWeatherCardAreaCardBodyIconSvg: {
        width: '100%',
    },
    boatOnWeatherCardAreaCardHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '& h2, & p': {
            fontSize: 20,
            fontWeight: 500,
        },
        '& span': {
            fontSize: 16,
            fontWeight: 500,
            color: '#999999',
        },
    },
    boatOnWeatherTableArea: {
        marginTop: 24,
        overflowX: 'auto',
        width: '100%',
        '& table': {
            tableLayout: 'fixed',
            borderCollapse: 'separate',
            borderSpacing: '0 2px',
            '& td': {
                backgroundColor: 'white',
                textAlign: 'center',
                maxWidth: 75,
                minWidth: 75,
                height: 22,
                fontSize: 12,
                padding: 4,
                '&:first-child': {
                    textAlign: 'left',
                    maxWidth: 120,
                    minWidth: 120,
                    fontWeight: 600,
                    position: 'sticky',
                    top: 0,
                    left: 0,
                    backgroundColor: 'white',
                    zIndex: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    '& span': {
                        fontWeight: '400',
                        color: '#C1C1C1',
                    },
                },
                '&:last-child': {
                    padding: 0,
                },
            },
            '& tr': {
                '&:first-child': {
                    '& td': {
                        fontSize: 10,
                        height: 12,
                    },
                },
                '&:nth-child(2)': {
                    '& td:not(:first-child)': {
                        backgroundColor: '#EAECF5',
                    },
                },
                '&:nth-child(3), &:nth-child(8)': {
                    '& td:not(:first-child)': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 12,
        },
    },
    boatOnWeatherTableAreaIcon: {
        width: 16,
        height: 16,
    },
    isDay: {
        backgroundColor: '#303F9F !important',
        color: 'white',
    },
    freshTier4: {
        backgroundColor: '#64ED83!important',
    },
    freshTier3: {
        backgroundColor: '#80ED5F!important',
    },
    freshTier2: {
        backgroundColor: '#CCED5E!important',
    },
    freshTier1: {
        backgroundColor: '#E1EC5E!important',
    },
    moderateTier5: {
        backgroundColor: '#FAFAFF!important',
    },
    moderateTier4: {
        backgroundColor: '#F7F7FF!important',
    },
    moderateTier3: {
        backgroundColor: '#E5E7FF!important',
    },
    moderateTier2: {
        backgroundColor: '#DCDFFE!important',
    },
    moderateTier1: {
        backgroundColor: '#AEB4FE!important',
    },
    hotTier5: {
        backgroundColor: '#FEEF51!important',
    },
    hotTier4: {
        backgroundColor: '#EDCF5F!important',
    },
    hotTier3: {
        backgroundColor: '#FFCC28!important',
    },
    hotTier2: {
        backgroundColor: '#EDA35E!important',
    },
    hotTier1: {
        backgroundColor: '#FF9C28!important',
    },
    rightArrow: {
        position: 'relative',
        padding: '1px 1px',
        backgroundColor: '#444',
        color: 'white',
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        left: 'calc(100% - 5px)', // 76px minus point and arrow
        bottom: 12,
        width: '76px',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: '50%',
            left: 0,
            transform: 'translate(-50%, -50%)',
            borderWidth: '3px',
            borderStyle: 'solid',
            borderColor: '#444',
            borderRadius: '50%',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            right: 0,
            transform: 'translate(50%, -50%)',
            borderWidth: '5px',
            borderStyle: 'solid',
            borderColor: 'transparent transparent transparent #444',
        },
    },
    leftArrow: {
        position: 'relative',
        padding: '1px 1px',
        backgroundColor: '#444',
        color: 'white',
        textAlign: 'center',
        fontSize: 18,
        fontWeight: 'bold',
        left: '100%',
        width: '100%',
        bottom: 14,
        '&::after': {
            content: '""',
            position: 'absolute',
            top: '50%',
            right: 0,
            transform: 'translate(50%, -50%)',
            borderWidth: '3px',
            borderStyle: 'solid',
            borderColor: '#444',
            borderRadius: '50%',
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            left: 0,
            transform: 'translate(-50%, -50%)',
            borderWidth: '5px',
            borderStyle: 'solid',
            borderColor: 'transparent #444 transparent transparent',
        },
    },
    stopOverLine: {
        position: 'relative',
        borderTop: '2px dashed #444',
        left: '76px',
        width: '76px',
        bottom: 12,
    },

    selectedPoint: {
        border: '2px solid white',
        borderRadius: '100%',
        backgroundColor: 'black',
        height: '10px !important',
        width: '10px !important',
        '&:hover': {
            cursor: 'unset',
        },
        position: 'relative',
    },
    selectedPointFirstText: {
        color: 'white',
        width: 30,
        height: 18,
        padding: '4px 8px',
        position: 'absolute',
        borderRadius: 16,
        backgroundColor: 'black',
        top: -30,
        left: 'calc((-30px / 2) - 2.5px)',
        textAlign: 'center',
    },
    selectedPointSecondText: {
        width: 45,
        height: 18,
        padding: '4px 8px',
        position: 'absolute',
        borderRadius: 16,
        backgroundColor: 'white',
        border: '1px solid black',
        bottom: -33,
        left: 'calc((-45px / 2) - 3.5px)',
        textAlign: 'center',
        whiteSpace: 'nowrap',
    },
})
