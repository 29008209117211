import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import ReactGA from 'react-ga'
import ReactPixel from 'react-facebook-pixel'
import BoatOnComponent from '../../../common/BoatOnComponent'
import AutoComplete from '../../../common/AutoComplete'
import SaveBar from '../../../common/SaveBar'

import styles from './Styles/BoatInfoPageCss'
import dictionary from './Dictionary/BoatInfoPageDico'
import ModalConnection from '../../../common/UsefullComponents/ModalConnection/ModalConnection'
import BoatOnModal from '../../../common/BoatOnModal'
import AddressSearch from '../../../common/UsefullComponents/AddressSearch'
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Edit as EditIcon,
} from '@material-ui/icons'
import {
    updateBOBBoat,
    initBobWithBoat,
    removeBOBBoat,
} from '../../../../actions/bob.actions'
import { userActions } from '../../../../actions/user.actions'
import RenderBoatCreate from '../../../common/UsefullComponents/RenderBoatCreate'
import { typesActions } from '../../../../actions'
import ModalDeleteBoat from './ModalDelete'
import { boatActions } from '../../../../actions/boat.actions'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'
import AppRoute from '../../../../constants/AppRoute'
import { history } from '../../../../helpers'
import { Button as ButtonBO } from '../../../common'
import catamaranicon from '../../../../images/catamaranicon.svg'
import { getBoatsOfAFleet } from '../../../../actions/bob.actions'
import { Button } from '../../../common/BoatOnButton'
import HourAlert from '../HourAlert'
import pricesConfig from '../../../PricesPage/pricesConfig'
import BoatOnNumberField from '../../../common/UsefullComponents/BoatOnNumberField'
import { Divider } from '@material-ui/core'
import { getContextFromUrl } from '../../../../languages/LocalizerUtils'
import { countries } from '../../../../constants/countries.constants'
import {
    getWorkingHours,
    getFillingLevels,
} from '../LogBookPage/Utils/eventsFilter'
import { getTranslatedTitleFromEvent } from '../Utils'
import {
    Autocomplete as MuiAutocomplete,
    createFilterOptions,
} from '@mui/material'
import BoatOnSpeedometer from '../../../common/BoatOnSpeedometer'
import EditWorkingHoursModal from './Modals/EditWorkingHoursModal'
import EditFillingLevelsModal from './Modals/EditFillingLevelsModal'
import { fixIosHeaderBug } from '../../../../utils/usefullFunctions'
import { Actions, PermissionsEntities } from '../../../../constants'

const COUNTRIES_OPTIONS = Object.entries(countries).map(([key, value]) => ({
    key: key,
    FR: value.FR,
    EN: value.EN,
}))

const defaultFilterOptions = createFilterOptions()

class BoatInfoPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.initializeReactGA()
        this.state = {
            boats: getBoatsOfAFleet(this.props.user, this.props.groupId),
            boat: null,
            resetSave: false,
            openModalConnection: true,
            openModalNoBoat: false,
            openModalNewBoat: false,
            openModalDeleteBoat: false,
            boatNameCreate: '',
            makerCreate: '',
            modelCreate: '',
            yearCreate: '',
            engineCreate: '',
            stockageCreate: '',
            boatTypeCreate: '',
            boatCreation: '',
            searchResult: [],
            searchResultMaker: [],
            boatid: '',
            subscribed: false,
            saveBoatIdDelete: false,
            equipmentInUse: null,
            equipmentCapacities: [],
            selectedModal: false,
        }

        const options = {
            autoConfig: true,
            debug: false,
        }

        ReactPixel.init('181677145678471', options)

        ReactPixel.pageView()

        this.checkBoatInfoAreValid = this.checkBoatInfoAreValid.bind(this)
        this.save = this.save.bind(this)
        this.valid = this.valid.bind(this)
        this.handleResetSave = this.handleResetSave.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleListSelection = this.handleListSelection.bind(this)
        this.setAddress = this.setAddress.bind(this)
        this.setEnsign = this.setEnsign.bind(this)
        this.renderNoBoatModal = this.renderNoBoatModal.bind(this)
        this.deleteBoat = this.deleteBoat.bind(this)
        this.openNotSub = this.openNotSub.bind(this)
        this.openSubNewBoat = this.openSubNewBoat.bind(this)
        this.redirectTarifs = this.redirectTarifs.bind(this)
        this.redirectInfos = this.redirectInfos.bind(this)
        this.getPriceFromSub = this.getPriceFromSub.bind(this)
        this.handleCloseSub = this.handleCloseSub.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.openModal = this.openModal.bind(this)
        this.getOptionLabel = this.getOptionLabel.bind(this)
    }

    initializeReactGA() {
        ReactGA.initialize('UA-93941518-1')
        ReactGA.pageview('/my-boat-logbook/my-informations')
    }

    async componentDidMount() {
        const {
            boatTypes,
            loadingTypes,
            subscriptions,
            user,
            bob,
            events,
        } = this.props

        if (bob?.boat) {
            if (user && !subscriptions && this.props.profile) {
                this.props.dispatch(userActions.requestSubscriptions())
            }
            if (!boatTypes && !loadingTypes) {
                this.props.dispatch(typesActions.requestBoatTypes())
            }
            if (
                (!this.state.boat && this.props.bob.boat) ||
                JSON.stringify(this.state.boat) !==
                    JSON.stringify(this.props.bob.boat)
            ) {
                this.setBoat()
            }
        }

        if (events) this._initState()
    }

    async componentDidUpdate(prevProps) {
        const {
            boatTypes,
            loadingTypes,
            subscriptions,
            user,
            bob,
            events,
            userEquipments,
        } = this.props

        if (bob?.boat) {
            if (this.state.resetSave) {
                this.setState({ resetSave: false })
            }
            if (user && !subscriptions && this.props.profile) {
                this.props.dispatch(userActions.requestSubscriptions())
            }

            if (!boatTypes && !loadingTypes) {
                this.props.dispatch(typesActions.requestBoatTypes())
            }

            if (
                this.props.bob.loading === 0 &&
                prevProps.bob.loading !== 0 &&
                ((!this.state.boat && this.props.bob.boat) ||
                    JSON.stringify(this.state.boat) !==
                        JSON.stringify(this.props.bob.boat))
            ) {
                this.setBoat()
                this.setState({ resetSave: true })
            }

            if (
                subscriptions &&
                userActions.userIsSubscribed(subscriptions) &&
                this.state.subscribed === false
            ) {
                this.setState({
                    subscribed: userActions.userIsSubscribed(subscriptions),
                })
            }
        }
        if (!this.state.boat && this.state.boats?.length > 0) {
            const boatId = localStorage.getItem('boatIdSelect')
            const boat = this.state.boats.filter(boat => boat.id === boatId)
            if (boat.length > 0) {
                this.setState({ boat: boat[0] })
            }
        }

        if (events !== prevProps.events) {
            this._initState()
        }

        if (prevProps.userEquipments.length < userEquipments.length)
            this._initState()
    }

    _initState() {
        const { events, userEquipments } = this.props

        const workingHours = getWorkingHours(events)
        const fillingLevels = getFillingLevels(events, userEquipments)

        this.setState({
            equipmentInUse: workingHours?.equipmentInUse,
            equipmentCapacities: fillingLevels?.bobEventUsed
                ? fillingLevels.bobEventUsed
                : [],
        })
    }

    handleClose() {
        this.setState({
            openModalConnection: false,
            openModalNewBoat: false,
            newBoatId: false,
        })
    }

    closeModal() {
        this.setState({
            selectedModal: false,
        })
    }

    openModal(modal) {
        this.setState({
            selectedModal: modal,
        })
    }

    setBoat() {
        const { bob } = this.props
        if (bob.boat) {
            this.setState(prevState => ({
                boat: bob.boat,
            }))
        }
    }

    handleResetSave() {
        this.setState({ resetSave: false })
    }

    handleChange(value, key) {
        this.setState(prevState => ({
            boat: {
                ...prevState.boat,
                [key]: value,
            },
        }))
    }

    setAddress(address) {
        this.setState(prevState => ({
            boat: {
                ...prevState.boat,
                address: address,
            },
        }))
    }

    setEnsign(_, value) {
        this.setState(prevState => ({
            boat: {
                ...prevState.boat,
                ensign: value ? (value.key ? value.key : value) : null,
            },
        }))
    }

    handleSearchList(value, key) {
        if (!value) {
            // no value no results
            return this.setState({ searchResult: [], modelCreate: value })
        }

        const { listBoats } = this.props
        let matches = []

        if (key === 'boatModel') {
            for (let i in listBoats.boatModels) {
                if (
                    listBoats.boatModels[i].libelleModeleBateau
                        .toLowerCase()
                        .includes(value.toLowerCase())
                ) {
                    matches.push(listBoats.boatModels[i])
                }
            }
            this.setState({
                searchResult: matches.length < 50 ? matches : [],
                modelCreate: value,
            })
        } else {
            for (let i in listBoats.boatModels) {
                if (
                    listBoats.boatModels[i].libelleConstructeur
                        .toLowerCase()
                        .includes(value.toLowerCase())
                ) {
                    matches.push(listBoats.boatModels[i].libelleConstructeur)
                }
            }
            matches = [...new Set(matches)]
            this.setState({
                searchResultMaker: matches.length < 50 ? matches : [],
                makerCreate: value,
            })
        }
    }

    handleListSelection(value, type) {
        if (value.libelleGenre === 'Bateau à moteur / jetski')
            value.libelleGenre = this.state.boat.boatType
        if (type === 'maker') {
            this.setState(prevState => ({
                boat: {
                    ...prevState.boat,
                    boatMaker: value,
                },
                searchResultMaker: [],
            }))
        } else {
            this.setState(prevState => ({
                boat: {
                    ...prevState.boat,
                    boatModel: value.libelleModeleBateau,
                    boatMaker: value.libelleConstructeur,
                    boatType: value.libelleGenre,
                },
                searchResult: [],
            }))
        }
    }

    deleteBoat({ boatId, tableId, valid }) {
        const { openModalDeleteBoat, saveBoatIdDelete } = this.state
        const { groupId, user } = this.props

        if (saveBoatIdDelete && openModalDeleteBoat && valid) {
            this.props.dispatch(
                removeBOBBoat(saveBoatIdDelete, {
                    callback: () => {
                        this.props.dispatch(
                            boatActions.requestBoats(groupId, user),
                        )
                    },
                }),
            )
            this.setState({
                openModalDeleteBoat: false,
                saveBoatIdDelete: false,
            })
            this.setBoat()
        } else if (boatId && !openModalDeleteBoat) {
            this.setState({
                openModalDeleteBoat: true,
                saveBoatIdDelete: boatId,
            })
        } else if (tableId) {
            let { boat } = this.state
            boat.splice(tableId, 1)
            this.setState({ boat: boat })
        } else if (!valid) {
            this.setState({
                openModalDeleteBoat: false,
                saveBoatIdDelete: false,
            })
        }
    }

    checkBoatInfoAreValid() {
        const { boat } = this.state
        let isValid = true

        if (boat.mmsi && boat.mmsi.length !== 9) isValid = false

        return isValid
    }

    async save() {
        const { boat } = this.state
        const { user, groupId } = this.props

        if (!this.checkBoatInfoAreValid()) return false

        await this.props.dispatch(updateBOBBoat(boat, user, groupId))
        this.setState({ resetSave: true })
        this.setBoat()
    }

    valid(next, boatId) {
        if (boatId) {
            this.historyPush(history, AppRoute.LogBook.Repair)
            this.props.dispatch(initBobWithBoat({ boatId }))
        }
        if (next && typeof next === 'string') {
            this.setState({ openModalNewBoat: next, newBoatId: boatId })
            this.setBoat()
        } else {
            this.handleClose()
        }
    }

    handleCloseSub() {
        this.valid(`boatInfos`)
    }

    openNotSub() {
        this.setState({ openModalNewBoat: `notSub` })
    }

    openSubNewBoat() {
        this.setState({ openModalNewBoat: `subButPrice` })
    }

    redirectTarifs() {
        this.historyPush(history, AppRoute.LogBook.Pricing)
    }

    async redirectInfos() {
        const url = await userActions.showClientPortail()
        window.location.assign(url)
    }

    getPriceFromSub() {
        let { subscriptions } = this.props

        if (!(subscriptions?.length > 0)) return 0

        subscriptions = subscriptions.filter(
            subscription =>
                subscription?.subType?.id >= 1 &&
                subscription?.subType?.id <= 10,
        )

        if (!(subscriptions?.length > 0)) return 0

        subscriptions.sort((a, b) => a.id - b.id)

        switch (subscriptions[0]?.subType?.id) {
            case 1:
                return pricesConfig.find(p => p.skipper).skipper.month.ttc
            case 2:
                return pricesConfig.find(p => p.skipper).skipper.year.ttc
            case 3:
                return pricesConfig.find(p => p.bosco).bosco.month.ttc
            case 4:
                return pricesConfig.find(p => p.bosco).bosco.year.ttc
            case 5:
                return pricesConfig.find(p => p.commandant).commandant.month.ttc
            case 6:
                return pricesConfig.find(p => p.commandant).commandant.year.ttc
            case 7:
                return pricesConfig.find(p => p.amiral).amiral.month.ttc
            case 8:
                return pricesConfig.find(p => p.amiral).amiral.year.ttc
            case 9:
                return pricesConfig.find(p => p.captain).captain.month.ttc
            case 10:
                return pricesConfig.find(p => p.captain).captain.year.ttc
            default:
                return 0
        }
    }

    renderNoBoatModal(addNow = false) {
        const { classes, subscriptions, numberBoats } = this.props

        return (
            <BoatOnModal
                openCondition={this.state.openModalNewBoat}
                handleClose={this.handleClose}
                modalCores={{
                    boatInfos: (
                        <RenderBoatCreate addNow={addNow} valid={this.valid} />
                    ),
                    notSub: (
                        <div className={classes.containerModal}>
                            <Typography variant="body1">
                                {' '}
                                {this.displayText(`cta1`)}{' '}
                                <span
                                    onClick={
                                        subscriptions?.length > 0
                                            ? this.redirectInfos
                                            : this.redirectTarifs
                                    }
                                    style={{
                                        textDecoration: `underline`,
                                        fontWeight: `bold`,
                                    }}
                                >
                                    {subscriptions?.length > 0
                                        ? this.displayText(`espaceClient`)
                                        : this.displayText(`tarifs`)}
                                </span>{' '}
                            </Typography>
                            <ButtonBO
                                label={this.displayText(`understand`)}
                                onClick={this.handleClose}
                            />
                        </div>
                    ),
                    subButPrice: !numberBoats ? (
                        this.renderLoading(`100px`, `100px`, true)
                    ) : (
                        <div className={classes.containerModal}>
                            <Typography variant="body1">
                                {' '}
                                {this.displayText(`cta21`)}{' '}
                                {this.getPriceFromSub()}€{' '}
                                {this.displayText(`ttc`)}{' '}
                                {this.displayText(`cta22`)}{' '}
                                {numberBoats > 1
                                    ? (
                                          this.getPriceFromSub() *
                                          (numberBoats + 1)
                                      ).toFixed(2)
                                    : (this.getPriceFromSub() * 2).toFixed(2)}
                                € {this.displayText(`ttc`)}.
                            </Typography>
                            <ButtonBO
                                label={this.displayText(`understand`)}
                                onClick={this.handleCloseSub}
                            />
                        </div>
                    ),
                }}
                titles={{
                    boatInfos: this.displayText('boatAdd'),
                    subButPrice: `Price goes up`,
                    notSub: `Price goes down`,
                }}
                maxWidth={{
                    boatInfos: `sm`,
                    subButPrice: `sm`,
                    notSub: `sm`,
                }}
            />
        )
    }

    getOptionLabel(option) {
        const { context } = this.props

        return option !== null && typeof option === 'object'
            ? option[context.toUpperCase()]
            : Object.keys(countries).includes(option)
            ? countries[option][context.toUpperCase()]
            : option
    }

    _renderContent() {
        const {
            classes,
            boatTypes,
            subscriptions,
            profile,
            context,
        } = this.props
        const {
            boat,
            searchResultMaker,
            equipmentInUse,
            equipmentCapacities,
        } = this.state

        const displayWokingEquipment = equipmentInUse
            ? equipmentInUse
                  .sort((a, b) => {
                      const aName = getTranslatedTitleFromEvent(this, a)
                      const bName = getTranslatedTitleFromEvent(this, b)

                      if (!aName) return 1
                      if (!bName) return -1

                      return aName.localeCompare(bName)
                  })
                  .map((event, index) => {
                      const equipment = event.detail?.equipment || null
                      const type = equipment.equipmentFamily?.type || null

                      if (!equipment) return null

                      const imgSrc = type?.image
                          ? require(`images/${type?.image}`)
                          : null

                      const value = event?.detail?.hourActual || 0

                      return (
                          <div
                              className={
                                  classes.worktimeItemContainer + ' case'
                              }
                              key={`worktimeItem-${index}`}
                          >
                              <img
                                  src={imgSrc}
                                  alt={type?.translation[context] || ''}
                                  className={classes.worktimeIcon}
                              />
                              <div className={classes.worktimeDataContainer}>
                                  <div className={classes.worktimeItemTitle}>
                                      {getTranslatedTitleFromEvent(this, event)}
                                  </div>
                                  <div className={classes.worktimeItemValue}>
                                      {value} h
                                  </div>
                              </div>
                          </div>
                      )
                  })
            : null

        const displayLevels = equipmentCapacities
            .sort((a, b) => {
                const aName = getTranslatedTitleFromEvent(this, a)
                const bName = getTranslatedTitleFromEvent(this, b)

                if (!aName) return 1
                if (!bName) return -1

                return aName.localeCompare(bName)
            })
            .map((event, index) => {
                const equipment = event.detail?.equipment || null
                const type = equipment?.equipmentFamily?.type || null
                const optionnalDetail = event.detail?.optionnalDetail || null

                if (!equipment) return null

                const imgSrc = type?.image
                    ? require(`images/${type?.image}`)
                    : null
                const value = optionnalDetail?.fillingLevel || 0
                const unit = event.capacity
                    ? 'L'
                    : equipment.equipmentType?.optionnalDetailActivated?.unit

                return (
                    <div
                        className={classes.stockItemContainer + ' case'}
                        key={`stockItem-${index}`}
                    >
                        <div className={classes.stockItemTitle}>
                            <img
                                src={imgSrc}
                                alt={type?.translation[context] || ''}
                                className={classes.stockIcon}
                            />
                            {getTranslatedTitleFromEvent(this, event)}
                        </div>
                        <div className={classes.stockItemLevels}>
                            <div className={classes.stockItemGraph}>
                                <BoatOnSpeedometer
                                    inverted={
                                        !equipment?.isInvertedFillingLevel
                                    }
                                    forceNormalRender
                                    removeGraduate
                                    minValue={0}
                                    maxValue={100}
                                    value={value}
                                />
                            </div>
                            <div className={classes.stockItemValues}>
                                <div
                                    className={
                                        equipment?.isInvertedFillingLevel
                                            ? value > 75
                                                ? `${classes.stockPercent} ${classes.redColor}`
                                                : classes.stockPercent
                                            : value < 25
                                            ? `${classes.stockPercent} ${classes.redColor}`
                                            : classes.stockPercent
                                    }
                                >
                                    {`${value}%`}
                                </div>
                                <div className={classes.stockCapacity}>
                                    {`${optionnalDetail?.capacity ||
                                        '0'} ${unit || ''}`}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            })

        return (
            <div className={classes.content}>
                {!profile && <HourAlert />}
                <div className={classes.infos}>
                    <div className={classes.boat}>
                        <div className={classes.headerBoat}>
                            <div className={classes.titleAndButton}>
                                <Typography className={classes.title}>
                                    {this.displayText('boat')} - {boat.name}
                                </Typography>
                                <BoatOnBlock
                                    permissions={{
                                        action: Actions.Delete,
                                        entityName: PermissionsEntities.Boat,
                                    }}
                                    children={
                                        <DeleteIcon
                                            className={classes.delete}
                                            onClick={() =>
                                                this.deleteBoat({
                                                    boatId: boat.id,
                                                })
                                            }
                                        />
                                    }
                                />
                            </div>
                            <BoatOnBlock
                                permissions={{
                                    action: Actions.Create,
                                    entityName: PermissionsEntities.Boat,
                                }}
                                children={
                                    <Button
                                        classNameOverride={classes.buttonAdd}
                                        variant="contained"
                                        color="primary"
                                        onClick={
                                            subscriptions &&
                                            userActions.checkSubscriptionPage(
                                                subscriptions,
                                                1,
                                            )
                                                ? this.openSubNewBoat
                                                : this.openNotSub
                                        }
                                        label={this.displayText('addBoat')}
                                        endIcon={
                                            <AddIcon
                                                className={classes.buttonSvg}
                                            />
                                        }
                                    />
                                }
                            />
                        </div>
                        <div className={classes.textAndField}>
                            <BoatOnBlock
                                permissions={{
                                    action: Actions.Update,
                                    entityName: PermissionsEntities.Boat,
                                }}
                                children={
                                    <TextField
                                        label={this.displayText('boatName')}
                                        id="name"
                                        margin="dense"
                                        required
                                        variant="outlined"
                                        placeholder="BoatOn"
                                        value={this.state.boat.name || ''}
                                        onChange={e =>
                                            this.handleChange(
                                                e.target.value,
                                                'name',
                                            )
                                        }
                                        className={classes.field}
                                        onBlur={() => fixIosHeaderBug()}
                                    />
                                }
                            />
                        </div>
                        <div className={classes.textAndField}>
                            <div className={classes.firstPart}>
                                <BoatOnBlock
                                    permissions={{
                                        action: Actions.Update,
                                        entityName: PermissionsEntities.Boat,
                                    }}
                                    children={
                                        <TextField
                                            label={this.displayText('boatType')}
                                            id="boatTypeId"
                                            margin="dense"
                                            required
                                            select
                                            variant="outlined"
                                            value={boat.boatTypeId}
                                            onChange={e =>
                                                this.handleChange(
                                                    e.target.value,
                                                    'boatTypeId',
                                                )
                                            }
                                            className={
                                                classes.lineWithMultiField
                                            }
                                            onBlur={() => fixIosHeaderBug()}
                                        >
                                            <MenuItem disabled value={-1}>
                                                <em>
                                                    {this.displayText(
                                                        'chooseBoat',
                                                    )}
                                                </em>
                                            </MenuItem>
                                            {boatTypes
                                                ?.filter(
                                                    t =>
                                                        t.translation !==
                                                            null &&
                                                        t.description !== null,
                                                )
                                                .map(type => (
                                                    <MenuItem
                                                        key={type.id}
                                                        value={type.id}
                                                    >
                                                        {this.displayTextApi(
                                                            type.translation,
                                                        ).toLowerCase()}
                                                    </MenuItem>
                                                ))}
                                        </TextField>
                                    }
                                />
                            </div>
                            <div className={classes.secondPart}>
                                <BoatOnBlock
                                    permissions={{
                                        action: Actions.Update,
                                        entityName: PermissionsEntities.Boat,
                                    }}
                                    children={
                                        <BoatOnNumberField
                                            label={this.displayText('boatYear')}
                                            id="year"
                                            margin="dense"
                                            required
                                            variant="outlined"
                                            placeholder="2000"
                                            value={boat.year || ''}
                                            onChange={e =>
                                                this.handleChange(
                                                    e.target.value !== ''
                                                        ? parseInt(
                                                              e.target.value,
                                                          )
                                                        : null,
                                                    'year',
                                                )
                                            }
                                            className={
                                                classes.lineWithMultiField
                                            }
                                            onBlur={() => fixIosHeaderBug()}
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <BoatOnBlock
                            permissions={{
                                action: Actions.Update,
                                entityName: PermissionsEntities.Boat,
                            }}
                            children={
                                <AutoComplete
                                    page="BoatInfoPageMaker"
                                    type="maker"
                                    searchResult={searchResultMaker}
                                    handleListSelection={
                                        this.handleListSelection
                                    }
                                />
                            }
                        />

                        <div className={classes.textAndField}>
                            <div className={classes.firstPart}>
                                <BoatOnBlock
                                    permissions={{
                                        action: Actions.Update,
                                        entityName: PermissionsEntities.Boat,
                                    }}
                                    children={
                                        <TextField
                                            label={this.displayText(
                                                'boatMaker',
                                            )}
                                            id="maker"
                                            margin="dense"
                                            required
                                            variant="outlined"
                                            placeholder="Beneteau"
                                            value={boat.maker || ''}
                                            onChange={e =>
                                                this.handleChange(
                                                    e.target.value,
                                                    'maker',
                                                )
                                            }
                                            className={
                                                classes.lineWithMultiField
                                            }
                                            onBlur={() => fixIosHeaderBug()}
                                        />
                                    }
                                />
                            </div>

                            <div className={classes.secondPart}>
                                <BoatOnBlock
                                    permissions={{
                                        action: Actions.Update,
                                        entityName: PermissionsEntities.Boat,
                                    }}
                                    children={
                                        <TextField
                                            label={this.displayText(
                                                'boatModel',
                                            )}
                                            id="model"
                                            margin="dense"
                                            required
                                            variant="outlined"
                                            value={boat.model || ''}
                                            onChange={e =>
                                                this.handleChange(
                                                    e.target.value,
                                                    'model',
                                                )
                                            }
                                            className={
                                                classes.lineWithMultiField
                                            }
                                            onBlur={() => fixIosHeaderBug()}
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className={classes.textAndField}>
                            <BoatOnBlock
                                permissions={{
                                    action: Actions.Update,
                                    entityName: PermissionsEntities.Boat,
                                }}
                                children={
                                    <TextField
                                        label={this.displayText('pontoon')}
                                        margin="dense"
                                        variant="outlined"
                                        value={boat.pontoon || ''}
                                        onChange={e =>
                                            this.handleChange(
                                                e.target.value,
                                                'pontoon',
                                            )
                                        }
                                        className={classes.field}
                                        onBlur={() => fixIosHeaderBug()}
                                    />
                                }
                            />
                            <BoatOnBlock
                                permissions={{
                                    action: Actions.Update,
                                    entityName: PermissionsEntities.Boat,
                                }}
                                children={
                                    <TextField
                                        label={this.displayText('landingStage')}
                                        margin="dense"
                                        variant="outlined"
                                        value={boat.landingStage || ''}
                                        onChange={e =>
                                            this.handleChange(
                                                e.target.value,
                                                'landingStage',
                                            )
                                        }
                                        className={classes.field}
                                        onBlur={() => fixIosHeaderBug()}
                                    />
                                }
                            />
                        </div>
                        <div className={`${classes.textAndField} smVertical`}>
                            <div className={classes.leftField}>
                                <BoatOnBlock
                                    permissions={{
                                        action: Actions.Update,
                                        entityName: PermissionsEntities.Boat,
                                    }}
                                    children={
                                        <TextField
                                            label={this.displayText(
                                                'boatRegistration',
                                            )}
                                            id="serialNumber"
                                            margin="dense"
                                            variant="outlined"
                                            placeholder="XX00000"
                                            value={boat.serialNumber || ''}
                                            onChange={e =>
                                                this.handleChange(
                                                    e.target.value,
                                                    'serialNumber',
                                                )
                                            }
                                            className={classes.field}
                                            onBlur={() => fixIosHeaderBug()}
                                        />
                                    }
                                />
                            </div>
                            <div className={classes.rightField}>
                                <BoatOnBlock
                                    permissions={{
                                        action: Actions.Update,
                                        entityName: PermissionsEntities.Boat,
                                    }}
                                    children={
                                        <TextField
                                            label={this.displayText(
                                                'mmsiNumber',
                                            )}
                                            margin="dense"
                                            variant="outlined"
                                            placeholder="123456789"
                                            value={boat.mmsi || ''}
                                            onChange={e =>
                                                this.handleChange(
                                                    e.target.value,
                                                    'mmsi',
                                                )
                                            }
                                            className={classes.field}
                                            onBlur={() => fixIosHeaderBug()}
                                            inputProps={{
                                                pattern: '[0-9]*',
                                                inputMode: 'numeric',
                                            }}
                                            error={
                                                boat.mmsi &&
                                                boat.mmsi.length !== 9
                                            }
                                            onKeyDown={e => {
                                                if (
                                                    /[^0-9]/.test(e.key) &&
                                                    e.key !== 'Backspace' &&
                                                    e.key !== 'Tab' &&
                                                    !e.ctrlKey &&
                                                    !e.metaKey
                                                )
                                                    e.preventDefault()
                                            }}
                                            onPaste={e => {
                                                // Récupérer le texte collé
                                                const paste = (
                                                    e.clipboardData ||
                                                    window.clipboardData
                                                ).getData('text')
                                                // Valider le contenu du presse-papiers
                                                if (!/^[0-9]*$/.test(paste)) {
                                                    e.preventDefault() // Empêcher le collage s'il contient des caractères non numériques
                                                }
                                            }}
                                        />
                                    }
                                />
                                <BoatOnBlock
                                    permissions={{
                                        action: Actions.Update,
                                        entityName: PermissionsEntities.Boat,
                                    }}
                                    children={
                                        <TextField
                                            label={this.displayText(
                                                'imoNumber',
                                            )}
                                            margin="dense"
                                            variant="outlined"
                                            placeholder="IMO 1234567"
                                            value={boat.imo || ''}
                                            onChange={e =>
                                                this.handleChange(
                                                    e.target.value,
                                                    'imo',
                                                )
                                            }
                                            className={classes.field}
                                            onBlur={() => fixIosHeaderBug()}
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className={`${classes.textAndField} smVertical`}>
                            <div className={classes.searchAddress}>
                                <BoatOnBlock
                                    permissions={{
                                        action: Actions.Update,
                                        entityName: PermissionsEntities.Boat,
                                    }}
                                    children={
                                        <AddressSearch
                                            label={this.displayText(
                                                'homePortOrStorage',
                                            )}
                                            setAddress={this.setAddress}
                                            fullAddressInfo={
                                                boat?.address?.fullText || ''
                                            }
                                            style={{ width: '100%' }}
                                        />
                                    }
                                />
                            </div>
                            <BoatOnBlock
                                permissions={{
                                    action: Actions.Update,
                                    entityName: PermissionsEntities.Boat,
                                }}
                                children={
                                    <MuiAutocomplete
                                        autocomplete
                                        className={classes.field}
                                        options={COUNTRIES_OPTIONS}
                                        freeSolo
                                        getOptionLabel={this.getOptionLabel}
                                        getOptionKey={option =>
                                            option !== null &&
                                            typeof option === 'object'
                                                ? option.key
                                                : null
                                        }
                                        filterOptions={(options, state) => {
                                            const result = defaultFilterOptions(
                                                options,
                                                state,
                                            )
                                            return result.length ===
                                                COUNTRIES_OPTIONS.length
                                                ? result.sort((a, b) =>
                                                      a.key === 'france'
                                                          ? -1
                                                          : b.key === 'france'
                                                          ? 1
                                                          : 0,
                                                  )
                                                : [
                                                      this.displayText(
                                                          'pressToValid',
                                                      ),
                                                      ...result,
                                                  ]
                                        }}
                                        getOptionDisabled={option =>
                                            option ===
                                            this.displayText('pressToValid')
                                        }
                                        renderInput={params => (
                                            <TextField
                                                {...params}
                                                className={
                                                    classes.autoCompleteInput
                                                }
                                                label={this.displayText('flag')}
                                                margin="dense"
                                                variant="outlined"
                                                onBlur={() => fixIosHeaderBug()}
                                            />
                                        )}
                                        isOptionEqualToValue={(option, value) =>
                                            option.key === value
                                        }
                                        onChange={this.setEnsign}
                                        value={this.getOptionLabel(boat.ensign)}
                                    />
                                }
                            />
                        </div>
                        <div className={classes.textAndField}>
                            <BoatOnBlock
                                permissions={{
                                    action: Actions.Update,
                                    entityName: PermissionsEntities.Boat,
                                }}
                                children={
                                    <TextField
                                        label={this.displayText(
                                            'boatMoreInformations',
                                        )}
                                        id="description"
                                        margin="dense"
                                        variant="outlined"
                                        placeholder={this.displayText(
                                            'importantInformations',
                                        )}
                                        multiline
                                        value={
                                            this.state.boat.description || ''
                                        }
                                        onChange={e => {
                                            this.handleChange(
                                                e.target.value,
                                                'description',
                                            )
                                        }}
                                        className={classes.field}
                                        onBlur={() => fixIosHeaderBug()}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <Divider classes={{ root: classes.divider }} />
                    <div className={classes.fillingLevelContainer}>
                        <div>{this.displayText('fillingLevelTitle')}</div>
                        <Button
                            onClick={() => this.openModal('editFillingLevel')}
                            style={{ gridArea: 'stock-edit-btn' }}
                            classNameOverride={classes.updateButton}
                            type="text"
                            startIcon={
                                <EditIcon className={classes.buttonSvg} />
                            }
                            label={this.displayText('updateFilligLevel')}
                        />
                        <div className={classes.stockContainer}>
                            {displayLevels}
                        </div>
                    </div>
                    <Divider classes={{ root: classes.divider }} />
                    <div className={classes.WorkingHoursContainer}>
                        <div>{this.displayText('workingHoursTitle')}</div>
                        <Button
                            onClick={() => this.openModal('editWorkingHours')}
                            style={{ gridArea: 'worktime-edit-btn' }}
                            classNameOverride={classes.updateButton}
                            type="text"
                            startIcon={
                                <EditIcon className={classes.buttonSvg} />
                            }
                            label={this.displayText('workingHours')}
                        />
                        <div className={classes.worktimeContainer}>
                            {displayWokingEquipment}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const { classes, user, loading, loadingTypes, loadingBoat } = this.props
        const {
            openModalDeleteBoat,
            openModalConnection,
            boats,
            boat,
            selectedModal,
            equipmentInUse,
            equipmentCapacities,
        } = this.state

        if (loading !== 0 || loadingTypes !== 0 || loadingBoat !== 0)
            return this.renderLoading(`100px`, `100px`, true)

        if (user === null) {
            return (
                <ModalConnection
                    open={openModalConnection}
                    handleClose={() => {
                        this.handleClose()
                    }}
                />
            )
        }
        if (!boat && boats?.length > 0) {
            const boatId = localStorage.getItem('boatIdSelect')
            const currentBoat = boats.filter(
                currentBoat => currentBoat.id === boatId,
            )
            if (currentBoat.length > 0) this.setState({ boat: currentBoat[0] })
        }
        if (!boat || (boat && !boat.id)) {
            return (
                <BoatOnBlock
                    permissions={{
                        action: Actions.Create,
                        entityName: PermissionsEntities.Boat,
                    }}
                    children={
                        <div className={classes.noBoatContainer}>
                            <Typography className={classes.title}>
                                {this.displayText('noBoat')}
                            </Typography>
                            <br />
                            <Button
                                className={classes.buttonAddCenter}
                                variant="contained"
                                color="primary"
                                onClick={() => this.valid('boatInfos')}
                                endIcon={
                                    <AddIcon className={classes.buttonSvg} />
                                }
                                label={this.displayText('addBoat')}
                            />
                            {this.renderNoBoatModal(true)}
                        </div>
                    }
                    childrenNoAccess={
                        <div className={classes.root2}>
                            <img
                                className={classes.backgroundImage}
                                src={catamaranicon}
                                alt="catamaranicon"
                            />
                            <p
                                onClick={this.openModal}
                                className={classes.noBoatText}
                            >
                                {this.displayText(`noBoat`)}
                            </p>
                        </div>
                    }
                />
            )
        }

        return (
            <div className={classes.root}>
                {loading === 0 && boat.boatTypeId
                    ? this._renderContent()
                    : this.renderLoading(`100px`, `100px`, true)}
                <ModalDeleteBoat
                    show={openModalDeleteBoat}
                    handleClose={this.deleteBoat}
                    type="boat"
                />
                <SaveBar
                    save={{ boat: this.state.boat }}
                    new={{ boat: this.state.boat }}
                    onCancel={save => this.setState({ boat: save.boat })}
                    onSave={this.save}
                    handleResetSave={this.handleResetSave}
                    resetSave={this.state.resetSave}
                    style={{ marginLeft: '20%' }}
                />
                {this.renderNoBoatModal(true)}

                <BoatOnModal
                    openCondition={selectedModal}
                    handleClose={this.closeModal}
                    modalCores={{
                        editFillingLevel: (
                            <EditFillingLevelsModal
                                equipmentCapacities={equipmentCapacities}
                                onClose={this.closeModal}
                            />
                        ),
                        editWorkingHours: (
                            <EditWorkingHoursModal
                                equipmentInUse={equipmentInUse}
                                onClose={this.closeModal}
                            />
                        ),
                    }}
                    maxWidth={{
                        editFillingLevel: 'sm',
                        editWorkingHours: 'sm',
                    }}
                    titles={{
                        editFillingLevel: this.displayText('updateFilligLevel'),
                        editWorkingHours: this.displayText('workingHoursTitle'),
                    }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const isMobile = window.innerWidth < 600
    const url = window.location.pathname
    const context = getContextFromUrl(url)

    return {
        isMobile,
        context,
        user: state.authentication.user,
        alert: state.alert,
        state: state.open,
        events: state.bob.events,
        userEquipments: state.types?.userEquipments || [],
        listBoats: state.fetch.listboatmodels,
        loading: state.bob.loading,
        bob: state.bob,
        boatTypes: state.types.boatTypes,
        loadingTypes: state.types.loading,
        loadingBoat: state.boat.loading,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        groupId: state.group.currentGroupId,
        boats: state.fetch.boats,
        numberBoats: state.group?.groupsMembers?.boats?.length,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(BoatInfoPage))
