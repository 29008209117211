export default theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'block',
        paddingLeft: 80,
        paddingRight: 80,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    containerBackBtn: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    backBtn: {
        gridArea: 'back-btn',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        fontWeight: 500,
        fontSize: 16,
    },
    header: {
        gridArea: 'header',
        marginBottom: 20,
    },
    backIcon: {
        rotate: '90deg',
        color: theme.palette.primary.main,
        width: '30px !important',
        height: '30px !important',
        '&:hover': {
            cursor: 'pointer',
        },
    },

    sharedButtons: {
        gridArea: 'shared-btns',
        display: 'flex',
        flexDirection: 'row',
        gap: 30,
        alignItems: 'center',
    },
    travelBtnContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 30,
    },
    travelIcons: {
        '&:hover': {
            cursor: 'pointer',
        },
    },
    outlinedBtn: {
        backgroundColor: 'white',
        color: 'black',
        '&:hover': {
            backgroundColor: 'white',
        },
    },
})
