export default theme => ({
    importantImage: {
        width: '100%',
        height: '100%',
        margin: 'auto',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: '1',
    },
    importantPdf: {
        height: '100%',
        margin: 'auto',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        zIndex: '1',
        width: 'calc(100% - 80px)',
    },
    pictures: {
        width: '100%',
        paddingTop: '56.25%',
        position: 'relative',
    },
    behind: {
        opacity: '0.5',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        content: "''",
        display: 'inline-block',
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
    },
    chevronLeft: {
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '2',
        height: '100%',
        width: '40px',
        minWidth: '40px',
    },
    chevronRight: {
        position: 'absolute',
        top: '0',
        right: '0',
        zIndex: '2',
        height: '100%',
        width: '40px',
        minWidth: '40px',
    },
    bottomBar: Object.assign(
        {
            width: '100%',
            position: 'absolute',
            bottom: '0px',
            paddingTop: '5px',
            paddingBottom: '5px',
            zIndex: '3',
            backgroundColor: 'rgba(255, 255, 255, .5)',
            alignItems: 'center',
        },
        theme.flex.rowCenter,
    ),
    imageBottom: {
        width: '40px',
        height: '40px',
        marginRight: '5px',
        marginLeft: '5px',
        cursor: 'pointer',
        userDrag: 'none',
        userSelect: 'none',
    },
    imageBottomSelected: {
        width: '44px',
        height: '44px',
        marginRight: '5px',
        marginLeft: '5px',
        border: '2px solid #3040a0',
        cursor: 'pointer',
        userDrag: 'none',
        userSelect: 'none',
    },
})
