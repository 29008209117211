import { bobOrdersConstants } from '../../constants/bobOrders.constants'
import { requestApi } from '../../services/api.service'
import { requestBobEvents } from '../bob.actions'

const fixTransaction = order => {
    if (order.transaction)
        order.transaction = {
            ...order.transaction,
            amountIt: order.transaction?.amountIt
                ? order.transaction.amountIt.toString()
                : undefined,
            amountEt: order.transaction?.amountEt
                ? order.transaction.amountEt.toString()
                : undefined,
        }
    return order
}

const postBobOrder = order => {
    return dispatch => {
        order = fixTransaction(order)
        dispatch({ type: bobOrdersConstants.LOAD_ORDERS })
        return requestApi(`/bob-orders`, `POST`, order).then(
            result => {
                dispatch({
                    type: bobOrdersConstants.POST_ORDER_SUCCESS,
                    payload: result,
                })
            },
            error => {
                console.error(error)
                dispatch({ type: bobOrdersConstants.ORDER_FAILURE })
                return null
            },
        )
    }
}

const updateBobOrder = (id, order) => {
    return dispatch => {
        order = fixTransaction(order)
        dispatch({ type: bobOrdersConstants.LOAD_ORDERS })
        return requestApi(`/bob-orders/${id}`, `PUT`, order).then(
            result => {
                dispatch({
                    type: bobOrdersConstants.UPDATE_ORDER_SUCCESS,
                    payload: result,
                })
            },
            error => {
                console.error(error)
                dispatch({ type: bobOrdersConstants.ORDER_FAILURE })
                return null
            },
        )
    }
}

const setOrderNextStep = (id, updatedOrder) => {
    return dispatch => {
        updatedOrder = fixTransaction(updatedOrder)
        dispatch({ type: bobOrdersConstants.LOAD_ORDERS })
        return requestApi(
            `/bob-orders/next-step/${id}`,
            `PUT`,
            updatedOrder,
        ).then(
            result => {
                dispatch({
                    type: bobOrdersConstants.UPDATE_ORDER_SUCCESS,
                    payload: result,
                })
                if (result.orderReceivedDate)
                    dispatch(
                        requestBobEvents(localStorage.getItem('boatIdSelect')),
                    )
            },
            error => {
                console.error(error)
                dispatch({ type: bobOrdersConstants.ORDER_FAILURE })
                return null
            },
        )
    }
}

const cancelOrder = id => {
    return dispatch => {
        dispatch({ type: bobOrdersConstants.LOAD_ORDERS })
        return requestApi(`/bob-orders/cancel/${id}`, `PUT`, {}).then(
            result => {
                dispatch({
                    type: bobOrdersConstants.UPDATE_ORDER_SUCCESS,
                    payload: result,
                })
            },
            error => {
                console.error(error)
                dispatch({ type: bobOrdersConstants.ORDER_FAILURE })
                return null
            },
        )
    }
}

const deleteBobOrder = id => {
    return dispatch => {
        dispatch({ type: bobOrdersConstants.LOAD_ORDERS })
        return requestApi(`/bob-orders/${id}`, `DELETE`).then(
            result => {
                dispatch({
                    type: bobOrdersConstants.DELETE_ORDER_SUCCESS,
                    payload: id,
                })
            },
            error => {
                console.error(error)
                dispatch({ type: bobOrdersConstants.ORDER_FAILURE })
                return null
            },
        )
    }
}

const getBobOrders = groupId => {
    return dispatch => {
        dispatch({ type: bobOrdersConstants.LOAD_ORDERS })
        return requestApi(`/bob-orders/group/${groupId}`, `GET`).then(
            result => {
                dispatch({
                    type: bobOrdersConstants.GET_ORDERS_SUCCESS,
                    payload: result,
                })
            },
            error => {
                console.error(error)
                dispatch({ type: bobOrdersConstants.ORDER_FAILURE })
                return null
            },
        )
    }
}

export const bobOrdersActions = {
    postBobOrder,
    updateBobOrder,
    setOrderNextStep,
    cancelOrder,
    deleteBobOrder,
    getBobOrders,
}
