import React from 'react'
import BoatOnModalCore from '../../../common/BoatOnModalCore'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/OrderModalCss'
import dictionary from './Dictionary/OrderModalDico'
import {
    Step,
    StepLabel,
    Stepper,
    Tooltip,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
} from '@material-ui/core'
import { Help as HelpIcon } from '@material-ui/icons'
import BoatOnAutoComplete from '../../../common/BoatOnAutoComplete'
import BoatOnNumberField from '../../../common/UsefullComponents/BoatOnNumberField'
import InputAdornment from '@material-ui/core/InputAdornment'
import DisplayerImgModal from '../../DisplayerImgModal'
import { API_URL } from '../../../../services/config.service'
import { bobOrdersActions } from '../../../../actions/bob/bobOrders.actions'
import { history } from '../../../../helpers'
import AppRoute from '../../../../constants/AppRoute'
import { getCurrentRouteKey } from '../../../../languages/LocalizerUtils'
import { Inventory } from '@mui/icons-material'
import BoatOnAppBar from '../../../common/BoatOnAppBar'
import ActivityComponent from '../../../common/ActivityComponent'
import { activityActions } from '../../../../actions/activity.actions'
import { commentsActions } from '../../../../actions/bob/comments.actions'
import BoatOnModal from '../../../common/BoatOnModal'
import BoatOnConfirmationModal from '../../../common/BoatOnConfirmationModal'

class OrderModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            selectedModal: false,
            order: this.props?.orderToEdit || {
                managerLinkId: null,
                transaction: null,
                operator: this.props.operator || null,
                orderForm: this.props.orderForm || null,
                orderNumber: this.props.orderNumber || null,
                orderDate: this.props.orderDate || null,
                inventoryDetails: this.props.inventoryDetails || [],
            },
            tax: 20,
            errorFields: [],
            tabIndex: 0,
        }

        this.topPage = React.createRef()

        this.save = this.save.bind(this)
        this.nextStep = this.nextStep.bind(this)
        this.cancelOrder = this.cancelOrder.bind(this)
        this.handleChangeField = this.handleChangeField.bind(this)
        this.handleChangeOrderField = this.handleChangeOrderField.bind(this)
        this.handleChangeTax = this.handleChangeTax.bind(this)
        this.handleChangeAmount = this.handleChangeAmount.bind(this)
        this.getUserName = this.getUserName.bind(this)
        this.deleteOrder = this.deleteOrder.bind(this)
        this.setTabIndex = this.setTabIndex.bind(this)
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
    }

    componentDidMount() {
        const { dispatch } = this.props
        const { order } = this.state

        if (order?.id) {
            dispatch(activityActions.getOrderActivity(order.id))
            dispatch(commentsActions.getOrderComments(order.id))
        }
    }

    componentDidUpdate(prevProps) {
        if (
            !prevProps.orderForm &&
            this.props.orderForm &&
            !this.state.orderForm
        ) {
            this.handleChangeOrderField('orderForm', this.props.orderForm)
        }
    }

    setTabIndex(tabIndex) {
        this.setState({ tabIndex })
    }

    handleChangeField(field, value) {
        this.setState({ [field]: value })
    }

    handleChangeOrderField(field, value) {
        const { order } = this.state

        this.setState({ order: { ...order, [field]: value || null } })
    }

    handleChangeTax(value) {
        const { order } = this.state

        this.setState(
            {
                tax: value,
            },
            () =>
                this.handleChangeAmount(
                    'amountEt',
                    order?.transaction?.amountEt ?? '',
                ),
        )
    }

    handleChangeAmount(field, value) {
        const { order, tax } = this.state

        value = value.toString().replace(',', '.')
        const transaction =
            field === 'amountIt'
                ? {
                      amountIt: value.trim(),
                      amountEt: parseFloat(value / (1 + tax / 100)).toFixed(2),
                  }
                : {
                      amountIt: parseFloat(value * (1 + tax / 100)).toFixed(2),
                      amountEt: value.trim(),
                  }

        this.setState({
            order: {
                ...order,
                transaction: {
                    ...order.transaction,
                    ...transaction,
                },
            },
        })
    }

    nextStep() {
        const { order } = this.state

        order.boat = {
            id: order?.boat?.id,
        }

        this.props.dispatch(
            bobOrdersActions.setOrderNextStep(this.state?.order?.id, order),
        )
        this.props.handleClose()
    }

    cancelOrder() {
        this.props.dispatch(bobOrdersActions.cancelOrder(this.state.order?.id))
        this.props.handleClose()
    }

    deleteOrder() {
        this.props.dispatch(
            bobOrdersActions.deleteBobOrder(this.state.order.id),
        )
        this.props.handleClose()
    }

    save() {
        const { order } = this.state
        const { boat } = this.props

        if (!order.managerLinkId) {
            this.setState({ errorFields: ['managerLinkId'] })
        } else {
            const url = window.location.pathname
            const route = getCurrentRouteKey(
                url,
                this.getContext(),
                this.getMessages(),
            )
            order.boat = {
                id: order?.boat?.id || boat.id,
            }

            if (order.id)
                this.props.dispatch(
                    bobOrdersActions.updateBobOrder(order.id, order),
                )
            else this.props.dispatch(bobOrdersActions.postBobOrder(order))

            this.props.handleClose()

            if (route !== AppRoute.LogBook.Orders)
                this.historyPush(history, AppRoute.LogBook.Orders)
        }
    }

    getUserName(element) {
        const { linkedUsers } = this.props
        if (element?.user?.id) {
            let name = ``
            linkedUsers.forEach(link => {
                if (link?.user?.id === element?.user?.id) {
                    name = `${link?.user?.firstName} ${link?.user?.lastName}`
                }
            })
            return name
        } else if (element?.userSubscribe?.id && element?.userSubscribe?.mail) {
            return element?.userSubscribe?.mail
        }
        return null
    }

    filterUsers(opt, input) {
        const firstLast = opt?.user?.id
            ? `${opt?.user?.firstName} ${opt?.user?.lastName}`
            : null
        const lastFirst = opt?.user?.id
            ? `${opt?.user?.lastName} ${opt?.user?.firstName}`
            : null

        if (
            !input?.inputValue ||
            (opt?.user?.id &&
                ((firstLast &&
                    firstLast.toLowerCase().includes(input.inputValue)) ||
                    (lastFirst &&
                        lastFirst.toLowerCase().includes(input.inputValue)))) ||
            (opt?.userSubscribe?.id &&
                opt?.userSubscribe?.mail
                    .toLowerCase()
                    .includes(input.inputValue.toLowerCase()))
        ) {
            return true
        }
        return false
    }

    _renderResponsible() {
        const { errorFields } = this.state
        const { managerLinkId } = this.state.order
        const { classes, linkedUsers } = this.props

        const manager = linkedUsers.find(
            userLink => userLink.id === managerLinkId,
        )

        return (
            <BoatOnAutoComplete
                required
                classes={{
                    inputRootOverride: classes.autocompleteInput,
                }}
                error={
                    errorFields.find(field => field === managerLinkId) &&
                    !manager
                }
                helperText={
                    errorFields.find(field => field === managerLinkId) &&
                    !manager
                        ? this.displayText('errorManager')
                        : null
                }
                filterOptions={this.filterUsers}
                autoComplete
                useOneTag
                renderTagCondition={() => false}
                getOptionSelected={this.isOptionEqualToValue}
                margin="normal"
                value={manager ? [manager] : []}
                conditionAlready={option => managerLinkId === option.id}
                options={linkedUsers}
                disabled={this._isOrderCancelled(this.state.order)}
                label={
                    <>
                        {this.displayText('responsible')}
                        <Tooltip
                            title={this.displayText('helpResponsible')}
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <HelpIcon className={classes.helpIcon} />
                        </Tooltip>
                    </>
                }
                onChange={(_, value) =>
                    this.handleChangeOrderField('managerLinkId', value?.[0]?.id)
                }
                getElementToShow={this.getUserName}
                getName={this.getUserName}
            />
        )
    }

    _renderOperator() {
        const { classes } = this.props
        const { operator } = this.state.order

        return (
            <BoatOnAutoComplete
                required
                classes={{
                    inputRootOverride: classes.autocompleteInput,
                }}
                autoComplete
                useOneTag
                renderTagCondition={() => false}
                margin="normal"
                value={operator ? [operator] : []}
                options={[operator]}
                disabled
                label={
                    <>
                        {this.displayText('operator')}
                        <Tooltip
                            title={this.displayText('helpOperator')}
                            classes={{ tooltip: classes.tooltip }}
                        >
                            <HelpIcon className={classes.helpIcon} />
                        </Tooltip>
                    </>
                }
                getName={element => element}
                // filterOptions={this.filterUsers}
                // addButton={userActions.checkSubscriptionPage(subscriptions, 7)}
                // labelAdd={this.displayText('addLabelButton')}
                // onAddButtonClicked={() => this.handleOpenNewUser(true)}
            />
        )
    }

    _renderAmount() {
        const { classes } = this.props
        const { order, tax } = this.state

        return (
            <div className={classes.amountContainer}>
                <BoatOnNumberField
                    isDecimal
                    id="amountEt"
                    variant="outlined"
                    label={this.displayText('amountEt')}
                    margin="dense"
                    value={order?.transaction?.amountEt ?? ''}
                    onChange={e =>
                        this.handleChangeAmount('amountEt', e.target.value)
                    }
                    disabled={this._isOrderCancelled(this.state.order)}
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                        endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        classes: { root: classes.labelInput },
                    }}
                />
                <BoatOnNumberField
                    isDecimal
                    variant="outlined"
                    label={this.displayText('vat')}
                    margin="dense"
                    value={tax}
                    min={0}
                    max={100}
                    disabled={this._isOrderCancelled(this.state.order)}
                    onChange={e => this.handleChangeTax(e.target.value)}
                    className={classes.taxField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                        endAdornment: (
                            <InputAdornment position="end">%</InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        classes: { root: classes.labelInput },
                    }}
                />
                <BoatOnNumberField
                    isDecimal
                    id="amountIt"
                    variant="outlined"
                    label={this.displayText('amountIt')}
                    margin="dense"
                    disabled={this._isOrderCancelled(this.state.order)}
                    value={order?.transaction?.amountIt ?? ''}
                    onChange={e =>
                        this.handleChangeAmount('amountIt', e.target.value)
                    }
                    className={classes.textField}
                    InputProps={{
                        classes: {
                            input: classes.input,
                            marginDense: classes.marginInput,
                        },
                        endAdornment: (
                            <InputAdornment position="end">€</InputAdornment>
                        ),
                    }}
                    InputLabelProps={{
                        classes: {
                            root: classes.labelInput,
                        },
                    }}
                />
            </div>
        )
    }

    _renderOrderForm() {
        const { orderForm } = this.state.order

        if (!orderForm) return <></>

        return (
            <>
                <Typography style={{ fontSize: 16, margin: '10px 0px' }}>
                    {this.displayText('orderForm')}
                </Typography>
                <DisplayerImgModal
                    file={[
                        {
                            ...orderForm,
                            link: `${API_URL}/files/${orderForm.link}`,
                        },
                    ]}
                />
            </>
        )
    }

    _isOrderReceived(order) {
        return order?.orderStatusType?.id === 5
    }

    _isOrderCancelled(order) {
        return order?.orderStatusType?.id === 6
    }

    _renderStepper() {
        const { orderStatusTypes, classes } = this.props
        const { order } = this.state

        return (
            <Stepper
                className={classes.stepper}
                activeStep={
                    this._isOrderReceived(order)
                        ? 5
                        : this._isOrderCancelled(order)
                        ? 0
                        : order?.orderStatusType?.id - 1 || 0
                }
                alternativeLabel
                style={{ padding: '24px 0px' }}
            >
                {orderStatusTypes
                    .filter(
                        orderStatusType =>
                            (order.orderStatusType?.id === 6 &&
                                orderStatusType.id !== 5) ||
                            (order.orderStatusType?.id !== 6 &&
                                orderStatusType.id !== 6),
                    )
                    .map((orderStatusType, index) => (
                        <Step
                            key={this.displayTextApi(
                                orderStatusType.translation,
                            )}
                        >
                            <StepLabel
                                error={
                                    order.orderStatusType?.id === 6 &&
                                    index === 4
                                }
                            >
                                {this.displayTextApi(
                                    orderStatusType.translation,
                                )}
                            </StepLabel>
                        </Step>
                    ))}
            </Stepper>
        )
    }

    _renderGridItems() {
        const { classes, equipmentFamilyTypes } = this.props
        const { order } = this.state
        const familyTypes = new Set([])

        ;(order.inventoryDetails || []).forEach(item => {
            if (item.equipment?.equipmentFamily)
                familyTypes.add(item.equipment.equipmentFamily.id)
            if (item.part?.equipment?.equipmentFamily)
                familyTypes.add(item.part.equipment.equipmentFamily.id)
        })

        return (
            <Table>
                <TableHead className={classes.header}>
                    <TableCell>
                        {this.displayText('equipmentAndPart')}
                    </TableCell>
                    <TableCell>{this.displayText('model')}</TableCell>
                    <TableCell>{this.displayText('reference')}</TableCell>
                    <TableCell>{this.displayText('quantity')}</TableCell>
                </TableHead>
                <TableBody>
                    {[...familyTypes].map(familyTypeId => {
                        const inventoryItems = order.inventoryDetails.filter(
                            item =>
                                (item.equipment?.equipmentFamily &&
                                    item.equipment.equipmentFamily.id ===
                                        familyTypeId) ||
                                (item.part?.equipment?.equipmentFamily &&
                                    item.part.equipment.equipmentFamily.id ===
                                        familyTypeId),
                        )
                        const familyType = equipmentFamilyTypes.find(
                            eft => eft.id === familyTypeId,
                        )
                        return (
                            <>
                                <TableRow className={classes.familyRow}>
                                    <TableCell>
                                        {familyType?.type
                                            ? this.displayTextApi(
                                                  familyType?.type?.translation,
                                              ).toUpperCase()
                                            : inventoryItems.length > 0
                                            ? inventoryItems[0].equipment
                                                  ?.equipmentFamily
                                                  ?.userEquipmentFamily?.name ||
                                              inventoryItems[0].part?.equipment
                                                  ?.equipmentFamily
                                                  ?.userEquipmentFamily?.name
                                            : ''}
                                    </TableCell>
                                    <TableCell />
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                                {inventoryItems.map(item => (
                                    <TableRow className={classes.row}>
                                        <TableCell>
                                            {item.part
                                                ? item.part?.partType
                                                    ? this.displayTextApi(
                                                          item.part?.partType
                                                              ?.translation,
                                                      )
                                                    : item.part?.userPart?.name
                                                : item.equipment
                                                ? item.equipment?.equipmentType
                                                    ? this.displayTextApi(
                                                          item.equipment
                                                              ?.equipmentType
                                                              ?.translation,
                                                      )
                                                    : item.equipment
                                                          ?.userEquipment?.name
                                                : ''}
                                        </TableCell>
                                        <TableCell>{item.model}</TableCell>
                                        <TableCell>
                                            {item.internalReference}
                                        </TableCell>
                                        <TableCell>{item.quantity}</TableCell>
                                    </TableRow>
                                ))}
                            </>
                        )
                    })}
                </TableBody>
            </Table>
        )
    }

    _body() {
        const { ordersLoading, activities, classes } = this.props
        const { order, tabIndex } = this.state

        if (ordersLoading > 0) return this.renderLoading('100')

        const createdOrder = order?.orderStatusType?.id ? false : true

        return (
            <div ref={this.topPage}>
                {!createdOrder && this._renderStepper()}

                {!createdOrder && (
                    <BoatOnAppBar
                        tabIndex={tabIndex}
                        displayBodyOut={this.setTabIndex}
                        labels={[
                            this.displayText('listOrderTitle'),
                            this.displayText('informationsTitle'),
                            this.displayText('activityTitle'),
                        ]}
                    />
                )}

                {tabIndex === 0 && !createdOrder && (
                    <>{this._renderGridItems()}</>
                )}

                {(tabIndex === 1 || createdOrder) && (
                    <>
                        <div className={classes.responsibleOperatorRow}>
                            {this._renderResponsible()}
                            {this._renderOperator()}
                        </div>
                        {this._renderAmount()}
                        {this._renderOrderForm()}
                    </>
                )}

                {tabIndex === 2 && !createdOrder && (
                    <div className={classes.activityContainer}>
                        <ActivityComponent
                            activities={activities}
                            event={order}
                            eventType="order"
                            scrollToTop={() => {
                                this.topPage.current.scrollIntoView({
                                    behavior: 'smooth',
                                })
                            }}
                        />
                    </div>
                )}
            </div>
        )
    }

    _getTitleOnStep() {
        const { order } = this.state
        const { orderStatusTypes } = this.props

        if (!order?.orderStatusType?.id)
            return this.displayText('generatingOrderTitle')

        return this.displayTextApi(
            orderStatusTypes.find(
                orderStatusType =>
                    orderStatusType.id === order.orderStatusType.id,
            ).translation,
        )
    }

    openModal(modal) {
        this.setState({ selectedModal: modal })
    }

    closeModal() {
        this.setState({ selectedModal: false })
    }

    _getButtons() {
        const { ordersLoading } = this.props
        const { order } = this.state

        switch (order?.orderStatusType?.id) {
            case 1:
                return [
                    {
                        label: this.displayText('cancel'),
                        action: () => this.openModal('cancelConfirm'),
                        type: `cancel`,
                        disabled: ordersLoading > 0,
                    },
                    {
                        label: this.displayText('save'),
                        action: this.save,
                        type: `secondary`,
                        disabled: ordersLoading > 0,
                    },
                    {
                        label: this.displayText('valid'),
                        action: this.nextStep,
                        type: `primary`,
                        disabled: ordersLoading > 0,
                    },
                ]
            case 2:
                return [
                    {
                        label: this.displayText('cancel'),
                        action: () => this.openModal('cancelConfirm'),
                        type: `cancel`,
                        disabled: ordersLoading > 0,
                    },
                    {
                        label: this.displayText('save'),
                        action: this.save,
                        type: `secondary`,
                        disabled: ordersLoading > 0,
                    },
                    {
                        label: this.displayText('quoteReceived'),
                        action: this.nextStep,
                        type: `primary`,
                        disabled: ordersLoading > 0,
                    },
                ]
            case 3:
                return [
                    {
                        label: this.displayText('cancel'),
                        action: () => this.openModal('cancelConfirm'),
                        type: `cancel`,
                        disabled: ordersLoading > 0,
                    },
                    {
                        label: this.displayText('save'),
                        action: this.save,
                        type: `secondary`,
                        disabled: ordersLoading > 0,
                    },
                    {
                        label: this.displayText('validQuote'),
                        action: () => this.openModal('validOrderConfirm'),
                        type: `primary`,
                        disabled: ordersLoading > 0,
                    },
                ]
            case 4:
                return [
                    {
                        label: this.displayText('cancel'),
                        action: () => this.openModal('cancelConfirm'),
                        type: `cancel`,
                        disabled: ordersLoading > 0,
                    },
                    {
                        label: this.displayText('save'),
                        action: this.save,
                        type: `secondary`,
                        disabled: ordersLoading > 0,
                    },
                    {
                        label: this.displayText('orderReceived'),
                        action: this.nextStep,
                        type: `primary`,
                        disabled: ordersLoading > 0,
                    },
                ]
            case 5:
                return [
                    {
                        label: this.displayText('save'),
                        action: this.save,
                        type: `secondary`,
                        disabled: ordersLoading > 0,
                    },
                ]
            case 6:
                return [
                    {
                        label: this.displayText('delete'),
                        action: this.deleteOrder,
                        type: `primary`,
                        disabled: ordersLoading > 0,
                    },
                ]
            default:
                return [
                    {
                        label: this.displayText('send'),
                        action: this.save,
                        type: `primary`,
                        disabled: ordersLoading > 0,
                    },
                ]
        }
    }

    render() {
        const { selectedModal } = this.state

        return (
            <>
                {this._renderTitle(this._getTitleOnStep())}
                {this._renderBody({
                    body: this._body(),
                })}
                {this._renderActions(this._getButtons())}
                <BoatOnModal
                    openCondition={selectedModal}
                    handleClose={this.closeModal}
                    modalCores={{
                        cancelConfirm: (
                            <BoatOnConfirmationModal
                                onClose={this.closeModal}
                                callback={this.cancelOrder}
                                message={this.displayText('cancelConfirmText')}
                                override={{
                                    continue: this.displayText(
                                        'cancelConfirmBtn',
                                    ),
                                }}
                            />
                        ),
                        validOrderConfirm: (
                            <BoatOnConfirmationModal
                                onClose={this.closeModal}
                                callback={this.nextStep}
                                message={this.displayText(
                                    'validOrderConfirmText',
                                )}
                                override={{
                                    continue: this.displayText(
                                        'validOrderConfirmBtn',
                                    ),
                                }}
                            />
                        ),
                    }}
                    titles={{
                        cancelConfirm: this.displayText('cancelConfirmTitle'),
                        validOrderConfirm: this.displayText(
                            'validOrderConfirmTitle',
                        ),
                    }}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
        orderForm: state.bobOrder?.newOrderForm,
        ordersLoading: state.bobOrder?.loading,
        boat: state.bob.boat,
        orderStatusTypes: state.types.orderStatusTypes || [],
        orders: state.bobOrder.orders || [],
        equipmentFamilyTypes: state.types.userEquipmentFamilies || [],
        activities: state.activity.activity || [],
    }
}

export default connect(mapStateToProps)(withStyles(styles)(OrderModal))
