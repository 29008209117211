export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        [theme.breakpoints.down('sm')]: {
            marginTop: '0',
        },
    },
    iconButton: {
        marginLeft: 12,
        marginRight: 36,
    },
    drawerButton: {
        width: 45,
        height: 45,
        backgroundColor: '#303F9F',
        position: 'fixed',
        top: 30,
        zIndex: theme.zIndex.drawer + 2,
        borderTopRightRadius: 25,
        borderBottomRightRadius: 25,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 30,
    },
    drawerPaper: {
        position: 'relative',
        backgroundColor: '#303F9F',
        width: '240px',
        color: '#fff',
        [theme.breakpoints.down('sm')]: {
            marginTop: '62px',
            paddingBottom: 100,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
        },
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        whiteSpace: 'noWrap',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(9),
        [theme.breakpoints.down('sm')]: {
            width: theme.spacing(7),
        },
    },
    toolbar: {
        position: 'relative',
        width: 'calc(100%)',
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 24px)',
            padding: '0px 12px',
        },
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 12px)',
            padding: '0px 6px',
        },
    },
    toolbarRight: {
        marginRight: 0,
        marginLeft: 'auto',
    },
    button: {
        color: '#fff',
        paddingLeft: 0,
        paddingRight: 0,
        margin: 0,
    },
    scrollContent: {
        height: '100%',
        width: '100%',
        overflowY: 'auto',
        [theme.breakpoints.down('sm')]: {
            overflowY: 'visible',
        },
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    mainContent: {
        minHeight: '100vh',
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: '20px 20px 35px 20px',
        boxSizing: 'border-box',
    },
    boatSelecterContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',

        boxSizing: 'border-box',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    rowBreadCrumbs: {
        width: 'auto',
        marginBlock: '25px',
        alignSelf: 'flex-start',
    },
    rowBreadCrumbsWSideMenu: {
        width: 'auto',
        marginTop: '25px',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '60px',
        },
    },
    drawerContainer: {
        color: '#fff',
        position: 'fixed',
        width: theme.spacing(9),
        overflow: 'hidden',
        textWrap: 'nowrap',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    drawerContainerOpen: {
        width: 240,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    listMobile: {
        overflowY: 'auto',
        paddingBottom: 100,
    },
})
