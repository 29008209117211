export default theme => ({
    rootPrintable: { width: '100%', paddingBottom: '20px' },
    principalInfo: Object.assign(
        {
            width: 'calc(100% - 100px)',
            paddingRight: '50px',
            paddingLeft: '50px',
            [theme.breakpoints.down('sm')]: {
                padding: '0',
                width: 'calc(100%)',
            },
        },
        theme.flex.columnAlignCenter,
    ),
    rows: Object.assign({ width: '100%' }, theme.flex.row),
    rowDate: Object.assign(
        {
            width: 'calc(50% - 20px)',
            height: 'calc(100% - 20px)',
            padding: '10px',
        },
        theme.flex.column,
    ),
    verticalDivider: {
        backgroundColor: '#c4c4c4',
        height: '50px',
        margin: '10px',
    },
    imageParking: { width: 'calc(100% - 20px)', padding: '10px' },
    divider: {
        backgroundColor: '#c4c4c4',
        marginTop: '10px',
        marginBottom: '10px',
    },
    bold: { fontWeight: '500' },
    buttonChevron: Object.assign({ width: '100%' }, theme.flex.rowSpacebetween),
    flexRow: theme.flex.row,
    box: Object.assign(
        { width: 'calc(100% - 20px)', padding: '10px' },
        theme.column,
    ),
    boxTitle: Object.assign(
        { width: '100%', position: 'relative' },
        theme.column,
    ),
    title: Object.assign(
        { padding: '10px', textAlign: 'center' },
        theme.typography.title,
    ),
    subtitle: theme.typography.subtitle,
    particularInfo: { marginTop: '10px', marginBottom: '10px' },
    parkingMap: { width: '100%', marginBottom: '20px', marginTop: '20px' },
    cross: {
        position: 'absolute',
        height: '40px',
        top: 'calc(50% - 20px)',
        left: '2%',
        cursor: 'pointer',
    },
    test: {
        fontSize: ' 0.9em',
        marginTop: '2px',
    },
})
