import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import classNames from 'classnames'
import Moment from 'react-moment'
import styles from './Styles/ChatBoxCss'
import dictionary from './Dictionary/ChatBoxDico'
import { postData } from '../../../actions/api.actions'
import BoatOnComponent from '../BoatOnComponent'
import { API_URL } from '../../../services/config.service'
import CheckIcon from '@material-ui/icons/Check'
import SendIcon from '@material-ui/icons/Send'

class ChatBox extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            newMessage: null,
        }

        this.sendMessage = this.sendMessage.bind(this)
        this.filterMessage = this.filterMessage.bind(this)
    }

    componentDidMount() {
        this.scrollToBottom()
    }

    componentDidUpdate() {
        this.scrollToBottom()
    }

    handleChange(value, key) {
        this.setState({ [key]: value })
    }

    keyPressed(e) {
        // if enter key
        if (e.keyCode === 13) {
            this.sendMessage()
        }
    }

    scrollToBottom = () => {
        if (this.listContainer) {
            this.listContainer.scrollIntoView({ inline: 'end', block: 'end' })
        }
    }

    sortMessage(a, b) {
        if (a && a.Timestamp && b && b.Timestamp) {
            a = new Date(a.Timestamp)
            b = new Date(b.Timestamp)

            if (a > b) return 1
            if (a <= b) return -1
        }
        return 0
    }

    filterMessage(message) {
        if (message.InqueryID === this.props.selectedMessage.InqueryID)
            return true
        return false
    }

    sendMessage() {
        const messages = this.props.messages || []

        const receiverUserId = this.props.receiverUserId
        const inqueryreceiverId =
            this.props.inqueryReceiverId || messages[0].InqueryReceiverID
        const inqueryId = this.props.inqueryId || this.props.selectedMessage

        if (messages && this.state.newMessage) {
            // const data = new FormData()
            // data.append('message', this.state.newMessage)
            // data.append('inquery_id', inqueryId)
            // data.append('receiver_id', receiverUserId)
            // if (this.props.orderId) data.append('order_id', this.props.orderId)
            // data.append('inquery_receiver_id', inqueryreceiverId)
            // this.props
            //     .dispatch(postData(API_URL + '/messagecreate.php', data))
            //     .then(() => {
            //         this.handleChange(null, 'newMessage')
            //         this.props.update()
            //     })
        }
    }

    renderMessage(message, index) {
        const { classes, user } = this.props
        if (message) {
            const userMessage = message.UserMessage || message.MessageText

            if (
                (message.ReceiverID === user.UserID &&
                    message.MessageType !== '5') ||
                (message.SenderID === user.UserID &&
                    message.MessageType === '5')
            ) {
                return (
                    <div
                        className={classes.other}
                        ref={element => {
                            this.listContainer = element
                        }}
                        key={index}
                    >
                        <div className={classes.dateAndNameOther}>
                            <Typography style={{ fontWeight: 'bold' }}>
                                {message.ReceiverFirstName}&nbsp;
                            </Typography>
                            <Moment
                                format="DD/MM/YYYY"
                                className={classes.dateMessageOther}
                            >
                                {message.SenderRead
                                    ? message.SenderRead
                                    : message.Timestamp}
                            </Moment>
                        </div>
                        <Typography className={classes.borderMessageOther}>
                            {userMessage.indexOf('en cliquant ici.') !== -1
                                ? userMessage.replace('en cliquant ici.', '')
                                : userMessage}
                            {userMessage.indexOf('en cliquant ici.') !== -1 ? (
                                <a
                                    href="https://www.info.boaton.fr/reparer-mon-bateau"
                                    title="reparation"
                                >
                                    en cliquant ici.
                                </a>
                            ) : (
                                <></>
                            )}
                        </Typography>
                    </div>
                )
            } else if (message.MessageType !== '5')
                return (
                    <div
                        className={classes.me}
                        ref={element => {
                            this.listContainer = element
                        }}
                        key={index}
                    >
                        <div className={classes.dateAndNameMe}>
                            <Moment
                                format="DD/MM/YYYY"
                                className={classes.dateMessageMe}
                            >
                                {message.SenderRead
                                    ? message.SenderRead
                                    : message.Timestamp}
                            </Moment>
                            <Typography style={{ fontWeight: 'bold' }}>
                                &nbsp;{message.SenderFirstName}
                            </Typography>
                        </div>
                        <Typography className={classes.borderMessageMe}>
                            {userMessage}
                        </Typography>
                    </div>
                )
        }
        return <div />
    }

    renderSeen() {
        const { messages, user, classes } = this.props
        let senderMessage = []

        senderMessage = messages.filter(
            m => m.SenderID === user.UserID && m.MessageType !== '5',
        )
        if (senderMessage.length > 0) {
            if (senderMessage[senderMessage.length - 1].ReceiverRead) {
                const readDate = new Date(
                    senderMessage[senderMessage.length - 1].ReceiverRead,
                ).toLocaleString()
                return (
                    <div
                        className={classes.read}
                        ref={element => {
                            this.listContainer = element
                        }}
                    >
                        <CheckIcon color="secondary" />
                        <Typography>{this.displayText('readed')}</Typography>
                        <Typography>&nbsp;{readDate}</Typography>
                    </div>
                )
            } else {
                return (
                    <div
                        className={classes.read}
                        ref={element => {
                            this.listContainer = element
                        }}
                    >
                        <CheckIcon />
                        <Typography>{this.displayText('sent')}</Typography>
                    </div>
                )
            }
        }
        return <div />
    }

    render() {
        const { classes } = this.props
        const messages = this.props.messages || []
        const border = this.props.border === false ? false : true

        if (!messages) {
            return <div />
        }

        return (
            <div className={border ? classes.rootBorder : classes.root}>
                <div className={classes.messagesBox}>
                    {messages.sort(this.sortMessage).map((message, i) => (
                        <React.Fragment key={i}>
                            {this.renderMessage(message, i)}
                        </React.Fragment>
                    ))}
                    {this.renderSeen()}
                </div>
                <div className={this.props.classes.divInput}>
                    <input
                        className={classNames(
                            this.props.classes.input,
                            'form-control',
                        )}
                        type="text"
                        placeholder="message"
                        name="message"
                        value={this.state.newMessage || ''}
                        onKeyDown={e => {
                            this.keyPressed(e)
                        }}
                        onChange={e =>
                            this.handleChange(e.target.value, 'newMessage')
                        }
                    />
                    <Button
                        variant="contained"
                        color="secondary"
                        className={this.props.classes.validate}
                        onClick={this.sendMessage}
                    >
                        <Typography className={classes.sendText}>
                            {this.displayText('send')}
                        </Typography>
                        <SendIcon
                            width="50px"
                            height="50px"
                            className={classes.sendIcon}
                        />
                    </Button>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        loading: state.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ChatBox))
