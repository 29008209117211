export default {
    notAttributed: {
        FR: 'Non attribué',
        EN: 'Not attributed',
    },
    search: {
        FR: 'Rechercher',
        EN: 'Search',
    },
}
