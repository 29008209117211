import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import { createTheme } from '@material-ui/core/styles'

const breakpoints = createBreakpoints({ tablet: true })

const theme = createTheme({
    palette: {
        primary: {
            main: '#3040a0',
            contrastText: '#fff',
        },
        secondary: {
            main: '#fec107',
            contrastText: '#000',
        },
        action: {
            active: '#3040a0',
            contrastText: '#fff',
        },
        active: {
            main: '#3040a0',
            contrastText: '#fff',
        },
        accepted: {
            main: '#1c856a',
            contrastText: '#000',
        },
        declined: {
            main: '#ef5350',
            contrastText: '#000',
        },
        waiting: {
            main: '#c4c4c4',
            contrastText: '#000',
        },
        warning: {
            main: '#F4BA5C',
            contrastText: '#fff',
        },
        error: {
            main: '#f44336',
            contrastText: '#fff',
        },
        info: {
            main: '#5FA7F6',
            contrastText: '#fff',
        },
    },
    button: {
        primary: {
            backgroundColor: '#3040a0',
            contrastText: '#fff',
            '&:hover': {
                backgroundColor: '#fec107',
                color: 'black',
            },
        },
        secondary: {
            backgroundColor: '#fec107',
            contrastText: '#000',
            '&:hover': {
                backgroundColor: '#3040a0',
                color: 'black',
            },
        },
    },
    typography: {
        fontFamily: 'Roboto',
        fontSize: 14,
        useNextVariants: true,
        button: {
            fontSize: '1.2rem',
        },
        body1: {
            fontSize: '14px',
            lineHeight: `22px`,
        },
        body2: {
            fontSize: '12px',
        },
        subtitle1: {
            fontSize: '1.25rem',
            fontFamily: 'Roboto',
            fontWeight: '500',
            lineHeight: 1.6,
        },
        subtitle2: {
            fontSize: '16px',
            fontFamily: 'Roboto',
            fontWeight: '500',
            lineHeight: 1.6,
        },
        h4: {
            fontWeight: '500',
            fontSize: '12px',
        },
        title: {
            fontSize: '28px',
            fontFamily: 'Roboto',
            [breakpoints.down('sm')]: {
                fontSize: '20px',
            },
        },
        subtitle: {
            fontSize: '22px',
            fontFamily: 'Roboto',
            fontWeight: '500',
            [breakpoints.down('sm')]: {
                fontSize: '16px',
                fontWeight: '500',
            },
        },
        minititle: {
            fontSize: '14px',
            fontFamily: 'Roboto',
            fontWeight: '500',
            [breakpoints.down('sm')]: {
                fontSize: '14px',
                fontWeight: '500',
            },
        },
    },
    boxBoatOn: {
        border: '1px solid #c4c4c4',
        borderRadius: '5px',
        padding: '20px',
        width: 'calc(100% - 40px)',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        [breakpoints.down('sm')]: {
            padding: '12px',
            width: 'calc(100% - 24px)',
        },
    },
    flex: {
        columnSpacebetween: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        columnSpacearound: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
        },
        columnCenter: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
        },
        rowSpacebetween: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
        rowSpacearound: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
        },
        rowCenter: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
        },
        rowEnd: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
        },
        rowStart: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
        },
        columnAlignSpacebetween: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-between',
        },
        columnAlignSpacearound: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'space-around',
        },
        columnAlignCenter: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        },
        columnAlignEnd: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
        },
        rowAlignSpacebetween: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'space-between',
        },
        rowAlignSpacearound: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'space-around',
        },
        rowAlignCenter: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        rowAlignEnd: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
        },
    },
    components: {
        MuiTypography: {
            variants: [
                {
                    props: { variant: `subtitle` },
                    style: {
                        fontSize: '22px',
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        [breakpoints.down('sm')]: {
                            fontSize: '16px',
                            fontWeight: '500',
                        },
                    },
                },
            ],
        },
    },
    overrides: {
        MuiInputLabel: {
            root: {
                '&$focused': {
                    color: '#3040a0 !important',
                },
            },
        },
        MuiOutlinedInput: {
            root: {
                '&$focused $notchedOutline': {
                    borderColor: '#3040a0 !important',
                },
            },
        },
        MuiPaper: {
            root: {
                '& .MuiClock-clock .MuiClock-pin': {
                    backgroundColor: '#3040a0',
                },
                '& .MuiClock-clock .MuiClockPointer-root': {
                    backgroundColor: '#3040a0',
                },
                '& .MuiClock-clock .MuiClockPointer-thumb': {
                    borderColor: '#3040a0',
                    backgroundColor: 'rgba(48, 64, 160, 0.6)',
                },
                '& .MuiPickersLayout-actionBar > button': {
                    color: '#3040a0',
                },
            },
        },
    },
})

export default theme
