import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../common/BoatOnModalCore'
import {
    Button,
    Chip,
    IconButton,
    Popover,
    TextField,
    Tooltip,
    Typography,
    withStyles,
    Checkbox,
    Divider,
    DialogActions,
} from '@material-ui/core'
import styles from './WorkTimeModalCss'
import UserSelector from '../../../../common/UserSelector/UserSelector'
import dictionary from '../Dictionary/WorkTimeModalDico'
import { MobileTimePicker } from '@mui/x-date-pickers'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import HelpIcon from '@material-ui/icons/Help'
import dayjs from 'dayjs'
import { Autocomplete } from '@material-ui/lab'
import { workTimeActions } from '../../../../../actions/bob/workTime.actions'
import BoatOnDateSelector from '../../../../common/UsefullComponents/BoatOnDateSelector'
import { getContextFromUrl } from '../../../../../languages/LocalizerUtils'
import BoatOnBlock from '../../../../common/Blocks/BoatOnBlock'
import { Button as BobButton } from '../../../../common/BoatOnButton'
import { commentsActions } from '../../../../../actions/bob/comments.actions'
import { activityActions } from '../../../../../actions/activity.actions'
import { settingsActions } from '../../../../../actions/bob/settings.actions'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import { requestApi } from '../../../../../services/api.service'
import { absencesActions } from '../../../../../actions/bob/absences.actions'
import BoatOnAlert from '../../../../common/UsefullComponents/BoatOnAlert'
import { Actions, PermissionsEntities } from '../../../../../constants'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)

class WorkTimeModal extends BoatOnModalCore {
    constructor(props) {
        super(props)

        this.state = {
            tabIndex: 0,
            openSettingIndex: 0,
            dateSelectedIndex: null,
            responsibleLink: props?.workTime?.responsibleLink || undefined,
            currentUserLink: this._findUserLink(),
            userLink:
                this.getUserLinkById(props?.workTime?.userLinkId) ||
                this._findUserLink(),
            comment: props?.workTime?.comment || undefined,
            workTimes: props.workTime
                ? [props.workTime]
                : [
                      {
                          startTime: null,
                          endTime: null,
                          breakTimes: [],
                          delimitedDate: { startDate: null, endDate: null },
                          //   génerer un id temporaire pour pouvoir filtrer plus facilement
                          temp_id: Math.random()
                              .toString(36)
                              .substr(2, 9),
                      },
                  ], // For adding
            errorFields: [],
            isCalendarOpen: false,
            isSameHours: false,
            userGroup: null,
            splitWorkTimes: [],
            byManager: false,
            //Utilisé pour connaitre les worktimes et les absences de l'utilisateur sélectionné dans la modale
            selectedUserWorkTimes: null,
            selectedUserAbsences: null,
        }

        this.dictionary = dictionary

        this.daysOfWeek = [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
        ]

        this.renderTags = this.renderTags.bind(this)
        this.closeDateRange = this.closeDateRange.bind(this)
        this.openDateRange = this.openDateRange.bind(this)
        this.deleteDateOfWorkTimes = this.deleteDateOfWorkTimes.bind(this)
        this.isDisableDay = this.isDisableDay.bind(this)
        this.getDisabledDates = this.getDisabledDates.bind(this)
        this.getMaxTime = this.getMaxTime.bind(this)
        this.getAvailableTimeSlotsForOneDay = this.getAvailableTimeSlotsForOneDay.bind(
            this,
        )
        this.getAvailableTimeForDateRange = this.getAvailableTimeForDateRange.bind(
            this,
        )
        this.getNextEvent = this.getNextEvent.bind(this)
        this.handleTimeChange = this.handleTimeChange.bind(this)
        this.addBreak = this.addBreak.bind(this)
        this.deleteBreak = this.deleteBreak.bind(this)
        this.changeOpenedDay = this.changeOpenedDay.bind(this)
        this.saveWorkTime = this.saveWorkTime.bind(this)
        this.onlyTime = this.onlyTime.bind(this)
        this.earliest = this.earliest.bind(this)
        this.latest = this.latest.bind(this)
        this.splitEvents = this.splitEvents.bind(this)
        this._sortAndSplitData = this._sortAndSplitData.bind(this)
        this.onUserChange = this.onUserChange.bind(this)
        this.onManagerChange = this.onManagerChange.bind(this)
        this.shouldDisableTimeStart = this.shouldDisableTimeStart.bind(this)
        this.shouldDisableTimeEnd = this.shouldDisableTimeEnd.bind(this)
    }

    componentDidMount() {
        const {
            workTimes,
            absences,
            workTime,
            currentGroupId,
            workTimesManager,
        } = this.props

        const { userLink } = this.state

        if (!workTimes) {
            this.props.dispatch(workTimeActions.getWorkTimes(userLink.id))
        }
        if (!absences) {
            this.props.dispatch(
                absencesActions.requestUserAbsences(userLink.id),
            )
        }

        if (!workTimesManager) {
            this.props.dispatch(
                workTimeActions.getWorkTimesByManager(userLink.id),
            )
        }

        if (workTimes || absences) {
            this._sortAndSplitData(workTimes, absences)
        }
        if (workTime?.id) {
            this.props.dispatch(
                commentsActions.getWorkTimeComments(workTime.id),
            )
            this.props.dispatch(
                activityActions.getWorkTimeActivity(workTime.id),
            )
        }

        if (currentGroupId) {
            this.props.dispatch(
                settingsActions.getGroupWorkTimeSettings({
                    groupId: currentGroupId,
                }),
            )
        }

        // Si l'utilisateur actuel a des worktimes où il est assigné en tant que manager
        // Alors on lui affichera le champ "Utilisateur" pour qu'il puisse saisir des worktimes pour un autre utilisateur
        if (workTimesManager && workTimesManager.length > 0) {
            this.setState({
                byManager: true,
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { workTimeGroups, workTimes, absences } = this.props
        const {
            selectedUserAbsences,
            selectedUserWorkTimes,
            userLink,
        } = this.state

        if (workTimeGroups !== prevProps.workTimeGroups) {
            const userGroup =
                workTimeGroups.find(wtg =>
                    wtg.userLinks.find(ul => ul.id === userLink.id),
                ) ?? workTimeGroups.find(wtg => wtg.byDefault)

            this.setState({
                userGroup: userGroup,
            })
        }

        if (workTimes !== prevProps.workTimes) {
            this.setState({
                splitWorkTimes: this.splitEvents(workTimes),
            })
        }
        if (absences !== prevProps.absences) {
            this.setState({
                splitAbsences: this.splitEvents(absences),
            })
        }

        if (
            selectedUserAbsences !== prevState.selectedUserAbsences ||
            selectedUserWorkTimes !== prevState.selectedUserWorkTimes
        ) {
            this._sortAndSplitData(selectedUserWorkTimes, selectedUserAbsences)
        }
    }

    _sortAndSplitData(workTimes, absences) {
        const { workTime } = this.props
        const data = (workTimes || []).concat(absences || []).sort((d1, d2) => {
            return d1.delimitedDate.startDate < d2.delimitedDate.startDate
                ? 1
                : -1
        })

        if (data?.[0]?.responsibleLink?.id && !workTime?.id) {
            this.setState({
                event: {
                    ...this.state.event,
                    responsibleLink: data[0].responsibleLink,
                },
                responsibleLink: data[0].responsibleLink,
            })
        }

        if (workTimes) {
            this.setState({
                splitWorkTimes: this.splitEvents(workTimes),
            })
        }

        if (absences) {
            this.setState({
                splitAbsences: this.splitEvents(absences),
            })
        }
    }

    _addErrors(fields) {
        this.setState({
            errorFields: fields,
        })
    }

    _handleError() {
        const { responsibleLink, workTimes, userLink } = this.state
        const errors = []

        if (!userLink) {
            errors.push('userLink')
        }

        if (!responsibleLink) {
            errors.push('responsibleLink')
        }

        if (workTimes.length === 0) {
            errors.push('workTimes')
        }

        for (const workTime of workTimes) {
            if (!workTime?.startTime) {
                errors.push('startTime')
            }

            if (!workTime?.endTime) {
                errors.push('endTime')
            }

            if (!workTime?.delimitedDate?.startDate) {
                errors.push('startDate')
            }

            if (!workTime?.delimitedDate?.endDate) {
                errors.push('endDate')
            }

            workTime.breakTimes.forEach((b, index) => {
                if (!b.startTime) {
                    errors.push(`breakTimeStart${index}`)
                }
                if (!b.endTime) {
                    errors.push(`breakTimeEnd${index}`)
                }
            })
        }

        this._addErrors(errors)

        return errors.length === 0
    }

    _findUserLink() {
        const { groupMembers, user } = this.props

        return groupMembers.find(
            gm =>
                (user?.email && gm?.user?.email === user.email) ||
                gm?.userSubscribe?.mail === user.email,
        )
    }

    getUserLinkById(id) {
        const { groupMembers } = this.props
        return groupMembers.find(gm => gm.id === id)
    }

    saveWorkTime() {
        const { comment, responsibleLink, workTimes, userLink } = this.state

        const currentUserLink = this._findUserLink()

        if (!this._handleError()) return

        for (const workTime of workTimes) {
            //TODO: créer X jours entre jours de debut et de fin ?
            const body = {
                startTime: dayjs(workTime.startTime)
                    .set('second', 0)
                    .locale(this.context)
                    .format('HH:mm:ss'),
                endTime: dayjs(workTime.endTime)
                    .set('second', 0)
                    .locale(this.context)
                    .format('HH:mm:ss'),
                responsibleLink: responsibleLink,
                responsibleUser: responsibleLink,
                delimitedDate: {
                    id: workTime?.delimitedDate?.id || undefined,
                    startDate: dayjs(workTime.delimitedDate.startDate),
                    endDate: dayjs(workTime.delimitedDate.endDate),
                },
                breakTimes: workTime.breakTimes.map(b => {
                    return {
                        id: b?.id || undefined,
                        startTime:
                            typeof b.startTime === 'string'
                                ? b.startTime
                                : b.startTime
                                      .set('second', 0)
                                      .format('HH:mm:ss'),
                        endTime:
                            typeof b.endTime === 'string'
                                ? b.endTime
                                : b.endTime.set('second', 0).format('HH:mm:ss'),
                    }
                }),
                userLinkId: userLink.id,
                comment: comment,
            }

            if (workTime?.id) {
                this.props.dispatch(
                    workTimeActions.updateWorkTime(
                        body,
                        workTime.id,
                        currentUserLink,
                    ),
                )
            } else {
                this.props.dispatch(
                    workTimeActions.postWorkTime(body, currentUserLink),
                )
            }
        }
        this.props.handleClose()
    }

    changeOpenedDay(index) {
        this.setState({
            openSettingIndex: index,
        })
    }

    deleteBreak(wkIndex, index) {
        const { isSameHours } = this.state
        const workTimes = [...this.state.workTimes]

        if (isSameHours) {
            for (const workTime of workTimes) {
                const newBreaks = [...workTime.breakTimes]
                newBreaks.splice(index, 1)
                workTime.breakTimes = newBreaks
            }
        } else {
            const newBreaks = [...workTimes[wkIndex].breakTimes]
            newBreaks.splice(index, 1)
            workTimes[wkIndex].breakTimes = newBreaks
        }
        this.setState({
            workTimes: workTimes,
        })
    }

    addBreak(wkIndex) {
        const workTimes = [...this.state.workTimes]
        const newBreaks = [...workTimes[wkIndex].breakTimes]

        newBreaks.push({
            startTime: null,
            endTime: null,
        })
        workTimes[wkIndex].breakTimes = newBreaks
        this.setState({
            workTimes: workTimes,
        })
    }

    handleBreakChange(wkIndex, index, field, value) {
        const { isSameHours } = this.state
        const workTimes = [...this.state.workTimes]
        const breaks = [...workTimes[wkIndex].breakTimes]
        breaks[index][field] = value
        if (isSameHours) {
            for (const workTime of workTimes) workTime.breakTimes = breaks
        } else {
            workTimes[wkIndex].breakTimes = breaks
        }
        this.setState({
            workTimes,
        })
    }

    handleTimeChange(field, value, fkIndex) {
        const { isSameHours } = this.state
        const workTimes = [...this.state.workTimes]

        if (isSameHours) {
            for (const workTime of workTimes) {
                if (dayjs.isDayjs(value)) {
                    // On met à jour la date de l'objet dayjs avec l'heure du worktime
                    // pour simplifier la validation des heures de début et de fin
                    if (
                        workTime.delimitedDate.startDate &&
                        field === 'startTime'
                    ) {
                        value = dayjs(value)
                            .date(
                                dayjs(workTime.delimitedDate.startDate).date(),
                            )
                            .month(
                                dayjs(workTime.delimitedDate.startDate).month(),
                            )
                            .year(
                                dayjs(workTime.delimitedDate.startDate).year(),
                            )
                    }
                    if (workTime.delimitedDate.endDate && field === 'endTime') {
                        value = dayjs(value)
                            .date(dayjs(workTime.delimitedDate.endDate).date())
                            .month(
                                dayjs(workTime.delimitedDate.endDate).month(),
                            )
                            .year(dayjs(workTime.delimitedDate.endDate).year())
                    }
                }
                workTime[field] = value
            }
        } else {
            if (dayjs.isDayjs(value)) {
                // On met à jour la date de l'objet dayjs avec l'heure du worktime
                // pour simplifier la validation des heures de début et de fin
                if (
                    workTimes[fkIndex].delimitedDate.startDate &&
                    field === 'startTime'
                ) {
                    value = dayjs(value)
                        .date(
                            dayjs(
                                workTimes[fkIndex].delimitedDate.startDate,
                            ).date(),
                        )
                        .month(
                            dayjs(
                                workTimes[fkIndex].delimitedDate.startDate,
                            ).month(),
                        )
                        .year(
                            dayjs(
                                workTimes[fkIndex].delimitedDate.startDate,
                            ).year(),
                        )
                }
                if (
                    workTimes[fkIndex].delimitedDate.endDate &&
                    field === 'endTime'
                ) {
                    value = dayjs(value)
                        .date(
                            dayjs(
                                workTimes[fkIndex].delimitedDate.endDate,
                            ).date(),
                        )
                        .month(
                            dayjs(
                                workTimes[fkIndex].delimitedDate.endDate,
                            ).month(),
                        )
                        .year(
                            dayjs(
                                workTimes[fkIndex].delimitedDate.endDate,
                            ).year(),
                        )
                }
            }
            workTimes[fkIndex][field] = value
        }

        this.setState({
            workTimes,
        })
    }

    closeDateRange() {
        const workTimes = [...this.state.workTimes]
        const { dateSelectedIndex } = this.state

        let indexEdit =
            dateSelectedIndex !== null
                ? dateSelectedIndex
                : workTimes.length - 1

        if (
            workTimes[indexEdit].delimitedDate.startDate === null &&
            workTimes.length > 1
        ) {
            workTimes.splice(indexEdit, 1)
        }

        if (workTimes[indexEdit] === undefined) {
            indexEdit = null
        }

        this.setState({
            isCalendarOpen: false,
            dateSelectedIndex: indexEdit,
            workTimes,
        })
    }

    openDateRange(e, index = null) {
        const { workTime } = this.props
        const { byManager, userLink } = this.state
        const workTimes = [...this.state.workTimes]
        e.stopPropagation()

        // On ne peut pas modifier la date si:
        // - le worktime est déjà enregistré
        // - le worktime est en train d'être créé par un manager et le champ utilisateur est vide
        if (workTime?.id || (byManager && !userLink)) return

        if (this.state.isCalendarOpen) return

        if (
            index === null &&
            workTimes[workTimes.length - 1]?.delimitedDate?.startDate !==
                null &&
            workTime?.id === undefined
        ) {
            workTimes.push({
                delimitedDate: { startDate: null, endDate: null },
                startTime: null,
                endTime: null,
                breakTimes: [],
                temp_id: Math.random()
                    .toString(36)
                    .substr(2, 9),
            })
        }

        this.setState({
            isCalendarOpen: true,
            dateSelectedIndex: workTime?.id ? 0 : index,
            workTimes: workTimes,
        })
    }

    _getLabelDates(startDate, endDate) {
        let label = ''
        if (dayjs(startDate).isSame(dayjs(endDate), 'day')) {
            label = dayjs(startDate).format('DD/MM/YYYY')
        } else {
            if (dayjs(startDate).isSame(dayjs(endDate), 'year')) {
                label =
                    dayjs(startDate).format('DD/MM') +
                    ' - ' +
                    dayjs(endDate).format('DD/MM/YYYY')
            } else {
                label =
                    dayjs(startDate).format('DD/MM/YYYY') +
                    ' - ' +
                    dayjs(endDate).format('DD/MM/YYYY')
            }
        }

        return label
    }

    _getLabelStringDates(startDate, endDate) {
        const url = window.location.pathname
        const context = getContextFromUrl(url)
        const stringStartDay = new Date(startDate).toLocaleDateString(
            context === 'fr' ? 'fr-FR' : 'en-US',
            {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            },
        )

        if (dayjs(startDate).isSame(dayjs(endDate), 'day')) {
            return (
                stringStartDay.charAt(0).toUpperCase() + stringStartDay.slice(1)
            )
        }

        const stringEndDay = new Date(endDate).toLocaleDateString(
            context === 'fr' ? 'fr-FR' : 'en-US',
            {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            },
        )

        return (
            stringStartDay.charAt(0).toUpperCase() +
            stringStartDay.slice(1) +
            ' - ' +
            stringEndDay.charAt(0).toUpperCase() +
            stringEndDay.slice(1)
        )
    }

    renderTags(value) {
        const { workTime } = this.props // TODO: remove tooltip quand pas util

        return value.map((option, index) => {
            if (!option) return <></>

            let label = this._getLabelDates(option.startDate, option.endDate)

            return (
                <Chip
                    size="medium"
                    variant="outlined"
                    clickable={!workTime?.id}
                    style={{
                        backgroundColor: '#fcd48e',
                        marginRight: '5px',
                        opacity: workTime?.id ? 0.5 : 1,
                    }}
                    onClick={e => this.openDateRange(e, index)}
                    onDelete={
                        !workTime?.id
                            ? () =>
                                  this.deleteDateOfWorkTimes(
                                      option.startDate,
                                      option.endDate,
                                  )
                            : undefined
                    }
                    label={label}
                    key={index}
                />
            )
        })
    }

    deleteDateOfWorkTimes(start, end) {
        const { dateSelectedIndex } = this.state
        const workTimes = [...this.state.workTimes]
        const index = workTimes.findIndex(
            wt =>
                dayjs(wt.delimitedDate.startDate).isSame(start, 'day') &&
                dayjs(wt.delimitedDate.endDate).isSame(end, 'day'),
        )

        if (index !== -1) {
            workTimes.splice(index, 1)
        }

        if (workTimes.length === 0) {
            workTimes.push({
                startTime: null,
                endTime: null,
                breakTimes: [],
                delimitedDate: { startDate: null, endDate: null },
                temp_id: Math.random()
                    .toString(36)
                    .substr(2, 9),
            })
        }

        this.setState({
            workTimes,
            dateSelectedIndex:
                dateSelectedIndex - 1 > 0 ? dateSelectedIndex - 1 : 0,
        })
    }

    _editDatesWorkTimes(start, end) {
        const { dateSelectedIndex, userGroup } = this.state
        const workTimes = [...this.state.workTimes]
        const editIndex =
            dateSelectedIndex !== null
                ? dateSelectedIndex
                : workTimes.length - 1

        workTimes[editIndex].delimitedDate.startDate = start
        workTimes[editIndex].delimitedDate.endDate = end

        // Lorsqu'on ajoute ou modifie une date
        // On récupère les heures et les breaks des réglages du groupe de l'utilisateur
        // Et on les applique au worktime en cours d'édition

        // Récupérer le jour de la semaine de la date de début
        const dayOfWeek = dayjs(start).day()
        const dayName = this.daysOfWeek[dayOfWeek]
        const defaultWorkTimeSetting = userGroup[dayName + 'Setting']

        if (defaultWorkTimeSetting) {
            // Mise à jour des heures du worktime avec les heures par défaut du groupe de l'utilisateur
            workTimes[editIndex].startTime = dayjs(
                `${defaultWorkTimeSetting.startTime.split(':')[0]}:${
                    defaultWorkTimeSetting.startTime.split(':')[1]
                }`,
                'HH:mm',
            )
            workTimes[editIndex].endTime = dayjs(
                `${defaultWorkTimeSetting.endTime.split(':')[0]}:${
                    defaultWorkTimeSetting.endTime.split(':')[1]
                }`,
                'HH:mm',
            )

            // Mise à jour des breaks du worktime avec les breaks par défaut du groupe de l'utilisateur
            workTimes[
                editIndex
            ].breakTimes = defaultWorkTimeSetting.breakTimes.map(bt => {
                return {
                    startTime: dayjs(
                        `${bt.startTime.split(':')[0]}:${
                            bt.startTime.split(':')[1]
                        }`,
                        'HH:mm',
                    ),
                    endTime: dayjs(
                        `${bt.endTime.split(':')[0]}:${
                            bt.endTime.split(':')[1]
                        }`,
                        'HH:mm',
                    ),
                }
            })
        }

        this.closeDateRange()
        this.setState({ workTimes })
    }

    isDisableDay(day) {
        // We need to use this, we cant use maxDate, because maxDate with disabledDates make RangeDate crash
        return day > new Date()
    }

    /**
     * Cette fonction va renvoyer un tableau de créneaux horaires disponibles
     *
     * Les worktimes sur plusieurs jours sont séparés en plusieurs évènements
     * Et on va faire l'intersection de chacun des tableaux de min et max pour savoir
     * quelles sont les heures disponibles
     */
    getAvailableTimeForDateRange() {
        const { openSettingIndex, workTimes: dateRanges } = this.state

        const editingWorkTime = dateRanges[openSettingIndex]
        if (!editingWorkTime?.delimitedDate?.startDate) {
            return
        }

        let availableTimeSlots = []
        const splitCurrentWorkTime = this.splitEvents([editingWorkTime])

        // On boucle sur tous les jours de la période sélectionnée
        // Pour récupérer les créneaux horaires disponibles
        // Et on fait l'intersection de tous les créneaux horaires disponibles
        // ex:
        // 1er jour: [2h-4h, 18h-22h]
        // 2eme jour: [3h-5h, 16h-22h]
        // Résultat: [3h-4h, 18h-22h]
        for (let evt of splitCurrentWorkTime) {
            // Récupération des créneaux horaires disponibles pour un jour
            let tempAvailableTimeSlot = this.getAvailableTimeSlotsForOneDay(evt)
            if (availableTimeSlots.length === 0) {
                availableTimeSlots = tempAvailableTimeSlot
                continue
            }

            let tempOverlappingTimeSlots = []
            for (let i = 0; i < tempAvailableTimeSlot.length; i++) {
                for (let j = 0; j < availableTimeSlots.length; j++) {
                    let firstStartTime = this.onlyTime(
                        tempAvailableTimeSlot[i].start,
                    )
                    let secondStartTime = this.onlyTime(
                        availableTimeSlots[j].start,
                    )

                    let firstEndTime = tempAvailableTimeSlot[i].end.isAfter(
                        tempAvailableTimeSlot[i].start,
                        'date',
                    )
                        ? this.onlyTime(tempAvailableTimeSlot[i].end).add(
                              1,
                              'day',
                          )
                        : this.onlyTime(tempAvailableTimeSlot[i].end)
                    let secondEndTime = availableTimeSlots[j].end.isAfter(
                        availableTimeSlots[j].start,
                        'date',
                    )
                        ? this.onlyTime(availableTimeSlots[j].end).add(1, 'day')
                        : this.onlyTime(availableTimeSlots[j].end)

                    if (
                        firstStartTime.isBefore(secondEndTime) &&
                        firstEndTime.isAfter(secondStartTime)
                    ) {
                        tempOverlappingTimeSlots.push({
                            start: this.latest([
                                firstStartTime,
                                secondStartTime,
                            ]),
                            end: this.earliest([firstEndTime, secondEndTime]),
                        })
                    }
                }
            }
            availableTimeSlots =
                tempOverlappingTimeSlots.length > 0
                    ? tempOverlappingTimeSlots
                    : null

            if (availableTimeSlots === null) {
                break
            }
        }

        return availableTimeSlots
    }

    /**
     * Retourne la date du worktime suivant moins le temps de repos minimum
     * Ou la date du worktime suivant si c'est une absence
     *
     * OU
     *
     * Retourne la date du worktime suivant moins le temps de travail maximum
     */
    getMaxTime(event) {
        const {
            userGroup,
            workTimes: dateRanges,
            splitWorkTimes,
            splitAbsences,
        } = this.state

        // Récupération du temps de repos minimum du groupe de l'utilisateur
        const minimumRestTime = parseInt(
            userGroup.minimumRestTime.split(':')[0],
        )

        // Récupération du temps de travail maximum du groupe de l'utilisateur
        const dailyLimitTime = parseInt(userGroup.dailyLimitTime.split(':')[0])

        if (!event?.delimitedDate?.startDate) {
            return
        }

        // Ajout de l'heure de début et de fin du worktime
        // pour faciliter le traitement
        event.dateTimeStart = dayjs(event.delimitedDate.startDate)
        event.dateTimeEnd = dayjs(event.delimitedDate.startDate)

        if (event?.startTime) {
            event.dateTimeStart = event.dateTimeStart
                .hour(event.startTime.hour())
                .minute(event.startTime.minute())
        }
        if (event?.endTime) {
            event.dateTimeEnd = event.dateTimeEnd
                .hour(event.endTime.hour())
                .minute(event.endTime.minute())
        }

        // Sépare les dateRanges en plusieurs évènements
        // pour faciliter le traitement
        // Filtre le dateRanges en cours d'édition pour ne pas créer de conflit
        const splitDateRanges = this.splitEvents(dateRanges).filter(dr => {
            // return dr.temp_id !== event.temp_id
            return !(
                dayjs(dr.delimitedDate.startDate).isSame(
                    event.delimitedDate.startDate,
                ) &&
                event?.startTime === dr.startTime &&
                event?.endTime === dr.endTime
            )
        })
        // Regroupe et tri tous les évènements
        // absences, worktimes et worktimes non enregistrés
        const allNextEvents = splitDateRanges
            .concat(splitWorkTimes)
            .concat(splitAbsences)
            .filter(e => {
                // Récupérer uniquement les évènements qui sont avant ou pendant le worktime en cours d'édition
                return (
                    dayjs(e.dateTimeStart).isSameOrAfter(event.dateTimeStart) &&
                    e.id !== event?.id
                )
            })
            .sort((wt1, wt2) => {
                // Trier par date de début décroiassante
                return dayjs(wt1.dateTimeStart).isBefore(
                    dayjs(wt2.dateTimeStart),
                )
                    ? -1
                    : 1
            })

        // Il y'a deux différentes heures qui peuvent être la plus tardive
        // 1- L'heure de fin maximum en fonction du temps de travail maximum
        // 2- L'heure de fin maximum en fonction de l'évènement suivant (worktime ou absence) et du temps de pause minimum

        // 1- Calcul de l'heure de fin maximum en fonction du temps de travail maximum
        // Si l'heure de début est définie
        let maxTimes = {
            maxTimeFromDailyLimit: null,
            maxTimeFromNextEvt: null,
            canBeOvernight: false,
        }

        if (event?.startTime) {
            maxTimes.maxTimeFromDailyLimit = dayjs(event.dateTimeStart).add(
                dailyLimitTime,
                'hour',
            )

            if (
                dayjs(maxTimes.maxTimeFromDailyLimit).isAfter(
                    dayjs(event.dateTimeStart),
                    'date',
                )
            ) {
                maxTimes.canBeOvernight = true
            }
        }

        // 2- Récupération de l'évènement suivant (worktime, absence ou worktime non enregistré)
        // const nextEvent = this.getNextEvent(editingWorkTime)
        const nextEvent = allNextEvents[0]
        // Vérifier si le prochain évènement est le jour même ou le lendemain
        if (
            nextEvent &&
            nextEvent.dateTimeStart.diff(event.dateTimeStart, 'day') <= 1
        ) {
            if (nextEvent.absenceStatus) {
                maxTimes.maxTimeFromNextEvt = nextEvent.dateTimeStart
            } else {
                // On soustrait le temps de pause minimum à l'heure de début du wt suivant
                //  pour connaitre l'heure de fin maximum
                maxTimes.maxTimeFromNextEvt = nextEvent.dateTimeStart.subtract(
                    minimumRestTime,
                    'hour',
                )
            }
        }

        return maxTimes
    }

    /**
     *
     * Récupération des créneaux horaires disponibles pour un évènement
     *
     * 1) On boucle sur toutes les absences, worktimes, et autres créneaux horaires
     * pour trouver ceux qui chevauchent le worktime en cours d'édition
     * en prenant en compte le temps de repos minimum entre les worktimes
     *
     * 2) On boucle sur tous les worktimes qui chevauchent le worktime en cours d'édition
     * Pour trouver les créneaux horaires disponibles en fonction des contraintes
     * de temps de repos minimum et de temps de travail maximum
     *
     * Si l'évènement est une absence, les contraintes de temps de repos minimum ne sont pas appliquées
     * Car il n'y a pas besoin d'avoir de temps de repos avant/après une absence
     *
     * On vérifie systématiquement si l'heure de début d'un créneau est différente de l'heure de fin
     * Pour ne pas avoir de créneau horaire vide
     * ex: (16h00-16h00)
     *
     */
    getAvailableTimeSlotsForOneDay(event) {
        const {
            userGroup,
            workTimes: dateRanges,
            splitWorkTimes,
            splitAbsences,
        } = this.state

        if (!event?.delimitedDate?.startDate) {
            return
        }

        // Récupération du temps de repos minimum du groupe de l'utilisateur
        const minimumRestTime = parseInt(
            userGroup.minimumRestTime.split(':')[0],
        )

        // Récupération du temps de travail maximum du groupe de l'utilisateur
        const dailyLimitTime = parseInt(userGroup.dailyLimitTime.split(':')[0])

        // On sépare tous les workTimes non sauvegardés en plusieurs évènements
        // pour faciliter le traitement.
        // Et on filtre pour ne pas prendre en compte le worktime en cours d'édition
        const splitDateRanges = this.splitEvents(dateRanges).filter(dr => {
            return dr.temp_id !== event.temp_id
            // return !(
            //     dayjs(dr.delimitedDate.startDate).isSame(
            //         event.delimitedDate.startDate,
            //     ) &&
            //     event?.startTime === dr.startTime &&
            //     event?.endTime === dr.endTime
            // )
        })

        // 1) Récupération des évènements qui chevauchent le worktime en cours d'édition
        // const overlappingEvents = splitDateRanges
        //     .concat(splitWorkTimes)
        //     .concat(splitAbsences)
        const overlappingEvents = splitDateRanges
            .concat(splitWorkTimes)
            .concat(splitAbsences)

            // Récupération de tous les events qui chevauchent le worktime en cours d'édition
            // - A terminé la veille mais l'heure de fin + temps repos minimum tombe aujourd'hui
            // - Commence aujourd'hui
            // - Commence le lendemain mais l'heure de début + temps de repos minimum tombe aujourd'hui
            .filter(e => {
                // Pas de contrainte avec les absences sur toute la journée
                if (e.absenceStatus && !e.absenceTime) {
                    return false
                }

                if (e.startTime === null || e.endTime === null) {
                    return false
                }

                if (
                    // Event de la veille
                    (dayjs(e.dateTimeStart).isBefore(
                        event.delimitedDate.startDate,
                        'date',
                    ) &&
                        dayjs(e.dateTimeEnd)
                            .add(minimumRestTime, 'hour')
                            .isAfter(event.delimitedDate.startDate)) ||
                    //OU
                    // Events du jours
                    dayjs(e.dateTimeStart).isSame(
                        event.delimitedDate.startDate,
                        'date',
                    ) ||
                    // OU
                    // Events du lendemain
                    // Si la date de début du wt - le temps de repos minimum est avant
                    (dayjs(e.dateTimeStart)
                        .subtract(minimumRestTime, 'hour')
                        .isSameOrBefore(event.delimitedDate.endDate, 'date') &&
                        dayjs(e.dateTimeEnd).isSameOrAfter(
                            event.delimitedDate.endDate,
                            'date',
                        ))
                ) {
                    return true
                }
            })
            .sort((e1, e2) => {
                // Trier les évènements par date de début croissante
                return dayjs(e1.delimitedDate.startDate).isBefore(
                    e2.delimitedDate.startDate,
                )
                    ? -1
                    : 1
            })

        let availableTimeSlots = []

        // Si il n'y a pas d'evt qui chevauchent le worktime en cours d'édition
        // Aucune restriction
        if (
            overlappingEvents.length === 0 ||
            overlappingEvents.every(o => o.temp_id === event.temp_id)
        ) {
            availableTimeSlots.push({
                start: event.delimitedDate.startDate,
                end: event.delimitedDate.endDate.add(1, 'day'),
            })
            return availableTimeSlots
        }

        // Gestion du cas où il y'a le temps de placer un event entre le début de la journée
        //  et le début du premier event
        // ex (00:00 - 06:00) si le premier overlappevent commence à 06:00+minimumRestTime
        const firstEventStart = overlappingEvents[0].absenceStatus
            ? overlappingEvents[0].dateTimeStart
            : overlappingEvents[0].dateTimeStart.subtract(
                  minimumRestTime,
                  'hour',
              )
        if (
            firstEventStart.isAfter(
                event.delimitedDate.startDate.startOf('day'),
            )
        ) {
            availableTimeSlots.push({
                start: event.delimitedDate.startDate.startOf('day'),
                end: firstEventStart,
            })
        }

        // 2) Pour chaque évènement qui chevauche le worktime en cours d'édition
        // On vérifie si il y'a un créneau disponible entre la fin de l'event et le début du prochain event
        for (let i = 0; i < overlappingEvents.length; i++) {
            // Si l'écart entre la fin de l'event
            // et le début du prochain event est supérieur au temps de repos minimum * 2
            // Alors on peut ajouter un créneau disponible
            let currentOverlappingEvent = overlappingEvents[i]

            if (currentOverlappingEvent.temp_id === event.temp_id) {
                continue
            }

            let currentEnd
            if (currentOverlappingEvent.absenceStatus) {
                // Si l'évènement est une absence
                // Le dateTime de fin est soit
                // - L'heure de fin de l'absence si l'absence n'est pas toute la journée
                // - L'heure de fin de la journée si l'absence est toute la journée
                if (currentOverlappingEvent.absenceTime) {
                    currentEnd = currentOverlappingEvent.dateTimeEnd
                }
            } else {
                if (currentOverlappingEvent.dateTimeStart) {
                    currentEnd = currentOverlappingEvent.dateTimeStart.add(
                        dailyLimitTime,
                        'hour',
                    )
                }

                if (currentOverlappingEvent.dateTimeEnd) {
                    currentEnd = currentOverlappingEvent.dateTimeEnd.add(
                        minimumRestTime,
                        'hour',
                    )
                }
            }

            let nextOverlappingEvent = overlappingEvents[i + 1]
                ? overlappingEvents[i + 1]
                : null

            if (nextOverlappingEvent && currentEnd) {
                const nextStartMaxTime = nextOverlappingEvent.absenceStatus
                    ? nextOverlappingEvent.dateTimeStart
                    : nextOverlappingEvent.dateTimeStart.subtract(
                          minimumRestTime,
                          'hour',
                      )

                if (nextStartMaxTime.diff(currentEnd, 'hour') >= 0) {
                    let slotStart = currentEnd.isBefore(
                        event.delimitedDate.startDate.startOf('day'),
                        'date',
                    )
                        ? event.delimitedDate.startDate.startOf('day')
                        : currentEnd

                    if (!slotStart.isSame(nextStartMaxTime, 'minute')) {
                        availableTimeSlots.push({
                            start: slotStart,
                            end: nextStartMaxTime,
                        })
                    }
                }
            }
        }

        // Gestion du cas où il y'a le temps de placer un event entre
        // la fin du dernier event et la fin de la journée
        // ex: WT: 06h-12h, Temps de repos min: 10h
        // créneau dispo: 22h-00h
        const lastEvent = overlappingEvents[overlappingEvents.length - 1]

        if (lastEvent) {
            let nextEnd
            if (lastEvent.absenceStatus) {
                nextEnd = lastEvent.dateTimeEnd
            } else if (lastEvent.dateTimeEnd) {
                nextEnd = lastEvent.dateTimeEnd.add(minimumRestTime, 'hour')
            }

            if (
                lastEvent.dateTimeEnd &&
                lastEvent.dateTimeEnd.isBefore(event.delimitedDate.endDate) &&
                nextEnd
            ) {
                // Si les deux heures sont différentes alors on ajoute un slot
                // Car on ne veut pas avoir un slot qui commence à 20h et se termine à 20h
                if (!nextEnd.isSame(event.delimitedDate.endDate, 'minute')) {
                    availableTimeSlots.push({
                        start: nextEnd,
                        end: event.delimitedDate.endDate,
                    })
                }
            }
        }

        return availableTimeSlots
    }

    getDisabledDates() {
        const {
            workTimes: dateRanges,
            userGroup,
            splitWorkTimes,
            splitAbsences,
        } = this.state
        const { chronoWorkTime } = this.props
        const disabledDates = []

        const minimumRestTime = parseInt(
            userGroup.minimumRestTime.split(':')[0],
        )

        // Pour chaque évènement (worktime ou absence) on va vérifier:
        //
        // 1 - Si l'évènement est une absence sur toute la journée
        //     Alors la journée est désactivée sans vérifier les autres évènements
        //
        // 2 - Vérifier entre le début de l'évènement et la fin de l'évènement précédent,
        //     est ce qu'il y'a au moins deux fois le temps de repos minimum + 1h (?)
        //     ET que il y'a le temps de repos minimum + 1 heure entre minuit et le début de l'evt
        //     SAUF si l'evt précédent est une absence, auquel cas pas de temps de pause mini entre absence et worktime
        //     Ca va nous permettre de déterminer si il y'a le temps d'avoir un évènement et le temps de repos minimum avant cet évènement
        //
        //     ex: précédent evt: 14h-22h, evt: 14h-22H
        //         -> Il y'a le temps aujourd'hui avant 14h pour placer un evt
        //            Mais il n'y a pas le temps entre la veille 22h de placer 1 temps de repos, 1 evt (min 1h) et 1 autre temps de repos
        //
        //
        // 3 - Vérifier entre la fin de l'évènement et le début de l'évènement suivant,
        //     est ce qu'il y'a au moins deux fois le temps de repos minimum + 1h (?)
        //     SAUF si l'evt suivant est une absence, auquel cas pas de temps de pause mini entre absence et worktime
        //     Ca va nous permettre de déterminer si il y'a le temps d'avoir un évènement et le temps de repos minimum après cet évènement et avant le suivant
        //

        // Sépare les dateRanges en plusieurs évènements
        // pour faciliter le traitement
        const splitDateRanges = this.splitEvents(dateRanges)

        // Regroupe et tri tous les évènements
        // absences, worktimes et worktimes non enregistrés
        const allEvents = splitDateRanges
            .concat(splitWorkTimes)
            .concat(splitAbsences)
            .sort((wt1, wt2) => {
                return dayjs(wt1.dateTimeStart).isBefore(
                    dayjs(wt2.dateTimeStart),
                )
                    ? -1
                    : 1
            })

        allEvents.forEach((evt, index) => {
            const previousEvent = allEvents[index - 1]
            const nextEvent = allEvents[index + 1]

            // Le temps minimum entre deux évènement nécessaire pour pouvoir en placer un autre
            const minimumTimeGapRequired = minimumRestTime * 2 + 1

            let hasTimeBefore = false
            let hasTimeAfter = false
            if (evt?.absenceStatus && !evt?.absenceTime) {
                hasTimeBefore = false
                hasTimeAfter = false
            } else {
                //
                // 1 - Vérification avant
                //
                if (evt.dateTimeStart) {
                    if (!previousEvent || previousEvent.absenceStatus) {
                        hasTimeBefore =
                            evt.dateTimeStart.hour() >= minimumRestTime + 1
                    } else {
                        if (
                            evt.dateTimeStart.hour() >= minimumRestTime + 1 &&
                            evt.dateTimeStart.diff(
                                previousEvent.dateTimeEnd,
                                'hour',
                            ) >= minimumTimeGapRequired
                        ) {
                            hasTimeBefore = true
                        }
                    }
                }

                //
                // 2 - Vérification après
                //
                if (evt.dateTimeEnd) {
                    if (!nextEvent || !nextEvent.dateTimeStart) {
                        hasTimeAfter =
                            24 - evt.dateTimeEnd.hour() >= minimumRestTime
                    } else {
                        if (
                            24 - evt.dateTimeEnd.hour() >= minimumRestTime &&
                            nextEvent.dateTimeStart.diff(
                                evt.dateTimeEnd,
                                'hour',
                            ) >= minimumTimeGapRequired
                        ) {
                            hasTimeAfter = true
                        }
                    }
                }
            }

            if (!hasTimeBefore && !hasTimeAfter) {
                disabledDates.push(evt.delimitedDate.startDate.toDate())
            }
        })

        // Si il y'a un worktime issu du chronomètre qui est en cours
        // Alors on désactive la date du jour
        if (chronoWorkTime && Object.keys(chronoWorkTime).length !== 0) {
            disabledDates.push(new Date())
        }

        return disabledDates
    }

    _setSameHoursForAllDays() {
        const { isSameHours, openSettingIndex } = this.state
        const workTimes = [...this.state.workTimes]
        const startTime = workTimes[openSettingIndex].startTime
        const endTime = workTimes[openSettingIndex].endTime
        const breakTimes = workTimes[openSettingIndex].breakTimes

        for (const workTime of workTimes) {
            workTime.startTime = startTime
            workTime.endTime = endTime
            workTime.breakTimes = breakTimes
        }
        this.setState({ isSameHours: !isSameHours, workTimes })
    }

    _renderDates() {
        const { classes, workTime } = this.props
        const {
            isCalendarOpen,
            workTimes,
            errorFields,
            isSameHours,
            dateSelectedIndex,
            byManager,
            userLink,
        } = this.state
        const disabledDates = this.getDisabledDates()
        const disabled = workTime?.id

        return (
            <>
                <Autocomplete
                    multiple
                    id="multiple-limit-tags"
                    style={{ marginBottom: 10 }}
                    value={workTimes
                        .filter(
                            wt =>
                                wt?.delimitedDate?.startDate &&
                                wt?.delimitedDate?.endDate,
                        )
                        .map(wt => ({
                            startDate: wt.delimitedDate.startDate,
                            endDate: wt.delimitedDate.endDate,
                        }))}
                    options={[]}
                    renderTags={this.renderTags}
                    disabled={(byManager && !userLink) || disabled}
                    open={false}
                    disableClearable
                    renderInput={params => (
                        <TextField
                            {...params}
                            required={!disabled}
                            disabled={true}
                            onClick={e => this.openDateRange(e)}
                            margin={`normal`}
                            variant="outlined"
                            className={
                                !disabled
                                    ? classes.textFieldDates
                                    : classes.textFieldDatesNoCursor
                            }
                            error={
                                errorFields.find(
                                    error => error === 'workTimes',
                                ) && workTimes.length === 0
                            }
                            helperText={
                                errorFields.find(
                                    error => error === 'workTimes',
                                ) && workTimes.length === 0
                                    ? this.displayText('mandatoryDate')
                                    : undefined
                            }
                            label={
                                <>
                                    {'Dates'}
                                    <Tooltip
                                        title={''}
                                        classes={{ tooltip: classes.tooltip }}
                                    >
                                        <HelpIcon
                                            className={classes.helpIcon}
                                        />
                                    </Tooltip>
                                </>
                            }
                            InputLabelProps={{
                                style: {
                                    pointerEvents: 'auto',
                                },
                            }}
                        />
                    )}
                    sx={{ width: '500px' }}
                    ref={this.dateInputRef}
                />
                <Popover
                    open={isCalendarOpen}
                    onClose={this.closeDateRange}
                    anchorReference={this.dateInputRef}
                    anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'center',
                        horizontal: 'center',
                    }}
                >
                    <BoatOnDateSelector
                        isDateRange
                        onDateChange={(start, end) => {
                            this._editDatesWorkTimes(start, end)
                        }}
                        disabledDay={this.isDisableDay}
                        disabledDates={disabledDates}
                        startDate={
                            workTime?.id
                                ? workTimes[0].delimitedDate.startDate
                                : dateSelectedIndex !== null
                                ? workTimes[dateSelectedIndex].delimitedDate
                                      .startDate
                                : undefined
                        }
                        endDate={
                            workTime?.id
                                ? workTimes[0].delimitedDate.endDate
                                : dateSelectedIndex !== null
                                ? workTimes[dateSelectedIndex].delimitedDate
                                      .endDate
                                : undefined
                        }
                        maxDate={new Date()}
                        inputProps={{
                            helperText: errorFields.find(
                                error => error === `startDate`,
                            )
                                ? this.displayText('errorEmptyField')
                                : null,
                            error: errorFields.find(
                                error => error === `startDate`,
                            ),
                        }}
                    />
                </Popover>
                {workTimes.length > 1 && (
                    <div className={classes.sameHours}>
                        {this.displayText('sameHours')}
                        <Checkbox
                            checked={isSameHours}
                            onClick={() => this._setSameHoursForAllDays()}
                            color="primary"
                        />
                    </div>
                )}
            </>
        )
    }

    _renderManager() {
        const { classes, workTime } = this.props
        const { responsibleLink, errorFields } = this.state
        const disabled = workTime?.id

        return (
            <UserSelector
                required={!disabled}
                disabled={disabled}
                classes={{ input: classes.managerInput }}
                onChange={this.onManagerChange}
                label={this.displayText('manager')}
                errorMessageMandatory={this.displayText(
                    'errorMandatoryManager',
                )}
                value={[responsibleLink]}
                error={
                    errorFields.find(error => error === 'responsibleLink') &&
                    !responsibleLink
                        ? 'error'
                        : undefined
                }
                tooltip={this.displayText('managerTooltip')}
            />
        )
    }

    _renderClosedHours(workTime, wkIndex) {
        const { classes } = this.props
        const { errorFields } = this.state
        const { startDate, endDate } = workTime.delimitedDate
        const { startTime, endTime, breakTimes } = workTime
        const error =
            errorFields.find(
                error =>
                    error === 'breakStartTime' ||
                    error === 'breakEndTime' ||
                    error === 'startTime' ||
                    error === 'endTime',
            ) &&
            (!startTime ||
                !endTime ||
                breakTimes.find(b => !b.startTime || !b.endTime))

        return (
            <>
                <div
                    className={`${classes.flexColumnSpacebetween} ${
                        error ? classes.error : ''
                    }`}
                >
                    <div
                        style={{
                            display: 'flex',
                            minWidth: 275,
                            justifyContent: 'space-between',
                        }}
                    >
                        <Typography>
                            {this._getLabelDates(startDate, endDate)}
                        </Typography>
                        {startTime && endTime && (
                            <Typography>
                                {`${new Date(startTime)
                                    .getHours()
                                    .toString()
                                    .padStart(2, '0')}h${new Date(startTime)
                                    .getMinutes()
                                    .toString()
                                    .padStart(2, '0')} -
                            ${new Date(endTime)
                                .getHours()
                                .toString()
                                .padStart(2, '0')}h${new Date(endTime)
                                    .getMinutes()
                                    .toString()
                                    .padStart(2, '0')}`}
                            </Typography>
                        )}
                        {error && (
                            <Typography
                                className={classes.error}
                                style={{ marginLeft: 15 }}
                            >
                                {this.displayText('missingInformations')}
                            </Typography>
                        )}
                    </div>
                    <IconButton
                        size={`small`}
                        id={`delete-break`}
                        onClick={() => this.changeOpenedDay(wkIndex)}
                        children={<EditIcon />}
                        className={classes.iconButton}
                    />
                </div>
                <Divider
                    orientation="horizontal"
                    style={{ margin: '10px 0px' }}
                />
            </>
        )
    }

    shouldDisableTimeStart(time, currentBreak, wkIndex, maxTimeConstraints) {
        const { workTimes } = this.state

        if (maxTimeConstraints.canBeOvernight) {
            // Si le worktime peut être la nuit
            if (
                this.onlyTime(time).isAfter(
                    this.onlyTime(workTimes[wkIndex].endTime),
                ) &&
                this.onlyTime(time).isBefore(
                    this.onlyTime(workTimes[wkIndex].startTime),
                )
            ) {
                return true
            }
        } else {
            // Si l'heure de début du break est avant l'heure de début du worktime
            if (
                this.onlyTime(time).isBefore(
                    this.onlyTime(workTimes[wkIndex].startTime),
                )
            ) {
                return true
            }

            // Si l'heure de début du break est après l'heure de fin du worktime
            if (
                this.onlyTime(time).isAfter(
                    this.onlyTime(workTimes[wkIndex].endTime),
                )
            ) {
                return true
            }

            // Si l'heure de début du break est après l'heure de fin du break
            if (
                currentBreak.endTime &&
                this.onlyTime(time).isAfter(this.onlyTime(currentBreak.endTime))
            ) {
                return true
            }
        }
    }

    shouldDisableTimeEnd(
        time,
        currentBreak,
        wkIndex,
        maxTimeConstraints,
        view,
    ) {
        const { workTimes } = this.state

        if (maxTimeConstraints.canBeOvernight) {
            // Si le worktime peut être la nuit

            // L'heure de fin du break doit être entre
            // l'heure de début et l'heure de fin du worktime
            if (
                this.onlyTime(time).isAfter(
                    this.onlyTime(workTimes[wkIndex].endTime),
                ) ||
                this.onlyTime(time).isBefore(
                    this.onlyTime(workTimes[wkIndex].startTime),
                )
            ) {
                if (!currentBreak.startTime) return true

                // Gestion des cas où le break commence le lendemain
                if (
                    this.onlyTime(currentBreak.startTime).isAfter(
                        this.onlyTime(workTimes[wkIndex].startTime),
                    )
                ) {
                    // Ne peut pas être avant le début du break
                    // Ne peut pas être après le début du worktime
                    if (
                        this.onlyTime(time).isAfter(
                            this.onlyTime(workTimes[wkIndex].endTime),
                        ) &&
                        this.onlyTime(time).isBefore(
                            this.onlyTime(currentBreak.startTime),
                        )
                    ) {
                        return true
                    }

                    return false
                } else {
                    if (
                        this.onlyTime(time).isAfter(
                            this.onlyTime(currentBreak.startTime),
                        )
                    ) {
                        return true
                    }
                }
                return false
            }
        } else {
            // Si l'heure de fin du break est avant l'heure de début du worktime
            if (
                this.onlyTime(time).isBefore(
                    this.onlyTime(workTimes[wkIndex].startTime),
                )
            ) {
                return true
            }

            // Si l'heure de fin du break est après l'heure de fin du worktime
            if (
                this.onlyTime(time).isAfter(
                    this.onlyTime(workTimes[wkIndex].endTime),
                )
            ) {
                return true
            }

            // Si l'heure de fin du break est avant l'heure de début du break
            if (
                currentBreak.startTime &&
                ((view === 'hours' &&
                    this.onlyTime(time).isBefore(
                        this.onlyTime(currentBreak.startTime),
                        'hour',
                    )) ||
                    (view === 'minutes' &&
                        this.onlyTime(time).isBefore(
                            this.onlyTime(currentBreak.startTime),
                            'minute',
                        )))
            ) {
                return true
            }
        }
    }

    _renderBreaks(wkIndex, breaks, maxTimeConstraints) {
        const { classes, workTime } = this.props
        const { errorFields, workTimes } = this.state
        const disabled = workTime?.id

        return (
            <div
                className={classes.col}
                style={{
                    gap: 10,
                    marginTop: disabled ? 20 : 'unset',
                }}
            >
                {breaks.map((b, index) => (
                    <div
                        className={classes.row}
                        style={{
                            gap: 10,
                            alignItems: 'baseline',
                            marginBottom: 20,
                        }}
                        key={`break-${index}`}
                    >
                        <Typography>#{index + 1}</Typography>
                        <div className={classes.col}>
                            <MobileTimePicker
                                label={this.displayText('startTimeBreak')}
                                ampm={false}
                                value={
                                    !dayjs.isDayjs(b.startTime)
                                        ? dayjs(b.startTime, `HH:mm:ss`)
                                        : b.startTime
                                }
                                disabled={
                                    !workTimes[wkIndex].startTime ||
                                    !workTimes[wkIndex].endTime ||
                                    disabled
                                }
                                onChange={v =>
                                    this.handleBreakChange(
                                        wkIndex,
                                        index,
                                        'startTime',
                                        v,
                                    )
                                }
                                shouldDisableTime={time =>
                                    this.shouldDisableTimeStart(
                                        time,
                                        b,
                                        wkIndex,
                                        maxTimeConstraints,
                                    )
                                }
                                slotProps={{
                                    textField: {
                                        helperText:
                                            errorFields.find(
                                                error =>
                                                    error ===
                                                    `breakTimeStart${index}`,
                                            ) && !b.startTime
                                                ? this.displayText(
                                                      'errorEmptyField',
                                                  )
                                                : null,
                                        error:
                                            errorFields.find(
                                                error =>
                                                    error ===
                                                    `breakTimeStart${index}`,
                                            ) && !b.startTime,
                                    },
                                }}
                            />
                        </div>
                        <div className={classes.col}>
                            <MobileTimePicker
                                label={this.displayText('endTimeBreak')}
                                ampm={false}
                                value={
                                    !dayjs.isDayjs(b.endTime)
                                        ? dayjs(b.endTime, `HH:mm:ss`)
                                        : b.endTime
                                }
                                disabled={
                                    !workTimes[wkIndex].startTime ||
                                    !workTimes[wkIndex].endTime ||
                                    disabled
                                }
                                onChange={v =>
                                    this.handleBreakChange(
                                        wkIndex,
                                        index,
                                        'endTime',
                                        v,
                                    )
                                }
                                shouldDisableTime={(time, view) =>
                                    this.shouldDisableTimeEnd(
                                        time,
                                        b,
                                        wkIndex,
                                        maxTimeConstraints,
                                        view,
                                    )
                                }
                                slotProps={{
                                    textField: {
                                        helperText:
                                            errorFields.find(
                                                error =>
                                                    error ===
                                                    `breakTimeEnd${index}`,
                                            ) && !b.endTime
                                                ? this.displayText(
                                                      'errorEmptyField',
                                                  )
                                                : null,
                                        error:
                                            errorFields.find(
                                                error =>
                                                    error ===
                                                    `breakTimeEnd${index}`,
                                            ) && !b.endTime,
                                        disabled: disabled,
                                    },
                                }}
                            />
                        </div>
                        {!disabled && (
                            <IconButton
                                size={`small`}
                                id={`delete-break`}
                                onClick={() => this.deleteBreak(wkIndex, index)}
                                children={<DeleteIcon />}
                                className={classes.iconButton}
                            />
                        )}
                    </div>
                ))}
            </div>
        )
    }

    _shouldDisabledEndTime(time, startTime, maxTimeConstraints) {
        if (
            !maxTimeConstraints ||
            (!maxTimeConstraints.maxTimeFromDailyLimit &&
                !maxTimeConstraints.maxTimeFromNextEvt)
        )
            return false

        const timeToCheck = this.onlyTime(dayjs(time))

        if (maxTimeConstraints.canBeOvernight) {
            if (
                timeToCheck.isBefore(this.onlyTime(startTime)) &&
                timeToCheck.isAfter(
                    this.earliest([
                        this.onlyTime(maxTimeConstraints.maxTimeFromDailyLimit),
                        this.onlyTime(maxTimeConstraints.maxTimeFromNextEvt),
                    ]),
                )
            ) {
                return true
            }
        } else {
            // Si l'heure est après l'heure de fin la plus tôt
            // Alors on désactive
            const maxTime = this.onlyTime(
                this.earliest([
                    maxTimeConstraints.maxTimeFromDailyLimit,
                    maxTimeConstraints.maxTimeFromNextEvt,
                ]),
            )
            if (timeToCheck.isAfter(maxTime)) {
                return true
            }

            // Si l'heure est avant l'heure de début
            // Alors on désactive
            if (timeToCheck.isBefore(this.onlyTime(startTime))) {
                return true
            }
        }
    }

    _renderOpenedHours(workTime, wkIndex) {
        const { classes } = this.props
        const { errorFields, isSameHours } = this.state
        const { delimitedDate, startTime, endTime, breakTimes } = workTime
        const { startDate, endDate } = delimitedDate
        const disabled = workTime?.id

        if (!workTime) return <></>

        // Récupération des emplacements disponibles pour le worktime en cours d'édition
        let availableTimeSlots = this.getAvailableTimeForDateRange()

        let maxTimeConstraints = null

        // Get max time
        // On calcul le temps maximum en plus des emplacements disponibles
        // Car la date de début ne doit pas être influencée par la date de fin
        if (startTime) maxTimeConstraints = this.getMaxTime(workTime)

        const endTimeIsNotOk = this._shouldDisabledEndTime(
            endTime,
            startTime,
            maxTimeConstraints,
        )

        return (
            <>
                {!isSameHours && startDate && endDate && (
                    <Typography style={{ marginBottom: 10 }}>
                        {this._getLabelStringDates(startDate, endDate)}
                    </Typography>
                )}
                <div
                    className={classes.row}
                    style={{
                        gap: 10,
                    }}
                >
                    <div className={classes.col}>
                        <MobileTimePicker
                            label={this.displayText('startTime')}
                            ampm={false}
                            value={startTime}
                            disabled={!startDate || !endDate || disabled}
                            onChange={newValue => {
                                this.handleTimeChange(
                                    'startTime',
                                    newValue,
                                    wkIndex,
                                )
                            }}
                            shouldDisableTime={time => {
                                if (!availableTimeSlots) return true

                                const timeToCheck = this.onlyTime(dayjs(time))

                                let isAvailable = false
                                for (const timeSlot of availableTimeSlots) {
                                    const start = this.onlyTime(timeSlot.start)
                                    // Si la fin est le lendemain
                                    // On ajoute 1 jour pour pouvoir comparer correctement
                                    const end = timeSlot.end.isAfter(
                                        timeSlot.start,
                                        'date',
                                    )
                                        ? this.onlyTime(timeSlot.end).add(
                                              1,
                                              'day',
                                          )
                                        : this.onlyTime(timeSlot.end)

                                    if (
                                        timeToCheck.isSameOrAfter(start) &&
                                        timeToCheck.isSameOrBefore(end)
                                    ) {
                                        isAvailable = true
                                    }
                                }

                                if (
                                    (startDate === endDate ||
                                        !maxTimeConstraints.canBeOvernight) &&
                                    timeToCheck.isAfter(
                                        this.onlyTime(dayjs(endTime)),
                                    )
                                )
                                    isAvailable = false

                                return !isAvailable
                            }}
                            slotProps={{
                                textField: {
                                    helperText:
                                        errorFields.find(
                                            error => error === 'startTime',
                                        ) && !startTime
                                            ? this.displayText(
                                                  'errorEmptyField',
                                              )
                                            : null,
                                    error:
                                        errorFields.find(
                                            error => error === 'startTime',
                                        ) && !startTime,
                                    disabled: disabled,
                                },
                            }}
                        />
                    </div>
                    <div className={classes.col}>
                        <MobileTimePicker
                            label={this.displayText('endTime')}
                            ampm={false}
                            value={endTime}
                            disabled={!startDate || !endDate || disabled}
                            onChange={newValue => {
                                this.handleTimeChange(
                                    'endTime',
                                    newValue,
                                    wkIndex,
                                )
                            }}
                            shouldDisableTime={time => {
                                if (!availableTimeSlots) return true

                                const timeToCheck = this.onlyTime(dayjs(time))

                                let isAvailable = false
                                for (const timeSlot of availableTimeSlots) {
                                    const start = this.onlyTime(timeSlot.start)
                                    // Si la fin est le lendemain
                                    // On ajoute 1 jour pour pouvoir comparer correctement
                                    const end = timeSlot.end.isAfter(
                                        timeSlot.start,
                                        'date',
                                    )
                                        ? this.onlyTime(timeSlot.end).add(
                                              1,
                                              'day',
                                          )
                                        : this.onlyTime(timeSlot.end)

                                    if (
                                        timeToCheck.isSameOrAfter(start) &&
                                        timeToCheck.isSameOrBefore(end)
                                    ) {
                                        isAvailable = true
                                    }
                                }
                                if (
                                    (startDate === endDate ||
                                        !maxTimeConstraints.canBeOvernight) &&
                                    timeToCheck.isBefore(
                                        this.onlyTime(dayjs(startTime)),
                                    )
                                )
                                    isAvailable = false

                                return !isAvailable
                            }}
                            slotProps={{
                                textField: {
                                    helperText:
                                        errorFields.find(
                                            error => error === 'endTime',
                                        ) && !endTime
                                            ? this.displayText(
                                                  'errorEmptyField',
                                              )
                                            : endTimeIsNotOk
                                            ? this.displayText(
                                                  'errorTooMuchWork',
                                              )
                                            : null,
                                    error:
                                        errorFields.find(
                                            error => error === 'endTime',
                                        ) && !endTime,
                                    disabled: disabled,
                                },
                            }}
                        />
                    </div>
                </div>
                {!disabled && (
                    <Button
                        variant="text"
                        onClick={() => this.addBreak(wkIndex)}
                        color="primary"
                        classes={{
                            root: classes.textButton,
                        }}
                        disabled={!startDate || !endDate}
                    >
                        <AddIcon />
                        {this.displayText('addBreak')}
                    </Button>
                )}
                {this._renderBreaks(wkIndex, breakTimes, maxTimeConstraints)}
            </>
        )
    }

    /**
     * Fonction utilisée pour recréer un objet dayjs en définissant uniquement l'heure
     * Pour permettre de faciliter les comparaisons d'heures
     */
    onlyTime(time) {
        if (!time) return undefined
        if (typeof time === 'string' || time instanceof String) {
            return dayjs(time, 'HH:mm')
        } else {
            return dayjs(`${time.hour()}:${time.minute()}`, 'HH:mm')
        }
    }

    /**
     * Renvoi la date la plus tôt parmi les dates passées en paramètre
     * @param {*} dates
     */
    earliest(dates) {
        // let earliest = dates[0]
        let earliest
        for (const date of dates) {
            if (date && (!earliest || date.isBefore(earliest))) {
                earliest = date
            }
        }
        return earliest
    }

    /**
     * Renvoi la date la plus tardive parmi les dates passées en paramètre
     * @param {*} dates
     */
    latest(dates) {
        let latest = dates[0]
        for (const date of dates) {
            if (date.isAfter(latest)) {
                latest = date
            }
        }
        return latest
    }

    /**
     * Sépare les évènements en plusieurs évènements si ils sont sur plusieurs jours
     * Pour faciliter le traitement
     */
    splitEvents(events) {
        const splitEvents = []
        events.forEach(evt => {
            // Ne pas prendre en compte les évènements sans date de début ou de fin
            if (
                !evt.delimitedDate ||
                !evt.delimitedDate.startDate ||
                !evt.delimitedDate.endDate
            )
                return
            // if (!evt.absenceStatus && (!evt.startTime || !evt.endTime)) return
            // if (
            //     evt.absenceStatus &&
            //     evt.absenceTime &&
            //     (!evt.absenceTime.startTime || !evt.absenceTime.endTime)
            // )
            //     return
            const start = evt.delimitedDate.startDate
            const end = evt.delimitedDate.endDate
            const nbDays = dayjs(end).diff(dayjs(start), 'day') + 1 || 1

            for (let i = 0; i < nbDays; i++) {
                let actualStart = dayjs(start).add(i, 'day')
                let actualEnd = dayjs(end).subtract(nbDays - i - 2, 'day')

                let startTime, endTime
                // Si c'est une absence
                if (evt.absenceStatus) {
                    // Si l'absence n'a pas de date de début ou de fin
                    // C'est qu'elle est toute la journée
                    // Donc on met les heures à 00:00:00 et 23:59:59
                    if (!evt.absenceTime) {
                        startTime = dayjs('00:00:00', 'HH:mm:ss')
                        endTime = dayjs('00:00:00', 'HH:mm:ss')
                    } else {
                        if (evt.absenceTime.startTime)
                            startTime = dayjs(
                                evt.absenceTime.startTime,
                                'HH:mm:ss',
                            )

                        if (evt.absenceTime.endTime)
                            endTime = dayjs(evt.absenceTime.endTime, 'HH:mm:ss')
                    }
                } else {
                    // Si c'est un worktime
                    // On transforme les heures HH:mm:ss du worktime en objet dayjs
                    // Les heures des worktimes non enregistrés sont déjà des objets dayjs
                    if (evt.startTime)
                        startTime = dayjs.isDayjs(evt.startTime)
                            ? evt.startTime
                            : dayjs(evt.startTime, 'HH:mm:ss')

                    if (evt.endTime)
                        endTime = dayjs.isDayjs(evt.endTime)
                            ? evt.endTime
                            : dayjs(evt.endTime, 'HH:mm:ss')
                }

                // Si l'évènement est sur une seule journée
                // On soustrait un jour à l'heure de fin
                if (
                    startTime &&
                    endTime &&
                    this.onlyTime(startTime).isBefore(this.onlyTime(endTime))
                ) {
                    actualEnd = actualEnd.subtract(1, 'day')
                }

                const dateTimeStart = startTime
                    ? actualStart
                          .hour(startTime.hour())
                          .minute(startTime.minute())
                    : undefined

                const dateTimeEnd = endTime
                    ? actualEnd.hour(endTime.hour()).minute(endTime.minute())
                    : undefined

                splitEvents.push({
                    ...evt,
                    delimitedDate: {
                        startDate: actualStart,
                        endDate: actualStart.isSame(actualEnd, 'day')
                            ? actualEnd.add(1, 'day')
                            : actualEnd,
                    },
                    dateTimeStart,
                    dateTimeEnd,
                })
            }
        })
        splitEvents.sort((wt1, wt2) => {
            return dayjs(wt1.delimitedDate.startDate).isBefore(
                dayjs(wt2.delimitedDate.startDate),
            )
                ? -1
                : 1
        })

        return splitEvents
    }

    getNextEvent(editingWorkTime) {
        const { workTimes, workTimes: dateRanges } = this.props

        const editingWorkTimeStartDate = dayjs(
            editingWorkTime.delimitedDate.startDate,
        )

        // On va parcourir les worktimes enregistrés et non enregistrés
        // pour trouver le worktime juste après le worktime en cours d'édition
        // afin de déterminer l'heure maximum de fin du worktime en cours d'édition
        const filteredDateRanges = dateRanges
            .filter(dr => {
                // Filtrage des dateranges pour ne garder que celles dont les valeurs sont définies
                if (
                    !dr.delimitedDate?.startDate ||
                    !dr.startTime ||
                    !dr.endTime
                )
                    return false

                // Filtrer le daterange en cours d'édition pour ne pas créer de conflit
                if (
                    dayjs(dr.delimitedDate.startDate).isSame(
                        editingWorkTimeStartDate,
                    ) &&
                    editingWorkTime?.startTime === dr.startTime &&
                    editingWorkTime?.endTime === dr.endTime
                )
                    return false

                return true
            })
            .map(dr => {
                //Les heures sont mises au même format que celles des worktimes
                return {
                    ...dr,
                    startTime: `${dayjs(dr.startTime).hour()}:${dayjs(
                        dr.startTime,
                    ).minute()}:00`,
                    endTime: `${dayjs(dr.endTime).hour()}:${dayjs(
                        dr.startTime,
                    ).minute()}:00`,
                }
            })

        // Tri des worktimes par ordre décroissant (du plus récent au plus ancien)
        let sortedWt = workTimes
            // On ne prend que les worktimes qui sont avant le worktime en cours d'édition
            .concat(filteredDateRanges)
            .filter(wt => {
                const start = wt.startTime
                    ? wt.startTime.split(':')
                    : wt.absenceTime
                    ? wt.absenceTime.startTime.split(':')
                    : '00:00:00'.split(':')
                const end = wt.endTime
                    ? wt.endTime.split(':')
                    : wt.absenceTime
                    ? wt.absenceTime.endTime.split(':')
                    : '00:00:00'.split(':')
                //Retourne si la date du worktime est avant la date du worktime en cours d'édition
                return dayjs(wt.delimitedDate.startDate)
                    .hour(start[0])
                    .minute(start[1])
                    .isAfter(editingWorkTimeStartDate)
                //  ||
                // dayjs(wt.delimitedDate.startDate).isSame(
                //     ///////pose problème
                //     editingWorkTimeStartDate,
                // )
            })
            .sort((wt1, wt2) => {
                return dayjs(wt1.delimitedDate.startDate) <
                    dayjs(wt2.delimitedDate.startDate)
                    ? 1
                    : -1
            })

        if (sortedWt.length > 0) {
            return sortedWt[0]
        }
    }

    _renderCommentField() {
        const { classes } = this.props
        const { comment } = this.state

        return (
            <TextField
                id="details"
                variant="outlined"
                label={
                    <>
                        {this.displayText('comment')}
                        <Tooltip
                            title={this.displayText(`commentTooltip`)}
                            classes={{
                                tooltip: classes.tooltip,
                            }}
                        >
                            <HelpIcon className={classes.helpIcon} />
                        </Tooltip>
                    </>
                }
                margin={`normal`}
                multiline
                minRows={5}
                maxRows={5}
                value={comment || ''}
                onChange={e => {
                    this.setState({
                        comment: e.target.value,
                    })
                }}
                className={classes.commentField}
                InputProps={{
                    classes: {
                        input: classes.input,
                        marginDense: classes.marginInput,
                    },
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.labelInput,
                    },
                    style: { pointerEvents: 'auto' },
                }}
            />
        )
    }

    // A mettre dans les actions
    onUserChange = value => {
        const { groupMembers, workTimeGroups } = this.props
        const tempArray = []

        // SI c'est un nouvel utilisateur ajouté par la modale
        if (value?.emailAddUser) {
            tempArray.push(
                groupMembers.find(
                    u => u?.userSubscribe?.mail === value.emailAddUser,
                ),
            )
        } else {
            value.forEach(v => {
                if (
                    v?.user?.id ||
                    (v?.id && !v?.user && !v?.userSubscribe) ||
                    v?.userSubscribe
                )
                    tempArray.push(v)
            })
        }
        const selectedUser = tempArray[tempArray.length - 1]
        const userGroup =
            workTimeGroups.find(wtg =>
                wtg.userLinks.find(
                    ul =>
                        ul.user?.id === selectedUser?.user?.id ||
                        (ul.userSubscribe &&
                            selectedUser?.userSubscribe &&
                            ul.userSubscribe?.id ===
                                selectedUser?.userSubscribe?.id),
                ),
            ) ?? workTimeGroups.find(wtg => wtg.byDefault)
        this.setState({
            userGroup: userGroup,
            userLink: selectedUser,
        })

        // Récupération des worktimes de l'utilisateur sélectionné
        // Afin de pouvoir déterminer quels jours seront désactivés
        if (selectedUser?.id) {
            requestApi(`/work-times/user-link/${selectedUser?.id}`, 'GET').then(
                result => {
                    this.setState({
                        selectedUserWorkTimes: result,
                    })
                },
                error => {
                    this.setState({
                        selectedUserWorkTimes: null,
                    })
                },
            )

            requestApi(
                `/user-absences/user-link/${selectedUser?.id}`,
                'GET',
            ).then(
                result => {
                    this.setState({
                        selectedUserAbsences: result,
                    })
                },
                error => {
                    this.setState({
                        selectedUserAbsences: null,
                    })
                },
            )
        } else {
            this.setState({
                selectedUserWorkTimes: null,
                selectedUserAbsences: null,
            })
        }
    }

    onManagerChange = value => {
        const { groupMembers } = this.props
        const tempArray = []
        // SI c'est un nouvel utilisateur ajouté par la modale
        if (value?.emailAddUser) {
            tempArray.push(
                groupMembers.find(
                    u => u?.userSubscribe?.mail === value.emailAddUser,
                ),
            )
        } else {
            value.forEach(v => {
                if (
                    v?.user?.id ||
                    (v?.id && !v?.user && !v?.userSubscribe) ||
                    v?.userSubscribe
                )
                    tempArray.push(v)
            })
        }
        const selectedUser = tempArray[tempArray.length - 1]
        this.setState({
            responsibleLink: selectedUser,
        })
    }

    _renderUserSelector() {
        const { workTime } = this.props
        const { userLink, errorFields } = this.state

        const disabled = workTime?.id

        return (
            <UserSelector
                required={!disabled}
                disabled={disabled}
                onChange={this.onUserChange}
                label={this.displayText('user')}
                errorMessageMandatory={this.displayText('errorMandatoryUser')}
                value={[userLink]}
                error={
                    !userLink && errorFields.find(error => error === 'userLink')
                        ? 'error'
                        : undefined
                }
                tooltip={this.displayText('userTooltip')}
            />
        )
    }

    _renderEssential() {
        const {
            classes,
            currentGroupId,
            user,
            groupMembers,
            workTime,
        } = this.props
        const {
            workTimes,
            openSettingIndex,
            isSameHours,
            byManager,
        } = this.state
        const userLink = groupMembers.filter(
            member => member?.user?.id === user?.id,
        )

        return (
            <>
                {workTime?.id && (
                    <BoatOnAlert
                        severity="info"
                        description={this.displayText('noModification')}
                    />
                )}
                <div className={classes.firstPartInfos}>
                    {(byManager ||
                        currentGroupId === user.ownGroup.id ||
                        (userLink.length === 1 &&
                            userLink[0].userRole.userRoleId === 1)) &&
                        this._renderUserSelector()}
                    {this._renderManager()}
                    {this._renderDates()}
                </div>
                {!isSameHours &&
                    workTimes.map((wt, index) =>
                        index === openSettingIndex
                            ? this._renderOpenedHours(wt, index)
                            : this._renderClosedHours(wt, index),
                    )}
                {isSameHours && this._renderOpenedHours(workTimes[0], 0)}
                {this._renderCommentField()}
            </>
        )
    }

    _getContextButton() {
        const { workTime } = this.props
        const baseTab = [
            {
                label: workTime?.id
                    ? this.displayText(`update`)
                    : this.displayText(`validateTask`),
                action: this.saveWorkTime,
                type: `primary`,
                permissions: {
                    action: Actions.Create,
                    entityName: PermissionsEntities.WorkTime,
                },
            },
        ]
        return baseTab
    }

    calculateTotalTime() {
        const { workTimes } = this.state
        let total = 0

        for (const workTime of workTimes) {
            const nbDays =
                dayjs(workTime.delimitedDate.endDate).diff(
                    workTime.delimitedDate.startDate,
                    'day',
                ) + 1
            let startTime = this.onlyTime(workTime.startTime)
            let endTime = this.onlyTime(workTime.endTime)
            const breaks = workTime.breakTimes

            for (let i = 0; i < nbDays; i++) {
                if (startTime && endTime) {
                    // Si l'heure de fin est avant l'heure de début
                    // On ajoute 24h à l'heure de fin pour que le calcul soit correct

                    if (
                        this.onlyTime(endTime).isBefore(
                            this.onlyTime(startTime),
                        )
                    ) {
                        endTime = this.onlyTime(endTime).add('24', 'hour')
                    }

                    total += endTime.diff(this.onlyTime(startTime))
                    if (breaks && Array.isArray(breaks) && breaks.length > 0)
                        for (const b of breaks) {
                            if (b.startTime && b.endTime) {
                                // Si l'heure de fin est avant l'heure de début
                                // On ajoute 24h à l'heure de fin pour que le calcul soit correct

                                let breakEndTime = this.onlyTime(b.endTime)

                                if (
                                    breakEndTime.isBefore(
                                        this.onlyTime(b.startTime),
                                    )
                                ) {
                                    breakEndTime = breakEndTime.add(
                                        '24',
                                        'hour',
                                    )
                                }

                                let breakTime = breakEndTime.diff(
                                    this.onlyTime(b.startTime),
                                )
                                total -= breakTime
                            }
                        }
                }
            }
        }

        const h = total / 3600000 - (total % 3600000) / 3600000

        const m = (total % 3600000) / 60000

        return (
            <span>
                {h.toFixed().padStart(2, '0')}h
                {m
                    .toFixed()
                    .toString()
                    .padStart(2, '0')}
            </span>
        )
    }

    _renderActions(CTAs) {
        const { classes } = this.props

        if (!CTAs) return <></>

        return (
            <DialogActions className={classes.footer}>
                <span className={classes.totalTime}>
                    Total: {this.calculateTotalTime()}
                </span>
                {CTAs.map((cta, index) => {
                    return cta.permissions ? (
                        <BoatOnBlock
                            permissions={cta.permissions}
                            onlyOnePermission={cta.onlyOnePermission}
                            addedCondition={cta.addedCondition}
                            key={index}
                            children={
                                <BobButton
                                    primary
                                    label={cta.label}
                                    onClick={cta.action}
                                    style={{
                                        minWidth: cta.minWidth || 0,
                                        margin: 10,
                                        height: `calc(100% - 20px)`,
                                    }}
                                    endIcon={cta.endIcon || null}
                                    disabled={cta.disabled}
                                    type={cta.type}
                                    form={cta.form}
                                    size={`large`}
                                />
                            }
                        />
                    ) : (
                        <BobButton
                            primary
                            label={cta.label}
                            onClick={cta.action}
                            style={{
                                minWidth: cta.minWidth || 0,
                                margin: 10,
                                height: `calc(100% - 20px)`,
                            }}
                            endIcon={cta.endIcon || null}
                            disabled={cta.disabled}
                            type={cta.type}
                            form={cta.form}
                            size={`large`}
                            key={index}
                        />
                    )
                })}
            </DialogActions>
        )
    }

    render() {
        const {
            title,
            workTime,
            workTimeLoading,
            absencesLoading,
            noCross = true,
        } = this.props
        const { userGroup } = this.state

        if (!userGroup || workTimeLoading > 0 || absencesLoading > 0) {
            return this.renderLoading(100, 100)
        }

        return (
            <>
                {this._renderTitle(title, noCross, [
                    this.displayText(`essentialInfos`),
                    workTime?.id ? this.displayText(`activity`) : undefined,
                ])}
                {this._renderBody({
                    bodies: [
                        this._renderEssential(),
                        workTime?.id
                            ? this._renderActivity(
                                  this.props.activity,
                                  workTime,
                                  'workTime',
                              )
                            : undefined,
                    ],
                })}
                {this._renderActions(this._getContextButton())}
            </>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname

    return {
        boat: state.bob.boat,
        loading: state.bob.loading,
        activity: state.activity.activity,
        user: state.authentication.user,
        userLinks: state.group?.groupsMembers?.linkRGU || [],
        boatsGroup: state.group.groupsMembers
            ? state.group.groupsMembers.boats
            : null,
        absences: state.absence.absences,
        groupMembers: state.group?.groupsMembers?.linkRGU || [],
        workTimeGroups: state.settings.workTimeSettings,
        currentGroupId: state.group?.currentGroupId,
        workTimes: state.workTime.workTimes,
        workTimesManager: state.workTime.workTimesManager,
        absencesLoading: state.absence.loading,
        workTimeLoading: state.workTime.loading,
        chronoWorkTime: state.chrono.workTime,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(WorkTimeModal))
