export default {
    engine: {
        FR: 'Moteur',
        EN: 'Engine',
    },
    sailing: {
        FR: 'Voilier',
        EN: 'Sailing',
    },
    pneumatic: {
        FR: 'Semi-Rigide',
        EN: 'Pneumatic',
    },
    catamaran: {
        FR: 'Catamaran',
        EN: 'Catamaran',
    },
    barge: {
        FR: 'Péniche',
        EN: 'Barge',
    },
    jetSki: {
        FR: 'Jet-Ski',
        EN: 'Jet-Ski',
    },
    boatName: {
        FR: 'Nom du bateau',
        EN: 'Boat name',
    },
    boatType: {
        FR: 'Type du bateau',
        EN: 'Boat type',
    },
    boatYear: {
        FR: 'Année du bateau',
        EN: 'Boat year',
    },
    boatMaker: {
        FR: 'Marque du bateau',
        EN: 'Boat Maker',
    },
    boatModel: {
        FR: 'Modèle du bateau',
        EN: 'Boat model',
    },
    boatRegistration: {
        FR: 'Immatriculation',
        EN: 'Boat registration',
    },
    boatEngine: {
        FR: 'Moteur',
        EN: 'Engine',
    },
    homePortOrStorage: {
        FR: 'Port ou lieu de stockage',
        EN: 'Homeport or storage area',
    },
    createBoat: {
        FR: 'Je crée mon bateau',
        EN: 'Create my boat',
    },
    boatNeeded: {
        FR:
            "Oups, il semble que vous n'ayez pas enregistré les informations de base de votre bateau. Cliquez sur le bouton ci-dessous pour les ajouter.",
        EN:
            "Oops, it seems you didn't record the basic information of your boat. Click on the button below to add them.",
    },
    addBoat: {
        FR: "J'ajoute mon bateau",
        EN: 'I add my boat',
    },
    nothing: {
        FR:
            'Oups, votre BoatOn Book est vide ! Ajoutez vos prochaines échéances d’entretien en cliquant sur « Mes alertes », vos frais et revenus en cliquant sur « Mes dépenses & mes revenus » ou vos documents importants en cliquant sur « Mes documents ». Rendez-vous dans le menu juste à gauche de votre écran pour retrouver toutes ces options.',
        EN:
            'Oops, your BoatOn Book is empty! Add your next maintenance deadlines by clicking on "My alerts", your fees and revenues by clicking on "My expenses & revenues" or your important documents by clicking on "My documents". Go to the menu just to the left of your screen to find all these options.',
    },
    missingInfo: {
        FR:
            'Les informations suivantes sont nécessaires afin de créer votre bateau: nom, marque, modèle et port ou lieu de stockage.',
        EN:
            'The following information is required to create your boat: name, make, model and port or storage location.',
    },
    storage: {
        FR: 'Port ou lieu de stockage',
        EN: 'Port or storage location',
    },
    boatAdd: {
        FR: "Ajout d'un bateau",
        EN: 'Add a boat',
    },
    enginesAdd: {
        FR: 'Ajout des moteurs',
        EN: 'Add engines',
    },
    missingBoat: {
        FR: `Bateau manquant`,
        EN: `Missing boat`,
    },
}
