import { boatConstants } from '../../constants'

export const boatReducer = (prevState, action) => {
    const address =
        action.boat && action.boat.address && action.boat.address.fullAddress
            ? {
                  fullText: action.boat.address.fullAddress,
                  latitude: action.boat.address.latitude.toString(),
                  longitude: action.boat.address.longitude.toString(),
              }
            : null

    let newBoat = null

    switch (action.type) {
        case boatConstants.REQUEST_BOAT:
            return {
                ...prevState,
                boat: null,
                loading: prevState.loading + 1,
            }
        case boatConstants.NO_BOAT:
            return {
                ...prevState,
                boat: null,
                events: [],
            }
        case boatConstants.UPDATE_BOAT:
            return {
                ...prevState,
                boat: null,
                loading: prevState.loading + 1,
            }
        case boatConstants.BOAT_SUCCESS:
            newBoat = {
                ...action.boat,
                boatTypeId:
                    action.boat.boatType && action.boat.boatType.id
                        ? action.boat.boatType.id
                        : -1,
                address,
            }

            return {
                ...prevState,
                boat: newBoat,
                loading: prevState.loading - 1,
            }
        case boatConstants.BOAT_FAILURE:
            return {
                ...prevState,
                boat: null,
                loading: prevState.loading - 1,
            }
        case boatConstants.BOAT_SUCCESS_WITHOUT_LOADING:
            newBoat = {
                ...action.boat,
                boatTypeId:
                    action.boat.boatType && action.boat.boatType.id
                        ? action.boat.boatType.id
                        : -1,
                address,
            }

            return {
                ...prevState,
                boat: newBoat,
            }
        default:
            return prevState
    }
}
