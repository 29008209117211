export default theme => ({
    fields: {
        width: '60%',
        margin: 'auto',
    },
    buttons: {
        margin: 'auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    button: {
        margin: '15px',
        marginTop: '25px',
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            width: '85%',
            height: '5%',
            margin: 'auto',
            marginTop: '5%',
            marginBottom: '2%',
            fontSize: '12px',
        },
    },
})
