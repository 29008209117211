export default theme => ({
    root: {
        width: '100%',
        boxSizing: 'border-box',
    },
    blocs: {
        display: 'grid',
        gridTemplateColumns:
            'repeat(auto-fill, [col-start] minmax(500px, auto) [col-end])',
        gridGap: 20,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            padding: 0,
            gridTemplateColumns: '100%',
            gridGap: '10px',
        },
        boxSizing: 'border-box',
    },
    bloc: {
        position: 'relative',
        boxSizing: 'border-box',
        minWidth: '450px',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            minWidth: 0,
            padding: '10px',
            marginBottom: '10px',
            margin: 0,
        },
    },
    imageHead: {
        width: '100%',
        height: '175px',
        position: 'relative',
        backgroundColor: '#3040a01c',
        marginBottom: '10px',
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
        margin: 'auto',
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
    title: {
        fontSize: 24,
        marginBottom: '10px',
        color: '#3040a0',
        fontWeight: 500,
    },
    mobileTitle: {
        fontWeight: 500,
        marginBottom: '10px',
    },
    line: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: '10px',
        alignItems: 'center',
    },
    iconDiv: {
        width: 35,
        [theme.breakpoints.down('sm')]: {
            width: 30,
        },
    },
    icon: {
        marginRight: '5px',
        height: 'auto',
        width: '100%',
        display: 'block',
    },
    infos: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    infosBlock: {
        width: 'calc(100% - 50px)',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        [theme.breakpoints.down('sm')]: {
            width: 'calc(100% - 35px)',
        },
    },
    texts40: {
        width: 'calc(40% - 20px)',
        margin: '0 10px',
    },
    texts60: {
        width: 'calc(60%)',
    },
    description: {
        fontSize: 18,
        fontWeight: 500,
        color: '#3040a0',
        lineHeight: 1.2,
    },
    helpText: {
        fontSize: 14,
        lineHeight: 1.2,
    },
    colorRed: {
        color: '#f44336',
    },
    tooltip: {
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
    },
    buttonsContainer: {
        display: 'flex',
        flexDirection: 'row-reverse',
        position: 'absolute',
        zIndex: 1,
        top: 15,
        right: 20,
        padding: 5,
        alignItems: 'center',
    },
    bellIcon: {
        width: '20px',
        height: '20px',
        marginLeft: '0px',
        marginTop: '5px',
        [theme.breakpoints.down('md')]: {
            width: '15px',
            height: '15px',
            marginBottom: '5px',
        },
    },
    bellBtn: {
        minWidth: 'unset',
    },
    dateAlertRed: {
        width: '40px',
        height: '30px',
        backgroundColor: '#f44336',
        borderRadius: '5px',
        boxShadow:
            '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
        [theme.breakpoints.down('md')]: {
            width: '25px',
            height: '25px',
        },
    },
})
