import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import BoatOnComponent from './BoatOnComponent'
import styles from './Styles/BoatOnAvatarCss'
import { Typography } from '@material-ui/core'

class BoatOnAvatar extends BoatOnComponent {
    renderImageAvatar() {
        const { classes } = this.props
        //TODO: a faire quand un utilisateur pourra mettre une photo
        return <div className={classes.root}></div>
    }

    renderLetterAvatar() {
        const { classes, user } = this.props
        const firstName = user.firstName
        const lastName = user.lastName

        return (
            <div className={classes.root}>
                <Typography className={classes.letters}>
                    {firstName[0]}
                    {lastName[0]}
                </Typography>
            </div>
        )
    }

    render() {
        const { user } = this.props

        if (user.files) return this.renderImageAvatar()
        return this.renderLetterAvatar()
    }
}
export default withStyles(styles)(BoatOnAvatar)
