export default {
    selectBoat: {
        FR: 'Nom de votre bateau',
        EN: 'Name of your boat',
    },
    noMaintenance: {
        FR: 'Pas de tâche à venir dans les 3 prochains mois',
        EN: 'No task to come in the next 3 months',
    },
    maintenancePlural: {
        FR: 'tâches bientôt à échéance',
        EN: 'tasks soon to expire',
    },
    maintenanceSingular: {
        FR: 'tâche bientôt à échéance',
        EN: 'task soon to expire',
    },
    maintenanceLateSingular: {
        FR: 'tâche en retard',
        EN: 'overdue task',
    },
    maintenanceLatePlural: {
        FR: 'tâches en retard',
        EN: 'overdue tasks',
    },
    hour: {
        FR: 'heure moteur au compteur au',
        EN: 'engine hour on',
    },
    hours: {
        FR: 'heures moteur au compteur au',
        EN: 'engine hours on',
    },
    spent: {
        FR: '€ dépensé en',
        EN: '€ spent in',
    },
    spents: {
        FR: '€ dépensés en',
        EN: '€ spent in',
    },
    earned: {
        FR: '€ gagné en',
        EN: '€ earned in',
    },
    earneds: {
        FR: '€ gagnés en',
        EN: '€ earned in',
    },
    addBoat: {
        FR: `Ajouter un bateau`,
        EN: `Add a boat`,
    },
}
