export default theme => ({
    modal: {
        width: 'min-content',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    modalContent: {
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        alignItems: 'center',
    },
    yearList: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 400,
        overflowY: 'visible',
        alignItems: 'center',
        gap: 30,
        maxWidth: 'min-content',
        width: '100%',
    },
    yearRow: {
        display: 'flex',
        alignItems: 'center',
        textWrap: 'nowrap',
        width: '100%',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            textWrap: 'wrap',
        },
    },
    disabled: {
        color: 'darkgrey',
    },
    dateRangeLabel: {
        fontWeight: 500,
    },
    inputContainer: {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
    },
    yearInput: {
        width: 75,
        position: 'unset',
        '& input': {
            textAlign: 'center',
            paddingLeft: 24,
        },
    },
    applyEverywhere: {
        minWidth: 150,
        color: 'blue',
        textDecoration: 'underline',
        cursor: 'pointer',
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            minWidth: 60,
        },
    },
    toggle: {
        color: 'blue',
        textDecoration: 'underline',
        cursor: 'pointer',
        textAlign: 'center',
    },
    error: {
        color: 'red',
    },
})
