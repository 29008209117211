import React from 'react'
import { connect } from 'react-redux'
import BoatOnModalCore from '../../../../../common/BoatOnModalCore'
import { Button as ButtonBON } from '../../../../../common/BoatOnButton'

import TextField from '@material-ui/core/TextField'
import {
    MailOutline as MailOutlineIcon,
    TextsmsOutlined as TextsmsOutlinedIcon,
    WhatsApp as WhatsAppIcon,
    ContentCopy as ContentCopyIcon,
    Facebook as FacebookIcon,
} from '@mui/icons-material/'
import XIcon from '@mui/icons-material/X'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/ShareModalCss'
import dictionary from './Dictionary/ShareModalDico'

class ShareModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {}

        this.sendMail = this.sendMail.bind(this)
        this.sendSMS = this.sendSMS.bind(this)
        this.sendWhatsApp = this.sendWhatsApp.bind(this)
        this.sendFacebook = this.sendFacebook.bind(this)
        this.sendX = this.sendX.bind(this)
    }

    sendMail() {
        const { currentUser } = this.props
        const subject = this.displayText('mailSubject')
        const userName = `${currentUser?.firstName} ${currentUser?.lastName}`
        const body = `${userName} ${this.displayText('mailBody')} ${
            window.location.href
        }`

        window.location.href = `mailto:?subject=${subject}&body=${encodeURIComponent(
            body,
        )}`
    }

    sendSMS() {
        const { currentUser } = this.props

        const userName = `${currentUser?.firstName} ${currentUser?.lastName}`

        const body = `${userName} ${this.displayText('smsBody')}${
            window.location.href
        }`

        window.location.href = `sms:&body=${encodeURIComponent(body)}`
    }

    sendWhatsApp() {
        const { currentUser } = this.props

        const userName = `${currentUser?.firstName} ${currentUser?.lastName}`
        const body = `${userName} ${this.displayText('whatsappBody')} ${
            window.location.href
        }`

        window.location.href = `whatsapp://send?text=${encodeURIComponent(
            body,
        )}`
    }

    sendFacebook() {
        const body = window.location.href

        window.location.href = `https://www.facebook.com/sharer.php?u=${body}`
    }

    sendX() {
        const { currentUser } = this.props

        const userName = `${currentUser?.firstName} ${currentUser?.lastName}`
        const url = window.location.href
        const body = `${userName} ${encodeURIComponent(
            this.displayText('xBody'),
        )}&url=${url}`

        window.location.href = `https://twitter.com/intent/tweet?text=${body}`
    }

    copyLink() {
        navigator.clipboard.writeText(window.location.href)
    }

    _renderBody() {
        const { classes } = this.props

        return (
            <div className={classes.root}>
                <ButtonBON
                    type="light"
                    label={
                        <>
                            <MailOutlineIcon className={classes.buttonIcon} />
                            {this.displayText('email')}
                        </>
                    }
                    size={`large`}
                    fullWidth={true}
                    onClick={this.sendMail}
                />

                <ButtonBON
                    type="light"
                    label={
                        <>
                            <TextsmsOutlinedIcon
                                className={classes.buttonIcon}
                            />
                            {this.displayText('sms')}
                        </>
                    }
                    size={`large`}
                    fullWidth={true}
                    onClick={this.sendSMS}
                />

                <ButtonBON
                    type="light"
                    label={
                        <>
                            <WhatsAppIcon className={classes.buttonIcon} />
                            Whatsapp
                        </>
                    }
                    size={`large`}
                    fullWidth={true}
                    onClick={this.sendWhatsApp}
                />

                <ButtonBON
                    type="light"
                    label={
                        <>
                            <FacebookIcon className={classes.buttonIcon} />
                            Facebook
                        </>
                    }
                    size={`large`}
                    fullWidth={true}
                    onClick={this.sendFacebook}
                />

                <ButtonBON
                    type="light"
                    label={
                        <>
                            <XIcon className={classes.buttonIcon} />X
                        </>
                    }
                    size={`large`}
                    fullWidth={true}
                    onClick={this.sendX}
                />

                <div className={classes.linkContainer}>
                    {this.displayText('link')}
                    <TextField
                        readOnly
                        variant="outlined"
                        value={window.location.href}
                        fullWidth={true}
                    />
                    <ButtonBON
                        type="light"
                        label={
                            <>
                                <ContentCopyIcon
                                    className={classes.buttonIcon}
                                />
                                {this.displayText('copyLink')}
                            </>
                        }
                        size={`large`}
                        fullWidth={true}
                        onClick={this.copyLink}
                    />
                </div>
            </div>
        )
    }

    render() {
        const { title, noCross = true, showFollowButton } = this.props

        return (
            <>
                {this._renderTitle(
                    title,
                    noCross,
                    null,
                    undefined,
                    showFollowButton,
                )}
                {this._renderBody({
                    body: this._renderBody(),
                })}
            </>
        )
    }
}

function mapStateToProps(state) {
    const isMobile = window.innerWidth < 600

    return {
        isMobile,
        currentUser: state.authentication?.user || null,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ShareModal))
