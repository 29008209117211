export const countries = {
    afghanistan: {
        FR: 'Afghanistan',
        EN: 'Afghanistan',
    },
    albania: {
        FR: 'Albanie',
        EN: 'Albania',
    },
    algeria: {
        FR: 'Algérie',
        EN: 'Algeria',
    },
    andorra: {
        FR: 'Andorre',
        EN: 'Andorra',
    },
    angola: {
        FR: 'Angola',
        EN: 'Angola',
    },
    argentina: {
        FR: 'Argentine',
        EN: 'Argentina',
    },
    armenia: {
        FR: 'Arménie',
        EN: 'Armenia',
    },
    australia: {
        FR: 'Australie',
        EN: 'Australia',
    },
    austria: {
        FR: 'Autriche',
        EN: 'Austria',
    },
    azerbaijan: {
        FR: 'Azerbaïdjan',
        EN: 'Azerbaijan',
    },
    bahamas: {
        FR: 'Bahamas',
        EN: 'Bahamas',
    },
    bahrain: {
        FR: 'Bahreïn',
        EN: 'Bahrain',
    },
    bangladesh: {
        FR: 'Bangladesh',
        EN: 'Bangladesh',
    },
    barbados: {
        FR: 'Barbade',
        EN: 'Barbados',
    },
    belarus: {
        FR: 'Biélorussie',
        EN: 'Belarus',
    },
    belgium: {
        FR: 'Belgique',
        EN: 'Belgium',
    },
    belize: {
        FR: 'Belize',
        EN: 'Belize',
    },
    benin: {
        FR: 'Bénin',
        EN: 'Benin',
    },
    bhutan: {
        FR: 'Bhoutan',
        EN: 'Bhutan',
    },
    bolivia: {
        FR: 'Bolivie',
        EN: 'Bolivia',
    },
    bosnia_and_herzegovina: {
        FR: 'Bosnie-Herzégovine',
        EN: 'Bosnia and Herzegovina',
    },
    botswana: {
        FR: 'Botswana',
        EN: 'Botswana',
    },
    brazil: {
        FR: 'Brésil',
        EN: 'Brazil',
    },
    brunei: {
        FR: 'Brunéi',
        EN: 'Brunei',
    },
    bulgaria: {
        FR: 'Bulgarie',
        EN: 'Bulgaria',
    },
    burkina_faso: {
        FR: 'Burkina Faso',
        EN: 'Burkina Faso',
    },
    burundi: {
        FR: 'Burundi',
        EN: 'Burundi',
    },
    cambodia: {
        FR: 'Cambodge',
        EN: 'Cambodia',
    },
    cameroon: {
        FR: 'Cameroun',
        EN: 'Cameroon',
    },
    canada: {
        FR: 'Canada',
        EN: 'Canada',
    },
    cape_verde: {
        FR: 'Cap-Vert',
        EN: 'Cape Verde',
    },
    central_african_republic: {
        FR: 'République centrafricaine',
        EN: 'Central African Republic',
    },
    chad: {
        FR: 'Tchad',
        EN: 'Chad',
    },
    chile: {
        FR: 'Chili',
        EN: 'Chile',
    },
    china: {
        FR: 'Chine',
        EN: 'China',
    },
    colombia: {
        FR: 'Colombie',
        EN: 'Colombia',
    },
    comoros: {
        FR: 'Comores',
        EN: 'Comoros',
    },
    congo: {
        FR: 'Congo',
        EN: 'Congo',
    },
    costa_rica: {
        FR: 'Costa Rica',
        EN: 'Costa Rica',
    },
    croatia: {
        FR: 'Croatie',
        EN: 'Croatia',
    },
    cuba: {
        FR: 'Cuba',
        EN: 'Cuba',
    },
    cyprus: {
        FR: 'Chypre',
        EN: 'Cyprus',
    },
    czech_republic: {
        FR: 'République tchèque',
        EN: 'Czech Republic',
    },
    denmark: {
        FR: 'Danemark',
        EN: 'Denmark',
    },
    djibouti: {
        FR: 'Djibouti',
        EN: 'Djibouti',
    },
    dominica: {
        FR: 'Dominique',
        EN: 'Dominica',
    },
    dominican_republic: {
        FR: 'République dominicaine',
        EN: 'Dominican Republic',
    },
    east_timor: {
        FR: 'Timor oriental',
        EN: 'East Timor',
    },
    ecuador: {
        FR: 'Équateur',
        EN: 'Ecuador',
    },
    egypt: {
        FR: 'Égypte',
        EN: 'Egypt',
    },
    el_salvador: {
        FR: 'Salvador',
        EN: 'El Salvador',
    },
    equatorial_guinea: {
        FR: 'Guinée équatoriale',
        EN: 'Equatorial Guinea',
    },
    eritrea: {
        FR: 'Érythrée',
        EN: 'Eritrea',
    },
    estonia: {
        FR: 'Estonie',
        EN: 'Estonia',
    },
    eswatini: {
        FR: 'Eswatini',
        EN: 'Eswatini',
    },
    ethiopia: {
        FR: 'Éthiopie',
        EN: 'Ethiopia',
    },
    fiji: {
        FR: 'Fidji',
        EN: 'Fiji',
    },
    finland: {
        FR: 'Finlande',
        EN: 'Finland',
    },
    france: {
        FR: 'France',
        EN: 'France',
    },
    gabon: {
        FR: 'Gabon',
        EN: 'Gabon',
    },
    gambia: {
        FR: 'Gambie',
        EN: 'Gambia',
    },
    georgia: {
        FR: 'Géorgie',
        EN: 'Georgia',
    },
    germany: {
        FR: 'Allemagne',
        EN: 'Germany',
    },
    ghana: {
        FR: 'Ghana',
        EN: 'Ghana',
    },
    greece: {
        FR: 'Grèce',
        EN: 'Greece',
    },
    grenada: {
        FR: 'Grenade',
        EN: 'Grenada',
    },
    guatemala: {
        FR: 'Guatemala',
        EN: 'Guatemala',
    },
    guinea: {
        FR: 'Guinée',
        EN: 'Guinea',
    },
    guinea_bissau: {
        FR: 'Guinée-Bissau',
        EN: 'Guinea-Bissau',
    },
    guyana: {
        FR: 'Guyana',
        EN: 'Guyana',
    },
    haiti: {
        FR: 'Haïti',
        EN: 'Haiti',
    },
    honduras: {
        FR: 'Honduras',
        EN: 'Honduras',
    },
    hungary: {
        FR: 'Hongrie',
        EN: 'Hungary',
    },
    iceland: {
        FR: 'Islande',
        EN: 'Iceland',
    },
    india: {
        FR: 'Inde',
        EN: 'India',
    },
    indonesia: {
        FR: 'Indonésie',
        EN: 'Indonesia',
    },
    iran: {
        FR: 'Iran',
        EN: 'Iran',
    },
    iraq: {
        FR: 'Irak',
        EN: 'Iraq',
    },
    ireland: {
        FR: 'Irlande',
        EN: 'Ireland',
    },
    israel: {
        FR: 'Israël',
        EN: 'Israel',
    },
    italy: {
        FR: 'Italie',
        EN: 'Italy',
    },
    ivory_coast: {
        FR: "Côte d'Ivoire",
        EN: 'Ivory Coast',
    },
    jamaica: {
        FR: 'Jamaïque',
        EN: 'Jamaica',
    },
    japan: {
        FR: 'Japon',
        EN: 'Japan',
    },
    jordan: {
        FR: 'Jordanie',
        EN: 'Jordan',
    },
    kazakhstan: {
        FR: 'Kazakhstan',
        EN: 'Kazakhstan',
    },
    kenya: {
        FR: 'Kenya',
        EN: 'Kenya',
    },
    kiribati: {
        FR: 'Kiribati',
        EN: 'Kiribati',
    },
    kuwait: {
        FR: 'Koweït',
        EN: 'Kuwait',
    },
    kyrgyzstan: {
        FR: 'Kirghizistan',
        EN: 'Kyrgyzstan',
    },
    laos: {
        FR: 'Laos',
        EN: 'Laos',
    },
    latvia: {
        FR: 'Lettonie',
        EN: 'Latvia',
    },
    lebanon: {
        FR: 'Liban',
        EN: 'Lebanon',
    },
    lesotho: {
        FR: 'Lesotho',
        EN: 'Lesotho',
    },
    liberia: {
        FR: 'Libéria',
        EN: 'Liberia',
    },
    libya: {
        FR: 'Libye',
        EN: 'Libya',
    },
    liechtenstein: {
        FR: 'Liechtenstein',
        EN: 'Liechtenstein',
    },
    lithuania: {
        FR: 'Lituanie',
        EN: 'Lithuania',
    },
    luxembourg: {
        FR: 'Luxembourg',
        EN: 'Luxembourg',
    },
    madagascar: {
        FR: 'Madagascar',
        EN: 'Madagascar',
    },
    malawi: {
        FR: 'Malawi',
        EN: 'Malawi',
    },
    malaysia: {
        FR: 'Malaisie',
        EN: 'Malaysia',
    },
    maldives: {
        FR: 'Maldives',
        EN: 'Maldives',
    },
    mali: {
        FR: 'Mali',
        EN: 'Mali',
    },
    malta: {
        FR: 'Malte',
        EN: 'Malta',
    },
    marshall_islands: {
        FR: 'Îles Marshall',
        EN: 'Marshall Islands',
    },
    mauritania: {
        FR: 'Mauritanie',
        EN: 'Mauritania',
    },
    mauritius: {
        FR: 'Maurice',
        EN: 'Mauritius',
    },
    mexico: {
        FR: 'Mexique',
        EN: 'Mexico',
    },
    micronesia: {
        FR: 'Micronésie',
        EN: 'Micronesia',
    },
    moldova: {
        FR: 'Moldavie',
        EN: 'Moldova',
    },
    monaco: {
        FR: 'Monaco',
        EN: 'Monaco',
    },
    mongolia: {
        FR: 'Mongolie',
        EN: 'Mongolia',
    },
    montenegro: {
        FR: 'Monténégro',
        EN: 'Montenegro',
    },
    morocco: {
        FR: 'Maroc',
        EN: 'Morocco',
    },
    mozambique: {
        FR: 'Mozambique',
        EN: 'Mozambique',
    },
    myanmar: {
        FR: 'Myanmar',
        EN: 'Myanmar',
    },
    namibia: {
        FR: 'Namibie',
        EN: 'Namibia',
    },
    nauru: {
        FR: 'Nauru',
        EN: 'Nauru',
    },
    nepal: {
        FR: 'Népal',
        EN: 'Nepal',
    },
    netherlands: {
        FR: 'Pays-Bas',
        EN: 'Netherlands',
    },
    new_zealand: {
        FR: 'Nouvelle-Zélande',
        EN: 'New Zealand',
    },
    nicaragua: {
        FR: 'Nicaragua',
        EN: 'Nicaragua',
    },
    niger: {
        FR: 'Niger',
        EN: 'Niger',
    },
    nigeria: {
        FR: 'Nigéria',
        EN: 'Nigeria',
    },
    north_korea: {
        FR: 'Corée du Nord',
        EN: 'North Korea',
    },
    north_macedonia: {
        FR: 'Macédoine du Nord',
        EN: 'North Macedonia',
    },
    norway: {
        FR: 'Norvège',
        EN: 'Norway',
    },
    oman: {
        FR: 'Oman',
        EN: 'Oman',
    },
    pakistan: {
        FR: 'Pakistan',
        EN: 'Pakistan',
    },
    palau: {
        FR: 'Palaos',
        EN: 'Palau',
    },
    palestine: {
        FR: 'Palestine',
        EN: 'Palestine',
    },
    panama: {
        FR: 'Panama',
        EN: 'Panama',
    },
    papua_new_guinea: {
        FR: 'Papouasie-Nouvelle-Guinée',
        EN: 'Papua New Guinea',
    },
    paraguay: {
        FR: 'Paraguay',
        EN: 'Paraguay',
    },
    peru: {
        FR: 'Pérou',
        EN: 'Peru',
    },
    philippines: {
        FR: 'Philippines',
        EN: 'Philippines',
    },
    poland: {
        FR: 'Pologne',
        EN: 'Poland',
    },
    portugal: {
        FR: 'Portugal',
        EN: 'Portugal',
    },
    qatar: {
        FR: 'Qatar',
        EN: 'Qatar',
    },
    romania: {
        FR: 'Roumanie',
        EN: 'Romania',
    },
    russia: {
        FR: 'Russie',
        EN: 'Russia',
    },
    rwanda: {
        FR: 'Rwanda',
        EN: 'Rwanda',
    },
    saint_kitts_and_nevis: {
        FR: 'Saint-Christophe-et-Niévès',
        EN: 'Saint Kitts and Nevis',
    },
    saint_lucia: {
        FR: 'Sainte-Lucie',
        EN: 'Saint Lucia',
    },
    saint_vincent_and_the_grenadines: {
        FR: 'Saint-Vincent-et-les-Grenadines',
        EN: 'Saint Vincent and the Grenadines',
    },
    samoa: {
        FR: 'Samoa',
        EN: 'Samoa',
    },
    san_marino: {
        FR: 'Saint-Marin',
        EN: 'San Marino',
    },
    sao_tome_and_principe: {
        FR: 'Sao Tomé-et-Principe',
        EN: 'Sao Tome and Principe',
    },
    saudi_arabia: {
        FR: 'Arabie Saoudite',
        EN: 'Saudi Arabia',
    },
    senegal: {
        FR: 'Sénégal',
        EN: 'Senegal',
    },
    serbia: {
        FR: 'Serbie',
        EN: 'Serbia',
    },
    seychelles: {
        FR: 'Seychelles',
        EN: 'Seychelles',
    },
    sierra_leone: {
        FR: 'Sierra Leone',
        EN: 'Sierra Leone',
    },
    singapore: {
        FR: 'Singapour',
        EN: 'Singapore',
    },
    slovakia: {
        FR: 'Slovaquie',
        EN: 'Slovakia',
    },
    slovenia: {
        FR: 'Slovénie',
        EN: 'Slovenia',
    },
    solomon_islands: {
        FR: 'Îles Salomon',
        EN: 'Solomon Islands',
    },
    somalia: {
        FR: 'Somalie',
        EN: 'Somalia',
    },
    south_africa: {
        FR: 'Afrique du Sud',
        EN: 'South Africa',
    },
    south_korea: {
        FR: 'Corée du Sud',
        EN: 'South Korea',
    },
    south_sudan: {
        FR: 'Soudan du Sud',
        EN: 'South Sudan',
    },
    spain: {
        FR: 'Espagne',
        EN: 'Spain',
    },
    sri_lanka: {
        FR: 'Sri Lanka',
        EN: 'Sri Lanka',
    },
    sudan: {
        FR: 'Soudan',
        EN: 'Sudan',
    },
    suriname: {
        FR: 'Suriname',
        EN: 'Suriname',
    },
    sweden: {
        FR: 'Suède',
        EN: 'Sweden',
    },
    switzerland: {
        FR: 'Suisse',
        EN: 'Switzerland',
    },
    syria: {
        FR: 'Syrie',
        EN: 'Syria',
    },
    taiwan: {
        FR: 'Taïwan',
        EN: 'Taiwan',
    },
    tajikistan: {
        FR: 'Tadjikistan',
        EN: 'Tajikistan',
    },
    tanzania: {
        FR: 'Tanzanie',
        EN: 'Tanzania',
    },
    thailand: {
        FR: 'Thaïlande',
        EN: 'Thailand',
    },
    togo: {
        FR: 'Togo',
        EN: 'Togo',
    },
    tonga: {
        FR: 'Tonga',
        EN: 'Tonga',
    },
    trinidad_and_tobago: {
        FR: 'Trinité-et-Tobago',
        EN: 'Trinidad and Tobago',
    },
    tunisia: {
        FR: 'Tunisie',
        EN: 'Tunisia',
    },
    turkey: {
        FR: 'Turquie',
        EN: 'Turkey',
    },
    turkmenistan: {
        FR: 'Turkménistan',
        EN: 'Turkmenistan',
    },
    tuvalu: {
        FR: 'Tuvalu',
        EN: 'Tuvalu',
    },
    uganda: {
        FR: 'Ouganda',
        EN: 'Uganda',
    },
    ukraine: {
        FR: 'Ukraine',
        EN: 'Ukraine',
    },
    united_arab_emirates: {
        FR: 'Émirats Arabes Unis',
        EN: 'United Arab Emirates',
    },
    united_kingdom: {
        FR: 'Royaume-Uni',
        EN: 'United Kingdom',
    },
    united_states: {
        FR: 'États-Unis',
        EN: 'United States',
    },
    uruguay: {
        FR: 'Uruguay',
        EN: 'Uruguay',
    },
    uzbekistan: {
        FR: 'Ouzbékistan',
        EN: 'Uzbekistan',
    },
    vanuatu: {
        FR: 'Vanuatu',
        EN: 'Vanuatu',
    },
    vatican_city: {
        FR: 'Vatican',
        EN: 'Vatican City',
    },
    venezuela: {
        FR: 'Venezuela',
        EN: 'Venezuela',
    },
    vietnam: {
        FR: 'Viêt Nam',
        EN: 'Vietnam',
    },
    yemen: {
        FR: 'Yémen',
        EN: 'Yemen',
    },
    zambia: {
        FR: 'Zambie',
        EN: 'Zambia',
    },
    zimbabwe: {
        FR: 'Zimbabwe',
        EN: 'Zimbabwe',
    },
}
