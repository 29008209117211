export default {
    save: {
        FR: 'Enregistrer',
        EN: 'Save',
    },
    titleWorktimes: {
        FR: `Mettez à jour le nombre d'heures des équipements ci-dessous.`,
        EN: 'Update worktime of your equimpent.',
    },
    capacity: {
        FR: 'Cap.',
        EN: 'Cap.',
    },
    actualLevel: {
        FR: 'Niveau actuel',
        EN: 'Actual level',
    },
}
