import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import styles from './Styles/ModalDeleteEngineCss'
import dictionary from './Dictionary/ModalDeleteEngineDico'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import BoatOnModal from '../../../common/BoatOnModal'
import BoatOnModalCore from '../../../common/BoatOnModalCore'

class ModalDelete extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary
    }

    renderModal() {
        const { classes, type } = this.props
        return (
            <div className={classes.infos}>
                <Typography style={{ fontWeight: '500' }}>
                    {type === 'boat'
                        ? this.displayText('careBoat')
                        : this.displayText('careEngine')}
                </Typography>
                <Typography>
                    {type === 'boat'
                        ? this.displayText('questionBoat')
                        : this.displayText('questionEngine')}
                </Typography>
            </div>
        )
    }

    render() {
        const { show, handleClose, classes, type } = this.props

        if (!show) {
            return <div />
        }

        return (
            <div className={classes.root}>
                <BoatOnModal
                    openCondition={show ? 'delete' : false}
                    handleClose={() => handleClose({ valid: false })}
                    titles={{
                        delete:
                            type === 'boat'
                                ? this.displayText('titleBoat')
                                : this.displayText('titleEngine'),
                    }}
                    modalCores={{
                        delete: (
                            <BoatOnModalCore
                                body={this.renderModal()}
                                actions={[
                                    {
                                        label: this.displayText('cancel'),
                                        action: () =>
                                            handleClose({ valid: false }),
                                        minWidth: `120px`,
                                        type: `cancel`,
                                    },
                                    {
                                        label: this.displayText('accept'),
                                        action: () =>
                                            handleClose({ valid: true }),
                                        minWidth: `120px`,
                                    },
                                ]}
                            />
                        ),
                    }}
                    maxWidth={{ delete: `xs` }}
                ></BoatOnModal>
            </div>
        )
    }
}

export default withStyles(styles)(ModalDelete)
