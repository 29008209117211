export default theme => ({
    root: {
        marginTop: '40px',
    },
    nothing: {
        width: '100%',
        fontSize: '20px',
        textAlign: 'center',
        padding: '20px',
    },
    buttonNewInquery: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        border: '2px solid #3040a0',
        borderRadius: '5px',
        alignItems: 'center',
        paddingLeft: '20px',
        paddingRight: '20px',
        width: '100%',
    },
    iconAdd: {
        color: '#3040a0',
        marginLeft: 10,
    },
    buttonText: {
        textTransform: 'none',
    },
})
