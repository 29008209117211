export default theme => ({
    buttonAdd: {
        width: `100%`,
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `space-between`,
        paddingBottom: `10px`,
        margin: '-5px -10px',
    },
    divider: {
        width: `calc(100% + 45px)`,
        marginLeft: `-20px`,
    },
    flexRow: {
        display: `flex`,
        flexDirection: `row`,
        cursor: `pointer`,
        width: `100%`,
        paddingTop: `10px`,
        color: `#849299`,
        fontWeight: `300`,
        fontSize: `14px`,
        alignItems: `center`,
    },
    plusWithCategory: {
        marginLeft: `-8px`,
        marginRight: `8px`,
    },
    plus: {
        marginLeft: `0px`,
        marginRight: `8px`,
    },
})
