import React from 'react'
import { connect } from 'react-redux'
import { history } from '../../../../../helpers'
import AppRoute from '../../../../../constants/AppRoute'

import BoatOnComponent from '../../../../common/BoatOnComponent'
import BoatOnAppBar from '../../../../common/BoatOnAppBar'
import BoatOnModal from '../../../../common/BoatOnModal'
import { Button as ButtonBON } from '../../../../common/BoatOnButton'
import { getContextFromUrl } from '../../../../../languages/LocalizerUtils'
import { fixIosHeaderBug } from 'utils/usefullFunctions'

import dictionary from './Dictionary/NavigationPageDico'
import {
    UTurnLeft as UTurnLeftIcon,
    FlagOutlined as FlagOutlinedIcon,
    ModeEdit as ModeEditIcon,
    IosShare as IosShareIcon,
    Delete as DeleteIcon,
} from '@mui/icons-material/'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/NavigationPageCss'
import TravelTab from './TravelTab'
import DetailsTab from './DetailsTab'
import ActivityComponent from '../../../../common/ActivityComponent'
import EditNavigationModal from './Modals/EditNavigationModal'
import LogBookManualSetupModal from '../Modals/LogBookManualSetupModal'
import ShareModal from './Modals/ShareModal'
import RepairModal from '../../RepairPage/Modal/RepairModal'
import ConfirmationModal from './Modals/ConfirmationModal'

import { getNavigationsFromGroup } from '../../../../../actions/bob/navigation.actions'
import BoatOnLoading from '../../../../common/UsefullComponents/BoatOnLoading'
import { activityActions } from '../../../../../actions/activity.actions'
import { commentsActions } from '../../../../../actions/bob/comments.actions'
import { deleteNavigation } from '../../../../../actions'
import { initBobWithBoat } from '../../../../../actions/bob.actions'
import { switchNavigation } from '../../../../../actions/bob/navigationApiData.actions'
import { getAisByNavigation } from '../../../../../actions/bob/navigationApiData.actions'

import { getTextFromAddress } from '../Utils/eventsFilter'

class NavigationPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            idNavTab: 0,
            navigation: null,
            selectedModal: false,
            timeoutId: null,
        }

        this.topPage = React.createRef()

        this.changeTab = this.changeTab.bind(this)
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.deleteNavigation = this.deleteNavigation.bind(this)
        this.updateAis = this.updateAis.bind(this)
    }

    componentDidMount() {
        const { dispatch, navigations, groupId, id } = this.props

        if (navigations === null) dispatch(getNavigationsFromGroup(groupId))

        if (id) dispatch(switchNavigation(id))

        this.updateAis()

        dispatch(activityActions.getNavigationActivity(id))
        dispatch(commentsActions.getNavigationComments(id))

        // Scroll to top
        if (document.getElementById('nav'))
            document.getElementById('nav').scrollTo(0, 0)
    }

    componentDidUpdate(prevProps) {
        const { navigation, user, dispatch } = this.props

        if (navigation !== prevProps.navigation) {
            this.setState({ navigation })
        }

        const boatIdSelect = localStorage.getItem('boatIdSelect')
        if (navigation && boatIdSelect !== `${navigation.boatId}`) {
            localStorage.setItem('boatIdSelect', navigation.boatId || -1)
            dispatch(
                initBobWithBoat({
                    user,
                    boatId: navigation.boatId,
                }),
            )
        }

        if (this.state.timeoutId === null) {
            this.updateAis()
        }
    }

    componentWillUnmount() {
        clearTimeout(this.state.timeoutId)
    }

    updateAis() {
        const { dispatch, navigation } = this.props

        if (!navigation) return

        dispatch(getAisByNavigation(navigation.id))

        const timeoutId = setTimeout(this.updateAis, 1000 * 60 * 10)

        this.setState({
            timeoutId,
        })
    }

    deleteNavigation() {
        this.props.dispatch(deleteNavigation(this.props.navigation))
        this.historyPush(history, AppRoute.LogBook.LogBook, '', {}, {})
    }

    changeTab(idNavTab) {
        fixIosHeaderBug(1)
        this.setState({ idNavTab })
    }

    openModal(modal) {
        this.setState({ selectedModal: modal })
    }

    closeModal() {
        this.setState({ selectedModal: false })
    }

    _renderHeaderPage() {
        const {
            context,
            classes,
            navigation,
            currentBoat,
            isMobile,
        } = this.props
        const startDateTravel = new Date(
            navigation?.delimitedDate?.startDate,
        ).toLocaleDateString(context)
        let title = `${this.displayText('navigationReady')} - ${
            currentBoat?.name
        }`

        if (
            navigation?.delimitedDate?.startDate &&
            !navigation?.delimitedDate?.endDate &&
            navigation.nbDepartingPassengers !== null
        )
            title = `${this.displayText('navigationInProgress')} - ${
                currentBoat?.name
            }`

        if (
            navigation?.delimitedDate?.startDate &&
            navigation?.delimitedDate?.endDate
        ) {
            const departure = navigation.departureAddress
            const arrival = navigation.arrivalAddress
            const localisation = `${getTextFromAddress(departure)} ${
                navigation.arrivalAddress ? '-' : ''
            } ${getTextFromAddress(arrival)}`

            title = `${localisation} ${this.displayText(
                'the',
            )} ${startDateTravel} - ${currentBoat?.name}`
        }

        return (
            <div className={classes.containerBackBtn}>
                <div className={classes.backBtn}>
                    <UTurnLeftIcon
                        className={classes.backIcon}
                        onClick={() =>
                            this.historyPush(history, AppRoute.LogBook.LogBook)
                        }
                    />
                    {title}
                </div>
                {!isMobile && this._renderSharedButtons()}
            </div>
        )
    }

    _renderSharedButtons() {
        const { classes, isMobile, navigation } = this.props

        if (!navigation?.delimitedDate?.startDate) return null

        const isEnded =
            navigation?.delimitedDate?.startDate &&
            navigation?.delimitedDate?.endDate

        return (
            <div className={classes.sharedButtons}>
                <ButtonBON
                    classNameOverride={classes.outlinedBtn}
                    type="error"
                    label={
                        <>
                            <FlagOutlinedIcon />
                            {this.displayText('declareIncident')}
                        </>
                    }
                    size={`large`}
                    fullWidth={isMobile}
                    onClick={() => this.openModal('incident')}
                />
                {isEnded && (
                    <>
                        <ModeEditIcon
                            className={classes.travelIcons}
                            onClick={() => this.openModal('editNav')}
                        />
                        <IosShareIcon
                            className={classes.travelIcons}
                            onClick={() => this.openModal('share')}
                        />
                        <DeleteIcon
                            className={classes.travelIcons}
                            onClick={() => this.openModal('confirmationModal')}
                        />
                    </>
                )}
            </div>
        )
    }

    _renderActuality() {
        const { navigation } = this.props

        if (!navigation) return <BoatOnLoading />

        return (
            <ActivityComponent
                activities={this.props.activities}
                event={navigation}
                eventType="navigation"
                scrollToTop={() => {
                    this.topPage.current.scrollIntoView({ behavior: 'smooth' })
                }}
            />
        )
    }

    render() {
        const { classes, navigation, navigations, loading } = this.props
        const { idNavTab, selectedModal } = this.state

        if (navigations === null || loading > 0)
            return (
                <div className={classes.root}>
                    <BoatOnLoading />
                </div>
            )

        return (
            <div className={classes.root} ref={this.topPage}>
                {this._renderHeaderPage()}
                <div className={classes.header}>
                    <BoatOnAppBar
                        tabIndex={idNavTab}
                        displayBodyOut={this.changeTab}
                        labels={[
                            this.displayText('travelTab'),
                            this.displayText('navDetailsTab'),
                            this.displayText('activityTab'),
                        ]}
                    />
                </div>
                {idNavTab === 0 && (
                    <TravelTab
                        openModal={this.openModal}
                        onChangeTab={id => this.changeTab(id)}
                    />
                )}
                {idNavTab === 1 && <DetailsTab />}
                {idNavTab === 2 && this._renderActuality()}

                <BoatOnModal
                    openCondition={selectedModal}
                    handleClose={this.closeModal}
                    modalCores={{
                        editNav: (
                            <EditNavigationModal
                                onClose={this.closeModal}
                                noCross={false}
                                changeModal={this.openModal}
                            />
                        ),
                        editTravel: (
                            <LogBookManualSetupModal
                                onClose={this.closeModal}
                                onCancel={() => this.openModal('editNav')}
                                id={navigation?.id}
                                noCross={false}
                            />
                        ),
                        incident: (
                            <RepairModal
                                language={this.getContext()}
                                handleSelfClose={this.closeModal}
                                handleValidatedEvent={this.closeModal}
                                noCross={false}
                                showFollowButton={true}
                                type={`NEXT_REPAIR`}
                                reparation={true}
                            />
                        ),
                        share: <ShareModal handleClose={this.closeModal} />,
                        confirmationModal: (
                            <ConfirmationModal
                                onClose={this.closeModal}
                                onValid={this.deleteNavigation}
                                noCross={false}
                            />
                        ),
                    }}
                    maxWidth={{
                        editNav: 'sm',
                        editTravel: 'sm',
                        incident: 'sm',
                        share: 'sm',
                        confirmationModal: 'sm',
                    }}
                    titles={{
                        editNav: this.displayText('editNavigation'),
                        editTravel: this.displayText('editTravel'),
                        incident: this.displayText('incidentTitle'),
                        share: this.displayText('share'),
                        confirmationModal: this.displayText(
                            'confirmationModal',
                        ),
                    }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    const url = window.location.pathname
    const id = url.split('/').slice(-1)[0]
    const navigation = state.logbook.navigations?.find(
        nav => nav?.id === parseInt(id),
    )
    const context = getContextFromUrl(url)
    const isMobile = window.innerWidth < 600
    const groupBoats = state.group?.groupsMembers?.boats || []
    const currentBoat = groupBoats.find(boat => boat.id === navigation?.boatId)

    return {
        isMobile,
        id,
        context,
        currentBoat,
        navigation,
        navigations: state.logbook.navigations,
        loading: state.logbook.loading,
        groupId: state.group.currentGroupId,
        activities: state.activity.activity || [],
        events: state.bob.events,
        user: state.authentication.user,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(NavigationPage))
