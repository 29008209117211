export default theme => ({
    root: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    rootLine: {
        width: 'calc(100vw - calc(100vw - 877px))',
        display: `grid`,
        alignItems: 'center',
        gridTemplateColumns: `1fr 1fr 1fr 50px`,
        gridTemplateRows: `100%`,
        gridColumnGap: `5px`,
        padding: '10px',
        minHeight: '40px',
        height: '40px',
        backgroundColor: '#F7F9FB',
        minWidth: `670px`,
        [theme.breakpoints.down('md')]: {
            width: `calc(100% - 20px)`,
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: `200px 200px 200px 50px`,
        },
    },
    icons: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '50px',
    },
    infos: {
        width: 'calc(100% - 70px)',
        minWidth: '350px',
        marginLeft: '10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
    div33: {
        width: '100%',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    buttons: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        marginTop: '20px',
    },
    text: {
        fontWeight: 500,
        marginBottom: '10px',
    },
    headerTable: {
        display: `grid`,
        gridTemplateColumns: `1fr 1fr 1fr 50px`,
        width: 'calc(100vw - calc(100vw - 877px))',
        margin: '10px',
        gridColumnGap: `5px`,
        marginTop: 20,
        minWidth: `670px`,
        [theme.breakpoints.down('md')]: {
            width: `calc(100% - 20px)`,
        },
        [theme.breakpoints.down('xs')]: {
            gridTemplateColumns: `200px 200px 200px 50px`,
        },
    },
    scrollableBody: {
        maxHeight: `260px`,
        overflowY: `auto`,
        overflowX: `hidden`,
        minWidth: '670px',
        [theme.breakpoints.down('md')]: {
            width: `100%`,
        },
    },
    tableTitle: {
        fontWeight: 700,
        fontSize: `12px`,
    },
    table: {
        overflowX: `auto`,
        whiteSpace: 'nowrap',
        minWidth: `100%`,
        height: `auto`,
    },
    backgroundColorWhite: {
        backgroundColor: 'white',
    },
})
