export default {
    startDate: {
        FR: 'Du',
        EN: 'From',
    },
    endDate: {
        FR: 'Au',
        EN: 'All',
    },
    empty: {
        FR: 'Tout',
        EN: 'All',
    },
    dateEmpty: {
        FR: 'jj/mm/aaaa',
        EN: 'mm/dd/yyyy',
    },
    save: {
        FR: 'Enregistrer',
        EN: 'Save',
    },
    delete: {
        FR: 'Effacer',
        EN: 'Delete',
    },
    cancel: {
        FR: 'Annuler',
        EN: 'Cancel',
    },
    filter: {
        FR: 'Filtrer',
        EN: 'Filter',
    },
    validate: {
        FR: 'Valider',
        EN: 'Validate',
    },
    date: {
        FR: 'Date',
        EN: 'Date',
    },
}
