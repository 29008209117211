export default {
    getReadyMsg: {
        FR: `Votre bateau est prêt à l'appareillage, cliquez sur le bouton pour démarrer la navigation`,
        EN:
            'Casting off is ready for your boat, click on the buton to start the navigation',
    },
    startNavigation: {
        FR: 'Démarrer la navigation',
        EN: 'Start navigation',
    },
    impossibleToStart: {
        FR: 'Impossible de démarrer la navigation sans données AIS',
        EN: 'Impossible to start the navigation without AIS data',
    },
    leaveStopover: {
        FR: 'Reprendre la navigation',
        EN: 'Go back to navigation',
    },
    declareIncident: {
        FR: 'Déclarer un incident',
        EN: 'Declare an incident',
    },
    shareNavigation: {
        FR: 'Partager la navigation',
        EN: 'Share the navigation',
    },
    deleteNavigation: {
        FR: 'Supprimer la navigation',
        EN: 'Delete the navigation',
    },
    endNavigation: {
        FR: 'Fin de navigation',
        EN: 'End navigation',
    },
    navigationDuration: {
        FR: 'Durée de navigation',
        EN: 'Navigation duration',
    },
    travelDistance: {
        FR: 'Distance parcourue',
        EN: 'Travel distance',
    },
    departure: {
        FR: 'Départ',
        EN: 'Departure',
    },
    stopover: {
        FR: 'Escale',
        EN: 'Stopover',
    },
    arrival: {
        FR: 'Arrivée',
        EN: 'Arrival',
    },
    stockTitle: {
        FR: 'Niveau des réserves',
        EN: 'Filling levels',
    },
    updateStocks: {
        FR: 'Mettre à jour le niveau des réserves',
        EN: 'Update filling levels',
    },
    worktimeTitle: {
        FR: 'Mes heures de marche',
        EN: 'Worktimes',
    },
    updateWorktimes: {
        FR: 'Mettre à jour les heures de marche',
        EN: 'Update worktimes',
    },
    judgmentTitle: {
        FR: 'Appréciation du pilote',
        EN: 'Captain judgment',
    },
    imagesTitle: {
        FR: 'Photos de l’itinéraire',
        EN: 'Picture of the travel',
    },
    navDetails: {
        FR: 'Détails de la navigation',
        EN: 'Navigation details',
    },
    captain: {
        FR: 'Capitaine',
        EN: 'Captain',
    },
    crewMembers: {
        FR: `Membres d'équipage`,
        EN: 'Crew members',
    },
    passengersNumber: {
        FR: 'Nombre de passagers',
        EN: 'Number of passengers',
    },
    editLevel: {
        FR: 'Modifier le niveau des réserves',
        EN: 'Edit filling levels',
    },
    editWorktimes: {
        FR: 'Modifier les heures de marche',
        EN: 'Edit worktimes',
    },
    endNavigationTitle: {
        FR: 'Fin de la navigation',
        EN: 'End of the travel',
    },
    declareIncidentTitle: {
        FR: `Création d'une future tâche`,
        EN: 'Creating a futur task',
    },
    editNavigation: {
        FR: 'Modifier la navigation',
        EN: 'Edit navigation',
    },
    editTravel: {
        FR: 'Modifier trajet',
        EN: 'Edit travel',
    },
    editCrew: {
        FR: 'Modifier équipage',
        EN: 'Edit crew',
    },
    confirmationModal: {
        FR: 'Confirmation de suppression',
        EN: 'Delete confirmation',
    },
    nauticMille: {
        FR: 'M',
        EN: 'Nm',
    },
    aisWithoutAddress: {
        FR: `Remplir une adresse`,
        EN: 'Fill an address',
    },
}
