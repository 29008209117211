import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Button } from '../../common/BoatOnButton'
import BoatOnComponent from '../../common/BoatOnComponent'
import styles from './Styles/InsuranceTabCss'
import dictionary from './Dictionary/InsuranceTabDico'
import AppRoute from '../../../constants/AppRoute'
import { history } from '../../../helpers'
import { getCurrentRouteKey } from '../../../languages/LocalizerUtils'
import InsuranceInqueryDetails from './InsuranceInqueryDetails'
import InsuranceBoxRecap from './InsuranceBoxRecap'
import { inqueriesActions } from '../../../actions/inqueries/inqueries.actions'

class InsuranceTab extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            quotations: [],
            inqueryIdOpen: '',
            insuranceSelected: false,
            refresh: false,
        }

        this.askInquery = this.askInquery.bind(this)
        this.makeNewInquery = this.makeNewInquery.bind(this)
        this.refresh = this.refresh.bind(this)
        this.updateSelectedInsurance = this.updateSelectedInsurance.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(inqueriesActions.getPersonalInsuranceInqueries())
    }

    refresh(refresh) {
        this.setState({ refresh: refresh })
    }

    updateSelectedInsurance(insurance) {
        this.setState({ insuranceSelected: insurance })
    }

    handleChange(value, key) {
        this.setState({ [key]: value })
    }

    redirectNoDetails() {
        this.historyPush(history, AppRoute.InsuranceInqueries)
        this.forceUpdate()
    }

    nbQuotationLoad(inqueryId) {
        let nb = 0
        for (const i in this.state.quotations) {
            if (this.state.quotations[i].InqueryID === inqueryId) {
                nb += 1
            }
        }
        return nb
    }

    makeNewInquery() {
        this.historyPush(history, AppRoute.InsurancePage)
    }

    // render button en bas pour ajouter des demandes
    askInquery(stateInquery) {
        const { classes } = this.props

        return (
            <Button
                className={classes.buttonNewInquery}
                type="primary"
                onClick={this.makeNewInquery}
                label={
                    stateInquery === 'noInquery'
                        ? this.displayText('makeFirst')
                        : this.displayText('makeNew')
                }
            />
        )
    }

    render() {
        const { classes, loading, insuranceInqueries } = this.props
        const { insuranceSelected } = this.state
        const url = window.location.href
        var currentAppRoute = getCurrentRouteKey(
            url,
            this.getContext(),
            this.getMessages(),
        )

        if (!loading && insuranceInqueries.length === 0) {
            return (
                <div>
                    <Typography className={classes.nothing}>
                        {this.displayText('noInquery')}
                    </Typography>
                    {this.askInquery('noInquery')}
                </div>
            )
        }

        if (loading > 0) {
            return this.renderLoading('50')
        }

        if (currentAppRoute === AppRoute.InsuranceDetails && !insuranceSelected)
            this.redirectNoDetails()

        // Si la route pointe vers les details d'une demande alors on affiche la page de details (pas trouvé d'autre moyen avec des menus dans des menus dans des menus)
        if (currentAppRoute === AppRoute.InsuranceDetails) {
            return (
                <InsuranceInqueryDetails
                    insurance={insuranceSelected}
                    quotations={
                        this.state.quotations[insuranceSelected.InqueryID]
                    }
                    refresh={this.refresh}
                />
            )
        }

        return (
            <div>
                <div className={classes.root}>
                    {insuranceInqueries.map((insurance, i) => (
                        <React.Fragment key={i}>
                            <InsuranceBoxRecap
                                buttonOn
                                insurance={insurance}
                                quotations={this.state.quotations}
                                updateSelectedInsurance={
                                    this.updateSelectedInsurance
                                }
                            />
                        </React.Fragment>
                    ))}
                    {this.askInquery()}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        insuranceInqueries: state.inqueries.personalInsuranceInqueries,
        quotations: state.fetch.quotations,
        user: state.authentication.user,
        loading: state.inqueries.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(InsuranceTab))
