export default theme => ({
    appBar: {
        boxShadow: `none`,
        '& div': {
            overflowX: `auto`,
        },
    },
    rootTab: {
        color: `#0000006b`,
        fontSize: 14,
        textTransform: `none`,
        minWidth: 0,
        fontWeight: 400,
        padding: 0,
        marginRight: 22,
    },
    divider: {
        width: `calc(100% + 48px)`,
        marginLeft: `-24px`,
    },
})
