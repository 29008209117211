import React from 'react'
import PersonIcon from '@mui/icons-material/Person'

function UserBadge({ userLink, small }) {
    return (
        <div
            style={
                !small
                    ? {
                          width: 25,
                          height: 25,
                          borderRadius: 15,
                          fontSize: 12,
                          aspectRatio: 1,
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: '#303F9F',
                          backgroundColor: '#E1E5F9',
                          fontWeight: 'bold',
                          marginRight: 6,
                          alignSelf: 'center',
                          border: 'solid 2px #303F9F',
                      }
                    : {
                          width: 15,
                          height: 15,
                          borderRadius: '100%',
                          fontSize: 9,
                          aspectRatio: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: '#303F9F',
                          backgroundColor: '#E1E5F9',
                          fontWeight: 'bold',
                          marginRight: 3,
                          alignSelf: 'center',
                          border: 'solid 1px #303F9F',
                      }
            }
        >
            {userLink.user ? (
                userLink.user.firstName[0] + '' + userLink.user.lastName[0]
            ) : (
                <PersonIcon
                    style={
                        !small
                            ? {
                                  marginTop: '10px !important',
                                  width: '35px !important',
                                  height: '35px !important',
                              }
                            : {
                                  width: '15px !important',
                                  height: '15px !important',
                              }
                    }
                />
            )}
        </div>
    )
}

export default UserBadge
