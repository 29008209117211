export default theme => ({
    settingsRow: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
    },
    settingsInput: {
        width: 75,
    },
    settingsLabel: {
        display: 'flex',
        gap: 10,
    },
    settingsIcon: {
        width: '20px !important',
        height: '20px !important',
    },
})
