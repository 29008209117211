export default {
    titleEngine: {
        FR: "Suppression d'un moteur",
        EN: 'Deleting an engine',
    },
    questionEngine: {
        FR: 'Voulez-vous vraiment supprimer ce moteur ?',
        EN: 'Do you really want to delete this engine ?',
    },
    titleBoat: {
        FR: "Suppression d'un bateau",
        EN: 'Deleting a boat',
    },
    questionBoat: {
        FR: 'Voulez-vous vraiment supprimer ce bateau ?',
        EN: 'Do you really want to delete this boat ?',
    },
    accept: {
        FR: 'Supprimer',
        EN: 'Delete',
    },
    cancel: {
        FR: 'Annuler',
        EN: 'Cancel',
    },
    careEngine: {
        FR: 'Attention, vous êtes sur le point de supprimer votre moteur !',
        EN: 'Beware, you are about to delete your engine!',
    },
    careBoat: {
        FR: 'Attention, vous êtes sur le point de supprimer votre bateau !',
        EN: 'Beware, you are about to delete your boat!',
    },
}
