export default theme => ({
    root: {
        width: '100%',
    },
    backgroundImage: {
        maxWidth: 300,
        objectFit: `contain`,
    },
    noBoatText: {
        maxWidth: 300,
        color: `#303F9F`,
        fontSize: 22,
        fontWeight: `bold`,
        textAlign: `center`,
        margin: 0,
        '&:hover': {
            cursor: `pointer`,
        },
    },
    root2: {
        width: `100%`,
        height: `80vh`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
        flexDirection: `column`,
    },
    main: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    all: {
        backgroundColor: 'hsla(231, 9%, 55%, 0.4)',
        zIndex: '11000001',
        width: 'calc(100% - 40px)',
        padding: '20px',
        height: 'calc(100% - 40px)',
        marginBottom: '5px',
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#fff',
        },
    },
    popuptext: {
        textAlign: 'center',
        paddingTop: '10px',
        paddingBottom: '10px',
        zIndex: '1',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
        whiteSpace: 'pre-line',
    },
    texts: {
        width: '90%',
        margin: 'auto',
        marginTop: '13vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '10px',
        position: 'relative',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            width: 'calc(100% - 10px)',
        },
        backgroundColor: '#fff',
        border: '5px solid #3040a0',
        boxShadow: '0px 1px 20px 0.1px #000',
        borderRadius: 5,
    },
})
