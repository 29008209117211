import { transform } from 'lodash'

export default theme => ({
    page: {
        paddingInline: 20,
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            paddingInline: 0,
        },
    },
    row: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        paddingBottom: 15,
        [theme.breakpoints.down('sm')]: {
            '& p': {
                fontSize: '12px !important',
            },
        },
    },
    lineContent: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        gap: '10%',
        minHeight: 100,
        paddingLeft: 20,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            padding: 10,
            paddingTop: 0,
            minHeight: 0,
            gap: 10,
        },
        [theme.breakpoints.down('md')]: {
            gap: '3%',
        },
    },
    col: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    columnFlex: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    date: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        minWidth: '130px',
        marginLeft: 20,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 25,
        },
    },
    title: {
        fontWeight: 500,
        fontSize: 16,
    },
    cardsContainer: {
        gap: 40,
        marginBlock: 20,
        [theme.breakpoints.down('xs')]: {
            gap: 5,
        },
    },
    card: {
        display: 'flex',
        flex: 1,
        gap: 5,
        flexDirection: 'column',
        alignItems: 'flex-end',
        borderRadius: 5,
        backgroundColor: 'green',
        color: 'white',
        padding: 10,
        fontSize: 40,
        [theme.breakpoints.down('sm')]: {
            padding: 5,
        },
        boxShadow:
            '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 4px 4px 0px rgba(0, 0, 0, 0.14)',
    },
    cardValue: {
        fontSize: '1em',
        marginBlock: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
    },
    cardLabel: {
        fontSize: '0.5em',
        marginBlock: 0,
        textAlign: 'right',
        [theme.breakpoints.down('sm')]: {
            fontSize: 13,
        },
    },

    badgeContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        minWidth: 200,
    },
    userBadge: {
        background: '#E5E7F0',
        color: '#303F9F',
        width: 35,
        height: 35,
        borderRadius: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 500,
        fontSize: 14,
        overflow: 'hidden',
        minWidth: 35,
        minHeight: 35,
    },
    userBadgeIcon: {
        marginTop: '10px !important',
        width: '35px !important',
        height: '35px !important',
    },
    yearContainer: {
        marginBlock: 10,
    },
    accordionSummaryRoot: {
        backgroundColor: 'rgba(156, 184, 227, 0.3)',
        borderRadius: '7px',
        margin: 0,
    },
    expandedFixRadius: {
        borderBottomLeftRadius: '0px',
        borderBottomRightRadius: '0px',
    },
    accordionSummaryContent: {
        alignItems: 'center',
        width: '100%',
    },
    accordion: {
        marginBottom: '20px !important',
        boxShadow: 'none',
        margin: 0,
    },
    accordionDetailsRoot: {
        flexDirection: 'column',
        border: 'solid 1px #EFF3F6',
        borderBottom: 'none',
        padding: 0,
    },
    extension: {
        boxShadow: 'none',
        margin: '0px !important',
        '&:before': {
            opacity: 0,
        },
        marginBottom: '25px !important',
    },
    accordionItem: {
        display: 'flex',
        padding: 10,
        gap: 10,
        borderBottom: 'solid 1px #EFF3F6',
        width: '100%',
        boxSizing: 'border-box',
        [theme.breakpoints.down('sm')]: {
            // height: '165px',
            paddingBottom: 10,
            paddingInline: 10,
        },
    },
    fixWidthWithDivider: {
        width: 'calc(100% - 3px)',
    },
    itemRed: {
        borderLeft: '#E36B61 solid 3px',
    },
    itemOrange: {
        borderLeft: '#F4BA5C solid 3px',
    },
    timeStripContainer: {
        flex: 3,
        paddingBlock: 20,
        gap: 12,
        // paddingRight: 50,
        [theme.breakpoints.down('sm')]: {
            paddingBlock: 10,
            paddingRight: 20,
        },
    },
    half: {
        width: '50%',
    },
    work: {
        borderBottom: 'solid 4px #3040a0',
        height: 0,
        margin: '0px 6px',
    },
    startMarker: {
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 1,
            left: -7,
            width: 15,
            height: 15,
            borderRadius: 10,
            background: '#3040a0',
            transform: 'translate(0, -50%)',
        },
    },
    endMarker: {
        position: 'relative',
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 1,
            right: -7,
            width: 15,
            height: 15,
            borderRadius: 10,
            background: '#3040a0',
            transform: 'translate(0, -50%)',
        },
    },
    break: {
        borderBottom: 'dashed 4px #EAECF5',
        height: 0,
    },
    afternoon: {
        borderBottom: 'solid 4px #3040a0',
        height: 0,
        position: 'relative',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: 15,
            height: 15,
            borderRadius: 10,
            background: '#3040a0',
            transform: 'translate(0, -50%)',
        },
        '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            width: 15,
            height: 15,
            borderRadius: 10,
            background: '#3040a0',
            transform: 'translate(0, -50%)',
        },
    },
    rootLabel: {
        padding: `5px 8px`,
        textAlign: `center`,
        color: 'white',
        borderRadius: 3,
        fontWeight: `bold`,
        lineHeight: `15px`,
        width: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            padding: `2px 5px`,
        },
    },
    redLabel: {
        backgroundColor: '#f44336',
    },
    orangeLabel: {
        backgroundColor: '#F4BA5C',
    },
    greenLabel: {
        backgroundColor: '#6BC070',
    },
    greyLabel: {
        backgroundColor: '#C1C1C1',
    },
    brownLabel: {
        backgroundColor: '#E39861',
    },
    blueLabel: {
        backgroundColor: '#3040a0',
    },
    purpleLabel: {
        backgroundColor: '#C961E3',
    },
    lightBlueLabel: {
        backgroundColor: '#61BCE3',
    },
    labelTypeTravail: {
        background: '#3040a0',
    },
    labelTypeMaladie: {
        background: '#E36B61',
    },
    labelTypeConge: {
        background: '#6BC070',
    },
    totalContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            width: 'min-content',
            textAlign: 'center',
            gap: 0,
        },
    },
    daySum: {
        fontWeight: 700,
        fontSize: 16,
    },
    totalError: {
        color: '#E36B61',
    },
    actionMenu: {
        display: 'flex',
        flexDirection: 'column',
    },
    actionMenuItem: {
        height: 44,
        boxSizing: `border-box`,
        fontWeight: 400,
        fontSize: `14px`,
        justifyContent: `flex-start`,
        paddingInline: 17,
    },
    noMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    noDesktop: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    lineRow: {
        display: 'flex',
        flex: 1,
        gap: '10%',
        [theme.breakpoints.down('sm')]: {
            gap: 10,
        },
    },
    warningDivider: {
        width: 3,
        backgroundColor: `#F4BA5C`,
    },
    errorDivider: {
        width: 3,
        backgroundColor: `#F44336`,
    },
    verticalDivider: {
        height: 'calc(100% + 20px)',
        marginLeft: '-10px',
        marginTop: '-10px',
    },
    headerWeek: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: 'calc(100% + 48px)',
        gap: 35,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            gap: 0,
        },
    },
    lineGridHeader: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '4fr 5fr 1fr',
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
        },
    },
    weekLabel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minWidth: '130px',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            minWidth: 100,
        },
    },
    group: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    groupLine: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    textDate: {
        position: 'relative',
        top: '50%',
        transform: 'translateY(-50%)',
        [theme.breakpoints.down('sm')]: {
            position: 'block',
            top: 0,
            transform: 'none',
            padding: '20px 0px',
        },
    },
    userSpan: {
        [theme.breakpoints.down('sm')]: {
            gridRow: '1 / span 1',
            gridColumn: '2 / span 2',
        },
    },
    otherSpan: {
        [theme.breakpoints.down('sm')]: {
            // gridRow: '2 / span 1',
            // // alignSelf: 'center',
            // alignSelf: 'baseline',
        },
    },
    checkboxMobile: {
        [theme.breakpoints.down('sm')]: {
            padding: '0px 10px 0px 0px',
        },
    },
    noData: {
        fontSize: 14,
        textAlign: 'center',
    },
    totalWeekHours: {
        fontWeight: 700,
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
        },
    },
})
