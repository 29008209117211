import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { userActions } from '../../actions'
import Navigation from '../common/Navigation'
import styles from './Styles/ForgetPageCss'
import dictionary from './Dictionary/ForgetPageDico'
import BoatOnComponent from '../common/BoatOnComponent'
import { Button } from '../common/BoatOnButton'

class ForgetPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            submitted: false,
            openSnack: true,
        }

        this.dictionary = dictionary

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(e) {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    onChange(value, key) {
        this.setState({ [key]: value })
    }

    async handleSubmit(e) {
        const lang = this.context
        e.preventDefault()
        this.setState({ submitted: true })
        if (this.state.email) {
            await this.props.dispatch(
                userActions.forget(this.state.email, lang),
            )
            alert(this.displayText('email'))
        }
    }

    render() {
        const { classes } = this.props
        const { email, submitted } = this.state
        return (
            <div>
                <Navigation>
                    <div className={classes.card}>
                        <h3 className={classes.toCenter}>
                            {this.displayText('enjoyBoating')}
                        </h3>
                        <form name="form" onSubmit={this.handleSubmit}>
                            <div>
                                <TextField
                                    fullWidth
                                    id="standard-textarea"
                                    required
                                    label="Email"
                                    value={email}
                                    onChange={e =>
                                        this.onChange(e.target.value, 'email')
                                    }
                                    margin="normal"
                                    error={submitted && !email}
                                />
                            </div>
                            <div className={classes.sendButton}>
                                <Button
                                    fullWidth
                                    type="primary"
                                    margin="dense"
                                    label={this.displayText(`validate`)}
                                />
                            </div>
                        </form>
                    </div>
                </Navigation>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        state: state.open,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(ForgetPage))
