import React, { Component, lazy, Suspense } from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, Router } from 'react-router-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'

import { PrivateRoute } from './components/common/PrivateRoute'

import { history } from './helpers'
import { alertActions, userActions } from './actions'

import LoginPage from './components/LoginPage/LoginPage'
import ProfilePage from './components/ProfilePage/ProfilePage'
import ForgetPage from './components/ForgetPage/ForgetPage'
import LogbookHome from './components/LogbookPage/LogbookHome'
// Static Pages
import Error404StaticPage from './components/StaticPages/Error404Page'

import theme from './helpers/customTheme'
import anchorLoader from './images/anchorLoader.gif'
import { Elements } from '@stripe/react-stripe-js'
import AppLanguage from './constants/AppLanguage'
import AppRoute from './constants/AppRoute'
import { LocalizedRouter, MultiLangualSwitch, appStrings } from './languages'

import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_PUBLIC_KEY, GTM_ID } from './services/config.service'
import PricesPage from './components/PricesPage/PricesPage'
import TagManager from 'react-gtm-module'
import { Crisp } from 'crisp-sdk-web'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import RedirectPage from './components/RedirectPage/RedirectPage'

const OrderDetailsPage = lazy(() =>
    import('./components/OrderDetailsPage/OrderDetailsPage'),
)

const HomePage = lazy(() => import('./components/HomePage/HomePage'))
const ParkingPage = lazy(() => import('./components/ParkingPage/ParkingPage'))
const BookingPage = lazy(() => import('./components/BookingPage/BookingPage'))
const SepaPage = lazy(() =>
    import('./components/BookingPage/BookingNavigationPages/SepaPage'),
)
const EditParkingPage = lazy(() =>
    import('./components/EditParkingPage/EditParkingPage'),
)
const MessagePage = lazy(() => import('./components/MessagePage/MessagePage'))
const DuplicateParkingPage = lazy(() =>
    import('./components/DuplicateParkingPage/DuplicateParkingPage'),
)
const InsurancePage = lazy(() =>
    import('./components/InsurancePage/InsurancePage'),
)
const ParkingSearchPage = lazy(() =>
    import('./components/ParkingSearchPage/ParkingSearchPage'),
)
const RentParkingPage = lazy(() =>
    import('./components/RentParkingPage/RentParkingPage'),
)
const CalendarInvitationsPage = lazy(() =>
    import('./components/CalendarInvitationsPage/CalendarInvitationsPage'),
)
const tagManagerArgs = {
    gtmId: GTM_ID,
}

TagManager.initialize(tagManagerArgs)

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY)
class App extends Component {
    constructor(props) {
        super(props)

        const { dispatch } = this.props
        dispatch(userActions.init())

        history.listen(() => {
            dispatch(alertActions.clear())
        })
    }

    componentDidMount() {
        Crisp.configure('ec11f72f-2e19-414e-9ad3-9a349053777a')
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <LocalizedRouter
                        RouterComponent={Router}
                        languages={AppLanguage}
                        appStrings={appStrings}
                        history={history}
                    >
                        <Suspense
                            fallback={
                                <div
                                    style={{
                                        width: `100%`,
                                        height: `100vh`,
                                        display: `flex`,
                                        justifyContent: `center`,
                                        alignItems: `center`,
                                    }}
                                >
                                    <img
                                        src={anchorLoader}
                                        alt="anchor"
                                        title="anchor"
                                        margin="auto"
                                        width={100}
                                        height={100}
                                    />
                                </div>
                            }
                        >
                            <MultiLangualSwitch
                                errorRedirection={AppRoute.Error404}
                            >
                                <Route
                                    exact
                                    path={'/invitations/:token?'}
                                    component={CalendarInvitationsPage}
                                />
                                <Route
                                    exact
                                    path={'/ma-demande/:id?'}
                                    component={OrderDetailsPage}
                                />
                                <Route
                                    exact
                                    path={'/my-request/:id?'}
                                    component={OrderDetailsPage}
                                />
                                <Route
                                    route={AppRoute.Login}
                                    component={LoginPage}
                                />
                                <Route
                                    route={AppRoute.LoginInGroup}
                                    component={LoginPage}
                                />
                                <Route
                                    route={AppRoute.Register}
                                    component={() => (
                                        <LoginPage register={true} />
                                    )}
                                />
                                <Route
                                    route={AppRoute.Forget}
                                    component={ForgetPage}
                                />
                                <Route
                                    route={AppRoute.Redirect}
                                    component={RedirectPage}
                                ></Route>
                                <PrivateRoute
                                    route={AppRoute.Messages}
                                    component={MessagePage}
                                />
                                <PrivateRoute
                                    route={AppRoute.Profile}
                                    component={ProfilePage}
                                />
                                <PrivateRoute
                                    route={AppRoute.PersonnalInfos}
                                    component={ProfilePage}
                                />
                                <PrivateRoute
                                    route={AppRoute.PaymentInfos}
                                    component={ProfilePage}
                                />
                                <PrivateRoute
                                    route={AppRoute.ParkingInfos}
                                    component={ProfilePage}
                                />
                                <PrivateRoute
                                    route={AppRoute.BoatInfos}
                                    component={ProfilePage}
                                />
                                <PrivateRoute
                                    route={AppRoute.BoatInfosProfile}
                                    component={ProfilePage}
                                />
                                <PrivateRoute
                                    route={AppRoute.Inqueries}
                                    component={ProfilePage}
                                />
                                <PrivateRoute
                                    route={AppRoute.InsuranceInqueries}
                                    component={ProfilePage}
                                />
                                <PrivateRoute
                                    route={AppRoute.InsuranceDetails}
                                    component={ProfilePage}
                                />
                                <PrivateRoute
                                    route={AppRoute.ChatBox}
                                    component={ProfilePage}
                                />
                                <PrivateRoute
                                    route={AppRoute.RepairsInqueries}
                                    component={ProfilePage}
                                />
                                <PrivateRoute
                                    route={AppRoute.InqueriesParking}
                                    component={ProfilePage}
                                />
                                <PrivateRoute
                                    route={AppRoute.Messages}
                                    component={ProfilePage}
                                />
                                <Route
                                    exact
                                    route={AppRoute.Root}
                                    component={HomePage}
                                ></Route>

                                <Route
                                    exact
                                    route={AppRoute.Home}
                                    component={HomePage}
                                ></Route>

                                <Route
                                    route={AppRoute.LogBook.Settings.MyUsers}
                                    component={LogbookHome}
                                />
                                <Route
                                    route={AppRoute.LogBook.Fleet}
                                    component={LogbookHome}
                                />
                                <Route
                                    route={AppRoute.LogBook.Inventory}
                                    component={LogbookHome}
                                />
                                <Route
                                    route={AppRoute.LogBook.Orders}
                                    component={LogbookHome}
                                />
                                <Route
                                    route={AppRoute.LogBook.Settings.WorkTimes}
                                    component={LogbookHome}
                                />
                                <Route
                                    route={AppRoute.LogBook.LogBook}
                                    component={LogbookHome}
                                />
                                <Route
                                    exact
                                    route={AppRoute.PricesPage}
                                    component={PricesPage}
                                />
                                <Route
                                    route={AppRoute.PricesPagePart}
                                    component={() => (
                                        <PricesPage particulier={true} />
                                    )}
                                />
                                <Route
                                    route={AppRoute.PricesPageYacht}
                                    component={() => (
                                        <PricesPage particulier={false} />
                                    )}
                                />

                                {/* PARKING */}
                                <Route
                                    route={AppRoute.ParkingID}
                                    component={ParkingPage}
                                />
                                <PrivateRoute
                                    route={AppRoute.Booking}
                                    component={BookingPage}
                                />
                                <PrivateRoute route={AppRoute.SepaPage}>
                                    <Elements stripe={stripePromise}>
                                        <SepaPage />
                                    </Elements>
                                </PrivateRoute>

                                <Route
                                    exact
                                    route={AppRoute.Root}
                                    component={HomePage}
                                />

                                <Route exact route={AppRoute.Home}>
                                    <Redirect to="/" />
                                </Route>

                                <Route
                                    exact
                                    path="/cgv"
                                    title="cgv"
                                    component={() => {
                                        window.location =
                                            'https://www.info.boaton.fr/cgv'
                                        return null
                                    }}
                                />

                                <Route
                                    route={AppRoute.LogBook.Home}
                                    component={LogbookHome}
                                />
                                <Route path="/my-boat-logbook/boaton-book">
                                    <Redirect
                                        status={301}
                                        to="/boaton-book/boaton-book"
                                    />
                                </Route>
                                <Route path="/my-boat-logbook/my-alerts">
                                    <Redirect
                                        status={301}
                                        to="/boaton-book/mes-alertes"
                                    />
                                </Route>
                                <Route path="/my-boat-logbook/my-informations">
                                    <Redirect
                                        status={301}
                                        to="/boaton-book/mes-informations"
                                    />
                                </Route>
                                <Route path="/my-boat-logbook/my-expenses-and-incomes">
                                    <Redirect
                                        status={301}
                                        to="/boaton-book/mes-depenses-et-revenus"
                                    />
                                </Route>
                                <Route path="/my-boat-logbook/my-documents">
                                    <Redirect
                                        status={301}
                                        to="/boaton-book/mes-documents"
                                    />
                                </Route>
                                <Route path="/my-boat-logbook/">
                                    <Redirect status={301} to="/boaton-book/" />
                                </Route>

                                <Route path="/search/.hash.horsbord/">
                                    <Redirect to="/" />
                                </Route>
                                <Route path="/search/.hash.achat/">
                                    <Redirect to="/" />
                                </Route>
                                <Route path="/search/.hash.jetski/">
                                    <Redirect to="/" />
                                </Route>
                                <Route path="/search/.hash.moteur/">
                                    <Redirect to="/" />
                                </Route>
                                <Route path="/search/.hash.inboard/">
                                    <Redirect to="/" />
                                </Route>
                                <Route path="/search/.hash.voile/">
                                    <Redirect to="/" />
                                </Route>
                                <Route path="/search/.hash.bateau/">
                                    <Redirect to="/" />
                                </Route>

                                {/* VOILA LES SOFTS 404 NE FONCTIONNANT PAS */}
                                <Route path="/search-boat-parking#/">
                                    <Redirect to="/" />
                                </Route>
                                <Route path="/my-boat-logbook/my-expenses-and-incomes/">
                                    <Redirect to="/" />
                                </Route>

                                <Route
                                    exact
                                    path="/book"
                                    component={() => {
                                        window.location =
                                            'https://www.boaton.fr/my-boat-logbook'
                                        return null
                                    }}
                                />
                                <Route
                                    route={AppRoute.ParkingSearchPage}
                                    component={ParkingSearchPage}
                                />
                                <Route
                                    exact
                                    route={AppRoute.InsurancePage}
                                    component={InsurancePage}
                                />
                                {/*<Route exact path="/show-tarification" component={ShowTarificationPage}/>*/}
                                <Route
                                    exact
                                    route={AppRoute.RentMyParking}
                                    component={RentParkingPage}
                                />
                                <PrivateRoute
                                    exact
                                    path="/edit-my-parking/:id"
                                    component={EditParkingPage}
                                />
                                <PrivateRoute
                                    exact
                                    path="/duplicate-my-parking/:id"
                                    component={DuplicateParkingPage}
                                />
                                <Route
                                    exact
                                    path="/boatonPro"
                                    title="boaton pro"
                                    component={() => {
                                        window.location =
                                            'https://www.pro.boaton.fr'
                                        return null
                                    }}
                                />
                                <Route
                                    exact
                                    path="/boatonProParkings"
                                    component={() => {
                                        window.location =
                                            'https://www.pro.boaton.fr/#/parkings'
                                        return null
                                    }}
                                />
                                <Route
                                    exact
                                    path="/reparation-tempo"
                                    title="reparation"
                                    component={() => {
                                        window.location =
                                            'https://www.info.boaton.fr/custom'
                                        return null
                                    }}
                                />
                                <Route
                                    exact
                                    path="/carnet-tempo"
                                    title="carnet d'entretient"
                                    component={() => {
                                        window.location =
                                            'https://www.info.boaton.fr/mon-carnet-d-entretien'
                                        return null
                                    }}
                                />
                                <Route
                                    path="/le-blog/"
                                    title="blog"
                                    component={value => {
                                        window.location =
                                            'https://www.info.boaton.fr/le-blog/'
                                        return null
                                    }}
                                />
                                <Route
                                    path="/post/"
                                    title="post"
                                    component={value => {
                                        window.location =
                                            'https://www.info.boaton.fr/post/'
                                        return null
                                    }}
                                />

                                <Route
                                    path="*"
                                    component={Error404StaticPage}
                                    status={404}
                                />
                            </MultiLangualSwitch>
                        </Suspense>
                    </LocalizedRouter>
                </LocalizationProvider>
            </MuiThemeProvider>
        )
    }
}

export default connect()(App)
