export default {
    parking: {
        FR: 'Emplacement',
        EN: 'Place',
    },
    received: {
        FR: 'Reçu',
        EN: 'Received',
    },
    accepted: {
        FR: 'Accepté',
        EN: 'Accepted',
    },
    from: {
        FR: 'Du:',
        EN: 'From:',
    },
    to: {
        FR: 'Au:',
        EN: 'To:',
    },
    address: {
        FR: 'Adresse :',
        EN: 'Address :',
    },
    equipment: {
        FR: 'Equipement:',
        EN: 'Equipment:',
    },
    name: {
        FR: 'Nom :',
        EN: 'Name :',
    },
    phone: {
        FR: 'Téléphone :',
        EN: 'Phone :',
    },
    moreInfos: {
        FR: "+ D'infos",
        EN: '+ Infos',
    },
    exactAddress: {
        FR:
            "L'adresse exacte vous sera indiquée une fois votre demande de location acceptée.",
        EN:
            'The exact address will be given to you once your rental application has been accepted.',
    },
    amount: {
        FR: 'Montant',
        EN: 'Amount',
    },
    noInquery: {
        FR: "Vous n'avez fait aucune demande de stockage",
        EN: 'You have not applied for a storage',
    },
    makeNew: {
        FR: 'Faire une nouvelle demande de stockage',
        EN: 'Applying for new storage',
    },
    makeFirst: {
        FR: 'Faire une demande de stockage',
        EN: 'Applying for storage',
    },
    yourRent: {
        FR: 'Votre location',
        EN: 'Your rental',
    },
    unknown: {
        FR: `Indéterminée`,
        EN: `Unknown`,
    },
}
