import { addressConstants } from '../constants'

const initialState = {
    autoComplet: null,
    geojson: null,
    loading: 0,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case addressConstants.REQUEST_AUTOCOMPLET_ADDRESS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
                autoComplet: null,
            }
        case addressConstants.REQUEST_AUTOCOMPLET_CITY:
            return {
                ...prevState,
                loading: prevState.loading + 1,
                autoComplet: null,
            }
        case addressConstants.REQUEST_AUTOCOMPLET_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                autoComplet: action.autoComplet,
            }
        case addressConstants.REQUEST_AUTOCOMPLET_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                autoComplet: null,
            }
        case addressConstants.REQUEST_GEOJSON_CITY:
            return {
                ...prevState,
                loading: prevState.loading + 1,
                geojson: null,
            }
        case addressConstants.REQUEST_GEOJSON_CITY_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                geojson: action.geojson,
            }
        case addressConstants.REQUEST_GEOJSON_CITY_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                geojson: null,
            }
        default:
            return prevState
    }
}
