export default theme => ({
    line: {
        width: 'calc(100% - 20px)',
        padding: '10px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: '50px',
        [theme.breakpoints.down('sm')]: {
            gap: 10,
            height: `fit-content`,
            width: '100%',
            // flexDirection: `column`,
            paddingTop: 10,
            paddingLeft: 10,
            paddingRight: 10,
            boxSizing: `border-box`,
        },
    },
    checkbox: {
        [theme.breakpoints.down('sm')]: {
            padding: 0,
        },
    },
    bigScreenOnly: {
        [theme.breakpoints.down('sm')]: {
            display: `none !important`,
        },
    },
    blueLine: {
        backgroundColor: '#F7F9FB',
    },
    infos: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #c4c4c4',
        boxShadow: '0px 2px 10px 0.3px #c4c4c4',
    },
    root: {
        width: 'calc(100% - 50px)',
        margin: '25px',
        marginTop: 0,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            margin: 0,
        },
    },
    title: {
        fontWeight: 500,
        margin: '10px',
    },
    borderTop: {
        [theme.breakpoints.down('sm')]: {
            borderTop: `1px solid lightgray`,
        },
    },
    subtitle: {
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: 'auto',
        fontWeight: 500,
    },
    type: {
        textAlign: 'left',
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    titleAndType: {
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: '70%',
        },
    },
    date: {
        width: '100%',
        fontSize: '20px',
        whiteSpace: 'nowrap',
    },
    actionMobile: {
        width: 207,
        height: 44,
        boxSizing: `border-box`,
        fontWeight: 400,
        fontSize: `14px`,
        justifyContent: `flex-start`,
        paddingLeft: 17,
    },
    filesIconContainer: {
        width: 40,
    },
    filesIcon: {
        minWidth: 0,
        paddingLeft: 0,
    },
    startIconOverride: {
        marginLeft: 0,
        marginRight: 0,
    },
    iconsMobile: {
        minWidth: '90px',
        display: 'flex',
        flexDirection: 'column',
    },
    adaptableSection: {
        height: `100%`,
        width: `fit-content`,
        display: `flex`,
        alignItems: `center`,
        gap: 10,
        [theme.breakpoints.down('sm')]: {
            width: `100%`,
            marginBottom: 10,
        },
    },
    mobileOnly: {
        display: `none`,
        [theme.breakpoints.down('sm')]: {
            display: `inline-block`,
            marginLeft: 5,
        },
    },
    dates: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        marginInline: '20px',
        width: 30,
        [theme.breakpoints.down('sm')]: {
            marginInline: 0,
        },
    },
    datesDayMonth: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    amount: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '80px',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            // display: `none`,
            width: 'auto',
        },
    },
    modifyDelete: {
        textAlign: 'center',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '10%',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    firstLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    dividerH: {
        border: '0.5px solid #c4c4c4',
        height: '50px',
        [theme.breakpoints.down('sm')]: {
            display: `none`,
        },
    },
    icon: {
        width: '40px',
        height: '40px',
        marginLeft: '10px',
        marginRight: '10px',
        [theme.breakpoints.down('sm')]: {
            width: '30px',
            height: '30px',
            // padding: '5px',
            padding: 0,
            margin: 0,
        },
    },
    icons: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        color: '#000',
        alignItems: 'center',
        width: '25px',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            display: `none`,
        },
    },
    icone: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        color: '#000',
        alignItems: 'center',
        width: '25px',
        '&:hover': {
            cursor: 'pointer',
        },
        [theme.breakpoints.down('sm')]: {
            display: `none`,
        },
    },
    iconDisabled: {
        display: 'block',
        marginTop: 'auto',
        marginBottom: 'auto',
        width: '25px',
        color: '#C1C1C1',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    iconlink: {
        textDecoration: 'none',
    },
    green: {
        color: 'green',
    },
    red: {
        color: 'red',
    },
    section: {
        display: 'flex',
        flexGrow: 1,
        justifyContent: 'space-between',
    },
    renderDateRoot: {
        flexDirection: 'column',
    },
    iconButton: {
        [theme.breakpoints.down('sm')]: {
            paddingInline: 0,
        },
    },
    mobileTypeAndAmountContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
    },
})
