import { settingsConstants } from '../../constants/settings.constants'
import { requestApi } from '../../services/api.service'

export const defaultGroupSettings = {
    groupName: `Groupe`,
    byDefault: true,
    dailyLimitTime: '12:00:00',
    minimumRestTime: '12:00:00',
    userLinks: [],
    mondaySetting: {
        startTime: '08:00:00',
        endTime: '17:00:00',
        breakTimes: [
            {
                startTime: '12:00:00',
                endTime: '13:00:00',
            },
        ],
    },
    tuesdaySetting: {
        startTime: '08:00:00',
        endTime: '17:00:00',
        breakTimes: [
            {
                startTime: '12:00:00',
                endTime: '13:00:00',
            },
        ],
    },
    wednesdaySetting: {
        startTime: '08:00:00',
        endTime: '17:00:00',
        breakTimes: [
            {
                startTime: '12:00:00',
                endTime: '13:00:00',
            },
        ],
    },
    thursdaySetting: {
        startTime: '08:00:00',
        endTime: '17:00:00',
        breakTimes: [
            {
                startTime: '12:00:00',
                endTime: '13:00:00',
            },
        ],
    },
    fridaySetting: {
        startTime: '08:00:00',
        endTime: '17:00:00',
        breakTimes: [
            {
                startTime: '12:00:00',
                endTime: '13:00:00',
            },
        ],
    },
    saturdaySetting: {
        startTime: '08:00:00',
        endTime: '17:00:00',
        breakTimes: [],
    },
    sundaySetting: {
        startTime: '08:00:00',
        endTime: '17:00:00',
        breakTimes: [],
    },
}

export const defaultGroupPaidVacationSettings = {
    nbPaidVacationAllowed: 25,
    dayStartRefPeriod: 1,
    monthStartRefPeriod: 6,
    allowPaidVacationAddition: false,
    allowedVacationsClickedLinkUserId: null, //Définit pour quel utilisateur on modifie les jours autorisés
}

const getGroupWorkTimeSettings = ({ groupId }) => {
    return dispatch => {
        dispatch({ type: settingsConstants.GET_GROUP_WORKTIME_SETTINGS })

        return requestApi(`/work-time-settings/group/${groupId}`, 'GET')
            .then(
                result => {
                    const groupByDefault = result.filter(
                        group => group.byDefault === true,
                    )

                    dispatch({
                        type:
                            settingsConstants.GET_GROUP_WORKTIME_SETTINGS_SUCCESS,
                        workTimeSettings:
                            groupByDefault.length > 0
                                ? result
                                : result.length > 0
                                ? [...result, defaultGroupSettings]
                                : [defaultGroupSettings],
                    })
                },
                error => {
                    console.error(error)
                    dispatch({
                        type:
                            settingsConstants.GET_GROUP_WORKTIME_SETTINGS_FAILURE,
                        workTimeSettings: [],
                    })
                },
            )
            .catch(error => {
                console.error(error)
                dispatch({
                    type: settingsConstants.GET_GROUP_WORKTIME_SETTINGS_FAILURE,
                    workTimeSettings: [],
                })
            })
    }
}

const postGroupWorkTimeSettings = ({ groupId, body }) => {
    return dispatch => {
        dispatch({ type: settingsConstants.POST_GROUP_WORKTIME_SETTINGS })

        return requestApi(
            `/work-time-settings/group/${groupId}`,
            'POST',
            body,
            false,
            false,
        )
            .then(
                result => {
                    dispatch({
                        type:
                            settingsConstants.POST_GROUP_WORKTIME_SETTINGS_SUCCESS,
                    })
                    dispatch(getGroupWorkTimeSettings({ groupId: groupId }))
                },
                error => {
                    dispatch({
                        type:
                            settingsConstants.POST_GROUP_WORKTIME_SETTINGS_FAILURE,
                    })
                },
            )
            .catch(error => {
                dispatch({
                    type:
                        settingsConstants.POST_GROUP_WORKTIME_SETTINGS_FAILURE,
                })
            })
    }
}

const putGroupWorkTimeSettings = ({ groupSettingId, groupId, body }) => {
    return dispatch => {
        dispatch({ type: settingsConstants.PUT_GROUP_WORKTIME_SETTINGS })

        return requestApi(
            `/work-time-settings/${groupSettingId}`,
            'PUT',
            body,
            false,
        )
            .then(
                result => {
                    dispatch({
                        type:
                            settingsConstants.PUT_GROUP_WORKTIME_SETTINGS_SUCCESS,
                    })
                    dispatch(getGroupWorkTimeSettings({ groupId: groupId }))
                },
                error => {
                    dispatch({
                        type:
                            settingsConstants.PUT_GROUP_WORKTIME_SETTINGS_FAILURE,
                    })
                },
            )
            .catch(error => {
                dispatch({
                    type: settingsConstants.PUT_GROUP_WORKTIME_SETTINGS_FAILURE,
                })
            })
    }
}

export const deleteGroupWorkTimeSettings = ({ groupSettingId }) => {
    return dispatch => {
        dispatch({ type: settingsConstants.DELETE_GROUP_WORKTIME_SETTINGS })
        return requestApi(
            `/work-time-settings/${groupSettingId}`,
            'DELETE',
        ).then(
            result => {
                dispatch({
                    type:
                        settingsConstants.DELETE_GROUP_WORKTIME_SETTINGS_SUCCESS,
                })
                return result
            },
            error => {
                dispatch({
                    type:
                        settingsConstants.DELETE_GROUP_WORKTIME_SETTINGS_FAILURE,
                    error,
                })
            },
        )
    }
}

const getPaidVacationsSettings = ({ groupId }) => {
    return dispatch => {
        dispatch({ type: settingsConstants.GET_GROUP_PAID_VACATIONS_SETTINGS })

        return requestApi(
            `/paid-vacation-settings/group/${groupId}`,
            'GET',
        ).then(
            result => {
                dispatch({
                    type:
                        settingsConstants.GET_GROUP_PAID_VACATIONS_SETTINGS_SUCCESS,
                    paidVacationsSettings: result,
                })
            },
            error => {
                // Si il n'existe aucun groupe
                // Alors on créé un par défaut
                dispatch({
                    type:
                        settingsConstants.GET_GROUP_PAID_VACATIONS_SETTINGS_FAILURE,
                })

                if (groupId) {
                    dispatch(
                        postGroupPaidVacationsSettings({
                            groupId: groupId,
                            body: defaultGroupPaidVacationSettings,
                        }),
                    )
                }
            },
        )
    }
}

const postGroupPaidVacationsSettings = ({ groupId, body }) => {
    return dispatch => {
        dispatch({ type: settingsConstants.POST_GROUP_PAID_VACATIONS_SETTINGS })

        if (!groupId) {
            dispatch({
                type:
                    settingsConstants.POST_GROUP_PAID_VACATIONS_SETTINGS_FAILURE,
            })
        }

        return requestApi(
            `/paid-vacation-settings/group/${groupId}`,
            'POST',
            body,
            false,
            false,
        )
            .then(
                async result => {
                    await dispatch({
                        type:
                            settingsConstants.POST_GROUP_PAID_VACATIONS_SETTINGS_SUCCESS,
                    })
                    dispatch(getPaidVacationsSettings({ groupId }))
                },
                error => {
                    dispatch({
                        type:
                            settingsConstants.POST_GROUP_PAID_VACATIONS_SETTINGS_FAILURE,
                    })
                },
            )
            .catch(error => {
                dispatch({
                    type:
                        settingsConstants.POST_GROUP_WORKTIME_SETTINGS_FAILURE,
                })
            })
    }
}
const putGroupPaidVacationsSettings = ({ groupSettingId, body }) => {
    return dispatch => {
        dispatch({ type: settingsConstants.PUT_GROUP_PAID_VACATIONS_SETTINGS })

        return requestApi(
            `/paid-vacation-settings/${groupSettingId}`,
            'PUT',
            body,
            false,
        )
            .then(
                result => {
                    dispatch({
                        type:
                            settingsConstants.PUT_GROUP_PAID_VACATIONS_SETTINGS_SUCCESS,
                    })
                },
                error => {
                    dispatch({
                        type:
                            settingsConstants.PUT_GROUP_PAID_VACATIONS_SETTINGS_FAILURE,
                    })
                },
            )
            .catch(error => {
                dispatch({
                    type:
                        settingsConstants.PUT_GROUP_PAID_VACATIONS_SETTINGS_FAILURE,
                })
            })
    }
}

export const settingsActions = {
    getGroupWorkTimeSettings,
    postGroupWorkTimeSettings,
    putGroupWorkTimeSettings,
    deleteGroupWorkTimeSettings,
    getPaidVacationsSettings,
    postGroupPaidVacationsSettings,
    putGroupPaidVacationsSettings,
}
