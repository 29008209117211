export default {
    helpGreyChip: {
        FR: `Cet utilisateur n'a pas encore rejoint votre flotte`,
        EN: `This user has not yet joined your fleet`,
    },
    addLabelButton: {
        FR: `Ajouter un utilisateur`,
        EN: `Add a user`,
    },
    tooltipSupervisor: {
        FR: `Renseignez le ou les managers chargés de suivre la résolution de cette tâche. Ils recevront un e-mail les invitant à se connecter et traiter la tâche.`,
        EN: `Fill in the manager(s) responsible for following the resolution of this task. They will receive an email inviting them to connect and treat the task.`,
    },
}
