import React from 'react'
import BoatOnComponent from '../../../common/BoatOnComponent'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import styles from './Styles/OrderPageCss'
import { bobOrdersActions } from '../../../../actions/bob/bobOrders.actions'
import Checkbox from '@material-ui/core/Checkbox'
import BoatOnRenderDate from '../../../common/UsefullComponents/BoatOnRenderDate'
import AttachFile from '@material-ui/icons/AttachFile'
import {
    Button as ButtonMui,
    IconButton,
    Popover,
    Typography,
} from '@material-ui/core'
import BoatOnModal from '../../../common/BoatOnModal'
import BoatOnModalCore from '../../../common/BoatOnModalCore'
import BoatOnImageRender from '../../../common/BoatOnImageRender'
import dictionary from './Dictionary/OrderPageDico'
import { API_URL } from '../../../../services/config.service'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import Edit from '@material-ui/icons/Edit'
import Delete from '@material-ui/icons/Delete'
import OrderModal from './OrderModal'
import { typesActions } from '../../../../actions'
import BobFilter from '../BobFilter'
import pluralize from 'pluralize'
import { boatActions } from '../../../../actions/boat.actions'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'
import { Actions, PermissionsEntities } from '../../../../constants'

class OrderPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            openModal: null,
            openDoc: null,
            orderPopup: null,
            orderToEdit: null,
            anchorPopup: null,
            orderChecked: [],
        }

        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleOpenModalDocument = this.handleOpenModalDocument.bind(this)
        this.handleOpenPopOver = this.handleOpenPopOver.bind(this)
        this.handleClosePopup = this.handleClosePopup.bind(this)
        this.handleCheckOrder = this.handleCheckOrder.bind(this)
        this.handleCheckAll = this.handleCheckAll.bind(this)
        this.edit = this.edit.bind(this)
        this.delete = this.delete.bind(this)
    }

    edit(order) {
        this.setState({
            openModal: 'edit',
            orderToEdit: JSON.parse(JSON.stringify(order)),
        })
    }

    delete() {
        this.props.dispatch(
            bobOrdersActions.deleteBobOrder(this.state.orderPopup.id),
        )
        this.handleClosePopup()
    }

    handleCheckAll(value, orders) {
        if (value) {
            this.setState({
                orderChecked: orders.map(order => order.id),
            })
        } else {
            this.setState({
                orderChecked: [],
            })
        }
    }

    handleCheckOrder(orderId) {
        const { orderChecked } = this.state

        if (orderChecked.includes(orderId)) {
            this.setState({
                orderChecked: orderChecked.filter(id => id !== orderId),
            })
        } else {
            this.setState({
                orderChecked: [...orderChecked, orderId],
            })
        }
    }

    handleClosePopup() {
        this.setState({
            anchorPopup: null,
            orderPopup: null,
        })
    }

    handleOpenModalDocument(document) {
        this.setState({ openModal: 'showDocs', openDoc: document })
    }

    handleCloseModal() {
        this.setState({ openModal: false, openDoc: null, orderToEdit: null })
    }

    handleOpenPopOver(e, order) {
        this.setState({
            orderPopup: order,
            anchorPopup: e.currentTarget,
        })
    }

    componentDidMount() {
        const { groupId, linkedBoats, dispatch } = this.props

        if (groupId) dispatch(bobOrdersActions.getBobOrders(groupId))

        dispatch(typesActions.requestOrderStatusType())
        if (!linkedBoats || linkedBoats.length === 0) {
            dispatch(boatActions.requestLinkedBoats())
        }
    }

    _renderPopOver() {
        const { classes } = this.props
        const { orderPopup, anchorPopup } = this.state

        return (
            <Popover
                id={'popover'}
                open={orderPopup ? true : false}
                anchorEl={anchorPopup}
                onClose={this.handleClosePopup}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className={classes.iconsMobile}>
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Update,
                            entityName: PermissionsEntities.Order,
                        }}
                        children={
                            <ButtonMui
                                onClick={() => {
                                    this.edit(orderPopup)
                                    this.handleClosePopup()
                                }}
                                className={classes.actionMobile}
                                startIcon={<Edit />}
                            >
                                {this.displayText(`edit`)}
                            </ButtonMui>
                        }
                    />
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Delete,
                            entityName: PermissionsEntities.Order,
                        }}
                        children={
                            <ButtonMui
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            this.displayText('validateDelete'),
                                        )
                                    ) {
                                        this.delete(orderPopup)
                                    }
                                }}
                                className={classes.actionMobile}
                                startIcon={<Delete />}
                            >
                                {this.displayText(`delete`)}
                            </ButtonMui>
                        }
                    />
                </div>
            </Popover>
        )
    }

    _renderLine(order, index) {
        const { classes, orderStatusTypes } = this.props
        const { orderChecked } = this.state

        return (
            <tr
                className={`${classes.line} ${
                    index % 2 === 1 ? `` : classes.blueLine
                }`}
                key={index}
            >
                <td className={classes.checkbox}>
                    <Checkbox
                        color="primary"
                        onChange={() => {
                            this.handleCheckOrder(order.id)
                        }}
                        checked={
                            orderChecked.find(id => id === order.id)
                                ? true
                                : false
                        }
                    />
                </td>
                <td>
                    <BoatOnRenderDate
                        date={new Date(order.orderDate)}
                        classesOverride={{
                            root: classes.renderDateRoot,
                        }}
                    />
                </td>
                <td>
                    <Typography>
                        N°{order.orderNumber} - {this.displayText('operator')}:{' '}
                        {order.operator}
                    </Typography>
                </td>
                <td>
                    <Typography
                        className={`${classes.tag} ${
                            order.orderStatusType.id >= 2 &&
                            order.orderStatusType.id <= 4
                                ? classes.yellowTag
                                : order.orderStatusType.id === 5
                                ? classes.greenTag
                                : order.orderStatusType.id === 6
                                ? classes.redTag
                                : ''
                        }`}
                    >
                        {this.displayTextApi(
                            orderStatusTypes.find(
                                orderStatusType =>
                                    orderStatusType.id ===
                                    order.orderStatusType.id,
                            )?.translation,
                        ).toUpperCase()}
                    </Typography>
                </td>
                <td>
                    {order?.transaction?.amountIt
                        ? `${order.transaction.amountIt}€`
                        : null}
                </td>
                <td className={classes.icon}>
                    <IconButton
                        onClick={() => {
                            this.handleOpenModalDocument(order.orderForm)
                        }}
                    >
                        <AttachFile style={{ color: `black` }} />
                    </IconButton>
                </td>
                <td className={classes.icon}>
                    <BoatOnBlock
                        permissions={[
                            {
                                action: Actions.Update,
                                entityName: PermissionsEntities.Order,
                            },
                            {
                                action: Actions.Delete,
                                entityName: PermissionsEntities.Order,
                            },
                        ]}
                        onlyOnePermission
                        children={
                            <IconButton
                                onClick={e => {
                                    this.handleOpenPopOver(e, order)
                                }}
                            >
                                <MoreHorizIcon style={{ color: `black` }} />
                            </IconButton>
                        }
                    />
                </td>
            </tr>
        )
    }

    _renderDocuments() {
        const { openDoc } = this.state

        if (!openDoc) return <></>

        return (
            <BoatOnModalCore
                body={
                    <BoatOnImageRender
                        vertical
                        multi
                        bottomImage
                        imgs={[
                            {
                                ...openDoc,
                                link: API_URL + '/files/' + openDoc.link,
                            },
                        ]}
                    />
                }
            />
        )
    }

    _renderFilter() {
        const { orderChecked } = this.state
        const { orders } = this.props

        return (
            <BobFilter
                fieldName={'orders'}
                selectAll={value => {
                    this.handleCheckAll(value, orders)
                }}
                checkedBoxes={orderChecked}
                activeButton={{
                    equipmentFamily: true,
                    equipmentParts: true,
                    rangeDate: true,
                    rangeAmount: true,
                    boatsByFloat: true,
                    orderStatus: true,
                    orderOperator: true,
                }}
                nbEvent={orders.length}
            />
        )
    }

    renderEmpty() {
        const { classes } = this.props

        return (
            <div className={classes.empty}>
                <Typography>{this.displayText('noOrders')}</Typography>
            </div>
        )
    }

    render() {
        const { orders, classes, loading, nbOrders } = this.props
        const { openModal, orderPopup, orderToEdit } = this.state

        if (loading > 0) return this.renderLoading('100')

        return (
            <div className={classes.root}>
                {this._renderFilter()}
                {nbOrders === 0 && loading === 0 ? (
                    this.renderEmpty()
                ) : (
                    <>
                        <table className={classes.table}>
                            <tbody>
                                {orders.map((order, index) =>
                                    this._renderLine(order, index),
                                )}
                            </tbody>
                        </table>
                        <BoatOnModal
                            openCondition={openModal}
                            handleClose={this.handleCloseModal}
                            modalCores={{
                                showDocs: this._renderDocuments(),
                                edit: <OrderModal orderToEdit={orderToEdit} />,
                            }}
                            titles={{ showDocs: 'Mes documents' }}
                        />
                        {orderPopup && this._renderPopOver()}
                    </>
                )}
            </div>
        )
    }
}

function filterOrders(orders, bobFilter) {
    if (
        bobFilter.selectedBoatsByFleet &&
        bobFilter.selectedBoatsByFleet.length > 0
    ) {
        orders = orders.filter(order =>
            bobFilter.selectedBoatsByFleet.includes(order.boat?.id),
        )
    }

    if (bobFilter.rangeDate.start && bobFilter.rangeDate.end) {
        const start = new Date(bobFilter.rangeDate.start).setHours(0, 0, 0, 0)
        const end = new Date(bobFilter.rangeDate.end).setHours(0, 0, 0, 0)

        orders = orders.filter(
            order =>
                order.orderDate &&
                start <= new Date(order.orderDate).setHours(0, 0, 0, 0) &&
                end >= new Date(order.orderDate).setHours(0, 0, 0, 0),
        )
    }

    if (bobFilter.rangeAmount.min && bobFilter.rangeAmount.max) {
        orders = orders.filter(
            order =>
                order.transaction?.amountIt &&
                bobFilter.rangeAmount.min <= order.transaction.amountIt &&
                bobFilter.rangeAmount.max >= order.transaction.amountIt,
        )
    }

    if (bobFilter.searchString && bobFilter.searchString !== '') {
        orders = orders.filter(order => {
            const words = bobFilter.searchString
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .split(' ')

            return words.every(word => {
                return (
                    order.operator
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .includes(pluralize.singular(word)) ||
                    order.orderNumber
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .includes(pluralize.singular(word))
                )
            })
        })
    }

    if (
        bobFilter.orderStatusTypesChecked &&
        bobFilter.orderStatusTypesChecked.length > 0
    ) {
        orders = orders.filter(order =>
            bobFilter.orderStatusTypesChecked.includes(
                order?.orderStatusType?.id,
            ),
        )
    }

    if (
        bobFilter.orderOperatorChecked &&
        bobFilter.orderOperatorChecked.length > 0
    ) {
        orders = orders.filter(order =>
            bobFilter.orderOperatorChecked.includes(order.operator),
        )
    }

    if (
        bobFilter.equipmentFamilyType &&
        bobFilter.equipmentFamilyType.length > 0
    ) {
        orders = orders.filter(order => {
            let keep = false

            ;(order.inventoryDetails || []).forEach(item => {
                if (
                    item.equipment?.equipmentFamily?.id &&
                    bobFilter.equipmentFamilyType.includes(
                        item.equipment.equipmentFamily.id,
                    )
                )
                    keep = true

                if (
                    item.part?.equipment?.equipmentFamily?.id &&
                    bobFilter.equipmentFamilyType.includes(
                        item.part.equipment.equipmentFamily.id,
                    )
                )
                    keep = true
            })

            return keep
        })
    }

    if (
        bobFilter.equipmentPartsChecked &&
        bobFilter.equipmentPartsChecked.length > 0
    ) {
        orders = orders.filter(order => {
            let keep = false

            ;(order.inventoryDetails || []).forEach(item => {
                if (
                    item.part?.id &&
                    bobFilter.equipmentPartsChecked.includes(item.part?.id)
                )
                    keep = true
            })

            return keep
        })
    }

    return orders
}

function mapStateToProps(state) {
    let orders = state.bobOrder.orders
    const bobFilter = state.filter.bobFilter

    if (orders) orders = filterOrders(orders, bobFilter)

    return {
        user: state.authentication.user,
        linkedUsers: state.group?.groupsMembers?.linkRGU || [],
        groupId: state.group.currentGroupId,
        orderForm: state.bobOrder?.newOrderForm,
        boat: state.bob.boat,
        orders,
        orderStatusTypes: state.types.orderStatusTypes || [],
        bobFilter: bobFilter,
        linkedBoats: state.boat?.linkedBoats,
        loading: state.bobOrder.loading,
        nbOrders: state.bobOrder?.orders?.length || 0,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(OrderPage))
