export default {
    descriptionPart1: {
        FR: `D’après les informations que vous avez renseignées, le prochain contrôle de`,
        EN: `According to the information you have entered, the next`,
    },
    due: {
        FR: `doit s’effectuer le`,
        EN: `checkup is due on`,
    },
    descriptionPart2: {
        FR: `. Vous pouvez confirmer ou non cette prochaine échéance.`,
        EN: `. You can confirm or not this next deadline.`,
    },
    dontPlane: {
        FR: `Ne pas planifier`,
        EN: `Don't plan`,
    },
    plane: {
        FR: `Planifier`,
        EN: `Plan`,
    },
    nextCheckup: {
        FR: `Prochain contrôle planifié le`,
        EN: `Next inspection scheduled on`,
    },
    nextCheckupHour: {
        FR: `Prochain contrôle planifié à`,
        EN: `Next inspection scheduled in`,
    },
    descriptionHourPart1: {
        FR: `D’après les informations que vous avez renseignées, le prochain contrôle de`,
        EN: `Based on the information you entered, the next control`,
    },
    dueHour: {
        FR: `doit s’effectuer à`,
        EN: `must be carried out at`,
    },
    hours: {
        FR: `heures`,
        EN: `hours`,
    },
    descriptionHourPart2: {
        FR: `. Vous pouvez confirmer ou non cette prochaine échéance.`,
        EN: `. You can confirm or not this next deadline.`,
    },
    errorActualCycleLengthLast: {
        FR: `Indiquez ici le nombre d'heures à laquelle devra être fait cette tâche.`,
        EN: `Indicate here the number of hours at which this task should be done.`,
    },
    errorDate: {
        FR: `Vous devez choisir une date à laquelle sera fait cette tâche.`,
        EN: `You must choose a date for the task.`,
    },
}
