export default {
    multiElement: {
        FR: 'éléments modifiés',
        EN: 'changed elements',
    },
    oneElement: {
        FR: 'élément modifié',
        EN: 'changed element',
    },
    cancel: {
        FR: 'Annuler',
        EN: 'Cancel',
    },
    save: {
        FR: 'Enregistrer',
        EN: 'Save',
    },
}
