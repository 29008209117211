import React from 'react'
import { connect } from 'react-redux'

import { history } from '../../../../helpers'
import AppRoute from '../../../../constants/AppRoute'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import dictionary from './Dictionary/ModalBoatDico'
import styles from './Styles/ModalBoatsCss'
import BoatOnComponent from '../../BoatOnComponent'
import RenderBoatCreate from '../RenderBoatCreate'
import BoatOnModal from '../../BoatOnModal'
import BoatOnModalCore from '../../BoatOnModalCore'

class ModalNoBoat extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            open: props.open ? `boatInfos` : null,
            boatId: null,
        }

        this.valid = this.valid.bind(this)
        this.handleCreateBoat = this.handleCreateBoat.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidUpdate(prevProps) {
        if (
            this.state.open !== this.props.open &&
            prevProps.open !== this.props.open &&
            this.props.boatLoading === 0 &&
            this.props.modalShow !== 'infoPage'
        ) {
            this.setState({
                open: this.props.open ? `noBoat` : null,
            })
        } else if (
            this.props.open === true &&
            this.props.modalShow === 'infoPage' &&
            this.state.open !== 'boatInfos' &&
            this.state.open !== false &&
            this.props.boatLoading === 0 &&
            this.props.boat === null &&
            (!this.props.boatsGroup || this.props.boatsGroup?.length === 0)
        ) {
            this.setState({ open: 'boatInfos' })
        } else if (
            this.props.modalShow === 'infoPage' &&
            this.state.open === false &&
            prevProps.location &&
            prevProps.location.pathname &&
            this.props.location.pathname.toString() !==
                prevProps.location.pathname.toString() &&
            this.props.open === true
        ) {
            this.setState({ open: 'boatInfos' })
        } else if (
            this.props.open === false &&
            this.props.modalShow === 'infoPage' &&
            this.props.boatLoading === 0 &&
            this.props.boat?.id &&
            this.state.open === 'boatInfos'
        ) {
            this.setState({ open: null })
        }
    }

    handleCreateBoat() {
        this.setState({ open: 'boatInfos' })
        this.handleClose()
    }

    handleClose() {
        this.setState({ open: false })
        this.props.handleClose()
    }

    valid(next, boatId) {
        if (next && typeof next === 'string')
            this.setState({ open: next, boatId: boatId })
        else this.handleClose()

        this.historyPush(history, AppRoute.LogBook.Repair)
        window.location.reload()
    }

    render() {
        const { classes } = this.props
        return (
            <BoatOnModal
                openCondition={this.state.open}
                handleClose={this.handleClose}
                modalCores={{
                    noBoat: (
                        <BoatOnModalCore
                            body={
                                <Typography className={classes.helpText}>
                                    {this.displayText('boatNeeded')}
                                </Typography>
                            }
                            actions={[
                                {
                                    label: this.displayText('addBoat'),
                                    action: this.handleCreateBoat,
                                },
                            ]}
                        />
                    ),
                    boatInfos: <RenderBoatCreate valid={this.valid} />,
                }}
                titles={{
                    noBoat: this.displayText('missingBoat'),
                    boatInfos: this.displayText('boatAdd'),
                }}
                maxWidth={{ noBoat: `sm`, boatInfos: `sm` }}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        state: state.open,
        boatLoading: state.boat.loading,
        boat: state.bob.boat,
        boatsGroup: state.group.groupsMembers
            ? state.group.groupsMembers.boats
            : null,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ModalNoBoat))
