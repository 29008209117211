import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import BoatOnModalCore from '../../../common/BoatOnModalCore'
import dictionary from './Dictionary/InventoryHistoryModalDico'
import styles from './Styles/InventoryHistoryModalCss'
import {
    Typography,
    Tooltip,
    IconButton,
    DialogTitle,
    TextField,
    InputAdornment,
} from '@material-ui/core'
import bellicon from '../../../../images/bellicon.png'
import { Edit, CheckCircle, Add, Delete, Close } from '@material-ui/icons'
import BoatOnModal from '../../../common/BoatOnModal'
import InventoryModal from './InventoryModal/InventoryModal'
import InventoryReplacementModal from './InventoryReplacementModal'
import {
    putBOBEvents,
    removeDelimitedDateFromBOBEvent,
    postBOBEvents,
    removeDetailFromBOBEvent,
    getNameElementFromDetail,
    updateUserEquipmentName,
    updateUserPartName,
    updateEquipmentTypeName,
    updateEquipmentEngineType,
    updatePartTypeName,
} from '../../../../actions/bob.actions'
import { Button } from '../../../common'
import BoatOnBlock from '../../../common/Blocks/BoatOnBlock'
import { userActions } from '../../../../actions'
import BoatOnAutoComplete from '../../../common/BoatOnAutoComplete'
import { typesActions } from '../../../../actions/types.actions'
import { Actions, PermissionsEntities } from '../../../../constants'

class InventoryHistoryModal extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            equipment: props.equipment || null,
            part: props.part || null,
            actualDetail: null,
            availableDetails: null,
            replacedDetails: null,
            openModal: false,
            modalType: `INVENTORY_EQUIPMENT`,
            event: null,
            newElement: false,
            tabIndex: props.menuModalIndex || 0,
            editNew: 0,
            isEditingName: false,
            editingTitle: ``,
            editingEngine: null,
            title: props.title,
        }

        this.editRef = React.createRef()

        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.addStock = this.addStock.bind(this)
        this.editStock = this.editStock.bind(this)
        this.deleteEvent = this.deleteEvent.bind(this)
        this.unarchive = this.unarchive.bind(this)
        this.archive = this.archive.bind(this)
        this.handleChangeTab = this.handleChangeTab.bind(this)
        this.addSameStock = this.addSameStock.bind(this)
        this.addDifferentStock = this.addDifferentStock.bind(this)
        this._setTitle = this._setTitle.bind(this)
        this.updateIsEditingName = this.updateIsEditingName.bind(this)
        this.updateEditingTitle = this.updateEditingTitle.bind(this)
        this.handleClickOutside = this.handleClickOutside.bind(this)
        this.keyPress = this.keyPress.bind(this)
        this.getName = this.getName.bind(this)
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            JSON.stringify(this.props.events) !==
                JSON.stringify(prevProps.events) &&
            this.props.events
        ) {
            this.setDetails()
        }

        if (
            prevState.availableDetails?.length <
            this.state.availableDetails?.length
        ) {
            if (this.state.editNew === 1) {
                const newEvent = this.state.availableDetails.find(
                    e =>
                        undefined ===
                        prevState.availableDetails?.find(
                            elem => elem.id === e.id,
                        ),
                )
                if (newEvent) {
                    this.setState({
                        editNew: 0,
                        openModal: `addStock`,
                        event: newEvent,
                        newElement: true,
                    })
                }
            } else if (this.state.editNew === 2) {
                const newEvent = this.state.availableDetails.find(
                    e =>
                        undefined ===
                        prevState.availableDetails?.find(
                            elem => elem.id === e.id,
                        ),
                )
                if (newEvent) {
                    const {
                        description,
                        transaction,
                        eventType,
                        detail,
                        delimitedDate,
                    } = newEvent
                    this.props.dispatch(
                        putBOBEvents(newEvent.id, this._setTitle(detail), {
                            newFiles: [],
                            files: newEvent.files,
                            delimitedDate: delimitedDate,
                            transaction: transaction,
                            description: description,
                            eventTypeId: eventType.id,
                            deletedFiles: [],
                            detail: {
                                ...detail,
                                model: this.state.actualDetail.detail.model,
                                brand: this.state.actualDetail.detail.brand,
                            },
                        }),
                    )
                    this.setState({
                        editNew: 0,
                    })
                }
            }
        }
    }

    archive() {
        this.setState({ openModal: 'replace' })
    }

    _setTitle = detail => {
        if (detail) {
            if (detail.part) {
                return detail.part?.partType?.translation
                    ? this.displayTextApi(detail.part.partType.translation)
                    : detail.part.userPart.name
            }
            if (detail.equipment) {
                return detail.equipment.equipmentType
                    ? this.displayTextApi(
                          detail.equipment.equipmentType.translation,
                      )
                    : detail.equipment.userEquipment.name
            }
        }
        return null
    }

    unarchive(event) {
        const { actualDetail } = this.state

        this.props.dispatch(
            putBOBEvents(event.id, event.title, {
                detail: { ...event.detail, archived: null },
            }),
        )
        this.props.dispatch(removeDelimitedDateFromBOBEvent(actualDetail.id))
        this.handleChangeTab(0)
    }

    isActualEvent(event) {
        return (
            event.detail &&
            !event.detail.archived &&
            event.delimitedDate &&
            event.delimitedDate.startDate
        )
    }

    isAvailableEvent(event) {
        return !event.delimitedDate || !event.delimitedDate.startDate
    }

    isChangedEvent(event) {
        return event.detail && event.detail.archived
    }

    orderDetail({ actualDetail, availableDetails, replacedDetails, event }) {
        if (this.isChangedEvent(event)) replacedDetails.push(event)
        if (this.isAvailableEvent(event)) availableDetails.push(event)
        if (this.isActualEvent(event)) actualDetail = event
        return { actualDetail, availableDetails, replacedDetails }
    }

    addStock() {
        this.setState({ openModal: `addNewStock` })
    }

    async addSameStock() {
        const { boat } = this.props
        const { actualDetail } = this.state

        this.handleChangeTab(1)
        this.setState({
            editNew: 2,
        })
        await this.props.dispatch(
            postBOBEvents(boat.id, 7, actualDetail.title, {
                delimitedDate: null,
                detail: {
                    equipment: actualDetail.detail.equipment,
                    part: actualDetail.detail.part,
                },
            }),
        )
        this.handleCloseModal()
    }

    async addDifferentStock() {
        const { boat } = this.props
        const { actualDetail } = this.state

        this.handleChangeTab(1)
        this.setState({ editNew: 1 })
        await this.props.dispatch(
            postBOBEvents(boat.id, 7, actualDetail.title, {
                delimitedDate: null,
                detail: {
                    equipment: actualDetail.detail.equipment,
                    part: actualDetail.detail.part,
                },
            }),
        )
    }

    editStock(event, editDate) {
        this.setState({
            openModal: 'addStock',
            event: event,
            newElement: editDate,
        })
    }

    deleteEvent(e) {
        const { id } = e.currentTarget.dataset
        if (window.confirm(this.displayText('deleteElement')) && id) {
            this.props.dispatch(removeDetailFromBOBEvent(id))
        }
    }

    handleCloseModal() {
        this.setState({
            openModal: false,
            event: null,
            newElement: false,
        })
    }

    setDetails() {
        const { events } = this.props
        const { equipment, part } = this.state
        let actualDetail = null
        let availableDetails = []
        let replacedDetails = []

        if (events) {
            events.map(event => {
                if (
                    (equipment &&
                        event.detail &&
                        event.detail.equipment &&
                        equipment.id === event.detail.equipment.id) ||
                    (part &&
                        event.detail &&
                        event.detail.part &&
                        part.id === event.detail.part.id)
                ) {
                    ;({
                        actualDetail,
                        availableDetails,
                        replacedDetails,
                    } = this.orderDetail({
                        actualDetail,
                        availableDetails,
                        replacedDetails,
                        event,
                    }))
                }
            })
        }

        this.setState({ actualDetail, availableDetails, replacedDetails })
    }

    componentDidMount() {
        const { boat } = this.props

        document.addEventListener('mousedown', this.handleClickOutside)
        this.setDetails()
        this.props.dispatch(typesActions.requestUserEquipments(boat.id || null))
    }

    updateEquipmentName(newTitle, equipment) {
        const { boat } = this.props
        const { actualDetail, editingEngine } = this.state

        if (equipment.userEquipment && !equipment.equipmentType) {
            this.props
                .dispatch(
                    updateUserEquipmentName(equipment.userEquipment, newTitle),
                )
                .then(() => {
                    this.setState({ title: newTitle })
                })
        } else if (equipment.equipmentType && !equipment.userEquipment) {
            if (this._isActualSelectedEngine() && editingEngine !== null) {
                this.props
                    .dispatch(
                        updateEquipmentEngineType(
                            equipment.id,
                            actualDetail.id,
                            boat.id,
                        ),
                    )
                    .then(() => {
                        this.setState(
                            {
                                title: newTitle,
                                equipment: equipment,
                                editingTitle: ``,
                                editingEngine: null,
                            },
                            () => {
                                this.setDetails()
                            },
                        )
                    })
            } else {
                this.props
                    .dispatch(
                        updateEquipmentTypeName(
                            equipment.equipmentType,
                            newTitle,
                            boat.id,
                            this.getContext(),
                        ),
                    )
                    .then(newEquipment => {
                        this.setState(
                            {
                                title: newTitle,
                                equipment: newEquipment || equipment,
                            },
                            () => {
                                this.setDetails()
                            },
                        )
                    })
            }
        }
    }

    updatePartName(newTitle, part) {
        const { boat } = this.props

        if (part.userPart && !part.partType) {
            this.props
                .dispatch(updateUserPartName(part.userPart, newTitle))
                .then(() => {
                    this.setState({ title: newTitle })
                })
        } else if (part.partType && !part.userPart) {
            this.props
                .dispatch(updatePartTypeName(part.partType, newTitle, boat.id))
                .then(newPart => {
                    this.setState(
                        {
                            title: newTitle,
                            part: newPart || part,
                        },
                        () => {
                            this.setDetails()
                        },
                    )
                })
        }
    }

    updateElementName(newTitle) {
        const { equipment, part, title, editingEngine } = this.state

        if (this._isActualSelectedEngine() && editingEngine !== null) {
            this.updateEquipmentName(
                this.displayTextApi(editingEngine.equipmentType.translation),
                editingEngine,
            )
        } else {
            if (newTitle !== title) {
                if (equipment && !part) {
                    this.updateEquipmentName(newTitle, equipment)
                } else if (!equipment && part) {
                    this.updatePartName(newTitle, part)
                }
            }
        }
    }

    _isActualSelectedEngine() {
        const { actualDetail } = this.state
        return (
            actualDetail?.detail?.equipment?.id &&
            actualDetail.detail.equipment.id >= 1 &&
            actualDetail.detail.equipment.id <= 3
        )
    }

    handleClickOutside(event) {
        const { isEditingName, editingTitle, editingEngine, title } = this.state
        if (
            this?.editRef?.current?.contains &&
            !this.editRef.current.contains(event.target) &&
            isEditingName &&
            (!this._isActualSelectedEngine() ||
                (this._isActualSelectedEngine() &&
                    (editingEngine !== null ||
                        (editingEngine === null &&
                            editingTitle !== title &&
                            window.confirm(
                                this.displayText('confirmCreatingUserEngine'),
                            )))))
        ) {
            this.updateIsEditingName(editingTitle)
            this.updateElementName(editingTitle)
        } else if (
            this?.editRef?.current?.contains &&
            !this.editRef.current.contains(event.target) &&
            isEditingName &&
            this._isActualSelectedEngine() &&
            editingEngine === null &&
            editingTitle !== title
        ) {
            this.setState({ isEditingName: false })
        }
    }

    keyPress(e) {
        const { isEditingName, editingTitle, editingEngine, title } = this.state

        if (
            e.keyCode === 13 &&
            isEditingName &&
            (!this._isActualSelectedEngine() ||
                (this._isActualSelectedEngine() &&
                    (editingEngine !== null ||
                        (editingEngine === null &&
                            editingTitle !== title &&
                            window.confirm(
                                this.displayText('confirmCreatingUserEngine'),
                            )))))
        ) {
            this.updateIsEditingName(editingTitle)
            this.updateElementName(editingTitle)
        } else if (
            (e.keyCode === 13 &&
                isEditingName &&
                this._isActualSelectedEngine() &&
                editingEngine === null &&
                editingTitle !== title) ||
            e.keyCode === 27
        ) {
            this.setState({ isEditingName: false })
        }
    }

    handleOpenEdit(equipmentFamily, equipment, part, event) {
        if (part !== null) {
            this.setState({
                modalOpen: 'repair',
                event: event,
                modalType: part ? `INVENTORY_PART` : `INVENTORY_EQUIPMENT`,
                modalAdd: false,
            })
        } else {
            this.setState({
                modalOpen: 'repair',
                event: event,
                modalType: part ? `INVENTORY_PART` : `INVENTORY_EQUIPMENT`,
                modalAdd: false,
            })
        }
    }

    _renderDate(date) {
        return (
            <Typography align="left">
                {date ? new Date(date).toLocaleDateString() : '-'}
            </Typography>
        )
    }

    _renderBell(event) {
        const { classes, equipment } = this.props
        let color = null
        let text = null

        if (
            this._bellIsRed(
                (event.delimitedDate && event.delimitedDate.endDate) || null,
            )
        ) {
            color = classes.dateAlertRed
            text = this.displayText('dateReplaceDeprecated')
        } else if (
            this._bellIsOrange(
                (event.delimitedDate && event.delimitedDate.endDate) || null,
            )
        ) {
            color = classes.dateAlertOrange
            text = equipment
                ? this.displayText('renewedEquipmentMonth')
                : this.displayText('renewedPartsMonth')
        } else if (
            this._bellIsGreen(
                (event.delimitedDate && event.delimitedDate.endDate) || null,
            )
        ) {
            color = classes.dateAlertGreen
            text = equipment
                ? this.displayText('equipmentInStock')
                : this.displayText('partInStock')
        }

        return (
            <Tooltip
                className={classes.tooltip}
                title={
                    <React.Fragment>
                        <Typography color="inherit">{text}</Typography>
                    </React.Fragment>
                }
            >
                <div>
                    <div className={`${color} ${classes.dateAlert}`}>
                        <img
                            src={bellicon}
                            alt="bellicon"
                            className={classes.bellIcon}
                        ></img>
                    </div>
                </div>
            </Tooltip>
        )
    }

    _bellIsGreen(date) {
        var dateThreeMonth = new Date(
            new Date().setMonth(new Date().getMonth() + 3),
        )

        if (
            date &&
            dateThreeMonth.getTime() < new Date(date).getTime() &&
            new Date().getTime() <= new Date(date).getTime()
        )
            return true
        return false
    }

    _bellIsOrange(date) {
        var dateThreeMonth = new Date(
            new Date().setMonth(new Date().getMonth() + 3),
        )

        if (
            date &&
            dateThreeMonth.getTime() > new Date(date).getTime() &&
            new Date().getTime() <= new Date(date).getTime()
        )
            return true
        return false
    }

    _bellIsRed(date) {
        if (date && date && new Date().getTime() > new Date(date).getTime())
            return true
        return false
    }

    _renderHours(number) {
        const { classes } = this.props

        return (
            <Typography className={classes.date}>
                {number ? number + 'h' : '-'}
            </Typography>
        )
    }

    _renderLine(
        index,
        event,
        { bell = false, tick = false, disableDate = false },
    ) {
        const { classes, loading } = this.props
        const { equipment } = this.state
        const HtmlTooltip = withStyles(theme => ({
            tooltip: {
                backgroundColor: '#3040a0',
                color: 'white',
                maxWidth: 300,
                border: '1px solid #dadde9',
                fontSize: '15px',
            },
        }))(Tooltip)
        var numberOfControle = 1
        if (event.detail?.hourReplacement && event.detail?.hourActual) {
            numberOfControle =
                Math.trunc(
                    event.detail?.hourActual / event.detail?.hourReplacement,
                ) + 1
        }

        return (
            <div
                key={index}
                className={`${classes.rootLine} ${
                    index % 2 !== 0 ? classes.backgroundColorWhite : ''
                }`}
            >
                {(bell && this._renderBell(event)) || <div />}
                <Typography className={classes.div50}>
                    {event.detail.brand || `-`}
                </Typography>
                <Typography className={classes.div50}>
                    {event.detail.model || `-`}
                </Typography>
                {(event.detail &&
                    (event.detail.hourReplacement !== null ||
                        event.detail.hourActual !== null) &&
                    !event.detail.archived &&
                    this._renderHours(event.detail.hourActual)) ||
                    (!disableDate &&
                        this._renderDate(
                            (event.delimitedDate &&
                                event.delimitedDate.startDate) ||
                                null,
                        )) || <div />}
                {(event.detail &&
                    (event.detail.hourReplacement !== null ||
                        event.detail.hourActual !== null) &&
                    !event.detail.archived &&
                    this._renderHours(
                        event.detail.hourReplacement && event.detail.hourActual
                            ? event.detail.hourReplacement * numberOfControle -
                                  event.detail.hourActual
                            : null,
                    )) ||
                    (event.detail.archived &&
                        this._renderDate(
                            event.delimitedDate.endDate ||
                                event.detail.archived,
                        )) ||
                    (!disableDate &&
                        this._renderDate(
                            (event.delimitedDate &&
                                event.delimitedDate.endDate) ||
                                null,
                        )) || <div />}
                <div className={classes.icons}>
                    {tick && (
                        <BoatOnBlock
                            permissions={{
                                action: Actions.Update,
                                entityName: PermissionsEntities.InventoryDetail,
                            }}
                            children={
                                <HtmlTooltip
                                    title={
                                        <>
                                            <Typography color="inherit">
                                                {equipment
                                                    ? this.displayText(
                                                          'replaceEquipment',
                                                      )
                                                    : this.displayText(
                                                          'replacePart',
                                                      )}
                                            </Typography>
                                        </>
                                    }
                                >
                                    <IconButton
                                        disabled={loading > 0}
                                        className={classes.iconButton}
                                        onClick={() => {
                                            if (!event.detail.archived)
                                                this.archive(event)
                                            else {
                                                if (
                                                    window.confirm(
                                                        equipment
                                                            ? this.displayText(
                                                                  'validateUnarchiveEquipment',
                                                              )
                                                            : this.displayText(
                                                                  'validateUnarchivePart',
                                                              ),
                                                    )
                                                )
                                                    this.unarchive(event)
                                            }
                                        }}
                                    >
                                        <CheckCircle
                                            className={
                                                !event.detail.archived
                                                    ? classes.grey
                                                    : classes.basic
                                            }
                                        />
                                    </IconButton>
                                </HtmlTooltip>
                            }
                        />
                    )}
                    <BoatOnBlock
                        permissions={{
                            action: Actions.Update,
                            entityName: PermissionsEntities.InventoryDetail,
                        }}
                        children={
                            <IconButton
                                disabled={loading > 0}
                                className={classes.iconButton}
                                onClick={() => {
                                    this.editStock(event, disableDate)
                                }}
                            >
                                <Edit />
                            </IconButton>
                        }
                    />
                    {(!event.delimitedDate ||
                        (event.detail && event.detail.archived)) && (
                        <BoatOnBlock
                            permissions={{
                                action: Actions.Delete,
                                entityName: PermissionsEntities.InventoryDetail,
                            }}
                            children={
                                <IconButton
                                    disabled={loading > 0}
                                    className={classes.iconButton}
                                    data-id={event.id}
                                    onClick={this.deleteEvent}
                                >
                                    <Delete />
                                </IconButton>
                            }
                        />
                    )}
                </div>
            </div>
        )
    }

    _renderAvailable() {
        const { classes } = this.props
        const { availableDetails, actualDetail } = this.state

        if (availableDetails === null) return <></>

        return (
            <>
                <div className={classes.titleLine}>
                    <Typography className={classes.title}>
                        {`${availableDetails.length}
                        ${getNameElementFromDetail(
                            actualDetail?.detail,
                            this.getContext(),
                        )}
                        ${this.displayText('actualyAvailable')}`}
                    </Typography>
                </div>
                <div className={classes.table}>
                    <div className={classes.headerTable}>
                        <div></div>
                        <Typography className={classes.tableTitle}>
                            {this.displayText('maker')}
                        </Typography>
                        <Typography className={classes.tableTitle}>
                            {this.displayText('model')}
                        </Typography>
                    </div>
                    <div className={classes.scrollableBody}>
                        {availableDetails.map((event, index) =>
                            this._renderLine(index, event, {
                                disableDate: true,
                            }),
                        )}
                    </div>
                </div>
            </>
        )
    }

    _renderFullHeader(archived) {
        const { classes } = this.props
        const { actualDetail } = this.state

        return (
            <div className={classes.headerTable}>
                <div></div>
                <Typography className={classes.tableTitle}>
                    {this.displayText('maker')}
                </Typography>
                <Typography className={classes.tableTitle}>
                    {this.displayText('model')}
                </Typography>
                {((actualDetail?.detail?.hourReplacement !== null ||
                    actualDetail?.detail?.hourActual !== null) &&
                    !archived && (
                        <>
                            <Typography className={classes.tableTitle}>
                                {this.displayText('controlHourLabel')}
                            </Typography>
                            <Typography className={classes.tableTitle}>
                                {this.displayText('replaceHourLabel')}
                            </Typography>
                        </>
                    )) || (
                    <>
                        <Typography className={classes.tableTitle}>
                            {this.displayText('startDate')}
                        </Typography>
                        {(archived && (
                            <Typography className={classes.tableTitle}>
                                {this.displayText('replaced')}
                            </Typography>
                        )) || (
                            <Typography className={classes.tableTitle}>
                                {this.displayText('toReplace')}
                            </Typography>
                        )}
                    </>
                )}
            </div>
        )
    }

    _renderActual() {
        const { classes, boat } = this.props
        const { actualDetail } = this.state

        if (actualDetail === null) return <></>

        return (
            <>
                <div className={classes.titleLine}>
                    <Typography className={classes.title}>
                        {`${getNameElementFromDetail(
                            actualDetail.detail,
                            this.getContext(),
                        )}
                        ${this.displayText('actualyUsed')}
                        ${boat.name}`}
                    </Typography>
                </div>
                <div className={classes.table}>
                    {this._renderFullHeader()}
                    {this._renderLine(0, actualDetail, {
                        bell: true,
                        tick: true,
                    })}
                </div>
            </>
        )
    }

    _renderChanged() {
        const { classes, boat } = this.props
        const { replacedDetails, actualDetail } = this.state

        if (replacedDetails === null) return <></>

        return (
            <>
                <div className={classes.titleLine}>
                    <Typography className={classes.title}>
                        {`${this.displayText('listReplaced1')}
                        ${getNameElementFromDetail(
                            actualDetail?.detail,
                            this.getContext(),
                        )}
                        ${this.displayText('listReplaced2')}
                        ${boat.name}`}
                    </Typography>
                </div>
                <div className={classes.table}>
                    {this._renderFullHeader(true)}
                    {replacedDetails.map((event, index) =>
                        this._renderLine(index, event, { tick: true }),
                    )}
                </div>
            </>
        )
    }

    handleChangeTab(newValue) {
        this.setState({ tabIndex: newValue })
    }

    _actions() {
        const { classes, loading } = this.props

        return [
            {
                label: this.displayText('addStock'),
                action: this.addStock,
                endIcon: <Add className={classes.buttonSvg} />,
                disabled: loading > 0,
            },
        ]
    }

    updateEditingTitle(newTitle, value) {
        if (this._isActualSelectedEngine()) {
            if (!value || typeof value === 'string') {
                this.setState({ editingTitle: newTitle })
            } else {
                this.setState({ editingEngine: value })
            }
        } else {
            this.setState({ editingTitle: newTitle })
        }
    }

    updateIsEditingName(title) {
        this.setState({ isEditingName: !this.state.isEditingName })
        if (!this.state.isEditingName === true && title) {
            this.updateEditingTitle(title)
        }
    }

    _getAlreadyEngineSet() {
        let userEquipments = JSON.parse(
            JSON.stringify(this.props.userEquipments),
        )
        const { inventories } = this.props

        userEquipments = userEquipments?.filter(userEquipment => {
            if (!inventories || !inventories.equipmentFamilies) return false
            let toKeep = false
            Object.entries(inventories.equipmentFamilies).forEach(family => {
                if (family[1] && family[1].equipments) {
                    Object.entries(family[1].equipments).forEach(equipment => {
                        if (
                            !toKeep &&
                            userEquipment.id === parseInt(equipment[0]) &&
                            equipment[1].event
                        )
                            toKeep = true
                    })
                }
            })
            return toKeep
        })
        return userEquipments
    }

    _conditionAlreadySet = alreadySet => option => {
        return alreadySet.some(element => option.id && element.id === option.id)
    }

    getName(option) {
        if (option?.equipmentType)
            return this.displayTextApi(option.equipmentType.translation)
        if (typeof option === 'string') return option
        return ``
    }

    _renderEdition() {
        const { classes } = this.props
        const { editingEngine, editingTitle } = this.state
        const { userEquipments } = this.props

        if (this._isActualSelectedEngine()) {
            return (
                <BoatOnAutoComplete
                    style={{ minWidth: '225px' }}
                    freeSolo
                    value={editingEngine || editingTitle}
                    options={userEquipments.filter(
                        userEquipment =>
                            userEquipment?.id >= 1 && userEquipment?.id <= 3,
                    )}
                    onChange={this.updateEditingTitle}
                    getElementToShow={this.getName}
                    textFieldRef={this.editRef}
                    conditionAlready={this._conditionAlreadySet(
                        this._getAlreadyEngineSet(),
                    )}
                    filterOptions={(option, input) =>
                        !input.inputValue ||
                        this.getName(option)
                            .toLowerCase()
                            .includes(input.inputValue.toLowerCase())
                    }
                    onInputChange={(e, value) => this.updateEditingTitle(value)}
                    onKeyDown={this.keyPress}
                />
            )
        }

        return (
            <TextField
                style={{ minWidth: '225px' }}
                margin="dense"
                required
                variant="outlined"
                value={this.state.editingTitle}
                onChange={e => {
                    this.updateEditingTitle(e.target.value)
                }}
                onKeyDown={this.keyPress}
                ref={this.editRef}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                className={classes.iconButton}
                                onClick={() => this.updateIsEditingName(null)}
                                style={{
                                    marginLeft: `10px`,
                                }}
                            >
                                <Close />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        )
    }

    _renderTitle(title, labels = null) {
        const { handleClose, classes, loading, subscriptions } = this.props
        const { isEditingName } = this.state

        return (
            <DialogTitle
                id={`dialog-labelledby-${title}`}
                style={{
                    paddingBottom: labels ? 0 : 16,
                }}
                disableTypography
                children={
                    <>
                        <div
                            style={{
                                marginRight: `30px`,
                                width: `calc(100% - 30px)`,
                                display: `flex`,
                                flexDirection: `row`,
                            }}
                        >
                            {!isEditingName ? (
                                <>
                                    <Typography variant={`subtitle1`}>
                                        {title || 'Mon inventaire'}
                                    </Typography>
                                    {userActions.checkSubscriptionPage(
                                        subscriptions,
                                        6,
                                    ) && (
                                        <BoatOnBlock
                                            permissions={{
                                                action: Actions.Create,
                                                entityName:
                                                    PermissionsEntities.InventoryDetail,
                                            }}
                                            children={
                                                <IconButton
                                                    disabled={loading > 0}
                                                    className={
                                                        classes.iconButton
                                                    }
                                                    onClick={() =>
                                                        this.updateIsEditingName(
                                                            title,
                                                        )
                                                    }
                                                    style={{
                                                        marginLeft: `10px`,
                                                    }}
                                                >
                                                    <Edit
                                                        style={{
                                                            fontSize: `1.25rem`,
                                                        }}
                                                    />
                                                </IconButton>
                                            }
                                        />
                                    )}
                                </>
                            ) : (
                                this._renderEdition()
                            )}
                        </div>
                        <IconButton
                            size={`small`}
                            id={`closeDialog`}
                            onClick={handleClose}
                            children={<Close />}
                            style={{
                                position: `absolute`,
                                right: `24px`,
                                top: `16px`,
                                color: `#C4C4C4`,
                            }}
                        />
                        {labels && this._renderAppBar(labels)}
                    </>
                }
            ></DialogTitle>
        )
    }

    render() {
        const { title } = this.state
        const { actualDetail } = this.state

        return (
            <>
                {this._renderTitle(title, [
                    this.displayText('titleActual'),
                    this.displayText('inStock'),
                    `${getNameElementFromDetail(
                        actualDetail?.detail,
                        this.getContext(),
                    )} ${this.displayText('titleReplaced')}`,
                ])}
                {this._renderBody({
                    bodies: [
                        this._renderActual(),
                        this._renderAvailable(),
                        this._renderChanged(),
                    ],
                    bodyMinHeigh: `350px`,
                })}
                <BoatOnBlock
                    permissions={{
                        action: Actions.Create,
                        entityName: PermissionsEntities.InventoryDetail,
                    }}
                    children={this._renderActions(this._actions())}
                />
                <BoatOnModal
                    openCondition={this.state.openModal}
                    handleClose={this.handleCloseModal}
                    modalCores={{
                        addStock: (
                            <InventoryModal
                                handleClose={this.handleCloseModal}
                                event={this.state.event}
                                type={this.state.modalType}
                                onlyDetails
                                newElement={this.state.newElement}
                            />
                        ),
                        addNewStock: (
                            <div
                                style={{
                                    padding: 15,
                                    paddingTop: 0,
                                    display: `flex`,
                                    flexDirection: `column`,
                                    alignItems: `center`,
                                    justifyContent: `center`,
                                    textAlign: `center`,
                                }}
                            >
                                <p>
                                    {' '}
                                    {this.displayText('desc1')}
                                    <strong>
                                        {' '}
                                        {getNameElementFromDetail(
                                            this.state.actualDetail?.detail,
                                            this.getContext(),
                                        )}{' '}
                                    </strong>
                                    {this.displayText('desc2')}
                                </p>
                                <div style={{ display: `flex`, gap: 10 }}>
                                    <Button
                                        label={this.displayText('no')}
                                        onClick={this.addDifferentStock}
                                    />
                                    <Button
                                        label={this.displayText('yes')}
                                        onClick={this.addSameStock}
                                    />
                                </div>
                            </div>
                        ),
                        replace: (
                            <InventoryReplacementModal
                                equipment={
                                    this.state.equipment &&
                                    this.state.actualDetail
                                }
                                part={
                                    this.state.part && this.state.actualDetail
                                }
                                replace={this.state.availableDetails}
                                save={this.props.save}
                                saveMenuModalIndex={this.handleChangeTab}
                            />
                        ),
                    }}
                    maxWidth={{
                        addNewStock: `xs`,
                    }}
                    titles={{
                        addNewStock: `Mes stocks`,
                        addStock: this.state.title || 'Mon inventaire',
                        replace: 'Remplacement',
                    }}
                />
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        boat: state.bob.boat,
        events:
            state.bob.events && state.bob.events.filter(event => event.detail),
        loading: state.bob.loading,
        subscriptions: state.group?.groupsMembers?.user?.sub || [],
        userEquipments: state.types.userEquipments,
        inventories: state.bob.inventories,
    }
}

export default connect(mapStateToProps)(
    withStyles(styles)(InventoryHistoryModal),
)
