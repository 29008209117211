import { workTimeConstants } from '../constants/workTime.constants'

const initialState = {
    loading: 0,
    workTimes: null,
    workTimesManager: [],
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case workTimeConstants.POST:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case workTimeConstants.POST_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case workTimeConstants.POST_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case workTimeConstants.GET_WORK_TIMES:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case workTimeConstants.GET_WORK_TIMES_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                workTimes: action.workTimes,
            }
        case workTimeConstants.GET_WORK_TIMES_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                workTimes: [],
            }
        case workTimeConstants.GET_WORK_TIMES_MANAGER:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case workTimeConstants.GET_WORK_TIMES_MANAGER_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                workTimesManager: action.workTimesManager,
            }
        case workTimeConstants.GET_WORK_TIMES_MANAGER_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
                workTimesManager: [],
            }
        case workTimeConstants.UPDATE_WORK_TIME:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case workTimeConstants.UPDATE_WORK_TIME_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case workTimeConstants.UPDATE_WORK_TIME_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case workTimeConstants.DELETE_WORK_TIME:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case workTimeConstants.DELETE_WORK_TIME_SUCCESS:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        case workTimeConstants.DELETE_WORK_TIME_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }

        default:
            return prevState
    }
}
