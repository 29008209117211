export const calendarEventConstants = {
    REQUEST_CALENDAR_EVENT: 'REQUEST_CALENDAR_EVENT',
    CALENDAR_EVENT_SUCCESS: 'CALENDAR_EVENT_SUCCESS',
    CALENDAR_EVENT_FAILURE: 'CALENDAR_EVENT_FAILURE',
    ACCEPT_CALENDAR_EVENT_INVITATION: 'ACCEPT_CALENDAR_EVENT_INVITATION',
    ACCEPT_CALENDAR_EVENT_INVITATION_SUCCESS:
        'ACCEPT_CALENDAR_EVENT_INVITATION_SUCCESS',
    ACCEPT_CALENDAR_EVENT_INVITATION_FAILURE:
        'ACCEPT_CALENDAR_EVENT_INVITATION_FAILURE',
    DECLINE_CALENDAR_EVENT_INVITATION: 'DECLINE_CALENDAR_EVENT_INVITATION',
    DECLINE_CALENDAR_EVENT_INVITATION_SUCCESS:
        'DECLINE_CALENDAR_EVENT_INVITATION_SUCCESS',
    DECLINE_CALENDAR_EVENT_INVITATION_FAILURE:
        'DECLINE_CALENDAR_EVENT_INVITATION_FAILURE',
}
