import { withStyles } from '@material-ui/styles'
import React from 'react'
import styles from './Styles/MyUsersPageCss'
import BoatOnComponent from '../../../common/BoatOnComponent'
import dictionary from './Dictionary/MyUsersPageDictionary'
import Typography from '@material-ui/core/Typography'
import { Button } from '../../../common'
import { TextField } from '@material-ui/core'

class ChangeTitleModal extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.state = {
            changeName: ``,
        }

        this.dictionary = dictionary
        this._modifName = this._modifName.bind(this)
        this._submitModifName = this._submitModifName.bind(this)
    }

    componentDidMount() {
        this.setState({ changeName: this.props.groupName })
    }

    _modifName({ target: { value } }) {
        this.setState({ changeName: value })
    }

    _submitModifName() {
        this.props.submitModifName(this.state.changeName)
    }

    render() {
        const { classes } = this.props

        return (
            <div className={classes.changeTitle}>
                <Typography className={classes.changeTitleText}>
                    {' '}
                    {this.displayText(`newName`)}{' '}
                </Typography>
                <div className={classes.noNameSub}>
                    <TextField
                        label={this.displayText(`name`)}
                        onChange={this._modifName}
                        className={classes.changeTitleField}
                        value={this.state.changeName}
                        variant="outlined"
                        size="small"
                        type="text"
                    />
                    <Button
                        disabled={this.state.changeName === ``}
                        classNameOverride={classes.submitName}
                        label={this.displayText(`validerNom`)}
                        onClick={this._submitModifName}
                    />
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(ChangeTitleModal)
