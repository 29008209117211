export default {
    contactYourAdmin: {
        FR: `Veuillez contacter votre administrateur de flotte pour obtenir l'accès aux pages dont vous avez besoin.`,
        EN:
            'Please contact your fleet administrator to get access to the pages you need.',
    },
    noAccess: {
        FR: `Vous n'avez accès à aucune page du BoatOn Book`,
        EN: 'You do not have access to any page of the BoatOn Book',
    },
    understand: {
        FR: `J'ai compris`,
        EN: 'I understand',
    },
}
