export default theme => ({
    root: {
        width: '50%',
        margin: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        paddingTop: 50,
    },
    paper: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: theme.spacing(60),
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4),
        outline: 'none',
    },
    buttonCreateAccount: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        fontSize: '16px',
        paddingLeft: 5,
        color: '#3040a0',
    },
    newAccount: {
        display: 'flex',
        flexDirection: 'row',
        margin: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
    },
    forget: {
        textDecoration: 'none',
        fontSize: '15px',
        color: '#3040a0',
        textAlign: 'center',
        marginTop: '20px',
    },
    button: theme.button.primary,
})
