import React from 'react'
import { connect } from 'react-redux'
import { boatActions } from '../../../actions/boat.actions'
import InputLabel from './InputLabel'

import BoatOnComponent from '../BoatOnComponent'
import BoatOnAutoComplete from '../BoatOnAutoComplete'

class BoatOnBoatSelector extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.state = {
            boat: this.props.value || null,
            boats: this.props.values || [],
            openModal: false,
        }

        this.onChangeBoat = this.onChangeBoat.bind(this)
        this.onChangeBoats = this.onChangeBoats.bind(this)
    }

    componentDidMount() {
        const { dispatch, linkedBoats, value, values } = this.props

        if (!linkedBoats) {
            dispatch(boatActions.requestLinkedBoats())
        }

        if (value) this.setState({ boat: value })
        if (values) this.setState({ boats: values })
    }

    onChangeBoat(_, value) {
        this.setState(
            {
                boat: value?.id || null,
            },
            () => (this.props.onChange ? this.props.onChange(value) : null),
        )
    }

    onChangeBoats(values) {
        this.setState(
            {
                boats: (values || []).map(v => v.id),
            },
            () => (this.props.onChange ? this.props.onChange(values) : null),
        )
    }

    getGroupNameById(id) {
        const { linkedGroups } = this.props

        return linkedGroups?.find(group => group.id === id)?.name || ''
    }

    render() {
        const { linkedBoats, groupId, multiple = false } = this.props
        const { boat, boats, openModal } = this.state
        const autoCompleteProps = { ...this.props }
        const currentBoat =
            linkedBoats?.find(linkedBoat => linkedBoat.id === boat) || null

        let options = linkedBoats

        if (groupId && linkedBoats) {
            options = linkedBoats.filter(boat => boat.userGroupId === groupId)
        }

        //remove unused item
        delete autoCompleteProps.dispatch
        delete autoCompleteProps.value
        delete autoCompleteProps.linkedBoats
        delete autoCompleteProps.linkedGroups
        delete autoCompleteProps.onChange
        delete autoCompleteProps.groupId
        delete autoCompleteProps.value

        if (multiple) {
            const currentBoats = linkedBoats?.filter(linkedBoat => {
                return boats.includes(linkedBoat.id)
            })

            return (
                <div className={autoCompleteProps.class}>
                    <InputLabel
                        boats={options}
                        onTagsChange={this.onChangeBoats}
                        modalOpen={openModal}
                        handleOpenModal={this.handleOpenModal}
                        selected={currentBoats}
                        {...autoCompleteProps}
                    />
                </div>
            )
        }

        return (
            <BoatOnAutoComplete
                autoComplete
                value={currentBoat}
                getOptionSelected={(opt, value) => opt.id === value?.id}
                options={options || []}
                placeholder={this.displayText('boatName')}
                onChange={this.onChangeBoat}
                getElementToShow={option => option.name}
                groupBy={
                    !groupId
                        ? option =>
                              linkedBoats && linkedBoats.length > 1
                                  ? this.getGroupNameById(option.userGroupId)
                                  : null
                        : undefined
                }
                {...autoCompleteProps}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        linkedBoats: state.boat?.linkedBoats,
        linkedGroups: state.group?.linkedGroups,
    }
}

/**
 * Selector on all linked boats to current user
 * This component is extract from Autocomplete component from MUI
 *
 * @class BoatOnBoatSelector
 * @extends {BoatOnBoatSelector}
 *
 * @region ```
 * Globals Params
 * ```
 *
 * @param {OnChange} onChange - called each time value change.
 * @param {String} value - id of the selected boat.
 *
 * @region ```
 * Optional params
 * ```
 *
 *
 * @param {Boolean} error - if true, change style to indicate error.
 * @param {String} groupId - if exsit, filter by groupId
 *
 *
 * @region ```
 * Callbacks
 * ```
 *
 * @callback OnChange
 * @param {Object} value - value selected.
 *
 *
 * @region ```
 * Documentation infos
 * ```
 *
 * @date 30/04/2024 - 15:19
 * @author Samuel.C
 */
export default connect(mapStateToProps)(BoatOnBoatSelector)
