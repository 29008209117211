export default {
    follow: {
        FR: 'Suivre',
        EN: 'Follow',
    },
    tooltipFollow1: {
        FR:
            'Cliquez ici pour recevoir des notifications lors de nouvelles activités sur cette tâche',
        EN:
            'Click here to receive notifications of new activities on this task.',
    },
    tooltipFollow2: {
        FR:
            '(ou revenu ou dépense ou temps de travail ou absence selon où est la modale)',
        EN:
            '(or income or expenses or work time or absence depending on where the modal is)',
    },
}
