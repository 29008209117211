import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import dictionary from './Dictionary/ModalPasswordDico'
import styles from './Styles/ModalBoatsCss'
import BoatOnModalCore from '../../BoatOnModalCore'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import LockIcon from '@material-ui/icons/Lock'
import { userActions } from '../../../../actions'

class ModalPassword extends BoatOnModalCore {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            lastPassword: '',
            newPassword: '',
            checkNewPassword: '',
            focused: 'lastPassword',
        }

        this.valid = this.valid.bind(this)
    }

    valid(e) {
        const { lastPassword, newPassword, checkNewPassword } = this.state
        const language = this.context
        e.preventDefault()
        if (
            newPassword &&
            checkNewPassword &&
            newPassword === checkNewPassword &&
            lastPassword &&
            lastPassword.length >= 6 &&
            newPassword.length >= 6 &&
            newPassword !== lastPassword
        ) {
            this.props.dispatch(
                userActions.udpatePassword(
                    lastPassword,
                    newPassword,
                    language,
                    () => {
                        this.props.closeModal()
                    },
                ),
            )
        } else {
            this.setState({ focused: null })
        }
    }

    handleChange(value, key) {
        this.setState({ focused: key, [key]: value })
    }

    _body() {
        return (
            <form
                id="form-password"
                name="updatePassword"
                onSubmit={this.valid}
            >
                <TextField
                    id="password"
                    label={this.displayText('lastPassword')}
                    margin="normal"
                    type="password"
                    required
                    variant="outlined"
                    placeholder="**********"
                    error={
                        this.state.lastPassword.length < 6 &&
                        this.state.lastPassword !== '' &&
                        this.state.focused !== 'lastPassword'
                    }
                    helperText={
                        this.state.lastPassword.length < 6 &&
                        this.state.lastPassword !== '' &&
                        this.state.focused !== 'lastPassword'
                            ? this.displayText('nbCharacterPassword')
                            : ''
                    }
                    value={this.state.lastPassword}
                    onChange={e => {
                        this.handleChange(e.target.value, 'lastPassword')
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    id="password"
                    label={this.displayText('newPassword')}
                    margin="normal"
                    type="password"
                    required
                    variant="outlined"
                    placeholder="**********"
                    error={
                        (this.state.newPassword.length < 6 &&
                            this.state.newPassword !== '' &&
                            this.state.focused !== 'newPassword') ||
                        (this.state.newPassword.length >= 6 &&
                            this.state.newPassword ===
                                this.state.lastPassword &&
                            this.state.focused !== 'newPassword')
                    }
                    helperText={
                        this.state.newPassword.length < 6 &&
                        this.state.newPassword !== '' &&
                        this.state.focused !== 'newPassword'
                            ? this.displayText('nbCharacterPassword')
                            : this.state.newPassword.length >= 6 &&
                              this.state.newPassword ===
                                  this.state.lastPassword &&
                              this.state.focused !== 'newPassword'
                            ? this.displayText('samePassword')
                            : ''
                    }
                    value={this.state.newPassword}
                    onChange={e => {
                        this.handleChange(e.target.value, 'newPassword')
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                <TextField
                    id="password"
                    label={this.displayText('repeatPassword')}
                    margin="normal"
                    type="password"
                    required
                    variant="outlined"
                    placeholder="**********"
                    error={
                        this.state.newPassword !==
                            this.state.checkNewPassword &&
                        this.state.checkNewPassword !== '' &&
                        this.state.focused !== 'checkNewPassword'
                    }
                    helperText={
                        this.state.newPassword !==
                            this.state.checkNewPassword &&
                        this.state.checkNewPassword !== '' &&
                        this.state.focused !== 'checkNewPassword'
                            ? this.displayText('diffPassword')
                            : ''
                    }
                    value={this.state.checkNewPassword}
                    onChange={e => {
                        this.handleChange(e.target.value, 'checkNewPassword')
                    }}
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LockIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </form>
        )
    }

    render() {
        const { title } = this.props

        return (
            <>
                {this._renderTitle(title)}
                {this._renderBody({
                    body: this._body(),
                })}
                {this._renderActions([
                    {
                        label: this.displayText('submit'),
                        form: `form-password`,
                    },
                ])}
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        state: state.open,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ModalPassword))
