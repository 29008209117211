export default theme => ({
    container: {
        width: `calc(100vw - 100px)`,
        display: `flex`,
        justifyContent: `center`,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    subContainer: {
        marginTop: 20,
        border: `1px solid #E9EFF2`,
        overflow: `hidden`,
        width: `min-content`,
        minWidth: '75%',
        maxWidth: 1600,
        minHeight: 500,
        paddingBottom: 30,
        borderRadius: 5,
        boxShadow: `0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)`,
        [theme.breakpoints.down(`sm`)]: {
            border: `none`,
            boxShadow: `none`,
            paddingInline: 0,
        },
        paddingInline: 60,
    },
    header: {
        display: `flex`,
        height: `fit-content`,
        paddingLeft: 24,
        paddingTop: 24,
        paddingRight: 24,
        paddingBottom: 32,
        justifyContent: `space-between`,
        width: `100%`,
        boxSizing: `border-box`,
        [theme.breakpoints.down(`sm`)]: {
            padding: 20,
        },
    },
    title: {
        fontWeight: `bold`,
        fontSize: `24px`,
        [theme.breakpoints.down(`sm`)]: {
            fontSize: `16px`,
        },
    },
    alert: {
        margin: 0,
    },
    body: {
        padding: '0 24px',
        display: 'flex',
        flexDirection: 'column',
        gap: 20,
        [theme.breakpoints.down(`sm`)]: {
            padding: '0 20px',
        },
    },
    row: {
        display: `flex`,
        justifyContent: `flex-start`,
        alignItems: `center`,
        boxSizing: `border-box`,
        marginBottom: 24,
        [theme.breakpoints.down(`sm`)]: {
            flexDirection: `column`,
            alignItems: `flex-start`,
            marginBottom: '0px !important',
        },
    },
    inline: {
        display: `flex`,
        justifyContent: `flex-start`,
        alignItems: `center`,
        width: `100%`,
        boxSizing: `border-box`,
        [theme.breakpoints.down(`sm`)]: {
            flexFlow: `wrap row`,
        },
    },
    day: {
        minWidth: 150,
        display: `flex`,
        alignItems: `center`,
        [theme.breakpoints.down(`sm`)]: {
            minWidth: '0px !important',
            marginRight: 10,
        },
        [theme.breakpoints.down(`md`)]: {
            minWidth: 110,
        },
    },
    textButton: {
        fontSize: 14,
        alignSelf: 'center',
        minWidth: 200,
        [theme.breakpoints.down(`sm`)]: {
            minWidth: 160,
            margin: '10px 0px !important',
            paddingLeft: 0,
        },
        [theme.breakpoints.down(`md`)]: {
            minWidth: 160,
            margin: '10px 5px',
        },
    },
    breaks: {
        display: `flex`,
        flexDirection: `column`,
        gap: 8,
        alignItems: `center`,
        justifyContent: `space-between`,
        boxSizing: `border-box`,
    },
    emptyDiv: {
        minWidth: 200,
    },
    daySetting: {
        display: `flex`,
        alignSelf: `flex-start`,
        [theme.breakpoints.down(`sm`)]: {
            width: `100%`,
        },
    },
    disabled: {
        opacity: 0.3,
    },
    primaryColor: {
        color: `#3040a0 !important`,
    },
    section: {
        padding: `20px 0px`,
        [theme.breakpoints.down(`sm`)]: {
            padding: 0,
        },
        width: 'fit-content',
    },
    footer: {
        marginTop: 30,
        display: `flex`,
        justifyContent: `flex-end`,
        [theme.breakpoints.down(`sm`)]: {
            justifyContent: `center`,
            marginTop: 40,
        },
    },
    dayTextField: {
        minWidth: 120,
        [theme.breakpoints.down(`md`)]: {
            minWidth: 110,
        },
        [theme.breakpoints.down(`sm`)]: {
            minWidth: 100,
        },
        '& > div': {
            height: 40,
        },
    },
    breakTextField: {
        minWidth: 170,
        [theme.breakpoints.down(`sm`)]: {
            width: `100%`,
            maxWidth: 170,
        },
        [theme.breakpoints.down(`md`)]: {
            minWidth: 120,
        },
        '& > div': {
            height: 40,
        },
    },
    break: {
        display: `flex`,
        alignItems: `center`,
        [theme.breakpoints.down(`sm`)]: {
            width: `100%`,
        },
    },
    inlineBreak: {
        display: `flex`,
        justifyContent: `flex-start`,
        alignItems: `center`,
        boxSizing: `border-box`,
        gap: 10,
        width: `350px`,
        [theme.breakpoints.down(`md`)]: {
            width: `100%`,
            maxWidth: 250,
        },
    },
    inlineDay: {
        display: `flex`,
        justifyContent: `flex-start`,
        alignItems: `center`,
        boxSizing: `border-box`,
        gap: 10,
        width: `260px`,
        [theme.breakpoints.down(`md`)]: {
            width: `240px`,
        },
    },
    mobileDivider: {
        display: `none`,
        [theme.breakpoints.down(`sm`)]: {
            display: `block`,
            width: `50%`,
            margin: `30px auto`,
        },
    },
    desktopDivider: {
        display: `block`,
        [theme.breakpoints.down(`sm`)]: {
            display: `none`,
        },
    },
    mobileSpace: {
        [theme.breakpoints.down(`sm`)]: {
            marginTop: 15,
        },
    },
    button: {
        width: 150,
    },
    switchRoot: {
        [theme.breakpoints.down(`sm`)]: {
            marginLeft: '-10px',
        },
    },

    selectContainer: {
        display: 'flex',
        alignItems: 'center',
        flex: 1,
    },
    select: {
        width: '33%',
        [theme.breakpoints.down(`sm`)]: {
            width: '100%',
        },
    },
    selectInput: {
        display: 'flex !important',
        gap: 10,
    },
    selectItemRoot: {
        display: 'flex',
        flexDirection: 'row',
        '&:hover': {
            background: '#E5E7F0',
        },
    },
    selectItemGroupName: {
        fontSize: 14,
        fontWeight: 500,
        flex: 1,
    },
    selectBadgeContainer: {
        display: 'flex',
        flexDirection: 'row',
        flex: 1,
        gap: 5,
    },
    selectBadge: {
        width: 20,
        height: 20,
        background: '#E5E7F0',
        border: 'solid white 1px',
        borderRadius: 20,
        color: '#303F9F',
        marginLeft: -7,
        fontSize: 10,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontWeight: 900,
        overflow: 'hidden',
    },
    badgesExtra: {
        fontWeight: 500,
        fontSize: 12,
    },
    userBadgeIcon: {
        marginTop: '5px !important',
        width: '20px !important',
        height: '20px !important',
    },
    editGroupIcon: {
        height: '20px !important',
        width: '20px !important',
        color: '#212121',
    },
    disabledIcon: {
        color: '#C1C1C1 !important',
    },
    pageLoadingLabel: {
        fontWeight: 500,
    },
    content: {
        width: 'fit-content',
    },
})
