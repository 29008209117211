import React from 'react'
import { connect } from 'react-redux'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import ReactPixel from 'react-facebook-pixel'
import ModalRenderSignIn from './ModalRenderSignIn'
import ModalRenderConnection from './ModalRenderConnection'
import dictionary from './Dictionary/ModalConnectionDico'
import styles from './Styles/ModalConnectionCss'
import BoatOnComponent from '../../BoatOnComponent'
import BoatOnModal from '../../BoatOnModal'
import BoatOnModalCore from '../../BoatOnModalCore'

class ModalConnection extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary

        this.state = {
            openModal: false,
        }

        const options = {
            autoConfig: true,
            debug: false,
        }
        ReactPixel.init('181677145678471', options)

        this.handleConnection = this.handleConnection.bind(this)
        this.handleSignIn = this.handleSignIn.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }

    componentDidUpdate() {
        if (
            this.props.open &&
            !this.state.openModal &&
            this.props.onlyConnection
        ) {
            this.setState({ openModal: 'connection' })
        } else if (this.props.open && !this.state.openModal) {
            this.setState({ openModal: 'selection' })
        } else if (!this.props.open && this.state.openModal) {
            this.handleClose()
        }
    }

    handleConnection() {
        ReactPixel.track('Connection from BoatOn Book Popup')
        this.setState({ openModal: 'connection' })
    }

    handleSignIn() {
        ReactPixel.track('Signin from BoatOn Book Popup')
        this.setState({ openModal: 'signin' })
    }

    handleClose(e) {
        this.setState({ openModal: false })
        this.props.handleClose(e)
    }

    renderPreChoice() {
        return (
            <>
                <Typography variant={`body1`}>
                    {this.displayText('accountNeeded')}
                </Typography>
            </>
        )
    }

    render() {
        const { saveUpdateSubscription, goToRentParking } = this.props
        return (
            <BoatOnModal
                openCondition={this.state.openModal}
                handleClose={this.handleClose}
                maxWidth={{ selection: `sm`, connection: `sm`, signin: `sm` }}
                modalCores={{
                    selection: (
                        <BoatOnModalCore
                            body={this.renderPreChoice()}
                            actions={[
                                {
                                    label: this.displayText('connection'),
                                    action: this.handleConnection,
                                    minWidth: `120px`,
                                },
                                {
                                    label: this.displayText('createAccount'),
                                    action: this.handleSignIn,
                                    minWidth: `120px`,
                                },
                            ]}
                            noCross={true}
                        />
                    ),
                    connection: (
                        <ModalRenderConnection
                            saveUpdateSubscription={saveUpdateSubscription}
                            handleClose={this.handleClose}
                        />
                    ),
                    signin: (
                        <ModalRenderSignIn
                            saveUpdateSubscription={saveUpdateSubscription}
                            handleClose={this.handleClose}
                            goToRentParking={goToRentParking}
                        />
                    ),
                }}
                titles={{
                    selection: this.displayText('selection'),
                }}
                disableFullscreen
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        alert: state.alert,
        state: state.open,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(ModalConnection))
