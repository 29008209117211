import { commentsConstants } from '../constants'

const initialState = {
    comments: [],
    loading: 0,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case commentsConstants.SEND_COMMENT:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case commentsConstants.REQUEST_COMMENTS:
            return {
                ...prevState,
                loading: prevState.loading + 1,
            }
        case commentsConstants.COMMENT_SUCCESS:
            return {
                ...prevState,
                comments: action.comments,
                loading: 0,
            }
        case commentsConstants.COMMENT_FAILURE:
            return {
                ...prevState,
                loading: prevState.loading - 1,
            }
        default:
            return prevState
    }
}
