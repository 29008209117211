import { activityConstants } from '../constants'

const initialState = {
    activity: [],
    loading: 0,
}

export default function(prevState = initialState, action) {
    switch (action.type) {
        case activityConstants.REQUEST_ACTIVITY:
            return {
                ...prevState,
                activity: [],
                loading: prevState.loading + 1,
            }
        case activityConstants.ACTIVITY_SUCCESS:
            return {
                ...prevState,
                activity: action.activity,
                loading: prevState.loading - 1,
            }
        case activityConstants.ACTIVITY_FAILURE:
            return {
                ...prevState,
                activity: [],
                loading: prevState.loading - 1,
            }
        default:
            return prevState
    }
}
