export default {
    email: {
        FR: 'E-mail',
        EN: 'E-mail',
    },
    sms: {
        FR: 'SMS',
        EN: 'SMS',
    },
    link: {
        FR: 'Lien',
        EN: 'link',
    },
    copyLink: {
        FR: 'Copier le lien',
        EN: 'Copy the link',
    },

    mailSubject: {
        FR: 'Voici ma navigation !',
        EN: 'Look at my travel !',
    },
    mailBody: {
        FR:
            'vous partage sa dernière navigation ! Cliquez ici pour la voir :\n',
        EN: 'share you his last travel ! Click here to see it:\n',
    },

    smsBody: {
        FR:
            'vous partage sa dernière navigation ! Cliquez ici pour la voir :\n',
        EN: 'share you his last travel ! Click here to see it: \n',
    },

    whatsappBody: {
        FR:
            'vous partage sa dernière navigation ! Cliquez ici pour la voir :\n',
        EN: 'share you his last travel ! Click here to see it: \n',
    },

    xBody: {
        FR:
            'vous partage sa dernière navigation ! Cliquez ici pour la voir :\n',
        EN: 'share you his last travel ! Click here to see it: \n',
    },
}
