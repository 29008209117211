import { POST_DATA_SUCCESS, POST_DATA_ERROR } from '../actions/api.actions'

const initialState = {
    datas: [],
    error: [],
}

export default function postDatasReducer(prevState = initialState, action) {
    switch (action.type) {
        case POST_DATA_SUCCESS:
            return {
                ...prevState,
                datas: action.payload,
            }
        case POST_DATA_ERROR:
            return {
                ...prevState,
                error: action.error,
            }
        default:
            return prevState
    }
}
