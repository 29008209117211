export default theme => ({
    root: Object.assign(
        {
            margin: `10px`,
            height: 'fit-content',
            position: `sticky`,
            top: 20,
            right: 0,
            marginTop: `-10px`,
        },
        theme.boxBoatOn,
        {
            width: `250px`,
            padding: 0,
            background: `white`,
            [theme.breakpoints.down(`sm`)]: {
                width: 'auto',
                margin: 20,
                height: 'auto',
                position: 'relative',
            },
        },
    ),
    rootProfile: Object.assign(
        {
            margin: `10px`,
            height: `100%`,
            position: `sticky`,
            top: 0,
            right: 0,
            marginTop: `-10px`,
        },
        theme.boxBoatOn,
        {
            width: `250px`,
            padding: 0,
            background: `white`,
            [theme.breakpoints.down(`sm`)]: {
                width: `100%`,
                border: 0,
                top: 10,
                position: `relative`,
                zIndex: `3`,
                margin: 0,
                marginBottom: `10px`,
            },
        },
    ),
    title: {
        marginBottom: `10px`,
        position: `relative`,
        transition: `0.4s`,
        filter: `brightness(100%)`,
        '&:hover': {
            cursor: `pointer`,
            filter: `brightness(50%)`,
        },
    },
    text: {
        textAlign: `left`,
        width: `90%`,
        padding: `10px`,
        fontSize: `18px`,
        marginLeft: `1%`,
        backgroundColor: `white`,
        zIndex: `2`,
        [theme.breakpoints.down(`sm`)]: {
            display: `none`,
        },
    },
    name: {
        padding: `10px`,
        width: `calc(100% - 20px)`,
        marginBottom: `2%`,
        [theme.breakpoints.down(`sm`)]: {
            marginBottom: `1%`,
            marginTop: `20px`,
        },
    },
    iconBoat: {
        width: `100%`,
        height: `150px`,
        position: `relative`,
        backgroundColor: `#3040a01c`,
        marginBottom: `10px`,
    },
    iconBoat2: {
        position: `absolute`,
        width: `25%`,
        top: `5%`,
        left: `37%`,
    },
    info: {
        color: `#303f9f`,
        fontWeight: `bold`,
    },
    image: {
        maxWidth: `100%`,
        maxHeight: `100%`,
        margin: `auto`,
        position: `absolute`,
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
    },
    addPicture: {
        zIndex: 1,
        color: `black`,
        right: `10px`,
        top: `8px`,
        position: `absolute`,
    },
    block: {
        padding: `10px`,
        boxSizing: `border-box`,
    },
    flexRow: {
        display: `flex`,
        flexDirection: `row`,
        cursor: `pointer`,
        width: `100%`,
        paddingTop: `10px`,
        color: `#849299`,
        fontWeight: `300`,
        fontSize: `14px`,
        alignItems: `center`,
        marginTop: `-8px`,
    },
    plus: {
        marginLeft: `-8px`,
        marginRight: `8px`,
    },
    maintenance: {
        cursor: 'pointer',
        '& .MuiTypography-body1': {
            '&:hover': {
                textDecoration: 'underline',
            },
        },
    },
})
