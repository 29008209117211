export default theme => ({
    drawerBox: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minWidth: '25%',
    },
    drawerPhone: {
        backgroundColor: '#3040a0',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    drawerHeader: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1,
        gap: 20,
        backgroundColor: '#F2F4FE',
        padding: '20px 10px',
        paddingBottom: 0,
    },
    userContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    drawerMain: {
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'white',
        padding: '0px 10px',
        overflowY: 'auto',
        overflowX: 'hidden',
        flex: 2,
    },
    listHamburger: {
        padding: 0,
    },
    noUnderline: {
        textDecoration: 'none',
        color: 'inherit',
    },
    listHamburgerItem: {
        padding: `13px 10px`,
        color: 'black',
        fontSize: 14,
    },
    disabled: {
        opacity: 0.3,
    },
    profileMenuItem: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    profilePicture: {
        height: 50,
        width: 50,
        border: '2px solid #303F9F',
        borderRadius: '100%',
        color: '#303F9F',
        alignSelf: 'center',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.2em',
        fontWeight: 500,
        overflow: 'hidden',
    },
    username: {
        fontWeight: 500,
        color: 'black',
    },
    disconnect: {
        background: 'white',
        borderRadius: 5,
        padding: '2px 20px',
        margin: 10,
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
    },
    disconnectIcon: {
        marginRight: 10,
        fontSize: 18,
    },
    localizedSwitchSelect: {
        padding: `13px 0px`,
        fontSize: 14,
    },
    navLink: {
        textDecorationLine: 'none',
        padding: '5px 0px',
        color: 'inherit',
        textAlign: 'center',
        fontSize: '14px',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
    },
    footer: {
        display: 'flex',
        filter: 'drop-shadow(0px -1px 4px rgba(0, 0, 0, 0.25))',
        background: 'white',
        justifyContent: 'center',
    },
    icon: {
        minWidth: 30,
        // padding: `8px 0`,
        // display: `flex`,
        // alignItems: `center`,
        // justifyContent: `center`,
        // [theme.breakpoints.up('md')]: {
        //     minWidth: 72,
        // },
    },
    iconMenu: {
        width: '18px !important',
        color: 'black',
        // color: '#fff',
        // objectFit: 'contain',
        // width: '30px',
    },
})
