export default {
    alerts: {
        FR: 'Mes alertes',
        EN: 'My Alerts',
    },
    bosco: {
        FR: `Passez au mode Bosco pour y avoir accès !`,
        EN: `Switch to Captain mode to gain access !`,
    },
    skipper: {
        FR: `Passez au mode Skipper pour y avoir accès !`,
        EN: `Switch to Skipper mode to gain access !`,
    },
    amiral: {
        FR: `Passez au mode Amiral pour y avoir accès !`,
        EN: `Switch to Amiral mode to gain access !`,
    },
    commander: {
        FR: `Passez au mode Commandant pour y avoir accès !`,
        EN: `Switch to Commander mode to gain access !`,
    },
    captain: {
        FR: `Passez au mode Capitaine pour y avoir accès !`,
        EN: `Switch to Captain mode to gain access !`,
    },
    myUsers: {
        FR: `Utilisateurs`,
        EN: `Users`,
    },
    inventory: {
        FR: 'Inventaire',
        EN: 'Inventory',
    },
    calendar: {
        FR: 'Calendrier',
        EN: 'Agenda',
    },
    workTime: {
        FR: 'Travail et absences',
        EN: 'Work and leave',
    },
    checkup: {
        FR: 'Tâches',
        EN: 'Tasks',
    },
    expense: {
        FR: 'Budget',
        EN: 'Budget',
    },
    documents: {
        FR: 'Documents',
        EN: 'Documents',
    },
    boat: {
        FR: 'Bateau',
        EN: 'Boat',
    },
    fleet: {
        FR: 'Flotte & statistiques',
        EN: 'Fleet & statistics',
    },
    fleetNoShow: {
        FR:
            'Vous devez avoir plusieurs bateaux pour avoir accès à la page ma flotte',
        EN: 'You must have several boats to access the my fleet page',
    },
    fleetNoShowMultiBoat: {
        FR: `Vous devez avoir l'abonnement au multi bateaux pour avoir accès à la page ma flotte`,
        EN: `You must have the multiboat subscription to access the my fleet page`,
    },
    cta: {
        FR: `Cette fonctionnalité n'est pas incluse dans votre abonnement.`,
        EN: `This functionnality isn't include in your subscription.`,
    },
    nextLevel: {
        FR: `Passez à la vitesse supérieure !`,
        EN: `Take it to the next level !`,
    },
    settings: {
        FR: 'Paramètres',
        EN: 'Settings',
    },
    paidLeaves: {
        FR: 'Congés payés',
        EN: 'Paid leaves',
    },
    logBook: {
        FR: 'Journal de bord',
        EN: 'Log book',
    },
    subscribeV2: {
        FR: 'Mettez à jour votre abonnement pour en profiter !',
        EN: 'Update your subscription to take advantage!',
    },
    orders: {
        FR: 'Commandes',
        EN: 'Orders',
    },
}
