export default theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'calc(100vh - 88px - 96px - 64px)', //size of screen - header - footer - padding top in mobile
        },
    },
    iosRoot: {
        [theme.breakpoints.down('sm')]: {
            maxHeight: 'calc(100vh - 88px - 96px - 64px - 82px)', //size of screen - header - footer - padding top - footer safari in mobile
        },
    },
    title: {
        fontSize: 14,
        marginLeft: 20,
    },

    stockWrapper: {
        maxHeight: '70vh',
        overflowY: 'auto',
        padding: '0 20px 20px 20px',
        display: 'flex',
        flexDirection: 'column',
        gap: 25,
    },
    stockContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    stockVerticalContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    stockRowContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
        gap: 15,
    },
    stockInfoContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        flex: 1,
    },
    stockIconWrapper: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 20,
        height: 35,
        width: 35,
    },
    stockIcon: {
        height: 25,
        width: 25,
        filter: 'invert(1)',
    },
    stockInput: {
        width: '60%',
    },
    stockInfo: {
        fontSize: 14,
        fontWeight: 200,
        color: 'grey',
    },

    noCapacity: {
        '&:hover': {
            cursor: 'help',
        },
    },
})
