import React from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core/styles'
import TopInventory from './TopInventory'
import BoatOnComponent from '../../../common/BoatOnComponent'
import dictionary from './Dictionary/InventoryListDico'
import styles from './Styles/InventoryPageCss'
import InventoryBar from './InventoryBar'
import {
    removeAllDetailsFromAnEvent,
    getDetailsExcel,
    getDetailsEmail,
} from '../../../../actions/bob.actions'
import { history } from '../../../../helpers'
import AppRoute from '../../../../constants/AppRoute'
import catamaranicon from '../../../../images/catamaranicon.svg'
import ModalNoBoat from '../../../common/UsefullComponents/ModalConnection/ModalNoBoat'
import BoatOnModal from '../../../common/BoatOnModal'
import BoatOnAlert from '../../../common/UsefullComponents/BoatOnAlert'

class InventoryPage extends BoatOnComponent {
    constructor(props) {
        super(props)
        this.dictionary = dictionary
        this.state = {
            checkedBoxes: [],
            openModalNoBoat: false,
            selectedModal: false,
        }

        this.handleCheckBox = this.handleCheckBox.bind(this)
        this.handleSelectAllCheckBox = this.handleSelectAllCheckBox.bind(this)
        this.deleteSelected = this.deleteSelected.bind(this)
        this.downloadExcel = this.downloadExcel.bind(this)
        this.sendEmail = this.sendEmail.bind(this)
        this.openModal = this.openModal.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.downloadOrder = this.downloadOrder.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    downloadExcel() {
        const { checkedBoxes } = this.state

        this.setState({
            selectedModal: 'startDownloadModal',
        })

        getDetailsExcel(checkedBoxes, this.context)
    }

    async sendEmail() {
        const { checkedBoxes } = this.state
        const { user } = this.props
        const sub = this.displayText('sub')
        const details = await getDetailsEmail(checkedBoxes, this.context)
        const body =
            this.displayText('mailPart1') +
            details.email +
            this.displayText('mailPart2') +
            this.displayText('mailPart3') +
            user.lastName +
            ' ' +
            user.firstName

        window.location.href =
            'mailto:?subject=' + sub + '&body=' + encodeURIComponent(body) + ''
        //TODO: appeler le générateur en back, grace à l'event on peut récupérer le détails et aussi la piece ou l'équipement
    }

    downloadOrder() {
        const { checkedBoxes } = this.state
        this.historyPush(history, AppRoute.LogBook.OrderForm, '', {
            selectedEvents: checkedBoxes,
        })
    }

    handleCheckBox(eventId, event) {
        if (!event.target.checked) {
            this.setState(prevState => ({
                checkedBoxes: prevState.checkedBoxes.filter(
                    boxID => boxID !== eventId,
                ),
            }))
        } else {
            this.setState(prevState => ({
                checkedBoxes: prevState.checkedBoxes.concat([eventId]),
            }))
        }
    }

    handleSelectAllCheckBox(event, arrayIds) {
        if (event) {
            this.setState(() => ({
                checkedBoxes: arrayIds,
            }))
        } else {
            this.setState(() => ({
                checkedBoxes: [],
            }))
        }
    }

    async deleteSelected() {
        const { checkedBoxes } = this.state
        for (const checkedBoxe of checkedBoxes) {
            await this.props.dispatch(removeAllDetailsFromAnEvent(checkedBoxe))
        }
        this.setState({ checkedBoxes: [] })
    }

    handleClose() {
        this.setState({ openModalNoBoat: false })
    }

    handleCloseModal() {
        this.setState({ selectedModal: false })
    }

    openModal() {
        this.setState({ openModalNoBoat: true })
    }

    render() {
        const { classes, loading } = this.props
        const { boat } = this.props
        if (!boat && loading === 0) {
            return (
                <div className={classes.root2}>
                    <img
                        className={classes.backgroundImage}
                        src={catamaranicon}
                        alt="catamaranicon"
                    />
                    <p onClick={this.openModal} className={classes.noBoatText}>
                        {this.displayText(`noBoat`)}
                    </p>
                    {
                        <ModalNoBoat
                            open={this.state.openModalNoBoat}
                            handleClose={this.handleClose}
                        />
                    }
                </div>
            )
        }

        return (
            <div className={classes.root} key="alerts">
                <div className={classes.main}>
                    <TopInventory
                        handleCheckBox={this.handleCheckBox}
                        handleSelectAllCheckBox={this.handleSelectAllCheckBox}
                        checkedBoxes={this.state.checkedBoxes}
                    />
                    <InventoryBar
                        open={this.state.checkedBoxes.length}
                        deleteSelected={() => {
                            if (
                                window.confirm(
                                    this.displayText('validateDeleteInv'),
                                )
                            )
                                this.deleteSelected()
                        }}
                        downloadOrder={this.downloadOrder}
                        sendEmail={this.sendEmail}
                        downloadExcel={this.downloadExcel}
                    />
                </div>
                <BoatOnModal
                    openCondition={this.state.selectedModal}
                    handleClose={this.handleCloseModal}
                    modalCores={{
                        startDownloadModal: (
                            <BoatOnAlert
                                noCross={false}
                                closeModal={this.handleCloseModal}
                                severity={`info`}
                                description={this.displayText(
                                    'startDownloadMessage',
                                )}
                                style={{
                                    marginBottom: 0,
                                }}
                            />
                        ),
                    }}
                    titles={{
                        startDownloadModal: this.displayText(
                            'startDownloadTitle',
                        ),
                    }}
                />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        user: state.authentication.user,
        boats: state.fetch.boats,
        boat: state.bob.boat,
        loading: state.bob.loading,
    }
}

export default connect(mapStateToProps)(withStyles(styles)(InventoryPage))
