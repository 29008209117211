import React from 'react'
import BoatOnComponent from '../BoatOnComponent'
import { LocalizedLink, LocalizedSwitcher } from '../../../languages'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import { Button, ListItemIcon, ListItemText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import styles from './MobileDrawerCss'
import { getCurrentRootRouteKey } from '../../../languages/LocalizerUtils'
import dictionary from '../Dictionary/NavigationDico'
import MobileBoatSelecter from '../../LogbookPage/LogbookNavigationPage/MobileBoatSelecter'
import AppRoute from '../../../constants/AppRoute'
import logo from '../../../images/logoBleu.png'

class MobileDrawer extends BoatOnComponent {
    constructor(props) {
        super(props)

        this.dictionary = dictionary
    }

    render() {
        const {
            classes,
            firstMenu,
            secondMenu,
            loadingUser,
            user,
            logout,
        } = this.props
        const url = window.location.pathname
        const pathname = getCurrentRootRouteKey(url, this.getMessages())

        return (
            <div className={classes.drawerBox}>
                <div className={classes.drawerHeader}>
                    {user ? (
                        <LocalizedLink
                            link={'/profile'}
                            to={AppRoute.Profile}
                            style={{
                                textDecoration: 'none',
                            }}
                            className={classes.userContainer}
                        >
                            <div className={classes.profilePicture}>
                                <span>
                                    {user.firstName[0]}
                                    {user.lastName[0]}
                                </span>
                            </div>
                            <span className={classes.username}>
                                {user.firstName} {user.lastName}
                            </span>
                        </LocalizedLink>
                    ) : (
                        <LocalizedLink to={AppRoute.Home}>
                            <img
                                src={logo}
                                width="120px"
                                alt="Boaton"
                                title="Boaton"
                            />
                        </LocalizedLink>
                    )}
                    <MobileBoatSelecter />
                </div>
                <div className={classes.drawerMain}>
                    <List className={classes.listHamburger}>
                        {firstMenu.map(item => {
                            if (item.hidden) {
                                return null
                            }

                            if (item.route === undefined && item.link) {
                                return (
                                    <React.Fragment key={item.link}>
                                        <a
                                            href={item.link}
                                            className={classes.noUnderline}
                                            target="_blank"
                                        >
                                            <ListItem
                                                className={[
                                                    classes.listHamburgerItem,
                                                    item.disabled
                                                        ? classes.disabled
                                                        : undefined,
                                                ].join(' ')}
                                                button
                                            >
                                                <ListItemIcon
                                                    className={classes.icon}
                                                    onClick={e => {
                                                        if (item.disabled)
                                                            e.preventDefault()
                                                    }}
                                                >
                                                    {item.imageSource && (
                                                        <img
                                                            src={
                                                                item.imageSource
                                                            }
                                                            className={
                                                                classes.iconMenu
                                                            }
                                                        />
                                                    )}
                                                    {item.icon &&
                                                        React.createElement(
                                                            item.icon,
                                                            {
                                                                className:
                                                                    classes.iconMenu,
                                                            },
                                                        )}
                                                </ListItemIcon>
                                                <ListItemText
                                                    className={
                                                        pathname &&
                                                        pathname === item.route
                                                            ? classes.activeTextCategory
                                                            : null
                                                    }
                                                    primary={item.title}
                                                    onClick={e => {
                                                        if (item.disabled)
                                                            e.preventDefault()
                                                    }}
                                                />
                                            </ListItem>
                                        </a>
                                        <Divider />
                                    </React.Fragment>
                                )
                            }

                            return (
                                <React.Fragment key={item.title}>
                                    <LocalizedLink
                                        variant="body1"
                                        to={item.route}
                                        link={item.link}
                                        style={{
                                            textDecoration: 'none',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            color: '#000',
                                            padding: `0`,
                                        }}
                                    >
                                        <ListItem
                                            className={[
                                                classes.listHamburgerItem,
                                                item.disabled
                                                    ? classes.disabled
                                                    : undefined,
                                            ].join(' ')}
                                            button
                                        >
                                            <ListItemIcon
                                                className={classes.icon}
                                                onClick={e => {
                                                    if (item.disabled)
                                                        e.preventDefault()
                                                }}
                                            >
                                                {item.imageSource && (
                                                    <img
                                                        src={item.imageSource}
                                                        className={
                                                            classes.iconMenu
                                                        }
                                                    />
                                                )}
                                                {item.icon &&
                                                    React.createElement(
                                                        item.icon,
                                                        {
                                                            className:
                                                                classes.iconMenu,
                                                        },
                                                    )}
                                            </ListItemIcon>
                                            <ListItemText
                                                className={
                                                    pathname &&
                                                    pathname === item.route
                                                        ? classes.activeTextCategory
                                                        : null
                                                }
                                                primary={item.title}
                                                onClick={e => {
                                                    if (item.disabled)
                                                        e.preventDefault()
                                                }}
                                            />
                                        </ListItem>
                                    </LocalizedLink>
                                    <Divider />
                                </React.Fragment>
                            )
                        })}
                    </List>
                    <List className={classes.listHamburger}>
                        {!loadingUser &&
                            secondMenu.map(item => (
                                <>
                                    <LocalizedLink
                                        variant="body1"
                                        key={item.title}
                                        to={item.route}
                                        link={item.link}
                                        style={{
                                            textDecoration: 'none',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            color: '#000',
                                        }}
                                    >
                                        <ListItem
                                            className={
                                                classes.listHamburgerItem
                                            }
                                            button
                                        >
                                            <ListItemText
                                                primary={item.title}
                                            />
                                        </ListItem>
                                    </LocalizedLink>
                                    <Divider />
                                </>
                            ))}
                    </List>
                    <LocalizedSwitcher
                        format="long"
                        classes={{
                            root: classes.localizedSwitchSelect,
                            navLink: classes.navLink,
                        }}
                    />
                </div>
                <div className={classes.footer}>
                    {user ? (
                        <Button
                            className={classes.disconnect}
                            onClick={logout}
                            variant="contained"
                        >
                            <ExitToAppIcon
                                color="inherit"
                                className={classes.disconnectIcon}
                            />
                            {this.displayText('disconnect')}
                        </Button>
                    ) : (
                        <LocalizedLink
                            link={'/login'}
                            to={AppRoute.Login}
                            style={{
                                textDecoration: 'none',
                            }}
                        >
                            <Button
                                className={classes.disconnect}
                                variant="contained"
                            >
                                {this.displayText('login')}
                            </Button>
                        </LocalizedLink>
                    )}
                </div>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(MobileDrawer)
